import { AxiosPromise } from "axios";
import { HttpRequestMethod, Response } from "../core/HttpService";
import { Role } from "../pages/service/userRoleTypes";
import axios from "../utils/axios";

export interface IUserRes extends Response<IUserInfo> {}
export interface IUserInfo {
  userId: string;
  usersRole: Role;
  employeeId: string;
  id?: string; // inner userId
  nameCN?: string;
  nameEN?: string;
  email?: string;
}

export const getUserInfo = (token: string | number): AxiosPromise<IUserRes> => {
  const url = "/wise/v1/user/info";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
  });
};

export interface ISearUserReq {
  userId?: string;
  searchKey?: string;
  [key: string]: any;
}

export interface ISearchUserRes {
  nameCN: string;
  nameEN?: string;
  email?: string;
  userRole: Role;
  rbacCorrelationId: string;
}
/**
 *
 * @param role
 * @param params
 * @returns AxiosPromise<Response<any>>
 */

export const getSearchUser = (
  role: Role,
  params: ISearUserReq
): AxiosPromise<Response<ISearchUserRes[]>> => {
  const url = getSearchUserUrl(role);
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { ...params, searchKey: "" },
  });
};

const getSearchUserUrl = (role: Role): string => {
  const urlMaps = new Map([
    [Role.ROLE_SALES, "/wise/v1/Sales/Search"],
    [Role.ROLE_RSM, "/wise/v1/Rsm/Search"],
    [Role.ROLE_FSE, "/wise/v1/Fse/Search"],
    [Role.ROLE_APPLY_SUPERVISOR, "/wise/v1/APSupervisor/Search"],
    [Role.ROLE_APPLY_ENGINEER, "/wise/v1/APEngineer/Search"],
  ]);
  return urlMaps.get(role) ?? "";
};

export interface IAssignOrForwardUserReq {
  installationTaskId?: string;
  [key: string]: any;
}

export enum Action {
  FORWARD = 1,
  ASSIGN = 2,
}

export enum Feature {
  SPLIT_ORDER = "splitOrder",
  INFO = "info",
  SITE_SURVEY = "siteSurvey",
  INSTALLATION = "installation",
  TRAINING = "training",
  FAC_IBASE = "ibase",
}
/**
 *
 * @param role
 * @param action
 * @param data
 * @returns
 */
export const assignOrForwardUser = (
  role: Role,
  action: Action,
  feature: Feature,
  data: IAssignOrForwardUserReq
): AxiosPromise<Response<{}>> => {
  const url = getAssignOrForwardUserUrl(role, action, feature);
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

// url need to be confirm
const getAssignOrForwardUserUrl = (
  role: Role,
  action: Action,
  feature: Feature
): string => {
  const urlMaps = new Map<string, string>([
    [
      `${Feature.SPLIT_ORDER}_${Role.ROLE_SALES}_${Action.FORWARD}`,
      "/wise/v1/ForwardParentOrder",
    ],
    [
      `${Feature.INFO}_${Role.ROLE_SALES}_${Action.FORWARD}`,
      "/wise/v1/ForwardOtherSales",
    ],
    [
      `${Feature.SITE_SURVEY}_${Role.ROLE_RSM}_${Action.FORWARD}`,
      "/wise/v1/SiteSurvey/Rsm/Forward",
    ],
    [
      `${Feature.SITE_SURVEY}_${Role.ROLE_FSE}_${Action.ASSIGN}`,
      "/wise/v1/SiteSurvey/Rsm/Assign",
    ],
    [
      `${Feature.INSTALLATION}_${Role.ROLE_RSM}_${Action.FORWARD}`,
      "/wise/v1/Installation/Rsm/Forward",
    ],
    [
      `${Feature.INSTALLATION}_${Role.ROLE_FSE}_${Action.ASSIGN}`,
      "/wise/v1/Installation/Rsm/Assign",
    ],
    [
      `${Feature.TRAINING}_${Role.ROLE_APPLY_SUPERVISOR}_${Action.FORWARD}`,
      "/wise/v1/Training/APSupervisor/Forward",
    ],
    [
      `${Feature.TRAINING}_${Role.ROLE_APPLY_ENGINEER}_${Action.ASSIGN}`,
      "/wise/v1/Training/APSupervisor/Assign",
    ],
  ]);

  return urlMaps.get(`${feature}_${role}_${action}`) ?? "";
};
