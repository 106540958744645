interface IInfo {
  fillColor?: string;
  size?: "auto" | "default";
}

export default function (props: IInfo): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "14",
    height = size === "auto" ? "100%" : "16";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C11 6.20914 9.20914 8 7 8C4.79086 8 3 6.20914 3 4C3 1.79086 4.79086 0 7 0C9.20914 0 11 1.79086 11 4ZM10 4C10 5.65685 8.65685 7 7 7C5.34315 7 4 5.65685 4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4Z"
        fill="#32373E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15C0 11.6863 2.68629 9 6 9H8C11.3137 9 14 11.6863 14 15V16H0V15ZM1 15C1 12.2386 3.23858 10 6 10H8C10.7614 10 13 12.2386 13 15H1Z"
        fill="#32373E"
      />
    </svg>
  );
}
