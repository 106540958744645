import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import InstallationModel, { IInstallation } from "../installationModel";
import InstallationFseModel from "./InstallationFseModel";

export default function () {
  const { labelCancel, labelConfirm, rejectTitle, labelSubmitReminder } =
    useLang({
      labelCancel: { id: "common_btn_cancel" },
      labelConfirm: { id: "common_btn_confirm" },
      rejectTitle: { id: "order_reject_assignment" },
      labelSubmitReminder: { id: "order_confirm_order_reminder" },
    });
  const [infos, setInfos] = React.useState<Partial<IInstallation>>(
    {} as Partial<IInstallation>
  );
  const [rejectVisible, setReject] = React.useState(false);
  const [submitVisible, setSubmit] = React.useState(false);
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const { userId } = useRecoilValue(userState);
  const [buttonBlock, setBlock] = React.useState(false);
  const systemReginOrg = useRecoilValue(systemUserOrgs);
  const navigate = useNavigate();
  const rolePage = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );
  const platform = useRecoilValue(platformState);
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const submitEnable = React.useMemo(
    () => InstallationFseModel.verifySubmission(infos),
    [infos]
  );
  const locale = useRecoilValue(localeState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getInfos(installationTaskId), [installationTaskId]);

  const getInfos = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getInstallationInfo(installationTaskId, locale)
        .then((res) => setInfos(res?.data))
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [setLoading, toastError]
  );

  const rejectAssignment = React.useCallback(
    (rejectReason: string) => {
      return InstallationFseModel.fseRejectAssignInstallation({
        reason: rejectReason,
        fseRBACCorrelationId: userId,
        installationTaskId,
      });
    },
    [userId, installationTaskId]
  );

  const submitInstallation = React.useCallback(() => {
    const {
      actualInstallStartDate,
      actualInstallEndDate,
      installationReports,
    } = infos;
    return InstallationFseModel.fseSubmitInstallation({
      installationTaskId,
      actualInstallStartDate: actualInstallStartDate as number,
      actualInstallEndDate: actualInstallEndDate as number,
      installationReports: installationReports as any[],
    });
  }, [installationTaskId, infos]);

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const rejectHandler = React.useCallback(
    (type: CloseType, reason: any) => {
      setReject(false);
      if (type === "cancel") return;
      setLoading(true);
      setBlock(true);
      rejectAssignment(reason)
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [setLoading, rejectAssignment, backAllTickHandler, toastError]
  );

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setSubmit(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      setLoading(true);
      setBlock(true);
      submitInstallation()
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [setLoading, submitInstallation, backAllTickHandler, toastError]
  );

  const changeHandler = React.useCallback(
    (key: keyof IInstallation, value: any) => {
      setInfos((pre) => {
        if (key !== InstallationModel.ACTUAL_INSTALL_END_DATE) {
          return { ...pre, [key]: value };
        } else {
          if (value >= (pre?.actualInstallStartDate as number)) {
            return { ...pre, [key]: value };
          }
          return {
            ...pre,
            [InstallationModel.ACTUAL_INSTALL_END_DATE]: value,
            [InstallationModel.ACTUAL_INSTALL_START_DATE]: undefined,
          };
        }
      });
    },
    []
  );

  return {
    infos,
    rolePage,
    systemReginOrg,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitEnable,
    rejectVisible,
    rejectTitle,
    submitVisible,
    labelSubmitReminder,
    platform,
    setReject,
    setSubmit,
    rejectHandler,
    confirmHandler,
    changeHandler,
    backAllTickHandler,
  };
}
