import PageWrapper from "../../../../layout/PageWrapper";
import FacIBaseCRMUpdateMobile from "./FacIBaseCRMUpdateMobile";
import FacIBaseCRMUpdatePc from "./FacIBaseCRMUpdatePc";

export default () => (
  <PageWrapper
    PageMobile={FacIBaseCRMUpdateMobile}
    PagePc={FacIBaseCRMUpdatePc}
  />
);
