import { Button } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import { IFile } from "../../model/OrderRequestModel";
import FacIBaseModel, { IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseFacUpload.module.scss";
import useFacIBaseFac from "./useFacIBaseFac";

const FacIBaseFacUploadPc: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    rolePage,
    labelCancel,
    labelConfirm,
    labelSubmitReminder,
    labelInstallDateReminder,
    submitEnable,
    submitVisible,
    buttonBlock,
    platform,
    locale,
    setSubmit,
    confirmHandler,
    backToAllTicket,
    changeHandler,
  } = useFacIBaseFac();

  const filesPcView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoViewModel(
      facIBaseInfo as IFacIBase,
      rolePage?.pageStatus,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === FacIBaseModel.FAC_CAC_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return <div className={styles.filesPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, platform, locale, changeHandler]);

  const facDatePcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoViewPcModel(
      facIBaseInfo as Partial<IFacIBase>,
      rolePage?.pageStatus
    );
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.ACCEPTANCE_DATE_FAC,
          {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IFacIBase, value?.getTime()),
          },
        ],
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      const viewItem =
        key === InstallationModel.WARRANTY_PERIOD ? (
          <>
            <ViewComponent type={viewType as ViewType} props={_props} />
            <div className={styles.warrantyPeriodReminder}>
              {warrantyPeriodReminder}
            </div>
          </>
        ) : (
          <ViewComponent type={viewType as ViewType} props={_props} />
        );

      const containerCls = classNames({
        [styles.warrantyPeriodContainer]:
          key === InstallationModel.WARRANTY_PERIOD,
      });

      return (
        <div key={key} className={containerCls}>
          {viewItem}
        </div>
      );
    });

    return (
      <div className={styles.facDatePcView}>
        {views}
        <TextReminder label={labelInstallDateReminder} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facIBaseInfo,
    labelInstallDateReminder,
    rolePage?.pageStatus,
    locale,
    changeHandler,
  ]);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getFacUploadButtonViewModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
      [RsmAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === RsmAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, submitEnable, locale, backToAllTicket, setSubmit]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.facIbaseUploadPc}>
      <div className={styles.pageContentPc}>
        {filesPcView}
        {facDatePcView}
      </div>
      {buttonsView}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={submitVisible}
        title={labelSubmitReminder}
        onConfirm={confirmHandler}
      />
    </div>
  );
});

export default FacIBaseFacUploadPc;
