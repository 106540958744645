import { Button } from "@zeiss/ods-components-react";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import ModifyContact from "../../../../../components/ModifyContact/ModifyContact";
import { IFinalGoodsReceipt } from "../../../../../model/OrderRequestModel";
import { FinalGoodsRecipStatus } from "../../../InfoSalesModel";
import useFinalGoodsRecip from "../../../hooks/useFinalGoodsRecip";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./FinalGoodsRecipView.module.scss";

export const FinalGoodsRecipView: React.FC = ErrorDetectorMediumView(() => {
  const {
    title,
    finalGoodRecip,
    editable,
    revise,
    newLabel,
    finalGoodRecipNew,
    visible,
    models,
    locale,
    updateHandler,
    confirmHandler,
  } = useFinalGoodsRecip();

  const infoView = React.useCallback((recipInfos: IFinalGoodsReceipt) => {
    const infos = InfoSalesPcModel.getPartnerItem(recipInfos);
    return infos?.map(({ key, viewType, props }) => (
      <ViewComponent
        key={key}
        type={viewType as ViewType}
        props={{ ...props, key, className: styles.infoItem }}
      />
    ));
  }, []);

  const finalGoodsRecipView = React.useMemo(() => {
    return (
      <>
        <div className={styles.finalGoodsRecipView}>
          <div className={styles.title}>{title}</div>
          <div className={styles.personInfoView}>
            {infoView(finalGoodRecip)}
          </div>
          {editable && !finalGoodRecipNew?.name && (
            <Button
              className={styles.btn}
              onClick={updateHandler.bind(null, FinalGoodsRecipStatus.ADD)}
            >
              {revise}
            </Button>
          )}
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    finalGoodRecip,
    editable,
    finalGoodRecipNew?.name,
    locale,
    revise,
    infoView,
    updateHandler,
  ]);

  const finalGoodsRecipNewView = React.useMemo(() => {
    const titleText = (
      <>
        {title}
        <span className={styles.new}>{newLabel}</span>
      </>
    );

    return (
      finalGoodRecipNew?.name && (
        <div className={styles.finalGoodsRecipNewView}>
          <div className={styles.title}>{titleText}</div>
          <div className={styles.personInfoView}>
            {infoView(finalGoodRecipNew)}
          </div>
          {editable && (
            <Button
              className={styles.btn}
              onClick={updateHandler.bind(null, FinalGoodsRecipStatus.UPDATE)}
            >
              {revise}
            </Button>
          )}
        </div>
      )
    );
  }, [
    title,
    newLabel,
    finalGoodRecipNew,
    editable,
    infoView,
    updateHandler,
    revise,
  ]);

  return (
    <>
      {finalGoodsRecipView}
      {finalGoodsRecipNewView}
      {visible && (
        <ModifyContact
          title={title}
          subTitle={newLabel}
          status={FinalGoodsRecipStatus.UPDATE}
          models={models}
          visible={visible}
          onConfirm={confirmHandler}
        />
      )}
    </>
  );
});
