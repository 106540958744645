import classNames from "classnames";
import React from "react";
import { IMoreDetailItem } from "../../model/OrderRequestModel";
import styles from "./MoreDetailView.module.scss";

export interface IMoreDetailView extends IMoreDetailItem {
  className?: string;
}

const MoreDetailView: React.FC<IMoreDetailView> = (props: IMoreDetailView) => {
  const { itemDescription, itemOrderQuantity, itemMaterial, className } = props;
  const quantifyLabel = `x${itemOrderQuantity}`;
  const _moreDetail = classNames(styles.moreDetail, className);

  return (
    <div className={_moreDetail}>
      <div className={styles.name}>
        <div className={styles.text}>{itemDescription}</div>
        <div className={styles.sn}>{itemMaterial}</div>
      </div>
      <div className={styles.quantify}>{quantifyLabel}</div>
    </div>
  );
};

export default MoreDetailView;
