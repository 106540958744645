import { AxiosPromise } from "axios";
import { HttpRequestMethod, NormalRes, Response } from "../core/HttpService";
import { OrderStatus } from "../pages/OrderList/OrderListModel";
import { IFacIBase } from "../pages/OrderRequest/FacIBase/FacIBaseModel";
import {
  IInstallation,
  WarrantyPeriod,
} from "../pages/OrderRequest/Installation/installationModel";
import { IContactPerson } from "../pages/OrderRequest/OrderInfo/InfoSales/InfoSalesModel";
import {
  CommentKey,
  IFinalGoodsReceipt,
  IMoreDetailItem,
  IPrepareInstallation,
  OrderStepItem,
} from "../pages/OrderRequest/model/OrderRequestModel";
import axios from "../utils/axios";
import { OrderStepSummary } from "./../pages/service/roleStateService/RoleStepPageService";

export interface IUploadFile {
  fileUrl: string;
  fileName: string;
  id?: number | string;
}
export interface IUploadFileRes extends Response<IUploadFile> {
  [x: string]: any;
}
export const uploadFile = (params: File): AxiosPromise<IUploadFileRes> => {
  const url = "/wise/v1/UploadAttachment";
  const fileData = new FormData();
  fileData.append("file", params, params.name);

  const configs = {
    headers: { "Content-Type": "multipart/form-data" },
    method: HttpRequestMethod.METHOD_POST,
    url: url,
    data: fileData,
  };
  return axios.request(configs);
};

export interface IRemoveFileRes extends Response<{}> {}
export const removeFile = (
  fileId: number | string
): AxiosPromise<IRemoveFileRes> => {
  const url = "/wise/v1/DeleteAttachment";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    params: { filePath: fileId },
  });
};

/**
 * Get Order List
 */

export interface OrderItem {
  isMain: boolean;
  id: string; // installation Task id
  parentInstallationTaskId?: string; // main order id, need to split
  status: OrderStatus;
  fullFinalGoodsRecipName: string;
  isFinalGoodsRecipNew?: boolean;
  contactPersonName: string;
  mainProduct: string;
  subsStandardOrder: string;
  orderStepSummary: OrderStepSummary;
  salesEmployeeNameCN: string;
  salesEmployeeNameEN: string;
  owners: { ownerNameCN: string; ownerNameEN: string }[];
  serialNumbers: string[] | string;
  serviceRegion: number;
  isUnSplitOrder?: boolean;
}
export interface IOrderListRequest {
  pageIndex?: number;
  pageCount?: number;
  status?: OrderStatus;
  keywords?: string;
  userId?: string;
}
export interface IOrderListRes
  extends Response<{
    list: OrderItem[];
    total: number;
    pageIndex: number;
  }> {}
export const getOrderList = (
  params: IOrderListRequest
): AxiosPromise<IOrderListRes> => {
  const url = "/wise/v1/InstallationTask/GetTicketList";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params,
  });
};

/**
 * Get prepare install information
 */
export interface IPreInstallRes extends Response<IPrepareInstallation> {}

export const getPreInstallInfo = (
  installationTaskId: string
): AxiosPromise<IPreInstallRes> => {
  const url = "/wise/v1/GetSiteSurvey";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};

/**
 *  submit prepare install information
 */

export interface IPreInstallReq extends Partial<IPrepareInstallation> {
  installationTaskId: string;
}

export const submitPreInstallInfo = (
  data: IPreInstallReq
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/SiteSurvey/Submit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/**
 * Cancel order
 */
export interface IOrderCancelRes extends Response<{}> {}
export const orderCancel = (id: string): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/CancelSale";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data: { installationTaskId: id },
  });
};

/**
 * Get Order Process
 */

export interface IOrderProcessRes extends Response<OrderStepItem[]> {}
export const getOrderSteps = (id: string): AxiosPromise<IOrderProcessRes> => {
  const url = `/wise/v1/GetSteps`;
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

/**
 * Get Sales Organization
 */

export interface StaticSourceItem {
  value: number;
  text: string;
  [key: string]: any;
}

export interface OrgUserItem {
  key: number;
  userNameCN: string;
  userNameEN: string;
  email?: string;
}

export interface OrgRes {
  apSupervisor: OrgUserItem[];
  regionProductLine: ISaleOrgItem[];
}
export interface ISaleOrgItem {
  productLines: {
    key: number;
    value: string;
    rsm: OrgUserItem;
    isDeleted?: boolean;
  }[];
  region: {
    key: number;
    value: string;
    isDeleted?: boolean;
  };
  so: OrgUserItem;
  [key: string]: any;
}

export interface ISaleOrgRes extends Response<OrgRes> {}
export const getSaleOrg = (): AxiosPromise<ISaleOrgRes> => {
  const url = "/wise/v1/GetSalesRegionProductList";
  return axios.request({ method: HttpRequestMethod.METHOD_GET, url });
};

/**
 * Get Order header infos
 */

export interface IOrderBasicInfoReq {}
export interface IOrderBasicInfo {
  subsStandardOrder: string;
  finalGoodsRecipName: string;
  poDate: number;
  mainProduct: string;
  orderStepSummary: OrderStepSummary;
  orderStatus: OrderStatus;
  productQuantity: number;
  isFinalGoodsRecipNew: boolean;
}

export interface IOrderBasicInfoRes extends Response<IOrderBasicInfo> {}
export const getOrderBasicInfo = (
  id: string
): AxiosPromise<IOrderBasicInfoRes> => {
  const url = "/wise/v1/GetOrderBasicInfo";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

export interface IOrderComments
  extends Omit<OrderStepItem, "comments" | "key"> {
  key: CommentKey;
  comments: string;
  installationTaskId: string;
  commentsCN?: string;
  commentsEN?: string;
}

export interface IOrderCommentsRes extends Response<IOrderComments> {}
export const getOrderComment = (
  id: string
): AxiosPromise<IOrderCommentsRes> => {
  const url = "/wise/v1/GetComments";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

export const insertComments = (
  data: IOrderComments
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/InsertComment";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IOrderContactRes extends Response<IContactPerson> {}
export const getContactPerson = (
  id: string
): AxiosPromise<IOrderContactRes> => {
  const url = "/wise/v1/ContactPerson";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

export interface IOrderContactUpdateReq extends IContactPerson {
  installationTaskId: string;
}
export interface IOrderContactUpdateRes extends Response<{}> {}
export const updateContactPerson = (
  data: IOrderContactUpdateReq
): AxiosPromise<IOrderContactRes> => {
  const url = "/wise/v1/ContactPerson/edit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IOrderSoldToPartyRes extends Response<IFinalGoodsReceipt> {}
export const getSoldToParty = (
  id: string
): AxiosPromise<IOrderSoldToPartyRes> => {
  const url = "/wise/v1/SoldParty";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

export interface IOrderFinalGoodRecipRes
  extends Response<IFinalGoodsReceipt[]> {}
export const getFinalGoodsRecip = (
  id: string
): AxiosPromise<IOrderFinalGoodRecipRes> => {
  const url = "/wise/v1/FinalsGoodsRecips";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId: id },
  });
};

export interface IOrderFinalGoodRecipUpdateReq extends IFinalGoodsReceipt {
  installationTaskId: string;
}
export interface IOrderFinalGoodRecipAddReq extends IFinalGoodsReceipt {
  installationTaskId: string;
}
export interface IOrderFinalGoodRecipAddRes extends Response<{}> {}
export const addFinalGoodsRecip = (
  data: IOrderFinalGoodRecipUpdateReq
): AxiosPromise<IOrderFinalGoodRecipAddRes> => {
  const url = "/wise/v1/FinalsGoodsRecips/add";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/**
 * Fse reject site survey
 */
export interface IFseSiteSurveyReject {
  installationTaskId: string;
  fseRBACCorrelationId: string;
  reason: string;
}
export const fseRejectReason = (
  data: IFseSiteSurveyReject
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/SiteSurvey/Fse/Reject";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/**
 * Fse submit reports
 */
export interface IFseSiteSurveyReq {
  installationTaskId: string;
  fseRBACCorrelationId: string;
  attachments: IUploadFile[];
  actuarySiteSurveyDate: number;
}

export const fseSubmitSiteSurvey = (
  data: IFseSiteSurveyReq
): AxiosPromise<Response<any>> => {
  const url = "/wise/v1/SiteSurvey/Fse/Submit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/**
 * Order installation
 */

export interface IInstallationReq {
  installationTaskId: string;
  disclaimers: IUploadFile[];
  apSuperVisor: string;
  expectedInstallDate: number;
  warrantyPeriod: WarrantyPeriod;
  otherWarrantyPeriod: string;
  euCs: IUploadFile[];
  deliveryNotes: IUploadFile[];
  technicalAgreements: IUploadFile[];
  specifications: IUploadFile[];
  auditEmails?: IUploadFile[];
}
export const submitInstallationInfo = (
  data: IInstallationReq
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/Installation/Sales/Submit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IInstallationRes extends Response<IInstallation> {}
export const getInstallationInfo = (
  installationTaskId: string
): AxiosPromise<IInstallationRes> => {
  const url = "/wise/v1/GetInstallation";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};

export interface ISOReject {
  installationTaskId: string;
  soRBACCorrelationId: string;
  reason: string;
}
export const soRejectReason = (data: ISOReject): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/Installation/So/Reject";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface ISOApprove {
  installationTaskId: string;
  euCs?: IUploadFile[];
  deliveryNotes?: IUploadFile[];
  technicalAgreements?: IUploadFile[];
  specifications?: IUploadFile[];
  siteSurveyReports?: IUploadFile[];
  disclaimers?: IUploadFile[];
  auditEmails?: IUploadFile[];
}
export const soApprove = (data: ISOApprove): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/Installation/So/Approve";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IRsmNeedSiteSurveyReq {
  installationTaskId: string;
  rsmRBACCorrelationId: string;
}
export const rsmNeedSiteSurvey = (
  data: IRsmNeedSiteSurveyReq
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/Installation/Rsm/NeedSiteSurvey";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IFseInstallationRejectReq {
  installationTaskId: string;
  fseRBACCorrelationId: string;
  reason: string;
}
export const fseRejectAssignInstallation = (
  data: IFseInstallationRejectReq
): AxiosPromise<Response<any>> => {
  const url = "/wise/v1/Installation/Fse/Reject";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IFseSubmitInstallationReq {
  installationTaskId: string;
  actualInstallStartDate: number;
  actualInstallEndDate: number;
  installationReports: IUploadFile[];
}
export const fseSubmitInstallation = (
  data: IFseSubmitInstallationReq
): AxiosPromise<Response<any>> => {
  const url = "/wise/v1/Installation/Fse/Submit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/**
 * Training
 */

export interface IInstallationRes extends Response<IInstallation> {}
export const getTrainingInfo = (
  installationTaskId: string
): AxiosPromise<IInstallationRes> => {
  const url = "/wise/v1/GetTraining";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};
export interface IApEngineerRejectTrainingReq {
  installationTaskId: string;
  apEngineerRBACCorrelationId: string;
  reason: string;
}
export const apEngineerRejectTraining = (
  data: IApEngineerRejectTrainingReq
): AxiosPromise<Response<any>> => {
  const url = "/wise/v1/Training/APEngineer/Reject";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IApEngineerSubmitTrainingReq {
  installationTaskId: string;
  actualTrainingStartDate: number;
  actualTrainingEndDate: number;
  trainingReports: IUploadFile[];
}
export const apEngineerSubmitTraining = (
  data: IApEngineerSubmitTrainingReq
): AxiosPromise<Response<any>> => {
  const url = "/wise/v1/Training/APEngineer/Submit";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IInstallationRes extends Response<IInstallation> {}
export const getInstallationSummaryInfo = (
  installationTaskId: string
): AxiosPromise<IInstallationRes> => {
  const url = "/wise/v1/GetInstallationSummary";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};

/**Fac Ibase */

export interface IFacIBaseRes extends Response<IFacIBase> {}
export const getFacIBaseInfoFAC = (
  installationTaskId: string
): AxiosPromise<IFacIBaseRes> => {
  const url = "/wise/v1/facIBase/GetFacIBaseInfoFAC";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};

export interface IFacIBaseReq {
  installationTaskId: string;
  facCacReports: IUploadFile[];
  iBaseAcceptanceDate: number;
  warrantyPeriod: WarrantyPeriod;
  otherWarrantyPeriod?: string;
}
export const submitFacIBaseInfo = (
  data: IFacIBaseReq
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/facIBase/SubmitFAC";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export interface IFACIBaseCRMConfirmReq {
  installationTaskId: string;
  sorbacCorrelationId: string;
}
export const crmConfirm = (
  data: IFACIBaseCRMConfirmReq
): AxiosPromise<Response<{}>> => {
  const url = "/wise/v1/InstallationTask/ConfirmCompletionTimeOfIBaseInfo";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

/** Split Order */
export interface ISplitOrderBasicInfoRes extends IOrderBasicInfo {}
export const getSplitOrderBasicInfo = (
  parentInstallationTaskId: string
): AxiosPromise<Response<ISplitOrderBasicInfoRes>> => {
  const url = "/wise/v1/GetParentOrderBasicInfo";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { parentInstallationTaskId },
  });
};

export interface ISplitOrderInfoRes {
  mainProduct: IMoreDetailItem;
  otherProductItems: IMoreDetailItem[];
  splitOrderProductItems?: IMoreDetailItem[][];
}
export const getSplitOrderInfo = (
  parentInstallationTaskId: string
): AxiosPromise<Response<ISplitOrderInfoRes>> => {
  const url = "/wise/v1/GetParentOrderItems";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { parentInstallationTaskId },
  });
};

export interface ISplitOrderReq {
  parentInstallationTaskId: string;
  splitOrderProductItems: { id: string; itemOrderQuantity: number }[][];
}

export const splitOrder = (data: ISplitOrderReq): AxiosPromise<NormalRes> => {
  const url = "/wise/v1/SplitOrder";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};

export const cancelUnSplitOrder = (
  parentInstallationTaskId: string
): AxiosPromise<NormalRes> => {
  const url = "/wise/v1/CancelUnSplitOrder";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data: { parentInstallationTaskId },
  });
};

export interface ILinkedOrderList {
  installationTaskId: string;
  orderStepSummary: OrderStepSummary;
  subsStandardOrder: string;
}

export interface ILinkedOrderListRes extends Response<ILinkedOrderList> {}

export const getLinkedOrderList = (
  installationTaskId: string
): AxiosPromise<ILinkedOrderListRes> => {
  const url = "/wise/v1/InstallationTask/GetLinkedOrderList";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params: { installationTaskId },
  });
};

// Admin Module

export interface IAdminOrderList extends IOrderListRequest {}

export const getAdminOrderList = (
  params: IAdminOrderList
): AxiosPromise<IOrderListRes> => {
  const url = "/wise/v1/Admin/GetTicketList";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
    params,
  });
};

export enum AdminResetType {
  Restore = 1,
  Reset = 2,
}

export interface IResetTicketReq {
  installationTaskId: string;
  userId: string;
  isMain: boolean;
  actionType: AdminResetType;
}

export const resetTicket = (data: IResetTicketReq): AxiosPromise<NormalRes> => {
  const url = "/wise/v1/Admin/ResetOrRestore";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    data,
  });
};
