import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import Table from "../../../../../../../components/Table/Table";
import FoldCabinetVariantPc from "../../../../../../components/FoldCabinet/FoldCabinetVariantPc";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import { IPartnerTeamOtherInfo } from "../../../../../model/OrderRequestModel";
import useOrderProductInfo, {
  OrderProdInfoProps,
} from "../../../hooks/useOrderProductInfo";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./OrderProductInfoView.module.scss";

const OrderProductInfoView: React.FC<OrderProdInfoProps> =
  ErrorDetectorMediumView((props: OrderProdInfoProps) => {
    const {
      infos,
      pageStatus,
      productItems,
      visible,
      locale,
      getSaleOptions,
      changeHandler,
      folderChangeHandler,
    } = useOrderProductInfo(props);

    const saleBasicView = React.useMemo(() => {
      const saleModifyList = InfoSalesPcModel.getSaleBasicModifyModels(
        infos as Partial<IPartnerTeamOtherInfo>,
        pageStatus
      );

      return (
        <div className={styles.saleBasicView}>
          {saleModifyList?.map((item) => {
            const { key, props, viewType } = item;
            const options = getSaleOptions(key as any);
            const newProps = {
              ...props,
              key,
              options: options,
              disabled: !options.length || props?.disabled,
              onChange: (value: any) =>
                changeHandler(
                  key as keyof Partial<IPartnerTeamOtherInfo>,
                  value
                ),
            };
            return (
              <div key={key} className={styles.infoInput}>
                <ViewComponent type={viewType as ViewType} props={newProps} />
              </div>
            );
          })}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infos, pageStatus, locale, getSaleOptions, changeHandler]);

    const saleMoreDetailView = React.useMemo(() => {
      if (!productItems?.length) return null;
      const columns = InfoSalesPcModel.getProductItemModels();
      return (
        <FoldCabinetVariantPc
          status={visible}
          onChange={folderChangeHandler}
          cabinetClassName={styles.saleMoreDetail}
        >
          <div className={styles.moreDetailContainer}>
            <Table columns={columns} data={productItems} />
          </div>
        </FoldCabinetVariantPc>
      );
    }, [folderChangeHandler, productItems, visible]);

    return (
      <>
        <div className={styles.orderProductInfoView}>
          {saleBasicView}
          {saleMoreDetailView}
        </div>
      </>
    );
  });

export default OrderProductInfoView;
