import { Icons12 } from "@zeiss/ods-lib-types/components/ContentAndData/icons";

import { Icon12 } from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isString } from "../../utils/isType";
import { ErrorDetectorMediumView } from "../ErrorDecorator/ErrorDecorator";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "./Accordion/AccordionItem";
import styles from "./SideNavigation.module.scss";
import { SideNavigationSubCategoryProps } from "./SideNavigationSubCategory";

export interface SideNavigationCategoryProps {
  /** The category's title */
  title: string;
  /** The category's icon */
  icon?: Icons12 | React.ReactNode;
  /** The category's url */
  path?: string;
  /** List of sub categories for category */
  subCategories?: SideNavigationSubCategoryProps[];
  /** Determine if link is active */
  active?: boolean;
  /** customized class name */
  className?: string;
  /**onClick trigger event */
  onClick?: (path: string, evt?: any) => void;
}

export interface SideNavigationCategoryPropsExtended
  extends React.PropsWithChildren<SideNavigationCategoryProps>,
    Omit<
      React.HTMLAttributes<HTMLDivElement>,
      keyof SideNavigationCategoryProps
    > {
  /**
   * Determines if current category is expanded.
   */
  expanded: boolean;
  /**
   * Determines if the side navigation is currently open or not.
   */
  openNavigation: boolean;
  /**
   * Index of category which contains sub categories
   */
  index?: number;
  children?: React.ReactNode;
  disabled?: boolean;
}

const SideNavigationCategoryContent = ({
  icon,
  title,
}: Pick<SideNavigationCategoryPropsExtended, "icon" | "title">) => {
  const _icon = isString(icon) ? <Icon12 icon={icon as Icons12} /> : icon;

  return (
    <>
      {_icon}
      <span>{title}</span>
    </>
  );
};

const SideNavigationCategoryHeader = ({
  openNavigation,
  active,
  disabled,
  children,
}: Pick<
  SideNavigationCategoryPropsExtended,
  "openNavigation" | "active" | "children" | "disabled"
>) => {
  const _sideNavigationCategoryHeader = classNames(
    styles.sideNavigationCategoryHeader,
    { [styles.openNavigation]: openNavigation },
    { [styles.active]: active },
    { [styles.disabled]: disabled }
  );

  return (
    <AccordionButton className={_sideNavigationCategoryHeader}>
      {children}
    </AccordionButton>
  );
};

export const SideNavigationCategory = ErrorDetectorMediumView(
  (props: SideNavigationCategoryPropsExtended) => {
    const {
      children,
      title,
      icon,
      path,
      expanded,
      openNavigation,
      onClick = () => {},
      active = false,
    } = props;
    const _active = active && (!openNavigation || !expanded);
    const disabled = !openNavigation && expanded;
    const _sideNavigationCategory = classNames(
      styles.sideNavigationCategory,
      { [styles.openNavigationExpanded]: openNavigation && expanded },
      { [styles.noneOpenNavigation]: !openNavigation },
      { [styles.active]: _active }
    );
    const _sideNavigationLink = classNames(
      styles.sideNavigationLink,
      {
        [styles.active]: _active,
      },
      { [styles.openNavigation]: openNavigation }
    );
    const _sideNavigationCategoryContent = classNames(
      styles.sideNavigationCategoryContent,
      { [styles.noneOpenNavigation]: !openNavigation }
    );
    const style = !openNavigation || !expanded ? { maxHeight: 0 } : {};
    const withoutSubNav = (
      <div
        className={_sideNavigationLink}
        onClick={onClick.bind(null, path as string)}
      >
        <a>
          <SideNavigationCategoryContent icon={icon} title={title} />
        </a>
      </div>
    );

    return path ? (
      withoutSubNav
    ) : (
      <AccordionItem
        className={_sideNavigationCategory}
        onClick={onClick.bind(null, path as string)}
      >
        <SideNavigationCategoryHeader
          openNavigation={openNavigation}
          active={_active}
          disabled={disabled}
        >
          <SideNavigationCategoryContent icon={icon} title={title} />
        </SideNavigationCategoryHeader>
        <AccordionPanel
          className={_sideNavigationCategoryContent}
          style={style}
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
