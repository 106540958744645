import React from "react";

interface IInfo {
  fillColor?: string;
  size?: "auto" | "default";
}

export default function (props: IInfo): React.ReactElement {
  const { fillColor, size } = props;
  const fill = fillColor ? fillColor : "#828D9E";
  const width = size === "auto" ? "100%" : "33",
    height = size === "auto" ? "100%" : "32";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50201 7.04416L9.50208 5.04416L10.5021 5.04419L10.502 7.04419L9.50201 7.04416Z"
        fill="#0072EF"
      />
      <path
        d="M9.50195 9.04419V16.0442H10.502V9.04419H9.50195Z"
        fill="#0072EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.002 20.0442C15.5248 20.0442 20.002 15.567 20.002 10.0442C20.002 4.52134 15.5248 0.0441895 10.002 0.0441895C4.47911 0.0441895 0.00195312 4.52134 0.00195312 10.0442C0.00195312 15.567 4.47911 20.0442 10.002 20.0442ZM10.002 19.0442C14.9725 19.0442 19.002 15.0148 19.002 10.0442C19.002 5.07363 14.9725 1.04419 10.002 1.04419C5.03139 1.04419 1.00195 5.07363 1.00195 10.0442C1.00195 15.0148 5.03139 19.0442 10.002 19.0442Z"
        fill="#0072EF"
      />
    </svg>
  );
}
