import { Button } from "@zeiss/ods-components-react";
import React from "react";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { ApEngineerAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationApEngineer.module.scss";
import InstallationApEngineerModel from "./InstallationApEngineerModel";
import useInstallationApEngineer from "./useInstallationApEngineer";

const InstallationApEngineerPc: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemOrgs,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitEnable,
    rejectVisible,
    rejectTitle,
    submitVisible,
    labelSubmitReminder,
    platform,
    locale,
    rejectHandler,
    setReject,
    setSubmit,
    changeHandler,
    backAllTickHandler,
    confirmHandler,
  } = useInstallationApEngineer();

  const dateInfoPcView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getTimeViewPcModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const datePickKeys = [
      InstallationModel.ACTUAL_TRAINING_START_DATE,
      InstallationModel.ACTUAL_TRAINING_END_DATE,
    ];
    const views = list.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
            variantClassName:
              key === InstallationModel.ACTUAL_TRAINING_START_DATE
                ? styles.actualTrainStartDate
                : "",
          }
        : {
            ...props,
            className:
              key === InstallationModel.PLAN_TRAINING_END_DATE
                ? styles.planedEndDate
                : "",
          };

      return (
        <div className={styles.pcViewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.dateInfoPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeHandler, infos, locale, rolePage?.pageStatus]);

  const installationTimeInfoPcView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getInfoViewModel(
      infos as Partial<IInstallation>,
      systemOrgs,
      platform
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(0, splitIndex);
    const views = splitList.map(({ viewType, props, key }) => {
      const propsMaps = new Map([
        [
          InstallationModel.ACTUAL_INSTALL_END_DATE,
          {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          },
        ],
        [
          InstallationModel.ACTUAL_INSTALL_START_DATE,
          {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          },
        ],
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;

      return (
        <div className={styles.pcViewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, systemOrgs, platform]);

  const installationOwnerInfoPcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationApEngineerModel.getInfoViewModel(
      infos as Partial<IInstallation>,
      systemOrgs,
      platform
    );
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(splitIndex);
    const views = splitList.map(({ viewType, props, key }) => {
      const propsMaps = new Map([
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;

      const viewItem =
        key === InstallationModel.WARRANTY_PERIOD ? (
          <div className={styles.viewItemFull}>
            <ViewComponent type={viewType as ViewType} props={_props} />
            <div className={styles.warrantyPeriodReminder}>
              {warrantyPeriodReminder}
            </div>
          </div>
        ) : (
          <div className={styles.pcViewItem} key={key}>
            <ViewComponent type={viewType as ViewType} props={_props} />
          </div>
        );

      return viewItem;
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, systemOrgs, platform, locale]);

  const filesPcView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getFileInfoViewModel(
      infos as IInstallation,
      rolePage?.pageStatus,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === InstallationModel.TRAINING_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, rolePage?.pageStatus, platform, locale, changeHandler]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  const buttonsView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<ApEngineerAction, Record<string, any>>([
      [ApEngineerAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [ApEngineerAction.REJECT, { onClick: () => setReject(true) }],
      [ApEngineerAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === ApEngineerAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );
      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setReject,
    setSubmit,
  ]);

  return (
    <section className={styles.installationApEngineerPc}>
      <div className={styles.pageContentPc}>
        {dateInfoPcView}
        {installationTimeInfoPcView}
        {installationOwnerInfoPcView}
        <OrderDnInfoView {...(infos.dnInfo as IDNInfo)} />
        {filesPcView}
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          type={ConfirmIconType.WARNING}
          onConfirm={rejectHandler}
        />
      )}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={submitVisible}
        title={labelSubmitReminder}
        onConfirm={confirmHandler}
      />
    </section>
  );
});

export default InstallationApEngineerPc;
