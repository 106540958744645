import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "42",
    height = size === "auto" ? "100%" : "50";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 1.0061H25.7929L35.5 10.7132V48.0061H0.5V1.0061Z"
        fill="#E6EBF0"
        stroke="#E6EBF0"
      />
      <path d="M26 10.5061V0.506104L36 10.5061H26Z" fill="#F2F5F8" />
      <circle cx="33.5" cy="41.0061" r="8.5" fill="#0072EF" />
      <path
        d="M33.5 42.0061V46.5061H34.5V42.0061H39V41.0061H34.5V36.5061H33.5V41.0061H29V42.0061H33.5Z"
        fill="white"
      />
    </svg>
  );
}
