import { RenderLabel } from "./DatePickerView/DatePickerView";
import type { DatePrecision, PickerDate } from "./datePickerDateUtils";
import * as dateUtils from "./datePickerDateUtils";
import { TILL_NOW } from "./datePickerDateUtils";
import type { WeekPrecision } from "./datePickerWeekUtils";
import * as weekUtils from "./datePickerWeekUtils";

export type Precision = DatePrecision | WeekPrecision;

export type DatePickerFilter = Partial<
  Record<
    Precision,
    (
      val: number,
      extend: {
        date: Date;
      }
    ) => boolean
  >
>;

const precisionLengthRecord: Record<DatePrecision, number> = {
  year: 1,
  month: 2,
  day: 3,
  hour: 4,
  minute: 5,
  second: 6,
};

export const convertDateToStringArray = (
  date: Date | undefined | null,
  precision: Precision
) => {
  if (precision.includes("week")) {
    return weekUtils.convertDateToStringArray(date);
  } else {
    const datePrecision = precision as DatePrecision;
    const stringArray = dateUtils.convertDateToStringArray(date);
    return stringArray.slice(0, precisionLengthRecord[datePrecision]);
  }
};

export const convertStringArrayToDate = (
  value: (string | null | undefined)[],
  precision: Precision
) => {
  // Special case for DATE_NOW
  if (value?.[0] === TILL_NOW) {
    const now: PickerDate = new Date();
    now.tillNow = true;
    return now;
  }

  if (precision.includes("week")) {
    return weekUtils.convertStringArrayToDate(value);
  } else {
    return dateUtils.convertStringArrayToDate(value);
  }
};

export const generateDatePickerColumns = (
  selected: string[],
  min: Date,
  max: Date,
  precision: Precision,
  renderLabel: RenderLabel,
  filter: DatePickerFilter | undefined,
  tillNow?: boolean
) => {
  if (precision.startsWith("week")) {
    return weekUtils.generateDatePickerColumns(
      selected,
      min,
      max,
      precision as WeekPrecision,
      renderLabel,
      filter
    );
  } else {
    return dateUtils.generateDatePickerColumns(
      selected,
      min,
      max,
      precision as DatePrecision,
      renderLabel,
      filter,
      tillNow
    );
  }
};
