export default {
  order_main_product: "Main Product ",
  order_sub_order: "Subs. Standard Order",
  order_final_goods_recipe: "Final Goods Recip.",
  order_po_date: "PO Date",
  order_step: "Step",
  order_step_site_survey_req_sub: "Site Survey Request Submission",
  order_step_site_survey_assign: "Site Survey Assignment",
  order_step_site_survey_implement: "Site Survey Implement",
  order_step_install_req: "Installation Request Submission",
  order_step_install_audit: "Installation Request Audit",
  order_step_install_assign: "Installation Assignment",
  order_step_install_implement: "Installation Implement",
  order_step_trainingAssign: "Application Training Assignment",
  order_step_training_implement: "Application Training Implement",
  order_step_upload_fac: "FAC Upload",
  order_step_update_crm: "IBase Update",
  order_title_detail: "Ticket Details",
  order_info_sale_emp: "Sales Employee",
  order_info_sale_manager: "Sales Manager",
  order_info_service_employee: "Service Employee",
  order_info_incoterms: "Incoterms",
  order_info_sale_team: "Sales Team",
  order_info_customer_type: "Customer Type",
  order_info_region: "Service Region",
  order_info_product_line: "Product Line",
  order_info_service_supervisor: "Service Supervisor",
  order_dn_notice: "Below data can be acquired after DN order is created",
  order_dn_cpo_date: "CPO Date",
  order_dn_install_order_no: "Installation Order No.",
  order_dn_serial_number: "Serial Number",
  order_dn_service_contactor_order: "Service Contract Order No.",
  order_contact_person: "Contact Person",
  order_contact_mobile: "Mobile",
  order_contact_email: "Email",
  order_contact_recipe_city: "Recip. City",
  order_contact_recipe_address: "Recip. Address",
  order_post_code: "Post Code",
  order_sold_to_party: "Sold To Party",
  order_info_more_detail: "More Details",
  order_info_partner_title: "Partner Of The Request",
  order_ap_supervisor: "Ap Supervisor",
  order_sale_manager: "Sales Manager",
  order_ap_engineer: "Ap Engineer",
  order_contact_company_name: "Name",
  order_final_goods_recipe_new: "Final Goods Recip. New",
  order_customer_type_not_industry: "Non-Industry",
  order_customer_type_industry: "Industry",
  order_site_survey_required_yes:
    'If you select "YES", it will be forwarded to FSE team for site survey.',
  order_expected_site_survey_data: "Expected Site Survey Date",
  order_spec: "Specification",
  order_contact_email_optional: "Email (Optional)",
  order_site_survey_required: "Site Survey Required",
  order_site_survey_selected:
    'If you select "YES", it will be forwarded to FSE team for site survey.',
  order_site_survey_not_selected:
    'If you select "NO", please upload a disclaimer.',
  order_site_survey_expected_date: "Expected Site Survey Date",
  order_accessories: "3rd Party & Accessories",
  order_airlock: "Airlock",
  order_mute: "静音模式",
  order_ep: "EP模式（针对EVO机型）",
  order_cryo: "Cryo冷冻传输系统",
  order_laser: "Laser",
  order_3_view: "3View",
  order_cool_table: "冷台（非Cryo）",
  order_cl: "CL",
  order_energy_spectrum: "能谱",
  order_ebsd: "EBSD",
  order_vibration_isolation: "防震台",
  order_magnetic_eraser: "消磁器",
  order_ups: "UPS不间断电源",
  order_other: "其他",
  order_accessory_brand: "品牌及型号",
  order_accessory_computer: "自带电脑、桌子、外置模块",
  order_accessory_install_position: "在样品仓的安装方位（面对主机）",
  order_accessory_need_support: "ZEISS工程师负责安装",
  order_accessory_with_out_control: "自带外置控制模块",
  order_accessory_with_battery_cabinet: "自带外置电池柜及供电时长",
  order_install_pos_left: "Left",
  order_install_pos_right: "Right",
  order_install_pos_not_apply: "Not Apply",
  order_cancel_order_reminder:
    "Cancelling an order will not be able to undo this operation. Are you sure you want to delete it?",
  order_confirm_order_reminder: "Are you sure to submit your request ?",
  order_upload_disclaimer: "Disclaimer",
  order_upload_file_reminder:
    "Only JPG, PNG,  JPEG, PDF, Word and Excel file can be uploaded. You can only upload less than 5 files and each file size can not exceed 50M.",
  order_contact_person_name: "Contact Person Name",
  order_site_survey_qualified: "Site Survey Is Qualified",
  order_site_survey_report: "Site Survey Report",
  order_reject_reason: "Reject Reason",
  order_reject_assignment: "Are you sure to reject the assignment?",
  order_reject_pdh: "Please type reject reason",
  order_site_survey_actual_date: "Actual Site Survey Date",
  order_install_fac_required: "FAC Required",
  order_install_train_required: "Application Training Required",
  order_install_fac_reminder:
    'If you select "YES", you need to upload FAC report; If you select "No", FAC is not required and the installation date will be considered as acceptance date.',
  order_install_training_reminder:
    'If you select "YES", it will be forwarded to application team for application training.',
  order_install_expected_data: "Expected Installation Date",
  order_install_warranty_period: "Warranty Period",
  order_upload_euc_euu: "EUC/EUU (Optional)",
  order_delivery_notes: "Delivery Note",
  order_install_tech_agr: "Technical Agreement (Optional)",
  order_install_specs: "Specification (Optional)",
  order_train_reports: "Training Report",
  order_actual_install_start_date: "Actual Installation Start Date",
  order_actual_install_end_date: "Actual Installation End Date",
  order_install_warranty_period_12_reminder:
    "The warranty period shall be counting from the date on which the acceptance certificate or other document for acceptance has been signed by the representative of seller as well as the representative of the buyer or the end user of the product",
  order_install_warranty_period_15_reminder:
    "The billing date generated by seller's ERP system shall be considered as the foregoing date of delivery",
  order_install_warranty_12_months: "12 months",
  order_install_warranty_delivery_in_15_months:
    "15 months counting from the date of delivery",
  order_so_reject_install: "Are you sure to reject the installation?",
  order_so_confirm_install: "Are you sure to approve installation request?",
  order_service_coordinator: "Service Coordinator",
  order_site_survey_delete: "Are you sure to delete the file?",
  order_need_site_survey: "Need Site Survey",
  order_rsm_audit_need_site_survey_reminder:
    "Are you sure to add the site survey？This operation will make the process back to site survey.",
  order_planed_installation_start_date: "Planned Installation Start Date",
  order_planed_installation_end_date: "Planned Installation End Date",
  order_installation_reports: "Installation Report",
  order_training_report: "Application Training Report",
  order_plan_training_end_time: "Planned Training End Date",
  order_plan_training_start_time: "Planned Training Start Date",
  order_actual_training_end_time: "Actual Training End Date",
  order_actual_training_start_time: "Actual Training Start Date",
  order_file_preview: "Preview Document",
  order_file_download: "Download Document",
  order_list_search_phd: "Enter Order/Final Goods Recip/Product/Contact",
  order_list_search_status_to_do: "Pending",
  order_list_search_status_done: "Processed",
  order_list_search_status_complete: "Completed",
  order_list_search_status_cancel: "Cancelled",
  order_warranty_period_comment_phd:
    "Please fill in the comment, it's mandatory, otherwise the request can not be submitted.",
  order_rsm_need_site_survey_switch_reminder:
    "Please change the option of Site Survey Required to “YES”.",
  order_install_date_reminder:
    "Please check the final acceptance date and warranty period.",
  order_crm_confirm_reminder:
    "Are you sure the data from IBase has been updated?",
  order_crm_date_reminder:
    "Please check the installation date, acceptance date, warranty start/end date and maintain the responsible FSE in the CRM",
  order_fac_installation_date: "Installation Date",
  order_fac_acceptance_date: "Acceptance Date",
  order_warranty_start_date: "Warranty Start Date",
  order_warrant_end_data: "Warranty End Date",
  order_fac_cac_report: "FAC/CAC Report",
  order_disclaimer_notice:
    "Since site survey is not qualified, please upload a disclaimer for it.",
  order_delivery_note_optional: "Delivery Note (Optional)",
  order_comment_toggle_number: "Expand the remaining {value} items",
  order_fse_site_survey: "FSE Site Survey",
  order_fse_installation: "FSE Installation",
  order_upload_limit_reminder:
    "Please select image( type of PNG, JPG, JPEG), PDF, Word, Excel files less than 50M",
  order_dn_block_notice:
    "The DN has not been received for this order and the installation process cannot proceed",
  order_list_unSplit_reminder:
    "Please split this order refer to installation request",
  order_list_unSplit_label: "To-be Splited Order",
  order_split_order_zero_reminder: "Are you sure to delete this split order ?",
  order_dn_delivery: "Is DN Generated",
  order_split_device: "Please enter the quantity",
  order_reminder_split_order:
    "Please split this order to multi site survey requests according to your need (If not required, you can submit directly); All the main equipments will be allocated to each site survey request automatically refer to what you enter into the below function.",
  order_linker_sub_order:
    "Please check other orders related to the same sales order",
  order_list_search_status_all: "All",
  order_owner: "Owner",
  order_operation: "Operation",
  order_list_table_view: "View",
  order_rsm_site_survey: "Service Supervisor Site Survey",
  order_rsm_shorter: "Service Supervisor",
  order_rsm_installation: "Service Supervisor Installation",
  order_rsm_owner: "Responsible Service Supervisor",
  order_install_fac_uploader: "FAC Upload Owner",
  order_device_name: "Device Name",
  order_after_sales: "After Sale",
  order_select_phd: "Please Select",
  order_iBase_number: "IBase Number",
  order_verify_contact_person: "Please type contact person  correctly.",
  order_order_number: "Order Number",
  order_comment_employee_quite: "Employee has left",
  order_material_no: "Material No.",
  order_audit_email: "Approval Email (Optional)",
  order_audit_email_reminder:
    "If the end user is changed, please be sure to upload the approval email here",
  order_owner_installation_fse: "Responsible FSE",
  order_disclaimer_not_site_survey:
    "Since site survey is not required, please upload a disclaimer for it",
  order_material_name: "Material Name",
  order_preview_failed: "Preview file failed, please try to downloaded open",
  order_contact_person_reminder:
    "Pls revise it as contact person of end user. Chinese name and mobile phone number are mandatory.",
  order_list_reset_ticket:
    "Are you sure you want to reset the order? After the reset, the order will be restored to its original state.",
};
