export enum SystemPlatform {
  ENV_MOBILE = "mobile",
  ENV_PC = "pc",
}

export enum SystemOs {
  OS_ANDROID = "android",
  OS_IOS = "ios",
  OS_WINDOWS = "windows",
}

export enum SystemEnv {
  ENV_DEV = "dev",
  ENV_TEST = "test",
  ENV_PROD = "",
  ENV_LOCAL = "localhost",
}

export default class SystemService {
  static readonly PROJECT_KEY = "rms";
  static readonly PLATFORM_MOBILE_MAX_SIZE = 414;
  static readonly PLATFORM_PC_MIN_SIZE = 1440;

  static getEnv = () => {
    const { hostname } = window.location;
    const envMap = [
      {
        env: SystemEnv.ENV_LOCAL,
        isEnv: () => hostname?.includes(SystemEnv.ENV_LOCAL),
      },
      {
        env: SystemEnv.ENV_DEV,
        isEnv: () => hostname?.includes(SystemEnv.ENV_DEV),
      },
      {
        env: SystemEnv.ENV_TEST,
        isEnv: () => hostname?.includes(SystemEnv.ENV_TEST),
      },
    ];

    return envMap.find(({ isEnv }) => isEnv())?.env ?? SystemEnv.ENV_PROD;
  };

  static getPlatform = (): SystemPlatform => {
    const { userAgent } = window?.navigator ?? {};
    const mobileTag = [
      "Android",
      "webOS",
      "iPhone",
      "iPad",
      "iPod",
      "BlackBerry",
      "IEMobile",
      "Opera Mini",
    ];
    const formatAgent = userAgent?.toLocaleLowerCase();
    const isMobile = mobileTag.find((item) =>
      formatAgent.includes(item.toLocaleLowerCase())
    );

    return isMobile ? SystemPlatform.ENV_MOBILE : SystemPlatform.ENV_PC;
  };

  static getMobileOs = (): SystemOs | undefined => {
    const { userAgent } = window?.navigator ?? {};
    const androidTag = ["Android"],
      iosTag = ["iPhone", "iPad"];

    const formatAgent = userAgent?.toLocaleLowerCase();
    const isAndroid = androidTag.some((item) =>
      formatAgent.includes(item.toLocaleLowerCase())
    );
    const isIos = iosTag.some((item) =>
      formatAgent.includes(item.toLocaleLowerCase())
    );

    if (isAndroid) return SystemOs.OS_ANDROID;
    if (isIos) return SystemOs.OS_IOS;
    return undefined;
  };

  static getViewPlatform = (): SystemPlatform => {
    const { clientWidth } = document?.documentElement ?? {};
    const systemPlatform = SystemService.getPlatform();
    if (systemPlatform === SystemPlatform.ENV_MOBILE)
      return SystemPlatform.ENV_MOBILE;
    return clientWidth < SystemService.PLATFORM_MOBILE_MAX_SIZE
      ? SystemPlatform.ENV_MOBILE
      : SystemPlatform.ENV_PC;
  };
}
