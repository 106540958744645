import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "55",
    height = size === "auto" ? "100%" : "54";

  return (
    <svg width={width} height={height} viewBox="0 0 55 54" fill="none">
      <rect x="1" y="0.5" width="53" height="53" fill="#EDF2F7" />
      <g clipPath="url(#clip0_444_23968)">
        <path
          d="M34.2446 27.7553L34.2446 26.2446L28.2554 26.2446V20.2554H26.7446V26.2446L20.7554 26.2446L20.7554 27.7553H26.7446L26.7446 33.7446H28.2554L28.2554 27.7553H34.2446Z"
          fill="#828D9E"
          stroke="#828D9E"
          strokeWidth="0.3"
        />
      </g>
      <rect
        x="1"
        y="0.5"
        width="53"
        height="53"
        stroke="#A5B1C2"
        strokeDasharray="6 3"
      />
      <defs>
        <clipPath id="clip0_444_23968">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(18.5 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
