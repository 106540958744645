import React from "react";
import { useRecoilValue } from "recoil";
import { stepState, userState } from "../../../store";
import { isNumber } from "../../../utils/isType";
import { OrderStep } from "../../service/OrderStepTypes";
import { PageSubItems } from "../../service/pageType";
import StepPageModel from "../model/StepPageModel";
import InfoSales from "./InfoSales/InfoSales";
import SplitOrder from "./SplitOrder/SplitOrder";

export default function () {
  const { usersRole } = useRecoilValue(userState);
  const { key } = useRecoilValue(stepState);
  const stepNode = StepPageModel.getStepPageByTab(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
    key,
    usersRole
  );

  return React.useMemo(() => {
    const viewMap = new Map<OrderStep, React.ReactNode>([
      [OrderStep.SPLIT_ORDER, <SplitOrder key={OrderStep.SPLIT_ORDER} />],
      [
        OrderStep.SITE_SURVEY_SUBMIT,
        <InfoSales key={OrderStep.SITE_SURVEY_SUBMIT} />,
      ],
    ]);

    if (!isNumber(key)) return <></>;

    return (viewMap.get(key) ?? stepNode) as React.ReactElement;
  }, [key, stepNode]);
}
