import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { ApEngineerAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationAll.module.scss";
import InstallationAllModel from "./InstallationAllModel";
import useInstallationAll from "./useInstallationAll";

const InstallationAllMobile: React.FC = ErrorDetectorPageView(() => {
  const { infos, platform, systemOrgs, locale, backAllTickHandler } =
    useInstallationAll();

  const installationInfoView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationAllModel.getInfoReadonlyViewModel(
      infos as Partial<IInstallation>,
      systemOrgs,
      platform
    );

    const views = list.map(({ viewType, props, key }) => {
      const propsMaps = new Map([
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installationInfoView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, systemOrgs, locale]);

  const trainingInfoView = React.useMemo(() => {
    const list = InstallationAllModel.getTimeReadonlyViewModel(
      infos as Partial<IInstallation>
    );
    const views = list.map(({ viewType, props, key }) => {
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={props} />
      );
    });

    if (!list.length) return null;

    return <div className={styles.trainingInfoView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, locale]);

  const filesView = React.useMemo(() => {
    const list = InstallationAllModel.getFileInfoViewModel(
      infos as IInstallation
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, locale]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationAllModel.getButtonViewModel();
    const actionHandler = new Map<ApEngineerAction, Record<string, any>>([
      [ApEngineerAction.ALL_TICKET, { onClick: backAllTickHandler }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <section className={styles.installationAll}>
      <div className={styles.pageContainer}>
        {installationInfoView}
        {trainingInfoView}
        <OrderInfoDnView {...(infos.dnInfo as IDNInfo)} />
        {filesView}
        <CommentsView />
      </div>
      {buttonsView}
    </section>
  );
});

export default InstallationAllMobile;
