import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import React from "react";
import { bound } from "../utils/bound";
import styles from "./ImageViewer.module.scss";
import { Slide } from "./Slide";

export type SlidesType = {
  images: string[];
  onTap: () => void;
  maxZoom: number;
  defaultIndex: number;
  onIndexChange?: (index: number) => void;
};
export type SlidesRef = {
  swipeTo: (index: number, immediate?: boolean) => void;
};

export const Slides = React.forwardRef<SlidesRef, SlidesType>((props, ref) => {
  // something need to confirm ,why is 16
  const slideWidth = window.innerWidth + 16;
  const [{ x }, api] = useSpring(() => ({
    x: props.defaultIndex * slideWidth,
    config: { tension: 250, clamp: true },
  }));

  const count = props.images.length;

  function swipeTo(index: number, immediate = false) {
    const i = bound(index, 0, count - 1);
    props.onIndexChange?.(i);
    api.start({
      x: i * slideWidth,
      immediate,
    });
  }

  React.useImperativeHandle(ref, () => ({
    swipeTo,
  }));

  const dragLockRef = React.useRef(false);
  const bind = useDrag(
    (state) => {
      if (dragLockRef.current) return;
      const [offsetX] = state.offset;
      if (state.last) {
        const minIndex = Math.floor(offsetX / slideWidth);
        const maxIndex = minIndex + 1;
        const velocityOffset =
          Math.min(state.velocity[0] * 2000, slideWidth) * state.direction[0];
        swipeTo(
          bound(
            Math.round((offsetX + velocityOffset) / slideWidth),
            minIndex,
            maxIndex
          )
        );
      } else {
        api.start({
          x: offsetX,
          immediate: true,
        });
      }
    },
    {
      transform: ([x, y]) => [-x, y],
      from: () => [x.get(), 0],
      bounds: () => {
        return {
          left: 0,
          right: (count - 1) * slideWidth,
        };
      },
      rubberband: true,
      axis: "x",
      pointer: { touch: true },
    }
  );

  return (
    <div className={styles["imageViewer-slides"]} {...bind()}>
      <animated.div className={styles["imageViewer-indicator"]}>
        {x.to((v) => {
          const index: number = bound(Math.round(v / slideWidth), 0, count - 1);
          return `${index + 1} / ${count}`;
        })}
      </animated.div>
      <animated.div
        className={styles["imageViewer-slides-inner"]}
        style={{ x: x.to((x) => -x) }}
      >
        {props.images.map((image, index) => (
          <Slide
            key={index}
            image={image}
            onTap={props.onTap}
            maxZoom={props.maxZoom}
            onZoomChange={(zoom) => {
              if (zoom !== 1) {
                const index: number = Math.round(x.get() / slideWidth);
                api.start({
                  x: index * slideWidth,
                });
              }
            }}
            dragLockRef={dragLockRef}
          />
        ))}
      </animated.div>
    </div>
  );
});
