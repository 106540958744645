import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "36",
    height = size === "auto" ? "100%" : "49";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 1.00812H25.7929L35.5 10.7152V48.0081H0.5V1.00812Z"
        fill="white"
        stroke="#E6EBF0"
      />
      <path d="M26 10.5081V0.508118L36 10.5081H26Z" fill="#F2F5F8" />
    </svg>
  );
}
