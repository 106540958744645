import { IUserInfo } from "../../../services/user.service";
import { OrderStep, OrderStepStatus } from "../OrderStepTypes";
import { OrderRequestPage, PageSubItems } from "../pageType";
import { Role } from "../userRoleTypes";
import AdminStatePageService from "./AdminStepPageService";
import ApEngineerStatePageService from "./ApEngineerStepPageService";
import ApManagerStepPageService from "./ApManagerStepPageService";
import ApSupervisorStatePageService from "./ApSupervisorStepPageService";
import FseStatePageService from "./FseStepPageService";
import RsmManagerStepPageService from "./RsmManagerStepPageService";
import RsmStatePageService from "./RsmStepPageService";
import SaleManagerStatePageService from "./SaleManagerStepPageService";
import SaleStatePageService from "./SaleStepPageService";
import SoStatePageService from "./SoStepPageService";

export interface UserStep {
  key: OrderStep;
  status: OrderStepStatus;
  activeUserId?: string;
  role: Role;
  userNameCN?: string;
  userNameEN?: string;
}
export interface OrderStepSummary {
  userSteps?: UserStep[];
  key: OrderStep;
  status: OrderStepStatus;
  activeUserId: string;
  reflow: boolean;
  facRequired: boolean;
  siteSurveyRequired: boolean;
  trainingRequired: boolean;
  productLine: number;
  userNameCN?: string;
  userNameEN?: string;
  involvedUsers?: { role: Role; userId: string }[];
}

export abstract class RoleStepPageService {
  static getRolePageListByStep: (step: OrderStepSummary) => OrderRequestPage;
}

export const getOrderRequestSubTabs = (
  role: Role,
  step: OrderStepSummary
): OrderRequestPage => {
  const roleMaps = new Map<Role, any>([
    [Role.ROLE_SALES_MANAGER, SaleManagerStatePageService],
    [Role.ROLE_SALES, SaleStatePageService],
    [Role.ROLE_RSM, RsmStatePageService],
    [Role.ROLE_RSM_MANAGER, RsmManagerStepPageService],
    [Role.ROLE_FSE, FseStatePageService],
    [Role.ROLE_SO, SoStatePageService],
    [Role.ROLE_AP_MANAGER, ApManagerStepPageService],
    [Role.ROLE_APPLY_SUPERVISOR, ApSupervisorStatePageService],
    [Role.ROLE_APPLY_ENGINEER, ApEngineerStatePageService],
    [Role.ROLE_ADMIN, AdminStatePageService],
  ]);
  const { key } = step;
  const service = roleMaps.get(role) ?? SaleManagerStatePageService;
  const rolePageList = service?.getRolePageListByStep(step) ?? [];
  const filterPageList = filterRolFlowList(rolePageList, step);
  return (
    filterPageList.find(({ state }) => state === key) ??
    ({} as OrderRequestPage)
  );
};

const filterRolFlowList = (
  roleFlowList: OrderRequestPage[],
  step: OrderStepSummary
): OrderRequestPage[] => {
  const { facRequired, trainingRequired, siteSurveyRequired } = step;
  const siteSurveySteps = !siteSurveyRequired
      ? [
          OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
          OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
        ]
      : [],
    facRequiredSteps = !facRequired ? [OrderStep.FAC_UPLOAD] : [],
    trainingRequiredSteps = !trainingRequired
      ? [OrderStep.TRAINING_ASSIGN, OrderStep.TRAINING_IMPLEMENT]
      : [];
  const combineSteps = [
    ...siteSurveySteps,
    ...facRequiredSteps,
    ...trainingRequiredSteps,
  ];

  const filterTab = !siteSurveyRequired
    ? [PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY]
    : [];

  const filterStep = roleFlowList.filter(
    ({ state }) => !combineSteps.includes(state)
  );

  return filterStep.map((item) => ({
    ...item,
    subTabs: item.subTabs.filter((tab) => !filterTab.includes(tab.subItem)),
  }));
};

export const disposeOrderStepSummary = (
  summary: OrderStepSummary,
  userInfo: IUserInfo
): OrderStepSummary => {
  const { userSteps } = summary ?? {};
  const { id, usersRole } = userInfo ?? {};
  if (!Array.isArray(userSteps) || !userSteps?.length || !id)
    return {} as OrderStepSummary;
  const { length } = userSteps;
  if (length === 1) {
    const { key, status, activeUserId, userNameCN, userNameEN } =
      userSteps[0] as UserStep;
    return {
      ...summary,
      key,
      status,
      activeUserId: activeUserId as string,
      userNameCN,
      userNameEN,
    };
  } else {
    let userStep = {} as UserStep;
    const findActiveUserStep = userSteps.find(
      ({ activeUserId, role }) => role === usersRole && activeUserId === id
    );
    const findRoleUserStep = userSteps.find(({ role }) => role === usersRole);
    const defaultUserStep = userSteps.find(({ key }) =>
      [
        OrderStep.TRAINING_ASSIGN,
        OrderStep.TRAINING_IMPLEMENT,
        OrderStep.AUDIT,
        OrderStep.SO_CONFIRM,
      ].includes(key)
    );
    if (findActiveUserStep) {
      userStep = findActiveUserStep;
    } else {
      userStep = findRoleUserStep
        ? findRoleUserStep
        : defaultUserStep ?? ({} as UserStep);
    }
    const { key, status, activeUserId, userNameCN, userNameEN } = userStep;
    return {
      ...summary,
      key,
      status,
      activeUserId: activeUserId as string,
      userNameCN,
      userNameEN,
    };
  }
};
