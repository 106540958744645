import { crumbNode } from "../../../components/BreadCrumb/BreadCrumb";
import { SideNavigationCategoryProps } from "../../../components/SideNavigation/SideNavigationCategory";
import PermissionService from "../../../core/PermissionService";
import RouterService, { PageModule } from "../../../core/RouterService";
import { PagePaths } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import HomeModel from "../HomeModel";

export default class HomePcModel extends HomeModel {
  static DASHBOARD_OUT_LINK =
    "https://app.powerbi.com/groups/me/reports/75d54f01-d1e9-482e-9844-cd5179596e89/ReportSectionec07ed7ce39636e7ee5b?experience=power-bi";

  static getPagePath = (): {
    pathname: PagePaths;
    search?: string;
  } => {
    const { pathname, search } = new URL(window.location.href);
    const conditions = [process.env.PUBLIC_URL, "index.html"].map((item) =>
      item.replace("/", "")
    );
    const pathnames = pathname
      ?.split("/")
      .filter((path) => path && !conditions.includes(path));
    const _pathname =
      pathnames?.length === 1 ? `/${pathnames[0]}` : PagePaths.PATH_ANY;

    return {
      pathname: _pathname as PagePaths,
      search,
    };
  };

  static getSideNavGroups = (
    role: Role
  ): Array<SideNavigationCategoryProps[]> => {
    const { orderList, dashboard, orderManage } = HomeModel.getLabels();
    const groups = [
      {
        path: PagePaths.PATH_ORDER_LIST,
        icon: "Receipt",
        title: orderList,
        permission: PermissionService.checkPagePermission(
          role,
          PagePaths.PATH_ORDER_LIST
        ),
      },
      {
        path: PagePaths.PATH_ADMIN,
        icon: "Dashboard",
        title: orderManage,
        permission: PermissionService.checkPagePermission(
          role,
          PagePaths.PATH_ADMIN
        ),
      },
      {
        path: PagePaths.PATH_DASHBOARD,
        icon: "Dashboard",
        title: dashboard,
        permission: PermissionService.checkPagePermission(
          role,
          PagePaths.PATH_DASHBOARD
        ),
      },
    ];

    const _groups = groups.filter(({ permission }) => permission);

    return [_groups as SideNavigationCategoryProps[]];
  };

  static getRouteBreadCrumb = (page: PagePaths, role: Role): crumbNode[] => {
    if (!RouterService.ROUTE_PAGES_PC.includes(page)) return [];
    const { orderList, dashboard, orderDetail, orderManage } =
      HomeModel.getLabels();
    const routeBreadCrumbConfigs = [
      {
        module: PageModule.ORDER,
        pages: [PagePaths.PATH_ORDER_LIST, PagePaths.PATH_ORDER_REQUEST],
        breadCrumb: [
          {
            value: PagePaths.PATH_ORDER_LIST,
            text: orderList,
          },
          {
            value: PagePaths.PATH_ORDER_REQUEST,
            text: orderDetail,
          },
        ],
      },
      {
        module: PageModule.ADMIN,
        pages: [PagePaths.PATH_ADMIN, PagePaths.PATH_ORDER_REQUEST],
        breadCrumb: [
          {
            value: PagePaths.PATH_ADMIN,
            text: orderManage,
          },
          {
            value: PagePaths.PATH_ORDER_REQUEST,
            text: orderDetail,
          },
        ],
      },
      {
        module: PageModule.DASHBOARD,
        pages: [PagePaths.PATH_DASHBOARD],
        breadCrumb: [
          {
            value: PagePaths.PATH_DASHBOARD,
            text: dashboard,
          },
        ],
      },
    ];

    // const _list = routeBreadCrumbConfigs.filter(({ module }) =>
    //   role === Role.ROLE_ADMIN
    //     ? module !== PageModule.ORDER
    //     : module !== PageModule.ADMIN
    // );

    return (
      routeBreadCrumbConfigs.find(({ pages }) => pages.includes(page))
        ?.breadCrumb ?? []
    );
  };
}
