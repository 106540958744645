import { attachPropertiesToComponent } from "../utils/attachPropertiesToComponent";
import { ImageViewer, MultiImageViewer } from "./ImageViewer";
import {
  clearImageViewer,
  showImageViewer,
  showMultiImageViewer,
} from "./Methods";

export type {
  ImageProps,
  ImageViewerProps,
  MultiImageViewerProps,
  MultiImageViewerRef,
} from "./ImageViewer";
export type { ImageViewerShowHandler } from "./Methods";

const Multi = attachPropertiesToComponent(MultiImageViewer, {
  show: showMultiImageViewer,
});

export default attachPropertiesToComponent(ImageViewer, {
  Multi,
  show: showImageViewer,
  clear: clearImageViewer,
});
