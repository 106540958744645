import { Button, ButtonProps } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { isFunction, isNumber } from "../../../utils/isType";
import styles from "./ButtonMoreVariant.module.scss";

interface IButtonMoreVariant extends ButtonProps {
  actions: { value: any; text: string }[];
  visible?: boolean;
  onSelect?: (value: any) => void;
}

const ButtonMoreVariant: React.FC<IButtonMoreVariant> = ErrorDetectorSmallView(
  (props: IButtonMoreVariant) => {
    const {
      onClick,
      onSelect,
      visible,
      actions,
      children,
      variant = "secondary",
      className,
      ...rest
    } = props;
    const [open, setStatus] = React.useState(() => visible);
    const [actionsTop, setTop] = React.useState(0);
    const cls = classNames(styles.btn, className);

    React.useEffect(() => setStatus(visible), [visible]);

    const selectHandler = React.useCallback(
      (value: any) => isFunction(onSelect) && onSelect(value),
      []
    );

    const refHandler = React.useCallback((domRef: HTMLDivElement) => {
      const { clientHeight } = domRef ?? {};
      const gap = 12,
        defaultTop = 0;
      if (!isNumber(clientHeight)) return;
      const top = clientHeight ? clientHeight + gap : defaultTop;
      setTop(top);
    }, []);

    const clickHandler = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setStatus(!open);
        isFunction(onClick) && onClick(event);
      },
      [open]
    );

    const actionView = React.useMemo(
      () =>
        open && (
          <div
            className={styles.actions}
            ref={refHandler}
            style={{ top: `-${actionsTop}px` }}
          >
            <span className={styles.inner} />
            {actions?.map(({ value, text }) => (
              <div
                className={styles.actionItem}
                key={text}
                onClick={selectHandler.bind(null, value)}
              >
                {text}
              </div>
            ))}
          </div>
        ),
      [actions, open, actionsTop]
    );

    return (
      <Button
        icon={"BurgerMenu"}
        iconPosition="left"
        variant={variant}
        className={cls}
        {...rest}
        onClick={clickHandler}
        onBlur={() => setStatus(false)}
      >
        {children}
        {actionView}
      </Button>
    );
  }
);

export default ButtonMoreVariant;
