import { Button, Icon } from "@zeiss/ods-components-react";
import React from "react";
import {
  ConfirmVariant,
  IConfirmVariantProps,
} from "../../../components/Confirmor/ConfirmVariant/ConfirmVariant";
import { ErrorDetectorModalView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { InputBasic } from "../../../components/Input";
import Correct from "../../../components/svg/Correct";
import styles from "./SearchSelector.module.scss";
import useSearchSelector, {
  ConfirmType,
  SearchOption,
} from "./useSearchSelector";

export interface ISearchSelector extends Omit<IConfirmVariantProps, "onClose"> {
  disabled?: boolean;
  showConfirm?: boolean;
  options: SearchOption[];
  value?: any;
  onClose?: (type: ConfirmType, value?: any) => void;
  search?: boolean;
  buttonDisabled?: boolean;
}

const SearchSelectorPc: React.FC<ISearchSelector> = ErrorDetectorModalView(
  (props: ISearchSelector) => {
    const {
      showConfirm,
      search = true,
      className,
      buttonDisabled,
      onClose,
      ...rest
    } = props;

    const {
      stateOptions,
      stateValue,
      labelCancel,
      labelConfirm,
      changeHandler,
      clearHandler,
      searchHandler,
      confirmHandler,
    } = useSearchSelector(props);

    const searchIcon = React.useMemo(
      () =>
        search ? (
          <Icon icon={"Search"} size={16} className={styles.search} />
        ) : undefined,
      [search]
    );
    const clearIcon = React.useMemo(
      () =>
        stateValue ? (
          <span onClick={clearHandler}>
            <Icon icon={"Close"} size={16} className={styles.clear} />
          </span>
        ) : undefined,
      [clearHandler, stateValue]
    );
    const footerView = React.useMemo(() => {
      const disabled = !stateOptions.find((item) => item?.text === stateValue);
      return (
        <div className={styles.confirmPc}>
          <Button
            variant={"secondary"}
            onClick={confirmHandler.bind(null, ConfirmType.cancel)}
          >
            {labelCancel}
          </Button>
          <Button
            variant={"primary"}
            onClick={confirmHandler.bind(null, ConfirmType.ok)}
            disabled={disabled || buttonDisabled}
          >
            {labelConfirm}
          </Button>
        </div>
      );
    }, [
      stateOptions,
      confirmHandler,
      labelCancel,
      buttonDisabled,
      labelConfirm,
      stateValue,
    ]);

    const optionsView = React.useMemo(() => {
      return (
        <div className={styles.optionsList}>
          {stateOptions?.map(({ text, value, subText }) => {
            const itemView =
              typeof text === "string" ? (
                <div
                  className={styles.itemView}
                  onClick={changeHandler.bind(null, value)}
                >
                  <div>{text}</div>
                  <div>{subText}</div>
                </div>
              ) : (
                text
              );

            return (
              <div className={styles.optionItem} key={value}>
                {itemView}
                {text === stateValue && (
                  <span className={styles.icon}>
                    <Correct />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    }, [stateOptions, stateValue, changeHandler]);

    const contentView = React.useMemo(
      () => (
        <>
          <InputBasic
            value={stateValue}
            readOnly={!search}
            placeholder={"Search"}
            onChange={searchHandler}
            addonBefore={searchIcon}
            addonAfter={clearIcon}
            className={styles.pcSearchContent}
          />
          <div className={styles.pcOptions}>{optionsView}</div>
        </>
      ),
      [clearIcon, optionsView, search, searchIcon, stateValue, searchHandler]
    );

    return (
      <ConfirmVariant
        {...rest}
        footer={footerView}
        content={contentView}
        onConfirm={(type) => confirmHandler(type as ConfirmType)}
        destroyOnClose={false}
      />
    );
  }
);

export default SearchSelectorPc;
