import React from "react";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PageError } from "../core/ErrorCodeService";
import PermissionService from "../core/PermissionService";
import RouterService from "../core/RouterService";
import { SystemPlatform } from "../core/SystemService";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Home from "../pages/Home/Home";
import OrderList from "../pages/OrderList/OrderList";
import OrderListPc, {
  PcListViewType,
} from "../pages/OrderList/OrderListPc/OrderListPc";
import OrderRequest from "../pages/OrderRequest/OrderRequest";
import { PagePaths } from "../pages/service/pageType";
import { platformState, userState } from "../store";
import usePlatform from "./usePlatform";

//lazy import module， your may need to lazy load your feature page.
// const OrderList = React.lazy(() => import("../pages/OrderList/OrderList"));
// const OrderRequest = React.lazy(
//   () => import("../pages/OrderRequest/OrderRequest")
// );
export const routerConfigs: RouteObject[] = [
  {
    path: PagePaths.PATH_HOME,
    element: <Home />,
    children: [
      {
        path: PagePaths.PATH_ORDER_LIST,
        element: <OrderList />,
      },
      {
        path: PagePaths.PATH_ORDER_REQUEST,
        element: <OrderRequest />,
      },
      {
        path: PagePaths.PATH_DASHBOARD,
        element: <></>,
      },
      {
        path: PagePaths.PATH_ADMIN,
        element: <OrderListPc viewType={PcListViewType.Admin} />,
      },
      {
        path: PagePaths.PATH_ERROR_PAGE,
        element: <ErrorPage />,
      },
      {
        path: PagePaths.PATH_ANY,
        element: <ErrorPage pageCode={PageError.ERROR_404} />,
      },
    ],
  },
];

export default function () {
  const platform = useRecoilValue(platformState);
  React.useRef(usePlatform());
  const { usersRole } = useRecoilValue(userState);
  const routerConfig: RouteObject = routerConfigs?.[0] ?? {};
  const rolePageIndexPath = RouterService.getRouterIndexPathByRole(usersRole);
  const routerChildrenPaths = routerConfig?.children ?? [];

  const indexItem = routerChildrenPaths.find(
    ({ path }) => path === rolePageIndexPath
  ) ?? {
    path: PagePaths.PATH_ANY,
    element: <ErrorPage pageCode={PageError.ERROR_404} />,
  };

  const _indexItem = {
    index: true,
    element: indexItem?.element,
  } as RouteObject;
  const _routerChildrenPaths = [_indexItem, ...routerChildrenPaths];

  const mobileConfigs = _routerChildrenPaths.filter(({ path, index }) => {
    const hasPagePermission = PermissionService.checkPagePermission(
      usersRole,
      path as PagePaths
    );
    const inPages = RouterService.ROUTE_PAGES_MOBILE.includes(
      path as PagePaths
    );

    return (inPages && hasPagePermission) || index;
  });

  const pcConfigs = _routerChildrenPaths.filter(({ path, index }) => {
    const hasPagePermission = PermissionService.checkPagePermission(
      usersRole,
      path as PagePaths
    );
    const inPages = RouterService.ROUTE_PAGES_PC.includes(path as PagePaths);

    return (inPages && hasPagePermission) || index;
  });

  const _configs =
    platform === SystemPlatform.ENV_MOBILE ? mobileConfigs : pcConfigs;
  const _routerConfigs = [{ ...routerConfig, children: _configs }];

  const router = createBrowserRouter(_routerConfigs as RouteObject[], {
    basename: process.env.PUBLIC_URL,
  });

  return <RouterProvider router={router} />;
}
