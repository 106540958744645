import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../services/user.service";
import { formatDate } from "../../../../utils/commonUtils";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import TextVariantPc from "../../../components/TextVariantPc/TextVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { Role, RsmAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import styles from "./SiteSurveyRsm.module.scss";
import SiteSurveyRsmModel from "./SiteSurveyRsmModel";
import useSiteSurveyRsm from "./useSiteSurveyRsm";

const SiteSurveyRsmPc: React.FC = ErrorDetectorPageView(() => {
  const {
    preInstallInfo,
    orgs,
    rolePage,
    searchVisible,
    searchType,
    userId,
    expectedDate,
    platform,
    show3rdParty,
    locale,
    setVisible,
    backToAllTicket,
    assignAndForwardHandler,
    assignOrForwardHandler,
  } = useSiteSurveyRsm();

  const contactPcView = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getContactInfoModel(
      preInstallInfo,
      platform
    );

    return (
      <div className={styles.contactPcView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;
          const component = (
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...newProps,
                key,
              }}
            />
          );
          return (
            <div key={key} className={styles.pcTextItem}>
              {component}
            </div>
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preInstallInfo, platform, locale]);

  const servicePersonView = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getOrgInfoModel(
      preInstallInfo?.partnerTeamOtherInfo,
      orgs,
      platform
    );
    const { expectedStartDate } = preInstallInfo ?? {};
    const dateStr = formatDate(expectedStartDate);
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.pcTextItem,
          }}
        />
      );
      return component;
    });

    return (
      <div className={styles.servicePersonView}>
        {orgView}
        <TextVariantPc label={expectedDate}>{dateStr}</TextVariantPc>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preInstallInfo, orgs, expectedDate, platform, locale]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
      [
        RsmAction.FORWARD,
        { onClick: assignOrForwardHandler.bind(null, Action.FORWARD) },
      ],
      [
        RsmAction.ASSIGN,
        { onClick: assignOrForwardHandler.bind(null, Action.ASSIGN) },
      ],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.pcButtons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale, assignOrForwardHandler, backToAllTicket]);

  return (
    <div className={styles.siteSurveyRsmPc}>
      <div className={styles.pageContent}>
        {contactPcView}
        {servicePersonView}
        {show3rdParty && (
          <div className={styles.thirdPartyView}>
            <SiteSurveyAccessory
              accessories={(preInstallInfo?.thirdParty as any) ?? {}}
            />
          </div>
        )}
      </div>
      {buttonViews}
      {searchVisible && (
        <AssignOrForwardUser
          visible={searchVisible}
          onClose={(visible) => setVisible(visible)}
          onActionResponse={assignAndForwardHandler}
          action={searchType as Action}
          feature={Feature.SITE_SURVEY}
          role={searchType === Action.FORWARD ? Role.ROLE_RSM : Role.ROLE_FSE}
          searchQuery={{ rsmUserId: userId }}
          actionData={{
            selectUserIdKey:
              searchType === Action.FORWARD
                ? "rsmRBACCorrelationId"
                : "fseRBACCorrelationId",
          }}
        />
      )}
    </div>
  );
});

export default SiteSurveyRsmPc;
