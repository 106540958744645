import React from "react";
import { formatDate } from "../../../../utils/commonUtils";
import StepVariant, {
  IStepVariant,
} from "../../../components/StepVariant/StepVariant";
import { IStepItemProcess } from "../StepVariantProcess/StepVariantProcess";
import styles from "./../StepVariantProcess/StepVariantProcess.module.scss";

export interface IStepVariantComments extends Omit<IStepVariant, "steps"> {
  steps: IStepItemProcess[];
}

const StepVariantComments: React.FC<IStepVariantComments> = (
  props: IStepVariantComments
) => {
  const { steps, ...rest } = props;
  const mappingSteps = React.useMemo(() => {
    return steps?.map(({ status, author, comment, time, ...rest }, key) => {
      const timeLabel = formatDate(time as number);
      return {
        ...rest,
        subChildren: (
          <div className={styles.subChildren} key={key}>
            {author && (
              <div className={styles.author}>
                <span className={styles.itemLabel}>{author}</span>
              </div>
            )}
            {comment && (
              <div className={styles.action}>
                <span className={styles.itemValue}>{comment}</span>
              </div>
            )}
            {time && <div className={styles.time}>{timeLabel}</div>}
          </div>
        ),
      };
    });
  }, [steps]);

  return <StepVariant {...rest} steps={mappingSteps} />;
};

export default StepVariantComments;
