import {
  Button,
  RadioButtonsGroup,
  TextArea,
  Toggle,
} from "@zeiss/ods-components-react";
import { useRecoilValue } from "recoil";
import CheckBoxWrapper from "../../components/CheckBoxWrapper/CheckBoxWrapper";
import { InputVariantDatePicker } from "../../components/Input/InputVariantDatePicker/InputVariantDatePicker";
import { InputVariantDropDown } from "../../components/Input/InputVariantDropDown/InputVariantDropDown";
import PhoneCall from "../../components/PhoneCall/PhoneCall";
import { Upload } from "../../components/Upload/Upload";
import FileUpload from "../../components/svg/FileUpload";
import { SystemPlatform } from "../../core/SystemService";
import { platformState } from "../../store";
import ButtonMoreVariant from "./ButtonMoreVariant/ButtonMoreVariant";
import DatePickerVariant from "./DatePickerVariant/DatePickerVariant";
import FileUploadVariant from "./FileUploadVariant/FileUploadVariant";
import FoldCabinet from "./FoldCabinet/FoldCabinet";
import InputBasicVariant from "./InputBasicVariant/InputBasicVariant";
import InputVariant from "./InputVariant/InputVariant";
import Selector from "./Selector/Selector";
import SwitchCabinet from "./SwitchCabinet/SwitchCabinet";
import Text from "./Text/Text";
import TextVariantPc from "./TextVariantPc/TextVariantPc";
import UploadVariant from "./UploadVariant/UploadVariant";

export enum ViewType {
  VIEW_TEXT = "text",
  VIEW_TEXT_VARIANT_PC = "textVariantPc",
  VIEW_SELECT = "select",
  VIEW_DROP_DOWN_SELECT = "dropDownSelect",
  VIEW_INPUT = "input",
  VIEW_INPUT_BASIC = "inputBasic",
  VIEW_TEXTAREA = "textarea",
  VIEW_RADIO_GROUP = "radioGroup",
  VIEW_CHECKBOX = "checkbox",
  VIEW_SWITCH = "switch",
  VIEW_SWITCH_VARIANT = "switchVariant",
  VIEW_DATE_PICKER = "dataPicker",
  VIEW_DROP_DOWN_DATE_PICKER = "dropDownPicker",
  VIEW_TIME_PICKER = "timePicker",
  VIEW_UPLOAD = "upload",
  VIEW_TEXT_VARIANT_MORE = "textCabinet",
  VIEW_TEXT_VARIANT_MOBILE = "textMobile",
  VIEW_UPLOAD_VARIANT = "uploadVariant",
  VIEW_UPLOAD_STANDARD = "uploadStandard",
  VIEW_UPLOAD_STANDARD_VARIANT = "uploadStandardVariant",
  VIEW_BUTTON = "button",
  VIEW_BUTTON_MORE_VARIANT = "buttonMoreVariant",
  VIEW_CUSTOMIZED = "customized",
}

export interface ViewComponentProps {
  type: ViewType;
  props: any;
}

export const ViewComponent: React.FC<ViewComponentProps> = (
  _props: ViewComponentProps
): React.ReactElement => {
  const { props, type } = _props;
  const platform = useRecoilValue(platformState);

  const viewMap = new Map([
    [ViewType.VIEW_TEXT, <Text key={ViewType.VIEW_TEXT} {...props} />],
    [
      ViewType.VIEW_TEXT_VARIANT_PC,
      <TextVariantPc key={ViewType.VIEW_TEXT_VARIANT_PC} {...props} />,
    ],
    [ViewType.VIEW_SELECT, <Selector key={ViewType.VIEW_SELECT} {...props} />],
    [
      ViewType.VIEW_DROP_DOWN_SELECT,
      <InputVariantDropDown key={ViewType.VIEW_SELECT} {...props} />,
    ],
    [
      ViewType.VIEW_INPUT,
      <InputVariant key={ViewType.VIEW_INPUT} {...props} />,
    ],
    [
      ViewType.VIEW_INPUT_BASIC,
      <InputBasicVariant key={ViewType.VIEW_INPUT_BASIC} {...props} />,
    ],
    [
      ViewType.VIEW_TEXTAREA,
      <TextArea key={ViewType.VIEW_TEXTAREA} {...props} />,
    ],
    [
      ViewType.VIEW_RADIO_GROUP,
      <RadioButtonsGroup key={ViewType.VIEW_RADIO_GROUP} {...props} />,
    ],
    [
      ViewType.VIEW_CHECKBOX,
      <CheckBoxWrapper key={ViewType.VIEW_CHECKBOX} {...props} />,
    ],
    [ViewType.VIEW_SWITCH, <Toggle key={ViewType.VIEW_SWITCH} {...props} />],
    [
      ViewType.VIEW_SWITCH_VARIANT,
      <SwitchCabinet key={ViewType.VIEW_SWITCH_VARIANT} {...props} />,
    ],
    [
      ViewType.VIEW_DATE_PICKER,
      <DatePickerVariant key={ViewType.VIEW_DATE_PICKER} {...props} />,
    ],
    [
      ViewType.VIEW_DROP_DOWN_DATE_PICKER,
      <InputVariantDatePicker
        key={ViewType.VIEW_DROP_DOWN_DATE_PICKER}
        {...props}
      />,
    ],
    [ViewType.VIEW_UPLOAD, <Upload key={ViewType.VIEW_UPLOAD} {...props} />],
    [
      ViewType.VIEW_UPLOAD_VARIANT,
      <UploadVariant key={ViewType.VIEW_UPLOAD_VARIANT} {...props} />,
    ],
    [
      ViewType.VIEW_UPLOAD_STANDARD,
      <FileUpload key={ViewType.VIEW_UPLOAD_STANDARD} {...props} />,
    ],
    [
      ViewType.VIEW_UPLOAD_STANDARD_VARIANT,
      <FileUploadVariant
        key={ViewType.VIEW_UPLOAD_STANDARD_VARIANT}
        {...props}
      />,
    ],
    [
      ViewType.VIEW_TEXT_VARIANT_MORE,
      <FoldCabinet key={ViewType.VIEW_TEXT_VARIANT_MORE} {...props} />,
    ],
    [
      ViewType.VIEW_TEXT_VARIANT_MOBILE,
      <PhoneCall key={ViewType.VIEW_TEXT_VARIANT_MOBILE} {...props} />,
    ],
    [
      ViewType.VIEW_BUTTON,
      <Button key={ViewType.VIEW_TEXT_VARIANT_MOBILE} {...props} />,
    ],
    [
      ViewType.VIEW_BUTTON_MORE_VARIANT,
      <ButtonMoreVariant key={ViewType.VIEW_TEXT_VARIANT_MOBILE} {...props} />,
    ],
  ]);

  const mobileSpecialComponents = [
    ViewType.VIEW_SELECT,
    ViewType.VIEW_DATE_PICKER,
    ViewType.VIEW_UPLOAD,
    ViewType.VIEW_INPUT,
  ];

  const pcSpecialComponents = [
    ViewType.VIEW_TEXT_VARIANT_PC,
    ViewType.VIEW_DROP_DOWN_SELECT,
    ViewType.VIEW_DROP_DOWN_DATE_PICKER,
    ViewType.VIEW_UPLOAD_STANDARD,
    ViewType.VIEW_INPUT_BASIC,
  ];

  const arr = Array.from(viewMap);
  const mobileComponents = arr.filter(
      ([viewType]) => !pcSpecialComponents.includes(viewType)
    ),
    pcComponents = arr.filter(
      ([viewType]) => !mobileSpecialComponents.includes(viewType)
    );
  const components =
    platform === SystemPlatform.ENV_PC ? pcComponents : mobileComponents;
  const _viewMap = new Map(components);

  return _viewMap.get(type) ?? <></>;
};
