import { CheckboxProps } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import Cabinet, { ICabinet } from "../../../components/Cabinet/Cabinet";
import CheckBoxWrapper from "../../../components/CheckBoxWrapper/CheckBoxWrapper";
import { ErrorDetectorMediumView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { isFunction } from "../../../utils/isType";
import styles from "./CheckboxCabinet.module.scss";

interface ICheckboxCabinet
  extends CheckboxProps,
    Omit<ICabinet, "children" | "status"> {
  status?: boolean;
  className?: string;
}

const CheckboxCabinet: React.FC<ICheckboxCabinet> = ErrorDetectorMediumView(
  (props: ICheckboxCabinet) => {
    const {
      children,
      checked,
      onChange,
      className,
      cabinetClassName,
      ...rest
    } = props;
    const [status, setStatus] = React.useState(() => checked);

    React.useEffect(() => setStatus(checked), [checked]);
    const changeHandler = React.useCallback(
      (checked: any) => {
        setStatus(checked);
        isFunction(onChange) && onChange(checked as any);
      },
      [onChange]
    );

    const _className = classNames(styles.checkboxCabinet, className);

    return (
      <section className={_className}>
        <CheckBoxWrapper
          {...rest}
          checked={status}
          onChange={changeHandler}
          className={styles.checkbox}
        />
        <Cabinet status={status as boolean} cabinetClassName={cabinetClassName}>
          {children as React.ReactElement}
        </Cabinet>
      </section>
    );
  }
);

export default CheckboxCabinet;
