import { useRecoilValue } from "recoil";
import { orderRequestTabs, stepState, userState } from "../../store";
import { PageStatus, PageSubItems, SubTabs } from "../service/pageType";

export default function (tab: PageSubItems): SubTabs {
  const tabs = useRecoilValue(orderRequestTabs);
  const { id } = useRecoilValue(userState);
  const { activeUserId, involvedUsers } = useRecoilValue(stepState);
  const isCurrentUser = activeUserId === id,
    isHistoricalUser = involvedUsers?.some(
      ({ userId: _userId }) => _userId === id
    );

  const subTab = tabs?.find(({ subItem }) => subItem === tab);
  if (!subTab) return {} as SubTabs;
  const { pageStatus, actions } = subTab;
  if (pageStatus === PageStatus.READONLY) {
    return subTab;
  }
  const _pageStatus = isCurrentUser ? PageStatus.EDIT : PageStatus.READONLY;

  const _actions =
    _pageStatus === PageStatus.READONLY ? ["allTicket"] : actions;
  return {
    ...subTab,
    pageStatus: _pageStatus,
    actions: _actions,
  };
}
