import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { ErrorRes } from "../../../../core/HttpService";
import { IFseSiteSurveyReq } from "../../../../services/order.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import OrderRequestModel, {
  IPrepareInstallation,
} from "../../model/OrderRequestModel";
import SiteSurveyModel from "../model/SiteSurveyModel";
import SiteSurveyFseModel from "./SiteSurveyFseModel";

export default function () {
  const {
    labelActualSiteSurveyDate,
    siteSurveyDone,
    uploadReminder,
    siteSurveyReport,
    submitOrderReminder,
    cancel,
    confirm,
    rejectAssign,
    labelExpectedDate,
  } = useLang({
    expectedDate: { id: "order_site_survey_actual_date" },
    labelActualSiteSurveyDate: { id: "order_site_survey_actual_date" },
    siteSurveyDone: { id: "order_site_survey_qualified" },
    uploadReminder: { id: "order_upload_file_reminder" },
    siteSurveyReport: { id: "order_site_survey_report" },
    submitOrderReminder: { id: "order_confirm_order_reminder" },
    cancel: { id: "common_btn_cancel" },
    confirm: { id: "common_btn_confirm" },
    rejectAssign: { id: "order_reject_assignment" },
    labelExpectedDate: { id: "order_expected_site_survey_data" },
  });
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [siteSurveyFseInfo, setPreInstallInfo] = React.useState(
    {} as IPrepareInstallation
  );
  const {
    thirdParty,
    actuarySiteSurveyDate,
    isSiteSurveyDone,
    siteSurveyReports,
    expectedStartDate,
  } = siteSurveyFseInfo;
  const { userId } = useRecoilValue(userState) ?? {};
  const [rejectVisible, setReject] = React.useState(false);
  const [submitVisible, setSubmit] = React.useState(false);
  const [submitEnable, setEnable] = React.useState(false);
  const [errCode, setError] = React.useState<ErrorCode>();
  const siteSurveyInfoRef = React.useRef({});
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY);
  const locale = useRecoilValue(localeState);
  const [buttonBlock, setBlock] = React.useState(false);
  const platform = useRecoilValue(platformState);
  const orgs = useRecoilValue(systemUserOrgs);
  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  React.useEffect(() => {
    getPreInstallationInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  React.useEffect(() => {
    siteSurveyInfoRef.current = {
      installationTaskId,
      fseRBACCorrelationId: userId,
      actuarySiteSurveyDate,
      attachments: siteSurveyReports?.map(
        ({ name: fileName, url: fileUrl }) => ({ fileName, fileUrl })
      ),
      isSiteSurveyDone: isSiteSurveyDone ?? false,
    };

    const submitEnable = SiteSurveyFseModel.verifySubmission(
      siteSurveyInfoRef.current,
      [
        SiteSurveyModel.ACTUAL_SITE_SURVEY_DATE,
        SiteSurveyModel.IS_SITE_SURVEY_DONE,
        "installationTaskId",
        "fseRBACCorrelationId" as keyof IPrepareInstallation,
        "attachments" as keyof IPrepareInstallation,
      ]
    );
    setEnable(submitEnable);
  }, [
    actuarySiteSurveyDate,
    isSiteSurveyDone,
    siteSurveyReports,
    installationTaskId,
    userId,
  ]);

  const getPreInstallationInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      OrderRequestModel.getPreInstallInformation(installationTaskId, locale)
        .then((res) => {
          setPreInstallInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const backToAllTicket = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitReport = React.useCallback(() => {
    setLoading(true);
    setBlock(true);
    SiteSurveyFseModel.fseSubmitSiteSurvey(
      siteSurveyInfoRef.current as IFseSiteSurveyReq
    )
      .then(() => {
        EventService.getInstance().emit(
          EventService.EVENT_SUBMIT_STEP_COMMENTS
        );
        backToAllTicket();
      })
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [backToAllTicket, setLoading, toastError]);

  const rejectReq = React.useCallback(
    (reason: string) => {
      setLoading(true);
      setBlock(true);
      SiteSurveyFseModel.fseRejectReason({
        installationTaskId,
        fseRBACCorrelationId: userId,
        reason,
      })
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backToAllTicket();
        })
        .catch((res: ErrorRes) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [installationTaskId, userId, backToAllTicket, setLoading, toastError]
  );

  const changeHandler = React.useCallback(
    (key: keyof IPrepareInstallation, value: any) => {
      setPreInstallInfo((pre) => ({ ...pre, [key]: value }));
    },
    []
  );

  const confirmCloseHandler = React.useCallback(
    (type: CloseType) => {
      setSubmit(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      submitReport();
    },
    [submitReport]
  );

  const rejectHandler = React.useCallback(
    (type: CloseType, reason?: any) => {
      setReject(false);
      if (type === "cancel") return;
      rejectReq(reason as string);
    },
    [rejectReq]
  );

  return {
    submitOrderReminder,
    cancel,
    confirm,
    rejectAssign,
    expectedStartDate,
    actuarySiteSurveyDate,
    rolePage,
    thirdParty,
    labelExpectedDate,
    labelActualSiteSurveyDate,
    siteSurveyDone,
    isSiteSurveyDone,
    siteSurveyReport,
    uploadReminder,
    siteSurveyReports,
    submitEnable,
    rejectVisible,
    submitVisible,
    buttonBlock,
    locale,
    siteSurveyFseInfo,
    platform,
    orgs,
    setReject,
    setSubmit,
    confirmCloseHandler,
    rejectHandler,
    changeHandler,
    backToAllTicket,
  };
}
