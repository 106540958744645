import { isEmpty } from "lodash";
import { formatMessage } from "../../../../core/LangService";
import { isFunction, isObject } from "../../../../utils/isType";
import { IThirdParty, ViewItemModel } from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";

export enum AccessoryTypes {
  AIRLOCK = "airlock",
  MUTE = "mute",
  EP = "ep",
  CRYO = "cryo",
  LASER = "laser",
  VIEW3 = "view3",
  COLD_TABLE = "coldTable",
  CL = "cl",
  ENERGY_SPECTRUM = "energySpectrum",
  EBSD = "ebsd",
  VIBRATION_ISOLATION = "vibrationIsolation",
  DEMAGNETIZER = "demagnetizer",
  UPS = "ups",
  OTHER = "other",
}

export interface AccessoryProperties {
  spec?: string | number;
  brandAndModel: string;
  buildIn?: string;
  installPos?: string | number;
  supportInstall?: string | number;
  withOuterController?: string;
  withOuterBatteryCabinet?: string;
  comments?: string;
}

export enum NeedSupport {
  NO = 1,
  YES = 2,
}

export enum InstallPosition {
  LEFT = 1,
  RIGHT = 2,
  NOT_APPLY = 3,
}

export enum AirLock {
  Eighty = 1,
  tWO_HUNDRED = 2,
}

export default class SiteSurveyModel {
  static readonly SPEC = "spec";
  static readonly BRAND_AND_MODEL = "brandAndModel";
  static readonly BUILD_IN = "buildIn"; // 自带电脑、桌子、外置模块
  static readonly INSTALL_POS = "installPos";
  static readonly SUPPORT_INSTALL = "supportInstall"; // ZEISS工程师负责安装
  static readonly WITH_OUTER_CONTROLLER = "withOuterController"; //自带外置控制模块
  static readonly WITH_OUTER_BATTERY_CABINET = "withOuterBatteryCabinet"; //
  static readonly COMMENTS = "comments";

  static readonly EXPECTED_SITE_SURVEY_START_DATE = "expectedStartDate";
  static readonly EXPECTED_SITE_SURVEY_END_DATE = "expectedEndDate";

  static readonly ACTUAL_SITE_SURVEY_DATE = "actuarySiteSurveyDate";
  static readonly IS_SITE_SURVEY_DONE = "isSiteSurveyDone";
  static readonly SITE_SURVEY_REPORT = "siteSurveyReports";

  static verifyThirdParty = (infos: Partial<IThirdParty>) => {
    if (!isObject(infos) || isEmpty(infos)) return true;
    const entitiesInfos = Object.entries(infos);
    const verifyMap = new Map<AccessoryTypes, boolean>([
      [
        AccessoryTypes.AIRLOCK,
        SiteSurveyModel.verifyAirLock(
          infos[AccessoryTypes.AIRLOCK] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.CRYO,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.CRYO] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.LASER,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.LASER] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.VIEW3,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.VIEW3] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.COLD_TABLE,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.COLD_TABLE] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.CL,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.CL] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.ENERGY_SPECTRUM,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.ENERGY_SPECTRUM] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.EBSD,
        SiteSurveyModel.verifyCryoLike(
          infos[AccessoryTypes.EBSD] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.VIBRATION_ISOLATION,
        SiteSurveyModel.verifyVibrationLike(
          infos[
            AccessoryTypes.VIBRATION_ISOLATION
          ] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.DEMAGNETIZER,
        SiteSurveyModel.verifyVibrationLike(
          infos[AccessoryTypes.DEMAGNETIZER] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.UPS,
        SiteSurveyModel.verifyUPSLike(
          infos[AccessoryTypes.UPS] as Partial<AccessoryProperties>
        ),
      ],
      [
        AccessoryTypes.OTHER,
        SiteSurveyModel.verifyOther(
          infos[AccessoryTypes.OTHER] as Partial<AccessoryProperties>
        ),
      ],
    ]);

    const verifyList = entitiesInfos
      .map(([key]) => verifyMap.get(key as AccessoryTypes))
      .filter((item) => item !== undefined);
    return verifyList.every((item) => item);
  };

  static getAccessoriesLabels = (type: AccessoryTypes): string => {
    const {
      labelAirlock,
      labelMute,
      labelEP,
      labelCryo,
      labelLaser,
      label3View,
      labelCoolTable,
      labelCL,
      labelEnergySpectrum,
      labelEBSD,
      labelVibrationIsolation,
      labelMagneticEraser,
      labelUPS,
      labelOther,
    } = SiteSurveyModel.getLabels();

    const labelMaps = new Map([
      [AccessoryTypes.AIRLOCK, labelAirlock],
      [AccessoryTypes.EP, labelEP],
      [AccessoryTypes.MUTE, labelMute],
      [AccessoryTypes.CL, labelCL],
      [AccessoryTypes.LASER, labelLaser],
      [AccessoryTypes.VIEW3, label3View],
      [AccessoryTypes.CRYO, labelCryo],
      [AccessoryTypes.ENERGY_SPECTRUM, labelEnergySpectrum],
      [AccessoryTypes.EBSD, labelEBSD],
      [AccessoryTypes.COLD_TABLE, labelCoolTable],
      [AccessoryTypes.VIBRATION_ISOLATION, labelVibrationIsolation],
      [AccessoryTypes.DEMAGNETIZER, labelMagneticEraser],
      [AccessoryTypes.UPS, labelUPS],
      [AccessoryTypes.OTHER, labelOther],
    ]);

    return labelMaps.get(type) as string;
  };

  static getAccessoriesItemLabel = (key: string): string => {
    const {
      labelSpec,
      labelBrandAndModel,
      labelWithComputer,
      labelInstallPos,
      labelNeedSupport,
      labelWithOutControl,
      labelWithBatteryCabinet,
    } = SiteSurveyModel.getLabels();

    const labelMaps = new Map([
      [SiteSurveyModel.SPEC, labelSpec],
      [SiteSurveyModel.BRAND_AND_MODEL, labelBrandAndModel],
      [SiteSurveyModel.BUILD_IN, labelWithComputer],
      [SiteSurveyModel.INSTALL_POS, labelInstallPos],
      [SiteSurveyModel.SUPPORT_INSTALL, labelNeedSupport],
      [SiteSurveyModel.WITH_OUTER_CONTROLLER, labelWithOutControl],
      [SiteSurveyModel.WITH_OUTER_CONTROLLER, labelWithOutControl],
      [SiteSurveyModel.WITH_OUTER_BATTERY_CABINET, labelWithBatteryCabinet],
    ]);

    return labelMaps.get(key) as string;
  };

  static getMuteItemModels = (
    infos: AccessoryProperties
  ): Partial<ViewItemModel>[] => {
    return [];
  };

  static getEPItemModels = (
    infos: AccessoryProperties
  ): Partial<ViewItemModel>[] => {
    return [];
  };

  static getThirdPartyOptionLabel = (
    key: keyof AccessoryProperties,
    option: AirLock | InstallPosition | NeedSupport | any
  ): string => {
    const labelMaps = new Map<keyof AccessoryProperties, any>([
      [SiteSurveyModel.SPEC, SiteSurveyModel.getAirLockOptionLabel],
      [SiteSurveyModel.SUPPORT_INSTALL, SiteSurveyModel.getNeedSiteSurveyLabel],
      [SiteSurveyModel.INSTALL_POS, SiteSurveyModel.getInstallationPosLabel],
    ]);

    const handler = labelMaps.get(key);
    if (!isFunction(handler)) return option ?? "";
    return handler(option);
  };

  static getAirLockOptionLabel = (option: AirLock): string => {
    return (
      SiteSurveyModel.getAirLockOptions().find(({ value }) => value === option)
        ?.text ?? ""
    );
  };

  static getInstallationPosLabel = (option: InstallPosition): string => {
    return (
      SiteSurveyModel.getInstallPosOptions().find(
        ({ value }) => value === option
      )?.text ?? ""
    );
  };

  static getNeedSiteSurveyLabel = (option: NeedSupport): string => {
    return (
      SiteSurveyModel.getSupportOptions().find(({ value }) => value === option)
        ?.text ?? ""
    );
  };

  static getLabels = () => {
    return {
      labelSiteSurveyRequired: formatMessage("order_site_survey_required"),
      reminderSiteSurveySelected: formatMessage("order_site_survey_selected"),
      reminderSiteSurveyNotSelected: formatMessage(
        "order_site_survey_not_selected"
      ),
      labelExpectedSurveyDate: formatMessage("order_site_survey_expected_date"),
      labelAccessories: formatMessage("order_accessories"),
      labelAirlock: formatMessage("order_airlock"),
      labelMute: formatMessage("order_mute"),
      labelEP: formatMessage("order_ep"),
      labelCryo: formatMessage("order_cryo"),
      labelLaser: formatMessage("order_laser"),
      label3View: formatMessage("order_3_view"),
      labelCoolTable: formatMessage("order_cool_table"),
      labelCL: formatMessage("order_cl"),
      labelEnergySpectrum: formatMessage("order_energy_spectrum"),
      labelEBSD: formatMessage("order_ebsd"),
      labelVibrationIsolation: formatMessage("order_vibration_isolation"),
      labelMagneticEraser: formatMessage("order_magnetic_eraser"),
      labelUPS: formatMessage("order_ups"),
      labelOther: formatMessage("order_other"),
      labelSpec: formatMessage("order_spec"),
      labelBrandAndModel: formatMessage("order_accessory_brand"),
      labelWithComputer: formatMessage("order_accessory_computer"),
      labelInstallPos: formatMessage("order_accessory_install_position"),
      labelNeedSupport: formatMessage("order_accessory_need_support"),
      labelWithOutControl: formatMessage("order_accessory_with_out_control"),
      labelWithBatteryCabinet: formatMessage(
        "order_accessory_with_battery_cabinet"
      ),
      labelYes: formatMessage("common_yes"),
      labelNo: formatMessage("common_no"),
      labelLeft: formatMessage("order_install_pos_left"),
      labelRight: formatMessage("order_install_pos_right"),
      labelNotApply: formatMessage("order_install_pos_not_apply"),
      labelComments: formatMessage("common_comments"),
      phdComments: formatMessage("common_comments_phd"),
    };
  };

  protected static getSupportOptions = () => {
    const { labelYes, labelNo } = SiteSurveyModel.getLabels();
    return [
      { value: NeedSupport.YES, text: labelYes },
      { value: NeedSupport.NO, text: labelNo },
    ];
  };

  protected static getInstallPosOptions = () => {
    const { labelLeft, labelRight, labelNotApply } =
      SiteSurveyModel.getLabels();
    return [
      { value: InstallPosition.LEFT, text: labelLeft },
      { value: InstallPosition.RIGHT, text: labelRight },
      { value: InstallPosition.NOT_APPLY, text: labelNotApply },
    ];
  };

  protected static getAirLockOptions = () => {
    return [
      { value: AirLock.Eighty, text: "80mm" },
      { value: AirLock.tWO_HUNDRED, text: "200mm" },
    ];
  };

  private static verifyCryoLike = (infos: Partial<AccessoryProperties>) => {
    const keys: (keyof Partial<AccessoryProperties>)[] = [
      SiteSurveyModel.BRAND_AND_MODEL,
      SiteSurveyModel.BUILD_IN,
      SiteSurveyModel.INSTALL_POS,
      SiteSurveyModel.SUPPORT_INSTALL,
    ];

    return ValidationService.verifyIntegrity(infos, keys);
  };

  private static verifyVibrationLike = (
    infos: Partial<AccessoryProperties>
  ) => {
    const keys: (keyof Partial<AccessoryProperties>)[] = [
      SiteSurveyModel.BRAND_AND_MODEL,
      SiteSurveyModel.BUILD_IN,
      SiteSurveyModel.SUPPORT_INSTALL,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  private static verifyAirLock = (infos: Partial<AccessoryProperties>) => {
    const keys: (keyof Partial<AccessoryProperties>)[] = [SiteSurveyModel.SPEC];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  private static verifyUPSLike = (infos: Partial<AccessoryProperties>) => {
    const keys: (keyof Partial<AccessoryProperties>)[] = [
      SiteSurveyModel.BRAND_AND_MODEL,
      SiteSurveyModel.BUILD_IN,
      SiteSurveyModel.SUPPORT_INSTALL,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  private static verifyOther = (infos: Partial<AccessoryProperties>) => {
    const keys: (keyof Partial<AccessoryProperties>)[] = [
      SiteSurveyModel.COMMENTS,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };
}
