import { TextInput, TextInputProps } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../../../components/ErrorDecorator/ErrorDecorator";
import ValidationService, {
  VerifyType,
} from "../../OrderRequest/service/ValidationService";
import useLang from "../../hooks/useLang";
import styles from "./InputVariant.module.scss";

export enum LabelIndicator {
  TYPE_REQUIRED = "required",
  TYPE_OPTIONAL = "optional",
  TYPE_CUSTOM = "custom",
}

export enum LabelIndicatorIcon {
  TYPE_DOT = "dot",
  TYPE_STAR = "star",
}

export interface IIputWrapper extends Omit<TextInputProps, "label"> {
  label: React.ReactNode;
  inputType?: Partial<VerifyType>;
  indicator?: LabelIndicator;
  indicatorSymbol?: LabelIndicatorIcon;
  indicatorCustom?: React.ReactElement;
}

const InputVariant = ErrorDetectorSmallView((props: IIputWrapper) => {
  const {
    inputType,
    indicator,
    className,
    indicatorSymbol,
    indicatorCustom,
    label,
    disabled,
    ...rest
  } = props;
  const [error, setError] = React.useState<string | undefined>();
  const { invalidEmail, invalidPhone } = useLang({
    invalidEmail: { id: "error_invalid_email" },
    invalidPhone: { id: "error_invalid_phone" },
  });
  const cls = classNames(styles.inputVariant, className);

  const verify = React.useCallback(() => {
    const { value, indicator } = props;
    const isOptional =
      indicator === LabelIndicator.TYPE_OPTIONAL && `${value}`.length === 0;
    const errorMaps = new Map([
      [VerifyType.TYPE_EMAIL, invalidEmail],
      [VerifyType.TYPE_PHONE, invalidPhone],
    ]);
    const errorMsg = errorMaps.get(inputType as VerifyType);
    const valid = ValidationService.getVerifier({
      type: inputType as VerifyType,
      value,
    });
    const error = !isOptional && !valid ? errorMsg : undefined;
    setError(error);
  }, [inputType, invalidEmail, invalidPhone, props]);

  const labelView = React.useMemo(() => {
    const indicatorSymbolCls =
      indicatorSymbol === LabelIndicatorIcon.TYPE_DOT
        ? styles.indicatorDot
        : styles.indicatorStar;
    const labelIndicatorCls = classNames(
      styles.labelIndicator,
      indicatorSymbolCls
    );
    const _indicator = disabled ? LabelIndicator.TYPE_OPTIONAL : indicator;
    const indicatorIconView = <div className={labelIndicatorCls} />;
    const indicatorView = indicatorCustom ? indicatorCustom : indicatorIconView;
    const showIndicator = _indicator !== LabelIndicator.TYPE_OPTIONAL;

    return (
      <span className={styles.labelView}>
        <span>{label}</span>
        {showIndicator && indicatorView}
      </span>
    );
  }, [indicatorSymbol, disabled, indicator, indicatorCustom, label]);

  React.useEffect(
    () => verify(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputType, invalidEmail, invalidPhone, props.value]
  );

  return (
    <TextInput
      {...(rest as any)}
      error={error}
      className={cls}
      label={labelView}
      disabled={disabled}
    />
  );
});

InputVariant.defaultProps = {
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};

export default InputVariant;
