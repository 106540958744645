import { PagePaths } from "../pages/service/pageType";
import { Role } from "../pages/service/userRoleTypes";

export default class PermissionService {
  static checkPagePermission = (role: Role, path: PagePaths): boolean => {
    return PermissionService.getPagesByRole(role)?.includes(path as PagePaths);
  };

  static isValidUser = (role: Role, id: string): boolean => {
    if (!id) return false;
    const roleList = [
      Role.ROLE_ADMIN,
      Role.ROLE_SALES_MANAGER,
      Role.ROLE_SALES,
      Role.ROLE_RSM,
      Role.ROLE_FSE,
      Role.ROLE_SO,
      Role.ROLE_APPLY_SUPERVISOR,
      Role.ROLE_APPLY_ENGINEER,
      Role.ROLE_RSM_MANAGER,
      Role.ROLE_AP_MANAGER,
    ];
    return roleList.includes(role);
  };

  private static getPagesByRole = (role: Role): PagePaths[] => {
    const roleMaps = new Map([
      [Role.ROLE_ADMIN, [PagePaths.PATH_ORDER_LIST, PagePaths.PATH_ADMIN]],
      [Role.ROLE_SALES_MANAGER, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_SALES, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_RSM_MANAGER, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_RSM, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_FSE, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_SO, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_AP_MANAGER, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_APPLY_SUPERVISOR, [PagePaths.PATH_ORDER_LIST]],
      [Role.ROLE_APPLY_ENGINEER, [PagePaths.PATH_ORDER_LIST]],
    ]);

    const commonPages = [
      PagePaths.PATH_HOME,
      PagePaths.PATH_ORDER_REQUEST,
      PagePaths.PATH_DASHBOARD,
      PagePaths.PATH_ERROR_PAGE,
      PagePaths.PATH_ANY,
    ];

    const _pages = roleMaps.get(role) ?? [];

    return [...commonPages, ..._pages];
  };
}
