import React from "react";
import { isFunction } from "../../../utils/isType";
import { ErrorDetectorModalView } from "../../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../../Modal/Modal";
import { PreviewAndDownload } from "../PreviewAndDownloadModal";
import styles from "./ImagePreview.module.scss";

interface IImgViewer extends IModal {
  url: string;
  actionType?: PreviewAndDownload;
  previewIndex?: number;
  activeIndex?: number;
  onThumbClick?: (enablePreview?: boolean) => void;
  onError?: (error: any) => void;
}

const ImgPreviewer = ErrorDetectorModalView((props: IImgViewer) => {
  const {
    url,
    actionType,
    previewIndex,
    activeIndex,
    onClose,
    onThumbClick,
    onError,
    ...rest
  } = props;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(
    () =>
      setVisible(
        PreviewAndDownload.PREVIEW === actionType &&
          previewIndex === activeIndex
      ),
    [actionType, previewIndex, activeIndex]
  );

  const closeHandler = React.useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      setVisible(false);
      isFunction(onClose) && onClose(e);
    },
    []
  );

  const clickHandler = React.useCallback(() => {
    isFunction(onThumbClick) && onThumbClick();
  }, [actionType]);

  const errorHandler = React.useCallback((error: any) => {
    isFunction(onError) && onError(error);
  }, []);

  return (
    <>
      {url && (
        <img onClick={clickHandler} className={styles.thumbImg} src={url} />
      )}
      <Modal
        {...rest}
        visible={visible}
        onClose={closeHandler}
        className={styles.modalContainer}
      >
        <img
          className={styles.img}
          src={url}
          onClick={closeHandler}
          onError={errorHandler}
        />
      </Modal>
    </>
  );
});

export default ImgPreviewer;
