import useLang from "../../../../hooks/useLang";

export interface HookSoldToParty {
  title: string;
}
export default function (): HookSoldToParty {
  const { title } = useLang({
    title: { id: "order_sold_to_party" },
  });

  return {
    title,
  };
}
