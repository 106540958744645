import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Feature } from "../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationRsm.module.scss";
import InstallationRsmModel from "./InstallationRsmModel";
import useInstallationRsm from "./useInstallationRsm";

const InstallationRsmMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    confirmorVisible,
    reminderNeedSiteSurvey,
    assignAndForwardVisible,
    assignAndForwArdConfig,
    installationInfo,
    assignData,
    saleRegions,
    rolePage,
    submitEnable,
    cancel,
    buttonBlock,
    confirm,
    userId,
    locale,
    fseList,
    setVisible,
    confirmorHandler,
    approveHandler,
    backAllTickHandler,
    moreActionHandler,
    changeHandler,
    assignAndForwardHandler,
  } = useInstallationRsm();

  const orderInfoView = React.useMemo(() => {
    const list = InstallationRsmModel.getInfoViewMobileModel(
      installationInfo,
      saleRegions,
      rolePage.pageStatus,
      fseList
    );
    const reminder = InstallationModel.getReminderByWarranty(
      installationInfo?.warrantyPeriod as WarrantyPeriod
    );
    const reminderLabel = reminder
      ? reminder
      : installationInfo?.otherWarrantyPeriod;

    const view = list.map(({ key, viewType, props }) => {
      const propsMaps = new Map([
        [
          InstallationModel.PLANNED_INSTALL_START_DATE,
          {
            ...props,
            key,
            onConfirm: (value: Date) =>
              changeHandler(key as string, value?.getTime()),
            variantClassName: styles.datePicker,
          },
        ],
        [
          InstallationModel.PLANNED_INSTALL_END_DATE,
          {
            ...props,
            key,
            onConfirm: (value: Date) =>
              changeHandler(key as string, value?.getTime()),
            variantClassName: styles.datePicker,
          },
        ],
        [
          InstallationModel.OWNER_INSTALLATION_FSE,
          {
            ...props,
            key,
            onChange: (value: Date) => changeHandler(key as string, value),
            variantClassName: styles.datePicker,
          },
        ],
        [
          InstallationModel.FAC_REQUIRED,
          {
            ...props,
            key,
            className: styles.fac,
          },
        ],
        [
          InstallationModel.EXPECTED_INSTALL_DATA,
          {
            ...props,
            key,
            className: styles.expectedDate,
          },
        ],
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            key,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return (
      <div className={styles.orderInfoView}>
        {view}
        {reminderLabel && (
          <div className={styles.warrantyPeriodReminder}>{reminderLabel}</div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationInfo,
    saleRegions,
    rolePage.pageStatus,
    locale,
    changeHandler,
  ]);

  const uploadFileView = React.useMemo(() => {
    const list = InstallationRsmModel.getFileInfoViewModel(
      installationInfo as IInstallation
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.uploadFileView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationInfo, locale, changeHandler]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationRsmModel.getButtonViewMobileModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [RsmAction.FORWARD, { onSelect: moreActionHandler }],
      [RsmAction.ASSIGN, { onClick: approveHandler }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === RsmAction.ASSIGN ? !submitEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
  }, [
    rolePage,
    submitEnable,
    approveHandler,
    backAllTickHandler,
    moreActionHandler,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmorHandler.bind(null, "cancel")}
        >
          {cancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmorHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {confirm}
        </Button>
      </div>
    ),
    [cancel, buttonBlock, confirm, confirmorHandler]
  );

  return (
    <div className={styles.installationInfoRsm}>
      <div className={styles.pageContainer}>
        {orderInfoView}
        <OrderInfoDnView {...(installationInfo.dnInfo as IDNInfo)} />
        {uploadFileView}
        <CommentsView />
      </div>
      {buttonsView}
      <Confirmor
        visible={confirmorVisible}
        footer={confirmButtonView}
        onConfirm={confirmorHandler}
        content={
          <div className={styles.reminder}>{reminderNeedSiteSurvey}</div>
        }
      />
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          role={assignAndForwArdConfig.role}
          action={assignAndForwArdConfig.action}
          feature={Feature.INSTALLATION}
          searchQuery={{ rsmUserId: userId }}
          actionData={{
            ...assignData,
            selectUserIdKey: assignAndForwArdConfig.dataKey,
          }}
          onClose={() => setVisible(false)}
          onActionResponse={assignAndForwardHandler}
        />
      )}
    </div>
  );
});

export default InstallationRsmMobile;
