import { Toggle, ToggleProps } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import Cabinet, { ICabinet } from "../../../components/Cabinet/Cabinet";
import { ErrorDetectorMediumView } from "../../../components/ErrorDecorator/ErrorDecorator";
import {
  AppType,
  BasicView,
  LabelIndicator,
  LabelIndicatorIcon,
} from "../../../components/ViewWrapper/ViewWrapper";
import { isFunction } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import styles from "./SwitchCabinet.module.scss";

interface SwitchCabinet
  extends Omit<ToggleProps, "label">,
    Omit<ICabinet, "status" | "children"> {
  cabinetLabel: string | React.ReactNode;
  className?: string;
  labels?: [string, string];
  labelClassName?: string;
  toggleClassName?: string;
  indicator?: LabelIndicator;
  indicatorSymbol?: LabelIndicatorIcon;
  indicatorCustom?: React.ReactElement;
  viewType?: AppType;
}

const SwitchCabinet: React.FC<SwitchCabinet> = ErrorDetectorMediumView(
  (props: SwitchCabinet) => {
    const { no, yes } = useLang({
      no: { id: "common_no_upper" },
      yes: { id: "common_yes_upper" },
    });
    const {
      cabinetLabel,
      className,
      cabinetClassName,
      checked,
      children,
      labels = [no, yes],
      labelClassName,
      disabled,
      toggleClassName,
      indicator,
      indicatorSymbol,
      indicatorCustom,
      viewType,
      onChange,
      ...rest
    } = props;

    const [label, setLabel] = React.useState(() => labels[0]);
    const [stateChecked, setChecked] = React.useState(() => checked);
    const _className = classNames(styles.switchCabinet, className);
    const _labelSwitch = classNames(styles.labelSwitch, labelClassName);
    const _toggleClassName = classNames({ [styles.toggleDisabled]: disabled });

    React.useEffect(() => setChecked(checked), [checked]);
    React.useEffect(() => {
      const [noCheck, checked] = labels;
      const label = stateChecked ? checked : noCheck;
      setLabel(label);
    }, [labels, stateChecked]);

    const changeHandler = React.useCallback(
      (checked: boolean) => {
        setChecked(checked);
        isFunction(onChange) && onChange(checked);
      },
      [onChange]
    );

    return (
      <section className={_className}>
        <BasicView
          label={cabinetLabel as string}
          indicator={indicator}
          indicatorCustom={indicatorCustom}
          indicatorSymbol={indicatorSymbol}
          viewType={viewType}
          className={_labelSwitch}
          labelClassName={styles.switchLabel}
        >
          <Toggle
            {...rest}
            checked={stateChecked}
            onChange={changeHandler}
            label={label as string}
            disabled={disabled}
            className={_toggleClassName}
          />
        </BasicView>
        <Cabinet status={!!stateChecked} cabinetClassName={cabinetClassName}>
          {children as React.ReactElement}
        </Cabinet>
      </section>
    );
  }
);

export default SwitchCabinet;
