import { cloneDeep, isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../../core/ErrorCodeService";
import EventService from "../../../../../core/EventService";
import { NormalRes } from "../../../../../core/HttpService";
import { LocaleLang } from "../../../../../core/LocaleService";
import {
  IPreInstallReq,
  insertComments,
} from "../../../../../services/order.service";
import { IUserInfo } from "../../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  systemUserOrgs,
  userState,
} from "../../../../../store";
import {
  isFunction,
  isNumber,
  isObject,
  isString,
} from "../../../../../utils/isType";
import { useErrorMsg } from "../../../../hooks/useErrorMsg";
import useLang from "../../../../hooks/useLang";
import useRolePage from "../../../../hooks/useRolePage";
import {
  PageStatus,
  PageSubItems,
  SubTabs,
} from "../../../../service/pageType";
import SiteSurveyModel, {
  AccessoryProperties,
  AccessoryTypes,
} from "../../../SiteSurvey/model/SiteSurveyModel";
import OrderRequestModel, {
  IFile,
  IPartnerTeamOtherInfo,
  IPrepareInstallation,
  IThirdParty,
  ProductLine,
} from "../../../model/OrderRequestModel";
import InfoSalesModel, { ButtonMore, IContactPerson } from "../InfoSalesModel";

export interface HookInfoSales {
  reminder: string;
  cancel: string;
  confirm: string;
  confirmVisible: boolean;
  disclaimer: IFile[];
  partnerTeamOtherInfo: IPartnerTeamOtherInfo;
  thirdParty: IThirdParty;
  stateNeedSiteSurvey: boolean;
  expectedStartDate: number;
  rolePage: SubTabs;
  buttOnEnable: boolean;
  forwardVisible: boolean;
  preInstallInfo: IPrepareInstallation;
  userId: string;
  buttonBlock: boolean;
  reminderRef: React.MutableRefObject<string>;
  cancelOrderReminder: string;
  locale: LocaleLang;
  siteSurveyInfoChangeHandler: (changedItem: {
    key: keyof IPrepareInstallation;
    value: any;
  }) => void;
  partnerInfoChangeHandler: (
    changedItem: Partial<IPartnerTeamOtherInfo>
  ) => void;
  confirmCloseHandler: (type: CloseType) => void;
  assignAndForwardHandler: (res: Promise<NormalRes>) => void;
  setForward: React.Dispatch<React.SetStateAction<boolean>>;
  backToAllTicket: () => void;
  moreActionHandler: (value: ButtonMore) => void;
  submitOrderHandler: () => void;
  contactPersonChangeHandler: (info: IContactPerson) => void;
}

const formatThirdPartyResult = (thirdParty: IThirdParty): IThirdParty => {
  const copied: any = cloneDeep(thirdParty);
  if (isEmpty(copied) || !isObject(thirdParty)) return thirdParty;
  for (const key in copied) {
    if (!copied?.hasOwnProperty(key)) continue;
    const item = copied[key];
    if (isEmpty(item) || !isObject(item)) continue;
    for (const subKey in item) {
      if (!item?.hasOwnProperty(subKey)) continue;
      if (subKey === "notUse") {
        item[subKey] = "";
      }
      item[subKey] = isString(item[subKey])
        ? item[subKey].trim()
        : item[subKey];
    }
  }

  return copied;
};

export default function (): HookInfoSales {
  const { cancelOrderReminder, submitOrderReminder, cancel, confirm } = useLang(
    {
      cancel: { id: "common_btn_cancel" },
      confirm: { id: "common_btn_confirm" },
      cancelOrderReminder: { id: "order_cancel_order_reminder" },
      submitOrderReminder: { id: "order_confirm_order_reminder" },
    }
  );
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO
  );
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const [confirmVisible, setConfirmVisible] = React.useState(false);
  const [reminder, setReminder] = React.useState("");
  const [forwardVisible, setForward] = React.useState(false);
  const [preInstallInfo, setPreInstallInfo] = React.useState(
    () => ({} as IPrepareInstallation)
  );
  const { userId, usersRole } = (useRecoilValue(userState) as IUserInfo) ?? {};
  const [partnerTeamOtherInfo, setPartner] =
    React.useState<IPartnerTeamOtherInfo>({} as IPartnerTeamOtherInfo);
  const [stateNeedSiteSurvey, setNeed] = React.useState(false);
  const [expectedStartDate, setStartTime] = React.useState(() => Date.now());
  const [disclaimer, setDisClaimer] = React.useState<any[]>([]);
  const [thirdParty, setThirdParty] = React.useState({} as IThirdParty);
  const [buttonBlock, setBlock] = React.useState(false);
  const setLoading = useSetRecoilState(loadState);
  const preInstallRef = React.useRef({});
  const reminderRef = React.useRef("");
  const contactPersonRef = React.useRef({} as any);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO);
  const saleRegions = useRecoilValue(systemUserOrgs);
  const { region, productLine } = partnerTeamOtherInfo;
  const locale = useRecoilValue(localeState);

  const regions = React.useMemo(
    () =>
      saleRegions?.regionProductLine.filter(({ region }) => {
        return pageStatus === PageStatus.READONLY ? true : !region?.isDeleted;
      }) ?? [],
    [pageStatus, saleRegions?.regionProductLine]
  );

  const productLines = React.useMemo(() => {
    const productLines =
      regions.find(({ value }) => value === region)?.productLines ?? [];
    return pageStatus === PageStatus.READONLY
      ? productLines
      : productLines.filter((item: any) => !item?.isDeleted);
  }, [region, pageStatus, regions]);

  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);

  const getPreInstallationInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      OrderRequestModel.getPreInstallInformation(installationTaskId, locale)
        .then((res) => {
          const {
            partnerTeamOtherInfo,
            disclaimer,
            thirdParty,
            isSiteSurvey,
            expectedStartDate,
            contactPerson,
          } = res?.data ?? {};
          setPreInstallInfo(res?.data);
          setPartner(partnerTeamOtherInfo);
          setDisClaimer(disclaimer ?? []);
          setNeed(isSiteSurvey);
          setStartTime(expectedStartDate);
          setThirdParty(thirdParty);
          contactPersonRef.current = contactPerson;
        })
        .catch((res) => {
          setError(res?.errCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  React.useEffect(() => {
    getPreInstallationInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  React.useEffect(() => {
    const { regionProductLine } = saleRegions ?? {};
    if (isEmpty(regionProductLine) || !isNumber(region)) return;
    const rsm = OrderRequestModel.getServiceSupervisorLabel(
      saleRegions,
      region as number,
      productLine as number
    );
    const so = OrderRequestModel.getServiceCoordinatorLabel(
      saleRegions,
      region as number
    );
    setPartner((pre) => ({ ...pre, rsm: pre.rsm || rsm, so: pre.so || so }));
  }, [region, saleRegions, productLine]);

  const buttOnEnable = React.useMemo(() => {
    const _disclaimer = disclaimer.map(({ url: fileUrl, name: fileName }) => ({
      fileUrl,
      fileName,
    }));
    const isLM = partnerTeamOtherInfo?.productLine === ProductLine.LM;
    const needSiteSurvey = {
        partnerTeamOtherInfo,
        thirdParty: formatThirdPartyResult(thirdParty),
        isSiteSurvey: stateNeedSiteSurvey,
        installationTaskId,
        expectedStartDate,
      },
      notNeedSiteSurvey = {
        partnerTeamOtherInfo,
        isSiteSurvey: stateNeedSiteSurvey,
        installationTaskId,
        disclaimer: _disclaimer,
      },
      notNeedSiteSurveyForLM = {
        partnerTeamOtherInfo,
        isSiteSurvey: stateNeedSiteSurvey,
        installationTaskId,
      };

    preInstallRef.current = stateNeedSiteSurvey
      ? needSiteSurvey
      : isLM
      ? notNeedSiteSurveyForLM
      : notNeedSiteSurvey;

    const needSiteSurveyValid = InfoSalesModel.verifySaleSubmit(
        needSiteSurvey,
        [
          InfoSalesModel.PARTNER_TEAM_OTHER_INFO,
          InfoSalesModel.IS_SITE_SURVEY,
          InfoSalesModel.INSTALLATION_TASK_ID,
          InfoSalesModel.EXPECTED_START_DATE,
        ]
      ),
      notNeedSiteSurveyValid = InfoSalesModel.verifySaleSubmit(
        notNeedSiteSurvey as any,
        [
          InfoSalesModel.PARTNER_TEAM_OTHER_INFO,
          InfoSalesModel.IS_SITE_SURVEY,
          InfoSalesModel.INSTALLATION_TASK_ID,
          InfoSalesModel.DISCLAIMER,
        ]
      ),
      notNeedSiteSurveyValidForLM = InfoSalesModel.verifySaleSubmit(
        notNeedSiteSurvey as any,
        [
          InfoSalesModel.PARTNER_TEAM_OTHER_INFO,
          InfoSalesModel.IS_SITE_SURVEY,
          InfoSalesModel.INSTALLATION_TASK_ID,
        ]
      );
    const _notNeedSiteSurveyValid = isLM
      ? notNeedSiteSurveyValidForLM
      : notNeedSiteSurveyValid;
    const validThirdParty = SiteSurveyModel.verifyThirdParty(thirdParty);
    const validOrgInfo = InfoSalesModel.verifyOrgInfo(partnerTeamOtherInfo);
    const validRegion = InfoSalesModel.verifyOrgRegion(
      region as number,
      regions
    );
    const validProductLine = InfoSalesModel.verifyOrgProductLine(
      productLine as number,
      productLines
    );
    const validContactPerson = InfoSalesModel.verifyContactPersonFromSass(
      contactPersonRef.current
    );

    const needSiteSurveyVerify = [
        needSiteSurveyValid,
        validThirdParty,
        validOrgInfo,
        validContactPerson,
        validRegion,
        validProductLine,
      ].every((item) => item),
      notNeedSiteSurveyVerify = [
        _notNeedSiteSurveyValid,
        validOrgInfo,
        validContactPerson,
        validRegion,
        validProductLine,
      ].every((item) => item);

    return stateNeedSiteSurvey ? needSiteSurveyVerify : notNeedSiteSurveyVerify;
  }, [
    disclaimer,
    partnerTeamOtherInfo,
    thirdParty,
    stateNeedSiteSurvey,
    installationTaskId,
    expectedStartDate,
    region,
    regions,
    productLine,
    productLines,
  ]);

  const backToAllTicket = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitPreInstallationInfoAndComments = React.useCallback(() => {
    setLoading(true);
    OrderRequestModel.submitPreInstallationInfo(
      preInstallRef.current as IPreInstallReq
    )
      .then(() => {
        EventService.getInstance().emit(
          EventService.EVENT_SUBMIT_STEP_COMMENTS
        );
        backToAllTicket();
      })
      .catch((err) => {
        setError(err?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [backToAllTicket, setLoading, toastError]);

  const cancelOrderHandler = React.useCallback(() => {
    setLoading(true);
    InfoSalesModel.cancelOrder(installationTaskId)
      .then(() => {
        EventService.getInstance().emit(
          EventService.EVENT_SUBMIT_STEP_COMMENTS
        );
        backToAllTicket();
      })
      .catch((res) => {
        setError(res?.errCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationTaskId,
    backToAllTicket,
    insertComments,
    setLoading,
    toastError,
  ]);

  const partnerInfoChangeHandler = React.useCallback(
    (changedItem: Partial<IPartnerTeamOtherInfo>) => {
      setPartner((pre) => {
        const keys = Object.keys(changedItem);
        const changeProductLine = keys.includes(InfoSalesModel.PRODUCT_LINE);
        if (!changeProductLine) return { ...pre, ...changedItem };
        const isSiteSurvey =
          changedItem[InfoSalesModel.PRODUCT_LINE] !== ProductLine.LM;
        setNeed(isSiteSurvey);
        return { ...pre, ...changedItem };
      });
    },
    []
  );

  const siteSurveyInfoChangeHandler = React.useCallback(
    (changedItem: { key: keyof IPrepareInstallation; value: any }) => {
      const handlerMaps = new Map<any, any>([
        [InfoSalesModel.IS_SITE_SURVEY, (value: boolean) => setNeed(value)],
        [
          InfoSalesModel.EXPECTED_START_DATE,
          (value: number) => setStartTime(value),
        ],
        [
          InfoSalesModel.EXPECTED_START_DATE,
          (value: number) => setStartTime(value),
        ],
        [InfoSalesModel.DISCLAIMER, (value: any[]) => setDisClaimer(value)],
        [
          InfoSalesModel.THIRD_PARTY,
          (item: {
            key: AccessoryTypes;
            value: Partial<AccessoryProperties>;
            action: "update" | "delete";
          }) =>
            setThirdParty((pre) => {
              const copied: any = cloneDeep(pre);
              const { key, value, action } = item;
              if (action === "update") {
                return { ...pre, [key]: value };
              } else {
                delete copied[key];
              }
              return copied;
            }),
        ],
      ]);

      const handler = handlerMaps.get(changedItem?.key);
      if (!isFunction(handler)) return;
      handler(changedItem?.value);
    },
    []
  );

  const moreActionHandler = React.useCallback(
    (value: ButtonMore) => {
      if (value === ButtonMore.CANCEL_ORDER) {
        setConfirmVisible(true);
        setReminder(cancelOrderReminder);
        reminderRef.current = cancelOrderReminder;
        return;
      }
      setForward(true);
    },
    [cancelOrderReminder]
  );

  const confirmCloseHandler = React.useCallback(
    (type: CloseType) => {
      setBlock(true);
      setConfirmVisible(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      if (reminderRef.current === cancelOrderReminder) {
        cancelOrderHandler();
      }

      if (reminderRef.current === submitOrderReminder) {
        submitPreInstallationInfoAndComments();
      }
    },
    [
      cancelOrderReminder,
      submitOrderReminder,
      cancelOrderHandler,
      submitPreInstallationInfoAndComments,
    ]
  );

  const submitOrderHandler = React.useCallback(() => {
    setReminder(submitOrderReminder);
    reminderRef.current = submitOrderReminder;
    setConfirmVisible(true);
  }, [submitOrderReminder]);

  const assignAndForwardHandler = React.useCallback(
    (res: Promise<NormalRes>) => {
      res
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backToAllTicket();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        });
    },
    [backToAllTicket, toastError]
  );

  const contactPersonChangeHandler = React.useCallback(
    (contactInfo: IContactPerson) => {
      contactPersonRef.current = contactInfo;
      setPreInstallInfo((pre) => ({
        ...pre,
        contactPerson: contactInfo as IContactPerson,
      }));
    },
    []
  );

  return {
    reminder,
    cancel,
    confirm,
    confirmVisible,
    disclaimer,
    partnerTeamOtherInfo,
    thirdParty,
    stateNeedSiteSurvey,
    expectedStartDate,
    rolePage,
    buttOnEnable,
    forwardVisible,
    preInstallInfo,
    userId,
    buttonBlock,
    reminderRef,
    cancelOrderReminder,
    locale,
    siteSurveyInfoChangeHandler,
    partnerInfoChangeHandler,
    confirmCloseHandler,
    assignAndForwardHandler,
    setForward,
    backToAllTicket,
    moreActionHandler,
    submitOrderHandler,
    contactPersonChangeHandler,
  };
}
