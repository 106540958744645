import React, {
  FC,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Mask from "../Mask/Mask";
import { SafeArea } from "../SafeArea/SafeArea";
import { mergeProps } from "../utils/mergeDefaultProps";
import { NativeProps } from "../utils/mergeNativeProps";
import { GetContainer, renderToContainer } from "../utils/renderToContainer";
import styles from "./ImageViewer.module.scss";
import { Slide } from "./Slide";
import { Slides, SlidesRef } from "./Slides";

export type ImageViewerProps = {
  image?: string;
  maxZoom?: number | "auto";
  getContainer?: GetContainer;
  visible?: boolean;
  onClose?: () => void;
  afterClose?: () => void;
  renderFooter?: (image: string) => React.ReactNode;
};

export type ImageProps = {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  fit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  placeholder?: React.ReactNode;
  fallback?: React.ReactNode;
  lazy?: boolean;
  draggable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLImageElement, Event>) => void;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onContainerClick?: (event: React.MouseEvent<HTMLDivElement, Event>) => void;
} & NativeProps<"--width" | "--height"> &
  Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    | "crossOrigin"
    | "decoding"
    | "loading"
    | "referrerPolicy"
    | "sizes"
    | "srcSet"
    | "useMap"
  >;

const defaultProps = {
  maxZoom: 3,
  getContainer: null,
  visible: false,
};

export const ImageViewer: FC<ImageViewerProps> = (p) => {
  const props = mergeProps(defaultProps, p);

  const node = (
    <Mask
      visible={props.visible}
      disableBodyScroll={false}
      opacity="thick"
      afterClose={props.afterClose}
      destroyOnClose
    >
      <div className={styles["imageViewer-content"]}>
        {props.image && (
          <Slide
            image={props.image}
            onTap={() => {
              props.onClose?.();
            }}
            maxZoom={props.maxZoom}
          />
        )}
      </div>
      {props.image && (
        <div className={styles["imageViewer-footer"]}>
          {props.renderFooter?.(props.image)}
          <SafeArea position="bottom" />
        </div>
      )}
    </Mask>
  );
  return renderToContainer(props.getContainer, node);
};

export type MultiImageViewerRef = SlidesRef;

export type MultiImageViewerProps = Omit<
  ImageViewerProps,
  "image" | "renderFooter"
> & {
  images?: string[];
  defaultIndex?: number;
  onIndexChange?: (index: number) => void;
  renderFooter?: (image: string, index: number) => React.ReactNode;
};

const multiDefaultProps = {
  ...defaultProps,
  defaultIndex: 0,
};
export const MultiImageViewer = forwardRef<
  MultiImageViewerRef,
  MultiImageViewerProps
>((p, ref) => {
  const props = mergeProps(multiDefaultProps, p);
  const [index, setIndex] = useState(props.defaultIndex);

  const slidesRef = useRef<SlidesRef>(null);
  useImperativeHandle(ref, () => ({
    swipeTo: (index: number, immediate?: boolean) => {
      setIndex(index);
      slidesRef.current?.swipeTo(index, immediate);
    },
  }));

  const onSlideChange = useCallback(
    (index: number) => {
      setIndex(index);
      props.onIndexChange?.(index);
    },
    [props.onIndexChange]
  );

  const node = (
    <Mask
      visible={props.visible}
      disableBodyScroll={false}
      opacity="thick"
      afterClose={props.afterClose}
      destroyOnClose
    >
      <div className={styles["imageViewer-content"]}>
        {props.images && (
          <Slides
            ref={slidesRef}
            defaultIndex={index}
            onIndexChange={onSlideChange}
            images={props.images}
            onTap={() => {
              props.onClose?.();
            }}
            maxZoom={props.maxZoom}
          />
        )}
      </div>
      {props.images && (
        <div className={styles["imageViewer-footer"]}>
          {props.renderFooter?.(props.images[index], index)}
          <SafeArea position="bottom" />
        </div>
      )}
    </Mask>
  );
  return renderToContainer(props.getContainer, node);
});
