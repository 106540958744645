import { NotificationAPI } from "rc-notification";
import { atom } from "recoil";
import { PageError } from "../core/ErrorCodeService";
import { LocaleLang } from "../core/LocaleService";
import SystemService, { SystemPlatform } from "../core/SystemService";
import OrderListModel, { OrderStatus } from "../pages/OrderList/OrderListModel";
import { Draft } from "../pages/OrderRequest/model/OrderRequestModel";
import { PageSubItems, SubTabs } from "../pages/service/pageType";
import { OrderStepSummary } from "../pages/service/roleStateService/RoleStepPageService";
import { Role } from "../pages/service/userRoleTypes";
import { StaticSourceItem } from "../services/order.service";
import { IUserInfo } from "../services/user.service";

export const localeState = atom<LocaleLang>({
  key: "localeState",
  default: OrderListModel.getLang(),
});

export const userState = atom<IUserInfo>({
  key: "userState",
  default: {
    userId: "",
    usersRole: Role.ROLE_NONE,
    email: "",
    id: "",
    employeeId: "",
    nameCN: "",
    nameEN: "",
  },
});

export const orderTaskIdState = atom<string>({
  key: "orderTaskId",
  default: "",
});

export const systemUserOrgs = atom<{
  apSupervisor: StaticSourceItem[];
  regionProductLine: Partial<StaticSourceItem>[];
}>({
  key: "systemUserOrgs",
  default: { apSupervisor: [], regionProductLine: [] },
});

export const stepState = atom<OrderStepSummary>({
  key: "stepState",
  default: {} as OrderStepSummary,
});

export const orderRequestTabs = atom<SubTabs[]>({
  key: "orderRequestTabsState",
  default: [],
});

export const errorPageState = atom<PageError | undefined>({
  key: "errorPageState",
  default: undefined,
});

export const isFinalGoodsRecipChangeState = atom<boolean>({
  key: "isFinalGoodsRecipChangeState",
  default: false,
});

export const loadState = atom<boolean>({
  key: "loadState",
  default: false,
});

export const orderStatus = atom<OrderStatus>({
  key: "orderStatus",
  default: OrderStatus.PENDING,
});

export const orderParentTaskIdState = atom<string>({
  key: "orderParentTaskId",
  default: "",
});

export const platformState = atom<SystemPlatform>({
  key: "platformState",
  default: SystemService.getViewPlatform(),
});

export const orderTabState = atom<PageSubItems>({
  key: "orderTab",
  default: undefined,
});

export const notificationHandleState = atom<NotificationAPI>({
  key: "notificationHandle",
  default: {
    open: (config) => {},
    close: (key) => {},
    destroy: () => {},
  },
});

export const draftState = atom<Pick<Draft, keyof Draft>>({
  key: "draftState",
  default: {} as Pick<Draft, keyof Draft>,
});
