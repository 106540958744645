import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import Modal from "../../../../components/Modal/Modal";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  localeState,
  orderTaskIdState,
  platformState,
} from "../../../../store";
import PreviewContainer, {
  IPreviewContainer,
} from "../../../components/PreviewContainer/PreviewContainer";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import OrderRequestModel from "../../model/OrderRequestModel";
import StepVariantProcess, {
  IStepItemProcess,
} from "../StepVariantProcess/StepVariantProcess";
import styles from "./StepProcessModal.module.scss";

const StepProcessModal: React.FC<IPreviewContainer> = (
  props: IPreviewContainer
) => {
  const { title } = useLang({ title: { id: "order_step" } });
  const [stepProcess, setProcesses] = React.useState<IStepItemProcess[]>([]);
  const [errCode, setError] = React.useState<ErrorCode>();
  const [unFold, setUnFold] = React.useState(() => []);
  const taskId = useRecoilValue(orderTaskIdState);
  const toastError = useErrorMsg(errCode as ErrorCode);
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);
  const processClassName = classNames(styles.process, {
    [styles.processPc]: platform === SystemPlatform.ENV_PC,
  });
  const { onClose, ...rest } = props;

  React.useEffect(() => {
    if (!taskId) return;
    OrderRequestModel.getOrderSteps(taskId, locale)
      .then(({ data }) => {
        setProcesses(data);
      })
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, locale]);

  const processView = React.useMemo(
    () => (
      <>
        <div className={styles.title}>{title}</div>
        <StepVariantProcess
          steps={stepProcess}
          className={processClassName}
          collapseAll={true}
          unFolds={unFold}
        />
      </>
    ),
    [processClassName, stepProcess, title, unFold]
  );

  const closeIcon = React.useMemo(() => {
    return (
      <span className={styles.closeIcon} onClick={onClose}>
        {<Icon icon="Close" size={20} />}
      </span>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobileView = React.useMemo(
    () => (
      <PreviewContainer {...props} destroyOnClose={true}>
        {processView}
      </PreviewContainer>
    ),
    [processView, props]
  );

  const pcView = React.useMemo(
    () => (
      <Modal
        {...rest}
        closeIcon={closeIcon}
        closable={true}
        maskClosable={true}
        className={styles.pcModal}
      >
        <div className={styles.pcProcessContainer}>{processView}</div>
      </Modal>
    ),
    [closeIcon, processView, rest]
  );

  return platform === SystemPlatform.ENV_PC ? pcView : mobileView;
};

export default StepProcessModal;
