import { useRef } from "react";

function useLatest<T>(value: T): any {
  const ref = useRef(value);
  ref.current = value;

  return ref;
}

export default useLatest;
