import { getAuthToken, getCSRFtoken } from "../services/system.service";
import { getUserInfo } from "../services/user.service";
import { queryCookieByKey } from "../utils/commonUtils";
import { isNumber } from "../utils/isType";
import HttpService, { NormalRes } from "./HttpService";
import LocalStorageService from "./LocalStorageService";
import SystemService, { SystemEnv } from "./SystemService";

export default class AuthService {
  static readonly ENV_URL = process.env?.TECH_PLATFORM__Url__RedirectUrl;
  static readonly REDIRECT_LOCAL_URL = "http://localhost:3002";
  static readonly REDIRECT_DEV_URL =
    AuthService.ENV_URL ?? "https://wise.dev.zeiss.com.cn/wise-app/index.html";
  static readonly REDIRECT_TEST_URL =
    AuthService.ENV_URL ?? "https://wise.test.zeiss.com.cn/wise-app/index.html";
  static readonly REDIRECT_PROD_URL =
    AuthService.ENV_URL ?? "https://wise.zeiss.com.cn/wise-app/index.html";

  static readonly SSO_DEV_PATH = `https://pros.test.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=${AuthService.REDIRECT_DEV_URL}&scope=openid+profile+email+phone&code_challenge=6CQ6DK8SWnZE9t9_2GWJ6L_-nQ_sTaq9Ht17Z7GQtQQ&code_challenge_method=S256`;

  static readonly SSO_TEST_PATH = `https://pros.test.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=${AuthService.REDIRECT_TEST_URL}&scope=openid+profile+email+phone&code_challenge=6CQ6DK8SWnZE9t9_2GWJ6L_-nQ_sTaq9Ht17Z7GQtQQ&code_challenge_method=S256`;

  static readonly SSO_PROD_PATH = `https://pros.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=${AuthService.REDIRECT_PROD_URL}&scope=openid+profile+email+phone&code_challenge=J4FlXz0efviev6Oyfnvul7MyforwTwUkq-6c4LGg_sY&code_challenge_method=S256`;

  static readonly SSO_LOCAL_PATH =
    "https://pros.test.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=http://localhost:3002&scope=openid+profile+email+phone&code_challenge=6CQ6DK8SWnZE9t9_2GWJ6L_-nQ_sTaq9Ht17Z7GQtQQ&code_challenge_method=S256";

  static readonly LOGOUT_PATH =
    "https://pros.test.zeiss.com.cn/identity/connect/logout";

  private static MAXIMUM_REDIRECT_TIMES = 3;

  static readonly CSRF_TOKEN_KEY = "XSRF-TOKEN";

  static getSSOPath = () => {
    const env: SystemEnv = SystemService.getEnv();
    const authPathMap = new Map([
      [SystemEnv.ENV_DEV, AuthService.SSO_DEV_PATH],
      [SystemEnv.ENV_TEST, AuthService.SSO_TEST_PATH],
      [SystemEnv.ENV_PROD, AuthService.SSO_PROD_PATH],
      [SystemEnv.ENV_LOCAL, AuthService.SSO_LOCAL_PATH],
    ]);
    return authPathMap.get(env) ?? AuthService.SSO_DEV_PATH;
  };

  static getRedirectUrl = () => {
    const env: SystemEnv = SystemService.getEnv();
    const redirectPathMap = new Map([
      [SystemEnv.ENV_DEV, AuthService.REDIRECT_DEV_URL],
      [SystemEnv.ENV_TEST, AuthService.REDIRECT_TEST_URL],
      [SystemEnv.ENV_PROD, AuthService.REDIRECT_PROD_URL],
      [SystemEnv.ENV_LOCAL, AuthService.REDIRECT_LOCAL_URL],
    ]);
    return redirectPathMap.get(env) ?? "";
  };

  static getLogoutRedirectUrl = () => {
    const redirectUrl = AuthService.getRedirectUrl();
    return `https://pros.test.zeiss.com.cn/identity/connect/logout?post_logout_redirect_uri=${redirectUrl}`;
  };

  static getCSRFToken = (): Promise<NormalRes> => {
    return HttpService.splitResponse(getCSRFtoken());
  };

  static getToken = (code: string, redirectUrl: string): Promise<NormalRes> => {
    return HttpService.splitResponse(getAuthToken(code, redirectUrl));
  };

  static getUserInfo = (token: string): Promise<NormalRes> => {
    return HttpService.splitResponse(getUserInfo(token));
  };

  static isErrorRedirect = () => {
    const redirectCount = LocalStorageService.getStorage(
      LocalStorageService.REDIRECT_COUNT
    );
    return redirectCount >= AuthService.MAXIMUM_REDIRECT_TIMES;
  };

  static storeErrorRedirect = () => {
    const redirectCount = LocalStorageService.getStorage(
      LocalStorageService.REDIRECT_COUNT
    );
    if (!isNumber(redirectCount)) {
      LocalStorageService.setStorage(LocalStorageService.REDIRECT_COUNT, 1);
    }

    if (redirectCount < AuthService.MAXIMUM_REDIRECT_TIMES) {
      const _redirectCount = redirectCount + 1;
      LocalStorageService.setStorage(
        LocalStorageService.REDIRECT_COUNT,
        _redirectCount
      );
    } else {
      AuthService.resetRedirectCount();
    }
  };

  static resetRedirectCount = () => {
    LocalStorageService.setStorage(LocalStorageService.REDIRECT_COUNT, 0);
  };

  static storeCsrfTokenHandler = <T>(res: T): T => {
    const csrfToken = queryCookieByKey(AuthService.CSRF_TOKEN_KEY);
    if (!csrfToken) return res;
    LocalStorageService.setStorage(LocalStorageService.CSRF_TOKEN, csrfToken);
    return res;
  };
}
