import classNames from "classnames";
import React, { FC } from "react";
import { mergeProps } from "../utils/mergeDefaultProps";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import styles from "./Space.module.scss";

const classPrefix = `adm-space`;

export type SpaceProps = {
  direction?: "horizontal" | "vertical";
  align?: "start" | "end" | "center" | "baseline";
  justify?:
    | "start"
    | "end"
    | "center"
    | "between"
    | "around"
    | "evenly"
    | "stretch";
  wrap?: boolean;
  block?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: React.ReactNode;
} & NativeProps<"--gap" | "--gap-vertical" | "--gap-horizontal">;

const defaultProps = {
  direction: "horizontal",
};

export const Space: FC<SpaceProps> = (p) => {
  const props = mergeProps(defaultProps, p);
  const { direction, onClick } = props;
  return withNativeProps(
    props,
    <div
      className={classNames(classPrefix, {
        [styles["space-wrap"]]: props.wrap,
        [styles["space-block"]]: props.block,
        [styles[`space-${direction}`]]: true,
        [styles[`space-align-${props.align}`]]: !!props.align,
        [styles[`space-justify-${props.justify}`]]: !!props.justify,
      })}
      onClick={onClick}
    >
      {React.Children.map(props.children, (child) => {
        return (
          child !== null &&
          child !== undefined && (
            <div className={styles["space-item"]}>{child}</div>
          )
        );
      })}
    </div>
  );
};
