import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { NormalRes } from "../../../../core/HttpService";
import { Action } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import { Role } from "../../../service/userRoleTypes";
import InstallationModel, { IInstallation } from "../installationModel";
import InstallationApSupervisorModel from "./InstallationApSupervisorModel";

export default function () {
  const [infos, setInfos] = React.useState<Partial<IInstallation>>(
    {} as Partial<IInstallation>
  );
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const [assignAndForwardVisible, setVisible] = React.useState(false);
  const [assignAndForwArdConfig, setConfig] = React.useState<
    Record<string, any>
  >({});
  const { id, userId } = useRecoilValue(userState);
  const systemReginOrg = useRecoilValue(systemUserOrgs);
  const rolePage = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);

  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const submitEnable = React.useMemo(
    () => InstallationApSupervisorModel.verifyAssign(infos),
    [infos]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getInfos(installationTaskId), [installationTaskId]);

  const getInfos = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getTrainingInfo(installationTaskId)
        .then((res) => setInfos(res?.data))
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [setLoading, toastError]
  );

  const changeHandler = React.useCallback(
    (key: keyof IInstallation, value: any) => {
      setInfos((pre) => {
        if (key !== InstallationModel.PLAN_TRAINING_START_DATE)
          return { ...pre, [key]: value };
        return {
          ...pre,
          [InstallationModel.PLAN_TRAINING_START_DATE]: value,
          [InstallationModel.PLAN_TRAINING_END_DATE]: undefined,
        };
      });
    },
    []
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const setForwardAndAssignHandler = React.useCallback(
    (action: Action) => {
      const { planedTrainingStartDate, planedTrainingEndDate } = infos;

      setVisible(true);
      if (action === Action.FORWARD) {
        setConfig({
          action: Action.FORWARD,
          role: Role.ROLE_APPLY_SUPERVISOR,
        });
        return;
      }
      setConfig({
        action: Action.ASSIGN,
        role: Role.ROLE_APPLY_ENGINEER,
        otherData: {
          planedTrainingStartDate,
          planedTrainingEndDate,
        },
      });
    },
    [infos]
  );

  const assignAndForwardHandler = React.useCallback(
    (res: Promise<NormalRes>) => {
      res
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setVisible(false));
    },
    [backAllTickHandler, toastError]
  );

  return {
    infos,
    rolePage,
    systemReginOrg,
    assignAndForwardVisible,
    submitEnable,
    assignAndForwArdConfig,
    id,
    userId,
    platform,
    locale,
    setVisible,
    assignAndForwardHandler,
    backAllTickHandler,
    setForwardAndAssignHandler,
    changeHandler,
  };
}
