import HttpService, { NormalRes } from "../../../../core/HttpService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  IInstallationReq,
  StaticSourceItem,
  submitInstallationInfo,
} from "../../../../services/order.service";
import { isBoolean } from "../../../../utils/isType";
import { LabelIndicator } from "../../../components/InputVariant/InputVariant";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { SalesAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  CustomerTypes,
  ProductLine,
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import { ReminderType } from "./../../../components/TextReminder/TextReminder";

export default class InstallationSaleModel {
  static submitInstallationInfo = (
    data: IInstallationReq
  ): Promise<NormalRes> => {
    const formatDate = {
      ...data,
      disclaimers: OrderRequestModel.formatUploadItem(
        data?.disclaimers as any[]
      ),
      euCs: OrderRequestModel.formatUploadItem(data?.euCs as any[]),
      deliveryNotes: OrderRequestModel.formatUploadItem(
        data?.deliveryNotes as any[]
      ),
      technicalAgreements: OrderRequestModel.formatUploadItem(
        data?.technicalAgreements as any[]
      ),
      specifications: OrderRequestModel.formatUploadItem(
        data?.specifications as any[]
      ),
      auditEmails: OrderRequestModel.formatUploadItem(
        data?.auditEmails as any[]
      ),
    };
    return HttpService.splitResponse(submitInstallationInfo(formatDate));
  };

  static verifySubmission = (infos: Partial<IInstallation>) => {
    const {
      customerType,
      isSiteSurvey,
      isSiteSurveyDone,
      warrantyPeriod,
      trainingRequired,
      productLine,
    } = infos;
    const keysMap = {
      [InstallationModel.DELIVERY_NOTES]:
        customerType === CustomerTypes.NOT_INDUSTRY,
      [InstallationModel.DISCLAIMERS]:
        (!isSiteSurvey || !isSiteSurveyDone) &&
        (productLine as ProductLine) !== ProductLine.LM,
      [InstallationModel.WARRANTY_PERIOD_COMMENTS]:
        warrantyPeriod === WarrantyPeriod.OTHER,
      [InstallationModel.AP_SUPERVISOR]: trainingRequired,
    };

    const variableKeys = Object.entries(keysMap)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    const keys = [
      ...variableKeys,
      InstallationModel.EXPECTED_INSTALL_DATA,
      InstallationModel.FAC_REQUIRED,
      InstallationModel.TRAINING_REQUIRED,
      InstallationModel.WARRANTY_PERIOD,
    ];

    return ValidationService.verifyIntegrity(infos, keys);
  };

  static convertApSupervisorList = (
    apSupervisor: StaticSourceItem[]
  ): StaticSourceItem[] => {
    if (!Array.isArray(apSupervisor) || !apSupervisor.length) return [];
    return apSupervisor.map(
      ({ text, userNameCN, userNameEN, value, email }) => ({
        value,
        text: InstallationSaleModel.joinUserName(userNameCN, userNameEN),
        subText: email,
      })
    );
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    pageStatus: PageStatus,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      disclaimers,
      isSiteSurveyDone,
      isSiteSurvey,
      customerType,
      productLine,
      auditEmails,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelDisclaimer,
      uploadReminder,
      labelDeliveryNoteOptional,
      labelDisclaimerNotice,
      labelAuditEmail,
      labelAuditEmailReminder,
      labelDisclaimerNotSiteSurvey,
    } = InstallationModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    const partProps = {
      disableUpload: disabled,
      deletable: !disabled,
    };
    const reminder = disabled ? "" : uploadReminder;
    const isIndustry = customerType === CustomerTypes.INDUSTRY;
    const labelDeliveryNote = isIndustry
      ? labelDeliveryNoteOptional
      : labelDeliverNotes;

    const _labelDisclaimerNotice = !isSiteSurvey
      ? labelDisclaimerNotSiteSurvey
      : isBoolean(isSiteSurveyDone) && !isSiteSurveyDone
      ? labelDisclaimerNotice
      : "";

    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: InstallationModel.AUDIT_EMAIL,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelAuditEmail,
          value: auditEmails ?? [],
          key: InstallationModel.AUDIT_EMAIL,
          reminder,
          noticeType: ReminderType.ERROR,
          notice: !disabled && labelAuditEmailReminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelDisclaimer,
          value: disclaimers ?? [],
          key: InstallationModel.DISCLAIMERS,
          reminder,
          notice: !disabled && _labelDisclaimerNotice,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelEurOrEuu,
          value: euCs ?? [],
          key: InstallationModel.EUC_EUU,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelDeliveryNote,
          value: deliveryNotes ?? [],
          key: InstallationModel.DELIVERY_NOTES,
          reminder,
          indicator: isIndustry
            ? LabelIndicator.TYPE_OPTIONAL
            : LabelIndicator.TYPE_REQUIRED,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          key: InstallationModel.TECHNICAL_AGR,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelSpecs,
          value: specifications ?? [],
          key: InstallationModel.SPEC,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
    ];
    const filteredList = originList.filter(({ key }) => {
      if (
        productLine === ProductLine.LM ||
        (isSiteSurvey && isSiteSurveyDone === true)
      )
        return key !== InstallationModel.DISCLAIMERS;
      return true;
    });

    return disabled
      ? filteredList.filter(({ props }) => props?.value.length)
      : filteredList;
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelSubmit } = OrderInfoModel.getLabels();

    const original = [
      {
        key: SalesAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SalesAction.ALL_TICKET,
        },
      },
      {
        key: SalesAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: SalesAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  private static joinUserName = (nameCN: string, nameEN: string): string => {
    if (!nameCN && !nameEN) return "";
    if (!nameCN && nameEN) return nameEN;
    if (!nameEN && nameCN) return nameCN;
    return `${nameCN} (${nameEN})`;
  };
}
