import PageWrapper from "../../../../layout/PageWrapper";
import FacIBaseFacUploadMobile from "./FacIBaseFacUploadMobile";
import FacIBaseFacUploadPc from "./FacIBaseFacUploadPc";

export default () => (
  <PageWrapper
    PagePc={FacIBaseFacUploadPc}
    PageMobile={FacIBaseFacUploadMobile}
  />
);
