import classNames from "classnames";
import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import { IDNInfo } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationAll.module.scss";
import InstallationAllModel from "./InstallationAllModel";
import useInstallationAll from "./useInstallationAll";

const InstallationAllPc: React.FC = ErrorDetectorPageView(() => {
  const { infos, platform, systemOrgs, locale, backAllTickHandler } =
    useInstallationAll();

  const installationUserInfoPcView = React.useMemo(() => {
    const list = InstallationAllModel.getInfoPcUserInfoViewModel(
      infos as Partial<IInstallation>,
      systemOrgs
    );

    const views = list.map(({ viewType, props, key }) => {
      return (
        <div className={styles.pcViewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, systemOrgs, locale]);

  const installationInfoTimePcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationAllModel.getInfoTimeViewModel(
      infos as Partial<IInstallation>
    );

    const views = list.map(({ viewType, props, key }) => {
      const _pcViewItem = classNames(styles.pcViewItem, {
        [styles.fullWidth]: key === InstallationModel.WARRANTY_PERIOD,
      });
      const propsMaps = new Map([
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;
      return (
        <div className={_pcViewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installationInfoPcView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, systemOrgs, locale]);

  const trainingInfoPcView = React.useMemo(() => {
    const list = InstallationAllModel.getTimeReadonlyViewModel(
      infos as Partial<IInstallation>,
      platform
    );
    const views = list.map(({ viewType, props, key }) => {
      return (
        <div className={styles.pcViewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={props} />
        </div>
      );
    });

    if (!list.length) return null;

    return <div className={styles.trainingInfoPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, locale]);

  const filesPcView = React.useMemo(() => {
    const list = InstallationAllModel.getFileInfoViewModel(
      infos as IInstallation,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, locale]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationAllModel.getButtonViewModel();
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <section className={styles.installationAllPc}>
      <div className={styles.pageContentPc}>
        {installationUserInfoPcView}
        {installationInfoTimePcView}
        {trainingInfoPcView}
        <OrderDnInfoView {...(infos.dnInfo as IDNInfo)} />
        {filesPcView}
      </div>
      {buttonsView}
    </section>
  );
});

export default InstallationAllPc;
