import classNames from "classnames";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { SystemPlatform } from "../../../../core/SystemService";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import OrderRequestModel from "../../model/OrderRequestModel";
import StepProcessModal from "../StepProcessModal/StepProcessModal";
import styles from "./OrderHeader.module.scss";
import OrderHeaderModel from "./OrderHeaderModel";
import useOrderHeader, { OrderHeaderProps } from "./useOrderHeader";

const OrderHeader: React.FC<OrderHeaderProps> = ErrorDetectorMediumView(
  (props: OrderHeaderProps) => {
    const { visible, _list, key, platform, changeHandler } =
      useOrderHeader(props);
    const _containerClassName = classNames({
      [styles.mobileView]: platform === SystemPlatform.ENV_MOBILE,
      [styles.pcView]: platform === SystemPlatform.ENV_PC,
    });

    const _className = classNames(styles.orderHeader, {
      [styles.pcHeader]: platform === SystemPlatform.ENV_PC,
    });

    const stepView = React.useMemo(() => {
      const stepLabel = OrderRequestModel.formatStepLabel(key);
      return (
        stepLabel && (
          <div className={styles.step} onClick={changeHandler.bind(null, true)}>
            <span className={styles.stepLabel}>{stepLabel}</span>
          </div>
        )
      );
    }, [changeHandler, key]);

    const textComponentClassName =
      platform === SystemPlatform.ENV_MOBILE ? styles.textMobile : undefined;

    return React.useMemo(
      () => (
        <div className={_className}>
          <div className={_containerClassName}>
            {_list.map(({ key, props, viewType }) => {
              const subOrderChildren = <div>{props?.children}</div>;
              const propsMap = new Map([
                [OrderHeaderModel.STEP, { ...props, children: stepView }],
                [
                  OrderHeaderModel.SUB_ORDER,
                  { ...props, children: subOrderChildren },
                ],
                [
                  OrderHeaderModel.FINAL_GOODS_RECIPE,
                  { ...props, indicatorCustom: <IndicatorNew /> },
                ],
              ]);
              const newProps =
                propsMap.get(key as keyof OrderHeaderModel) ?? props;
              return (
                <ViewComponent
                  key={key}
                  type={viewType as ViewType}
                  props={{
                    ...newProps,
                    key,
                    className: textComponentClassName,
                  }}
                />
              );
            })}
          </div>
          <StepProcessModal
            visible={visible}
            onClose={changeHandler.bind(null, false)}
          />
        </div>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [_list, stepView, visible]
    );
  }
);

export default OrderHeader;
