import React from "react";
import LoggerService from "../../core/LoggerService";
import SystemService, { SystemEnv } from "../../core/SystemService";
import styles from "./ErrorDecorator.module.scss";

// In future, will refine the error page interaction.
// Usage:
// Function component
//  ErrorDetect(component() => {})
// Class component:
//  @ErrorDetect
//  class ClassComponent {}

const ERROR_MESSAGE = "Something went wrong, you can try to refresh the page";
const ERROR_PREFIX = "components error";

/**
 * Use for small view ,likes input , button and basic component.
 * @param WrappedComponent
 * @returns
 */
export const ErrorDetectorSmallView = (WrappedComponent: any): any => {
  const isProd = SystemService.getEnv() === SystemEnv.ENV_PROD;
  return class Wrap extends React.Component {
    state = {
      hasError: false,
    };
    static getDerivedStateFromError(err: React.ErrorInfo) {
      return {
        hasError: true,
        err,
      };
    }

    componentDidCatch(err: Error, info: React.ErrorInfo) {
      LoggerService.log(ERROR_PREFIX, err, info);
    }

    renderText = () => {
      const text = isProd ? null : ERROR_MESSAGE;
      return <span className={styles.smallView}>{text}</span>;
    };

    render() {
      const { hasError } = this.state;
      const errText = this.renderText();
      return hasError ? errText : <WrappedComponent {...this.props} />;
    }
  };
};

/**
 * Use for medium view, likes basic component container
 * @param WrappedComponent
 * @returns
 */
export const ErrorDetectorMediumView = (WrappedComponent: any): any => {
  return class Wrap extends React.Component {
    state = {
      hasError: false,
    };
    static getDerivedStateFromError(err: React.ErrorInfo) {
      return {
        hasError: true,
        err,
      };
    }

    componentDidCatch(err: Error, info: React.ErrorInfo) {
      LoggerService.log(ERROR_PREFIX, err, info);
    }

    renderText = () => {
      return <span className={styles.mediumView}>{ERROR_MESSAGE}</span>;
    };

    render() {
      const { hasError } = this.state;
      const errText = this.renderText();
      return hasError ? errText : <WrappedComponent {...this.props} />;
    }
  };
};

/**
 * Use for modal view
 * @param WrappedComponent
 * @returns
 */
export const ErrorDetectorModalView = (WrappedComponent: any): any => {
  return class Wrap extends React.Component {
    state = {
      hasError: false,
    };
    static getDerivedStateFromError(err: React.ErrorInfo) {
      return {
        hasError: true,
        err,
      };
    }

    componentDidCatch(err: Error, info: React.ErrorInfo) {
      LoggerService.log(ERROR_PREFIX, err, info);
    }

    renderText = () => {
      return <span className={styles.mediumView}>{ERROR_MESSAGE}</span>;
    };

    render() {
      const { hasError } = this.state;
      const errText = this.renderText();
      return hasError ? errText : <WrappedComponent {...this.props} />;
    }
  };
};

/**
 * User for page view
 * @param WrappedComponent
 * @returns
 */
export const ErrorDetectorPageView = (WrappedComponent: any): any => {
  return class Wrap extends React.Component {
    state = {
      hasError: false,
    };
    static getDerivedStateFromError(err: React.ErrorInfo) {
      return {
        hasError: true,
        err,
      };
    }

    componentDidCatch(err: Error, info: React.ErrorInfo) {
      LoggerService.log(ERROR_PREFIX, err, info);
    }

    renderText = () => {
      return <span className={styles.pageView}>{ERROR_MESSAGE}</span>;
    };
    render() {
      const errText = this.renderText();
      const { hasError } = this.state;
      return hasError ? errText : <WrappedComponent {...this.props} />;
    }
  };
};
