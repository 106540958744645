import React from "react";
import useLang from "../../hooks/useLang";

export enum ConfirmType {
  cancel = "cancel",
  ok = "ok",
}

export interface SearchOption {
  text: string;
  value: any;
  subText?: string;
}

export interface SearchSelectorProps {
  options: SearchOption[];
  value?: any;
  onClose?: (type: ConfirmType, value?: any) => void;
}

export default function (props: SearchSelectorProps) {
  const { options, value, onClose } = props;
  const { labelCancel, labelConfirm } = useLang({
    labelCancel: { id: "common_btn_cancel" },
    labelConfirm: { id: "common_btn_confirm" },
  });

  const [stateOptions, setOptions] = React.useState(() => options);

  const [stateValue, setState] = React.useState(value);

  React.useEffect(() => setOptions(options), [options]);

  const changeHandler = React.useCallback(
    (value: any) => {
      const { text } =
        stateOptions.find(({ value: originValue }) => originValue === value) ??
        {};
      setState(text as string);
    },
    [stateOptions]
  );

  const confirmHandler = React.useCallback(
    (actionType: ConfirmType) => {
      if (actionType === ConfirmType.cancel) {
        onClose?.(ConfirmType.cancel);
        return;
      }
      const { value } =
        stateOptions.find((item) => item?.text === stateValue) ?? {};
      // if (value) {
      onClose?.(ConfirmType.ok, value);
      // }
    },
    [stateOptions, stateValue, onClose]
  );

  const clearHandler = React.useCallback(() => {
    setState("");
    setOptions(options);
  }, [options]);

  const searchHandler = React.useCallback(
    (evt: any) => {
      const { value } = evt?.target ?? {};
      const _value = value?.trim();
      const newOptions = _value
        ? options.filter(
            ({ text, subText }) =>
              text?.includes(value) || subText?.includes(value)
          )
        : options;
      setState(value);
      setOptions(newOptions);
    },
    [options]
  );

  return {
    stateOptions,
    stateValue,
    labelCancel,
    labelConfirm,
    changeHandler,
    clearHandler,
    searchHandler,
    confirmHandler,
  };
}
