import { Button, TextArea } from "@zeiss/ods-components-react";
import React from "react";
import { useRecoilValue } from "recoil";
import {
  ConfirmVariantWithIcon,
  IConfirmVariantWithIcon,
} from "../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import Confirmor, {
  CloseType,
  IConfirmor,
} from "../../../components/Confirmor/Confirmor";
import { ErrorDetectorModalView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { SystemPlatform } from "../../../core/SystemService";
import { platformState } from "../../../store";
import { isFunction } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import styles from "./RejectModalVariant.module.scss";

interface IRejectModalVariant
  extends Omit<IConfirmor, "content" | "buttonTexts" | "onConfirm">,
    Omit<IConfirmVariantWithIcon, "content" | "buttonTexts" | "onConfirm"> {
  onConfirm: (type: CloseType, reason?: string) => void;
}

const RejectModalVariant: React.FC<IRejectModalVariant> =
  ErrorDetectorModalView((props: IRejectModalVariant) => {
    const [value, setValue] = React.useState("");
    const { onConfirm, ...rest } = props;
    const { reason, rejectPhd, cancel, confirm } = useLang({
      reason: { id: "order_reject_reason" },
      rejectPhd: { id: "order_reject_pdh" },
      cancel: { id: "common_btn_cancel" },
      confirm: { id: "common_btn_confirm" },
    });
    const platform = useRecoilValue(platformState);
    const changeHandler = React.useCallback((evt: any) => {
      setValue(evt?.target?.value);
    }, []);

    const ConfirmComponent =
      platform === SystemPlatform.ENV_PC ? ConfirmVariantWithIcon : Confirmor;

    const confirmHandler = React.useCallback(
      (type: CloseType) => {
        if (type === "cancel") {
          isFunction(onConfirm) && onConfirm("cancel");
          return;
        }
        isFunction(onConfirm) && onConfirm("ok", value);
      },
      [onConfirm, value]
    );

    const textAreaView = React.useMemo(() => {
      return (
        <div className={styles.textArea}>
          <TextArea
            label={reason}
            value={value}
            onChange={changeHandler}
            placeholder={rejectPhd}
            rows={4}
          />
        </div>
      );
    }, [reason, value, changeHandler, rejectPhd]);

    const confirmButtonMobileView = React.useMemo(
      () => (
        <div className={styles.confirmButtons}>
          <Button
            className={styles.confirmBtn}
            variant="secondary"
            onClick={confirmHandler.bind(null, "cancel")}
          >
            {cancel}
          </Button>
          <Button
            className={styles.confirmBtn}
            variant="primary"
            onClick={confirmHandler.bind(null, "ok")}
            disabled={!value?.trim()}
          >
            {confirm}
          </Button>
        </div>
      ),
      [cancel, confirm, confirmHandler, value]
    );

    const confirmButtonPcView = React.useMemo(
      () => (
        <div className={styles.confirmButtonsPc}>
          <Button
            variant="secondary"
            onClick={confirmHandler.bind(null, "cancel")}
          >
            {cancel}
          </Button>
          <Button
            variant="primary"
            onClick={confirmHandler.bind(null, "ok")}
            disabled={!value?.trim()}
          >
            {confirm}
          </Button>
        </div>
      ),
      [cancel, confirm, confirmHandler, value]
    );

    const buttonView =
      platform === SystemPlatform.ENV_PC
        ? confirmButtonPcView
        : confirmButtonMobileView;

    return (
      <ConfirmComponent
        {...rest}
        content={textAreaView}
        footer={buttonView}
        destroyOnClose={true}
        onConfirm={confirmHandler}
      />
    );
  });

export default RejectModalVariant;
