import * as React from "react";

import classNames from "classnames";
import styles from "./Accordion.module.scss";
import { AccordionProps } from "./types";
import { AccordionContext, useAccordion } from "./useAccordion";

export const Accordion = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AccordionProps>
>(({ children, chevronSize = 20, className, ...ownProps }, ref) => {
  const { htmlProps, ...context } = useAccordion({ ...ownProps, chevronSize });
  const ctx = React.useMemo(() => ({ ...context }), [context]);
  const _className = classNames(styles.accordion, className);
  return (
    <AccordionContext.Provider value={ctx}>
      <div className={_className} ref={ref} {...htmlProps}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
});
