import classNames from "classnames";
import React from "react";
import { ErrorDetectorModalView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import { formatDatePickValue } from "../../../../../../../utils/commonUtils";
import CheckboxCabinet from "../../../../../../components/CheckboxCabinet/CheckboxCabinet";
import DatePickerVariant from "../../../../../../components/DatePickerVariant/DatePickerVariant";
import FoldCabinet from "../../../../../../components/FoldCabinet/FoldCabinet";
import SwitchCabinet from "../../../../../../components/SwitchCabinet/SwitchCabinet";
import TextReminder, {
  ReminderType,
} from "../../../../../../components/TextReminder/TextReminder";
import UploadVariant from "../../../../../../components/UploadVariant/UploadVariant";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import { PageStatus } from "../../../../../../service/pageType";
import SiteSurveyMobileModel from "../../../../../SiteSurvey/model/SiteSurveyMobileModel";
import SiteSurveyModel, {
  AccessoryProperties,
  AccessoryTypes,
} from "../../../../../SiteSurvey/model/SiteSurveyModel";
import { ViewItemModel } from "../../../../../model/OrderRequestModel";
import OrderInfoModel from "../../../InfoSalesModel";
import useInfoSiteSurvey, {
  InfoSiteSurveyProps,
  defaultRecord,
} from "../../../hooks/useInfoSiteSurvey";
import styles from "./OrderInfoSiteSurvey.module.scss";

const OrderInfoSiteSurvey: React.FC<InfoSiteSurveyProps> =
  ErrorDetectorModalView((props: InfoSiteSurveyProps) => {
    const {
      isRsmNeedSiteSurvey,
      reminderNeedSiteSurvey,
      isSiteSurvey,
      reminderYes,
      reminderNo,
      siteSurvey,
      party3ViewTitle,
      part3ViewStatus,
      thirdParty,
      disclaimer,
      pageStatus,
      expectedSiteSurvey,
      labelDisclaimer,
      uploadReminder,
      expectedStartDate,
      showDisclaimer,
      locale,
      dateChangeHandler,
      subChangeHandler,
      checkboxChangeHandler,
      setPart3ViewStatus,
      fileChangHandler,
      onChange,
    } = useInfoSiteSurvey(props);

    const subAccessoryView = React.useCallback(
      (key: AccessoryTypes) => {
        const modelsMap = new Map([
          [
            AccessoryTypes.AIRLOCK,
            SiteSurveyMobileModel.getAirLockItemModifyModels(
              {
                ...(thirdParty[AccessoryTypes.AIRLOCK] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.CRYO,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.CRYO] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.LASER,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.LASER] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.VIEW3,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.VIEW3] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.COLD_TABLE,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[
                  AccessoryTypes.COLD_TABLE
                ] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.CL,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.CL] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.ENERGY_SPECTRUM,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[
                  AccessoryTypes.ENERGY_SPECTRUM
                ] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.EBSD,
            SiteSurveyMobileModel.getCryoItemLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.EBSD] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.VIBRATION_ISOLATION,
            SiteSurveyMobileModel.getVibrationLikeModifyModels(
              {
                ...(thirdParty[
                  AccessoryTypes.VIBRATION_ISOLATION
                ] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.DEMAGNETIZER,
            SiteSurveyMobileModel.getVibrationLikeModifyModels(
              {
                ...(thirdParty[
                  AccessoryTypes.DEMAGNETIZER
                ] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.UPS,
            SiteSurveyMobileModel.getUPSLikeModifyModels(
              {
                ...(thirdParty[AccessoryTypes.UPS] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
          [
            AccessoryTypes.OTHER,
            SiteSurveyMobileModel.getOtherItemModifyModels(
              {
                ...(thirdParty[AccessoryTypes.OTHER] as AccessoryProperties),
              },
              pageStatus
            ),
          ],
        ]);

        return modelsMap.get(key);
      },
      [pageStatus, thirdParty]
    );

    const siteSurveyRequiredView = React.useMemo(() => {
      const reminder = isRsmNeedSiteSurvey
        ? reminderNeedSiteSurvey
        : isSiteSurvey
        ? reminderYes
        : reminderNo;
      const reminderType = isRsmNeedSiteSurvey
        ? ReminderType.ERROR
        : ReminderType.INFO;
      return (
        <div className={styles.siteSurvey}>
          <SwitchCabinet
            cabinetLabel={siteSurvey}
            cabinetClassName={styles.cabinetCls}
            checked={isSiteSurvey}
            disabled={pageStatus === PageStatus.READONLY}
            onChange={(isChecked: boolean) =>
              onChange({ key: OrderInfoModel.IS_SITE_SURVEY, value: isChecked })
            }
          />
          {showDisclaimer && (
            <div className={styles.reminderContainer}>
              <TextReminder label={reminder} reminderType={reminderType} />
            </div>
          )}
        </div>
      );
    }, [
      isRsmNeedSiteSurvey,
      reminderNeedSiteSurvey,
      isSiteSurvey,
      reminderYes,
      reminderNo,
      siteSurvey,
      pageStatus,
      showDisclaimer,
      onChange,
    ]);

    const accessoriesView = React.useMemo(() => {
      const list = Object.entries(defaultRecord);
      const disabled = pageStatus === PageStatus.READONLY;
      const _accessoryCls = classNames(styles.accessoryCls, {
        [styles.active3rdParty]: part3ViewStatus,
      });

      return (
        <FoldCabinet
          label={party3ViewTitle}
          status={part3ViewStatus}
          onChange={(status) => setPart3ViewStatus(status)}
          cabinetClassName={_accessoryCls}
        >
          <div className={styles.accessoryContainer}>
            {list?.map(([key]) => {
              const label = SiteSurveyModel.getAccessoriesLabels(
                key as AccessoryTypes
              );
              const viewList = subAccessoryView(
                key as AccessoryTypes
              ) as Partial<ViewItemModel>[];
              const checked = thirdParty.hasOwnProperty(key);

              return (
                <CheckboxCabinet
                  label={label}
                  checked={checked}
                  key={label}
                  disabled={disabled}
                  cabinetClassName={styles.checkboxCabinetCls}
                  onChange={(checked: any | boolean) => {
                    checkboxChangeHandler(key as AccessoryTypes, checked);
                  }}
                >
                  <div className={styles.partySubItemView}>
                    {viewList?.map(
                      ({ viewType, key: subKey, props }, index) => {
                        const _props = {
                          ...props,
                          value: props?.value ?? "",
                          key: `${key}_${subKey}`,
                          label:
                            viewList?.length > 1
                              ? `${index + 1}. ${props?.label}`
                              : props?.label,
                          onChange: (result: any) =>
                            subChangeHandler(
                              key as string,
                              subKey as string,
                              viewType as ViewType,
                              result
                            ),
                          variantClassName:
                            subKey === SiteSurveyModel.SPEC
                              ? styles.airlockSpecialCls
                              : "",
                        };

                        return (
                          <ViewComponent
                            key={`${key}_${subKey}`}
                            type={viewType as ViewType}
                            props={_props}
                          />
                        );
                      }
                    )}
                  </div>
                </CheckboxCabinet>
              );
            })}
          </div>
        </FoldCabinet>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      pageStatus,
      part3ViewStatus,
      party3ViewTitle,
      thirdParty,
      locale,
      setPart3ViewStatus,
      subAccessoryView,
      checkboxChangeHandler,
      subChangeHandler,
    ]);

    const siteSurveyRequiredSubView = React.useMemo(() => {
      const disabled = pageStatus === PageStatus.READONLY;
      const formattedDate = formatDatePickValue(expectedStartDate);
      const [min, max] = OrderInfoModel.getSiteSurveyExpectedDateLimit();
      return (
        <div className={styles.dataPick}>
          <DatePickerVariant
            label={expectedSiteSurvey}
            value={formattedDate}
            onConfirm={dateChangeHandler}
            disabled={disabled}
            min={min}
            max={max}
          />
          <div className={styles.accessories}>{accessoriesView}</div>
        </div>
      );
    }, [
      pageStatus,
      expectedStartDate,
      expectedSiteSurvey,
      accessoriesView,
      dateChangeHandler,
    ]);

    const uploadFileView = React.useMemo(() => {
      const disabled = pageStatus === PageStatus.READONLY;

      return (
        <div className={styles.uploadContainer}>
          <UploadVariant
            title={labelDisclaimer}
            reminder={uploadReminder}
            maxCount={5}
            onChange={fileChangHandler}
            value={disclaimer}
            deletable={!disabled}
            disableUpload={disabled}
          />
        </div>
      );
    }, [
      disclaimer,
      labelDisclaimer,
      pageStatus,
      uploadReminder,
      fileChangHandler,
    ]);

    return (
      <div className={styles.orderInfoSiteSurvey}>
        {siteSurveyRequiredView}
        {!isSiteSurvey && showDisclaimer && uploadFileView}
        {isSiteSurvey && siteSurveyRequiredSubView}
      </div>
    );
  });

export default OrderInfoSiteSurvey;
