import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React, { useMemo } from "react";
import { isNumber } from "../../../../utils/isType";
import { ReactDatePickerHeaderProps } from "../InputVariantDatePicker";
import styles from "./DatePickerHeader.module.scss";
import { DatePickerYearDropdown } from "./DatepickerYearDropdown";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getCurrentYear(): number {
  return new Date().getFullYear();
}

function createYearsArray({
  from,
  toExclusive: to,
}: {
  from: number;
  toExclusive: number;
}) {
  if (to === from) {
    return [];
  }

  if (to < from) {
    throw new Error(`'to' (${to}) is not larger than 'from' (${from}).`);
  }

  if (!Number.isInteger(from) || !Number.isInteger(to)) {
    throw new Error(`Numbers must be integer, but received ${from} ${to}`);
  }

  return new Array(to - from).fill(0).map((_, index) => from + index);
}

type YearSelection = "past" | "future" | "default" | "all";

/**
 * Exported only for testing purposes.
 *
 * @param mode
 */
export function createYearsRange(mode: YearSelection): {
  fromYear: number;
  toYear: number;
} {
  const currentYear = getCurrentYear();
  let from = currentYear;
  let to = currentYear + 1;

  if (mode === "past" || mode === "all") {
    // According to wikipedia, current oldest person is Maria Branyas, born in 1907.
    from = 1907;
  }

  if (mode === "future" || mode === "all") {
    to = currentYear + 150;
  }

  if (mode === "past" || mode === "default") {
    from = currentYear - 10;
  }

  if (mode === "future" || mode === "default") {
    to = currentYear + 10;
  }
  return {
    fromYear: from,
    toYear: to,
  };
}

export function createDateRange(mode: YearSelection): {
  from: Date;
  to: Date;
} {
  const range = createYearsRange(mode);
  return {
    from: new Date(range.fromYear, 0),
    to: new Date(range.toYear, 0),
  };
}

function createYearsPreset(
  mode: YearSelection,
  maxTime?: Date,
  minTime?: Date
) {
  const { fromYear: from, toYear: to } = createYearsRange(mode);
  const yearArray = createYearsArray({ from, toExclusive: to });
  if (!(maxTime instanceof Date) || !(minTime instanceof Date))
    return yearArray;
  const maxYear = maxTime.getFullYear(),
    minYear = minTime.getFullYear();

  return [...new Set([minYear, maxYear])];
}

type Props = {
  selection?: YearSelection;
  headerProps: ReactDatePickerHeaderProps;
};

export const DatePickerHeader: React.FC<Props> = ({
  selection = "default",
  headerProps: {
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    maxDate,
    minDate,
  },
}) => {
  const years = useMemo(
    () => createYearsPreset(selection, maxDate, minDate),
    [maxDate, minDate, selection]
  );
  const prevMonthButtonCls = classNames(styles.stepperButton, {
    [styles.stepperButtonDisabled]: prevMonthButtonDisabled,
  });
  const nextMonthButtonCls = classNames(styles.stepperButton, {
    [styles.stepperButtonDisabled]: nextMonthButtonDisabled,
  });
  const _data =
    date instanceof Date ? date : isNumber(date) ? new Date(date) : undefined;
  if (!_data) return null;

  return (
    <div className={styles.datePickerHeaderRoot}>
      <div onClick={decreaseMonth} className={prevMonthButtonCls}>
        <Icon icon="ChevronBack" size={16} />
      </div>
      <div className={styles.pickerDateTitle}>{months[_data.getMonth()]}</div>
      <div onClick={increaseMonth} className={nextMonthButtonCls}>
        <Icon icon="ChevronNext" size={16} />
      </div>
      <div className={styles.spacer} />
      <div className={styles.pickerYearSelectWrapper}>
        <DatePickerYearDropdown
          selectedYear={_data.getFullYear()}
          years={years}
          onChange={changeYear}
        />
      </div>
    </div>
  );
};
