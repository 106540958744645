import classNames from "classnames";
import { useRecoilValue } from "recoil";
import {
  AppType,
  BasicView,
  IBasicViewProps,
} from "../../../components/ViewWrapper/ViewWrapper";
import { LocaleLang } from "../../../core/LocaleService";
import { localeState } from "../../../store";
import styles from "./TextVariantPc.module.scss";

export interface TextVariantPcProps extends Omit<IBasicViewProps, "viewType"> {
  withoutColon?: boolean;
}

const TextVariantPc: React.FC<IBasicViewProps> = (
  props: TextVariantPcProps
) => {
  const {
    labelTextClassName,
    containerClassName,
    className,
    label,
    withoutColon,
  } = props;
  const lang = useRecoilValue(localeState);

  const textCls = classNames(styles.label, labelTextClassName);
  const containerCls = classNames(styles.content, containerClassName);
  const cls = classNames(styles.container, className);
  const colon = withoutColon
    ? undefined
    : lang === LocaleLang.ZH_CN
    ? ":"
    : ":";

  return (
    <BasicView
      {...props}
      label={label}
      punctuation={colon}
      viewType={AppType.TYPE_MOBILE}
      labelTextClassName={textCls}
      containerClassName={containerCls}
      labelClassName={styles.labelContainer}
      className={cls}
    />
  );
};

export default TextVariantPc;
