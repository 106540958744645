import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { IUserInfo } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import FacIBaseModel, { IFacIBase } from "../FacIBaseModel";

export default function () {
  const [facIBaseInfo, setInfo] = React.useState<Partial<IFacIBase>>({});
  const navigate = useNavigate();
  const [errCode, setError] = React.useState<ErrorCode>();
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC);
  const [visible, setVisible] = React.useState(false);
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);
  const { usersRole } = (useRecoilValue(userState) as IUserInfo) ?? {};
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  const { labelDateReminder } = useLang({
    labelDateReminder: { id: "order_crm_date_reminder" },
  });

  React.useEffect(() => {
    if (!installationTaskId) return;
    getInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      setLoading(true);
      FacIBaseModel.getFacIBaseInfo(installationTaskId, locale)
        .then((res) => {
          setInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    facIBaseInfo,
    labelDateReminder,
    visible,
    rolePage,
    platform,
    locale,
    setVisible,
    backAllTickHandler,
  };
}
