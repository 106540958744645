import React from "react";

interface IIcon {
  fillColor?: string;
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { fillColor, size } = props;
  const fill = fillColor ? fillColor : "#828D9E";
  const width = size === "auto" ? "100%" : "33",
    height = size === "auto" ? "100%" : "32";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50191 16.03L9.50195 15.03L10.502 15.03L10.5019 16.03L9.50191 16.03Z"
        fill="#CD4B13"
      />
      <path d="M9.50195 8.03003V13.03H10.502V8.03003H9.50195Z" fill="#CD4B13" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 19.03L10.002 0.0300293L0.00195312 19.03H20.002ZM1.65832 18.03H18.3456L10.002 2.17712L1.65832 18.03Z"
        fill="#CD4B13"
      />
    </svg>
  );
}
