import { isEmpty, isNumber } from "lodash";
import { LabelIndicator } from "../../../../../components/ViewWrapper/ViewWrapper";
import { formatDate } from "../../../../../utils/commonUtils";
import { ViewType } from "../../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../../service/pageType";
import { OrderStepSummary } from "../../../../service/roleStateService/RoleStepPageService";
import { SalesAction } from "../../../../service/userRoleTypes";
import OrderRequestModel, {
  IDNInfo,
  IFinalGoodsReceipt,
  IPartnerTeamOtherInfo,
  ViewItemModel,
} from "../../../model/OrderRequestModel";
import { VerifyType } from "../../../service/ValidationService";
import InfoSalesModel, { ButtonMore, IContactPerson } from "../InfoSalesModel";

export default class InfoSalesMobileModel extends InfoSalesModel {
  static getSaleBasicModifyModels = (
    infos: Partial<IPartnerTeamOtherInfo>,
    pageStatus?: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { incoterms, customerType, region, productLine, saleTeam } =
      infos ?? {};
    const {
      labelIncoterms,
      labelCustomerType,
      labelRegin,
      labelProductLine,
      labelSaleTeam,
    } = InfoSalesModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: InfoSalesModel.INCOTERMS,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelIncoterms, children: incoterms },
      },
      {
        key: InfoSalesModel.CUSTOMER_TYPE,
        viewType: ViewType.VIEW_SELECT,
        props: { label: labelCustomerType, value: customerType, disabled },
        verifyType: [VerifyType.TYPE_NUMBER],
      },
      {
        key: InfoSalesModel.SALE_TEAM,
        viewType: ViewType.VIEW_SELECT,
        props: { label: labelSaleTeam, value: saleTeam, disabled },
        verifyType: [VerifyType.TYPE_NUMBER],
      },
      {
        key: InfoSalesModel.REGION,
        viewType: ViewType.VIEW_SELECT,
        props: { label: labelRegin, value: region, disabled },
        verifyType: [VerifyType.TYPE_NUMBER],
      },
      {
        key: InfoSalesModel.PRODUCT_LINE,
        viewType: ViewType.VIEW_SELECT,
        props: { label: labelProductLine, value: productLine, disabled },
        verifyType: [VerifyType.TYPE_NUMBER],
      },
    ];
  };

  static getStepAuthorModels = (
    infos: Partial<IPartnerTeamOtherInfo>,
    orderStepSummary: OrderStepSummary
  ): Partial<ViewItemModel>[] => {
    const {
      salesEmployee,
      salesManager,
      so,
      rsm,
      fseSiteSurvey,
      fseInstallation,
      apSupervisor,
      apEngineer,
      facUploader,
    } = infos ?? {};
    const { facRequired, trainingRequired, siteSurveyRequired } =
      orderStepSummary as OrderStepSummary;

    const {
      labelInfoSaleEmp,
      labelInfoSaleManager,
      labelServiceCoo,
      labelOwnerRsm,
      labelApSupervisor,
      labelApEngineer,
      labelFseSiteSurvey,
      labelFseInstallation,
      labelFacUploader,
    } = InfoSalesModel.getLabels();
    const list = [
      {
        key: InfoSalesModel.SALE_EMPLOYEE,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelInfoSaleEmp, children: salesEmployee },
      },
      {
        key: InfoSalesModel.SERVICE_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelOwnerRsm, children: rsm },
      },
      {
        key: InfoSalesModel.FSE_SITE_SURVEY,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelFseSiteSurvey, children: fseSiteSurvey },
        hidden: !siteSurveyRequired,
      },
      {
        key: InfoSalesModel.FSE_INSTALLATION,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelFseInstallation, children: fseInstallation },
      },
      {
        key: InfoSalesModel.SERVICE_COORDINATOR,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelServiceCoo, children: so },
      },
      {
        key: InfoSalesModel.SALE_MANAGER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelInfoSaleManager, children: salesManager },
      },
      {
        key: InfoSalesModel.FAC_UPLOADER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelFacUploader, children: facUploader },
        hidden: !facRequired || !facUploader,
      },
      {
        key: InfoSalesModel.AP_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelApSupervisor, children: apSupervisor },
        hidden: !trainingRequired,
      },
      {
        key: InfoSalesModel.AP_ENGINEER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelApEngineer, children: apEngineer },
        hidden: !trainingRequired,
      },
    ];

    return list.filter(({ hidden }) => !hidden);
  };

  static getDNItemModels = (
    infos: IDNInfo,
    dnDelivery?: boolean
  ): Partial<ViewItemModel>[] => {
    const {
      cpoDate,
      installationOrderNo,
      serialNumber,
      serviceContractorOrder,
    } = infos;
    const {
      labelDnDelivery,
      labelDnCpoDate,
      labelDnInstallOrderNo,
      labelDnSerialNumber,
      labelDnServiceContactorOrder,
    } = InfoSalesModel.getLabels();
    const dnDeliveryLabel = OrderRequestModel.getToggleLabel(
      dnDelivery as boolean
    );
    return [
      {
        key: InfoSalesModel.DN_DELIVERY,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelDnDelivery, children: dnDeliveryLabel },
      },
      {
        key: InfoSalesModel.CPO_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelDnCpoDate, children: formatDate(cpoDate) },
      },
      {
        key: InfoSalesModel.INSTALLATION_ORDER_NO,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelDnInstallOrderNo, children: installationOrderNo },
      },
      {
        key: InfoSalesModel.SERIAL_NUMBER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelDnSerialNumber, children: serialNumber },
      },
      {
        key: InfoSalesModel.SERVICE_CONTRACTOR_ORDER,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelDnServiceContactorOrder,
          children: serviceContractorOrder,
        },
      },
    ];
  };

  static getDNMoreDetailModels = (
    infos: Pick<IDNInfo, "dnItems">
  ): Partial<ViewItemModel>[] => {
    const { dnItems } = infos ?? {};
    if (isEmpty(dnItems) || !Array.isArray(dnItems)) return [];
    dnItems.sort((pre, next) => {
      if (!isNumber(pre.itemNo) || !isNumber(next.itemNo)) return -1;
      return pre.itemNo - next.itemNo;
    });
    return dnItems.map(
      ({ itemDescription, itemMaterial, itemOrderQuantity }) => ({
        key: itemDescription,
        props: { itemDescription, itemMaterial, itemOrderQuantity },
      })
    );
  };

  static getContactModels = (
    infos: Omit<IContactPerson, "name">
  ): Partial<ViewItemModel>[] => {
    const { mobile, email, recipCity, recipAddress } = infos ?? {};
    const {
      labelContactMobile,
      labelContactEmailOptional,
      labelRecipeCity,
      labelRecipeAddress,
    } = InfoSalesModel.getLabels();

    return [
      {
        key: InfoSalesModel.MOBILE_PHONE,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelContactMobile, children: mobile },
      },
      {
        key: InfoSalesModel.EMAIL,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelContactEmailOptional, children: email },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
    ];
  };

  static getPartnerItem = (
    partnerItem: Omit<IFinalGoodsReceipt, "name">
  ): Partial<ViewItemModel>[] => {
    const { recipCity, recipAddress } = partnerItem ?? {};
    const { labelRecipeCity, labelRecipeAddress } = this.getLabels();
    return [
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
    ];
  };

  static getContactModifyModel = (
    infos: IContactPerson
  ): Partial<ViewItemModel>[] => {
    const { name, mobile, email, recipCity, recipAddress } = infos ?? {};
    const {
      labelCompanyName,
      labelContactMobile,
      labelContactEmailOptional,
      labelRecipeCity,
      labelRecipeAddress,
    } = InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.CONTACT_NAME,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelCompanyName, value: name, maxLength: 20 },
      },
      {
        key: InfoSalesModel.MOBILE_PHONE,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING],
        props: {
          label: labelContactMobile,
          value: mobile,
          type: "number",
        },
      },
      {
        key: InfoSalesModel.EMAIL,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_EMAIL, VerifyType.OPTIONAL],
        props: {
          label: labelContactEmailOptional,
          value: email,
          inputType: VerifyType.TYPE_EMAIL,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.LENGTH_MAX_COMMON, VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeCity,
          value: recipCity,
          maxLength: 20,
        },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.LENGTH_MAX_COMMON, VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeAddress,
          value: recipAddress,
          maxLength: 100,
        },
      },
    ];
  };

  static getPartnerModifyItem = (
    partnerItem: IFinalGoodsReceipt
  ): Partial<ViewItemModel>[] => {
    const { name, recipCity, recipAddress } = partnerItem ?? {};
    const { labelRecipeCity, labelRecipeAddress, labelCompanyName } =
      InfoSalesModel.getLabels();

    return [
      {
        key: InfoSalesModel.COMPANY_NAME,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelCompanyName, value: name, maxLength: 50 },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelRecipeCity, value: recipCity, maxLength: 100 },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeAddress,
          value: recipAddress,
          maxLength: 100,
        },
      },
    ];
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const {
      labelAllTicket,
      labelForward,
      labelCancelOrder,
      labelSubmit,
      labelMore,
    } = InfoSalesModel.getLabels();

    const original = [
      {
        key: SalesAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SalesAction.ALL_TICKET,
        },
      },
      {
        key: SalesAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON_MORE_VARIANT,
        props: {
          actions: [
            { value: ButtonMore.FORWARD, text: labelForward },
            { value: ButtonMore.CANCEL_ORDER, text: labelCancelOrder },
          ],
          children: labelMore,
          key: SalesAction.FORWARD,
        },
      },
      {
        key: SalesAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: SalesAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
