import React from "react";
import { IInputBasicProps, InputBasic } from "../../../components/Input";
import {
  LabelIndicator,
  LabelIndicatorIcon,
} from "../../../components/ViewWrapper/ViewWrapper";
import ValidationService, {
  VerifyType,
} from "../../OrderRequest/service/ValidationService";
import useLang from "../../hooks/useLang";

export interface InputBasicWrapperProps extends IInputBasicProps {
  inputType?: Partial<VerifyType>;
}

export default function InputBasicVariant(props: InputBasicWrapperProps) {
  const { inputType, indicator, ...rest } = props;
  const [error, setError] = React.useState<string | undefined>();
  const { invalidEmail, invalidPhone } = useLang({
    invalidEmail: { id: "error_invalid_email" },
    invalidPhone: { id: "error_invalid_phone" },
  });

  const verify = React.useCallback(() => {
    const { value, indicator } = props;
    const isOptional =
      indicator === LabelIndicator.TYPE_OPTIONAL && `${value}`.length === 0;
    const errorMaps = new Map([
      [VerifyType.TYPE_EMAIL, invalidEmail],
      [VerifyType.TYPE_PHONE, invalidPhone],
    ]);
    const errorMsg = errorMaps.get(inputType as VerifyType);
    const valid = ValidationService.getVerifier({
      type: inputType as VerifyType,
      value,
    });
    const error = !isOptional && !valid ? errorMsg : undefined;
    setError(error);
  }, [inputType, invalidEmail, invalidPhone, props]);

  React.useEffect(() => verify(), [props.value, inputType, verify]);

  return <InputBasic {...(rest as any)} error={error} indicator={indicator} />;
}

InputBasicVariant.defaultProps = {
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};
