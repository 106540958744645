import { animated, useSpring } from "@react-spring/web";
import {
  EventTypes,
  FullGestureState,
  useDrag,
  useWheel,
} from "@use-gesture/react";
import isEqual from "lodash/isEqual";
import React, { ReactNode, memo, useRef } from "react";
import { bound } from "../../utils/bound";
import { measureCSSLength } from "../../utils/measureCssLength";
import { rubberbandIfOutOfBounds } from "../../utils/rubberband";
import { supportsPassive } from "../../utils/supportsPassive";
import styles from "./PickerView.module.scss";
import { PickerColumnItem, PickerValue } from "./index";

type Props = {
  index: number;
  column: PickerColumnItem[];
  value: PickerValue;
  onSelect: (value: PickerValue, index: number) => void;
  renderLabel: (item: PickerColumnItem) => ReactNode;
  mouseWheel: boolean;
};

export const Wheel = memo<Props>(
  (props) => {
    const { value, column, renderLabel } = props;
    function onSelect(val: PickerValue) {
      props.onSelect(val, props.index);
    }

    const [{ y }, api] = useSpring(() => ({
      from: { y: 0 },
      config: {
        tension: 400,
        mass: 0.8,
      },
    }));

    const draggingRef = useRef(false);

    const rootRef = useRef<HTMLDivElement>(null);

    const itemHeightMeasureRef = useRef<HTMLDivElement>(null);
    const itemHeight = useRef<number>(34);

    React.useLayoutEffect(() => {
      const itemHeightMeasure = itemHeightMeasureRef.current;
      if (!itemHeightMeasure) return;
      itemHeight.current = measureCSSLength(
        window.getComputedStyle(itemHeightMeasure).getPropertyValue("height")
      );
    });

    React.useLayoutEffect(() => {
      if (draggingRef.current) return;
      if (value === null) return;
      const targetIndex = column.findIndex((item) => item.value === value);
      if (targetIndex < 0) return;
      const finalPosition = targetIndex * -itemHeight.current;
      api.start({ y: finalPosition, immediate: y.goal !== finalPosition });
    }, [value, column]);

    React.useLayoutEffect(() => {
      if (column.length === 0) {
        if (value !== null) {
          onSelect(null);
        }
      } else {
        if (!column.some((item) => item.value === value)) {
          const firstItem = column[0];
          onSelect(firstItem.value);
        }
      }
    }, [column, value]);

    function scrollSelect(index: number) {
      const finalPosition = index * -itemHeight.current;
      api.start({ y: finalPosition });
      const item = column[index];
      if (!item) return;
      onSelect(item.value);
    }

    const handleDrag = (
      state:
        | (Omit<FullGestureState<"wheel">, "event"> & {
            event: EventTypes["wheel"];
          })
        | (Omit<FullGestureState<"drag">, "event"> & {
            event: EventTypes["drag"];
          })
    ) => {
      draggingRef.current = true;
      const min = -((column.length - 1) * itemHeight.current);
      const max = 0;
      if (state.last) {
        draggingRef.current = false;
        const position =
          state.offset[1] + state.velocity[1] * state.direction[1] * 50;
        const targetIndex =
          min < max
            ? -Math.round(bound(position, min, max) / itemHeight.current)
            : 0;
        scrollSelect(targetIndex);
      } else {
        const position = state.offset[1];
        api.start({
          y: rubberbandIfOutOfBounds(
            position,
            min,
            max,
            itemHeight.current * 50,
            0.2
          ),
        });
      }
    };

    useDrag(
      (state) => {
        state.event.stopPropagation();
        handleDrag(state);
      },
      {
        axis: "y",
        from: () => [0, y.get()],
        filterTaps: true,
        pointer: { touch: true },
        target: rootRef,
      }
    );

    useWheel(
      (state) => {
        state.event.stopPropagation();
        handleDrag(state);
      },
      {
        axis: "y",
        from: () => [0, y.get()],
        preventDefault: true,
        target: props.mouseWheel ? rootRef : undefined,
        eventOptions: supportsPassive
          ? { passive: false }
          : (false as unknown as AddEventListenerOptions),
      }
    );

    let selectedIndex: number | null = null;

    function renderAccessible() {
      if (selectedIndex === null) {
        return null;
      }
      const current = column[selectedIndex];
      const previousIndex = selectedIndex - 1;
      const nextIndex = selectedIndex + 1;
      const previous = column[previousIndex];
      const next = column[nextIndex];
      return (
        <div className={styles["pickerView-column-accessible"]}>
          <div
            className={styles["pickerView-column-accessible-current"]}
            role="button"
            aria-label={
              current ? `当前选择的是：${current.label}` : "当前未选择"
            }
          >
            -
          </div>
          <div
            className={styles["pickerView-column-accessible-button"]}
            onClick={() => {
              if (!previous) return;
              scrollSelect(previousIndex);
            }}
            role={previous ? "button" : "text"}
            aria-label={
              !previous ? "没有上一项" : `选择上一项：${previous.label}`
            }
          >
            -
          </div>
          <div
            className={styles["pickerView-column-accessible-button"]}
            onClick={() => {
              if (!next) return;
              scrollSelect(nextIndex);
            }}
            role={next ? "button" : "text"}
            aria-label={!next ? "没有下一项" : `选择下一项：${next.label}`}
          >
            -
          </div>
        </div>
      );
    }

    return (
      <div className={styles["pickerView-column"]}>
        <div
          className={styles["pickerView-item-height-measure"]}
          ref={itemHeightMeasureRef}
        />
        <animated.div
          ref={rootRef}
          style={{ translateY: y }}
          className={styles["pickerView-column-wheel"]}
          aria-hidden
        >
          {column.map((item, index) => {
            const selected = props.value === item.value;
            if (selected) selectedIndex = index;
            function handleClick() {
              draggingRef.current = false;
              scrollSelect(index);
            }
            return (
              <div
                key={item.key ?? item.value}
                data-selected={item.value === value}
                className={styles["pickerView-column-item"]}
                onClick={handleClick}
                aria-hidden={!selected}
                aria-label={selected ? "active" : ""}
              >
                <div className={styles["pickerView-column-item-label"]}>
                  {renderLabel(item)}
                </div>
              </div>
            );
          })}
        </animated.div>
        {renderAccessible()}
      </div>
    );
  },
  (prev, next) => {
    if (prev.index !== next.index) return false;
    if (prev.value !== next.value) return false;
    if (prev.onSelect !== next.onSelect) return false;
    if (prev.renderLabel !== next.renderLabel) return false;
    if (prev.mouseWheel !== next.mouseWheel) return false;
    if (!isEqual(prev.column, next.column)) {
      return false;
    }
    return true;
  }
);

Wheel.displayName = "Wheel";
