import React from "react";

interface IFail {
  size?: "auto" | "default";
}

export default function (props: IFail): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "21",
    height = size === "auto" ? "100%" : "21";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.002 6.00195H11.002V12.002H10.002V6.00195Z" fill="#E71E1E" />
      <path d="M11.002 14.002H10.002V13.002H11.002V14.002Z" fill="#E71E1E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.002 20.002C15.5248 20.002 20.002 15.5248 20.002 10.002C20.002 4.47911 15.5248 0.00195312 10.002 0.00195312C4.47911 0.00195312 0.00195312 4.47911 0.00195312 10.002C0.00195312 15.5248 4.47911 20.002 10.002 20.002ZM10.002 19.002C14.9725 19.002 19.002 14.9725 19.002 10.002C19.002 5.03139 14.9725 1.00195 10.002 1.00195C5.03139 1.00195 1.00195 5.03139 1.00195 10.002C1.00195 14.9725 5.03139 19.002 10.002 19.002Z"
        fill="#E71E1E"
      />
    </svg>
  );
}
