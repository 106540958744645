import HttpService, { NormalRes } from "../../../../core/HttpService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  IFseInstallationRejectReq,
  IFseSubmitInstallationReq,
  StaticSourceItem,
  fseRejectAssignInstallation,
  fseSubmitInstallation,
} from "../../../../services/order.service";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { FseAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";

export default class InstallationFseModel {
  static fseRejectAssignInstallation = (
    data: IFseInstallationRejectReq
  ): Promise<NormalRes> => {
    return HttpService.splitResponse(fseRejectAssignInstallation(data));
  };

  static fseSubmitInstallation = (
    data: IFseSubmitInstallationReq
  ): Promise<NormalRes> => {
    const formattedData = {
      ...data,
      installationReports: OrderRequestModel.formatUploadItem(
        data?.installationReports as any[]
      ),
    };
    return HttpService.splitResponse(fseSubmitInstallation(formattedData));
  };

  static verifySubmission = (infos: Partial<IInstallation>) => {
    const keys = [
      InstallationModel.ACTUAL_INSTALL_END_DATE,
      InstallationModel.ACTUAL_INSTALL_START_DATE,
      InstallationModel.INSTALLATION_REPORTS,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static getInfoViewMobileModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      formattedActualInsStartDate = formatDatePickValue(
        actualInstallStartDate as number
      ),
      formattedActualInsEndDate = formatDatePickValue(
        actualInstallEndDate as number
      ),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      );
    const disabled = pageStatus === PageStatus.READONLY;

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelActualInstallStartDate,
          value: formattedActualInsStartDate,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelActualInstallEndDate,
          value: formattedActualInsEndDate,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
          min: formattedActualInsStartDate,
          disabled,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
        hidden: !trainingRequired,
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return list.filter(({ hidden }) => !hidden);
  };

  static getInfoViewPcModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      formattedActualInsStartDate = formatDatePickValue(
        actualInstallStartDate as number
      ),
      formattedActualInsEndDate = formatDatePickValue(
        actualInstallEndDate as number
      ),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      );
    const disabled = pageStatus === PageStatus.READONLY;
    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelActualInstallStartDate,
          value: formattedActualInsStartDate,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelActualInstallEndDate,
          value: formattedActualInsEndDate,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
          minDate: formattedActualInsStartDate,
          disabled,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
        hidden: !trainingRequired,
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return list;
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    pageStatus: PageStatus,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      installationReports,
      siteSurveyReports,
      disclaimers,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelSiteSurveyReport,
      labelDisclaimer,
      labelInstallationReport,
      uploadReminder,
    } = InstallationModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    const partProps = {
      disableUpload: disabled,
      deletable: !disabled,
    };
    const reminder = disabled ? "" : uploadReminder;
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: viewType,
        props: {
          title: labelDisclaimer,
          value: disclaimers ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DISCLAIMERS,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: viewType,
        props: {
          title: labelDeliverNotes,
          value: deliveryNotes ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DELIVERY_NOTES,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: viewType,
        props: {
          title: labelEurOrEuu,
          value: euCs ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.EUC_EUU,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: viewType,
        props: {
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.TECHNICAL_AGR,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: viewType,
        props: {
          title: labelSpecs,
          value: specifications ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SPEC,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: viewType,
        props: {
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SITE_SURVEY_REPORT,
        },
      },
      {
        key: InstallationModel.INSTALLATION_REPORTS,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelInstallationReport,
          value: installationReports ?? [],
          key: InstallationModel.INSTALLATION_REPORTS,
          reminder,
        },
      },
    ];

    return originList.filter(
      ({ props, key }) =>
        key === InstallationModel.INSTALLATION_REPORTS || !!props?.value?.length
    );
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelSubmit, labelReject } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: FseAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: FseAction.ALL_TICKET,
        },
      },
      {
        key: FseAction.REJECT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelReject,
          key: FseAction.REJECT,
        },
      },
      {
        key: FseAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: FseAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
