// In future, azure app insight will be integrated
// In future, will allow to print info, error and warning
import isDev from "../utils/isDev";

export default class LoggerService {
  static log = (name: string, ...rest: any[]) => {
    if (isDev) return;
    const prefix = rest ? `${name} -- ` : name;
    console.log(prefix, ...rest);
  };
}
