import * as React from "react";

import { AccordionProps, ExpandedIndex } from "./types";

/**
 * useAccordion hook provides all the state and focus management logic
 * for accordion items.
 */
export function useAccordion(props: AccordionProps) {
  const {
    onChange: onChangeCb,
    index,
    defaultIndex,
    allowMultiple,
    allowToggle,
    chevronSize = 20,
    ...htmlProps
  } = props;
  const initialOpenIndex = index ?? defaultIndex;
  const [openIndex, setOpenIndex] = React.useState<ExpandedIndex>(
    allowMultiple ? initialOpenIndex ?? [] : initialOpenIndex ?? -1
  );

  React.useEffect(() => {
    if (index !== undefined) {
      setOpenIndex(index);
    }
  }, [index]);

  React.useEffect(() => {
    if (onChangeCb && openIndex !== undefined) {
      onChangeCb(openIndex);
    }
  }, [openIndex]);

  const open = (idx: number, multipleIdx?: number[]) =>
    multipleIdx
      ? setOpenIndex([...multipleIdx, idx]) //
      : setOpenIndex(idx);

  const close = (idx: number, multipleIdx?: number[]) =>
    multipleIdx
      ? setOpenIndex(multipleIdx.filter((i) => i !== idx))
      : setOpenIndex(-1);

  const absIndices: number[] = [];

  /**
   * Gets the `isOpen` and `onChange` props for a child accordion item based on
   * the child's index.
   *
   * @param relIdx {number} The index of the child accordion item
   */
  const getAccordionItemProps = (relIdx: number) => {
    // This makes sure the index of AccordionItems starts with 0 to set via index and defaultIndex prop
    absIndices.push(relIdx);
    const idx = relIdx - absIndices[0];

    const isOpen = Array.isArray(openIndex)
      ? openIndex.includes(idx)
      : openIndex === idx;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onChange = (setOpen: boolean) => {
      let multipleIdx;
      if (allowMultiple) {
        multipleIdx = Array.isArray(openIndex) ? openIndex : [openIndex];
      }
      if (setOpen) {
        open(idx, multipleIdx);
      } else if (allowToggle) {
        close(idx, multipleIdx);
      }
    };

    return { isOpen, onChange };
  };

  return {
    htmlProps,
    chevronSize,
    getAccordionItemProps,
  };
}

export type UseAccordionReturn = ReturnType<typeof useAccordion>;

export const AccordionContext = React.createContext<
  Omit<UseAccordionReturn, "htmlProps">
>({
  getAccordionItemProps: () => ({
    isOpen: false,
    onChange: () => {},
  }),
  chevronSize: 20,
});
AccordionContext.displayName = "AccordionContext";
