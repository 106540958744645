import { Button } from "@zeiss/ods-components-react";
import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import Table from "../../../../components/Table/Table";
import { SystemPlatform } from "../../../../core/SystemService";
import FoldCabinetVariantPc from "../../../components/FoldCabinet/FoldCabinetVariantPc";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import FacIBaseModel, { IBaseInfo, IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseCRM.module.scss";
import useFacIBaseCRMUpdate from "./useFacIBaseCRMUpdate";

const FacIBaseCRMUpdatePc: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    labelDateReminder,
    visible,
    rolePage,
    labelCancel,
    buttonBlock,
    labelConfirm,
    confirmVisible,
    labelConfirmReminder,
    platform,
    locale,
    setVisible,
    setConfirm,
    backAllTickHandler,
    confirmCloseHandler,
  } = useFacIBaseCRMUpdate();

  const renderDateView = React.useCallback(
    (ibaseInfo: IBaseInfo) => {
      const list = FacIBaseModel.getCRMDateInfoViewModel(ibaseInfo, platform);
      const view = list.map(({ key, viewType, props }) => {
        const _props = { ...props, key };
        if (key === FacIBaseModel.IBASE_NUMBER) {
          const _label = props?.children
            ? `${props?.label} (${props?.children})`
            : props?.label;
          return (
            <div className={styles.ibaseTitlePc} key={key}>
              {_label}
            </div>
          );
        }
        return (
          <div className={styles.viewItemPc} key={ibaseInfo.iBaseNumber}>
            <ViewComponent
              key={key}
              type={viewType as ViewType}
              props={_props}
            />
          </div>
        );
      });

      return view;
    },
    [platform]
  );

  const InfoView = React.useMemo(() => {
    const { ibaseInfos, ownerInstallationFseName } = facIBaseInfo;
    if (!Array.isArray(ibaseInfos) || !ibaseInfos.length) return null;
    const [ibase, ...rest] = ibaseInfos;
    const columns = FacIBaseModel.getIBaseItemColumns();
    const { labelOwnerInstallationFse } = FacIBaseModel.getLabels();
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    return (
      <div className={styles.infoPcView}>
        <div className={styles.infoPcViewItem}>{renderDateView(ibase)}</div>
        {ownerInstallationFseName && (
          <ViewComponent
            type={viewType}
            props={{
              label: labelOwnerInstallationFse,
              children: ownerInstallationFseName,
            }}
          />
        )}
        {!!rest?.length && (
          <FoldCabinetVariantPc
            status={visible}
            onChange={(visible) => setVisible(visible)}
            cabinetClassName={styles.cabinetClassName}
          >
            <div className={styles.moreDetailContainer}>
              <Table columns={columns} data={rest} />
            </div>
          </FoldCabinetVariantPc>
        )}
        <TextReminder label={labelDateReminder} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facIBaseInfo,
    labelDateReminder,
    visible,
    locale,
    setVisible,
    renderDateView,
  ]);

  const facDatePcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoReadonlyViewModel(
      facIBaseInfo as Partial<IFacIBase>,
      platform
    );
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriodPc,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.facDatePcView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, platform, locale]);

  const facFileUploadPcView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoReadonlyViewModel(
      facIBaseInfo as IFacIBase,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadPcItem} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return (
      !!list?.length && <div className={styles.facFilePcView}>{views}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, platform, locale]);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getCRMConfirmButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SoAction.CONFIRM, { onClick: () => setConfirm(true) }],
    ]);

    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );
      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale, backAllTickHandler, setConfirm]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmCloseHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmCloseHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmCloseHandler]
  );

  return (
    <div className={styles.facIbaseCrmUpdatePc}>
      <div className={styles.pageContentPc}>
        {InfoView}
        {facDatePcView}
        {facFileUploadPcView}
      </div>
      {buttonsView}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={confirmVisible}
        title={labelConfirmReminder}
        onConfirm={confirmCloseHandler}
      />
    </div>
  );
});

export default FacIBaseCRMUpdatePc;
