import classNames from "classnames";
import React from "react";
import {
  DatePicker,
  DatePickerProps,
} from "../../../components/DatePicker/DatePicker";
import { ErrorDetectorModalView } from "../../../components/ErrorDecorator/ErrorDecorator";
import Calendar from "../../../components/svg/Calendar";
import { formatDate } from "../../../utils/commonUtils";
import { isFunction } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import InputVariant from "../InputVariant/InputVariant";
import styles from "./DatePickerVariant.module.scss";

interface IDatePickerVariant extends Omit<DatePickerProps, "visible"> {
  label: string;
  disabled?: boolean;
  variantClassName?: string;
}

const DatePickerVariant: React.FC<IDatePickerVariant> = ErrorDetectorModalView(
  (props: IDatePickerVariant) => {
    const {
      label,
      value,
      disabled,
      variantClassName,

      onConfirm,
      onCancel,
      onClose,
      ...rest
    } = props;
    const [stateValue, setState] = React.useState(() => value);
    const stateText = formatDate(stateValue as Date) as string;
    const [stateVisible, setVisible] = React.useState(false);
    const variantCls = classNames(
      styles.textInputContainer,
      { [styles.textInputContainerDisabled]: disabled },
      variantClassName
    );
    const { labelButtonCancel, labelButtonOk } = useLang({
      labelButtonCancel: { id: "common_btn_cancel" },
      labelButtonOk: { id: "common_ok_upper" },
    });

    React.useEffect(() => setState(value), [value]);

    const visibleChangeHandler = React.useCallback(
      () => !disabled && setVisible(true),
      [disabled]
    );

    const iconView = React.useMemo(() => {
      const cls = classNames(styles.datePickerIcon, {
        [styles.disabled]: disabled,
      });
      return (
        <span className={cls} onClick={visibleChangeHandler}>
          <Calendar />
        </span>
      );
    }, [disabled, visibleChangeHandler]);

    const confirmHandler = React.useCallback((date: Date) => {
      isFunction(onConfirm) && onConfirm(date);
      setVisible(false);
      setState(date);
    }, []);

    const cancelHandler = React.useCallback(() => {
      isFunction(onCancel) && onCancel();
      setVisible(false);
    }, []);

    const closeHandler = React.useCallback(() => {
      isFunction(onClose) && onClose();
      setVisible(false);
    }, []);

    return (
      <>
        <div className={variantCls}>
          <InputVariant
            label={label}
            value={stateText}
            readOnly
            onClick={visibleChangeHandler}
            disabled={disabled}
          />
          {iconView}
        </div>
        <DatePicker
          {...rest}
          cancelText={labelButtonCancel}
          confirmText={labelButtonOk}
          value={stateValue}
          visible={stateVisible}
          onConfirm={confirmHandler}
          onCancel={cancelHandler}
          onClose={closeHandler}
        />
      </>
    );
  }
);

export default DatePickerVariant;
