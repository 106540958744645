import { ProductLine } from "../../OrderRequest/model/OrderRequestModel";
import { OrderStep } from "../OrderStepTypes";
import { OrderRequestPage, PageStatus, PageSubItems } from "../pageType";
import { FseAction, Role, RsmManagerAction } from "../userRoleTypes";
import { OrderStepSummary, RoleStepPageService } from "./RoleStepPageService";

export default class RsmManagerStepPageService implements RoleStepPageService {
  private static readonly STATE_SITE_SURVEY_SELECTION_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SITE_SURVEY_SUBMIT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [FseAction.ALL_TICKET, FseAction.REJECT, FseAction.SUBMIT],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_IMPLEMENT_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [FseAction.ALL_TICKET, FseAction.REJECT, FseAction.SUBMIT],
        },
      ],
    };

  private static readonly STATE_INSTALL_SUBMIT_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_RSM_MANAGER,
    state: OrderStep.SUBMISSION,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_INSTALL_AUDIT_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_RSM_MANAGER,
    state: OrderStep.AUDIT,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_INSTALLATION_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.INSTALL,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [FseAction.ALL_TICKET, FseAction.REJECT, FseAction.SUBMIT],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.TRAINING_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.TRAINING_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_ORDER_FAC_EM_XRM_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_RSM_MANAGER,
    state: OrderStep.FAC_UPLOAD,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [RsmManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
        pageStatus: PageStatus.READONLY,
        actions: [FseAction.ALL_TICKET, FseAction.SUBMIT],
      },
    ],
  };

  private static readonly STATE_CRM_IBASE_CONFIRM_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_RSM_MANAGER,
      state: OrderStep.SO_CONFIRM,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [RsmManagerAction.ALL_TICKET],
        },
      ],
    };

  static getRolePageListByStep = (
    step: OrderStepSummary
  ): OrderRequestPage[] => {
    const { reflow, productLine } = step;
    const isEmXrLine = [ProductLine.EM, ProductLine.XRM].includes(productLine);

    const list = [
      RsmManagerStepPageService.STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS,
      RsmManagerStepPageService.STATE_SITE_SURVEY_IMPLEMENT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALL_SUBMIT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALL_AUDIT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_ASSIGN_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_IMPLEMENT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_ORDER_FAC_EM_XRM_SUB_ITEMS,
      RsmManagerStepPageService.STATE_CRM_IBASE_CONFIRM_SUB_ITEMS,
    ];

    const reflowList = [
      RsmManagerStepPageService.STATE_SITE_SURVEY_SELECTION_SUB_ITEMS_REFLOW,
      RsmManagerStepPageService.STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS_REFLOW,
      RsmManagerStepPageService.STATE_SITE_SURVEY_IMPLEMENT_SUB_ITEMS_REFLOW,
      RsmManagerStepPageService.STATE_INSTALL_SUBMIT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALL_AUDIT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_ASSIGN_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_IMPLEMENT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS,
      RsmManagerStepPageService.STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS,
      RsmManagerStepPageService.STATE_ORDER_FAC_EM_XRM_SUB_ITEMS,
      RsmManagerStepPageService.STATE_CRM_IBASE_CONFIRM_SUB_ITEMS,
    ];

    return list;
  };
}
