import { Button } from "@zeiss/ods-components-react";
import React from "react";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Feature } from "../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationRsm.module.scss";
import InstallationRsmModel, {
  InstallationRsmAction,
} from "./InstallationRsmModel";
import useInstallationRsm from "./useInstallationRsm";

const InstallationRsmPc: React.FC = ErrorDetectorPageView(() => {
  const {
    confirmorVisible,
    reminderNeedSiteSurvey,
    assignAndForwardVisible,
    assignAndForwArdConfig,
    installationInfo,
    assignData,
    saleRegions,
    rolePage,
    submitEnable,
    cancel,
    buttonBlock,
    confirm,
    userId,
    platform,
    locale,
    fseList,
    setVisible,
    confirmorHandler,
    approveHandler,
    backAllTickHandler,
    moreActionHandler,
    changeHandler,
    assignAndForwardHandler,
  } = useInstallationRsm();

  const orderInfoTimePcView = React.useMemo(() => {
    const list = InstallationRsmModel.getInfoViewPcModel(
      installationInfo,
      saleRegions,
      rolePage.pageStatus,
      fseList
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(0, splitIndex);

    const view = splitList.map(({ key, viewType, props }) => {
      const propsMaps = new Map([
        [
          InstallationModel.PLANNED_INSTALL_START_DATE,
          {
            ...props,
            key,
            onChange: (value: Date) =>
              changeHandler(key as string, value?.getTime()),
          },
        ],
        [
          InstallationModel.PLANNED_INSTALL_END_DATE,
          {
            ...props,
            key,
            onChange: (value: Date) =>
              changeHandler(key as string, value?.getTime()),
          },
        ],
        [
          InstallationModel.OWNER_INSTALLATION_FSE,
          {
            ...props,
            key,
            onChange: (value: Date) => changeHandler(key as string, value),
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? { ...props, key };
      return (
        <div className={styles.viewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.orderInfoPcView}>{view}</div>;
  }, [
    installationInfo,
    saleRegions,
    rolePage.pageStatus,
    fseList,
    changeHandler,
  ]);

  const orderInfoOwnerPcView = React.useMemo(() => {
    const list = InstallationRsmModel.getInfoViewPcModel(
      installationInfo,
      saleRegions,
      rolePage.pageStatus,
      fseList
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(splitIndex);
    const reminder = InstallationModel.getReminderByWarranty(
      installationInfo?.warrantyPeriod as WarrantyPeriod
    );
    const reminderLabel = reminder
      ? reminder
      : installationInfo?.otherWarrantyPeriod;

    const view = splitList.map(({ key, viewType, props }) => {
      const _props = { ...props, key };
      return (
        <div className={styles.viewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return (
      <div className={styles.orderInfoPcView}>
        {view}
        {reminderLabel && (
          <div className={styles.warrantyPeriodReminder}>{reminderLabel}</div>
        )}
      </div>
    );
  }, [installationInfo, saleRegions, rolePage.pageStatus, fseList]);

  const uploadFilePcView = React.useMemo(() => {
    const list = InstallationRsmModel.getFileInfoViewModel(
      installationInfo as IInstallation,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.uploadFilePcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationInfo, platform, locale, changeHandler]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationRsmModel.getButtonViewPcModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [
        RsmAction.FORWARD,
        {
          onClick: moreActionHandler.bind(null, InstallationRsmAction.FORWARD),
        },
      ],
      [
        RsmAction.NEED_SITE_SURVEY,
        {
          onClick: moreActionHandler.bind(
            null,
            InstallationRsmAction.NEED_SITE_SURVEY
          ),
        },
      ],
      [RsmAction.ASSIGN, { onClick: approveHandler }],
    ]);

    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === RsmAction.ASSIGN ? !submitEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });
    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    approveHandler,
    backAllTickHandler,
    moreActionHandler,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmorHandler.bind(null, "cancel")}
        >
          {cancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmorHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {confirm}
        </Button>
      </div>
    ),
    [cancel, buttonBlock, confirm, confirmorHandler]
  );

  return (
    <div className={styles.installationInfoRsmPc}>
      <div className={styles.pageContentPc}>
        {orderInfoTimePcView}
        {orderInfoOwnerPcView}
        <OrderDnInfoView {...(installationInfo.dnInfo as IDNInfo)} />
        {uploadFilePcView}
      </div>
      {buttonsView}
      <ConfirmVariantWithIcon
        visible={confirmorVisible}
        footer={confirmButtonView}
        onConfirm={confirmorHandler}
        title={reminderNeedSiteSurvey}
        type={ConfirmIconType.WARNING}
      />
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          role={assignAndForwArdConfig.role}
          action={assignAndForwArdConfig.action}
          feature={Feature.INSTALLATION}
          searchQuery={{ rsmUserId: userId }}
          actionData={{
            ...assignData,
            selectUserIdKey: assignAndForwArdConfig.dataKey,
          }}
          onClose={() => setVisible(false)}
          onActionResponse={assignAndForwardHandler}
        />
      )}
    </div>
  );
});

export default InstallationRsmPc;
