import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationSo.module.scss";
import InstallationSoModel from "./InstallationSoModel";
import useInstallationSo from "./useInstallationSo";

const InstallationSoMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    installationSoInfo,
    rejectVisible,
    approveVisible,
    rejectTitle,
    saleRegions,
    rolePage,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    reminder,
    locale,
    setReject,
    setApprove,
    rejectHandler,
    confirmHandler,
    backAllTickHandler,
    changeHandler,
  } = useInstallationSo();

  const orderInfoView = React.useMemo(() => {
    const list = InstallationSoModel.getInfoViewModel(
      installationSoInfo,
      saleRegions
    );

    const view = list.map(({ key, viewType, props }) => {
      const _props = { ...props, key };
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return <div className={styles.orderInfoView}>{view}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationSoInfo, saleRegions, locale]);

  const orderContactView = React.useMemo(() => {
    const list = InstallationSoModel.getContactViewModel(installationSoInfo);

    const view = list.map(({ key, viewType, props }) => {
      const propsMap = new Map([
        [
          InstallationModel.WARRANTY_PERIOD,
          { ...props, key, className: styles.warrantyPeriod },
        ],
        [
          InstallationModel.FINAL_GOODS_RECEIPT_NAME,
          { ...props, key, indicatorCustom: <IndicatorNew /> },
        ],
      ]);

      const _props = propsMap.get(key as string) || { ...props, key };
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const { warrantyPeriod, otherWarrantyPeriod } = installationSoInfo;
    const reminder = InstallationModel.getReminderByWarranty(
      warrantyPeriod as WarrantyPeriod
    );
    const reminderLabel = reminder ? reminder : otherWarrantyPeriod;

    return (
      <div className={styles.orderContactView}>
        {view}
        {reminderLabel && (
          <div className={styles.warrantyPeriodReminder}>{reminderLabel}</div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationSoInfo, locale]);

  const uploadFileView = React.useMemo(() => {
    const list = InstallationSoModel.getFileInfoViewModel(
      installationSoInfo as IInstallation,
      rolePage.pageStatus
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.uploadFileView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationSoInfo, rolePage.pageStatus, locale, changeHandler]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationSoModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SoAction.REJECT, { onClick: () => setReject(true) }],
      [SoAction.APPROVE, { onClick: () => setApprove(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };

    const views = list.map(({ viewType, props, key }) => {
      const disabled = key === SoAction.APPROVE ? !submitEnable : false;
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setReject,
    setApprove,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.installationInfoSo}>
      <div className={styles.pageContainer}>
        {orderInfoView}
        {orderContactView}
        <OrderInfoDnView {...(installationSoInfo.dnInfo as IDNInfo)} />
        {uploadFileView}
        <CommentsView />
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          onConfirm={rejectHandler}
        />
      )}
      <Confirmor
        footer={confirmButtonView}
        visible={approveVisible}
        content={reminder}
      />
    </div>
  );
});

export default InstallationSoMobile;
