import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import Modal, { IModal } from "../../../components/Modal/Modal";
import { isFunction } from "../../../utils/isType";
import styles from "./PreviewContainer.module.scss";

export interface IPreviewContainer extends IModal {
  contentClassName?: string;
}

const PreviewContainer: React.FC<IPreviewContainer> = (
  props: IPreviewContainer
) => {
  const { onClose, visible, children, className, contentClassName, ...rest } =
    props;
  const [stateVisible, setVisible] = React.useState<boolean>(() => !!visible);
  const contentCls = classNames(styles.content, contentClassName);
  const modalCls = classNames(styles.modal, className);

  React.useEffect(() => setVisible(!!visible), [visible]);
  const clickHandler = React.useCallback(
    (evt: React.SyntheticEvent<Element, Event>) => {
      setVisible(false);
      isFunction(onClose) && onClose(evt);
    },
    [onClose]
  );

  return (
    <Modal {...rest} visible={stateVisible} className={modalCls}>
      <div className={contentCls}>{children}</div>
      <div className={styles.closeIcon} onClick={clickHandler}>
        <Icon icon={"Close"} fill={"#FFFFFF"} size={16} />
      </div>
    </Modal>
  );
};

export default PreviewContainer;
