import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { NormalRes } from "../../../../core/HttpService";
import { Action } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import { defaultRecord } from "../../OrderInfo/InfoSales/hooks/useInfoSiteSurvey";
import OrderRequestModel, {
  IPrepareInstallation,
} from "../../model/OrderRequestModel";
import { AccessoryProperties, AccessoryTypes } from "../model/SiteSurveyModel";

export const has3rdParty = (
  accessories: Record<AccessoryTypes, Partial<AccessoryProperties>>
): boolean => {
  const list = Object.entries(accessories ?? {});
  const defaultKeyList = Object.entries(defaultRecord).map(([key]) => key);
  const filterList = list.filter(([item]) => defaultKeyList.includes(item));

  return !!filterList.length;
};

export default function () {
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const { expectedDate } = useLang({
    expectedDate: { id: "order_expected_site_survey_data" },
  });
  const [searchVisible, setVisible] = React.useState(false);
  const [searchType, setSearch] = React.useState<Action>();
  const [preInstallInfo, setPreInstallInfo] = React.useState(
    () => ({} as IPrepareInstallation)
  );
  const { userId } = useRecoilValue(userState) ?? {};
  const [errCode, setError] = React.useState<ErrorCode>();
  const orgs = useRecoilValue(systemUserOrgs);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY);
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);
  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const show3rdParty =
    preInstallInfo?.isSiteSurvey &&
    has3rdParty(preInstallInfo?.thirdParty as any);

  const assignOrForwardHandler = React.useCallback((action: Action) => {
    setSearch(action);
    setVisible(true);
  }, []);

  React.useEffect(() => {
    getPreInstallationInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const getPreInstallationInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      OrderRequestModel.getPreInstallInformation(installationTaskId, locale)
        .then((res) => {
          setPreInstallInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const backToAllTicket = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const assignAndForwardHandler = React.useCallback(
    (res: Promise<NormalRes>) => {
      res
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backToAllTicket();
        })
        .catch((res) => {
          setError(res.errorCode);
          toastError();
        });
    },
    [backToAllTicket, toastError]
  );

  return {
    preInstallInfo,
    orgs,
    rolePage,
    searchVisible,
    searchType,
    userId,
    expectedDate,
    platform,
    show3rdParty,
    locale,
    setVisible,
    backToAllTicket,
    assignAndForwardHandler,
    assignOrForwardHandler,
  };
}
