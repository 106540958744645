import { OrderStep } from "../OrderStepTypes";
import { OrderRequestPage, PageStatus, PageSubItems } from "../pageType";
import { ApSupervisorAction, Role } from "../userRoleTypes";
import { OrderStepSummary, RoleStepPageService } from "./RoleStepPageService";

export default class ApSupervisorStepPageService
  implements RoleStepPageService
{
  private static readonly STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_APPLY_SUPERVISOR,
      state: OrderStep.TRAINING_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.EDIT,
          actions: [
            ApSupervisorAction.ALL_TICKET,
            ApSupervisorAction.FORWARD,
            ApSupervisorAction.ASSIGN,
          ],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_APPLY_SUPERVISOR,
      state: OrderStep.TRAINING_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.EDIT,
          actions: [
            ApSupervisorAction.ALL_TICKET,
            ApSupervisorAction.REJECT,
            ApSupervisorAction.SUBMIT,
          ],
        },
      ],
    };

  private static readonly STATE_ORDER_FAC_EM_XRM_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_APPLY_SUPERVISOR,
    state: OrderStep.FAC_UPLOAD,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [ApSupervisorAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [ApSupervisorAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [ApSupervisorAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
        pageStatus: PageStatus.READONLY,
        actions: [ApSupervisorAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_CRM_IBASE_CONFIRM_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_APPLY_SUPERVISOR,
      state: OrderStep.SO_CONFIRM,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [ApSupervisorAction.ALL_TICKET],
        },
      ],
    };

  static getRolePageListByStep = (
    step: OrderStepSummary
  ): OrderRequestPage[] => {
    return [
      ApSupervisorStepPageService.STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS,
      ApSupervisorStepPageService.STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS,
      ApSupervisorStepPageService.STATE_ORDER_FAC_EM_XRM_SUB_ITEMS,
      ApSupervisorStepPageService.STATE_CRM_IBASE_CONFIRM_SUB_ITEMS,
    ];
  };
}
