import { SystemPlatform } from "../../../../core/SystemService";
import { StaticSourceItem } from "../../../../services/order.service";
import { formatDate } from "../../../../utils/commonUtils";
import { ViewType } from "../../../components/ViewTypes";
import { ApEngineerAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  ViewItemModel,
} from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";

export default class InstallationAllModel extends InstallationModel {
  static getInfoReadonlyViewModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelOwnerInstallationFse,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
      labelSiteSurveyRequired,
      labelSiteSurveyQualified,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
      isSiteSurvey,
      isSiteSurveyDone,
      ownerInstallationFseName,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      actualInsStartDateLabel = formatDate(actualInstallStartDate as number),
      actualInsEndDateLabel = formatDate(actualInstallEndDate as number),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      ),
      siteSurveyLabel = OrderRequestModel.getToggleLabel(
        isSiteSurvey as boolean
      ),
      siteSurveyDoneLabel = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );

    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const list = [
      {
        key: InstallationModel.SITE_SURVEY_REQUIRED,
        viewType,
        props: {
          label: labelSiteSurveyRequired,
          children: siteSurveyLabel,
        },
      },
      {
        key: InstallationModel.IS_SITE_SURVEY_DONE,
        viewType: isSiteSurvey ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelSiteSurveyQualified,
          children: siteSurveyDoneLabel,
        },
        hidden: !isSiteSurvey,
      },
      {
        key: InstallationModel.OWNER_INSTALLATION_FSE,
        viewType: viewType,
        props: {
          label: labelOwnerInstallationFse,
          children: ownerInstallationFseName,
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: isSiteSurvey ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: isSiteSurvey ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
        hidden: !trainingRequired,
      },
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType,
        props: {
          label: labelActualInstallStartDate,
          children: actualInsStartDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType,
        props: {
          label: labelActualInstallEndDate,
          children: actualInsEndDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return platform === SystemPlatform.ENV_MOBILE
      ? list.filter(({ hidden }) => !hidden)
      : list;
  };

  static getInfoTimeViewModel = (
    info: Partial<IInstallation>
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelWarrantyPeriod,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      warrantyPeriod,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      actualInsStartDateLabel = formatDate(actualInstallStartDate as number),
      actualInsEndDateLabel = formatDate(actualInstallEndDate as number);

    return [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: InstallationModel.INSTALLATION_TASK_ID,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: InstallationModel.INSTALLATION_TASK_ID,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelActualInstallStartDate,
          children: actualInsStartDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelActualInstallEndDate,
          children: actualInsEndDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];
  };

  static getInfoPcUserInfoViewModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    }
  ): Partial<ViewItemModel>[] => {
    const {
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelFacUploader,
      labelSiteSurveyRequired,
      labelSiteSurveyQualified,
      labelOwnerInstallationFse,
    } = InstallationModel.getLabels();
    const {
      facRequired,
      trainingRequired,
      apSupervisor,
      facUploader,
      isSiteSurvey,
      isSiteSurveyDone,
      ownerInstallationFseName,
    } = info;

    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      ),
      siteSurveyLabel = OrderRequestModel.getToggleLabel(
        isSiteSurvey as boolean
      ),
      siteSurveyDoneLabel = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );

    return [
      {
        key: InstallationModel.SITE_SURVEY_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelSiteSurveyRequired,
          children: siteSurveyLabel,
        },
      },
      {
        key: InstallationModel.IS_SITE_SURVEY_DONE,
        viewType: isSiteSurvey
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelSiteSurveyQualified,
          children: siteSurveyDoneLabel,
        },
      },
      {
        key: InstallationModel.OWNER_INSTALLATION_FSE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelOwnerInstallationFse,
          children: ownerInstallationFseName,
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
        hidden: !trainingRequired,
      },
    ];
  };

  static getTimeReadonlyViewModel = (
    info: Partial<IInstallation>,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelPlanTrainingStartTime,
      labelPlanTrainingEndTime,
      labelActualTrainingEndTime,
      labelActualTrainingStartTime,
    } = InstallationModel.getLabels();
    const {
      planedTrainingStartDate,
      planedTrainingEndDate,
      actualTrainingStartDate,
      actualTrainingEndDate,
    } = info;
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const planedTrainingStartDateLabel = formatDate(
        planedTrainingStartDate as number
      ),
      planedTrainingEndDateLabel = formatDate(planedTrainingEndDate as number),
      formattedActualTrainingStartDateLabel = formatDate(
        actualTrainingStartDate as number
      ),
      formattedActualTrainingEndDateLabel = formatDate(
        actualTrainingEndDate as number
      );
    const list = [
      {
        key: InstallationModel.PLAN_TRAINING_START_DATE,
        viewType,
        props: {
          label: labelPlanTrainingStartTime,
          children: planedTrainingStartDateLabel,
          key: InstallationModel.PLAN_TRAINING_START_DATE,
        },
      },
      {
        key: InstallationModel.PLAN_TRAINING_END_DATE,
        viewType,
        props: {
          label: labelPlanTrainingEndTime,
          children: planedTrainingEndDateLabel,
          key: InstallationModel.PLAN_TRAINING_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_START_DATE,
        viewType,
        props: {
          label: labelActualTrainingStartTime,
          children: formattedActualTrainingStartDateLabel,
          key: InstallationModel.ACTUAL_TRAINING_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_END_DATE,
        viewType,
        props: {
          label: labelActualTrainingEndTime,
          children: formattedActualTrainingEndDateLabel,
          key: InstallationModel.ACTUAL_TRAINING_END_DATE,
        },
      },
    ];

    return list.filter(({ props }) => !!props.children);
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      siteSurveyReports,
      installationReports,
      disclaimers,
      trainingReports,
      auditEmails,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelSiteSurveyReport,
      labelDisclaimer,
      labelInstallationReport,
      labelTrainReports,
      uploadReminder,
      labelAuditEmail,
    } = InstallationModel.getLabels();
    const partProps = {
      disableUpload: true,
      deletable: false,
    };
    const reminder = platform === SystemPlatform.ENV_PC ? uploadReminder : "";
    const uploadViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;
    const originList = [
      {
        key: InstallationModel.AUDIT_EMAIL,
        viewType: uploadViewType,
        props: {
          title: labelAuditEmail,
          value: auditEmails ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.AUDIT_EMAIL,
        },
      },
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: uploadViewType,
        props: {
          title: labelDisclaimer,
          value: disclaimers ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DISCLAIMERS,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: uploadViewType,
        props: {
          title: labelDeliverNotes,
          value: deliveryNotes ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DELIVERY_NOTES,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: uploadViewType,
        props: {
          title: labelEurOrEuu,
          value: euCs ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.EUC_EUU,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: uploadViewType,
        props: {
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.TECHNICAL_AGR,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: uploadViewType,
        props: {
          title: labelSpecs,
          value: specifications ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SPEC,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: uploadViewType,
        props: {
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SITE_SURVEY_REPORT,
        },
      },
      {
        key: InstallationModel.INSTALLATION_REPORTS,
        viewType: uploadViewType,
        props: {
          title: labelInstallationReport,
          value: installationReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.INSTALLATION_REPORTS,
        },
      },
      {
        key: InstallationModel.TRAINING_REPORTS,
        viewType: uploadViewType,
        props: {
          ...partProps,
          title: labelTrainReports,
          value: trainingReports ?? [],
          key: InstallationModel.TRAINING_REPORTS,
          reminder,
        },
      },
    ];

    return originList.filter(({ props }) => !!props?.value?.length);
  };

  static getButtonViewModel = (): Partial<ViewItemModel>[] => {
    const { labelAllTicket } = InfoSalesModel.getLabels();
    return [
      {
        key: ApEngineerAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: ApEngineerAction.ALL_TICKET,
        },
      },
    ];
  };
}
