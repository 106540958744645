import classNames from "classnames";
import type { DialogProps } from "rc-dialog";
import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import React from "react";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./Modal.module.scss";

// more info please find in https://github.com/react-component/dialog
export interface IModal extends DialogProps {}

const Modal: React.FC<IModal> = ErrorDetectorModalView((props: IModal) => {
  const { className, children, ...rest } = props;
  const dialogCls = classNames(styles.modal, className);

  return (
    <Dialog {...rest} className={dialogCls}>
      {children}
    </Dialog>
  );
});

Modal.defaultProps = {
  animation: "zoom",
  maskAnimation: "fade",
  closable: false,
};

export default Modal;
