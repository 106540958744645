import classNames from "classnames";
import React, {
  CSSProperties,
  FC,
  InputHTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import SystemService, {
  SystemOs,
  SystemPlatform,
} from "../../core/SystemService";
import { useUnmount } from "../../hooks";
import useSize from "../../hooks/useSize";
import OrderRequestModel from "../../pages/OrderRequest/model/OrderRequestModel";
import { RegUtil } from "../../utils/RegUtils";
import { isFunction, isNumber } from "../../utils/isType";
import { Grid, GridProps } from "../Grid/Grid";
import ImageViewer, {
  ImageProps,
  ImageViewerShowHandler,
} from "../ImageViewer";
import { Space } from "../Space/Space";
import Close from "../svg/Close";
import UploadFile from "../svg/UploadFile";
import { measureCSSLength } from "../utils/measureCssLength";
import { mergeProps } from "../utils/mergeDefaultProps";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import { usePropsValue } from "../utils/usePropsValue";
import PreviewAndDownloadModal, {
  PreviewAndDownload,
} from "./PreviewAndDownloadModal";
import PreviewItem from "./PreviewItem";
import styles from "./Upload.module.scss";

export enum TaskStatus {
  PENDING = "pending",
  FAIL = "fail",
  SUCCESS = "success",
}

export enum FileType {
  IMAGE = "image",
  PDF = "pdf",
  EXCEL = "spreadsheetml.sheet",
  WORD = "word",
  OTHER = "other",
}

export interface UploadItem {
  key?: string | number;
  url: string;
  name?: string;
  thumbnailUrl?: string;
  extra?: any;
  fileType?: FileType;
  file?: File | Blob;
}

type Task = {
  id: number;
  url?: string;
  file: File;
  status: TaskStatus;
};

export type UploadTask = Pick<Task, "id" | "status">;

export type UploaderProps = {
  defaultValue?: UploadItem[];
  value?: UploadItem[];
  columns?: GridProps["columns"];
  onChange?: (items: UploadItem[]) => void;
  onUploadQueueChange?: (tasks: UploadTask[]) => void;
  accept?: string;
  multiple?: boolean;
  maxCount?: number;
  onCountExceed?: (exceed: number) => void;
  disableUpload?: boolean;
  showUpload?: boolean;
  deletable?: boolean;
  deleteIcon?: React.ReactNode;
  isDeleteConfirmor?: boolean;
  deleteConfirmor?: React.ReactNode;
  capture?: InputHTMLAttributes<unknown>["capture"];
  onPreview?: (fileType: FileType, err: any) => void;
  onDownload?: (result: Promise<any>) => void;
  beforeUpload?: (
    file: File,
    files: File[]
  ) => Promise<File | null> | File | null;
  upload: (file: File) => Promise<UploadItem>;
  onDelete?: (item: UploadItem) => boolean | Promise<boolean> | void;
  preview?: boolean;
  showFailed?: boolean;
  imageFit?: ImageProps["fit"];
  children?: React.ReactNode;
  renderItem?: (
    originNode: React.ReactElement,
    file: UploadItem,
    fileList: UploadItem[]
  ) => React.ReactNode;
} & NativeProps<
  "--cell-size" | "--gap" | "--gap-vertical" | "--gap-horizontal"
>;

export const androidAccept = "application/pdf";
export const pcAccept =
  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,.jpg, .xlsx, .xls";

const defaultAccept =
  SystemService.getPlatform() === SystemPlatform.ENV_MOBILE
    ? SystemService.getMobileOs() === SystemOs.OS_ANDROID
      ? androidAccept
      : pcAccept
    : pcAccept;

const defaultProps = {
  disableUpload: false,
  deletable: true,
  deleteIcon: (
    <div className={styles["defaultDeleteIcon"]}>
      <Close size="auto" />
    </div>
  ),
  showUpload: true,
  multiple: false,
  maxCount: 0,
  defaultValue: [] as UploadItem[],
  accept: defaultAccept,
  preview: true,
  showFailed: true,
  imageFit: "cover",
};

const upload = "upload";

export const Upload: FC<UploaderProps> = (p) => {
  const props = mergeProps(defaultProps, p);
  const { columns } = props;
  const [value, setValue] = usePropsValue(props);
  const [tasks, setTasks] = useState<Task[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const containerSize = useSize(containerRef);
  const gapMeasureRef = useRef<HTMLDivElement>(null);
  const [cellSize, setCellSize] = useState<number>(80);
  const [previewAndDownloadVisible, setVisible] = React.useState(false);
  const [actionType, setAction] = React.useState<PreviewAndDownload>();
  const [fileType, setFileType] = React.useState<FileType>();
  const [activeIndex, setActive] = React.useState<number>();
  const previewIndexRef = React.useRef<number>();
  const valueRef = React.useRef<UploadItem[]>();
  const fileTypeRef = React.useRef<FileType>();
  const disabledUploadFill = useLayoutEffect(() => {
    const gapMeasure = gapMeasureRef.current;
    if (columns && containerSize && gapMeasure) {
      const width = containerSize.width;
      const gap = measureCSSLength(
        window.getComputedStyle(gapMeasure).getPropertyValue("height")
      );
      setCellSize((width - gap * (columns - 1)) / columns);
    }
  }, [containerSize?.width]);

  const style: CSSProperties & {
    "--cell-size": string;
  } = {
    "--cell-size": cellSize + "px",
  };

  useLayoutEffect(() => {
    setTasks((prev) =>
      prev.filter((task) => {
        if (task.url === undefined) return true;
        return !value.some((fileItem) => fileItem.url === task.url);
      })
    );
  }, [value]);

  useLayoutEffect(() => {
    props.onUploadQueueChange?.(
      tasks.map((item) => ({ id: item.id, status: item.status }))
    );
  }, [tasks]);

  const idCountRef = useRef(0);

  const { maxCount, onPreview, renderItem, onDownload } = props;

  async function processFile(file: File, fileList: File[]) {
    const { beforeUpload } = props;

    let transformedFile: File | null | undefined = file;

    transformedFile = await beforeUpload?.(file, fileList);

    return transformedFile;
  }

  function getFinalTasks(tasks: Task[]) {
    return props.showFailed
      ? tasks
      : tasks.filter((task) => task.status !== "fail");
  }

  async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    const { files: rawFiles } = e.target;
    if (!rawFiles) return;
    let files = [].slice.call(rawFiles) as File[];
    e.target.value = "";

    if (props.beforeUpload) {
      const postFiles = files.map((file) => {
        return processFile(file, files);
      });

      await Promise.all(postFiles).then((filesList) => {
        files = filesList.filter(Boolean) as File[];
      });
    }

    if (files.length === 0) {
      return;
    }

    if (maxCount > 0) {
      const exceed = value.length + files.length - maxCount;
      if (exceed > 0) {
        files = files.slice(0, files.length - exceed);
        props.onCountExceed?.(exceed);
      }
    }

    const newTasks = files.map(
      (file) =>
        ({
          id: idCountRef.current++,
          status: "pending",
          file,
        } as Task)
    );

    setTasks((prev) => [...getFinalTasks(prev), ...newTasks]);

    await Promise.all(
      newTasks.map(async (currentTask) => {
        try {
          const result = await props.upload(currentTask.file);
          setTasks((prev) => {
            return prev.map((task) => {
              if (task.id === currentTask.id) {
                return {
                  ...task,
                  status: TaskStatus.SUCCESS,
                  url: result.url,
                };
              }
              return task;
            });
          });
          setValue((prev) => {
            const newVal = { ...result };
            return [...prev, newVal];
          });
        } catch (e) {
          setTasks((prev) => {
            return prev.map((task) => {
              if (task.id === currentTask.id) {
                return {
                  ...task,
                  status: TaskStatus.FAIL,
                };
              }
              return task;
            });
          });
          throw e;
        }
      })
    ).catch((error) => console.error(error));
  }

  const imageViewerHandlerRef = useRef<ImageViewerShowHandler | null>(null);

  function previewImage(index: number) {
    if (fileTypeRef.current !== FileType.IMAGE) return;
    imageViewerHandlerRef.current = ImageViewer.Multi.show({
      images: value
        ?.filter(({ fileType }) => fileType === FileType.IMAGE)
        .map((fileItem) => fileItem.url),
      defaultIndex: index,
      onClose: () => {
        imageViewerHandlerRef.current = null;
      },
    });
  }

  const previewAndDownloadHandler = (type: PreviewAndDownload) => {
    const { current } = previewIndexRef;
    setVisible(false);
    setAction(type);
    if (!isNumber(current)) return;
    if (type === PreviewAndDownload.PREVIEW) {
      setActive(current);
    } else {
      const { url = "", name = "" } = valueRef?.current
        ? valueRef.current[current]
        : {};
      const valid = RegUtil.verifyWebAddr(url);
      if (!valid) return;
      const result = OrderRequestModel.downloadFile(url, name);
      isFunction(onDownload) && onDownload(result);
    }
  };

  const preViewErrorHandler = (fileType: FileType, err: any) => {
    isFunction(onPreview) && onPreview(fileType, err);
  };

  useUnmount(() => {
    imageViewerHandlerRef.current?.close();
  });

  const finalTasks = getFinalTasks(tasks);

  const showUpload =
    props.showUpload &&
    (maxCount === 0 || value.length + finalTasks.length < maxCount);

  const renderImages = () => {
    return value.map((fileItem, index) => {
      const originNode = (
        <PreviewItem
          key={fileItem?.key ?? index}
          url={fileItem?.thumbnailUrl ?? fileItem?.url}
          file={fileItem?.file as File}
          fileType={fileItem?.fileType}
          deletable={props.deletable}
          deleteIcon={props.deleteIcon}
          imageFit={props.imageFit}
          name={fileItem?.name}
          previewIndex={index}
          actionType={actionType}
          activeIndex={activeIndex}
          onClick={() => {
            setFileType(fileItem?.fileType);
            fileTypeRef.current = fileItem?.fileType;
            valueRef.current = value;
            previewIndexRef.current = index;
            setVisible(true);
          }}
          onClose={() => setActive(null as any)}
          onDelete={async () => {
            const canDelete = await props.onDelete?.(fileItem);
            if (canDelete === false) return;
            setValue(value.filter((x, i) => i !== index));
          }}
          onError={preViewErrorHandler}
        />
      );
      return renderItem ? renderItem(originNode, fileItem, value) : originNode;
    });
  };

  const contentNode = (
    <>
      {renderImages()}
      {tasks.map((task) => {
        if (!props.showFailed && task.status === "fail") {
          return null;
        }
        return (
          <PreviewItem
            key={task.id}
            file={task.file}
            deletable={task.status !== "pending"}
            deleteIcon={props.deleteIcon}
            status={task.status}
            imageFit={props.imageFit}
            onDelete={() => {
              setTasks(tasks.filter((x) => x.id !== task.id));
            }}
          />
        );
      })}
      <div
        className={styles["upload-upload-button-wrap"]}
        style={showUpload ? undefined : { display: "none" }}
      >
        {props.children ||
          (!props.disableUpload && (
            <span
              className={classNames(
                styles["upload-cell-noContent"],
                styles["upload--upload-button"],
                { [styles.disabledUpload]: props.disableUpload }
              )}
              role="button"
              aria-label={upload}
            >
              <UploadFile size={"auto"} />
            </span>
          ))}
        {!props.disableUpload && (
          <input
            accept={props.accept}
            type="file"
            className={styles["upload-input"]}
            onChange={onChange}
            aria-hidden
          />
        )}
      </div>
    </>
  );

  return withNativeProps(
    props,
    <div className={styles.upload} ref={containerRef}>
      {columns ? (
        <Grid className={styles["upload-grid"]} columns={columns} style={style}>
          <div className={styles["upload-gap-measure"]} ref={gapMeasureRef} />
          {contentNode.props.children}
        </Grid>
      ) : (
        <Space className={styles["upload-space"]} wrap block>
          {contentNode.props.children}
        </Space>
      )}
      <PreviewAndDownloadModal
        visible={previewAndDownloadVisible}
        onTrigger={previewAndDownloadHandler}
        onClose={() => setVisible(false)}
        fileType={fileType as FileType}
      />
    </div>
  );
};
