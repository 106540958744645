import { useRecoilValue } from "recoil";
import {
  NotificationContentView,
  NotificationType,
  OpenConfig,
} from "../../components/Notification/Notification";
import Toast, { ToastShowProps } from "../../components/Toast";
import ErrorCodeService, { ErrorCode } from "../../core/ErrorCodeService";
import { SystemPlatform } from "../../core/SystemService";
import { notificationHandleState, platformState } from "../../store";
import useLang from "./useLang";

export const useErrorMsg = (errorCode: ErrorCode) => {
  const errMsg = ErrorCodeService.getErrorMsgKey(errorCode);
  const { msg } = useLang({ msg: { id: errMsg } });
  const platform = useRecoilValue(platformState);
  const { open, close } = useRecoilValue(notificationHandleState);

  const mobileHandler = (config?: Partial<ToastShowProps>) => {
    const { content } = config ?? {};
    const _content = content || msg;

    Toast.show({
      content: _content,
    });
  };

  const pcHandler = (config?: Partial<OpenConfig>) => {
    const { content, key } = config ?? {};
    const _key = key || `${msg}_${Date.now()}`;
    const _content = content || msg;

    open({
      key: _key,
      content: (
        <NotificationContentView
          title={_content}
          contentType={NotificationType.failed}
          onClose={close.bind(null, _key)}
        />
      ),
    });
  };

  return platform === SystemPlatform.ENV_PC ? pcHandler : mobileHandler;
};
