import {
  Icon,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableStyleProps,
  Table as _Table,
} from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { objectHasProperties } from "../../utils/commonUtils";
import { isFunction, isObject, isString } from "../../utils/isType";
import { ErrorDetectorMediumView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./Table.module.scss";

export interface ITableColumn {
  key: keyof ITableItem;
  label: string;
}

export interface ITableItem extends Record<string, any> {}
export interface ITableProps<ITableItem> extends TableStyleProps {
  /** Table columns header label and key */
  columns: ITableColumn[];
  /** Table data List, columns key must match the everyone of data */
  data: ITableItem[];
  /** Table columns footer label */
  footers?: { key: keyof ITableItem; label: string }[];
  /** Actions column label */
  actionColumn?: ITableColumn;
  /** Usually will show the action in the last column */
  actions?: { key: string; label: string; icon?: string | React.ReactNode }[];
  /** Customized class name to override styles */
  className?: string;
  /** Will triggered if any action be clicked  */
  onAction?: (key: string, data: ITableItem) => void;
  /** Row click callback */
  onClick?: (item: ITableItem, evt?: MouseEvent) => void;
}

/**
 * Sample packing for table component in ODS
 */
export const Table: React.FC<ITableProps<ITableItem>> = ErrorDetectorMediumView(
  (props: ITableProps<ITableItem>) => {
    const {
      columns,
      data,
      footers,
      actions,
      className,
      actionColumn,
      onAction,
      onClick,
      ...rest
    } = props;

    const _className = classNames(
      styles.table,
      { [styles.curseAuto]: !isFunction(onClick) },
      className
    );
    const hasActions = React.useMemo(
      () =>
        objectHasProperties(actionColumn, ["key", "label"]) &&
        Array.isArray(actions) &&
        actions.length,
      [actionColumn, actions]
    );

    const _columns = React.useMemo(() => {
      if (!Array.isArray(columns) || !columns.length) return [];
      return objectHasProperties(actionColumn, ["key", "label"])
        ? [...columns, actionColumn]
        : columns;
    }, [actionColumn, columns]);

    const clickHandler = React.useCallback(
      (item: ITableItem, evt: MouseEvent) => {
        if (!isFunction(onClick)) return;
        onClick(item, evt);
      },
      [onClick]
    );

    const actionHandler = React.useCallback(
      (key: string, item: ITableItem) => {
        if (!isFunction(onAction)) return;
        onAction(key, item);
      },
      [onAction]
    );

    const renderActionIconView = (icon?: string | React.ReactNode) => {
      if (!icon) return null;
      if (isString(icon)) return <Icon icon={icon as any} size={12} />;
      return icon;
    };

    const renderActionView = React.useCallback(
      (item: ITableItem) => {
        if (!hasActions) return null;
        const { length } = data;
        return (
          <TableCell key={`_actions_${length}`}>
            <div className={styles.actions}>
              {actions?.map(({ key, label, icon }) => (
                <div
                  className={styles.actionItem}
                  key={key}
                  onClick={actionHandler.bind(null, key, item)}
                >
                  {renderActionIconView(icon)}
                  <span key={key}>{label}</span>
                </div>
              ))}
            </div>
          </TableCell>
        );
      },
      [actions, data, hasActions, actionHandler]
    );

    const headerView = React.useMemo(() => {
      return (
        <TableHead>
          <TableRow>
            {_columns.map((item, index) => (
              <TableHeaderCell key={index}>{item?.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }, [_columns]);

    const contentView = React.useMemo(() => {
      if (!Array.isArray(data) || !data.length) return null;

      return (
        <TableBody>
          {data.map((item, index) => {
            if (!isObject(item) || isEmpty(item))
              return (
                <TableRow
                  key={index}
                  onClick={(evt: MouseEvent) => clickHandler(item, evt)}
                />
              );
            return (
              <TableRow
                key={index}
                onClick={(evt: MouseEvent) => clickHandler(item, evt)}
              >
                {_columns.map((_item, _index) =>
                  _item?.key === actionColumn?.key ? (
                    renderActionView(item)
                  ) : (
                    <TableCell key={_index}>
                      {item[_item?.key as string]}
                    </TableCell>
                  )
                )}
              </TableRow>
            );
          })}
        </TableBody>
      );
    }, [data, _columns, actionColumn?.key, clickHandler, renderActionView]);

    const footerView = React.useMemo(() => {
      if (!Array.isArray(footers) || !footers.length) return null;
      return (
        <TableFooter>
          <TableRow>
            {footers.map(({ key, label }) => (
              <TableCell key={key}>{label}</TableCell>
            ))}
          </TableRow>
        </TableFooter>
      );
    }, [footers]);

    return (
      <_Table {...rest} className={_className}>
        {headerView}
        {contentView}
        {footerView}
      </_Table>
    );
  }
);

Table.defaultProps = {
  borderless: true,
  hover: true,
  borderStyle: "collapse",
  fontSize: "14px",
};

export default Table;
