import classNames from "classnames";
import React from "react";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import styles from "./SafeArea.module.scss";

export type SafeAreaProps = {
  position: "top" | "bottom";
} & NativeProps;

export const SafeArea: React.FC<SafeAreaProps> = (props) => {
  return withNativeProps(
    props,
    <div
      className={classNames(
        styles.safeArea,
        styles[`safeArea-position-${props.position}`]
      )}
    />
  );
};
