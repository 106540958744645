import React from "react";

interface IInfo {
  fillColor?: string;
  size?: "auto" | "default";
}

export default function (props: IInfo): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "14",
    height = size === "auto" ? "100%" : "11";

  return (
    <svg width={width} height={height} viewBox="0 0 14 11" fill="none">
      <path
        d="M12.3332 2L4.99984 9.33333L1.6665 6"
        stroke="#0050F2"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
