import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { StaticSourceItem } from "../../../../services/order.service";
import { Action, ISearUserReq } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { SearchOption } from "../../../components/SearchSelector/useSearchSelector";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems, SubTabs } from "../../../service/pageType";
import { Role } from "../../../service/userRoleTypes";
import AssignOrForwardUserModel from "../../components/AssignOrForwardUser/AssignOrForwardUserModel";
import InstallationModel, { IInstallation } from "../installationModel";
import InstallationRsmModel, {
  InstallationRsmAction,
} from "./InstallationRsmModel";

export interface HookInstallationRsm {
  confirmorVisible: boolean;
  reminderNeedSiteSurvey: string;
  assignAndForwardVisible: boolean;
  assignAndForwArdConfig: Record<string, any>;
  installationInfo: Partial<IInstallation>;
  assignData: Record<string, any>;
  saleRegions: {
    apSupervisor: StaticSourceItem[];
    regionProductLine: Partial<StaticSourceItem>[];
  };
  rolePage: SubTabs;
  submitEnable: boolean;
  cancel: string;
  buttonBlock: boolean;
  confirm: string;
  userId: string;
  setVisible: boolean;
  confirmorHandler: (type: CloseType) => void;
  approveHandler: () => void;
  backAllTickHandler: () => void;
  moreActionHandler: (value: InstallationRsmAction) => void;
  changeHandler: (key: string, value: any) => void;
}

export default function () {
  const { cancel, confirm, reminderNeedSiteSurvey } = useLang({
    cancel: { id: "common_btn_cancel" },
    confirm: { id: "common_btn_confirm" },
    reminderNeedSiteSurvey: { id: "order_rsm_audit_need_site_survey_reminder" },
  });
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const { userId } = useRecoilValue(userState) ?? {};
  const [installationInfo, setInfo] = React.useState<Partial<IInstallation>>(
    {}
  );
  const [errCode, setError] = React.useState<ErrorCode>();
  const [assignAndForwardVisible, setVisible] = React.useState(false);
  const [assignAndForwArdConfig, setConfig] = React.useState<
    Record<string, any>
  >({});
  const [confirmorVisible, setConfirmor] = React.useState(false);
  const saleRegions = useRecoilValue(systemUserOrgs);
  const [buttonBlock, setBlock] = React.useState(false);
  const [fseList, setFseList] = React.useState<SearchOption[]>([]);
  const locale = useRecoilValue(localeState);

  const navigate = useNavigate();
  const assignData = {
    planedInstallationStartDate: installationInfo?.planedInstallationStartDate,
    planedInstallationEndDate: installationInfo?.planedInstallationEndDate,
    ownerInstallationFse: installationInfo?.ownerInstallationFse,
  };
  const rolePage = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );
  const platform = useRecoilValue(platformState);
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  React.useEffect(() => {
    getInfo(installationTaskId);
    getFseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const submitEnable = React.useMemo(
    () => InstallationRsmModel.verifyRsmAssign(installationInfo),
    [installationInfo]
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getInstallationInfo(installationTaskId, locale)
        .then((res) => setInfo(res?.data))
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const getFseList = React.useCallback(() => {
    AssignOrForwardUserModel.getSaleForwardUsers(Role.ROLE_FSE, {
      rsmUserId: userId,
    } as ISearUserReq)
      .then((res) => setFseList(res.data))
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      });
  }, [toastError, userId]);

  const rsmNeedSiteSurvey = React.useCallback(() => {
    setLoading(true);
    setBlock(true);

    InstallationRsmModel.rsmNeedSiteSurvey({
      installationTaskId,
      rsmRBACCorrelationId: userId,
    })
      .then(() => {
        EventService.getInstance().emit(
          EventService.EVENT_SUBMIT_STEP_COMMENTS
        );
        backAllTickHandler();
      })
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [installationTaskId, userId, setLoading, backAllTickHandler, toastError]);

  const changeHandler = React.useCallback((key: string, value: any) => {
    setInfo((pre) => {
      if (key !== InstallationModel.PLANNED_INSTALL_START_DATE)
        return { ...pre, [key]: value };
      return {
        ...pre,
        [InstallationModel.PLANNED_INSTALL_START_DATE]: value,
        [InstallationModel.PLANNED_INSTALL_END_DATE]: undefined,
      };
    });
  }, []);

  const moreActionHandler = React.useCallback(
    (value: InstallationRsmAction) => {
      if (value === InstallationRsmAction.NEED_SITE_SURVEY) {
        setConfirmor(true);
        return;
      }
      setVisible(true);
      setConfig({
        action: Action.FORWARD,
        role: Role.ROLE_RSM,
        dataKey: "rsmRBACCorrelationId",
      });
    },
    []
  );

  const confirmorHandler = React.useCallback(
    (type: CloseType) => {
      setConfirmor(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      rsmNeedSiteSurvey();
    },
    [rsmNeedSiteSurvey]
  );

  const approveHandler = React.useCallback(() => {
    setVisible(true);
    setConfig({
      action: Action.ASSIGN,
      role: Role.ROLE_FSE,
      paramsKey: "rsmUserId",
      dataKey: "fseRBACCorrelationId",
    });
  }, []);

  const assignAndForwardHandler = React.useCallback(
    (result: Promise<any>) => {
      result
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch(() => {});
    },
    [backAllTickHandler]
  );

  return {
    confirmorVisible,
    reminderNeedSiteSurvey,
    assignAndForwardVisible,
    assignAndForwArdConfig,
    installationInfo,
    assignData,
    saleRegions,
    rolePage,
    submitEnable,
    cancel,
    buttonBlock,
    confirm,
    userId,
    platform,
    locale,
    fseList,
    setVisible,
    confirmorHandler,
    approveHandler,
    backAllTickHandler,
    moreActionHandler,
    changeHandler,
    assignAndForwardHandler,
  };
}
