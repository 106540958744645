import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import Table from "../../../../../../../components/Table/Table";
import FoldCabinetVariantPc from "../../../../../../components/FoldCabinet/FoldCabinetVariantPc";
import TextReminder from "../../../../../../components/TextReminder/TextReminder";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import InfoSalesModel from "../../../InfoSalesModel";
import useOrderDnInfo, {
  OrderInfoDnProps,
} from "../../../hooks/useOrderDnInfo";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./OrderDnInfoView.module.scss";

const OrderDnInfoView: React.FC<OrderInfoDnProps> = ErrorDetectorMediumView(
  (props: OrderInfoDnProps) => {
    const {
      cpoDate,
      installationOrderNo,
      serialNumber,
      serviceContractorOrder,
      dnItems,
      dnDelivered,
      visible,
      notice,
      noticeType,
      locale,
      setVisible,
    } = useOrderDnInfo(props);

    const generateOrderView = React.useCallback(
      (list: string[], label: string) => {
        const columns =
          InfoSalesPcModel.getDnInstallationAndSerialNumberColumns(label);
        const data = InfoSalesPcModel.generateDnInstallationAndSerialNumberData(
          list,
          label
        );

        return <Table data={data} columns={columns} />;
      },
      []
    );

    const dnView = React.useMemo(() => {
      const viewList = InfoSalesPcModel.getDNItemModels(
        {
          cpoDate,
          installationOrderNo,
          serialNumber,
          serviceContractorOrder,
          dnItems,
        },
        dnDelivered
      );
      return viewList.map(({ key, viewType, props }) => {
        const isTable = [
          InfoSalesModel.INSTALLATION_ORDER_NO,
          InfoSalesModel.SERIAL_NUMBER,
        ].includes(key as keyof InfoSalesModel);

        const children =
          isTable && !!props?.children?.length
            ? props?.children?.length > 1
              ? generateOrderView(props?.children, props?.label)
              : props?.children.join(",")
            : props?.children;

        return isTable && props?.children?.length > 1 ? (
          <div key={key} className={styles.dnItemFull}>
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...props,
                key,
                children,
                className: styles.tableItem,
              }}
            />
          </div>
        ) : (
          <div key={key} className={styles.dnItem}>
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...props,
                children,
              }}
            />
          </div>
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      cpoDate,
      installationOrderNo,
      serialNumber,
      serviceContractorOrder,
      dnItems,
      dnDelivered,
    ]);

    const moreDetailView = React.useMemo(() => {
      const columns = InfoSalesPcModel.getDnItemColumns();
      const show = !!dnItems?.length;

      return (
        show && (
          <FoldCabinetVariantPc
            status={visible}
            onChange={(visible) => setVisible(visible)}
          >
            <div className={styles.moreDetailContainer}>
              <Table columns={columns} data={dnItems} />
            </div>
          </FoldCabinetVariantPc>
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dnItems, visible]);

    return (
      <div className={styles.orderInfoDnView}>
        <div className={styles.notice}>
          <TextReminder label={notice} reminderType={noticeType} />
        </div>
        <div className={styles.dnView}>{dnView}</div>
        {moreDetailView}
      </div>
    );
  }
);

export default OrderDnInfoView;
