import PageWrapper from "../../../../layout/PageWrapper";
import InstallationApEngineerMobile from "./InstallationApEngineerMobile";
import InstallationApEngineerPc from "./InstallationApEngineerPc";

export default () => (
  <PageWrapper
    PagePc={InstallationApEngineerPc}
    PageMobile={InstallationApEngineerMobile}
  />
);
