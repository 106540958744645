import React from "react";
import { ErrorDetectorSmallView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { LocaleLang } from "../../../../core/LocaleService";
import { isFunction } from "../../../../utils/isType";
import OrderListModel from "../../OrderListModel";
import ToggleLocaleCn from "./ToggleLocaleCn";
import ToggleLocaleEn from "./ToggleLocaleEn";

interface ToggleLocaleProps {
  locale?: LocaleLang;
  onChange?: (lang: LocaleLang) => void;
  className?: string;
}

const ToggleLocale: React.FC<ToggleLocaleProps> = ErrorDetectorSmallView(
  (props: ToggleLocaleProps) => {
    const { locale, className, onChange } = props;
    const localeList = React.useRef([LocaleLang.EN_US, LocaleLang.ZH_CN]);
    const include = localeList.current.includes(locale as LocaleLang);
    const [_locale, setLocale] = React.useState(() =>
      include ? locale : OrderListModel.getLang()
    );

    React.useEffect(() => {
      include && setLocale(locale);
    }, [locale, include]);

    const changeHandler = React.useCallback(
      (locale: LocaleLang) => {
        setLocale(locale);
        isFunction(onChange) && onChange(locale);
      },
      [onChange]
    );

    return React.useMemo(() => {
      const viewMap = new Map([
        [
          LocaleLang.EN_US,
          <span
            key={LocaleLang.EN_US}
            onClick={changeHandler.bind(null, LocaleLang.ZH_CN)}
          >
            <ToggleLocaleEn />
          </span>,
        ],
        [
          LocaleLang.ZH_CN,
          <span
            key={LocaleLang.ZH_CN}
            onClick={changeHandler.bind(null, LocaleLang.EN_US)}
          >
            <ToggleLocaleCn />
          </span>,
        ],
      ]);

      const icon = viewMap.get(_locale) ?? <></>;

      return <div className={className}>{icon}</div>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_locale, className]);
  }
);

export default ToggleLocale;
