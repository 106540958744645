import React from "react";

interface IInfo {
  fillColor?: string;
  size?: "auto" | "default";
}

export default function (props: IInfo): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "16",
    height = size === "auto" ? "100%" : "16";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00016 1.3335H5.3335V4.00016H4.00016L4.00016 1.3335Z"
        fill="#606A76"
      />
      <path
        d="M9.3335 2.66683H6.66683V4.00016H9.3335V2.66683Z"
        fill="#606A76"
      />
      <path
        d="M13.3335 13.3334H2.66683V2.66683H1.3335V14.6667H14.6668V2.66683H13.3335V13.3334Z"
        fill="#606A76"
      />
      <path
        d="M12.0002 1.3335H10.6668V4.00016H12.0002V1.3335Z"
        fill="#606A76"
      />
      <rect x="4" y="6.5" width="2" height="2" fill="#606A76" />
      <rect x="4" y="9.5" width="2" height="2" fill="#606A76" />
      <rect x="7" y="6.5" width="2" height="2" fill="#606A76" />
      <rect x="7" y="9.5" width="2" height="2" fill="#606A76" />
      <rect x="10" y="6.5" width="2" height="2" fill="#606A76" />
      <rect x="10" y="9.5" width="2" height="2" fill="#606A76" />
    </svg>
  );
}
