import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useClickAway from "../../../../hooks/useClickAway";
import styles from "./DatepickerYearDropdown.module.scss";

type DatePickerYearDropdownProps = {
  selectedYear: number;
  years: number[];
  onChange: (year: number) => void;
};

const scrollbarHeight = 20;

const ScrollContentPopup: React.FC<
  DatePickerYearDropdownProps & { onClose: () => void }
> = ({ selectedYear, years, onChange, onClose }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  // we don't want to keep scrolling on every change, only when the popup first appears
  const alreadyScrolled = useRef(false);
  const [scrollbarOffset, setScrollbarOffset] = useState(0);

  const scrollingParent = useRef<HTMLDivElement>(null);
  const scrolledContent = useRef<HTMLDivElement>(null);

  // we want the dropdown to be opened at the selected year
  const selectedYearRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parent = scrollingParent.current;
    const content = scrolledContent.current;

    if (!content || !parent) {
      return undefined;
    }

    const listener = () => {
      const scrollPosition = content.scrollTop;
      const visibleAreaHeight = content.clientHeight;
      const totalScrollableAmount = content.scrollHeight - visibleAreaHeight;
      const scrollOffsetPercent = scrollPosition / totalScrollableAmount;
      const maxScrollbarOffset = content.clientHeight - scrollbarHeight;
      const newScrollbarOffset = maxScrollbarOffset * scrollOffsetPercent;

      setScrollbarOffset(newScrollbarOffset);
    };
    content.addEventListener("scroll", listener);
    return () => {
      content.removeEventListener("scroll", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledContent.current, scrollingParent.current]);

  // useLayoutEffect to scroll before the first render, so the options do not flicker
  useLayoutEffect(() => {
    if (alreadyScrolled.current) {
      return;
    }

    if (!selectedYearRef.current || !scrolledContent.current) {
      return;
    }

    alreadyScrolled.current = true;
    const position = selectedYearRef.current.offsetTop;
    scrolledContent.current.scrollTop = position;
  });

  return (
    <div className={styles.yearPopupParent} ref={rootRef}>
      <div className={styles.yearPopupChild} ref={scrollingParent}>
        {/* <div className={styles.zeissScrollbarWrapper}>
          <div
            className={styles.zeissScrollbarScroller}
            style={{
              top: scrollbarOffset,
              height: scrollbarHeight,
            }}
          />
        </div> */}
        <div className={styles.hiddenScrollbarScrollArea} ref={scrolledContent}>
          {years.map((year) => {
            const selected = year === selectedYear;
            const selectableYearDropdownCls = classNames(
              styles.selectableYearInDropdown,
              { [styles.selectableYearInDropdownSelected]: selected }
            );
            return (
              <div
                className={selectableYearDropdownCls}
                ref={selected ? selectedYearRef : undefined}
                key={year}
                onClick={() => {
                  onChange(year);
                  onClose();
                }}
              >
                {year}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const DatePickerYearDropdown: React.FC<
  DatePickerYearDropdownProps & {
    // specifying open state to be able to increase test coverage
    defaultOpen?: boolean;
  }
> = ({ selectedYear, years, onChange, defaultOpen }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, [setIsOpen]);

  useClickAway(onClose, rootRef);

  return (
    <div className={styles.datePickerYearDropdownRoot} ref={rootRef}>
      <div className={styles.yearDropdownOpener} onClick={toggle}>
        <div>{selectedYear}</div>
        <div className={styles.selectableYearDropdownIcon}>
          <Icon icon="ChevronExpand" size={16} />
        </div>
      </div>
      {isOpen && (
        <ScrollContentPopup
          years={years}
          selectedYear={selectedYear}
          onChange={onChange}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};
