import React from "react";
import { useRecoilValue } from "recoil";
import { localeState, stepState } from "../../../../../store";
import useLang from "../../../../hooks/useLang";
import { IPartnerTeamOtherInfo } from "../../../model/OrderRequestModel";

export interface OrderRequestPartnerProps
  extends Partial<IPartnerTeamOtherInfo> {}

export interface HookOrderRequestPartner {}

export default function (props: OrderRequestPartnerProps) {
  const { partnerTitle } = useLang({
    partnerTitle: { id: "order_info_partner_title" },
  });
  const [visible, setVisible] = React.useState(false);
  const [stateInfo, setStateInfo] = React.useState(() => props);
  const orderStepSummary = useRecoilValue(stepState);
  const locale = useRecoilValue(localeState);

  React.useEffect(() => setStateInfo(props), [props]);

  return {
    partnerTitle,
    visible,
    stateInfo,
    orderStepSummary,
    locale,
    setVisible,
  };
}
