import { Button } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { CloseType } from "../../../components/Confirmor/Confirmor";
import { ErrorDetectorMediumView } from "../../../components/ErrorDecorator/ErrorDecorator";
import FileUpload, {
  IFileUploadProps,
} from "../../../components/FileUpload/FileUpload";
import {
  FileType,
  IFileUploadItem,
} from "../../../components/FileUpload/FileUploadService";
import {
  NotificationContentView,
  NotificationType,
} from "../../../components/Notification/Notification";
import { identifyFileTypeByName } from "../../../components/Upload/utils";
import {
  LabelIndicator,
  LabelIndicatorIcon,
} from "../../../components/ViewWrapper/ViewWrapper";
import { ErrorCode } from "../../../core/ErrorCodeService";
import { notificationHandleState } from "../../../store";
import { isFunction } from "../../../utils/isType";
import InfoSalesModel from "../../OrderRequest/OrderInfo/InfoSales/InfoSalesModel";
import { useErrorMsg } from "../../hooks/useErrorMsg";
import useLang from "../../hooks/useLang";
import TextReminder, { ReminderType } from "../TextReminder/TextReminder";
import styles from "./FileUploadVariant.module.scss";

export interface FileUploadVariantProps
  extends Omit<IFileUploadProps, "onUpload"> {
  title?: string;
  notice?: string | React.ReactNode;
  noticeType?: ReminderType;
  reminder?: string;
  variantClassName?: string;
  disableUpload?: boolean;
  indicator?: LabelIndicator;
  indicatorSymbol?: LabelIndicatorIcon;
  indicatorCustom?: React.ReactElement;
}

export const pcAccept =
  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,.jpg, .xlsx, .xls";

const FileUploadVariant: React.FC<FileUploadVariantProps> =
  ErrorDetectorMediumView((props: FileUploadVariantProps) => {
    const {
      title,
      notice,
      noticeType,
      reminder,
      value,
      variantClassName,
      maxCount = 5,
      disableUpload,
      indicator,
      indicatorSymbol,
      indicatorCustom,
      onChange,
      ...rest
    } = props;

    const [confirmVisible, setConfirmVisible] = React.useState(false);
    const [stateValue, setValue] = React.useState<IFileUploadItem[]>(
      (value as IFileUploadItem[]) ?? []
    );
    const {
      surveyDeleteReminder,
      cancel,
      confirm,
      invalidFileReminder,
      defaultReminder,
      previewFail,
    } = useLang({
      cancel: { id: "common_btn_cancel" },
      confirm: { id: "common_btn_confirm" },
      surveyDeleteReminder: { id: "order_site_survey_delete" },
      invalidFileReminder: { id: "order_upload_limit_reminder" },
      defaultReminder: { id: "order_upload_file_reminder" },
      previewFail: { id: "order_preview_failed" },
    });
    const [currenFile, setCurrenFile] = React.useState<IFileUploadItem>();
    const [errorCode, setError] = React.useState<ErrorCode>();
    const toastError = useErrorMsg(errorCode as ErrorCode);
    const { open, close } = useRecoilValue(notificationHandleState);

    const indicatorSymbolCls =
      indicatorSymbol === LabelIndicatorIcon.TYPE_DOT
        ? styles.indicatorDot
        : styles.indicatorStar;
    const labelIndicatorCls = classNames(
      styles.labelIndicator,
      indicatorSymbolCls
    );
    const _indicator = disableUpload ? LabelIndicator.TYPE_OPTIONAL : indicator;
    const indicatorIconView = <div className={labelIndicatorCls} />;
    const indicatorView = indicatorCustom ? indicatorCustom : indicatorIconView;
    const showIndicator = _indicator !== LabelIndicator.TYPE_OPTIONAL;

    React.useEffect(() => {
      setValue(value as IFileUploadItem[]);
    }, [value]);

    const confirmCloseHandler = React.useCallback(
      (type: CloseType) => {
        setConfirmVisible(false);
        if (type == "ok") {
          const filtered = stateValue.filter((x) => x.url !== currenFile?.url);
          setValue(filtered);
          isFunction(onChange) && onChange(filtered);
        }
      },
      [currenFile?.url, onChange, stateValue]
    );

    const onChangeHandler = React.useCallback(
      (value: IFileUploadItem[]) => {
        setValue(value);
        isFunction(onChange) && onChange(value);
      },
      [onChange]
    );

    const confirmButtonView = React.useMemo(
      () => (
        <div className={styles.confirmButtonsPc}>
          <Button
            variant="secondary"
            onClick={confirmCloseHandler.bind(null, "cancel")}
          >
            {cancel}
          </Button>
          <Button
            variant="primary"
            onClick={confirmCloseHandler.bind(null, "ok")}
          >
            {confirm}
          </Button>
        </div>
      ),
      [cancel, confirm, confirmCloseHandler]
    );

    const confirmorView = React.useMemo(
      () => (
        <ConfirmVariantWithIcon
          visible={confirmVisible}
          onConfirm={confirmCloseHandler}
          title={surveyDeleteReminder}
          footer={confirmButtonView}
          type={ConfirmIconType.WARNING}
        />
      ),
      [
        confirmVisible,
        surveyDeleteReminder,
        confirmButtonView,
        confirmCloseHandler,
      ]
    );

    const beforeUploadHandler = (file: File): boolean => {
      const { name, size } = file ?? {};
      if (!name || !size) return false;
      const sizeLimit = 50 * 1024 * 1024;
      const fileType = identifyFileTypeByName(name);
      const supportFileList = [
        FileType.WORD,
        FileType.IMAGE,
        FileType.EXCEL,
        FileType.PDF,
      ];
      const valid = supportFileList.includes(fileType) && size <= sizeLimit;
      if (!valid) {
        toastError({ content: invalidFileReminder });
        return false;
      }
      return true;
    };

    const preViewHandler = (result: Promise<any>) => {
      if (!(result instanceof Promise)) return;
      const _key = `${previewFail}_${Date.now()}`;

      result.catch(() => {
        open({
          key: _key,
          content: (
            <NotificationContentView
              title={previewFail}
              contentType={NotificationType.failed}
              onClose={close.bind(null, _key)}
            />
          ),
        });
      });
    };

    const downloadHandler = (result: Promise<any>) => {
      if (!(result instanceof Promise)) return;
      result.catch(() => {
        setError(ErrorCode.COMMON_ERROR);
        toastError();
      });
    };

    const beforeDeleteHandler = (fileItem: IFileUploadItem): Promise<void> => {
      setCurrenFile(fileItem);
      setConfirmVisible(true);
      return Promise.reject();
    };

    const uploadHandler = (file: File) => {
      return InfoSalesModel.uploadFile(file).then((res) => {
        const { fileUrl: url, fileName: name } = res?.data ?? {};

        return { url, name };
      });
    };

    return (
      <div className={styles.fileUploadVariant}>
        {!!title && (
          <div className={styles.title}>
            {title}
            {showIndicator && (
              <div className={styles.indicatorCls}>{indicatorView}</div>
            )}
          </div>
        )}
        {!!notice && (
          <TextReminder reminderType={noticeType} label={notice as string} />
        )}
        <FileUpload
          {...rest}
          disabled={disableUpload}
          reminder={reminder || defaultReminder}
          maxCount={maxCount}
          value={stateValue}
          onChange={onChangeHandler}
          beforeUpload={beforeUploadHandler}
          onUpload={uploadHandler}
          beforeDelete={beforeDeleteHandler}
          onDownload={downloadHandler}
          onPreview={preViewHandler}
          onError={() => downloadHandler(Promise.reject())}
          accept={pcAccept}
        />
        {confirmorView}
      </div>
    );
  });

FileUploadVariant.defaultProps = {
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};

export default FileUploadVariant;
