import { Button } from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isEqual, isFunction } from "lodash";
import React from "react";
import Confirmor, { CloseType } from "../../../components/Confirmor/Confirmor";
import { ErrorDetectorMediumView } from "../../../components/ErrorDecorator/ErrorDecorator";
import Toast from "../../../components/Toast";
import {
  FileType,
  Upload,
  UploadItem,
  UploaderProps,
} from "../../../components/Upload/Upload";
import { identifyFileTypeByName } from "../../../components/Upload/utils";
import { ErrorCode } from "../../../core/ErrorCodeService";
import OrderInfoModel from "../../OrderRequest/OrderInfo/InfoSales/InfoSalesModel";
import { useErrorMsg } from "../../hooks/useErrorMsg";
import useLang from "../../hooks/useLang";
import {
  LabelIndicator,
  LabelIndicatorIcon,
} from "../InputVariant/InputVariant";
import TextReminder, { ReminderType } from "../TextReminder/TextReminder";
import styles from "./UploadVariant.module.scss";

interface IUploadVariant extends Omit<UploaderProps, "upload"> {
  title?: string;
  notice?: string | React.ReactNode;
  noticeType?: ReminderType;
  reminder?: string;
  upload?: (file: File) => Promise<UploadItem>;
  variantClassName?: string;
  indicator?: LabelIndicator;
  indicatorSymbol?: LabelIndicatorIcon;
  indicatorCustom?: React.ReactElement;
}

const UploadVariant: React.FC<IUploadVariant> = ErrorDetectorMediumView(
  (props: IUploadVariant) => {
    const {
      title,
      notice,
      noticeType,
      reminder,
      value,
      variantClassName,
      maxCount = 5,
      indicator,
      indicatorSymbol,
      indicatorCustom,
      onChange,
      ...rest
    } = props;
    const variantCla = classNames(
      styles.uploadVariant,
      { [styles.disabledUpload]: rest.disableUpload },
      variantClassName
    );
    const [confirmVisible, setConfirmVisible] = React.useState(false);
    const [stateValue, setValue] = React.useState<UploadItem[]>(
      formatUploadItem(value as UploadItem[]) ?? []
    );
    const stateValueRef = React.useRef(
      formatUploadItem(value as UploadItem[]) ?? []
    );
    const [currenFile, setCurrenFile] = React.useState<UploadItem>();
    const {
      surveyDeleteReminder,
      cancel,
      confirm,
      invalidFileReminder,
      previewFail,
      commonError,
    } = useLang({
      cancel: { id: "common_btn_cancel" },
      confirm: { id: "common_btn_confirm" },
      surveyDeleteReminder: { id: "order_site_survey_delete" },
      invalidFileReminder: { id: "order_upload_limit_reminder" },
      previewFail: { id: "order_preview_failed" },
      commonError: { id: "common_msg_error" },
    });
    const [errorCode, setError] = React.useState<ErrorCode>();
    const toastError = useErrorMsg(errorCode as ErrorCode);
    const _indicator = rest.disableUpload
      ? LabelIndicator.TYPE_OPTIONAL
      : indicator;
    const indicatorSymbolCls =
      indicatorSymbol === LabelIndicatorIcon.TYPE_DOT
        ? styles.indicatorDot
        : styles.indicatorStar;
    const labelIndicatorCls = classNames(
      styles.labelIndicator,
      indicatorSymbolCls
    );
    const indicatorIconView = <div className={labelIndicatorCls} />;
    const indicatorView = indicatorCustom ? indicatorCustom : indicatorIconView;
    const showIndicator = _indicator !== LabelIndicator.TYPE_OPTIONAL;

    React.useEffect(() => {
      const stateValue = formatUploadItem(value as UploadItem[]);
      if (isEqual(stateValueRef.current, stateValue)) return;
      setValue(stateValue);
      stateValueRef.current = stateValue;
    }, [value]);

    const confirmCloseHandler = React.useCallback(
      (type: CloseType) => {
        if (type == "ok") {
          const filtered = stateValue.filter((x) => x.url !== currenFile?.url);
          setValue(filtered);
          isFunction(onChange) && onChange(filtered);
        }
        setConfirmVisible(false);
      },
      [currenFile?.url, onChange, stateValue]
    );

    const onChangeHandler = React.useCallback(
      (value: UploadItem[]) => {
        setValue(value);
        isFunction(onChange) && onChange(value);
      },
      [onChange]
    );

    const confirmButtonView = React.useMemo(
      () => (
        <div className={styles.confirmButtons}>
          <Button
            className={styles.confirmBtn}
            variant="secondary"
            onClick={confirmCloseHandler.bind(null, "cancel")}
          >
            {cancel}
          </Button>
          <Button
            className={styles.confirmBtn}
            variant="primary"
            onClick={confirmCloseHandler.bind(null, "ok")}
          >
            {confirm}
          </Button>
        </div>
      ),
      [cancel, confirm, confirmCloseHandler]
    );

    const confirmorView = React.useMemo(
      () => (
        <Confirmor
          visible={confirmVisible}
          onConfirm={confirmCloseHandler}
          content={
            <div className={styles.deleteReminder}>{surveyDeleteReminder}</div>
          }
          footer={confirmButtonView}
        />
      ),
      [
        confirmVisible,
        surveyDeleteReminder,
        confirmButtonView,
        confirmCloseHandler,
      ]
    );

    const beforeUpload = (file: File) => {
      const { name, size } = file ?? {};
      if (!name || !size) return null;
      const sizeLimit = 50 * 1024 * 1024;
      const fileType = identifyFileTypeByName(name);
      const supportFileList = [
        FileType.WORD,
        FileType.IMAGE,
        FileType.EXCEL,
        FileType.PDF,
      ];
      const valid = supportFileList.includes(fileType) && size <= sizeLimit;
      if (!valid) {
        Toast.show({
          content: invalidFileReminder,
          contentWrapClassName: styles.uploadErrorReminder,
          duration: 4000,
        });
        return null;
      }
      return file;
    };

    const downloadHandler = (result: Promise<any>) => {
      if (!(result instanceof Promise)) return;
      result.catch(() => {
        setError(ErrorCode.COMMON_ERROR);
        toastError();
      });
    };

    const preViewHandler = (fileType: FileType, err: any) => {
      const msg = fileType === FileType.WORD ? previewFail : commonError;
      Toast.show({
        content: msg,
      });
    };

    return (
      <div className={variantCla}>
        {!!title && (
          <div className={styles.title}>
            {title}
            {showIndicator && (
              <div className={styles.indicatorCls}>{indicatorView}</div>
            )}
          </div>
        )}
        {!!notice && (
          <TextReminder reminderType={noticeType} label={notice as string} />
        )}
        {confirmorView}
        <Upload
          {...rest}
          maxCount={maxCount}
          value={stateValue}
          beforeUpload={beforeUpload}
          onChange={onChangeHandler}
          upload={function (file: File): Promise<UploadItem> {
            const uploadRes = OrderInfoModel.uploadFile(file);
            return uploadRes
              .then((res) => {
                const newItem = {
                  key: res.timestamp,
                  url: res.data.fileUrl,
                  name: res.data.fileName,
                  file: file,
                  fileType: identifyFileTypeByName(res.data.fileName),
                };
                setValue([...stateValue, newItem]);
                return newItem;
              })
              .catch(() => {
                setError(ErrorCode.COMMON_ERROR);
                toastError();
                return Promise.reject();
              });
          }}
          isDeleteConfirmor={true}
          onDelete={(file) => {
            setCurrenFile(file);
            setConfirmVisible(true);
            return false;
          }}
          onPreview={preViewHandler}
          onDownload={downloadHandler}
        />
        {reminder && <div className={styles.reminder}>{reminder}</div>}
      </div>
    );
  }
);

const formatUploadItem = (items: UploadItem[]): UploadItem[] => {
  return (
    items?.map((item) => ({
      ...item,
      fileType: identifyFileTypeByName(item?.name as string),
    })) ?? []
  );
};

UploadVariant.defaultProps = {
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};

export default UploadVariant;
