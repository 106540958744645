import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import FoldCabinet from "../../../../../../components/FoldCabinet/FoldCabinet";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import MoreDetailView from "../../../../../components/MoreDetailView/MoreDetailView";
import { IPartnerTeamOtherInfo } from "../../../../../model/OrderRequestModel";
import OrderInfoModel from "../../../InfoSalesModel";
import useOrderProductInfo, {
  OrderProdInfoProps,
} from "../../../hooks/useOrderProductInfo";
import InfoSalesMobileModel from "../../InfoSaleMobileModel";
import styles from "./OrderInfoView.module.scss";
import OrderRequestPartner from "./OrderRequestPartner";

const OrderInfoView: React.FC<OrderProdInfoProps> = ErrorDetectorMediumView(
  (props: OrderProdInfoProps) => {
    const {
      infos,
      pageStatus,
      productItems,
      visible,
      locale,
      getSaleOptions,
      changeHandler,
      folderChangeHandler,
    } = useOrderProductInfo(props);

    const saleBasicView = React.useMemo(() => {
      const saleModifyList = InfoSalesMobileModel.getSaleBasicModifyModels(
        infos as Partial<IPartnerTeamOtherInfo>,
        pageStatus
      );

      return (
        <div className={styles.orderBasicView}>
          {saleModifyList?.map((item) => {
            const { key, props, viewType } = item;
            const options = getSaleOptions(key as any);
            const newProps = {
              ...props,
              key,
              options: options,
              disabled: !options.length || props?.disabled,
              className:
                key === OrderInfoModel.INCOTERMS ? styles.incotermsText : null,
              onChange: (value: any) =>
                changeHandler(
                  key as keyof Partial<IPartnerTeamOtherInfo>,
                  value
                ),
            };
            const component = (
              <ViewComponent type={viewType as ViewType} props={newProps} />
            );
            return component;
          })}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infos, pageStatus, locale, getSaleOptions, changeHandler]);

    const saleMoreDetailView = React.useMemo(() => {
      if (!productItems?.length) return null;
      return (
        <FoldCabinet
          status={visible}
          onChange={folderChangeHandler}
          className={styles.saleMoreDetail}
        >
          <div className={styles.moreDetailContainer}>
            {productItems?.map((item) => (
              <div key={item.itemNo} className={styles.moreDetailItem}>
                <MoreDetailView {...item} />
              </div>
            ))}
          </div>
        </FoldCabinet>
      );
    }, [folderChangeHandler, productItems, visible]);

    return (
      <>
        <div className={styles.orderInfoView}>
          {saleBasicView}
          {saleMoreDetailView}
        </div>
        <OrderRequestPartner {...infos} />
      </>
    );
  }
);

export default OrderInfoView;
