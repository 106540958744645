import * as React from "react";

import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import styles from "./Accordion.module.scss";
import { AccordionItemProps } from "./types";
import { AccordionContext } from "./useAccordion";
import { AccordionItemContext, useAccordionItem } from "./useAccordionItem";

export const AccordionItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AccordionItemProps>
>(({ children, className, ...ownProps }, ref) => {
  const { htmlProps, ...context } = useAccordionItem(ownProps);
  const ctx = React.useMemo(() => context, [context]);
  const _accordionItem = classNames(styles.accordionItem, className);
  return (
    <AccordionItemContext.Provider value={ctx}>
      <div className={_accordionItem} ref={ref} {...htmlProps}>
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
});

/**
 * AccordionButton is used expands and collapses an accordion item.
 * It must be a child of `AccordionItem`.
 *
 * Note 🚨: Each accordion button must be wrapped in an heading tag,
 * that is appropriate for the information architecture of the page.
 */
export const AccordionButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>
>(({ children, className, ...props }, ref) => {
  const { isOpen, onClick, disabled } = React.useContext(AccordionItemContext);
  const { chevronSize } = React.useContext(AccordionContext);
  const _className = classNames(styles.accordionButton, className);
  const iconClassName = classNames(
    styles.accordionButtonIcon,
    {
      [styles.disabled]: disabled,
    },
    { [styles.isOpen]: isOpen }
  );
  return (
    <button
      {...props}
      className={_className}
      ref={ref}
      type="button"
      onClick={onClick}
      disabled={disabled}
      aria-disabled={disabled}
      aria-expanded={isOpen}
    >
      <div className={styles.accordionButtonContent}>{children}</div>
      <Icon icon="ChevronExpand" size={chevronSize} className={iconClassName} />
    </button>
  );
});

/**
 * Accordion panel that holds the content for each accordion.
 * It shows and hides based on the state login from the `AccordionItem`.
 */
export const AccordionPanel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>(({ children, className, ...props }, ref) => {
  const [maxHeight, setMaxHeight] = React.useState<number>(0);
  const { isOpen } = React.useContext(AccordionItemContext);
  const innerRef = React.useRef<HTMLDivElement>(null);
  const _className = classNames(styles.accordionPanel, className);

  React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

  React.useEffect(() => {
    if (innerRef.current) {
      const panel = innerRef.current;
      setMaxHeight(panel.scrollHeight);
    }
  }, [isOpen]);

  return (
    <div
      className={_className}
      ref={innerRef}
      role="region"
      style={{ maxHeight }}
      {...props}
    >
      <div className={styles.accordionButtonContent}>{children}</div>
    </div>
  );
});
