import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { ApSupervisorAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationApSupervisor.module.scss";
import InstallationApSupervisorModel from "./InstallationApSupervisorModel";
import useInstallationApSupervisor from "./useInstallationApSupervisor";

const InstallationApSupervisorMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemReginOrg,
    assignAndForwardVisible,
    submitEnable,
    assignAndForwArdConfig,
    userId,
    locale,
    setVisible,
    assignAndForwardHandler,
    backAllTickHandler,
    setForwardAndAssignHandler,
    changeHandler,
  } = useInstallationApSupervisor();

  const dateInfoView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getTimeViewMobileModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const datePickKeys = [
      InstallationModel.PLAN_TRAINING_START_DATE,
      InstallationModel.PLAN_TRAINING_END_DATE,
    ];
    const views = list.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          }
        : props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return <div className={styles.dateInfoView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, infos, rolePage?.pageStatus, changeHandler]);

  const installationInfoView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationApSupervisorModel.getInfoViewModel(
      infos as IInstallation,
      systemReginOrg
    );

    const views = list.map(({ viewType, props, key }) => {
      const _props =
        key === InstallationModel.WARRANTY_PERIOD
          ? { ...props, className: styles.warrantyPeriod }
          : props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installationInfoView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, systemReginOrg, locale]);

  const filesView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getFileInfoViewModel(
      infos as IInstallation
    );
    const views = list.map(({ viewType, props, key }) => {
      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, locale]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<ApSupervisorAction, Record<string, any>>([
      [ApSupervisorAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [
        ApSupervisorAction.FORWARD,
        { onClick: setForwardAndAssignHandler.bind(null, Action.FORWARD) },
      ],
      [
        ApSupervisorAction.ASSIGN,
        { onClick: setForwardAndAssignHandler.bind(null, Action.ASSIGN) },
      ],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === ApSupervisorAction.ASSIGN ? !submitEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setForwardAndAssignHandler,
  ]);

  return (
    <section className={styles.installationApSupervisor}>
      <div className={styles.pageContainer}>
        {dateInfoView}
        {installationInfoView}
        <OrderInfoDnView {...(infos.dnInfo as IDNInfo)} />
        {filesView}
        <CommentsView />
      </div>
      {buttonsView}
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          role={assignAndForwArdConfig.role}
          action={assignAndForwArdConfig.action}
          feature={Feature.TRAINING}
          searchQuery={{ aPSupervisorUserId: userId }}
          actionData={{
            ...assignAndForwArdConfig.otherData,
            selectUserIdKey: "aprbacCorrelationId",
          }}
          onActionResponse={assignAndForwardHandler}
          onClose={() => setVisible(false)}
        />
      )}
    </section>
  );
});

export default InstallationApSupervisorMobile;
