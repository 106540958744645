import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import PhoneCall from "../../../../components/PhoneCall/PhoneCall";
import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import DatePickerVariant from "../../../components/DatePickerVariant/DatePickerVariant";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import SwitchCabinet from "../../../components/SwitchCabinet/SwitchCabinet";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import Text from "../../../components/Text/Text";
import UploadVariant from "../../../components/UploadVariant/UploadVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { FseAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import CommentsView from "../../components/CommentView/CommentsView";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import SiteSurveyModel from "../model/SiteSurveyModel";
import styles from "./SiteSurveyFSE.module.scss";
import SiteSurveyFseModel from "./SiteSurveyFseModel";
import useSiteSurveyFse from "./useSiteSurveyFse";

const SiteSurveyFseMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    submitOrderReminder,
    cancel,
    confirm,
    rejectAssign,
    expectedStartDate,
    actuarySiteSurveyDate,
    rolePage,
    thirdParty,
    labelExpectedDate,
    labelActualSiteSurveyDate,
    siteSurveyDone,
    isSiteSurveyDone,
    siteSurveyReport,
    uploadReminder,
    siteSurveyReports,
    submitEnable,
    rejectVisible,
    submitVisible,
    buttonBlock,
    siteSurveyFseInfo,
    orgs,
    confirmCloseHandler,
    rejectHandler,
    changeHandler,
    backToAllTicket,
    setReject,
    setSubmit,
  } = useSiteSurveyFse();

  const contactView = React.useMemo(() => {
    const list = SiteSurveyFseModel.getContactInfoModel(siteSurveyFseInfo);

    return (
      <div className={styles.contactView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.MOBILE_PHONE,
              {
                ...props,
                children: <PhoneCall phoneNumber={props?.children} />,
              },
            ],
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;
          const component = (
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...newProps,
                key,
              }}
            />
          );
          return component;
        })}
      </div>
    );
  }, [siteSurveyFseInfo]);

  const servicePersonView = React.useMemo(() => {
    const list = SiteSurveyFseModel.getOrgInfoModel(
      siteSurveyFseInfo?.partnerTeamOtherInfo,
      orgs
    );
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            key,
          }}
        />
      );
      return component;
    });

    return (
      <div className={styles.siteSurveyView}>
        {orgView}
        <div></div>
      </div>
    );
  }, [siteSurveyFseInfo, orgs]);

  const siteSurveyView = React.useMemo(() => {
    const expectedLabel = formatDate(expectedStartDate);
    const formatDateParams = formatDatePickValue(actuarySiteSurveyDate);
    const disabled = rolePage?.pageStatus === PageStatus.READONLY;

    return (
      <div className={styles.siteSurveyView}>
        <SiteSurveyAccessory accessories={thirdParty} />
        <Text label={labelExpectedDate} className={styles.expectedDate}>
          {expectedLabel}
        </Text>
        <div className={styles.datePicker}>
          <DatePickerVariant
            label={labelActualSiteSurveyDate}
            value={formatDateParams}
            disabled={disabled}
            onConfirm={(value) =>
              changeHandler(
                SiteSurveyModel.ACTUAL_SITE_SURVEY_DATE,
                value?.getTime()
              )
            }
          />
        </div>
        <SwitchCabinet
          cabinetLabel={siteSurveyDone}
          cabinetClassName={styles.cabinetCls}
          checked={isSiteSurveyDone ? isSiteSurveyDone : false}
          disabled={disabled}
          indicator={LabelIndicator.TYPE_REQUIRED}
          onChange={(value) =>
            changeHandler(SiteSurveyModel.IS_SITE_SURVEY_DONE, value)
          }
        />
        <UploadVariant
          variantClassName={styles.uploadCls}
          title={siteSurveyReport}
          reminder={disabled ? "" : uploadReminder}
          maxCount={5}
          onChange={(value) =>
            changeHandler(SiteSurveyModel.SITE_SURVEY_REPORT, value)
          }
          value={siteSurveyReports}
          deletable={!disabled}
          disableUpload={disabled}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expectedStartDate,
    actuarySiteSurveyDate,
    rolePage?.pageStatus,
    thirdParty,
    labelExpectedDate,
    labelActualSiteSurveyDate,
    siteSurveyDone,
    isSiteSurveyDone,
    siteSurveyReport,
    uploadReminder,
    siteSurveyReports,
    changeHandler,
  ]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyFseModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<FseAction, Record<string, any>>([
      [FseAction.ALL_TICKET, { onClick: backToAllTicket }],
      [FseAction.REJECT, { onClick: () => setReject(true) }],
      [FseAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === FseAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, submitEnable, backToAllTicket, setReject, setSubmit]);

  return (
    <>
      <div className={styles.siteSurveyRse}>
        <div className={styles.pageContainer}>
          {contactView}
          {servicePersonView}
          {siteSurveyView}
          <CommentsView />
        </div>
        {buttonViews}
      </div>
      <Confirmor
        visible={submitVisible}
        onConfirm={confirmCloseHandler}
        content={submitOrderReminder}
        buttonTexts={[
          {
            type: "secondary",
            label: cancel,
            closeType: "cancel",
          },
          {
            type: "primary",
            label: confirm,
            closeType: "ok",
            disabled: buttonBlock,
          },
        ]}
      />
      <RejectModalVariant
        title={rejectAssign}
        visible={rejectVisible}
        onConfirm={rejectHandler}
      />
    </>
  );
});

export default SiteSurveyFseMobile;
