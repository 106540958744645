import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import Cabinet, { ICabinet } from "../../../components/Cabinet/Cabinet";
import { ErrorDetectorSmallView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { getCssVariable } from "../../../utils/commonUtils";
import { isFunction } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import styles from "./FoldCabinet.module.scss";

export interface IFoldCabinet extends ICabinet {
  label?: string | React.ReactElement;
  className?: string;
  labelClassName?: string;
  onChange?: (status: boolean) => void;
  icon?: React.ReactElement;
}

const FoldCabinet: React.FC<IFoldCabinet> = ErrorDetectorSmallView(
  (props: IFoldCabinet) => {
    const {
      label,
      labelClassName,
      status,
      onChange,
      icon,
      className,
      children,
      ...rest
    } = props;
    const cls = classNames(styles.foldCabinet, className);
    const labelCls = classNames(styles.label, labelClassName);
    const [foldState, setFold] = React.useState(() => status ?? false);
    const { defaultLabel } = useLang({
      defaultLabel: { id: "order_info_more_detail" },
    });

    React.useEffect(() => setFold(status), [status]);

    const changeHandler = React.useCallback(() => {
      const newStatus = !foldState;

      setFold(newStatus);
      isFunction(onChange) && onChange(newStatus);
    }, [foldState]);

    const iconView = React.useMemo(() => {
      if (icon) return icon;
      const iconCls = classNames(styles.icon, {
        [styles.iconActive]: foldState,
      });
      const fill = getCssVariable("--color-interaction-link");
      return (
        <span className={iconCls}>
          <Icon icon={"ChevronExpand"} size={16} fill={fill} />
        </span>
      );
    }, [icon, foldState]);

    return (
      <section>
        <div className={cls} onClick={changeHandler}>
          <span className={labelCls}>{label ?? defaultLabel}</span>
          {iconView}
        </div>
        <Cabinet status={foldState} {...rest}>
          {children}
        </Cabinet>
      </section>
    );
  }
);

export default FoldCabinet;
