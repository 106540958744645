import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { ApEngineerAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationApEngineer.module.scss";
import InstallationApEngineerModel from "./InstallationApEngineerModel";
import useInstallationApEngineer from "./useInstallationApEngineer";

const InstallationApEngineerMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemOrgs,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitEnable,
    rejectVisible,
    rejectTitle,
    submitVisible,
    labelSubmitReminder,
    locale,
    rejectHandler,
    setReject,
    setSubmit,
    changeHandler,
    backAllTickHandler,
    confirmHandler,
  } = useInstallationApEngineer();

  const dateInfoView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getTimeViewMobileModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const datePickKeys = [
      InstallationModel.ACTUAL_TRAINING_START_DATE,
      InstallationModel.ACTUAL_TRAINING_END_DATE,
    ];
    const views = list.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
            variantClassName:
              key === InstallationModel.ACTUAL_TRAINING_START_DATE
                ? styles.actualTrainStartDate
                : "",
          }
        : {
            ...props,
            className:
              key === InstallationModel.PLAN_TRAINING_END_DATE
                ? styles.planedEndDate
                : "",
          };

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return <div className={styles.dateInfoView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, infos, rolePage?.pageStatus, changeHandler]);

  const installationInfoView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationApEngineerModel.getInfoViewModel(
      infos as Partial<IInstallation>,
      systemOrgs
    );

    const views = list.map(({ viewType, props, key }) => {
      const propsMaps = new Map([
        [
          InstallationModel.ACTUAL_INSTALL_END_DATE,
          {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          },
        ],
        [
          InstallationModel.ACTUAL_INSTALL_START_DATE,
          {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          },
        ],
        [
          InstallationModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);

      const _props = propsMaps.get(key as string) ?? props;
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installationInfoView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, systemOrgs, locale, changeHandler]);

  const filesView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getFileInfoViewModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === InstallationModel.TRAINING_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, rolePage?.pageStatus, locale, changeHandler]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  const buttonsView = React.useMemo(() => {
    const list = InstallationApEngineerModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<ApEngineerAction, Record<string, any>>([
      [ApEngineerAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [ApEngineerAction.REJECT, { onClick: () => setReject(true) }],
      [ApEngineerAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === ApEngineerAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );
      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setReject,
    setSubmit,
  ]);

  return (
    <section className={styles.installationApEngineer}>
      <div className={styles.pageContainer}>
        {dateInfoView}
        {installationInfoView}
        <OrderInfoDnView {...(infos.dnInfo as IDNInfo)} />
        {filesView}
        <CommentsView />
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          onConfirm={rejectHandler}
        />
      )}
      <Confirmor
        footer={confirmButtonView}
        visible={submitVisible}
        content={labelSubmitReminder}
      />
    </section>
  );
});

export default InstallationApEngineerMobile;
