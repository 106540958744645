import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { LocaleLang } from "../../../../core/LocaleService";
import { SystemPlatform } from "../../../../core/SystemService";
import { StaticSourceItem } from "../../../../services/order.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems, SubTabs } from "../../../service/pageType";
import InstallationModel, { IInstallation } from "../installationModel";
import InstallationSaleModel from "./InstallationSalesModel";

export interface HookInstallationSales {
  installationSaleInfo: Partial<IInstallation>;
  rolePage: SubTabs;
  saleRegions: {
    apSupervisor: StaticSourceItem[];
    regionProductLine: Partial<StaticSourceItem>[];
  };
  labelFacRequired: string;
  reminderFacRequired: string;
  labelFacUploader: string;
  labelTrainingRequired: string;
  reminderTrainingRequired: string;
  labelApSupervisor: string;
  labelExpectedInstallDate: string;
  labelWarrantyPeriod: string;
  labelWarrantyPeriodPhd: string;
  labelDisclaimerNotice: string;
  submitEnable: boolean;
  labelCancel: string;
  buttonBlock: boolean;
  labelConfirm: string;
  submitVisible: boolean;
  labelSubmitReminder: string;
  platform: SystemPlatform;
  locale: LocaleLang;
  confirmHandler: (type: CloseType) => void;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  backAllTickHandler: () => void;
  changeHandler: (key: keyof IInstallation, value: any) => void;
}

export default function (): HookInstallationSales {
  const {
    labelFacRequired,
    labelTrainingRequired,
    reminderFacRequired,
    reminderTrainingRequired,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelApSupervisor,
    labelCancel,
    labelConfirm,
    labelSubmitReminder,
    labelWarrantyPeriodPhd,
    labelDisclaimerNotice,
    labelFacUploader,
  } = useLang({
    labelFacRequired: { id: "order_install_fac_required" },
    labelTrainingRequired: { id: "order_install_train_required" },
    reminderFacRequired: { id: "order_install_fac_reminder" },
    reminderTrainingRequired: { id: "order_install_training_reminder" },
    labelExpectedInstallDate: { id: "order_install_expected_data" },
    labelWarrantyPeriod: { id: "order_install_warranty_period" },
    labelYes: { id: "common_yes_upper" },
    labelNO: { id: "common_no_upper" },
    labelApSupervisor: { id: "order_ap_supervisor" },
    labelCancel: { id: "common_btn_cancel" },
    labelConfirm: { id: "common_btn_confirm" },
    labelSubmitReminder: { id: "order_confirm_order_reminder" },
    labelWarrantyPeriodPhd: { id: "order_warranty_period_comment_phd" },
    labelDisclaimerNotice: { id: "order_disclaimer_notice" },
    labelFacUploader: { id: "order_install_fac_uploader" },
  });
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [installationSaleInfo, setInfo] = React.useState<
    Partial<IInstallation>
  >({});
  const [errCode, setError] = React.useState<ErrorCode>();
  const saleRegions = useRecoilValue(systemUserOrgs);
  const [buttonBlock, setBlock] = React.useState(false);
  const [submitVisible, setSubmit] = React.useState(false);
  const rolePage = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );
  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const submitEnable = React.useMemo(
    () => InstallationSaleModel.verifySubmission(installationSaleInfo),
    [installationSaleInfo]
  );
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);

  React.useEffect(() => {
    getInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getInstallationInfo(installationTaskId, locale)
        .then((res) => {
          setInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const submitInfo = React.useCallback(() => {
    const {
      euCs,
      deliveryNotes,
      technicalAgreements,
      specifications,
      disclaimers,
      expectedInstallDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      otherWarrantyPeriod,
      auditEmails,
    } = installationSaleInfo;

    const submitData = {
      euCs,
      deliveryNotes,
      technicalAgreements,
      specifications,
      disclaimers,
      installationTaskId,
      expectedInstallDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      otherWarrantyPeriod,
      auditEmails,
    };

    return InstallationSaleModel.submitInstallationInfo(submitData as any);
  }, [installationSaleInfo, installationTaskId]);

  const changeHandler = React.useCallback(
    (key: keyof IInstallation, value: any) => {
      setInfo((pre) => ({ ...pre, [key]: value }));
    },
    []
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setSubmit(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      setLoading(true);
      setBlock(true);
      submitInfo()
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [
      setSubmit,
      setLoading,
      setBlock,
      submitInfo,
      backAllTickHandler,
      setError,
      toastError,
    ]
  );

  return {
    installationSaleInfo,
    rolePage,
    saleRegions,
    labelFacRequired,
    reminderFacRequired,
    labelFacUploader,
    labelTrainingRequired,
    reminderTrainingRequired,
    labelApSupervisor,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelWarrantyPeriodPhd,
    labelDisclaimerNotice,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitVisible,
    labelSubmitReminder,
    platform,
    locale,
    confirmHandler,
    setSubmit,
    backAllTickHandler,
    changeHandler,
  };
}
