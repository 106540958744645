export default {
  order_main_product: "设备类型",
  order_sub_order: "整机订单号",
  order_final_goods_recipe: "最终用户",
  order_po_date: "下单日期",
  order_step: "状态",
  order_step_site_survey_req_sub: "场测申请提交",
  order_step_site_survey_assign: "场测派工",
  order_step_site_survey_implement: "场测执行",
  order_step_install_req: "安装申请提交",
  order_step_install_audit: "安装申请审核",
  order_step_install_assign: "安装派工",
  order_step_install_implement: "安装执行",
  order_step_trainingAssign: "应用培训派工",
  order_step_training_implement: "应用培训执行",
  order_step_upload_fac: "上传验收报告",
  order_step_update_crm: "更新IBase",
  order_title_detail: "订单详情",
  order_info_sale_emp: "销售",
  order_info_sale_manager: "销售经理",
  order_info_service_employee: "服务员工",
  order_info_incoterms: "Incoterms",
  order_info_sale_team: "销售团队",
  order_info_customer_type: "客户类型",
  order_info_region: "服务区域",
  order_info_product_line: "产品线",
  order_info_service_supervisor: "服务主管",
  order_dn_notice: "DN创建后可以获取以下数据",
  order_dn_cpo_date: "CPO日期",
  order_dn_install_order_no: "安装订单号",
  order_dn_serial_number: "序列号",
  order_dn_service_contactor_order: "服务合同订单号",
  order_contact_person: "联系人",
  order_contact_mobile: "手机",
  order_contact_email: "邮箱",
  order_contact_recipe_city: "城市",
  order_contact_recipe_address: "地址",
  order_post_code: "邮编",
  order_sold_to_party: "买方",
  order_info_more_detail: "详情",
  order_info_partner_title: "需求相关人员",
  order_ap_supervisor: "应用主管",
  order_sale_manager: "销售经理",
  order_ap_engineer: "应用工程师",
  order_contact_company_name: "名称",
  order_final_goods_recipe_new: "实际最终用户",
  order_customer_type_not_industry: "非工业",
  order_customer_type_industry: "工业",
  order_site_survey_required_yes:
    "若选择“是”，会流转至服务工程师团队执行场测。",
  order_expected_site_survey_data: "期望场测日期",
  order_spec: "规格",
  order_contact_email_optional: "邮箱 (可选)",
  order_site_survey_required: "是否需要场测",
  order_site_survey_selected: "若选择“是”，会流转至服务工程师团队执行场测。",
  order_site_survey_not_selected: "若选择“否”，请上传免责声明",
  order_site_survey_expected_date: "期望场测日期",
  order_accessories: "第三方附件",
  order_airlock: "Airlock",
  order_mute: "静音模式",
  order_ep: "EP模式（针对EVO机型）",
  order_cryo: "Cryo冷冻传输系统",
  order_laser: "Laser",
  order_3_view: "3View",
  order_cool_table: "冷台（非Cryo）",
  order_cl: "CL",
  order_energy_spectrum: "能谱",
  order_ebsd: "EBSD",
  order_vibration_isolation: "防震台",
  order_magnetic_eraser: "消磁器",
  order_ups: "UPS不间断电源",
  order_other: "其他",
  order_accessory_brand: "品牌及型号",
  order_accessory_computer: "自带电脑、桌子、外置模块",
  order_accessory_install_position: "在样品仓的安装方位（面对主机）",
  order_accessory_need_support: "ZEISS工程师负责安装",
  order_accessory_with_out_control: "自带外置控制模块",
  order_accessory_with_battery_cabinet: "自带外置电池柜及供电时长",
  order_install_pos_left: "左侧",
  order_install_pos_right: "右侧",
  order_install_pos_not_apply: "不适用",
  order_cancel_order_reminder: "取消订单将无法撤消。请确认是否删除？",
  order_confirm_order_reminder: "确定要提交申请吗？",
  order_upload_disclaimer: "免责声明",
  order_upload_file_reminder:
    "上传文件支持 JPG, PNG, JPEG, PDF, Word,Excel 格式， 上传数量最多为5个，且每个文件大小不超过50M",
  order_contact_person_name: "联系人姓名",
  order_site_survey_qualified: "场测是否合格",
  order_site_survey_report: "场测报告",
  order_reject_reason: "拒绝原因",
  order_reject_assignment: "请确认是否要拒绝此派工？",
  order_reject_pdh: "请填写拒绝原因",
  order_site_survey_actual_date: "实际场测日期",
  order_install_fac_required: "是否需要验收",
  order_install_train_required: "是否需要应用培训",
  order_install_fac_reminder:
    "若选择“是”，需上传用户验收报告；若选择“否”，则不需要验收，安装日期视为验收日期",
  order_install_training_reminder:
    "若选择“是”，安装后此订单会流转至应用团队，进入培训流程",
  order_install_expected_data: "期望安装日期",
  order_install_warranty_period: "质保期",
  order_upload_euc_euu: "EUC/EUU（可选）",
  order_delivery_notes: "Delivery Note",
  order_install_tech_agr: "技术协议（可选）",
  order_install_specs: "技术参数（可选）",
  order_train_reports: "应用培训报告",
  order_actual_install_start_date: "实际安装开始日期",
  order_actual_install_end_date: "实际安装结束日期",
  order_install_warranty_period_12_reminder:
    "自卖方代表与买方或产品最终用户的代表在验收证书或其它验收文件上签字之日起算。如果非因卖方原因。前述文件在交货后三个月内仍未签署，质保有效期自交货日起算。",
  order_install_warranty_period_15_reminder:
    "该交货日以卖方ERP系统开票日期为准",
  order_install_warranty_12_months: "十二（12）个月",
  order_install_warranty_delivery_in_15_months: "卖方交货日起十五（15）个月",
  order_so_reject_install: "请确认是否要驳回此安装申请？",
  order_so_confirm_install: "请确认是否要通过此安装申请？",
  order_service_coordinator: "服务助理",
  order_site_survey_delete: "请确认是否要删除此文件？",
  order_need_site_survey: "需要场测",
  order_rsm_audit_need_site_survey_reminder:
    "请确认是否要补场测？此操作将会使流程重新流转到场测申请。",
  order_planed_installation_start_date: "预计安装开始日期",
  order_planed_installation_end_date: "预计安装结束日期",
  order_installation_reports: "安装报告",
  order_training_report: "应用培训报告",
  order_plan_training_end_time: "预计应用培训结束日期",
  order_plan_training_start_time: "预计应用培训开始日期",
  order_actual_training_end_time: "实际应用培训结束日期",
  order_actual_training_start_time: "实际应用培训开始日期",
  order_file_preview: "预览文件",
  order_file_download: "下载文件",
  order_list_search_phd: "请输入整机订单号/最终用户/设备类型/联系人",
  order_list_search_status_to_do: "待处理",
  order_list_search_status_done: "已处理",
  order_list_search_status_complete: "已完成",
  order_list_search_status_cancel: "已取消",
  order_warranty_period_comment_phd:
    "请填写备注，备注为必填项，不填写无法提交。",
  order_rsm_need_site_survey_switch_reminder: "请将是否需要场测选项改为“是”",
  order_install_date_reminder: "请核实最终验收日期和质保期",
  order_crm_confirm_reminder: "请确认设备信息是否已在CRM更新？",
  order_crm_date_reminder:
    "请核实安装日期，验收日期以及质保开始/结束日期，并维护责任工程师",
  order_fac_installation_date: "安装日期",
  order_fac_acceptance_date: "验收日期",
  order_warranty_start_date: "质保开始日期",
  order_warrant_end_data: "质保结束日期",
  order_fac_cac_report: "验收报告",
  order_disclaimer_notice: "由于客户场测不合格，此处需上传免责声明。",
  order_delivery_note_optional: "Delivery Note (可选)",
  order_comment_toggle_number: "展开剩余{value}条",
  order_fse_site_survey: "场测服务工程师",
  order_fse_installation: "安装服务工程师",
  order_upload_limit_reminder:
    "请选择小于50M的 图片（PNG, JPG, JPEG格式），PDF，Word，Excel 文件",
  order_dn_block_notice: "此订单尚未接收到DN,无法进行安装流程",
  order_list_unSplit_reminder: "请将此订单按实际场测与安装情况进行拆分",
  order_list_unSplit_label: "待拆分订单",
  order_split_order_zero_reminder: "是否确认取消拆分此订单？",
  order_dn_delivery: "DN 是否生成",
  order_split_device: "请输入每笔订单中主设备数量",
  order_reminder_split_order:
    "请根据实际需要，将一个整机订单拆分成多个场测订单（若不需要拆分可以直接提交），系统会依据下方卡片中填写的内容，将主设备分配到每一个场测订单中",
  order_linker_sub_order: "查看关联同一整机订单下其他订单",
  order_list_search_status_all: "全部",
  order_owner: "负责人",
  order_operation: "操作",
  order_list_table_view: "查看",
  order_rsm_site_survey: "场测服务主管",
  order_rsm_shorter: "服务主管",
  order_rsm_installation: "安装服务主管",
  order_rsm_owner: "责任服务主管",
  order_install_fac_uploader: "验收报告上传负责人",
  order_device_name: "设备名称",
  order_after_sales: "售后",
  order_select_phd: "请选择",
  order_iBase_number: "IBase 序号",
  order_verify_contact_person: "请填写正确的联系人信息。",
  order_order_number: "订单号",
  order_comment_employee_quite: "员工已离职",
  order_material_no: "物料号",
  order_audit_email: "审批邮件（可选）",
  order_audit_email_reminder: "如最终用户发生变更，请务必在此处上传审批邮件",
  order_owner_installation_fse: "责任工程师",
  order_disclaimer_not_site_survey: "由于客户未进行场测，此处需上传免责声明",
  order_material_name: "物料名称",
  order_preview_failed: "预览文件失败，请尝试下载打开",
  order_contact_person_reminder: "请修改为最终用户联系人，中文名字和手机必填。",
  order_list_reset_ticket:
    "你确定要重置该订单吗？重置后该订单将恢复到初始状态。",
};
