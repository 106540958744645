import { cloneDeep, isFunction } from "lodash";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IConfirmor } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import {
  IOrderContactUpdateReq,
  IOrderFinalGoodRecipAddReq,
} from "../../../../services/order.service";
import { loadState, orderTaskIdState } from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import InfoSalesModel, {
  ContactPersonStatus,
  FinalGoodsRecipStatus,
} from "../../OrderInfo/InfoSales/InfoSalesModel";
import { ViewItemModel } from "../../model/OrderRequestModel";
import { CloseType } from "./../../../../components/Confirmor/Confirmor";

export enum Action {
  Cancel = "cancel",
  Confirm = "confirm",
}

export interface ModifyContactProps extends Omit<IConfirmor, "onConfirm"> {
  title: string;
  models: Partial<ViewItemModel>[];
  subTitle?: string;
  status: FinalGoodsRecipStatus | ContactPersonStatus;
  onConfirm?: (type: CloseType) => void;
}

const getModifyInfos = (
  models: Partial<ViewItemModel>[]
): Record<string, any> => {
  if (!Array.isArray(models)) return [];
  const result = models.map(({ key, props }) => [key, props?.value?.trim()]);
  return Object.fromEntries(result);
};

export default function (props: ModifyContactProps) {
  const { cancel, confirm } = useLang({
    cancel: { id: "common_btn_cancel" },
    confirm: { id: "common_btn_confirm" },
  });
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const { title, models, subTitle, status, visible, onConfirm, ...rest } =
    props;
  const [stateModels, setModels] =
    React.useState<Partial<ViewItemModel>[]>(models);
  const [stateVisible, setVisible] = React.useState(() => visible);
  const [blockBtn, setBlock] = React.useState(false);
  const [errorCode, setError] = React.useState<ErrorCode>();
  const toastError = useErrorMsg(errorCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  React.useEffect(() => {
    setModels(models);
  }, [models]);

  React.useEffect(() => setVisible(visible), [visible]);

  const changeHandler = React.useCallback(
    (key: string, value: string) => {
      const copyModel = cloneDeep(stateModels);
      const changeItem: any = copyModel.find((item: any) => item?.key === key);
      if (!changeItem) return;
      changeItem.props = {
        ...changeItem.props,
        value: value,
      };

      setModels(copyModel);
    },
    [stateModels]
  );

  const addFinalGoodsRecip = React.useCallback(() => {
    return InfoSalesModel.addFinalGoodsRecip({
      ...getModifyInfos(stateModels),
      installationTaskId,
    } as IOrderFinalGoodRecipAddReq)
      .then(() => isFunction(onConfirm) && onConfirm("ok"))
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [installationTaskId, stateModels, onConfirm, setLoading, toastError]);

  const updateContactPerson = React.useCallback(() => {
    return InfoSalesModel.updateContactPerson({
      ...getModifyInfos(stateModels),
      installationTaskId,
    } as IOrderContactUpdateReq)
      .then(() => isFunction(onConfirm) && onConfirm("ok"))
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [installationTaskId, stateModels, onConfirm, setLoading, toastError]);

  const saveHandler = React.useCallback(() => {
    const saveMap = new Map<string, any>([
      [FinalGoodsRecipStatus.ADD, addFinalGoodsRecip],
      [FinalGoodsRecipStatus.UPDATE, addFinalGoodsRecip],
      [ContactPersonStatus.UPDATE, updateContactPerson],
    ]);
    setLoading(true);
    setBlock(true);
    return saveMap.get(status as unknown as string);
  }, [status, addFinalGoodsRecip, updateContactPerson, setLoading]);

  const clickHandler = React.useCallback(
    (action: Action) => {
      if (action === Action.Cancel) {
        setVisible(false);
        isFunction(onConfirm) && onConfirm("cancel");
        return;
      }
      const handler = saveHandler();
      isFunction(handler) && handler();
    },
    [saveHandler, onConfirm]
  );

  const confirmHandler = React.useCallback(
    (closeType: CloseType) => {
      clickHandler(closeType as Action);
    },
    [clickHandler]
  );

  return {
    stateModels,
    title,
    subTitle,
    stateVisible,
    cancel,
    confirm,
    blockBtn,
    rest,
    confirmHandler,
    clickHandler,
    changeHandler,
  };
}
