import { Button, Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import Empty from "../../../assets/svg/Empty";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { CloseType } from "../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { Pagination } from "../../../components/Pagination/Pagination";
import Table, { ITableItem } from "../../../components/Table/Table";
import Tabs from "../../../components/Tabs/Tabs";
import Tooltip, { TooltipType } from "../../../components/Tooltip/Tooltip";
import SystemService, { SystemEnv } from "../../../core/SystemService";
import { useTitle } from "../../../hooks";
import {
  AdminResetType,
  IOrderListRequest,
} from "../../../services/order.service";
import {
  localeState,
  orderParentTaskIdState,
  orderRequestTabs,
  orderTaskIdState,
  stepState,
  systemUserOrgs,
  userState,
} from "../../../store";
import { getCssVariable } from "../../../utils/commonUtils";
import { isFunction, isObject } from "../../../utils/isType";
import HomeModel from "../../Home/HomeModel";
import OrderRequestModel from "../../OrderRequest/model/OrderRequestModel";
import { IndicatorNew } from "../../components/Text/IndicatorNew/IndicatorNew";
import useLang from "../../hooks/useLang";
import { PagePaths } from "../../service/pageType";
import OrderListModel, { OrderStatus, OrderTableItem } from "../OrderListModel";
import useLogout from "../useLogout";
import styles from "./OrderListPc.module.scss";
import OrderListPcModel, { TableActions } from "./OrderListPcModel";
import { SearchPc } from "./SearchPc/SearchPc";

export enum PcListViewType {
  Basic,
  Admin,
}
export interface IOrderListPcProps {
  viewType: PcListViewType;
}

const OrderListPc: React.FC<IOrderListPcProps> = ErrorDetectorPageView(
  (props: IOrderListPcProps) => {
    const {
      title,
      searchPhd,
      labelSearch,
      labelView,
      labelEmpty,
      labelUnSplitReminder,
      totalLabel,
      pageLabel,
      adminTitle,
      reminderRest,
      labelReset,
      labelRecover,
    } = useLang({
      title: { id: "common_btn_all_tickets" },
      searchPhd: { id: "order_list_search_phd" },
      labelSearch: { id: "common_btn_search" },
      labelView: { id: "order_list_table_view" },
      labelEmpty: { id: "common_empty_phd" },
      labelUnSplitReminder: { id: "order_list_unSplit_reminder" },
      totalLabel: { id: "common_pagination_total_item" },
      pageLabel: { id: "common_pagination_per_page" },
      adminTitle: { id: "common_page_title_ticket_management" },
      reminderRest: { id: "order_list_reset_ticket" },
      labelReset: { id: "common_btn_reset" },
      labelRecover: { id: "common_btn_recover" },
    });
    const { viewType } = props;
    const [list, setList] = React.useState<OrderTableItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [searchValue, setSearch] = React.useState("");
    const [orderStatus, setOrderStatus] = React.useState<string>(() =>
      viewType === PcListViewType.Admin
        ? `${OrderStatus.ALL}`
        : `${OrderStatus.PENDING_IN_PROGRESS}`
    );
    const { id } = useRecoilValue(userState);
    const userInfo = useRecoilValue(userState);
    const [org, setOrg] = useRecoilState(systemUserOrgs);
    const [paginationInfo, setPagination] = React.useState({
      pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
      pageCount: OrderListPcModel.DEFAULT_PAGE_NUMBER,
    });
    const [pageLoad, setLoad] = React.useState<boolean>(false);
    const [resetModalVisible, setResetModalVisible] =
      React.useState<boolean>(false);
    const {
      confirmVisible,
      loading,
      logoutLabel,
      cancel,
      confirm,
      setLoading,
      setError,
      toastError,
      confirmCloseHandler,
      clickHandler,
    } = useLogout();

    const showEmpty = !loading && pageLoad && !list.length;
    const locale = useRecoilValue(localeState);
    const resetTicketRef = React.useRef<ITableItem>();
    const _labelReset = React.useMemo(
      () =>
        orderStatus === `${OrderStatus.CANCEL}` ? labelRecover : labelReset,
      [labelRecover, labelReset, orderStatus]
    );

    const resetStep = useResetRecoilState(stepState);
    const resetInstallationTaskId = useResetRecoilState(orderTaskIdState);
    const resetParentInstallationTaskId = useResetRecoilState(
      orderParentTaskIdState
    );
    const resetTab = useResetRecoilState(orderRequestTabs);
    const navigate = useNavigate();
    useTitle(viewType === PcListViewType.Admin ? adminTitle : title);

    React.useEffect(() => {
      resetStep();
      resetInstallationTaskId();
      resetParentInstallationTaskId();
      resetTab();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      OrderRequestModel.getSaleOrg(locale)
        .then((res) => {
          if (isEqual(res?.data, org)) return;
          setOrg(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
      if (!org?.regionProductLine?.length) return;
      _getList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org]);

    const getList = React.useCallback(
      (params?: IOrderListRequest) => {
        if (!isObject(org) || !userInfo.userId) return;
        const { pageIndex, pageCount } = paginationInfo;
        const keywords = searchValue?.trim();
        const _params = {
          status: orderStatus,
          pageIndex,
          pageCount,
          userId: id,
          keywords,
          ...(params as IOrderListRequest),
        };
        setLoading(true);
        OrderListPcModel.getListPc(
          _params as IOrderListRequest,
          org,
          userInfo,
          locale
        )
          .then((res) => {
            const { list, total } = res.data ?? {};
            setList(list);
            setTotal(total);
            setLoad(true);
          })
          .catch((res) => {
            setError(res?.errorCode);
            toastError();
          })
          .finally(() => setLoading(false));
      },
      [
        org,
        userInfo,
        paginationInfo,
        orderStatus,
        id,
        searchValue,
        locale,
        setLoading,
        setError,
        toastError,
      ]
    );

    const getAdminList = React.useCallback(
      (params?: IOrderListRequest, ignoreLoading?: boolean) => {
        if (!isObject(org) || !userInfo.userId) return;
        const { pageIndex, pageCount } = paginationInfo;
        const keywords = searchValue?.trim();
        const _params = {
          status: orderStatus,
          pageIndex,
          pageCount,
          userId: id,
          keywords,
          ...(params as IOrderListRequest),
        };
        !ignoreLoading && setLoading(true);
        OrderListPcModel.getAdminListPc(
          _params as IOrderListRequest,
          org,
          userInfo,
          locale
        )
          .then((res) => {
            const { list, total } = res.data ?? {};
            setList(list);
            setTotal(total);
            setLoad(true);
          })
          .catch((res) => {
            setError(res?.errorCode);
            toastError();
          })
          .finally(() => !ignoreLoading && setLoading(false));
      },
      [
        org,
        userInfo,
        paginationInfo,
        orderStatus,
        id,
        searchValue,
        locale,
        setLoading,
        setError,
        toastError,
      ]
    );

    const resetTicket = React.useCallback(() => {
      const { id: installationTaskId, isMain } =
        (resetTicketRef.current as ITableItem) ?? {};
      const actionMap = new Map([
        [`${OrderStatus.ALL}`, AdminResetType.Reset],
        [`${OrderStatus.CANCEL}`, AdminResetType.Restore],
      ]);
      const actionType = actionMap.get(orderStatus);

      return OrderListModel.resetTicket({
        installationTaskId,
        isMain,
        userId: id as string,
        actionType: actionType as AdminResetType,
      }).catch((res) => {
        setError(res?.errorCode);
        toastError();
      });
    }, [id, orderStatus, setError, toastError]);

    const _getList = React.useCallback(
      (params?: IOrderListRequest) => {
        if (viewType === PcListViewType.Admin) {
          getAdminList(params);
        } else {
          getList(params);
        }
      },
      [getAdminList, getList, viewType]
    );

    const changeHandler = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt?.currentTarget ?? {};
        setSearch(value);
      },
      []
    );

    const searchHandler = React.useCallback(() => {
      const keywords = searchValue?.trim();
      _getList({ keywords, pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX });
      setPagination((pre) => ({
        ...pre,
        pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
      }));
    }, [_getList, searchValue]);

    const statusChangeHandler = React.useCallback(
      (status: string) => {
        setOrderStatus(status);
        _getList({
          status: +status as OrderStatus,
          pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
        });
        setPagination((pre) => ({
          ...pre,
          pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
        }));
      },
      [_getList]
    );

    const pageChangeHandler = React.useCallback(
      (pageIndex: number, pageCount: number) => {
        setPagination((pre) => ({ ...pre, pageIndex, pageCount }));
        _getList({ pageIndex, pageCount });
      },
      [_getList]
    );

    const clearSearchHandler = React.useCallback(() => {
      _getList({
        keywords: "",
        pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
      });
      setPagination((pre) => ({
        ...pre,
        pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
      }));
    }, [_getList]);

    const viewHandler = React.useCallback(
      (data: ITableItem, evt?: React.MouseEvent<HTMLSpanElement>) => {
        const { parentInstallationTaskId, id, isUnSplitOrder } = data;

        const queryKey = isUnSplitOrder
            ? HomeModel.QUERY_PARENT_INSTALLATION_TASK_ID
            : HomeModel.QUERY_INSTALLATION_TASK_ID,
          _id = isUnSplitOrder ? parentInstallationTaskId : id;

        const combinedSearch = `${queryKey}=${_id}`;

        navigate({
          pathname: PagePaths.PATH_ORDER_REQUEST,
          search: combinedSearch,
        });

        evt?.stopPropagation();
      },
      [navigate]
    );

    const resetHandler = React.useCallback((data: ITableItem) => {
      resetTicketRef.current = data;
      setResetModalVisible(true);
    }, []);

    const actionHandler = React.useCallback(
      (
        key: string,
        data: ITableItem,
        evt?: React.MouseEvent<HTMLSpanElement>
      ) => {
        const actionMap = new Map([
          [TableActions.VIEW, viewHandler.bind(null, data, evt)],
          [TableActions.RESET, resetHandler.bind(null, data)],
        ]);

        const handler = actionMap.get(key as TableActions);
        isFunction(handler) && handler();
      },
      [resetHandler, viewHandler]
    );

    const resetConfirmHandler = React.useCallback(
      (closeType: CloseType) => {
        setResetModalVisible(false);
        if (closeType === "cancel") return;
        const pagination = {
          ...paginationInfo,
          pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
        };
        setPagination(pagination);
        setLoading(true);
        resetTicket()
          .then(() =>
            getAdminList(
              {
                pageIndex: OrderListPcModel.DEFAULT_PAGE_INDEX,
              },
              true
            )
          )
          .finally(() => setLoading(false));
      },
      [getAdminList, paginationInfo, resetTicket, setLoading]
    );

    const searchView = React.useMemo(() => {
      const fill = getCssVariable("--color-interaction-grey-hover");
      const icon = searchValue ? (
        <span onClick={clearSearchHandler}>
          <Icon icon={"StatusCanceled"} size={12} fill={fill} />
        </span>
      ) : (
        <></>
      );

      const _logout = classNames(styles.logout, {
        [styles.logoutProd]: SystemService.getEnv() === SystemEnv.ENV_PROD,
      });

      return (
        <div className={styles.searchView}>
          <div className={_logout} onClick={clickHandler} />
          <SearchPc
            containerClassName={styles.search}
            placeholder={searchPhd}
            value={searchValue}
            allowClear={{
              clearIcon: icon,
            }}
            autoFocus
            onChange={changeHandler}
          />
          <Button size="s" onClick={searchHandler}>
            <div className={styles.searchBtn}>
              <Icon icon={"Search"} size={12} className={styles.searchIcon} />
              <span className={styles.searchText}>{labelSearch}</span>
            </div>
          </Button>
        </div>
      );
    }, [
      searchValue,
      searchPhd,
      labelSearch,
      clearSearchHandler,
      clickHandler,
      changeHandler,
      searchHandler,
    ]);

    const tabView = React.useMemo(() => {
      const statusList = OrderListPcModel.getOrderStatusList(viewType);

      return (
        <Tabs
          destroyInactiveTabPane={true}
          items={statusList}
          activeKey={orderStatus}
          onChange={statusChangeHandler}
          className={styles.tabs}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus, locale, statusChangeHandler]);

    const emptyView = React.useMemo(() => {
      return (
        <div className={styles.emptyView}>
          <Empty />
          <div className={styles.emptyText}>{labelEmpty}</div>
        </div>
      );
    }, [labelEmpty]);

    const generateActionView = React.useCallback(
      (item: ITableItem) => {
        const showReset = viewType === PcListViewType.Admin;

        return (
          <div className={styles.subActionView}>
            <span
              className={styles.subAction}
              onClick={(evt) => actionHandler(TableActions.VIEW, item, evt)}
            >
              {labelView}
            </span>
            {showReset && (
              <span
                className={styles.subAction}
                onClick={actionHandler.bind(null, TableActions.RESET, item)}
              >
                {_labelReset}
              </span>
            )}
          </div>
        );
      },
      [_labelReset, actionHandler, labelView, viewType]
    );

    const convertTableData = React.useCallback(
      (list: OrderTableItem[]) => {
        return list?.map((item) => {
          const {
            subsStandardOrder,
            isUnSplitOrder,
            isFinalGoodsRecipNew,
            fullFinalGoodsRecipName,
          } = item;
          const unSplitOrderView = (
            <div
              className={styles.unSplitOrderSubOrder}
              key={subsStandardOrder}
            >
              {subsStandardOrder}
              <Tooltip
                type={TooltipType.failed}
                overlay={labelUnSplitReminder}
              />
            </div>
          );
          const _subsStandardOrder = isUnSplitOrder
            ? unSplitOrderView
            : subsStandardOrder;
          const _fullFinalGoodsRecipName = isFinalGoodsRecipNew ? (
            <>
              {fullFinalGoodsRecipName} <IndicatorNew />
            </>
          ) : (
            fullFinalGoodsRecipName
          );

          return {
            ...item,
            subsStandardOrder: _subsStandardOrder,
            fullFinalGoodsRecipName: _fullFinalGoodsRecipName,
            operation: generateActionView(item),
          };
        });
      },
      [generateActionView, labelUnSplitReminder]
    );

    const tableView = React.useMemo(() => {
      const columns = OrderListPcModel.getTableColumns();
      const showSizeChanger = total > OrderListPcModel.DEFAULT_PAGE_NUMBER;
      const pageSizeOptions = OrderListPcModel.calcListPageSize(total);
      const { pageCount, pageIndex } = paginationInfo;
      const _list = convertTableData(list);

      return (
        <div className={styles.tableView}>
          <div className={styles.table}>
            <Table
              columns={columns}
              data={_list}
              onClick={(item) =>
                viewType !== PcListViewType.Admin && viewHandler(item)
              }
            />
            {showEmpty && emptyView}
          </div>
          <div className={styles.pagination}>
            <Pagination
              total={total}
              current={pageIndex}
              pageSize={pageCount}
              showSizeChanger={showSizeChanger}
              pageSizeOptions={pageSizeOptions}
              showLessItems={true}
              totalLabel={totalLabel}
              pageChangeLabel={pageLabel}
              onChange={pageChangeHandler}
            />
          </div>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      labelView,
      total,
      paginationInfo,
      list,
      locale,
      convertTableData,
      actionHandler,
      viewHandler,
      pageChangeHandler,
    ]);

    const logoutView = React.useMemo(
      () => (
        <ConfirmVariantWithIcon
          visible={confirmVisible}
          type={ConfirmIconType.WARNING}
          title={logoutLabel}
          onConfirm={confirmCloseHandler}
          onClose={confirmCloseHandler.bind(null, "cancel")}
          buttonTexts={[
            {
              type: "secondary",
              label: cancel,
              closeType: "cancel",
            },
            {
              type: "primary",
              label: confirm,
              closeType: "ok",
            },
          ]}
        />
      ),
      [cancel, confirm, confirmVisible, logoutLabel, confirmCloseHandler]
    );

    const resetView = React.useMemo(
      () => (
        <ConfirmVariantWithIcon
          visible={resetModalVisible}
          type={ConfirmIconType.FAIL}
          title={reminderRest}
          onConfirm={resetConfirmHandler}
          onClose={confirmCloseHandler.bind(null, "cancel")}
          buttonTexts={[
            {
              type: "secondary",
              label: cancel,
              closeType: "cancel",
            },
            {
              type: "primary",
              label: confirm,
              closeType: "ok",
            },
          ]}
        />
      ),
      [
        resetModalVisible,
        reminderRest,
        resetConfirmHandler,
        confirmCloseHandler,
        cancel,
        confirm,
      ]
    );

    return (
      <section className={styles.orderListPc}>
        {searchView}
        <div className={styles.listView}>
          {tabView}
          {tableView}
        </div>
        {logoutView}
        {resetView}
      </section>
    );
  }
);

OrderListPc.defaultProps = {
  viewType: PcListViewType.Basic,
};

export default OrderListPc;
