import { cloneDeep } from "lodash";
import HttpService, { Response } from "../../../../core/HttpService";
import {
  Action,
  Feature,
  IAssignOrForwardUserReq,
  ISearUserReq,
  ISearchUserRes,
  assignOrForwardUser,
  getSearchUser,
} from "../../../../services/user.service";
import { SearchOption } from "../../../components/SearchSelector/useSearchSelector";
import { Role } from "../../../service/userRoleTypes";

export default class AssignOrForwardUserModel {
  static getSaleForwardUsers = (
    role: Role,
    params: ISearUserReq
  ): Promise<Response<SearchOption[]>> => {
    return HttpService.splitResponse(getSearchUser(role, params)).then(
      (res) => ({
        ...res,
        data: AssignOrForwardUserModel.formatUserList(res?.data),
      })
    );
  };

  static assignOrForwardUser = (
    role: Role,
    action: Action,
    feature: Feature,
    data: IAssignOrForwardUserReq
  ): Promise<Response<{}>> => {
    return HttpService.splitResponse(
      assignOrForwardUser(role, action, feature, data)
    );
  };

  private static formatUserList = (list: ISearchUserRes[]): SearchOption[] => {
    if (!Array.isArray(list)) return [];
    const _list = cloneDeep(list);
    _list.sort((pre, next) => pre?.nameCN?.localeCompare(next.nameCN, "zh"));
    return _list
      .map(({ nameCN, nameEN, email: subText, rbacCorrelationId: value }) => ({
        text: `${nameCN} (${nameEN})`,
        subText,
        value,
      }))
      .filter(({ text, value }) => text && value);
  };
}
