import { TextArea } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import { ErrorDetectorMediumView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { SystemPlatform } from "../../../../core/SystemService";
import { IOrderComments } from "../../../../services/order.service";
import {
  localeState,
  orderStatus,
  orderTabState,
  orderTaskIdState,
  platformState,
  stepState,
  userState,
} from "../../../../store";
import { isFunction } from "../../../../utils/isType";
import { OrderStatus } from "../../../OrderList/OrderListModel";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { OrderStep, OrderStepStatus } from "../../../service/OrderStepTypes";
import { PageStatus } from "../../../service/pageType";
import OrderRequestModel from "../../model/OrderRequestModel";
import StepVariantComments from "../StepVariantComments/StepVariantComments";
import { IStepItemProcess } from "../StepVariantProcess/StepVariantProcess";
import styles from "./CommentsView.module.scss";

interface ICommentsView {
  onChange?: (value: string) => void;
}

const CommentsView: React.FC<ICommentsView> = ErrorDetectorMediumView(
  (props: ICommentsView) => {
    const [steps, setSteps] = React.useState<IStepItemProcess[]>([]);
    const _orderStatus = useRecoilValue(orderStatus);
    const [errCode, setError] = React.useState<ErrorCode>();
    const { key } = useRecoilValue(stepState);
    const { onChange } = props;
    const taskId = useRecoilValue(orderTaskIdState);
    const orderTab = useRecoilValue(orderTabState);
    const { pageStatus } = useRolePage(orderTab);
    const { id } = useRecoilValue(userState);
    const showTextArea = pageStatus === PageStatus.EDIT;
    const showPendingStep = [OrderStatus.PENDING].includes(_orderStatus);
    const hiddenCommentView = key === OrderStep.SPLIT_ORDER;
    const platform = useRecoilValue(platformState);
    const locale = useRecoilValue(localeState);
    const { commentLabel } = useLang({
      commentLabel: { id: "common_comments" },
    });
    const [comments, setComments] = React.useState<string>("");
    const toastError = useErrorMsg(errCode as ErrorCode);
    const _commentsContainer = classNames(styles.commentsContainer, {
      [styles.commentsContainerPc]: platform === SystemPlatform.ENV_PC,
    });

    const insertComment = React.useCallback(() => {
      const formatValue = comments?.trim();
      if (!formatValue) return;
      const data = {
        comments: formatValue,
        installationTaskId: taskId,
        time: Date.now(),
        status: OrderStepStatus.RESOLVE,
        author: id as string,
        key,
      };
      OrderRequestModel.insertComments(data as unknown as IOrderComments).catch(
        (res) => {
          setError(res?.errorCode);
          toastError();
        }
      );
    }, [comments, id, key, taskId, toastError]);

    React.useEffect(() => {
      const emitter = EventService.getInstance();
      const unbind = emitter.on(
        EventService.EVENT_SUBMIT_STEP_COMMENTS,
        insertComment
      );

      return unbind;
    }, [comments, taskId, key, id, insertComment]);

    React.useEffect(() => {
      if (!taskId) return;
      OrderRequestModel.getOrderComments(taskId, locale)
        .then(({ data }) => {
          const steps = showPendingStep
            ? [
                ...data,
                {
                  key,
                  label: OrderRequestModel.formatStepLabel(key),
                  status: OrderStepStatus.PENDING,
                  icon: OrderRequestModel.formatStepIcon(
                    OrderStepStatus.PENDING
                  ),
                },
              ]
            : data;

          setSteps(steps);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, key, showPendingStep, locale]);

    const changeHandler = React.useCallback(
      (evt: any) => {
        const { value } = evt?.target ?? {};
        setComments(value);
        isFunction(onChange) && onChange(value);
      },
      [onChange]
    );

    return (
      !hiddenCommentView &&
      !!steps?.length && (
        <div className={_commentsContainer}>
          <StepVariantComments
            toggleCollapse={true}
            minDisplayStep={3}
            steps={steps}
          />
          {showTextArea && showPendingStep && (
            <div className={styles.textarea}>
              <TextArea
                label={commentLabel}
                onChange={changeHandler}
                value={comments}
                maxLength={200}
                rows={4}
              />
            </div>
          )}
        </div>
      )
    );
  }
);

export default CommentsView;
