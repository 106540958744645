import * as React from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Toast from "../../../components/Toast";
import { errorPageState, userState } from "../../../store";
import { PagePaths } from "../../service/pageType";
import HomeMobileModel from "./HomeMobileModel";

// check url include order Id
// 1. include
//    1.1 get order info
//    1.2 calculate the page structure and cache in global store
// 2. not include
//    2.1 move to order list

const HomeMobile: React.FC = () => {
  const navigate = useNavigate();
  const errorPage = useRecoilValue(errorPageState);
  const { usersRole } = useRecoilValue(userState);

  React.useEffect(() => {
    Toast.config({ duration: 2000, position: "center", maskClickable: true });
    const path = HomeMobileModel.getPagePath(usersRole);
    const _path = errorPage ? PagePaths.PATH_ERROR_PAGE : path;
    const { search } = new URL(window.location.href);
    navigate(
      {
        pathname: _path,
        search,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPage]);

  // to do: Will use suspense component to load lazy view
  return (
    <>
      <Outlet />
    </>
  );
};

export default HomeMobile;
