import React from "react";
import useDebounceEffect from "../../../hooks/useDebounceEffect";
import { mergeProps } from "../../utils/mergeDefaultProps";
import { NativeProps, withNativeProps } from "../../utils/mergeNativeProps";
import { PickerProps } from "../Picker";
import { defaultRenderLabel } from "../pickerUtil";
import styles from "./PickerView.module.scss";
import { Wheel } from "./Wheel";
import { useColumnsExtend } from "./columnsExtend";

export type PickerValue = string | null;

export type PickerValueExtend = {
  columns: PickerColumnItem[][];
  items: (PickerColumnItem | null)[];
};

export type PickerColumnItem = {
  label: React.ReactNode;
  value: string;
  key?: string | number;
};

export type PickerColumn = (string | PickerColumnItem)[];

export type PickerViewProps = {
  columns: PickerColumn[] | ((value: PickerValue[]) => PickerColumn[]);
  value?: PickerValue[];
  defaultValue?: PickerValue[];
  mouseWheel?: boolean;
  loading?: boolean;
  loadingContent?: React.ReactNode;
  onChange?: (value: PickerValue[], extend: PickerValueExtend) => void;
} & Pick<PickerProps, "renderLabel"> &
  NativeProps<"--height" | "--item-height" | "--item-font-size">;

const defaultProps = {
  defaultValue: [],
  renderLabel: defaultRenderLabel,
  mouseWheel: false,
  loadingContent: "Loading",
};

export const PickerView = React.memo<PickerViewProps>((p) => {
  const props = mergeProps(defaultProps, p);

  const [innerValue, setInnerValue] = React.useState<PickerValue[]>(
    props.value === undefined ? props.defaultValue : props.value
  );

  // Sync `value` to `innerValue`
  React.useEffect(() => {
    if (props.value === undefined) return; // Uncontrolled mode
    if (props.value === innerValue) return;
    setInnerValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (props.value === innerValue) return;
    const timeout = window.setTimeout(() => {
      if (props.value !== undefined && props.value !== innerValue) {
        setInnerValue(props.value);
      }
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [props.value, innerValue]);

  const extend = useColumnsExtend(props.columns, innerValue);
  const columns = extend.columns;

  useDebounceEffect(
    () => {
      if (props.value === innerValue) return;
      props.onChange?.(innerValue, extend);
    },
    [innerValue],
    {
      wait: 0,
      leading: false,
      trailing: true,
    }
  );

  const handleSelect = React.useCallback((val: PickerValue, index: number) => {
    setInnerValue((prev) => {
      const next = [...prev];
      next[index] = val;
      return next;
    });
  }, []);

  return withNativeProps(
    props,
    <div className={styles.pickerView}>
      {props.loading ? (
        props.loadingContent
      ) : (
        <>
          {columns.map((column: any, index: number) => (
            <Wheel
              key={index}
              index={index}
              column={column}
              value={innerValue[index]}
              onSelect={handleSelect}
              renderLabel={props.renderLabel}
              mouseWheel={props.mouseWheel}
            />
          ))}
          <div className={styles["pickerView-mask"]}>
            <div className={styles["pickerView-mask-top"]} />
            <div className={styles["pickerView-mask-middle"]} />
            <div className={styles["pickerView-mask-bottom"]} />
          </div>
        </>
      )}
    </div>
  );
});
