import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import PhoneCall from "../../../../components/PhoneCall/PhoneCall";
import { formatDate } from "../../../../utils/commonUtils";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import Text from "../../../components/Text/Text";
import UploadVariant from "../../../components/UploadVariant/UploadVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import CommentsView from "../../components/CommentView/CommentsView";
import OrderRequestModel from "../../model/OrderRequestModel";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import styles from "./SiteSurveyAll.module.scss";
import SiteSurveyAllModel from "./SiteSurveyAllModel";
import useSiteSurveyAll from "./useSiteSurveyAll";

const SiteSurveyAllMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    preInstallInfo,
    orgs,
    expectedDate,
    labelActualSiteSurveyDate,
    labelSiteSurveyReport,
    siteSurveyDone,
    locale,
    backToAllTicket,
  } = useSiteSurveyAll();

  const contactView = React.useMemo(() => {
    const list = SiteSurveyAllModel.getContactInfoModel(preInstallInfo);

    return (
      <div className={styles.contactView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.MOBILE_PHONE,
              {
                ...props,
                children: <PhoneCall phoneNumber={props?.children} />,
              },
            ],
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;

          const component = (
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...newProps,
                key,
              }}
            />
          );

          return component;
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preInstallInfo, locale]);

  const siteSurveyView = React.useMemo(() => {
    const list = SiteSurveyAllModel.getOrgInfoModel(
      preInstallInfo?.partnerTeamOtherInfo,
      orgs
    );
    const { expectedStartDate, actuarySiteSurveyDate, isSiteSurveyDone } =
      preInstallInfo ?? {};
    const expectedStr = formatDate(expectedStartDate),
      actualStr = formatDate(actuarySiteSurveyDate),
      siteSurveyDoneStr = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            key,
          }}
        />
      );
      return component;
    });

    return (
      <div className={styles.siteSurveyView}>
        {orgView}
        <div>
          {expectedStr && <Text label={expectedDate}>{expectedStr}</Text>}
          {actualStr && (
            <Text label={labelActualSiteSurveyDate}>{actualStr}</Text>
          )}
          {siteSurveyDoneStr && (
            <Text label={siteSurveyDone}>{siteSurveyDoneStr}</Text>
          )}
          <SiteSurveyAccessory
            accessories={(preInstallInfo?.thirdParty as any) ?? {}}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preInstallInfo,
    orgs,
    expectedDate,
    labelActualSiteSurveyDate,
    siteSurveyDone,
    locale,
  ]);

  const siteSurveyReportView = React.useMemo(() => {
    const show = !!preInstallInfo?.siteSurveyReports?.length;
    return (
      show && (
        <div className={styles.siteSurveyReport}>
          <UploadVariant
            variantClassName={styles.uploadCls}
            title={labelSiteSurveyReport}
            reminder={""}
            maxCount={5}
            value={preInstallInfo?.siteSurveyReports}
            deletable={false}
            disableUpload
          />
        </div>
      )
    );
  }, [labelSiteSurveyReport, preInstallInfo?.siteSurveyReports]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyAllModel.getButtonViewModel();
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div className={styles.siteSurveyAll}>
      <div className={styles.pageContainer}>
        {contactView}
        {siteSurveyView}
        {siteSurveyReportView}
        <CommentsView />
      </div>
      {buttonViews}
    </div>
  );
});

export default SiteSurveyAllMobile;
