import { Button } from "@zeiss/ods-components-react";
import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import TextVariantPc from "../../../components/TextVariantPc/TextVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationSo.module.scss";
import InstallationSoModel from "./InstallationSoModel";
import useInstallationSo from "./useInstallationSo";

const InstallationSoPc: React.FC = ErrorDetectorPageView(() => {
  const {
    installationSoInfo,
    rejectVisible,
    approveVisible,
    rejectTitle,
    saleRegions,
    rolePage,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    reminder,
    platform,
    locale,
    setReject,
    setApprove,
    rejectHandler,
    confirmHandler,
    backAllTickHandler,
    changeHandler,
  } = useInstallationSo();

  const orderInfoView = React.useMemo(() => {
    const list = InstallationSoModel.getInfoViewModel(
      installationSoInfo,
      saleRegions,
      platform
    );

    const view = list.map(({ key, viewType, props }) => {
      const _props = { ...props, key };
      return (
        <div className={styles.viewItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.orderInfoPcView}>{view}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationSoInfo, platform, saleRegions, locale]);

  const orderContactView = React.useMemo(() => {
    const list = InstallationSoModel.getContactViewModel(
      installationSoInfo,
      platform
    );
    const _list = list.filter(
      ({ key }) => key !== InstallationModel.WARRANTY_PERIOD
    );
    const warrantyPeriodView = list.find(
      ({ key }) => key === InstallationModel.WARRANTY_PERIOD
    );
    const view = _list.map(({ key, viewType, props }) => {
      const propsMap = new Map([
        [
          InstallationModel.WARRANTY_PERIOD,
          { ...props, key, className: styles.warrantyPeriod },
        ],
        [
          InstallationModel.FINAL_GOODS_RECEIPT_NAME,
          { ...props, key, indicatorCustom: <IndicatorNew /> },
        ],
      ]);

      const _props = propsMap.get(key as string) || { ...props, key };

      return (
        <div className={styles.viewItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    const { warrantyPeriod, otherWarrantyPeriod } = installationSoInfo;
    const reminder = InstallationModel.getReminderByWarranty(
      warrantyPeriod as WarrantyPeriod
    );
    const reminderLabel = reminder ? reminder : otherWarrantyPeriod;
    return (
      <div className={styles.orderContactPcView}>
        {view}
        <TextVariantPc label={warrantyPeriodView?.props?.label}>
          {warrantyPeriodView?.props?.children}
        </TextVariantPc>
        {reminderLabel && (
          <div className={styles.warrantyPeriodReminder}>{reminderLabel}</div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationSoInfo, platform, locale]);

  const uploadFileView = React.useMemo(() => {
    const list = InstallationSoModel.getFileInfoViewModel(
      installationSoInfo as IInstallation,
      rolePage.pageStatus,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.uploadFilePcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationSoInfo,
    rolePage.pageStatus,
    platform,
    locale,
    changeHandler,
  ]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationSoModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SoAction.REJECT, { onClick: () => setReject(true) }],
      [SoAction.APPROVE, { onClick: () => setApprove(true) }],
    ]);

    const views = list.map(({ viewType, props, key }) => {
      const disabled = key === SoAction.APPROVE ? !submitEnable : false;
      return (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled,
          }}
        />
      );
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setReject,
    setApprove,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.installationInfoSoPc}>
      <div className={styles.pageContentPc}>
        {orderInfoView}
        {orderContactView}
        <OrderDnInfoView {...(installationSoInfo.dnInfo as IDNInfo)} />
        {uploadFileView}
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          onConfirm={rejectHandler}
        />
      )}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={approveVisible}
        title={reminder}
        onConfirm={confirmHandler}
      />
    </div>
  );
});

export default InstallationSoPc;
