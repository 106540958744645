import {
  DefaultEvents,
  Emitter,
  Unsubscribe,
  createNanoEvents,
} from "nanoevents";

export default class EventService {
  static readonly EVENT_SUBMIT_STEP_COMMENTS = "submitStepComets";

  private emitter: Emitter<DefaultEvents>;
  private static instance: EventService;
  private constructor() {
    this.emitter = createNanoEvents();
  }

  static getInstance = () => {
    return (
      EventService.instance || (EventService.instance = new EventService())
    );
  };

  emit = (eventName: string, ...args: any[]): void =>
    EventService.getInstance().emitter.emit(eventName, ...args);

  on = (eventName: string, callback: (...args: any[]) => void): Unsubscribe =>
    EventService.getInstance().emitter.on(eventName, callback);
}
