import { IntlShape, createIntl, createIntlCache } from "react-intl";
import enUS from "../locale/en-US";
import zhCN from "../locale/zh-CN";
import LocaleService, { LocaleLang } from "./LocaleService";

export default class LangService {
  private static instance: LangService | null = null;
  private cache = createIntlCache();
  private intl: IntlShape | undefined;

  private constructor() {
    const lang = LocaleService.getDefaultLocale()?.lang;
    this._setLang(lang);
  }

  static getInstance = () => {
    return LangService.instance || (LangService.instance = new LangService());
  };

  setLang = (language: LocaleLang) => {
    const { lang } = LocaleService.getLocale(language) ?? {};
    if (!lang) return;
    this._setLang(lang);
  };

  getIntl = () => {
    return this.intl;
  };

  private _setLang = (lang: LocaleLang) => {
    const messages = loadLocaleLangData(lang);
    this.intl = createIntl(
      {
        locale: lang,
        messages,
      },
      this.cache
    );
  };
}

const loadLocaleLangData = (locale: LocaleLang): Record<string, string> => {
  const langSourceMap = new Map([
    [LocaleLang.EN_US, enUS],
    [LocaleLang.ZH_CN, zhCN],
  ]);
  const { lang } = LocaleService.getDefaultLocale();
  return langSourceMap.get(locale ?? lang) as Record<string, string>;
};

export function formatMessage(
  id: string,
  values?: Record<string, any>,
  opts?: any
): string {
  const descriptor = {
    id,
    defaultMessage: id,
  };

  return LangService.getInstance()
    .getIntl()
    ?.formatMessage(descriptor, values, opts) as string;
}
