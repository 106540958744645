import classNames from "classnames";
import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { SystemPlatform } from "../../../../core/SystemService";
import FoldCabinet from "../../../components/FoldCabinet/FoldCabinet";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import CommentsView from "../../components/CommentView/CommentsView";
import FacIBaseModel, { IBaseInfo, IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseAll.module.scss";
import useFacIBaseAll from "./useFacIBaseAll";

const FacIBaseAllMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    labelDateReminder,
    visible,
    rolePage,
    locale,
    platform,
    setVisible,
    backAllTickHandler,
  } = useFacIBaseAll();

  const renderDateView = React.useCallback((ibaseInfo: IBaseInfo) => {
    const list = FacIBaseModel.getCRMDateInfoViewModel(ibaseInfo);

    const view = list.map(({ key, viewType, props }) => {
      if (key === FacIBaseModel.IBASE_NUMBER) {
        const _label = props?.children
          ? `${props?.label} (${props?.children})`
          : props?.label;
        return (
          <div className={styles.ibaseTitle} key={key}>
            {_label}
          </div>
        );
      }
      return (
        <ViewComponent type={viewType as ViewType} props={props} key={key} />
      );
    });

    return <div key={ibaseInfo.iBaseNumber}>{view}</div>;
  }, []);

  const infoView = React.useMemo(() => {
    const { ibaseInfos, ownerInstallationFseName } = facIBaseInfo;
    if (!Array.isArray(ibaseInfos) || !ibaseInfos.length) return null;
    const [ibase, ...rest] = ibaseInfos;
    const { labelOwnerInstallationFse } = FacIBaseModel.getLabels();
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const ownerFseClassName = classNames({
      [styles.ownerFse]: platform === SystemPlatform.ENV_MOBILE,
    });
    return (
      <div className={styles.facDateView}>
        {renderDateView(ibase)}
        {!!rest?.length && (
          <div className={styles.subDateView}>
            <FoldCabinet
              status={visible}
              onChange={(visible) => setVisible(visible)}
              cabinetClassName={styles.cabinetClassName}
            >
              <div className={styles.moreDetailContainer}>
                {rest?.map((item) => (
                  <div key={item.iBaseNumber} className={styles.detailItem}>
                    {renderDateView(item)}
                  </div>
                ))}
              </div>
            </FoldCabinet>
          </div>
        )}
        {ownerInstallationFseName && (
          <div className={ownerFseClassName}>
            <ViewComponent
              type={viewType}
              props={{
                label: labelOwnerInstallationFse,
                children: ownerInstallationFseName,
              }}
            />
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facIBaseInfo,
    labelDateReminder,
    visible,
    locale,
    renderDateView,
    setVisible,
  ]);

  const facDateView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoReadonlyViewModel(
      facIBaseInfo as Partial<IFacIBase>
    );
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installDateView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, locale]);

  const facFileUploadView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoReadonlyViewModel(
      facIBaseInfo as IFacIBase
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, locale]);

  const commentsView = React.useMemo(() => {
    return <CommentsView />;
  }, []);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getSoButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale, backAllTickHandler]);

  return (
    <div className={styles.facIbaseAll}>
      <div className={styles.pageContainer}>
        {infoView}
        {facDateView}
        {facFileUploadView}
        {commentsView}
      </div>
      {buttonsView}
    </div>
  );
});

export default FacIBaseAllMobile;
