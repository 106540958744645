import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import HttpService, { NormalRes } from "../../../../core/HttpService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  IFseSiteSurveyReject,
  IFseSiteSurveyReq,
  StaticSourceItem,
  fseRejectReason,
  fseSubmitSiteSurvey,
} from "../../../../services/order.service";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { FseAction } from "../../../service/userRoleTypes";
import InfoSaleModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  CustomerTypes,
  IPartnerTeamOtherInfo,
  IPrepareInstallation,
  SaleTeam,
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import SiteSurveyRsmModel from "../SiteSurveyRsm/SiteSurveyRsmModel";

export default class SiteSurveyFseModel {
  static fseRejectReason = (data: IFseSiteSurveyReject): Promise<NormalRes> => {
    return HttpService.splitResponse(fseRejectReason(data));
  };

  static fseSubmitSiteSurvey = (
    data: IFseSiteSurveyReq
  ): Promise<NormalRes> => {
    return HttpService.splitResponse(fseSubmitSiteSurvey(data));
  };

  static verifySubmission = (
    infos: Partial<IPrepareInstallation>,
    keys: (keyof IPrepareInstallation)[]
  ) => {
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static getOrgInfoModel = (
    infos: Partial<IPartnerTeamOtherInfo>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { saleTeam, region, customerType, salesEmployee } = infos ?? {};
    const { labelInfoSaleEmp, labelCustomerType, labelSaleTeam, labelRegin } =
      InfoSaleModel.getLabels();
    const customerTypeLabel = OrderRequestModel.getCustomerLabel(
        customerType as CustomerTypes
      ),
      saleTeamLabel = OrderRequestModel.getSaleTeamLabel(saleTeam as SaleTeam),
      serviceRegion = OrderRequestModel.getRegionLabel(
        orgOptions,
        region as number
      );
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    return [
      {
        key: InfoSaleModel.SALE_EMPLOYEE,
        viewType: _viewType,
        props: { label: labelInfoSaleEmp, children: salesEmployee },
      },
      {
        key: InfoSaleModel.CUSTOMER_TYPE,
        viewType: _viewType,
        props: { label: labelCustomerType, children: customerTypeLabel },
      },
      {
        key: InfoSaleModel.SALE_TEAM,
        viewType: _viewType,
        props: { label: labelSaleTeam, children: saleTeamLabel },
      },
      {
        key: InfoSaleModel.REGION,
        viewType: _viewType,
        props: { label: labelRegin, children: serviceRegion },
      },
    ];
  };

  static getContactInfoModel = (
    infos: IPrepareInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { name, mobile } = infos?.contactPerson ?? {};
    const [finalGoodsReceipt, finalGoodsReceiptNew] =
      infos?.finalsGoodsRecip ?? [];
    const recipient = finalGoodsReceiptNew?.name
      ? finalGoodsReceiptNew
      : finalGoodsReceipt ?? {};
    const indicator = finalGoodsReceiptNew?.name
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;
    const { name: receiptName, recipCity, recipAddress } = recipient;
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const {
      labelContactPerson,
      labelContactMobile,
      labelRecipeCity,
      labelRecipeAddress,
      labelFinalGoodRecipe,
    } = InfoSaleModel.getLabels();
    const customerKey1 = `${InfoSaleModel.INSTALLATION_TASK_ID}_1`;

    return [
      {
        key: SiteSurveyRsmModel.FINAL_GOODS_RECIP,
        viewType: _viewType,
        props: {
          label: labelFinalGoodRecipe,
          children: receiptName,
          indicator,
        },
      },
      {
        key: InfoSaleModel.RECIPE_CITY,
        viewType: _viewType,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSaleModel.RECIPE_ADDRESS,
        viewType: _viewType,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InfoSaleModel.CONTACT_NAME,
        viewType: _viewType,
        props: { label: labelContactPerson, children: name },
      },
      {
        key: InfoSaleModel.MOBILE_PHONE,
        viewType: _viewType,
        props: { label: labelContactMobile, children: mobile },
      },
    ];
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelSubmit, labelReject } =
      InfoSaleModel.getLabels();

    const original = [
      {
        key: FseAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: FseAction.ALL_TICKET,
        },
      },
      {
        key: FseAction.REJECT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelReject,
          key: FseAction.REJECT,
        },
      },
      {
        key: FseAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: FseAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
