import { AxiosPromise, AxiosResponse } from "axios";

export enum HttpStatus {
  OK = 200,
  NoContent = 204,
  Created = 201,
  UnAuthorized = 401,
  BadRequest = 400,
  SERVER_ERROR = 500,
}

export enum HttpRequestMethod {
  METHOD_GET = "GET",
  METHOD_POST = "POST",
}

export interface Response<T> {
  errorCode: number;
  data: T;
  msg?: string;
}

export interface ErrorRes extends Response<any> {}
export interface NormalRes extends Response<any> {}

export default class HttpService {
  static readonly splitResponse = (
    res: AxiosPromise<Response<any>>
  ): Promise<Response<any>> => {
    return res
      .then((res: AxiosResponse<Response<any>>) => {
        const { status, data } = res ?? {};
        const { errorCode } = data ?? {};
        if (status === HttpStatus.OK && errorCode === HttpStatus.OK)
          return res?.data;
        return Promise.reject(data);
      })
      .catch((res: AxiosResponse<Response<any>>) => {
        const { errorCode = HttpStatus.SERVER_ERROR, response } =
          (res as any) ?? {};
        const { status } = response ?? {};
        return Promise.reject({ errorCode: errorCode ?? status, data: null });
      });
  };
}
