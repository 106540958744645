import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { SystemPlatform } from "../../../../core/SystemService";
import FoldCabinet from "../../../components/FoldCabinet/FoldCabinet";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import CommentsView from "../../components/CommentView/CommentsView";
import FacIBaseModel, { IBaseInfo, IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseCRM.module.scss";
import useFacIBaseCRMUpdate from "./useFacIBaseCRMUpdate";

const FacIBaseCRMUpdateMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    labelDateReminder,
    visible,
    rolePage,
    labelCancel,
    buttonBlock,
    labelConfirm,
    confirmVisible,
    labelConfirmReminder,
    locale,
    platform,
    setVisible,
    setConfirm,
    backAllTickHandler,
    confirmCloseHandler,
  } = useFacIBaseCRMUpdate();

  const renderDateView = React.useCallback((ibaseInfo: IBaseInfo) => {
    const list = FacIBaseModel.getCRMDateInfoViewModel(ibaseInfo);

    const view = list.map(({ key, viewType, props }) => {
      const _props = { ...props, key };
      if (key === FacIBaseModel.IBASE_NUMBER) {
        const _label = props?.children
          ? `${props?.label} (${props?.children})`
          : props?.label;
        return (
          <div className={styles.ibaseTitle} key={key}>
            {_label}
          </div>
        );
      }
      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });

    return <div key={ibaseInfo.iBaseNumber}>{view}</div>;
  }, []);

  const InfoView = React.useMemo(() => {
    const { ibaseInfos, ownerInstallationFseName } = facIBaseInfo;
    if (!Array.isArray(ibaseInfos) || !ibaseInfos.length) return null;
    const { labelOwnerInstallationFse } = FacIBaseModel.getLabels();
    const [ibase, ...rest] = ibaseInfos;
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    return (
      <div className={styles.infoView}>
        <div className={styles.installDateView}>{renderDateView(ibase)}</div>
        {ownerInstallationFseName && (
          <div className={styles.installDateViewSpecial}>
            <ViewComponent
              type={viewType}
              props={{
                label: labelOwnerInstallationFse,
                children: ownerInstallationFseName,
              }}
            />
          </div>
        )}
        {!!rest?.length && (
          <div className={styles.installDateView}>
            <FoldCabinet
              status={visible}
              onChange={(visible) => setVisible(visible)}
              cabinetClassName={styles.cabinetClassName}
            >
              <div className={styles.moreDetailContainer}>
                <div className={styles.detailItem}>
                  {rest?.map((item) => renderDateView(item))}
                </div>
              </div>
            </FoldCabinet>
          </div>
        )}
        <div className={styles.installDateViewSpecial}>
          <TextReminder label={labelDateReminder} />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facIBaseInfo,
    labelDateReminder,
    visible,
    locale,
    setVisible,
    renderDateView,
  ]);

  const commentsView = React.useMemo(() => {
    return <CommentsView />;
  }, []);

  const facDateView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoReadonlyViewModel(
      facIBaseInfo as Partial<IFacIBase>
    );
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installDateView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, locale]);

  const facFileUploadView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoReadonlyViewModel(
      facIBaseInfo as IFacIBase
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, locale]);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getCRMConfirmButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SoAction.CONFIRM, { onClick: () => setConfirm(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );
      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale, backAllTickHandler, setConfirm]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmCloseHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmCloseHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmCloseHandler]
  );

  return (
    <div className={styles.facIbaseCrmUpdate}>
      <div className={styles.pageContainer}>
        {InfoView}
        {facDateView}
        {facFileUploadView}
        {commentsView}
      </div>
      {buttonsView}
      <Confirmor
        footer={confirmButtonView}
        visible={confirmVisible}
        content={labelConfirmReminder}
      />
    </div>
  );
});

export default FacIBaseCRMUpdateMobile;
