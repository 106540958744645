import React from "react";

interface IClose {
  size?: "auto" | "default";
  fill?: string;
}

export default function (props: IClose): React.ReactElement {
  const { size, fill } = props;
  const width = size === "auto" ? "100%" : "12",
    height = size === "auto" ? "100%" : "12";
  const _fill = fill || "#ffffff";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={_fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_113520_39586"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99617 7.41429L2.20328 11.2072L0.789063 9.79297L4.58196 6.00008L0.789063 2.20718L2.20328 0.792969L5.99617 4.58586L9.78906 0.792969L11.2033 2.20718L7.41038 6.00008L11.2033 9.79297L9.78906 11.2072L5.99617 7.41429Z"
          fill={_fill}
        />
      </mask>
      <g mask="url(#mask0_113520_39586)">
        <rect x="-6" y="-6" width="24" height="24" fill={_fill} />
      </g>
    </svg>
  );
}
