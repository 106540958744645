import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import Loader from "../components/Loader/Loader";
import AuthService from "../core/AuthService";
import { PageError } from "../core/ErrorCodeService";
import LocalStorageService from "../core/LocalStorageService";
import PermissionService from "../core/PermissionService";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import { errorPageState, loadState, userState } from "../store";
import { queryUrlByKey } from "../utils/commonUtils";

interface IAuthGuarderProps {
  children?: React.ReactNode;
}

// in local test, if your meet error about get token,
// you need to use the SSO_DEV_PATH to get code in browser, and replace the LOCAL_DEV_CODE to simulate the login flow.
// to do, logout api

const AuthGuarder: React.FC<IAuthGuarderProps> = ({ children }) => {
  const [userInfo, setUser] = useRecoilState(userState);
  const { current: apiToken } = React.useRef(
    LocalStorageService.getStorage(LocalStorageService.API_TOKEN)
  );
  const setErrorPage = useSetRecoilState(errorPageState);
  const [loading, setLoading] = useRecoilState(loadState);
  const showErrorPage = AuthService.isErrorRedirect();

  React.useEffect(() => {
    draftAuth();
    initUserState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const draftAuth = React.useCallback(() => {
    if (apiToken) return;
    const code = queryUrlByKey("code");
    if (code) return;
    const path = AuthService.getSSOPath();
    if (!path) {
      return;
    }
    window.location.replace(path);
  }, [apiToken]);

  const authErrorHandler = React.useCallback(() => {
    const reloadUrl = AuthService.getSSOPath();
    AuthService.storeErrorRedirect();
    const isErrorRedirect = AuthService.isErrorRedirect();
    LocalStorageService.removeStorage(LocalStorageService.API_TOKEN);

    if (!isErrorRedirect) {
      reloadUrl && window.location.replace(reloadUrl);
      return;
    }
    setErrorPage(PageError.ERROR_403);
  }, [setErrorPage]);

  const getUserInfo = React.useCallback(
    (token: string) => {
      setLoading(true);
      return AuthService.getUserInfo(token)
        .then((res) => {
          const { data } = res;
          const { usersRole, rbacCorrelationId } = data ?? {};
          if (!PermissionService.isValidUser(usersRole, rbacCorrelationId)) {
            authErrorHandler();
            return;
          }
          setUser({
            ...data,
            userId: data?.rbacCorrelationId as string,
          } as any);
          AuthService.resetRedirectCount();
          return Promise.resolve();
        })
        .catch(authErrorHandler)
        .finally(() => setLoading(false));
    },
    [authErrorHandler, setLoading, setUser]
  );

  const getSystemToken = React.useCallback(
    (code: string, redirectUrl: string) =>
      AuthService.getToken(code, redirectUrl).then((res) => {
        const { data } = res ?? {};
        LocalStorageService.setStorage(LocalStorageService.API_TOKEN, data);
      }),
    []
  );

  const initUserState = React.useCallback(() => {
    const code = queryUrlByKey("code");
    const redirectUrl = AuthService.getRedirectUrl();
    if (apiToken) {
      getUserInfo(apiToken);
      return;
    }

    if (!code) return;
    setLoading(true);
    AuthService.getCSRFToken()
      .then(() => getSystemToken(code, redirectUrl))
      .then(() => AuthService.getCSRFToken())
      .then(() => {
        const apiToken = LocalStorageService.getStorage(
          LocalStorageService.API_TOKEN
        );
        getUserInfo(apiToken);
      })
      .catch(authErrorHandler)
      .finally(() => setLoading(false));
  }, [apiToken, authErrorHandler, getSystemToken, getUserInfo, setLoading]);

  return (
    <Loader loading={loading} delay={350}>
      {userInfo?.id && children}
      {showErrorPage && <ErrorPage />}
    </Loader>
  );
};

export default AuthGuarder;
