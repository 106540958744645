import { Icon12 } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { isFunction } from "../../utils/isType";
import { Accordion } from "./Accordion/Accordion";
import styles from "./SideNavigation.module.scss";
import {
  SideNavigationCategory,
  SideNavigationCategoryProps,
} from "./SideNavigationCategory";
import { SideNavigationSubCategory } from "./SideNavigationSubCategory";

export interface SideNavigationProps {
  /** List of categories for navigation. */
  groups: Array<SideNavigationCategoryProps[]>;
  /** Determines if the side navigation is currently open or not. */
  open?: boolean;
  /** Active Path in groups */
  activePath?: string;
  /** Root node class name */
  className?: string;
  /** Check which item be clicked */
  onChange?: (path: string) => void;
}

export const SideNavigation = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<SideNavigationProps>
>(({ groups, open, activePath, onChange, className, ...ownProps }, ref) => {
  const [expandedIndices, setExpandedIndices] = React.useState<number[]>([]);
  const [_activePath, setActive] = React.useState<string>(
    () => activePath as string
  );
  const [isOpenNavigation, setIsOpenNavigation] = React.useState<boolean>(
    !!open
  );
  const _group: Array<SideNavigationCategoryProps[]> = React.useMemo(() => {
    if (!Array.isArray(groups) || !groups.length) return [];
    return groups.map((group) =>
      group.map((category) => {
        if (category.subCategories) {
          return {
            ...category,
            subCategories: category.subCategories.map((subCategory) => ({
              ...subCategory,
              active: subCategory.path === _activePath,
            })),
          };
        }
        return { ...category, active: category.path === _activePath };
      })
    );
  }, [_activePath, groups]);
  const _sideNavigation = classNames(
    styles.sideNavigation,
    {
      [styles.openNavigation]: isOpenNavigation,
    },
    className
  );
  const _sideNavigationToggle = classNames(styles.sideNavigationToggle, {
    [styles.openNavigation]: isOpenNavigation,
  });
  let accordionItemIndex = 0;

  React.useEffect(() => setActive(activePath as string), [activePath]);
  React.useEffect(() => setIsOpenNavigation(!!open), [open]);

  const handleOpenNavigation = (value?: boolean) => () =>
    setIsOpenNavigation((prevState) => value || !prevState);

  const handleExpandedItems = (items: number | number[]) =>
    setExpandedIndices(Array.isArray(items) ? items : [items]);

  const isActiveCategory = (
    category: SideNavigationCategoryProps,
    isExpanded: boolean
  ) =>
    category.active ||
    ((!isOpenNavigation || !isExpanded) &&
      !!category.subCategories?.find(({ active }) => active));

  const pathChangeHandler = (path: string) => {
    setActive(path);
    isFunction(onChange) && onChange(path);
  };

  const clickHandler = (path: string) => {
    if (path === undefined) return;
    handleOpenNavigation(true)();
    pathChangeHandler(path);
  };

  return (
    <Accordion
      className={_sideNavigation}
      allowToggle
      allowMultiple
      onChange={handleExpandedItems}
      ref={ref}
      chevronSize={12}
      {...ownProps}
    >
      <div className={styles.navigationHeader}>
        <button
          className={_sideNavigationToggle}
          onClick={handleOpenNavigation()}
        >
          <Icon12 icon="Hide" />
        </button>
      </div>
      {_group.map((group, index) => (
        <React.Fragment key={index}>
          {group.map((category) => {
            let isExpanded = false;
            if (category.subCategories) {
              isExpanded = expandedIndices.includes(accordionItemIndex);
              accordionItemIndex += 1;
            }
            return (
              <SideNavigationCategory
                key={category.title}
                title={category.title}
                icon={category.icon}
                path={category.path}
                expanded={isExpanded}
                openNavigation={isOpenNavigation}
                active={isActiveCategory(category, isExpanded)}
                onClick={clickHandler}
              >
                {category.subCategories &&
                  category.subCategories.map((subCategory) => (
                    <SideNavigationSubCategory
                      key={subCategory.path}
                      title={subCategory.title}
                      path={subCategory.path}
                      active={subCategory.active}
                      onClick={pathChangeHandler}
                    />
                  ))}
              </SideNavigationCategory>
            );
          })}
          {groups.length - 1 !== index && (
            <div className={styles.sideNavigationDivider} />
          )}
        </React.Fragment>
      ))}
    </Accordion>
  );
});
