import classNames from "classnames";
import RcTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import type { TooltipProps as RcTooltipProps } from "rc-tooltip/lib/Tooltip";
import React from "react";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import Fail from "../svg/Fail";
import Info from "../svg/Info";
import Suc from "../svg/Suc";
import Warning from "../svg/Warning";
import styles from "./Tooltip.module.scss";

export enum TooltipType {
  success = "suc",
  failed = "failed",
  info = "info",
  warning = "warning",
}

export interface ToolTipProps extends Omit<RcTooltipProps, "children"> {
  defaultArrowContentClassName?: string;
  type?: TooltipType;
  children?: React.ReactNode;
}

/** That's has a ui problem for triangle , will be refine feature */
const Tooltip: React.FC<ToolTipProps> = ErrorDetectorSmallView(
  (props: ToolTipProps) => {
    const {
      defaultArrowContentClassName,
      arrowContent,
      type,
      children,
      overlayClassName,
      ...rest
    } = props;
    const _overlayClassName = classNames(styles.overlay, overlayClassName);

    const statusIcon = React.useMemo(() => {
      const iconMap = new Map([
        [TooltipType.info, <Info key={TooltipType.info} size="auto" />],
        [TooltipType.success, <Suc key={TooltipType.success} size="auto" />],
        [TooltipType.failed, <Fail key={TooltipType.failed} size="auto" />],
        [
          TooltipType.warning,
          <Warning key={TooltipType.warning} size="auto" />,
        ],
      ]);
      return iconMap.get(type as TooltipType);
    }, [type]);

    const _arrowContent = React.useMemo(() => {
      const arrowContentClassName = classNames(
        styles.arrow,
        defaultArrowContentClassName
      );
      const defaultView = <div className={arrowContentClassName} />;

      return React.isValidElement(arrowContent) ? arrowContent : defaultView;
    }, [arrowContent, defaultArrowContentClassName]);

    const _children = React.useMemo(() => {
      return React.isValidElement(children) ? (
        children
      ) : (
        <span className={styles.icon}>{statusIcon}</span>
      );
    }, [children, statusIcon]);

    return (
      <RcTooltip
        {...rest}
        overlayClassName={_overlayClassName}
        arrowContent={_arrowContent}
      >
        {_children}
      </RcTooltip>
    );
  }
);

Tooltip.defaultProps = {
  placement: "bottom",
};

export default Tooltip;
