import classNames from "classnames";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./Cabinet.module.scss";

export interface ICabinet {
  status: boolean;
  cabinetClassName?: string;
  children?: React.ReactNode;
}

const Cabinet: React.FC<ICabinet> = ErrorDetectorSmallView(
  (props: ICabinet) => {
    const { status, cabinetClassName, children } = props;
    const cls = classNames(styles.cabinet, cabinetClassName, {
      [styles.cabinetActive]: status,
    });

    return <section className={cls}>{children}</section>;
  }
);

export default Cabinet;
