import { Button, TextArea } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { InputVariantDatePicker } from "../../../../components/Input/InputVariantDatePicker/InputVariantDatePicker";
import { InputVariantDropDown } from "../../../../components/Input/InputVariantDropDown/InputVariantDropDown";
import { LocaleLang } from "../../../../core/LocaleService";
import { formatDatePickValue } from "../../../../utils/commonUtils";
import SwitchCabinet from "../../../components/SwitchCabinet/SwitchCabinet";
import TextReminder from "../../../components/TextReminder/TextReminder";
import TextVariantPc from "../../../components/TextVariantPc/TextVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { SalesAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import OrderRequestModel, {
  IDNInfo,
  IFile,
} from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationSales.module.scss";
import InstallationSaleModel from "./InstallationSalesModel";
import useInstallationSales from "./useInstallationSales";

const InstallationSalesPc: React.FC = ErrorDetectorPageView(() => {
  const {
    installationSaleInfo,
    rolePage,
    saleRegions,
    labelFacRequired,
    reminderFacRequired,
    labelFacUploader,
    labelTrainingRequired,
    reminderTrainingRequired,
    labelApSupervisor,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelWarrantyPeriodPhd,
    labelDisclaimerNotice,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitVisible,
    labelSubmitReminder,
    platform,
    locale,
    setSubmit,
    confirmHandler,
    backAllTickHandler,
    changeHandler,
  } = useInstallationSales();

  const facAndTrainRequirePcView = React.useMemo(() => {
    const { facRequired, trainingRequired, apSupervisor, facUploader } =
      installationSaleInfo;
    const { pageStatus } = rolePage;
    const disabled = pageStatus === PageStatus.READONLY;

    const apSupervisorOption =
      OrderRequestModel.getApSupervisorList(saleRegions);
    const _apSupervisorOption =
      InstallationSaleModel.convertApSupervisorList(apSupervisorOption);
    const _pcSwitchCabinet = classNames(
      styles.pcSwitchCabinet,
      { [styles.toggleLabelEn]: locale === LocaleLang.EN_US },
      { [styles.toggleLabelCn]: locale === LocaleLang.ZH_CN }
    );

    return (
      <div className={styles.facAndTrainRequirePcView}>
        <div className={styles.togglePcView}>
          <SwitchCabinet
            labelClassName={_pcSwitchCabinet}
            cabinetClassName={styles.pcCabinetClass}
            cabinetLabel={labelFacRequired}
            checked={facRequired ? facRequired : false}
            disabled={disabled}
            onChange={(isChecked: boolean) =>
              changeHandler(
                InstallationModel.FAC_REQUIRED as keyof IInstallation,
                isChecked
              )
            }
          />
          <TextReminder label={reminderFacRequired} />
        </div>
        {facRequired && (
          <TextVariantPc label={labelFacUploader}>{facUploader}</TextVariantPc>
        )}
        <div className={styles.togglePcView}>
          <SwitchCabinet
            labelClassName={_pcSwitchCabinet}
            cabinetLabel={labelTrainingRequired}
            cabinetClassName={styles.pcCabinetClass}
            checked={trainingRequired ? trainingRequired : false}
            disabled={disabled}
            onChange={(isChecked: boolean) =>
              changeHandler(
                InstallationModel.TRAINING_REQUIRED as keyof IInstallation,
                isChecked
              )
            }
          />
          <TextReminder label={reminderTrainingRequired} />
        </div>
        {trainingRequired && (
          <InputVariantDropDown
            containerClassName={styles.pcDropDown}
            label={labelApSupervisor}
            options={_apSupervisorOption}
            value={apSupervisor}
            disabled={disabled}
            search
            onChange={(value) =>
              changeHandler(InstallationModel.AP_SUPERVISOR, value)
            }
          />
        )}
      </div>
    );
  }, [
    installationSaleInfo,
    rolePage,
    saleRegions,
    locale,
    labelFacRequired,
    reminderFacRequired,
    labelFacUploader,
    labelTrainingRequired,
    reminderTrainingRequired,
    labelApSupervisor,
    changeHandler,
  ]);

  const installDatePcView = React.useMemo(() => {
    const warrantyReminder = InstallationModel.getReminderByWarranty(
      installationSaleInfo.warrantyPeriod as WarrantyPeriod
    );
    const warrantyOptions = InstallationModel.getWarrantyPeriodOptions();
    const { expectedInstallDate } = installationSaleInfo;
    const { pageStatus } = rolePage;
    const disabled = pageStatus === PageStatus.READONLY;

    return (
      <div className={styles.installDatePcView}>
        <InputVariantDatePicker
          label={labelExpectedInstallDate}
          className={styles.pcDropDown}
          disabled={disabled}
          value={formatDatePickValue(expectedInstallDate as number)}
          onChange={(value: Date) =>
            changeHandler(
              InstallationModel.EXPECTED_INSTALL_DATA,
              new Date(value).getTime()
            )
          }
        />
        <div>
          <InputVariantDropDown
            containerClassName={styles.pcDropDown}
            label={labelWarrantyPeriod}
            options={warrantyOptions}
            value={installationSaleInfo.warrantyPeriod}
            disabled={disabled}
            onChange={(value) =>
              changeHandler(InstallationModel.WARRANTY_PERIOD, value)
            }
          />
          {(installationSaleInfo.warrantyPeriod ===
            WarrantyPeriod.DELIVERY_MONTH_15 ||
            installationSaleInfo.warrantyPeriod ===
              WarrantyPeriod.MONTH_12) && (
            <div className={styles.warrantyReminder}>{warrantyReminder}</div>
          )}
          {installationSaleInfo.warrantyPeriod === WarrantyPeriod.OTHER && (
            <div className={styles.otherComments}>
              <TextArea
                label=""
                placeholder={labelWarrantyPeriodPhd}
                value={installationSaleInfo.otherWarrantyPeriod}
                disabled={disabled}
                onChange={(evt) =>
                  changeHandler(
                    InstallationModel.WARRANTY_PERIOD_COMMENTS,
                    evt?.target?.value
                  )
                }
                maxLength={50}
                rows={4}
              />
            </div>
          )}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationSaleInfo,
    rolePage,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelWarrantyPeriodPhd,
    locale,
    changeHandler,
  ]);

  const installUploadFilePcView = React.useMemo(() => {
    const list = InstallationSaleModel.getFileInfoViewModel(
      installationSaleInfo as IInstallation,
      rolePage?.pageStatus,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.installUploadFilePcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationSaleInfo,
    rolePage?.pageStatus,
    platform,
    labelDisclaimerNotice,
    locale,
    changeHandler,
  ]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationSaleModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SalesAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === SalesAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );
      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, submitEnable, locale, backAllTickHandler, setSubmit]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.installationInfoSalesPc}>
      <div className={styles.pageContentPc}>
        {facAndTrainRequirePcView}
        {installDatePcView}
        <OrderDnInfoView
          {...(installationSaleInfo?.dnInfo as IDNInfo)}
          customerType={installationSaleInfo.customerType}
        />
        {installUploadFilePcView}
      </div>
      {buttonsView}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={submitVisible}
        title={labelSubmitReminder}
        onConfirm={confirmHandler}
      />
    </div>
  );
});

export default InstallationSalesPc;
