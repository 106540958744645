import { isEmpty } from "lodash";
import React from "react";
import { useRecoilValue } from "recoil";
import { formatMessage } from "../../core/LangService";
import { localeState } from "../../store";
import { isObject } from "../../utils/isType";

export default function useLang(
  langObjet: Record<
    string,
    { id: string; values?: Record<string, string | number> }
  >
): Record<string, string> {
  const lang = useRecoilValue(localeState);

  return React.useMemo(() => {
    if (!isObject(langObjet) || isEmpty(langObjet)) return {};
    const langs: Record<string, string> = {};
    for (const i in langObjet) {
      if (!langObjet.hasOwnProperty(i)) continue;
      langs[i] = formatMessage(langObjet[i].id, langObjet[i].values);
    }
    return langs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langObjet, lang]);
}
