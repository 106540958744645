import React from "react";
import { useRecoilValue } from "recoil";
import { LocaleLang } from "../../../../../core/LocaleService";
import { localeState } from "../../../../../store";
import { ReminderType } from "../../../../components/TextReminder/TextReminder";
import useLang from "../../../../hooks/useLang";
import {
  CustomerTypes,
  IDNInfo,
  IMoreDetailItem,
} from "../../../model/OrderRequestModel";

export interface OrderInfoDnProps extends IDNInfo {
  dnDelivered?: boolean;
  customerType?: CustomerTypes;
}

export interface HookOrderInfoDn {
  cpoDate: number;
  installationOrderNo: string[];
  serialNumber: string[];
  serviceContractorOrder: string;
  dnItems: IMoreDetailItem[];
  dnDelivered: boolean | undefined;
  visible: boolean;
  notice: string;
  noticeType: ReminderType;
  multipleVisible: {
    installationOrderNo: boolean;
    serialNumber: boolean;
  };
  locale: LocaleLang;
  setMultipleVisible: React.Dispatch<
    React.SetStateAction<{
      installationOrderNo: boolean;
      serialNumber: boolean;
    }>
  >;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function (props: OrderInfoDnProps): HookOrderInfoDn {
  const { dnNotice, dnBlockNotice } = useLang({
    dnNotice: { id: "order_dn_notice" },
    dnBlockNotice: { id: "order_dn_block_notice" },
  });
  const locale = useRecoilValue(localeState);
  const [visible, setVisible] = React.useState(false);
  const {
    cpoDate,
    installationOrderNo,
    serialNumber,
    serviceContractorOrder,
    dnItems,
    dnDelivered,
    customerType,
  } = props;
  const dnBlock =
    customerType === CustomerTypes.NOT_INDUSTRY && dnDelivered === false;
  const notice = dnBlock ? dnBlockNotice : dnNotice;
  const noticeType = dnBlock ? ReminderType.ERROR : ReminderType.INFO;
  const [multipleVisible, setMultipleVisible] = React.useState<{
    installationOrderNo: boolean;
    serialNumber: boolean;
  }>({ installationOrderNo: false, serialNumber: false });

  return {
    cpoDate,
    installationOrderNo,
    serialNumber,
    serviceContractorOrder,
    multipleVisible,
    dnItems,
    dnDelivered,
    visible,
    notice,
    noticeType,
    locale,
    setMultipleVisible,
    setVisible,
  };
}
