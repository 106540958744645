import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import Cabinet from "../../../components/Cabinet/Cabinet";
import { ErrorDetectorMediumView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { getCssVariable } from "../../../utils/commonUtils";
import { isFunction, isNumber, isString } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import styles from "./StepVariant.module.scss";

export enum StepIcon {
  PENDING = "pending",
  COMPLETE = "complete",
  REJECT = "reject",
  INACTIVE = "inactive",
}

export interface IStepItem {
  key: string | any;
  label: string | React.ReactElement;
  labelClassName?: string;
  subChildren?: React.ReactElement;
  icon?: StepIcon | React.ReactElement;
  lineClassName?: string;
}

export interface IStepVariant {
  className?: string;
  collapseAll?: boolean;
  steps: IStepItem[];
  unFolds?: number[];
  toggleCollapse?: boolean;
  minDisplayStep?: number;
  onChange?: (key: number[]) => void;
}

const StepVariant: React.FC<IStepVariant> = ErrorDetectorMediumView(
  (props: IStepVariant) => {
    const {
      collapseAll,
      steps = [],
      unFolds = [],
      className,
      onChange,
      toggleCollapse,
      minDisplayStep,
    } = props;
    const stepVariantCls = classNames(styles.stepVariant, className);
    const [stateUnFold, setUnFolds] = React.useState<number[]>(() => unFolds);
    const [stateToggle, setToggle] = React.useState(() => toggleCollapse);
    const _steps = React.useMemo(() => {
      if (
        !stateToggle ||
        !Array.isArray(steps) ||
        !steps.length ||
        !isNumber(minDisplayStep) ||
        (minDisplayStep as number) > steps.length
      )
        return steps;
      const index = +`-${minDisplayStep}`;
      return steps.slice(index);
    }, [steps, minDisplayStep, stateToggle]);

    React.useEffect(() => {
      const unFolds = new Array(_steps.length).fill(0).map((_, index) => index);
      setUnFolds(unFolds);
    }, [_steps]);

    const toggleNumber = React.useMemo(() => {
      if (
        !Array.isArray(steps) ||
        !steps.length ||
        !isNumber(minDisplayStep) ||
        minDisplayStep >= steps.length
      )
        return 0;
      if (!stateToggle) return 0;
      return steps.length - minDisplayStep;
    }, [steps, minDisplayStep, stateToggle]);

    const { expand, shrink } = useLang({
      expand: {
        id: "order_comment_toggle_number",
        values: { value: `${toggleNumber}` },
      },
      shrink: { id: "common_shirk" },
    });

    const toggleHandler = React.useCallback(() => {
      setToggle((pre) => !pre);
    }, []);

    const changeHandler = React.useCallback(
      (index: number) => {
        let unFolds = [...stateUnFold];
        if (stateUnFold?.includes(index)) {
          unFolds = stateUnFold.filter((item) => item !== index);
        } else {
          unFolds.push(index);
        }
        setUnFolds(unFolds);
        isFunction(onChange) && onChange(unFolds);
      },
      [stateUnFold, onChange]
    );

    const toggleView = React.useMemo(() => {
      const show = toggleCollapse && steps?.length > Number(minDisplayStep);
      const label = stateToggle ? expand : shrink;
      const _className = classNames(styles.expandIcon, {
        [styles.active]: !stateToggle,
      });
      const fill = getCssVariable("--color-interaction-link");
      return (
        show && (
          <div className={styles.toggle} onClick={toggleHandler}>
            <span className={styles.labelText}>{label}</span>
            {
              <Icon
                icon={"ChevronExpand"}
                size={12}
                className={_className}
                fill={fill}
              />
            }
          </div>
        )
      );
    }, [
      toggleCollapse,
      steps?.length,
      minDisplayStep,
      stateToggle,
      expand,
      shrink,
      toggleHandler,
    ]);

    const stepView = React.useCallback(
      (step: IStepItem, index: number) => {
        const { label, subChildren, icon, labelClassName, lineClassName } =
          step;
        const labelCls = classNames(styles.label, labelClassName);
        const iconView = isString(icon) ? getIcon(icon) : icon;
        const status = isEmpty(collapseAll)
          ? stateUnFold?.includes(index)
          : collapseAll;

        const cabinetCls = classNames(
          styles.cabinet,
          { [styles.nonLine]: _steps.length === index + 1 },
          { [styles.stepActive]: isString(icon) && icon !== StepIcon.INACTIVE },
          {
            [styles.stepInactive]: isString(icon) && icon === StepIcon.INACTIVE,
          },
          lineClassName
        );

        return (
          <div
            className={styles.step}
            onClick={changeHandler.bind(null, index)}
            key={index}
          >
            <div className={labelCls}>
              {iconView}
              <span className={styles.labelText}>{label}</span>
            </div>
            <Cabinet status={!!status} cabinetClassName={cabinetCls}>
              {status ? (
                <div className={styles.cabinetContent}>{subChildren}</div>
              ) : (
                <></>
              )}
            </Cabinet>
          </div>
        );
      },
      [collapseAll, stateUnFold, _steps.length, changeHandler]
    );

    return (
      <section className={stepVariantCls}>
        {toggleView}
        {Array.isArray(_steps) &&
          _steps?.map((item, index) => stepView(item, index))}
      </section>
    );
  }
);

const getIcon = (name: StepIcon | string) => {
  const activeColor = getCssVariable("--color-interaction-link"),
    pendingColor = getCssVariable("--fill-color-headline-invert"),
    rejectColor = getCssVariable("--text-color-button");

  const iconMap = new Map([
    [
      StepIcon.PENDING,
      <span className={styles.pending} key={StepIcon.PENDING}>
        <Icon icon={"StatusPending"} size={12} fill={pendingColor} />
      </span>,
    ],
    [
      StepIcon.COMPLETE,
      <span className={styles.complete} key={StepIcon.COMPLETE}>
        <Icon icon={"Checkmark"} size={12} fill={activeColor} />
      </span>,
    ],
    [
      StepIcon.REJECT,
      <span className={styles.reject} key={StepIcon.REJECT}>
        <Icon icon={"Refresh"} size={12} fill={rejectColor} />
      </span>,
    ],
    [
      StepIcon.INACTIVE,
      <span className={styles.inactive} key={StepIcon.INACTIVE} />,
    ],
  ]);
  return (
    iconMap.get(name as StepIcon) ?? (
      <span className={styles.inactive} key={StepIcon.INACTIVE} />
    )
  );
};

StepVariant.defaultProps = {
  collapseAll: true,
};

export default StepVariant;
