interface IImg {
  fillColor?: string;
  size?: "auto" | "default";
}

export const ExcelIcon = (props: IImg) => {
  const { size } = props;
  const width = size === "auto" ? "100%" : "54",
    height = size === "auto" ? "100%" : "54";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8 2C8 0.895429 8.89543 0 10 0H38L54 15V52C54 53.1046 53.1046 54 52 54H10C8.89543 54 8 53.1046 8 52V2Z"
        fill="#DCE3E9"
      />
      <path
        d="M8 33.7998C8 32.6952 8.89543 31.7998 10 31.7998H37C38.1046 31.7998 39 32.6952 39 33.7998V45.7998C39 46.9044 38.1046 47.7998 37 47.7998H8V33.7998Z"
        fill="#CAD2DB"
      />
      <rect y="30" width="39" height="16" rx="2" fill="#3BE493" />
      <path d="M38 0V13C38 14.1046 38.8954 15 40 15H54L38 0Z" fill="#B1B7BE" />
      <path d="M44 15L54 23.5V15H44Z" fill="#CAD2DB" />
      <path
        d="M9.67387 34.86H11.1039L12.7839 37.38L14.4639 34.86H15.8939L13.4839 38.31L16.0539 42H14.6239L12.7839 39.24L10.9439 42H9.51387L12.0639 38.31L9.67387 34.86ZM16.8045 34.86H17.9645V41H21.8245V42H16.8045V34.86ZM25.2425 34.72C26.0825 34.72 26.7425 34.89 27.2125 35.24C27.7125 35.61 28.0025 36.19 28.0725 36.97H26.9125C26.8125 36.53 26.6225 36.21 26.3625 36.02C26.1025 35.82 25.7025 35.73 25.1825 35.73C24.7325 35.73 24.3925 35.79 24.1525 35.92C23.8525 36.07 23.7125 36.32 23.7125 36.66C23.7125 36.96 23.8725 37.21 24.2125 37.39C24.3625 37.47 24.7725 37.62 25.4325 37.83C26.4025 38.13 27.0225 38.37 27.3025 38.53C27.9225 38.9 28.2325 39.41 28.2325 40.07C28.2325 40.71 27.9825 41.22 27.4825 41.59C26.9825 41.95 26.2725 42.14 25.3625 42.14C24.4825 42.14 23.7925 41.97 23.3025 41.63C22.7025 41.21 22.3725 40.55 22.3225 39.64H23.4825C23.5625 40.18 23.7525 40.57 24.0625 40.8C24.3425 41 24.7725 41.11 25.3625 41.11C25.8825 41.11 26.3025 41.02 26.6025 40.85C26.9025 40.67 27.0625 40.44 27.0625 40.14C27.0625 39.76 26.8325 39.46 26.3925 39.24C26.2525 39.17 25.7925 39.01 25.0025 38.77C24.1225 38.49 23.5725 38.29 23.3625 38.17C22.8125 37.84 22.5425 37.36 22.5425 36.73C22.5425 36.1 22.8025 35.6 23.3425 35.24C23.8425 34.89 24.4725 34.72 25.2425 34.72Z"
        fill="white"
      />
    </svg>
  );
};
