import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import CommentsView from "../../components/CommentView/CommentsView";
import { IFile } from "../../model/OrderRequestModel";
import FacIBaseModel, { IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseFacUpload.module.scss";
import useFacIBaseFac from "./useFacIBaseFac";

const FacIBaseFacUploadMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    rolePage,
    labelCancel,
    labelConfirm,
    labelSubmitReminder,
    labelInstallDateReminder,
    submitEnable,
    submitVisible,
    buttonBlock,
    locale,
    setSubmit,
    confirmHandler,
    backToAllTicket,
    changeHandler,
  } = useFacIBaseFac();

  const FacFileUploadView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoViewModel(
      facIBaseInfo as IFacIBase,
      rolePage?.pageStatus
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === FacIBaseModel.FAC_CAC_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.filesView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, locale, changeHandler]);

  const installDateView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoViewMobileModel(
      facIBaseInfo as Partial<IFacIBase>,
      rolePage?.pageStatus
    );
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.ACCEPTANCE_DATE_FAC,
          {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IFacIBase, value?.getTime()),
            variantClassName: styles.acceptDate,
          },
        ],
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installDateView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
        <div className={styles.reminderGap}>
          <TextReminder label={labelInstallDateReminder} />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facIBaseInfo,
    labelInstallDateReminder,
    rolePage?.pageStatus,
    locale,
    changeHandler,
  ]);

  const commentsView = React.useMemo(() => {
    return <CommentsView />;
  }, []);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getFacUploadButtonViewModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
      [RsmAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === RsmAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, submitEnable, locale, backToAllTicket, setSubmit]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.facIbaseUpload}>
      <div className={styles.pageContainer}>
        {FacFileUploadView}
        {installDateView}
        {commentsView}
      </div>
      {buttonsView}
      <Confirmor
        footer={confirmButtonView}
        visible={submitVisible}
        content={labelSubmitReminder}
      />
    </div>
  );
});

export default FacIBaseFacUploadMobile;
