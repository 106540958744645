import React, { FC } from "react";
import { mergeProps } from "../utils/mergeDefaultProps";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import { toCSSLength } from "../utils/toCssLength";
import styles from "./Grid.module.scss";

export type GridProps = {
  columns: number;
  gap?: number | string | [number | string, number | string];
  children?: React.ReactNode;
} & NativeProps<"--gap" | "--gap-vertical" | "--gap-horizontal">;

export const Grid: FC<GridProps> = (props) => {
  const style: GridProps["style"] & Record<"--columns", string> = {
    "--columns": props.columns.toString(),
  };
  const { gap } = props;
  if (gap !== undefined) {
    if (Array.isArray(gap)) {
      style["--gap-horizontal"] = toCSSLength(gap[0]);
      style["--gap-vertical"] = toCSSLength(gap[1]);
    } else {
      style["--gap"] = toCSSLength(gap);
    }
  }

  return withNativeProps(
    props,
    <div className={styles.grid} style={style}>
      {props.children}
    </div>
  );
};

export type GridItemProps = {
  span?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: React.ReactNode;
} & NativeProps;

type GridItemStyle = React.CSSProperties &
  Record<"--item-span", GridItemProps["span"]>;

export const GridItem: FC<GridItemProps> = (p) => {
  const props = mergeProps({ span: 1 }, p);

  const itemStyle: GridItemStyle = {
    "--item-span": props.span,
  };
  return withNativeProps(
    props,
    <div
      className={styles["grid-item"]}
      style={itemStyle}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
