import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import React, {
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useUnmountedRef from "../../hooks/useUnmountRef";
import { ErrorDetectorMediumView } from "../ErrorDecorator/ErrorDecorator";
import Mask, { MaskProps } from "../Mask/Mask";
import Close from "../svg/Close";
import { mergeProps } from "../utils/mergeDefaultProps";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import { GetContainer, renderToContainer } from "../utils/renderToContainer";
import { ShouldRender } from "../utils/shouldRender";
import { useInnerVisible } from "../utils/useInnerVisible";
import { useLockScroll } from "../utils/useLockScroll";
import {
  PropagationEvent,
  withStopPropagation,
} from "../utils/withStopPropagation";
import styles from "./Popup.module.scss";

export type PopupBaseProps = {
  afterClose?: () => void;
  afterShow?: () => void;
  bodyClassName?: string;
  bodyStyle?: React.CSSProperties;
  closeOnMaskClick?: boolean;
  destroyOnClose?: boolean;
  disableBodyScroll?: boolean;
  forceRender?: boolean;
  getContainer?: GetContainer;
  mask?: boolean;
  maskClassName?: string;
  maskStyle?: MaskProps["style"];
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose?: () => void;
  onMaskClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  showCloseButton?: boolean;
  stopPropagation?: PropagationEvent[];
  visible?: boolean;
};

export type PopupProps = PopupBaseProps &
  PropsWithChildren<{
    position?: "bottom" | "top" | "left" | "right";
  }> &
  NativeProps<"--z-index">;

const defaultProps = {
  closeOnMaskClick: true,
  destroyOnClose: false,
  disableBodyScroll: true,
  forceRender: false,
  getContainer: () => document.body,
  mask: true,
  showCloseButton: false,
  stopPropagation: ["click"],
  visible: false,
  position: "bottom",
};

export const Popup: FC<PopupProps> = ErrorDetectorMediumView(
  (p: PopupProps) => {
    const props = mergeProps(defaultProps, p);

    const bodyCls = classNames(
      `${styles.popupBody}`,
      props.bodyClassName,
      `${styles[`popupBody-position-${props.position}`]}`
    );

    const [active, setActive] = useState(props.visible);
    useLayoutEffect(() => {
      if (props.visible) {
        setActive(true);
      }
    }, [props.visible]);

    const ref = useRef<HTMLDivElement>(null);
    useLockScroll(ref, props.disableBodyScroll && active ? "strict" : false);

    const unmountedRef = useUnmountedRef();
    const { percent } = useSpring({
      percent: props.visible ? 0 : 100,
      config: {
        precision: 0.1,
        mass: 0.4,
        tension: 300,
        friction: 30,
      },
      onRest: () => {
        if (unmountedRef.current) return;
        setActive(props.visible);
        if (props.visible) {
          props.afterShow?.();
        } else {
          props.afterClose?.();
        }
      },
    });

    const maskVisible = useInnerVisible(active && props.visible);

    const node = withStopPropagation(
      props.stopPropagation,
      withNativeProps(
        props,
        <div
          className={styles.popup}
          onClick={props.onClick}
          style={{ display: active ? undefined : "none" }}
        >
          {props.mask && (
            <Mask
              visible={maskVisible}
              forceRender={props.forceRender}
              destroyOnClose={props.destroyOnClose}
              onMaskClick={(e) => {
                props.onMaskClick?.(e);
                if (props.closeOnMaskClick) {
                  props.onClose?.();
                }
              }}
              className={props.maskClassName}
              style={props.maskStyle}
              disableBodyScroll={false}
              stopPropagation={props.stopPropagation}
            />
          )}
          <animated.div
            className={bodyCls}
            style={{
              ...props.bodyStyle,
              transform: percent.to((v) => {
                if (props.position === "bottom") {
                  return `translate(0, ${v}%)`;
                }
                if (props.position === "top") {
                  return `translate(0, -${v}%)`;
                }
                if (props.position === "left") {
                  return `translate(-${v}%, 0)`;
                }
                if (props.position === "right") {
                  return `translate(${v}%, 0)`;
                }
                return "none";
              }),
            }}
            ref={ref}
          >
            {props.showCloseButton && (
              <a
                className={styles.popupCloseIcon}
                onClick={() => {
                  props.onClose?.();
                }}
                role="button"
                aria-label={"Close"}
              >
                <Close />
              </a>
            )}
            {props.children}
          </animated.div>
        </div>
      )
    );

    return (
      <ShouldRender
        active={active}
        forceRender={props.forceRender}
        destroyOnClose={props.destroyOnClose}
      >
        {renderToContainer(props.getContainer, node)}
      </ShouldRender>
    );
  }
);
