import { PagePaths } from "../pages/service/pageType";
import { Role } from "../pages/service/userRoleTypes";

export enum PageModule {
  ORDER = "order",
  DASHBOARD = "dashboard",
  CONFIG = "config",
  ADMIN = "admin",
}

export default class {
  static readonly ROUTE_PAGES_MOBILE = [
    PagePaths.PATH_ORDER_LIST,
    PagePaths.PATH_ORDER_REQUEST,
    PagePaths.PATH_ANY,
    PagePaths.PATH_ERROR_PAGE,
  ];

  static readonly ROUTE_PAGES_PC = [
    PagePaths.PATH_ORDER_LIST,
    PagePaths.PATH_ORDER_REQUEST,
    PagePaths.PATH_DASHBOARD,
    PagePaths.PATH_ANY,
    PagePaths.PATH_ERROR_PAGE,
    PagePaths.PATH_ADMIN,
  ];

  static getRouterIndexPathByRole = (role: Role): PagePaths => {
    const indexPathMap = new Map([
      [Role.ROLE_ADMIN, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_SALES_MANAGER, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_RSM_MANAGER, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_AP_MANAGER, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_SALES, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_RSM, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_FSE, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_SO, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_APPLY_SUPERVISOR, PagePaths.PATH_ORDER_LIST],
      [Role.ROLE_APPLY_ENGINEER, PagePaths.PATH_ORDER_LIST],
    ]);

    return indexPathMap.get(role) as PagePaths;
  };
}
