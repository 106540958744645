import { Icon } from "@zeiss/ods-components-react";
import React, { FormEvent, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import Empty from "../../../assets/svg/Empty";
import Confirmor from "../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { InfiniteScroll } from "../../../components/InfiniteScroll/InfiniteScroll";
import { PullToRefresh } from "../../../components/PullToRefresh/PullToRefresh";
import { LocaleLang } from "../../../core/LocaleService";
import { useTitle } from "../../../hooks";
import useDebounceFn from "../../../hooks/useDebounceFn";
import { OrderItem } from "../../../services/order.service";
import {
  localeState,
  orderParentTaskIdState,
  orderRequestTabs,
  orderTaskIdState,
  stepState,
  userState,
} from "../../../store";
import { getCssVariable } from "../../../utils/commonUtils";
import HomeModel from "../../Home/HomeModel";
import OrderRequestModel from "../../OrderRequest/model/OrderRequestModel";
import { IndicatorNew } from "../../components/Text/IndicatorNew/IndicatorNew";
import TextReminder, {
  ReminderType,
} from "../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../components/ViewTypes";
import useLang from "../../hooks/useLang";
import { PagePaths } from "../../service/pageType";
import { disposeOrderStepSummary } from "../../service/roleStateService/RoleStepPageService";
import OrderListModel, { OrderStatus } from "../OrderListModel";
import useLogout from "../useLogout";
import styles from "./OrderListMobile.module.scss";
import OrderListMobileModel from "./OrderListMobileModel";
import ToggleLocale from "./ToggleLocale/ToggleLocale";

const PAGE_COUNT = 20;
const DEFAULT_INDEX = 1;

const OrderListMobile: React.FC = ErrorDetectorPageView(() => {
  const [list, setList] = React.useState<OrderItem[]>([]);
  const [, setKeywords] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState<OrderStatus | undefined>(
    OrderStatus.PENDING_IN_PROGRESS
  );
  const { id } = useRecoilValue(userState);
  const {
    title,
    searchPhd,
    labelStatusTodo,
    labelStatusDone,
    labelStatusComplete,
    labelStatusCancel,
    labelUnSplitReminder,
    labelEmpty,
  } = useLang({
    title: { id: "common_btn_all_tickets" },
    searchPhd: { id: "order_list_search_phd" },
    labelStatusTodo: { id: "order_list_search_status_to_do" },
    labelStatusDone: { id: "order_list_search_status_done" },
    labelStatusComplete: { id: "order_list_search_status_complete" },
    labelStatusCancel: { id: "order_list_search_status_cancel" },
    labelUnSplitReminder: { id: "order_list_unSplit_reminder" },
    labelEmpty: { id: "common_empty_phd" },
  });

  const [hasMore, setHasMore] = React.useState(false);
  const resetTab = useResetRecoilState(orderRequestTabs);
  const resetStep = useResetRecoilState(stepState);
  const resetInstallationTaskId = useResetRecoilState(orderTaskIdState);
  const resetParentInstallationTaskId = useResetRecoilState(
    orderParentTaskIdState
  );
  const userInfo = useRecoilValue(userState);
  const location = useLocation();
  const {
    confirmVisible,
    loading,
    logoutLabel,
    cancel,
    confirm,
    setLoading,
    setError,
    toastError,
    confirmCloseHandler,
    clickHandler: logoutHandler,
  } = useLogout();
  const pageIndexRef = React.useRef(DEFAULT_INDEX);
  const searchValueRef = React.useRef<string>();
  const [pageLoad, setLoad] = React.useState<boolean>(false);
  const locale = useRecoilValue(localeState);
  const showEmpty = !loading && pageLoad && !list.length;

  const { run: searchHandler } = useDebounceFn(
    (value) => {
      setKeywords(value);
      getList(value, orderStatus, DEFAULT_INDEX);
    },
    { wait: 500 }
  );
  const navigate = useNavigate();
  useTitle(title);

  useEffect(() => {
    refreshHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetInstallationTaskId();
    resetParentInstallationTaskId();
    resetStep();
    resetTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = React.useCallback(
    async (_keywords?: string, _status?: OrderStatus, index?: number) => {
      if (!id) return;
      setLoading(true);
      try {
        const pageIndex = index || pageIndexRef.current;
        const { data } = await OrderListModel.getList({
          userId: id,
          pageIndex: index || pageIndexRef.current,
          pageCount: PAGE_COUNT,
          status: _status ?? orderStatus,
          keywords: (_keywords || searchValueRef.current)?.trim(),
        });
        const { list: orders, total } = data;
        const listData =
          pageIndex === DEFAULT_INDEX ? orders : list.concat(orders);
        setHasMore(total > pageIndex * PAGE_COUNT);
        setList(listData);
        setLoad(true);
        pageIndexRef.current = index ? index : pageIndexRef.current + 1;
      } catch (res: any) {
        setError(res?.errorCode);
        toastError();
      }
      setLoading(false);
    },
    [id, orderStatus, list, setLoading, setError, toastError]
  );

  const inputChangeHandler = React.useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      const { value } = evt?.currentTarget ?? {};
      searchValueRef.current = value;
    },
    []
  );

  const statusChangeHandler = React.useCallback(
    (status: OrderStatus) => {
      if (orderStatus === status) {
        setOrderStatus(OrderStatus.ALL);
        getList(searchValueRef.current, OrderStatus.ALL, DEFAULT_INDEX);
      } else {
        setOrderStatus(status);
        getList(searchValueRef.current, status, DEFAULT_INDEX);
      }
    },
    [orderStatus, getList]
  );

  const refreshHandler = React.useCallback(() => {
    return getList(searchValueRef.current, orderStatus, DEFAULT_INDEX);
  }, [orderStatus, getList]);

  const loadMoreHandler = React.useCallback(() => {
    return getList(searchValueRef.current, orderStatus);
  }, [orderStatus, getList]);

  const clickHandler = React.useCallback(
    (
      installationTaskId: string,
      parentInstallationTaskId: string | undefined
    ) => {
      const { search } = location;
      const queryKey = parentInstallationTaskId
          ? HomeModel.QUERY_PARENT_INSTALLATION_TASK_ID
          : HomeModel.QUERY_INSTALLATION_TASK_ID,
        id = parentInstallationTaskId ?? installationTaskId;

      const combinedSearch = search
        ? `?${search}&${queryKey}=${id}`
        : `?${queryKey}=${id}`;

      navigate({
        pathname: PagePaths.PATH_ORDER_REQUEST,
        search: combinedSearch,
      });
    },
    [location, navigate]
  );

  const changeLang = React.useCallback((lang: string | number) => {
    OrderListModel.setLang(lang as LocaleLang);
  }, []);

  const listView = React.useMemo(() => {
    return list.map((order: OrderItem) => {
      const { id, orderStepSummary, parentInstallationTaskId } = order ?? {};
      const formattedOrderStepSummary = disposeOrderStepSummary(
        orderStepSummary,
        userInfo
      );
      const { key, status } = formattedOrderStepSummary ?? {};
      const _isUnSplitOrder = OrderListModel.isUnSplitOrder(
        formattedOrderStepSummary,
        order?.parentInstallationTaskId as string
      );

      const stepLabel = OrderRequestModel.formatStepLabel(key),
        statusLabel = OrderRequestModel.formatStepStatusLabel(status);

      const textListView = OrderListMobileModel.getInfoModels(order).map(
        ({ key, viewType, props }) => {
          const propsMap = new Map([
            [
              OrderListMobileModel.FINAL_GOODS_RECIPE,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);

          const _props = propsMap.get(key as string) || props;

          return (
            <div className={styles.textItem} key={key}>
              <ViewComponent type={viewType as ViewType} props={_props} />
            </div>
          );
        }
      );

      const _parentInstallationTaskId = _isUnSplitOrder
        ? parentInstallationTaskId
        : undefined;

      return (
        <div
          className={styles.listView}
          key={id}
          onClick={clickHandler.bind(null, id, _parentInstallationTaskId)}
        >
          <div className={styles.step}>
            <span className={styles.label}>{stepLabel}</span>
            <span className={styles.status}>{statusLabel}</span>
          </div>
          {textListView}
          {_isUnSplitOrder && (
            <div className={styles.unSplitReminder}>
              <TextReminder
                reminderType={ReminderType.ERROR}
                label={labelUnSplitReminder}
              />
            </div>
          )}
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelUnSplitReminder, list, userInfo, locale, clickHandler]);

  const searchView = React.useMemo(() => {
    const fill = getCssVariable("--text-color-body");

    return (
      <div className={styles.search}>
        <div onClick={logoutHandler} className={styles.iconContainer}>
          <Icon
            icon={"Search"}
            size={16}
            className={styles.searchIcon}
            fill={fill}
          />
        </div>
        <input
          placeholder={searchPhd}
          onChange={(e) => searchHandler(e.target.value)}
          className={styles.searchInput}
          onInput={inputChangeHandler}
        />
        <ToggleLocale locale={locale} onChange={changeLang} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhd, locale, searchHandler]);

  const statusView = React.useMemo(() => {
    return (
      <div className={styles.buttonGroup}>
        <button
          onClick={() => statusChangeHandler(OrderStatus.PENDING_IN_PROGRESS)}
          className={
            orderStatus === OrderStatus.PENDING_IN_PROGRESS ? styles.active : ""
          }
        >
          {labelStatusTodo}
        </button>
        <button
          onClick={() => statusChangeHandler(OrderStatus.PENDING_RESOLVE)}
          className={
            orderStatus === OrderStatus.PENDING_RESOLVE ? styles.active : ""
          }
        >
          {labelStatusDone}
        </button>
        <button
          onClick={() => statusChangeHandler(OrderStatus.RESOLVED)}
          className={orderStatus === OrderStatus.RESOLVED ? styles.active : ""}
        >
          {labelStatusComplete}
        </button>
        <button
          onClick={() => statusChangeHandler(OrderStatus.CANCEL)}
          className={orderStatus === OrderStatus.CANCEL ? styles.active : ""}
        >
          {labelStatusCancel}
        </button>
      </div>
    );
  }, [
    orderStatus,
    labelStatusTodo,
    labelStatusDone,
    labelStatusComplete,
    labelStatusCancel,
    statusChangeHandler,
  ]);

  const emptyView = React.useMemo(() => {
    return (
      <div className={styles.emptyView}>
        <Empty />
        <div className={styles.emptyText}>{labelEmpty}</div>
      </div>
    );
  }, [labelEmpty]);

  return (
    <>
      <PullToRefresh onRefresh={refreshHandler}>
        <section className={styles.orderList}>
          {searchView}
          {statusView}
          {!showEmpty && listView}
          {showEmpty && emptyView}
        </section>
      </PullToRefresh>
      <InfiniteScroll loadMore={loadMoreHandler} hasMore={hasMore} />
      <Confirmor
        visible={confirmVisible}
        content={logoutLabel}
        onConfirm={confirmCloseHandler}
        maskClosable={false}
        buttonTexts={[
          {
            type: "secondary",
            label: cancel,
            closeType: "cancel",
          },
          {
            type: "primary",
            label: confirm,
            closeType: "ok",
          },
        ]}
      />
    </>
  );
});

export default OrderListMobile;
