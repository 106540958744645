import { cloneDeep, isEqual } from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { ISplitOrderReq } from "../../../../services/order.service";
import {
  loadState,
  localeState,
  orderParentTaskIdState,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import { IMoreDetailItem } from "../../model/OrderRequestModel";
import { ButtonMore } from "../InfoSales/InfoSalesModel";
import { BeforeChangeType } from "../components/Counter/Counter";
import SplitOrderModel, {
  ConfirmReminder,
  ISplitItem,
  ISplitOrder,
  SplitType,
} from "./SplitOrderModel";

export default function () {
  const {
    labelCancel,
    labelConfirm,
    deleteReminder,
    labelSplitDevice,
    cancelOrderReminder,
    submitOrderReminder,
    labelOrder,
    labelAdd,
    reminderSplit,
  } = useLang({
    labelCancel: { id: "common_btn_cancel" },
    labelConfirm: { id: "common_btn_confirm" },
    deleteReminder: { id: "order_split_order_zero_reminder" },
    labelSplitDevice: { id: "order_split_device" },
    cancelOrderReminder: { id: "order_cancel_order_reminder" },
    submitOrderReminder: { id: "order_confirm_order_reminder" },
    labelOrder: { id: "common_order" },
    labelAdd: { id: "common_add" },
    reminderSplit: { id: "order_reminder_split_order" },
  });
  // const [infos, setInfos] = React.useState<ISplitOrder>(splitOrderMock);
  const [infos, setInfos] = React.useState<ISplitOrder>({} as ISplitOrder);

  const parentInstallationTaskId = useRecoilValue(orderParentTaskIdState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const [assignAndForwardVisible, setAssignAndForwardVisibleVisible] =
    React.useState(false);
  const { userId, usersRole } = useRecoilValue(userState);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO);
  const [folded, setFold] = React.useState(false);
  const {
    otherProductItems = [],
    splitOrderProductItems = [],
    mainProduct = {} as IMoreDetailItem,
  } = infos;
  const [_splitItems, setSplitItems] = React.useState<ISplitItem[][]>([]);
  const cacheSplitItemRef = React.useRef<ISplitItem[]>([] as ISplitItem[]);
  const [reminder, setReminder] = React.useState<ConfirmReminder>();
  const preSplitPropsRef = React.useRef<ISplitItem[][]>([]);
  const locale = useRecoilValue(localeState);
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO
  );

  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const _reminder = React.useMemo(() => {
    const labelMap = new Map([
      [ConfirmReminder.CancelOrder, cancelOrderReminder],
      [ConfirmReminder.Submit, submitOrderReminder],
      [ConfirmReminder.DeleteSplit, deleteReminder],
    ]);
    return labelMap.get(reminder as ConfirmReminder) ?? "";
  }, [cancelOrderReminder, submitOrderReminder, deleteReminder, reminder]);

  React.useEffect(() => {
    if (isEqual(preSplitPropsRef.current, splitOrderProductItems)) return;
    preSplitPropsRef.current = splitOrderProductItems;
    setSplitItems(splitOrderProductItems);
  }, [splitOrderProductItems]);
  React.useEffect(
    () => getInfos(parentInstallationTaskId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentInstallationTaskId]
  );

  const getInfos = (parentInstallationTaskId: string) => {
    if (!parentInstallationTaskId) return;
    setLoading(true);
    SplitOrderModel.getSplitInfo(parentInstallationTaskId)
      .then((res) => setInfos(res?.data))
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => setLoading(false));
  };

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const cancelOrder = React.useCallback(
    (parentInstallationTaskId: string) => {
      setLoading(true);
      SplitOrderModel.cancelOrder(parentInstallationTaskId)
        .then(backAllTickHandler)
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [backAllTickHandler, setLoading, toastError]
  );

  const submitSplit = React.useCallback(
    (data: ISplitOrderReq) => {
      setLoading(true);
      SplitOrderModel.splitOrder(data)
        .then(backAllTickHandler)
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [backAllTickHandler, setLoading, toastError]
  );

  const splitChangeHandler = React.useCallback(
    (type: SplitType, item?: ISplitItem[]) => {
      const { itemOrderQuantity: total } = mainProduct;
      let copySplitItems = cloneDeep(_splitItems) ?? [];
      const flatted = copySplitItems.flat().filter((item) => item);
      const _mainProduct = flatted.find(({ isMain }) => isMain);
      if (!_mainProduct) return;
      if (type === SplitType.Change && item?.length) {
        const index = flatted.findIndex(
          ({ index }) => index === item?.[0].index
        );
        copySplitItems.splice(index, 1, item);
      }
      if (type === SplitType.Plus) {
        const newItem = {
          ..._mainProduct,
          isMain: false,
          itemOrderQuantity: 1,
          index: copySplitItems.length + 1,
        };
        copySplitItems.push([newItem]);
      }
      if (type === SplitType.Minus) {
        const index = flatted.findIndex(
          ({ index: index }) => index === item?.[0].index
        );
        copySplitItems.splice(index, 1);
      }
      copySplitItems = SplitOrderModel.updateSplitItemMax(
        +total,
        copySplitItems
      );
      setSplitItems(() => copySplitItems);
    },
    [_splitItems, mainProduct]
  );

  const changeHandler = React.useCallback(
    (value: number, item: ISplitItem) => {
      splitChangeHandler(SplitType.Change, [
        { ...item, itemOrderQuantity: value },
      ]);
    },
    [splitChangeHandler]
  );

  const beforeChangeHandler = (pre: number, next: number, item: ISplitItem) => {
    if (next === 0) {
      cacheSplitItemRef.current = [{ ...item, itemOrderQuantity: next }];
      setReminder(ConfirmReminder.DeleteSplit);
      return BeforeChangeType.Cancel;
    }
    return BeforeChangeType.Ok;
  };

  const moreActionHandler = React.useCallback((value: ButtonMore) => {
    if (value === ButtonMore.CANCEL_ORDER) {
      setReminder(ConfirmReminder.CancelOrder);
      return;
    }
    setAssignAndForwardVisibleVisible(true);
  }, []);

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setReminder(undefined);
      if (type === "cancel") {
        return;
      }
      if (reminder === ConfirmReminder.DeleteSplit) {
        splitChangeHandler(SplitType.Minus, cacheSplitItemRef.current);
      }

      if (reminder === ConfirmReminder.CancelOrder) {
        cancelOrder(parentInstallationTaskId);
      }

      if (reminder === ConfirmReminder.Submit) {
        submitSplit({
          parentInstallationTaskId,
          splitOrderProductItems: _splitItems as {
            id: string;
            itemOrderQuantity: number;
          }[][],
        });
      }
    },
    [
      _splitItems,
      parentInstallationTaskId,
      reminder,
      cancelOrder,
      splitChangeHandler,
      submitSplit,
    ]
  );

  const submitOrderHandler = () => {
    setReminder(ConfirmReminder.Submit);
  };

  return {
    otherProductItems,
    mainProduct,
    folded,
    labelOrder,
    _splitItems,
    labelAdd,
    labelSplitDevice,
    reminderSplit,
    labelCancel,
    labelConfirm,
    rolePage,
    _reminder,
    assignAndForwardVisible,
    userId,
    parentInstallationTaskId,
    locale,
    pageStatus,
    confirmHandler,
    beforeChangeHandler,
    changeHandler,
    setFold,
    splitChangeHandler,
    submitOrderHandler,
    backAllTickHandler,
    moreActionHandler,
    setAssignAndForwardVisibleVisible,
  };
}
