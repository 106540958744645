/**
 * HeaderBar component is only fit for wise in PC web portal, it will be enhanced in feature to support multiple extending
 */

import classNames from "classnames";
import React from "react";
import { ErrorDetectorMediumView } from "../ErrorDecorator/ErrorDecorator";
import WiseLogo from "../svg/WiseLogo";
import styles from "./HeaderBar.module.scss";

export interface HeaderBarProps {
  title?: string | React.ReactNode;
  logo?: React.ReactNode;
  className?: string;
  headerSubItems?: React.ReactNode;
  onHomeClick?: VoidFunction;
}

const HeaderBar: React.FC<HeaderBarProps> = ErrorDetectorMediumView(
  (props: HeaderBarProps) => {
    const { title, logo, className, headerSubItems, onHomeClick } = props;
    const _className = classNames(styles.headerBar, className);

    const logoView = React.useMemo(() => {
      const _logo = (
        <span className={styles.logo}>
          <WiseLogo size="auto" />
        </span>
      );
      return _logo || logo;
    }, [logo]);

    const titleView = React.useMemo(
      () => <div className={styles.title}>{title}</div>,
      [title]
    );

    return (
      <div className={_className}>
        <div className={styles.tileAndLogo} onClick={() => onHomeClick?.()}>
          {logoView}
          {titleView}
        </div>
        <div className={styles.subItem}>{headerSubItems}</div>
      </div>
    );
  }
);

export default HeaderBar;
