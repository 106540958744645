import { Checkbox, CheckboxProps } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React, { FormEvent } from "react";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./CheckBoxWrapper.module.scss";

export interface CheckboxWrapperProps extends Omit<CheckboxProps, "onChange"> {
  onChange: (checked: boolean) => void;
}

const CheckBoxWrapper: React.FC<CheckboxWrapperProps> = ErrorDetectorSmallView(
  (props: CheckboxWrapperProps) => {
    const { className, checked, disabled, onChange, ...rest } = props;
    const [stateChecked, setChecked] = React.useState(checked);
    const boxCls = classNames(styles.box, {
      [styles.boxActive]: stateChecked,
    });
    const wrapperCls = classNames(styles.checkboxWrapper, {
      [styles.checkboxWrapperDisabled]: props.disabled,
    });
    const _className = classNames(styles.checkbox, className);

    React.useEffect(() => setChecked(checked), [checked]);

    const onChangeHandler = (target: FormEvent<HTMLInputElement>) => {
      const { checked } = target?.currentTarget ?? {};
      setChecked(checked);
      isFunction(onChange) && onChange(checked);
    };
    const clickHandler = () => {
      if (disabled) return;
      setChecked((pre) => !pre);
      isFunction(onChange) && onChange(!stateChecked);
    };

    return (
      <div className={wrapperCls}>
        <div className={boxCls} onClick={clickHandler} />
        <Checkbox
          {...rest}
          checked={stateChecked}
          onChange={onChangeHandler}
          className={_className}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default CheckBoxWrapper;
