import classNames from "classnames";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import FoldCabinetVariantPc from "../../../../../../components/FoldCabinet/FoldCabinetVariantPc";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import InfoSalesModel from "../../../InfoSalesModel";
import useRequestPartner, {
  OrderRequestPartnerProps,
} from "../../../hooks/useRequestPartner";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./OrderRequestPartnerView.module.scss";

const OrderRequestPartnerView: React.FC<OrderRequestPartnerProps> =
  ErrorDetectorMediumView((props: OrderRequestPartnerProps) => {
    const {
      partnerTitle,
      visible,
      stateInfo,
      orderStepSummary,
      locale,
      setVisible,
    } = useRequestPartner(props);

    const partnerInfoView = React.useMemo(() => {
      const keys = [
        InfoSalesModel.SALE_EMPLOYEE,
        InfoSalesModel.SERVICE_SUPERVISOR,
        InfoSalesModel.RSM_SITE_SURVEY,
        InfoSalesModel.RSM_INSTALLATION,
        InfoSalesModel.FSE_INSTALLATION,
        InfoSalesModel.FSE_SITE_SURVEY,
        InfoSalesModel.SERVICE_COORDINATOR,
        InfoSalesModel.SALE_MANAGER,
        InfoSalesModel.AP_SUPERVISOR,
        InfoSalesModel.AP_ENGINEER,
      ];
      const viewList = InfoSalesPcModel.getStepAuthorModels(
        stateInfo,
        orderStepSummary
      ).filter(({ key }) => keys.find((item) => item === key));
      const listView = viewList.map(({ key, props, viewType }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.partnerItem,
          }}
        />
      ));

      return <div className={styles.partnerInfoView}>{listView}</div>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStepSummary, stateInfo, locale]);

    const partnerMoreDetail = React.useMemo(() => {
      const keys = [
        InfoSalesModel.SALE_MANAGER,
        InfoSalesModel.AP_SUPERVISOR,
        InfoSalesModel.AP_ENGINEER,
      ];
      const viewList = InfoSalesPcModel.getStepAuthorModels(
        stateInfo,
        orderStepSummary
      ).filter(({ key }) => keys.find((item) => item === key));
      const view = viewList.map(({ key, props, viewType }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.partnerItem,
          }}
        />
      ));
      return (
        <FoldCabinetVariantPc
          status={visible}
          onChange={(visible) => setVisible(visible)}
          cabinetClassName={classNames({ [styles.cabinetCls]: visible })}
        >
          <div className={styles.moreDetailContainer}>{view}</div>
        </FoldCabinetVariantPc>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateInfo, orderStepSummary, visible, locale, setVisible]);

    return (
      <div className={styles.orderRequestPartnerView}>
        <div className={styles.title}>{partnerTitle}</div>
        {partnerInfoView}
        {/* {partnerMoreDetail} */}
      </div>
    );
  });

export default OrderRequestPartnerView;
