import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import PhoneCall from "../../../../components/PhoneCall/PhoneCall";
import { Action, Feature } from "../../../../services/user.service";
import { formatDate } from "../../../../utils/commonUtils";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import Text from "../../../components/Text/Text";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { Role, RsmAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import CommentsView from "../../components/CommentView/CommentsView";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import styles from "./SiteSurveyRsm.module.scss";
import SiteSurveyRsmModel from "./SiteSurveyRsmModel";
import useSiteSurveyRsm from "./useSiteSurveyRsm";

const SiteSurveyRsmMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    preInstallInfo,
    orgs,
    rolePage,
    searchVisible,
    searchType,
    userId,
    expectedDate,
    show3rdParty,
    setVisible,
    backToAllTicket,
    assignAndForwardHandler,
    assignOrForwardHandler,
  } = useSiteSurveyRsm();

  const contactView = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getContactInfoModel(preInstallInfo);

    return (
      <div className={styles.contactView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.MOBILE_PHONE,
              {
                ...props,
                children: <PhoneCall phoneNumber={props?.children} />,
              },
            ],
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;
          const component = (
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...newProps,
                key,
              }}
            />
          );
          return component;
        })}
      </div>
    );
  }, [preInstallInfo]);

  const siteSurveyView = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getOrgInfoModel(
      preInstallInfo?.partnerTeamOtherInfo,
      orgs
    );
    const { expectedStartDate } = preInstallInfo ?? {};
    const dateStr = formatDate(expectedStartDate);
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            key,
          }}
        />
      );
      return component;
    });

    return (
      <div className={styles.siteSurveyView}>
        {orgView}
        <div>
          <Text label={expectedDate}>{dateStr}</Text>
          {show3rdParty && (
            <SiteSurveyAccessory
              accessories={(preInstallInfo?.thirdParty as any) ?? {}}
            />
          )}
        </div>
      </div>
    );
  }, [preInstallInfo, orgs, expectedDate, show3rdParty]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyRsmModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
      [
        RsmAction.FORWARD,
        { onClick: assignOrForwardHandler.bind(null, Action.FORWARD) },
      ],
      [
        RsmAction.ASSIGN,
        { onClick: assignOrForwardHandler.bind(null, Action.ASSIGN) },
      ],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
  }, [rolePage, assignOrForwardHandler, backToAllTicket]);

  return (
    <div className={styles.siteSurveyRsm}>
      <div className={styles.pageContainer}>
        {contactView}
        {siteSurveyView}
        <CommentsView />
      </div>
      {buttonViews}
      {searchVisible && (
        <AssignOrForwardUser
          visible={searchVisible}
          onClose={(visible) => setVisible(visible)}
          onActionResponse={assignAndForwardHandler}
          action={searchType as Action}
          feature={Feature.SITE_SURVEY}
          role={searchType === Action.FORWARD ? Role.ROLE_RSM : Role.ROLE_FSE}
          searchQuery={{ rsmUserId: userId }}
          actionData={{
            selectUserIdKey:
              searchType === Action.FORWARD
                ? "rsmRBACCorrelationId"
                : "fseRBACCorrelationId",
          }}
        />
      )}
    </div>
  );
});

export default SiteSurveyRsmMobile;
