import { Button } from "@zeiss/ods-components-react";
import React from "react";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { FseAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationFse.module.scss";
import InstallationFseModel from "./InstallationFseModel";
import useInstallationFse from "./useInstallationFse";

const InstallationFsePc: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemReginOrg,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitEnable,
    rejectVisible,
    rejectTitle,
    submitVisible,
    labelSubmitReminder,
    platform,
    rejectHandler,
    setReject,
    setSubmit,
    confirmHandler,
    changeHandler,
    backAllTickHandler,
  } = useInstallationFse();

  const installationTimeInfoPcView = React.useMemo(() => {
    const list = InstallationFseModel.getInfoViewPcModel(
      infos as Partial<IInstallation>,
      systemReginOrg,
      rolePage?.pageStatus
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(0, splitIndex);

    const datePickKeys = [
      InstallationModel.ACTUAL_INSTALL_END_DATE,
      InstallationModel.ACTUAL_INSTALL_START_DATE,
    ];
    const views = splitList.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
            variantClassName:
              key === InstallationModel.ACTUAL_INSTALL_START_DATE
                ? styles.actualInstallStartDate
                : styles.actualInstallEndDate,
          }
        : key === InstallationModel.PLANNED_INSTALL_END_DATE
        ? {
            ...props,
          }
        : props;

      return (
        <div className={styles.viewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
  }, [infos, rolePage?.pageStatus, systemReginOrg, changeHandler]);

  const installationOwnerInfoPcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationFseModel.getInfoViewPcModel(
      infos as Partial<IInstallation>,
      systemReginOrg,
      rolePage?.pageStatus
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(splitIndex);
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;
    const views = splitList.map(({ viewType, props, key }) => {
      const _viewItem =
        key === InstallationModel.WARRANTY_PERIOD ? (
          <div className={styles.viewItemFull}>
            <ViewComponent type={viewType as ViewType} props={props} />
            {!!warrantyPeriod && (
              <div className={styles.warrantyPeriodReminder}>
                {warrantyPeriodReminder}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.viewItem} key={key}>
            <ViewComponent type={viewType as ViewType} props={props} />
          </div>
        );

      return _viewItem;
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
  }, [infos, rolePage?.pageStatus, systemReginOrg]);

  const filesPcView = React.useMemo(() => {
    const list = InstallationFseModel.getFileInfoViewModel(
      infos as IInstallation,
      rolePage?.pageStatus,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === InstallationModel.INSTALLATION_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.filesPcView}>{views}</div>;
  }, [infos, rolePage?.pageStatus, platform, changeHandler]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  const buttonsView = React.useMemo(() => {
    const list = InstallationFseModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<FseAction, Record<string, any>>([
      [FseAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [FseAction.REJECT, { onClick: () => setReject(true) }],
      [FseAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === FseAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
  }, [rolePage, submitEnable, backAllTickHandler, setReject, setSubmit]);

  return (
    <section className={styles.installationFsePc}>
      <div className={styles.pageContentPc}>
        {installationTimeInfoPcView}
        {installationOwnerInfoPcView}
        <OrderDnInfoView {...(infos.dnInfo as IDNInfo)} />
        {filesPcView}
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          type={ConfirmIconType.WARNING}
          onConfirm={rejectHandler}
        />
      )}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={submitVisible}
        title={labelSubmitReminder}
        onConfirm={confirmHandler}
      />
    </section>
  );
});

export default InstallationFsePc;
