import React from "react";

import { isString } from "../../../utils/isType";
import Fail from "../../svg/Fail";
import Info from "../../svg/Info";
import Suc from "../../svg/Suc";
import Warning from "../../svg/Warning";
import { ConfirmVariant, IConfirmVariantProps } from "./ConfirmVariant";
import styles from "./ConfirmVariant.module.scss";

export enum ConfirmIconType {
  SUCCESS = "success",
  FAIL = "fail",
  WARNING = "warning",
  INFO = "info",
}

export interface IConfirmVariantWithIcon extends IConfirmVariantProps {
  type?: ConfirmIconType;
}

export const ConfirmVariantWithIcon: React.FC<IConfirmVariantWithIcon> = (
  props: IConfirmVariantWithIcon
) => {
  const { type, title, ...rest } = props;
  const icon = React.useMemo(() => {
    const iconMap = new Map([
      [ConfirmIconType.INFO, <Info key={ConfirmIconType.INFO} size={"auto"} />],
      [
        ConfirmIconType.SUCCESS,
        <Suc key={ConfirmIconType.SUCCESS} size={"auto"} />,
      ],
      [
        ConfirmIconType.WARNING,
        <Warning key={ConfirmIconType.WARNING} size={"auto"} />,
      ],
      [ConfirmIconType.FAIL, <Fail key={ConfirmIconType.FAIL} size={"auto"} />],
    ]);

    return (
      iconMap.get(type as ConfirmIconType) ?? (
        <Info key={ConfirmIconType.INFO} size={"auto"} />
      )
    );
  }, [type]);

  const titleView = React.useMemo(() => {
    if (React.isValidElement(title) && !isString(title)) return title;
    return (
      <div className={styles.titleWithIcon}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.titleContent}>{title}</div>
      </div>
    );
  }, [title, icon]);

  return <ConfirmVariant {...rest} title={titleView} />;
};
