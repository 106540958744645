import classNames from "classnames";
import React, { FC, useEffect, useMemo } from "react";
import { isFunction } from "../../utils/isType";
import type { ImageProps } from "../ImageViewer";
import { PDFIcon } from "../PdfViewer/PDFIcon";
import PdfViewer from "../PdfViewer/PdfViewer";
import { ExcelIcon } from "../WordViewer/ExcelIcon";
import { WordIcon } from "../WordViewer/WordIcon";
import WordViewer from "../WordViewer/WordViewer";
import ImgPreviewer from "./ImagePreview/ImagePreview";
import { PreviewAndDownload } from "./PreviewAndDownloadModal";
import { FileType, TaskStatus } from "./Upload";
import styles from "./Upload.module.scss";
import { identifyFileType } from "./utils";

type Props = {
  onClick?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  onError?: (fileType: FileType, error: any) => void;
  deletable: boolean;
  deleteIcon: React.ReactNode;
  url?: string;
  file?: File;
  status?: TaskStatus;
  imageFit: ImageProps["fit"];
  fileType?: FileType;
  name?: string;
  actionType?: PreviewAndDownload;
  activeIndex?: number;
  previewIndex?: number;
  [key: string]: any;
};

const PreviewItem: FC<Props> = (props) => {
  const {
    url,
    file,
    deletable,
    deleteIcon,
    onDelete = () => {},
    onClose = () => {},
    onError = () => {},
    fileType,
    actionType,
    activeIndex,
    previewIndex,
    name,
  } = props ?? {};

  const src = useMemo(() => {
    if (url) {
      return url;
    }
    if (file && !url && fileType === FileType.IMAGE) {
      return URL.createObjectURL(file);
    }
    return "";
  }, [url, file, fileType]);

  const _fileType = useMemo(() => {
    if (!file && url) return fileType ?? FileType.OTHER;
    return identifyFileType(file as File);
  }, [fileType, file, url]);

  useEffect(() => {
    return () => {
      if (file && !url && fileType === FileType.IMAGE) URL.revokeObjectURL(src);
    };
  }, [src, file, fileType]);

  function renderLoading() {
    return (
      props.status === TaskStatus.PENDING && (
        <div className={styles["upload-cell-mask"]} />
      )
    );
  }

  function renderDelete() {
    return (
      deletable && (
        <span className={styles["upload-cell-delete"]} onClick={onDelete}>
          {deleteIcon}
        </span>
      )
    );
  }

  const errorHandler = (fileType: FileType, err: any) => {
    isFunction(onError) && onError(fileType, err);
  };

  const renderContent = () => {
    if (_fileType === FileType.IMAGE)
      return (
        <ImgPreviewer
          url={src}
          actionType={actionType}
          activeIndex={activeIndex}
          previewIndex={previewIndex}
          onThumbClick={props?.onClick}
          onClose={onClose}
          onError={(err: any) => errorHandler(FileType.IMAGE, err)}
        />
      );
    else if (_fileType === FileType.PDF)
      return (
        <PdfViewer
          fileType={_fileType}
          file={src ? src : file}
          fileThumb={{ thumb: <PDFIcon /> }}
          actionType={actionType}
          activeIndex={activeIndex}
          previewIndex={previewIndex}
          onThumbClick={props?.onClick}
          onClose={onClose}
          onError={(err: any) => errorHandler(FileType.PDF, err)}
        />
      );
    else if (_fileType === FileType.WORD)
      return (
        <WordViewer
          fileType={_fileType}
          file={src ? src : file}
          fileThumb={{ thumb: <WordIcon /> }}
          actionType={actionType}
          activeIndex={activeIndex}
          previewIndex={previewIndex}
          onThumbClick={props?.onClick}
          onClose={onClose}
          onError={(err: any) => errorHandler(FileType.WORD, err)}
        />
      );
    else if (_fileType === FileType.EXCEL)
      return (
        <PdfViewer
          fileType={_fileType}
          file={src ? src : file}
          fileThumb={{ thumb: <ExcelIcon /> }}
          onThumbClick={props?.onClick}
        />
      );
    else
      return (
        <PdfViewer
          fileType={_fileType}
          file={src ? src : file}
          onThumbClick={props?.onClick}
        />
      );
  };

  return (
    <div
      className={classNames(
        styles["upload-cell"],
        props?.status === TaskStatus.FAIL && styles["upload-cell-fail"]
      )}
    >
      {renderContent()}
      <div className={classNames(styles["upload-file-name"])}>
        {file?.name ?? props?.name}
      </div>
      {renderLoading()}
      {renderDelete()}
    </div>
  );
};

export default PreviewItem;
