import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import RcPagination, { PaginationProps } from "rc-pagination";
import "rc-pagination/assets/index.css";
import RcSelect, { SelectProps } from "rc-select";
import "rc-select/assets/index.less";
import { DefaultOptionType } from "rc-select/lib/Select";
import React from "react";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./Pagination.module.scss";

export interface IPagination extends PaginationProps {
  iconClassName?: string;
  /**Replace value to build label */
  totalLabel?: string;
  /**Replace value to build label */
  pageChangeLabel?: string;
}

export const Pagination: React.FC<IPagination> = ErrorDetectorSmallView(
  (props: IPagination) => {
    const {
      className,
      iconClassName,
      total,
      pageSize,
      totalLabel,
      pageSizeOptions,
      pageChangeLabel,
      prevIcon,
      nextIcon,
      selectComponentClass,
      onChange,
      showTotal,
      ...rest
    } = props;
    const _className = classNames(styles.pagination, className);
    const [iconDisabled, setIcon] = React.useState<{
      preDisabled: boolean;
      nextDisabled: boolean;
    }>({ preDisabled: false, nextDisabled: false });
    const preClassName = classNames({
      [styles.disabled]: iconDisabled.preDisabled,
      iconClassName,
    });
    const nextClassName = classNames({
      [styles.disabled]: iconDisabled.preDisabled,
      iconClassName,
    });

    const _showTotal = (total: number): React.ReactNode => {
      const label = totalLabel?.replace("value", `${total}`);
      return <span>{label}</span>;
    };

    const _preIcon = React.useMemo(
      () =>
        prevIcon || (
          <Icon icon={"ChevronBack"} size={12} className={preClassName} />
        ),
      [prevIcon, preClassName]
    );

    const _nextIcon = React.useMemo(
      () =>
        nextIcon || (
          <Icon icon={"ChevronNext"} size={12} className={nextClassName} />
        ),
      [nextIcon, nextClassName]
    );

    const SelectComponentClass = (
      props: SelectProps<number, DefaultOptionType>
    ) => {
      const { className, value, ...rest } = props;
      const _className = classNames(styles.select, className);
      const options =
        pageSizeOptions?.map((item) => ({
          label: pageChangeLabel
            ? pageChangeLabel.replace("value", `${item}`)
            : item,
          value: item,
          children: undefined,
        })) || [];
      const _value = pageChangeLabel
        ? pageChangeLabel.replace("value", `${value}`)
        : value;
      const [visible, setVisible] = React.useState(false);
      const selectIcon = classNames(styles.selectIcon, {
        [styles.selectActive]: visible,
      });

      return (
        <RcSelect
          {...rest}
          className={_className}
          options={options}
          value={_value as number}
          onDropdownVisibleChange={(visible) => setVisible(visible)}
          suffixIcon={
            <Icon icon="ChevronExpand" size={16} className={selectIcon} />
          }
        />
      );
    };

    SelectComponentClass.Option = RcSelect.Option;

    const _onChange = (page: number, pageSize: number): void => {
      isFunction(onChange) && onChange(page, pageSize);
      if (page === 1) {
        setIcon((pre) => ({ ...pre, preDisabled: true }));
      }
      if (pageSize * page >= +(total || 0)) {
        setIcon((pre) => ({ ...pre, nextDisabled: true }));
      }
    };

    return (
      <RcPagination
        {...rest}
        total={total}
        className={_className}
        selectComponentClass={selectComponentClass || SelectComponentClass}
        prevIcon={_preIcon}
        nextIcon={_nextIcon}
        onChange={_onChange}
        showTotal={showTotal || _showTotal}
      />
    );
  }
);

Pagination.defaultProps = {
  showSizeChanger: true,
  defaultPageSize: 10,
  defaultCurrent: 5,
  total: 500,
  hideOnSinglePage: true,
  pageSizeOptions: [5, 10, 20, 50],
  totalLabel: "Total value items",
  pageChangeLabel: "value / Page",
};
