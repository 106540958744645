export enum OrderStep {
  SPLIT_ORDER = -1,
  SITE_SURVEY_SUBMIT = 1,
  SUBMIT_SITE_SURVEY_ASSIGN = 2,

  SUBMIT_SITE_SURVEY_IMPLEMENT = 3,
  SUBMIT_SITE_SURVEY_IMPLEMENT_IN_PROGRESS = 31,
  SUBMIT_SITE_SURVEY_IMPLEMENT_IN_COMPLETE = 32,

  SUBMISSION = 4,

  AUDIT = 5,
  ASSIGN = 6,

  INSTALL = 7,
  INSTALL_IN_PROGRESS = 71,
  INSTALL_COMPLETE = 72,

  TRAINING_ASSIGN = 8,

  TRAINING_IMPLEMENT = 9,
  TRAINING_IMPLEMENT_IN_PROGRESS = 91,
  TRAINING_IMPLEMENT_COMPLETE = 92,

  FAC_UPLOAD = 10,
  FAC_UPLOAD_LM = 101,
  FAC_UPLOAD_EM = 102,

  SO_CONFIRM = 11,
}

export enum OrderStepStatus {
  PENDING = 1,
  RESOLVE = 2,
  REJECT = 3,
  NOT_START = 4,
  NOT_REQUIRED = 5,
}
