import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ErrorDetectorMediumView } from "../../../../../components/ErrorDecorator/ErrorDecorator";
import { SystemPlatform } from "../../../../../core/SystemService";
import {
  orderTaskIdState,
  platformState,
  userState,
} from "../../../../../store";
import { getCssVariable } from "../../../../../utils/commonUtils";
import { isFunction, isNumber } from "../../../../../utils/isType";
import HomeModel from "../../../../Home/HomeModel";
import FoldCabinet from "../../../../components/FoldCabinet/FoldCabinet";
import useLang from "../../../../hooks/useLang";
import { PagePaths } from "../../../../service/pageType";
import SplitOrderModel, {
  ISplitSubOrder,
} from "../../SplitOrder/SplitOrderModel";
import styles from "./SubOrders.module.scss";

export interface ISubOrders {
  linkPath?: string;
  onLink?: (installationTaskId: string) => void;
  /** The max number for subOrders in normal */
  max?: number;
}

const DEFAULT_MAX = 3;

export const SubOrders: React.FC<ISubOrders> = ErrorDetectorMediumView(
  (props: ISubOrders) => {
    const { onLink, max = DEFAULT_MAX } = props;
    const { labelLinkedOrders, labelOrderSubOrder, labelAction, labelView } =
      useLang({
        labelLinkedOrders: { id: "order_linker_sub_order" },
        labelOrderSubOrder: { id: "order_sub_order" },
        labelAction: { id: "common_action" },
        labelView: { id: "order_list_table_view" },
      });
    const [subOrders, setOrders] = React.useState<ISplitSubOrder[]>([]);
    const installationTaskId = useRecoilValue(orderTaskIdState);
    const { id } = useRecoilValue(userState);
    const [visible, setVisible] = React.useState(false);
    const [beforeList, afterList] = React.useMemo(
      () => splitListByMax(subOrders, max),
      [max, subOrders]
    );
    const platform = useRecoilValue(platformState);
    const navigate = useNavigate();

    React.useEffect(() => {
      if (!installationTaskId || !id) return;
      getSubOrders(installationTaskId, id);
    }, [installationTaskId, id]);

    const getSubOrders = (installationTaskId: string, userId: string) => {
      SplitOrderModel.getLinkedOrderList(installationTaskId, userId).then(
        (res) => setOrders(res.data)
      );
    };

    const linkHandler = React.useCallback(
      (order: ISplitSubOrder) => {
        const { installationTaskId, accessible } = order;
        if (!accessible) return;
        if (isFunction(onLink)) {
          onLink(installationTaskId);
        }
        const search = `?${HomeModel.QUERY_INSTALLATION_TASK_ID}=${installationTaskId}`;
        navigate(
          {
            pathname: PagePaths.PATH_ORDER_REQUEST,
            search,
          },
          { replace: true }
        );
      },
      [navigate, onLink]
    );

    const linkMobileView = (order: ISplitSubOrder) => {
      const fill = getCssVariable("--color-interaction-link");
      const _className = classNames(styles.linkView, {
        [styles.cursePointer]: order.accessible,
      });
      return (
        <div
          className={_className}
          key={order.subsStandardOrder}
          onClick={linkHandler.bind(null, order)}
        >
          <div className={styles.order}>{order.subsStandardOrder}</div>
          {order.accessible && (
            <Icon
              icon={"ArrowRight"}
              size={16}
              fill={fill}
              className={styles.icon}
            />
          )}
        </div>
      );
    };

    const linkPcView = (order: ISplitSubOrder) => {
      const fill = getCssVariable("--fill-color-body");
      const _className = classNames(styles.linkView, {
        [styles.cursePointer]: order.accessible,
      });
      return (
        <div
          className={_className}
          key={order.subsStandardOrder}
          onClick={linkHandler.bind(null, order)}
        >
          <div className={styles.order}>{order.subsStandardOrder}</div>
          {order.accessible && (
            <Icon
              icon={"ChevronNext"}
              size={16}
              className={styles.icon}
              fill={fill}
            />
          )}
        </div>
      );
    };

    const linkView = React.useCallback(
      (order: ISplitSubOrder) => {
        return platform === SystemPlatform.ENV_PC
          ? linkPcView(order)
          : linkMobileView(order);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [platform]
    );

    const beforeView = React.useMemo(
      () => beforeList.map((item) => linkView(item)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [beforeList]
    );

    const convertTableData = (
      data: ISplitSubOrder
    ): { operation: React.ReactNode } & ISplitSubOrder => {
      const { accessible } = data;
      const operationView = accessible ? (
        <span className={styles.linked} onClick={linkHandler.bind(null, data)}>
          {labelView}
        </span>
      ) : null;

      return {
        ...data,
        operation: operationView,
      };
    };

    const afterView = React.useMemo(() => {
      return afterList.map((item) => linkView(item));

      // const column: { key: string; label: string }[] = [
      //   {
      //     key: InfoSalesModel.SUB_STANDARD_ORDER,
      //     label: labelOrderSubOrder,
      //   },
      //   { key: InfoSalesModel.OPERATION, label: labelView },
      // ];
      // const _data = afterList.map((item) => convertTableData(item));
      // const pcView = <Table columns={column} data={_data} />;

      // return platform === SystemPlatform.ENV_PC ? pcView : mobileView;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [afterList]);

    const moreDetailView = React.useMemo(() => {
      const _className = classNames(styles.foldCabinet, {
        [styles.foldCabinetVariantPc]: platform === SystemPlatform.ENV_PC,
      });
      const _labelClassName = classNames({
        [styles.labelClassName]: platform === SystemPlatform.ENV_PC,
      });

      return (
        <FoldCabinet
          status={visible}
          onChange={(visible) => setVisible(visible)}
          className={_className}
          labelClassName={_labelClassName}
        >
          <div className={styles.moreDetailContainer}>{afterView}</div>
        </FoldCabinet>
      );
    }, [platform, visible, afterView]);

    return (
      !!subOrders.length && (
        <div className={styles.subOrders}>
          <div className={styles.labelLinkedOrders}>{labelLinkedOrders}</div>
          <div className={styles.linkedOrders}>
            {beforeView}
            {!!afterList.length && moreDetailView}
          </div>
        </div>
      )
    );
  }
);

// add comma to avoid parsing error in tsx.
const splitListByMax = <T,>(list: T[], max?: number): [T[], T[]] => {
  if (!Array.isArray(list) || !Array.length) return [[], []];
  if (!isNumber(max)) return [list, []];
  const before = list.slice(0, max),
    after = list.slice(max);

  return [before, after];
};
