interface IconProps {
  size?: "auto" | "default";
}

export default function (props: IconProps) {
  const { size } = props;
  const width = size === "auto" ? "100%" : "22",
    height = size === "auto" ? "100%" : "22";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5119 12.6085L13.3767 21H2C1.17157 21 0.5 20.3284 0.5 19.5V5.5C0.5 4.67157 1.17157 4 2 4H12.4414L11.5031 12.4448L11.4939 12.5274L11.5119 12.6085Z"
        fill="white"
        stroke="#0072EF"
      />
      <path
        d="M22 2.5C22 1.39543 21.1046 0.5 20 0.5H12L8 18.5H20C21.1046 18.5 22 17.6046 22 16.5V2.5Z"
        fill="#0072EF"
      />
      <path
        d="M12.42 7.216H15.498V7.774H13.074V9.022H15.354V9.58H13.074V10.942H15.6V11.5H12.42V7.216ZM17.8058 8.314C18.5738 8.314 18.9638 8.734 18.9638 9.586V11.5H18.3278V9.64C18.3278 9.112 18.0818 8.848 17.6018 8.848C17.4218 8.848 17.2598 8.908 17.1278 9.04C16.9837 9.184 16.8937 9.382 16.8698 9.634V11.5H16.2337V8.398H16.8698V8.782C16.9897 8.626 17.1278 8.512 17.2838 8.434C17.4398 8.35 17.6138 8.314 17.8058 8.314Z"
        fill="white"
      />
      <path
        d="M5.157 9.732H5.843V10.943H8.335V14.324H7.67V13.925H5.843V16.27H5.157V13.925H3.337V14.324H2.672V10.943H5.157V9.732ZM3.337 13.274H5.157V11.594H3.337V13.274ZM5.843 13.274H7.67V11.594H5.843V13.274Z"
        fill="#0072EF"
      />
    </svg>
  );
}
