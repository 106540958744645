import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { formatDate } from "../../../../utils/commonUtils";
import FileUploadVariant from "../../../components/FileUploadVariant/FileUploadVariant";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import TextVariantPc from "../../../components/TextVariantPc/TextVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel from "../../model/OrderRequestModel";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import SiteSurveyModel from "../model/SiteSurveyModel";
import styles from "./SiteSurveyAll.module.scss";
import SiteSurveyAllModel from "./SiteSurveyAllModel";
import useSiteSurveyAll from "./useSiteSurveyAll";

const SiteSurveyAllPc: React.FC = ErrorDetectorPageView(() => {
  const {
    preInstallInfo,
    orgs,
    expectedDate,
    labelActualSiteSurveyDate,
    labelSiteSurveyReport,
    platform,
    locale,
    siteSurveyDone,
    backToAllTicket,
  } = useSiteSurveyAll();

  const contactPcView = React.useMemo(() => {
    const list = SiteSurveyAllModel.getContactInfoModel(
      preInstallInfo,
      platform
    );

    return (
      <div className={styles.contactPcView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;
          return (
            <div className={styles.pcViewItem} key={key}>
              <ViewComponent
                type={viewType as ViewType}
                props={{
                  ...newProps,
                  key,
                }}
              />
            </div>
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, preInstallInfo, locale]);

  const siteSurveyPcView = React.useMemo(() => {
    const list = SiteSurveyAllModel.getOrgInfoModel(
      preInstallInfo?.partnerTeamOtherInfo,
      orgs,
      platform
    );
    const { expectedStartDate, actuarySiteSurveyDate, isSiteSurveyDone } =
      preInstallInfo ?? {};
    const expectedStr = formatDate(expectedStartDate),
      actualStr = formatDate(actuarySiteSurveyDate),
      siteSurveyDoneStr = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <div className={styles.pcViewItem} key={key}>
          <ViewComponent
            type={viewType as ViewType}
            props={{
              ...props,
              key,
            }}
          />
        </div>
      );
      return component;
    });

    return (
      <div className={styles.siteSurveyPcView}>
        {orgView}
        {expectedStr && (
          <div className={styles.pcViewItem}>
            <TextVariantPc
              key={InfoSalesModel.EXPECTED_START_DATE}
              label={expectedDate}
            >
              {expectedStr}
            </TextVariantPc>
          </div>
        )}
        {actualStr && (
          <div className={styles.pcViewItem}>
            <TextVariantPc
              key={SiteSurveyModel.ACTUAL_SITE_SURVEY_DATE}
              label={labelActualSiteSurveyDate}
            >
              {actualStr}
            </TextVariantPc>
          </div>
        )}
        {siteSurveyDoneStr && (
          <div className={styles.pcViewItem}>
            <TextVariantPc label={siteSurveyDone}>
              {siteSurveyDoneStr}
            </TextVariantPc>
          </div>
        )}
        <div className={styles.thirdParty}>
          <SiteSurveyAccessory
            accessories={(preInstallInfo?.thirdParty as any) ?? {}}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preInstallInfo,
    orgs,
    platform,
    expectedDate,
    labelActualSiteSurveyDate,
    locale,
  ]);

  const siteSurveyReportPcView = React.useMemo(() => {
    const show = !!preInstallInfo?.siteSurveyReports?.length;
    return (
      show && (
        <div className={styles.siteSurveyReportPcView}>
          <FileUploadVariant
            variantClassName={styles.uploadCls}
            title={labelSiteSurveyReport}
            value={preInstallInfo?.siteSurveyReports}
            deletable={false}
            disableUpload
          />
        </div>
      )
    );
  }, [labelSiteSurveyReport, preInstallInfo?.siteSurveyReports]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyAllModel.getButtonViewModel();
    const actionHandler = new Map<RsmAction, Record<string, any>>([
      [RsmAction.ALL_TICKET, { onClick: backToAllTicket }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div className={styles.siteSurveyAllPc}>
      <div className={styles.pageContentPc}>
        {contactPcView}
        {siteSurveyPcView}
        {siteSurveyReportPcView}
      </div>
      {buttonViews}
    </div>
  );
});

export default SiteSurveyAllPc;
