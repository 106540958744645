import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import NoPermission from "../../assets/svg/NoPermission";
import NotFound from "../../assets/svg/NotFound";
import { ErrorDetectorPageView } from "../../components/ErrorDecorator/ErrorDecorator";
import { PageError } from "../../core/ErrorCodeService";
import { SystemPlatform } from "../../core/SystemService";
import { useTitle } from "../../hooks";
import { errorPageState, platformState } from "../../store";
import useLang from "../hooks/useLang";
import styles from "./ErrorPage.module.scss";

export interface ErrorPageProps {
  pageCode?: PageError;
}

const ErrorPage: React.FC<ErrorPageProps> = ErrorDetectorPageView(
  (props: ErrorPageProps) => {
    const { pageCode } = props;
    const errorPageCode = pageCode || useRecoilValue(errorPageState);
    const { noPermission, error, pageNotFound, noPermissionPhd } = useLang({
      noPermission: { id: "error_no_permission" },
      error: { id: "common_msg_error" },
      pageNotFound: { id: "common_msg_path_not_found" },
      noPermissionPhd: { id: "common_no_permission" },
    });
    const platform = useRecoilValue(platformState);
    const _className = classNames(styles.errorPage, {
      [styles.pagePc]: platform === SystemPlatform.ENV_PC,
    });

    const title = React.useMemo(() => {
      const titleMap = new Map<PageError, string>([
        [PageError.ERROR_403, noPermission],
        [PageError.ERROR_404, pageNotFound],
        [PageError.ERROR_500, error],
      ]);

      return titleMap.get(errorPageCode as PageError) ?? pageNotFound;
    }, [error, errorPageCode, noPermission, pageNotFound]);

    const text = React.useMemo(() => {
      const textMap = new Map<PageError, string>([
        [PageError.ERROR_403, noPermissionPhd],
        [PageError.ERROR_404, pageNotFound],
        [PageError.ERROR_500, error],
      ]);

      return textMap.get(errorPageCode as PageError) ?? "";
    }, [error, errorPageCode, noPermissionPhd, pageNotFound]);

    useTitle(title);

    return React.useMemo(() => {
      const pageViews = new Map<PageError, React.ReactNode>([
        [PageError.ERROR_403, <NoPermission key={PageError.ERROR_403} />],
        [PageError.ERROR_404, <NoPermission key={PageError.ERROR_404} />],
        [PageError.ERROR_500, <NotFound key={PageError.ERROR_500} />],
      ]);

      const view = pageViews.get(errorPageCode as PageError) ?? <></>;
      return (
        errorPageCode && (
          <section className={_className}>
            {view}
            <div className={styles.text}>{text}</div>
          </section>
        )
      );
    }, [_className, errorPageCode, text]);
  }
);

export default ErrorPage;
