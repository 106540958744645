import PageWrapper from "../../../../layout/PageWrapper";
import InstallationApSupervisorMobile from "./InstallationApSupervisorMobile";
import InstallationApSupervisorPc from "./InstallationApSupervisorPc";

export default () => (
  <PageWrapper
    PageMobile={InstallationApSupervisorMobile}
    PagePc={InstallationApSupervisorPc}
  />
);
