import classNames from "classnames";
import React from "react";
import styles from "./ViewWrapper.module.scss";

export enum AppType {
  TYPE_PC = "pc",
  TYPE_MOBILE = "mobile",
}

export enum LabelIndicator {
  TYPE_REQUIRED = "required",
  TYPE_OPTIONAL = "optional",
  TYPE_CUSTOM = "custom",
}

export enum LabelIndicatorIcon {
  TYPE_DOT = "dot",
  TYPE_STAR = "star",
}

export interface IBasicViewProps {
  label?: string;
  viewType?: AppType;
  indicator?: LabelIndicator;
  indicatorSymbol?: LabelIndicatorIcon;
  indicatorCustom?: React.ReactElement;
  className?: string;
  containerClassName?: string;
  labelTextClassName?: string;
  labelClassName?: string;
  punctuation?: string;
  [key: string]: any;
  labelClick?: () => void;
  textClick?: () => void;
}

export const BasicView: React.FC<IBasicViewProps> = (
  props: IBasicViewProps
) => {
  const {
    label = "",
    viewType,
    className,
    labelClassName,
    labelTextClassName,
    containerClassName,
    indicator = LabelIndicator.TYPE_OPTIONAL,
    indicatorSymbol = LabelIndicatorIcon.TYPE_STAR,
    indicatorCustom,
    children,
    punctuation,
  } = props;
  const {
    basicView,
    basicViewPc,
    basicViewMobile,
    basicViewContainer,
    basicViewLabel,
    labelText,
    labelIndicator,
    indicatorCls,
    indicatorDot,
    indicatorStar,
    indicatorStarPc,
  } = styles;
  const appCls = viewType === AppType.TYPE_PC ? basicViewPc : basicViewMobile,
    viewCls = `${basicView} ${appCls} ${className}`,
    containerCls = `${basicViewContainer} ${containerClassName}`;

  const labelView = React.useMemo(() => {
    const labelCls = `${basicViewLabel} ${labelClassName}`;
    const labelStr = `${label}`;
    const indicatorSymbolCls =
      indicatorSymbol === LabelIndicatorIcon.TYPE_DOT
        ? indicatorDot
        : classNames(indicatorStar, {
            [indicatorStarPc]: viewType === AppType.TYPE_PC,
          });
    const labelIndicatorCls = `${labelIndicator} ${indicatorSymbolCls}`;
    const indicatorIconView = <div className={labelIndicatorCls} />;
    const indicatorView = indicatorCustom ? indicatorCustom : indicatorIconView;
    const showIndicator = indicator !== LabelIndicator.TYPE_OPTIONAL;
    const labelTextCls = `${labelText} ${labelTextClassName}`;

    return (
      <div className={labelCls}>
        <span className={labelTextCls} title={labelStr}>
          {labelStr}
        </span>
        {showIndicator && <div className={indicatorCls}>{indicatorView}</div>}
        {punctuation && <span>{punctuation}</span>}
      </div>
    );
  }, [
    basicViewLabel,
    labelClassName,
    label,
    indicatorSymbol,
    indicatorDot,
    indicatorStar,
    indicatorStarPc,
    viewType,
    labelIndicator,
    indicatorCustom,
    indicator,
    labelText,
    labelTextClassName,
    indicatorCls,
    punctuation,
  ]);

  return (
    <div className={viewCls}>
      {labelView}
      <div className={containerCls}>{children}</div>
    </div>
  );
};
