import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../../components/Confirmor/Confirmor";
import { LocaleLang } from "../../../../../core/LocaleService";
import {
  isFinalGoodsRecipChangeState,
  localeState,
  orderTaskIdState,
  platformState,
  stepState,
  userState,
} from "../../../../../store";
import useLang from "../../../../hooks/useLang";
import { OrderStep } from "../../../../service/OrderStepTypes";
import { Role } from "../../../../service/userRoleTypes";
import {
  IFinalGoodsReceipt,
  ViewItemModel,
} from "../../../model/OrderRequestModel";
import InfoSalesModel, { FinalGoodsRecipStatus } from "../InfoSalesModel";
import InfoSalesPcModel from "../InfoSalesPc/InfoSalesPcModel";

export interface hookFinalGoodsRecip {
  title: string;
  finalGoodRecip: IFinalGoodsReceipt;
  editable: boolean;
  revise: string;
  newLabel: string;
  finalGoodRecipNew: IFinalGoodsReceipt;
  visible: boolean;
  models: Partial<ViewItemModel>[];
  locale: LocaleLang;
  updateHandler: (type: FinalGoodsRecipStatus) => void;
  confirmHandler: (type: CloseType) => void;
}

const initFinalGoodsReceipt = (
  infos: IFinalGoodsReceipt[],
  isNew: boolean
): IFinalGoodsReceipt => {
  if (!Array.isArray(infos)) return {} as IFinalGoodsReceipt;
  return (
    infos?.find((item) => !!item?.isNew == isNew) ?? ({} as IFinalGoodsReceipt)
  );
};

export default function (): hookFinalGoodsRecip {
  const { revise } = useLang({ revise: { id: "common_btn_revise" } });
  const [visible, setVisible] = React.useState(false);
  const { title, newLabel } = useLang({
    title: { id: "order_final_goods_recipe" },
    newLabel: { id: "common_new" },
  });
  const [models, setModels] = React.useState<Partial<ViewItemModel>[]>([]);
  const [finalGoodRecip, setFinalGoodRecip] = React.useState(
    () => ({} as IFinalGoodsReceipt)
  );
  const [finalGoodRecipNew, setFinalGoodRecipNew] = React.useState(
    () => ({} as IFinalGoodsReceipt)
  );
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const { usersRole } = useRecoilValue(userState);
  const setChange = useSetRecoilState(isFinalGoodsRecipChangeState);
  const locale = useRecoilValue(localeState);
  const platform = useRecoilValue(platformState);
  const { key } = useRecoilValue(stepState);
  const supportEdit = [
    OrderStep.SITE_SURVEY_SUBMIT,
    OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
    OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
    OrderStep.SUBMISSION,
  ];
  const editable = usersRole === Role.ROLE_SALES && supportEdit.includes(key);

  const getFinalGoodsReceipt = React.useCallback((id: string) => {
    InfoSalesModel.getFinalGoodsRecip(id).then((res) => {
      const { data } = res ?? {};
      setFinalGoodRecip(initFinalGoodsReceipt(data, false));
      setFinalGoodRecipNew(initFinalGoodsReceipt(data, true));
    });
  }, []);

  React.useEffect(() => {
    getFinalGoodsReceipt(installationTaskId);
  }, [getFinalGoodsReceipt, installationTaskId]);

  const getModels = React.useCallback(
    (type: FinalGoodsRecipStatus) => {
      const addModel = InfoSalesPcModel.getPartnerModifyItem(
        finalGoodRecip as IFinalGoodsReceipt,
        platform
      ) as Partial<ViewItemModel>[];
      const updateModels = InfoSalesPcModel.getPartnerModifyItem(
        finalGoodRecipNew as IFinalGoodsReceipt,
        platform
      );
      return type === FinalGoodsRecipStatus.UPDATE ? updateModels : addModel;
    },
    [finalGoodRecip, finalGoodRecipNew, platform]
  );

  const updateHandler = React.useCallback(
    (type: FinalGoodsRecipStatus) => {
      const models = getModels(type);
      setModels(models);
      setVisible(true);
    },
    [getModels]
  );

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setVisible(false);
      if (type === "cancel") return;
      getFinalGoodsReceipt(installationTaskId);
      setChange(true);
    },
    [getFinalGoodsReceipt, installationTaskId, setChange]
  );

  return {
    title,
    finalGoodRecip,
    editable,
    revise,
    newLabel,
    finalGoodRecipNew,
    visible,
    models,
    locale,
    updateHandler,
    confirmHandler,
  };
}
