import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PagePaths, PageSubItems } from "../../../service/pageType";
import FacIBaseModel, { IFacIBase } from "../FacIBaseModel";

export default () => {
  const [facIBaseInfo, setInfo] = React.useState<Partial<IFacIBase>>(
    {} as Partial<IFacIBase>
  );
  const { userId } = useRecoilValue(userState);
  const platform = useRecoilValue(platformState);
  const navigate = useNavigate();
  const [errCode, setError] = React.useState<ErrorCode>();
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [submitVisible, setSubmit] = React.useState(false);
  const [buttonBlock, setBlock] = React.useState(false);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC);
  const locale = useRecoilValue(localeState);
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  const {
    labelCancel,
    labelConfirm,
    labelSubmitReminder,
    labelInstallDateReminder,
  } = useLang({
    labelCancel: { id: "common_btn_cancel" },
    labelConfirm: { id: "common_btn_confirm" },
    labelSubmitReminder: { id: "order_confirm_order_reminder" },
    labelInstallDateReminder: { id: "order_install_date_reminder" },
  });
  const submitEnable = React.useMemo(
    () => FacIBaseModel.verifyRsmSubmitFacIBase(facIBaseInfo),
    [facIBaseInfo]
  );

  React.useEffect(() => {
    if (!installationTaskId) return;
    getInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const backToAllTicket = React.useCallback(() => {
    navigate(PagePaths.PATH_ORDER_LIST, { replace: true });
  }, [navigate]);

  const submitInfo = React.useCallback(() => {
    const { facCacReports, acceptanceDate } = facIBaseInfo;

    const submitData = {
      installationTaskId,
      facCacReportRBACCorrelationId: userId,
      facCacReports: facCacReports as any[],
      acceptanceDate,
    };
    setLoading(true);
    setBlock(true);

    return FacIBaseModel.submitFacIBaseInfo(submitData as any)
      .then(backToAllTicket)
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      })
      .finally(() => {
        setBlock(false);
        setLoading(false);
      });
  }, [
    facIBaseInfo,
    installationTaskId,
    userId,
    setLoading,
    backToAllTicket,
    toastError,
  ]);

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setSubmit(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      setLoading(true);
      setBlock(true);
      submitInfo()
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backToAllTicket();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [setLoading, submitInfo, backToAllTicket, toastError]
  );

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      setLoading(true);
      FacIBaseModel.getFacIBaseInfo(installationTaskId, locale)
        .then((res) => {
          setInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const changeHandler = React.useCallback(
    (key: keyof IFacIBase, value: any) => {
      setInfo((pre) => ({ ...pre, [key]: value }));
    },
    []
  );

  return {
    facIBaseInfo,
    rolePage,
    installationTaskId,
    userId,
    labelCancel,
    labelConfirm,
    labelSubmitReminder,
    labelInstallDateReminder,
    submitEnable,
    submitVisible,
    buttonBlock,
    platform,
    locale,
    setSubmit,
    confirmHandler,
    backToAllTicket,
    setLoading,
    toastError,
    navigate,
    changeHandler,
  };
};
