import React from "react";
import { getCssVariable } from "../../../../utils/commonUtils";
import StepVariant, {
  IStepItem,
  IStepVariant,
} from "../../../components/StepVariant/StepVariant";
import useLang from "../../../hooks/useLang";
import { OrderStepStatus } from "../../../service/OrderStepTypes";
import styles from "./StepVariantProcess.module.scss";

export enum ProcessAction {
  SUBMIT = 1,
  ASSIGN = 2,
  FORWARD = 3,
  APPROVE = 4,
  REJECT = 5,
  UPDATE = 6,
  CONFIRM = 7,
  DELETE_ORDER = 8,
  NEED_SITE_SURVEY = 9,
}

export interface IStepItemProcess extends Omit<IStepItem, "subChildren"> {
  status: OrderStepStatus;
  statusLabel?: string;
  action?: ProcessAction;
  author?: string;
  comment?: string;
  time?: number;
}

export interface IStepVariantProcess extends Omit<IStepVariant, "steps"> {
  steps: IStepItemProcess[];
}

const StepVariantProcess: React.FC<IStepVariantProcess> = (
  props: IStepVariantProcess
) => {
  const { steps, ...rest } = props;
  const {
    labelSubmit,
    labelAssign,
    labelForward,
    labelApprove,
    labelReject,
    labelConfirm,
    labelCancelOrder,
    labelNeedSiteSurvey,
    labelAction,
    labelOperator,
    labelReason,
  } = useLang({
    labelSubmit: { id: "common_btn_submit" },
    labelAssign: { id: "common_btn_assign" },
    labelForward: { id: "common_btn_forward" },
    labelApprove: { id: "common_btn_approve" },
    labelReject: { id: "common_btn_reject" },
    labelConfirm: { id: "common_btn_confirm" },
    labelCancelOrder: { id: "common_btn_cancel_order" },
    labelNeedSiteSurvey: { id: "order_need_site_survey" },
    labelAction: { id: "common_action" },
    labelOperator: { id: "common_operator" },
    labelReason: { id: "common_reason" },
  });

  const getActionLabel = React.useCallback(
    (action: ProcessAction): string => {
      const labelMap = new Map<ProcessAction, string>([
        [ProcessAction.SUBMIT, labelSubmit],
        [ProcessAction.ASSIGN, labelAssign],
        [ProcessAction.FORWARD, labelForward],
        [ProcessAction.APPROVE, labelApprove],
        [ProcessAction.REJECT, labelReject],
        [ProcessAction.CONFIRM, labelConfirm],
        [ProcessAction.DELETE_ORDER, labelCancelOrder],
        [ProcessAction.NEED_SITE_SURVEY, labelNeedSiteSurvey],
      ]);

      return labelMap.get(action) ?? "";
    },
    [
      labelApprove,
      labelAssign,
      labelCancelOrder,
      labelConfirm,
      labelForward,
      labelNeedSiteSurvey,
      labelReject,
      labelSubmit,
    ]
  );

  const mappingSteps = React.useMemo(() => {
    return steps?.map(
      (
        { status, action, author, comment, time, label, statusLabel, ...rest },
        index
      ) => {
        const statusStyle = getStatusStyle(status);
        const actionLabel = getActionLabel(action as ProcessAction);
        const labelView = (
          <div className={styles.labelInfo} key={index}>
            {label}
            <div className={styles.status} style={statusStyle}>
              <span className={styles.statusText}>{statusLabel}</span>
            </div>
          </div>
        );
        const showTime = status !== OrderStepStatus.PENDING && time;

        return {
          ...rest,
          label: labelView,
          subChildren: (
            <div className={styles.subChildren} key={index}>
              {actionLabel && (
                <div className={styles.action}>
                  <span className={styles.itemLabel}>{`${labelAction}: `}</span>
                  <span className={styles.itemValue}>{actionLabel}</span>
                </div>
              )}
              {comment && (
                <div className={styles.action}>
                  <span className={styles.itemLabel}>{`${labelReason}: `}</span>
                  <span className={styles.itemValue}>{comment}</span>
                </div>
              )}
              {author && (
                <div className={styles.author}>
                  <span
                    className={styles.itemLabel}
                  >{`${labelOperator}: `}</span>
                  <span className={styles.itemValue}>{author}</span>
                </div>
              )}
              {showTime && <div className={styles.time}>{time}</div>}
            </div>
          ),
        };
      }
    );
  }, [getActionLabel, labelAction, labelOperator, labelReason, steps]);

  return <StepVariant {...rest} steps={mappingSteps} />;
};

const getStatusStyle = (status: OrderStepStatus) => {
  const activeColor = getCssVariable(
      "--color-interaction-link",
      "rgb(0,114,239)"
    ),
    activeBg = getCssVariable(
      "--color-interaction-disabled",
      "rgba(0, 114, 239, 40%)"
    ),
    pendingColor = getCssVariable(
      "--fill-color-headline-invert",
      "rgb(242,245,248)"
    ),
    inactiveBg = getCssVariable(
      "--background-color-light-04",
      "rgb(230,235,240)"
    ),
    inactiveColor = getCssVariable("--text-color-headline", "rgb(50, 55, 62)");

  const mapping = new Map([
    [
      OrderStepStatus.NOT_START,
      { backgroundColor: inactiveBg, color: inactiveColor },
    ],
    [
      OrderStepStatus.NOT_REQUIRED,
      { backgroundColor: inactiveBg, color: inactiveColor },
    ],
    [OrderStepStatus.REJECT, { backgroundColor: activeBg, color: activeColor }],
    [
      OrderStepStatus.RESOLVE,
      { backgroundColor: activeBg, color: activeColor },
    ],
    [
      OrderStepStatus.PENDING,
      { backgroundColor: activeColor, color: pendingColor },
    ],
  ]);

  return (
    mapping.get(status) ?? { backgroundColor: inactiveBg, color: inactiveColor }
  );
};

export default StepVariantProcess;
