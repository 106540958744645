import type { DependencyList, EffectCallback } from "react";
import { useEffect, useState } from "react";
import type { DebounceOptions } from "./useDebounce";
import useDebounceFn from "./useDebounceFn";
import useUpdateEffect from "./useUpdateEffect";

function useDebounceEffect(
  effect: EffectCallback,
  deps?: DependencyList,
  options?: DebounceOptions
) {
  const [flag, setFlag] = useState({});

  const { run } = useDebounceFn(() => {
    setFlag({});
  }, options);

  useEffect(() => {
    return run();
  }, deps);

  useUpdateEffect(effect, [flag]);
}

export default useDebounceEffect;
