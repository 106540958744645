export default {
  common_no: "No",
  common_comments: "Comments",
  common_edit: "Edit",
  common_btn_all_tickets: "All Tickets",
  common_btn_forward: "Forward",
  common_btn_assign: "Assign",
  common_btn_reject: "Reject",
  common_btn_submit: "Submit",
  common_btn_delete: "Delete",
  common_btn_approve: "Approve",
  common_btn_more: "More",
  common_btn_add_site_survey: "Need Site Survey",
  common_on_going: "On Going",
  common_completed: "Completion",
  common_not_start: "Not Start",
  common_msg_success: "Successed",
  common_msg_server_error: "Server error",
  common_msg_path_not_found: "Page Not Found",
  common_info_title: "Info.",
  common_msg_error: "Error",
  common_site_survey_title: "Site Survey",
  common_install_title: "Installation",
  common_fac_title: "FAC/IBase",
  common_btn_revise: "Revise",
  common_btn_cancel: "Cancel",
  common_btn_confirm: "Confirm",
  common_new: "New",
  common_yes: "Yes",
  common_btn_cancel_order: "Cancel Order",
  common_not_required: "Not Required",
  common_yes_upper: "YES",
  common_no_upper: "NO",
  common_inner_error: "Internal Error",
  common_action: "Action",
  common_operator: "Operator",
  common_reason: "Reason",
  common_logout_reminder: "Are you sure to logout ?",
  common_shirk: "Collapse",
  common_order: "Order ",
  common_add: "Add",
  common_cancel_upper: "CANCEL",
  common_ok_upper: "OK",
  common_others: "Others",
  common_page_title_dashboard: "Dashboard",
  common_pc_wise_title: "RMS Site Survey&Installation",
  common_btn_search: "Search",
  common_number: "Number",
  common_comments_phd: "Please type comments",
  common_empty_phd: "No Content",
  common_no_permission: "No Permission, please contact administrator",
  common_pagination_total_item: "Total value Items",
  common_pagination_per_page: "value / Page",
  common_page_title_ticket_management: "Ticket Management",
  common_btn_reset: "Reset",
  common_btn_recover: "Recover",
};
