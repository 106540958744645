import { AxiosPromise } from "axios";
import { HttpRequestMethod, NormalRes, Response } from "../core/HttpService";
import axios from "../utils/axios";

export interface AuthTokenRes extends Response<string> {}

export const getAuthToken = (
  code: string,
  redirectUrl?: string
): AxiosPromise<AuthTokenRes> => {
  const url = "/wise/v1/token";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
    params: { code, redirectUrl },
  });
};

export const testApi = (): AxiosPromise<AuthTokenRes> => {
  const url = `/wise/test`;
  return axios.request({ method: HttpRequestMethod.METHOD_GET, url });
};

export const getCSRFtoken = (): AxiosPromise<AuthTokenRes> => {
  const url = "/wise/antiforgery/token";
  return axios.request({
    method: HttpRequestMethod.METHOD_GET,
    url,
  });
};

export const logout = (): AxiosPromise<NormalRes> => {
  const url = "/wise/v1/logout";
  return axios.request({
    method: HttpRequestMethod.METHOD_POST,
    url,
  });
};
