import { OrderStep } from "./OrderStepTypes";
import { Role } from "./userRoleTypes";

export enum PagePaths {
  PATH_DEFAULT = "/",
  PATH_HOME = "/",
  PATH_ORDER_REQUEST = "/orderRequest",
  PATH_ORDER_LIST = "/orderList",
  PATH_CONFIG = "/config",
  PATH_DASHBOARD = "/dashboard",
  PATH_ADMIN = "/admin",
  PATH_ERROR_PAGE = "/errorPage",
  PATH_ANY = "*",
}

export enum PageNames {
  PAGE_HOME = "home",
  PAGE_ORDER_REQUEST = "orderRequest",
  PAGE_ORDER_LIST = "orderList",
  PAGE_CONFIG = "config",
  PAGE_DASHBOARD = "dashboard",
  PAGE_ADMIN = "admin",
}

export enum PageSubItems {
  PAGE_ORDER_REQUEST_SUB_ORDER_INFO = "orderRequest.orderInfo",
  PAGE_ORDER_REQUEST_SUB_SITE_SURVEY = "orderRequest.siteSurvey",
  PAGE_ORDER_REQUEST_SUB_INSTALLATION = "orderRequest.installation",
  PAGE_ORDER_REQUEST_SUB_FAC = "orderRequest.fac",
}

export enum PageStatus {
  EDIT = "edit",
  READONLY = "readOnly",
}

export interface SubTabs {
  subItem: PageSubItems;
  pageStatus: PageStatus;
  actions: string[];
}

export interface OrderRequestPage {
  role: Role;
  state: OrderStep;
  subTabs: SubTabs[];
}

export interface IPageStructure {
  pageName: PageNames;
  pagePath: PagePaths;
  pageSubItems: OrderRequestPage[];
}
