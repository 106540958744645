import HttpService, { NormalRes } from "../../../../core/HttpService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  IApEngineerRejectTrainingReq,
  IApEngineerSubmitTrainingReq,
  StaticSourceItem,
  apEngineerRejectTraining,
  apEngineerSubmitTraining,
} from "../../../../services/order.service";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import { ViewType } from "../../../components/ViewTypes";
import { SubTabs } from "../../../service/pageType";
import { ApEngineerAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import { PageStatus } from "./../../../service/pageType";

export default class InstallationApEngineerModel {
  static apEngineerRejectTraining = (
    data: IApEngineerRejectTrainingReq
  ): Promise<NormalRes> => {
    return HttpService.splitResponse(apEngineerRejectTraining(data));
  };

  static apEngineerSubmitTraining = (
    data: IApEngineerSubmitTrainingReq
  ): Promise<NormalRes> => {
    const formattedData = {
      ...data,
      trainingReports: OrderRequestModel.formatUploadItem(
        data?.trainingReports as any[]
      ),
    };
    return HttpService.splitResponse(apEngineerSubmitTraining(formattedData));
  };

  static verifySubmission = (infos: Partial<IInstallation>) => {
    const keys = [
      InstallationModel.ACTUAL_TRAINING_START_DATE,
      InstallationModel.ACTUAL_TRAINING_END_DATE,
      InstallationModel.TRAINING_REPORTS,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static getTimeViewMobileModel = (
    info: Partial<IInstallation>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const {
      labelPlanTrainingStartTime,
      labelPlanTrainingEndTime,
      labelActualTrainingEndTime,
      labelActualTrainingStartTime,
    } = InstallationModel.getLabels();
    const {
      planedTrainingStartDate,
      planedTrainingEndDate,
      actualTrainingStartDate,
      actualTrainingEndDate,
    } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const planedTrainingStartDateLabel = formatDate(
        planedTrainingStartDate as number
      ),
      planedTrainingEndDateLabel = formatDate(planedTrainingEndDate as number),
      formattedActualTrainingStartDate = formatDatePickValue(
        actualTrainingStartDate as number
      ),
      formattedActualTrainingEndDate = formatDatePickValue(
        actualTrainingEndDate as number
      );

    return [
      {
        key: InstallationModel.PLAN_TRAINING_START_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanTrainingStartTime,
          children: planedTrainingStartDateLabel,
          key: InstallationModel.PLAN_TRAINING_START_DATE,
        },
      },
      {
        key: InstallationModel.PLAN_TRAINING_END_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanTrainingEndTime,
          children: planedTrainingEndDateLabel,
          key: InstallationModel.PLAN_TRAINING_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_START_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelActualTrainingStartTime,
          value: formattedActualTrainingStartDate,
          key: InstallationModel.ACTUAL_TRAINING_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_END_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelActualTrainingEndTime,
          value: formattedActualTrainingEndDate,
          key: InstallationModel.ACTUAL_TRAINING_END_DATE,
          min: formattedActualTrainingStartDate,
          disabled,
        },
      },
    ];
  };

  static getTimeViewPcModel = (
    info: Partial<IInstallation>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const {
      labelPlanTrainingStartTime,
      labelPlanTrainingEndTime,
      labelActualTrainingEndTime,
      labelActualTrainingStartTime,
    } = InstallationModel.getLabels();
    const {
      planedTrainingStartDate,
      planedTrainingEndDate,
      actualTrainingStartDate,
      actualTrainingEndDate,
    } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const planedTrainingStartDateLabel = formatDate(
        planedTrainingStartDate as number
      ),
      planedTrainingEndDateLabel = formatDate(planedTrainingEndDate as number),
      formattedActualTrainingStartDate = formatDatePickValue(
        actualTrainingStartDate as number
      ),
      formattedActualTrainingEndDate = formatDatePickValue(
        actualTrainingEndDate as number
      );

    return [
      {
        key: InstallationModel.PLAN_TRAINING_START_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanTrainingStartTime,
          children: planedTrainingStartDateLabel,
          key: InstallationModel.PLAN_TRAINING_START_DATE,
        },
      },
      {
        key: InstallationModel.PLAN_TRAINING_END_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelPlanTrainingEndTime,
          children: planedTrainingEndDateLabel,
          key: InstallationModel.PLAN_TRAINING_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_START_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelActualTrainingStartTime,
          value: formattedActualTrainingStartDate,
          key: InstallationModel.ACTUAL_TRAINING_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.ACTUAL_TRAINING_END_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelActualTrainingEndTime,
          value: formattedActualTrainingEndDate,
          key: InstallationModel.ACTUAL_TRAINING_END_DATE,
          minDate: actualTrainingStartDate,
          disabled,
        },
      },
    ];
  };

  static getInfoViewModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      actualInsStartDateLabel = formatDate(actualInstallStartDate as number),
      actualInsEndDateLabel = formatDate(actualInstallEndDate as number),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      );

    const textViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: textViewType,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: textViewType,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: textViewType,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: textViewType,
        props: {
          label: labelActualInstallStartDate,
          children: actualInsStartDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: textViewType,
        props: {
          label: labelActualInstallEndDate,
          children: actualInsEndDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: textViewType,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired ? textViewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: textViewType,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired ? textViewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: textViewType,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return platform === SystemPlatform.ENV_MOBILE
      ? list.filter(({ hidden }) => !hidden)
      : list;
  };

  static getInfoReadonlyViewModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    }
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
      labelSiteSurveyRequired,
      labelSiteSurveyQualified,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
      isSiteSurvey,
      isSiteSurveyDone,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      actualInsStartDateLabel = formatDate(actualInstallStartDate as number),
      actualInsEndDateLabel = formatDate(actualInstallEndDate as number),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      ),
      siteSurveyLabel = OrderRequestModel.getToggleLabel(
        isSiteSurvey as boolean
      ),
      siteSurveyDoneLabel = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );

    const list = [
      {
        key: InstallationModel.SITE_SURVEY_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelSiteSurveyRequired,
          children: siteSurveyLabel,
        },
      },
      {
        key: InstallationModel.IS_SITE_SURVEY_DONE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelSiteSurveyQualified,
          children: siteSurveyDoneLabel,
        },
        hidden: !isSiteSurvey,
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
        hidden: !trainingRequired,
      },

      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelActualInstallStartDate,
          children: actualInsStartDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelActualInstallEndDate,
          children: actualInsEndDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return list.filter(({ hidden, props }) => !hidden && !!props?.children);
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    pageStatus: PageStatus,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      siteSurveyReports,
      disclaimers,
      trainingReports,
      installationReports,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelSiteSurveyReport,
      labelDisclaimer,
      labelInstallationReport,
      labelTrainReports,
      uploadReminder,
    } = InstallationModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    const partProps = {
      disableUpload: disabled,
      deletable: !disabled,
    };
    const reminder = disabled ? "" : uploadReminder;
    const uploadViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;
    const originList = [
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: uploadViewType,
        props: {
          title: labelDisclaimer,
          value: disclaimers ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DISCLAIMERS,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: uploadViewType,
        props: {
          title: labelDeliverNotes,
          value: deliveryNotes ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DELIVERY_NOTES,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: uploadViewType,
        props: {
          title: labelEurOrEuu,
          value: euCs ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.EUC_EUU,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: uploadViewType,
        props: {
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.TECHNICAL_AGR,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: uploadViewType,
        props: {
          title: labelSpecs,
          value: specifications ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SPEC,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: uploadViewType,
        props: {
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SITE_SURVEY_REPORT,
        },
      },
      {
        key: InstallationModel.INSTALLATION_REPORTS,
        viewType: uploadViewType,
        props: {
          title: labelInstallationReport,
          value: installationReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.INSTALLATION_REPORTS,
        },
      },
      {
        key: InstallationModel.TRAINING_REPORTS,
        viewType: uploadViewType,
        props: {
          ...partProps,
          title: labelTrainReports,
          value: trainingReports ?? [],
          key: InstallationModel.TRAINING_REPORTS,
          reminder,
        },
      },
    ];

    const editable = originList.filter(
      ({ props, key }) =>
        key === InstallationModel.TRAINING_REPORTS || !!props?.value?.length
    );

    const readonly = originList.filter(({ props }) => !!props?.value?.length);

    return pageStatus === PageStatus.EDIT ? editable : readonly;
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelSubmit, labelReject } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: ApEngineerAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: ApEngineerAction.ALL_TICKET,
        },
      },
      {
        key: ApEngineerAction.REJECT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelReject,
          key: ApEngineerAction.REJECT,
        },
      },
      {
        key: ApEngineerAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: ApEngineerAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
