import { ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { ViewItemModel } from "../../model/OrderRequestModel";
import { VerifyType } from "../../service/ValidationService";
import SiteSurveyModel, { AccessoryProperties } from "./SiteSurveyModel";

export default class SiteSurveyMobileModel extends SiteSurveyModel {
  static getAirLockItemModifyModels = (
    infos: Partial<AccessoryProperties>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { spec } = infos ?? {};
    const options = SiteSurveyModel.getAirLockOptions();
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: SiteSurveyModel.SPEC,
        viewType: ViewType.VIEW_SELECT,
        props: {
          label: "",
          value: spec,
          options,
          disabled,
        },
        verifyType: [VerifyType.TYPE_NUMBER],
      },
    ];
  };

  static getOtherItemModifyModels = (
    infos: Partial<AccessoryProperties>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { comments } = infos ?? {};
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: SiteSurveyModel.COMMENTS,
        viewType: ViewType.VIEW_TEXTAREA,
        props: {
          label: "",
          value: comments,
          maxLength: 50,
          disabled,
        },
        verifyType: [VerifyType.TYPE_STRING],
      },
    ];
  };

  // support cryo, laser, view3, cold table, CL, energy Spectrum, EBSD,
  static getCryoItemLikeModifyModels = (
    infos: AccessoryProperties,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    return SiteSurveyMobileModel.generateCryoLikeModifyModels(
      infos,
      pageStatus
    );
  };

  static getUPSLikeModifyModels = (
    infos: AccessoryProperties,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { brandAndModel, buildIn, supportInstall } = infos ?? {};
    const { labelBrandAndModel, labelWithBatteryCabinet, labelNeedSupport } =
      SiteSurveyModel.getLabels();
    const needSupportOptions = SiteSurveyModel.getSupportOptions();
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: SiteSurveyModel.BRAND_AND_MODEL,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelBrandAndModel,
          value: brandAndModel,
          maxLength: 100,
          disabled,
        },
      },
      {
        key: SiteSurveyModel.BUILD_IN,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelWithBatteryCabinet,
          value: buildIn,
          maxLength: 100,
          disabled,
        },
      },
      {
        key: SiteSurveyModel.SUPPORT_INSTALL,
        viewType: ViewType.VIEW_SELECT,
        verifyType: [VerifyType.TYPE_NUMBER],
        props: {
          label: labelNeedSupport,
          value: supportInstall,
          options: needSupportOptions,
          disabled,
        },
      },
    ];
  };

  static getVibrationLikeModifyModels = (
    infos: AccessoryProperties,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { brandAndModel, buildIn, supportInstall } = infos ?? {};
    const { labelBrandAndModel, labelWithOutControl, labelNeedSupport } =
      SiteSurveyModel.getLabels();
    const needSupportOptions = SiteSurveyModel.getSupportOptions();
    const disabled = pageStatus === PageStatus.READONLY;

    return [
      {
        key: SiteSurveyModel.BRAND_AND_MODEL,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelBrandAndModel,
          value: brandAndModel,
          disabled,
          maxLength: 100,
        },
      },
      {
        key: SiteSurveyModel.BUILD_IN,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelWithOutControl,
          value: buildIn,
          disabled,
          maxLength: 100,
        },
      },
      {
        key: SiteSurveyModel.SUPPORT_INSTALL,
        viewType: ViewType.VIEW_SELECT,
        verifyType: [VerifyType.TYPE_NUMBER],
        props: {
          label: labelNeedSupport,
          value: supportInstall,
          options: needSupportOptions,
          disabled,
        },
      },
    ];
  };

  private static generateCryoLikeModifyModels = (
    infos: AccessoryProperties,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { brandAndModel, buildIn, installPos, supportInstall } = infos ?? {};
    const {
      labelBrandAndModel,
      labelWithComputer,
      labelInstallPos,
      labelNeedSupport,
    } = SiteSurveyModel.getLabels();
    const needSupportOptions = SiteSurveyModel.getSupportOptions();
    const installPosOptions = SiteSurveyModel.getInstallPosOptions();
    const disabled = pageStatus === PageStatus.READONLY;

    return [
      {
        key: SiteSurveyModel.BRAND_AND_MODEL,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelBrandAndModel,
          value: brandAndModel,
          maxLength: 100,
          disabled,
        },
      },
      {
        key: SiteSurveyModel.BUILD_IN,
        viewType: ViewType.VIEW_INPUT,
        verifyType: [VerifyType.TYPE_STRING, VerifyType.LENGTH_MAX_COMMON],
        props: {
          label: labelWithComputer,
          value: buildIn,
          maxLength: 100,
          disabled,
        },
      },
      {
        key: SiteSurveyModel.INSTALL_POS,
        viewType: ViewType.VIEW_SELECT,
        verifyType: [VerifyType.TYPE_NUMBER],
        props: {
          label: labelInstallPos,
          value: installPos,
          options: installPosOptions,
          disabled,
        },
      },
      {
        key: SiteSurveyModel.SUPPORT_INSTALL,
        viewType: ViewType.VIEW_SELECT,
        verifyType: [VerifyType.TYPE_NUMBER],
        props: {
          label: labelNeedSupport,
          value: supportInstall,
          options: needSupportOptions,
          disabled,
        },
      },
    ];
  };
}
