import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { ErrorRes } from "../../../../core/HttpService";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import FacIBaseModel, { IFacIBase } from "../FacIBaseModel";

export default () => {
  const [facIBaseInfo, setInfo] = React.useState<Partial<IFacIBase>>({});
  const { userId } = useRecoilValue(userState);
  const navigate = useNavigate();
  const [errCode, setError] = React.useState<ErrorCode>();
  const [confirmVisible, setConfirm] = React.useState<boolean>(false);
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const rolePage = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC);
  const [buttonBlock, setBlock] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  const { labelConfirm, labelCancel, labelConfirmReminder, labelDateReminder } =
    useLang({
      labelConfirm: { id: "common_btn_confirm" },
      labelCancel: { id: "common_btn_cancel" },
      labelConfirmReminder: { id: "order_crm_confirm_reminder" },
      labelDateReminder: { id: "order_crm_date_reminder" },
    });

  React.useEffect(() => {
    if (!installationTaskId) return;
    getInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      setLoading(true);
      FacIBaseModel.getFacIBaseInfo(installationTaskId, locale)
        .then((res) => {
          setInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const confirmCloseHandler = React.useCallback(
    (type: CloseType) => {
      setConfirm(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }

      const confirmPromise = FacIBaseModel.crmConfirm({
        installationTaskId,
        sorbacCorrelationId: userId,
      });
      setLoading(true);
      setBlock(true);

      confirmPromise
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res: ErrorRes) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [installationTaskId, userId, setLoading, backAllTickHandler, toastError]
  );

  return {
    facIBaseInfo,
    labelDateReminder,
    visible,
    rolePage,
    labelCancel,
    buttonBlock,
    labelConfirm,
    confirmVisible,
    labelConfirmReminder,
    platform,
    locale,
    setVisible,
    setConfirm,
    backAllTickHandler,
    confirmCloseHandler,
  };
};
