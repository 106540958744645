import React from "react";
import { useRecoilValue } from "recoil";
import { CloseType } from "../../../../../components/Confirmor/Confirmor";
import { LocaleLang } from "../../../../../core/LocaleService";
import {
  localeState,
  orderTaskIdState,
  stepState,
  userState,
} from "../../../../../store";
import { isFunction } from "../../../../../utils/isType";
import useLang from "../../../../hooks/useLang";
import { OrderStep } from "../../../../service/OrderStepTypes";
import { Role } from "../../../../service/userRoleTypes";
import InfoSalesModel, { IContactPerson } from "../InfoSalesModel";

export interface HookContactPerson {
  title: string;
  reminder: string;
  revise: string;
  name: string;
  contactPersonInfo: IContactPerson;
  visible: boolean;
  editable: boolean;
  locale: LocaleLang;
  clickHandler: () => void;
  confirmHandler: (type: CloseType) => void;
}

export interface ContactPersonProps {
  onChange?: (info: IContactPerson) => void;
}

export default function (props: ContactPersonProps): HookContactPerson {
  const { onChange } = props;
  const { revise } = useLang({ revise: { id: "common_btn_revise" } });
  const { title, reminder } = useLang({
    title: { id: "order_contact_person" },
    reminder: { id: "order_contact_person_reminder" },
  });
  const [contactPersonInfo, setInfo] = React.useState<IContactPerson>(
    {} as IContactPerson
  );
  const [visible, setVisible] = React.useState(false);
  const { name } = contactPersonInfo;
  const { usersRole } = useRecoilValue(userState);
  const { key } = useRecoilValue(stepState);
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const supportEdit = [
    OrderStep.SITE_SURVEY_SUBMIT,
    OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
    OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
    OrderStep.SUBMISSION,
  ];
  const editable = usersRole === Role.ROLE_SALES && supportEdit.includes(key);
  const locale = useRecoilValue(localeState);

  const getContactPerson = React.useCallback((id: string) => {
    return InfoSalesModel.getContactPerson(id).then((res) => {
      res?.data && setInfo(res?.data);

      return res?.data;
    });
  }, []);

  React.useEffect(() => {
    getContactPerson(installationTaskId);
  }, [getContactPerson, installationTaskId]);

  const clickHandler = React.useCallback(() => setVisible(true), []);

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setVisible(false);
      if (type === "cancel") return;
      getContactPerson(installationTaskId).then(
        (res) => isFunction(onChange) && onChange(res)
      );
    },
    [getContactPerson, installationTaskId, onChange]
  );

  return {
    title,
    reminder,
    revise,
    name,
    contactPersonInfo,
    visible,
    editable,
    locale,
    clickHandler,
    confirmHandler,
  };
}
