import { OrderStep } from "../OrderStepTypes";
import { OrderRequestPage, PageStatus, PageSubItems } from "../pageType";
import { Role, SalesManagerAction } from "../userRoleTypes";
import { RoleStepPageService } from "./RoleStepPageService";

export default class AdminStatePageService implements RoleStepPageService {
  static readonly STATE_SPLIT_ORDER_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_ADMIN,
    state: OrderStep.SPLIT_ORDER,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
    ],
  };
  private static readonly STATE_SITE_SURVEY_SELECTION_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SITE_SURVEY_SUBMIT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_SELECTION_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SITE_SURVEY_SUBMIT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_SITE_SURVEY_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_ADMIN,
    state: OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_SITE_SURVEY_SUB_ITEMS_REFLOW: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALL_SUBMIT_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_ADMIN,
    state: OrderStep.SUBMISSION,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_INSTALL_AUDIT_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_ADMIN,
    state: OrderStep.AUDIT,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_ORDER_INSTALLATION_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_SUB_ITEMS: OrderRequestPage = {
    role: Role.ROLE_ADMIN,
    state: OrderStep.INSTALL,
    subTabs: [
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
      {
        subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        pageStatus: PageStatus.READONLY,
        actions: [SalesManagerAction.ALL_TICKET],
      },
    ],
  };

  private static readonly STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.TRAINING_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.TRAINING_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_ORDER_FAC_LM_META_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.FAC_UPLOAD,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_CRM_IBASE_CONFIRM_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_ADMIN,
      state: OrderStep.SO_CONFIRM,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [SalesManagerAction.ALL_TICKET],
        },
      ],
    };

  static getRolePageListByStep = (): OrderRequestPage[] => {
    const list = [
      AdminStatePageService.STATE_SPLIT_ORDER_SUB_ITEMS,
      AdminStatePageService.STATE_SITE_SURVEY_SELECTION_SUB_ITEMS,
      AdminStatePageService.STATE_SITE_SURVEY_ASSIGN_SUB_ITEMS,
      AdminStatePageService.STATE_SITE_SURVEY_SUB_ITEMS,
      AdminStatePageService.STATE_INSTALL_SUBMIT_SUB_ITEMS,
      AdminStatePageService.STATE_INSTALL_AUDIT_SUB_ITEMS,
      AdminStatePageService.STATE_ORDER_INSTALLATION_ASSIGN_SUB_ITEMS,
      AdminStatePageService.STATE_INSTALLATION_SUB_ITEMS,
      AdminStatePageService.STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS,
      AdminStatePageService.STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS,
      AdminStatePageService.STATE_ORDER_FAC_LM_META_SUB_ITEMS,
      AdminStatePageService.STATE_CRM_IBASE_CONFIRM_SUB_ITEMS,
    ];

    return list;
  };
}
