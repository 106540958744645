import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import Table from "../../../../components/Table/Table";
import { SystemPlatform } from "../../../../core/SystemService";
import FoldCabinetVariantPc from "../../../components/FoldCabinet/FoldCabinetVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { SoAction } from "../../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../../Installation/installationModel";
import FacIBaseModel, { IBaseInfo, IFacIBase } from "../FacIBaseModel";
import styles from "./FacIBaseAll.module.scss";
import useFacIBaseAll from "./useFacIBaseAll";

const FacIBaseAllPc: React.FC = ErrorDetectorPageView(() => {
  const {
    facIBaseInfo,
    visible,
    rolePage,
    platform,
    locale,
    setVisible,
    backAllTickHandler,
  } = useFacIBaseAll();

  const renderDateView = React.useCallback(
    (ibaseInfo: IBaseInfo) => {
      const list = FacIBaseModel.getCRMDateInfoViewModel(ibaseInfo, platform);

      const view = list.map(({ key, viewType, props }) => {
        if (key === FacIBaseModel.IBASE_NUMBER) {
          const _label = props?.children
            ? `${props?.label} (${props?.children})`
            : props?.label;
          return (
            <div className={styles.ibaseTitlePc} key={key}>
              {_label}
            </div>
          );
        }
        return (
          <div className={styles.viewItemPc} key={key}>
            <ViewComponent type={viewType as ViewType} props={props} />
          </div>
        );
      });

      return (
        <div className={styles.viewPc} key={ibaseInfo.iBaseNumber}>
          {view}
        </div>
      );
    },
    [platform]
  );

  const InfoPcView = React.useMemo(() => {
    const { ibaseInfos, ownerInstallationFseName } = facIBaseInfo;
    if (!Array.isArray(ibaseInfos) || !ibaseInfos.length) return null;
    const [ibase, ...rest] = ibaseInfos;
    const columns = FacIBaseModel.getIBaseItemColumns();
    const { labelOwnerInstallationFse } = FacIBaseModel.getLabels();
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    return (
      <div className={styles.infoPcView}>
        <div>{renderDateView(ibase)}</div>

        {!!rest?.length && (
          <FoldCabinetVariantPc
            status={visible}
            onChange={(visible) => setVisible(visible)}
            cabinetClassName={styles.cabinetClassName}
          >
            <div className={styles.moreDetailContainer}>
              <Table columns={columns} data={rest} />
            </div>
          </FoldCabinetVariantPc>
        )}
        {ownerInstallationFseName && (
          <ViewComponent
            type={viewType}
            props={{
              label: labelOwnerInstallationFse,
              children: ownerInstallationFseName,
            }}
          />
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, visible, locale, renderDateView, setVisible]);

  const facDatePcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = facIBaseInfo;
    const list = FacIBaseModel.getFacInfoReadonlyViewModel(
      facIBaseInfo as Partial<IFacIBase>,
      platform
    );
    const views = list.map(({ viewType, props, key }) => {
      const propsMap = new Map([
        [
          FacIBaseModel.WARRANTY_PERIOD,
          {
            ...props,
            className: styles.warrantyPeriod,
          },
        ],
      ]);
      const _props = propsMap.get(key as string) ?? props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.facDatePcView}>
        {views}
        <div className={styles.warrantyPeriodReminder}>
          {warrantyPeriodReminder}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, platform, locale]);

  const facFileUploadPcView = React.useMemo(() => {
    const list = FacIBaseModel.getFacFileInfoReadonlyViewModel(
      facIBaseInfo as IFacIBase,
      platform
    );
    const views = list.map(({ viewType, key, props }) => {
      return (
        <div className={styles.uploadPcItem} key={key}>
          <ViewComponent key={key} type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return (
      !!list?.length && <div className={styles.facFilePcView}>{views}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facIBaseInfo, rolePage?.pageStatus, platform, locale]);

  const buttonsView = React.useMemo(() => {
    const list = FacIBaseModel.getSoButtonViewModel(rolePage);
    const actionHandler = new Map<SoAction, Record<string, any>>([
      [SoAction.ALL_TICKET, { onClick: backAllTickHandler }],
    ]);

    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale]);

  return (
    <div className={styles.facIbaseAllPc}>
      <div className={styles.pageContentPc}>
        {InfoPcView}
        {facDatePcView}
        {facFileUploadPcView}
      </div>
      {buttonsView}
    </div>
  );
});

export default FacIBaseAllPc;
