import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isEqual } from "lodash";
import RcDropDown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { DropdownProps } from "rc-dropdown/lib/Dropdown";
import React, { FormEvent } from "react";
import { InputBasicWrapperProps } from "../../../pages/components/InputBasicVariant/InputBasicVariant";
import { getCssVariable } from "../../../utils/commonUtils";
import { isFunction, isNumber, isString } from "../../../utils/isType";
import { ErrorDetectorSmallView } from "../../ErrorDecorator/ErrorDecorator";
import {
  LabelIndicator,
  LabelIndicatorIcon,
} from "../../ViewWrapper/ViewWrapper";
import { InputBasic } from "../InputBasic/InputBasic";
import styles from "./InputVariantDropDown.module.scss";

export interface IDropDownOption {
  value: string | number;
  text: string | number | React.ReactNode;
  children?: React.ReactNode;
  subText?: string | number | React.ReactNode;
}

export interface IInputVariantDropDownProps
  extends Omit<DropdownProps, "children">,
    Omit<InputBasicWrapperProps, "onChange"> {
  placeholder?: string;
  containerClassName?: string;
  options?: IDropDownOption[];
  value?: string | number;
  disabled?: boolean;
  search?: boolean;
  onChange?: (value: string | number) => void;
}

export const InputVariantDropDown: React.FC<IInputVariantDropDownProps> =
  ErrorDetectorSmallView((props: IInputVariantDropDownProps) => {
    const {
      containerClassName,
      value,
      options,
      disabled,
      visible,
      placeholder,
      search,
      onVisibleChange,
      onChange,
      ...rest
    } = props;
    const [_value, setValue] = React.useState(() => value);
    const [_visible, setVisible] = React.useState(() => visible);
    const [text, setText] = React.useState<string>(
      () =>
        options?.find(({ value: _value }) => value === _value)?.text as string
    );
    const [stateOptions, setOptions] = React.useState(() => options ?? []);
    const preOptionsRef = React.useRef(options);
    const _containerClassName = classNames(
      styles.inputVariantDropDown,
      containerClassName
    );
    const inputClassName = classNames(styles.inputSelect, {
      [styles.searchInput]: search,
    });

    React.useEffect(() => {
      if (isEqual(preOptionsRef?.current, options)) return;
      setOptions(options ?? []);
      preOptionsRef.current = options;
    }, [options]);

    React.useEffect(() => {
      setValue(value);
      const text = options?.find(({ value: _value }) => value === _value)
        ?.text as string;
      setText(text);
    }, [options, value]);

    React.useEffect(() => setVisible(visible), [visible]);

    const selectHandler = React.useCallback(
      (value: string | number) => {
        setValue(value);
        const { text } =
          stateOptions?.find(({ value: _value }) => value === _value) ?? {};
        setText(text as string);
        setVisible(false);
        isFunction(onChange) && onChange(value);
      },
      [onChange, stateOptions]
    );

    const visibleChangeHandler = React.useCallback(
      (visible: boolean) => {
        isFunction(onVisibleChange) && onVisibleChange(visible);
        setVisible(visible);
      },
      [onVisibleChange]
    );

    const inputChangeHandler = React.useCallback(
      (evt: FormEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        setText(value);
        const formatted = value?.trim().toLowerCase();
        if (!formatted) {
          setOptions(options ?? []);
          return;
        }
        const filteredOptions = options
          ?.map((item) => {
            const { text, subText } = item;
            const _text =
              isString(text) || isNumber(text) ? text.toString() : undefined;
            const _subText =
              isString(subText) || isNumber(subText)
                ? subText.toString()
                : undefined;
            return {
              ...item,
              text: _text,
              subText: _subText,
            };
          })
          ?.filter(({ text, subText }) => text || subText)
          .filter(
            ({ text, subText }) =>
              text?.toLocaleLowerCase().includes(formatted) ||
              subText?.toLocaleLowerCase().includes(formatted)
          );
        setOptions(filteredOptions as IDropDownOption[]);
      },
      [options]
    );

    const searchIcon = React.useMemo(
      () => (search ? <Icon icon={"Search"} size={16} /> : undefined),
      [search]
    );

    const dropDownIcon = React.useMemo(() => {
      const iconClassName = classNames(styles.arrow, {
        [styles.arrowActive]: _visible,
      });
      const fillNormal = getCssVariable("--fill-color-headline"),
        fillDisabled = getCssVariable("--border-color-inactive");
      const fill = disabled ? fillDisabled : fillNormal;

      return (
        <span
          className={iconClassName}
          onClick={() => {
            setVisible((pre) => !pre);
          }}
        >
          <Icon icon={"ChevronExpand"} size={16} fill={fill} />
        </span>
      );
    }, [_visible, disabled]);

    const inputView = React.useMemo(
      () => (
        <InputBasic
          {...rest}
          addonBefore={searchIcon}
          addonAfter={dropDownIcon}
          value={text}
          readOnly={!search}
          className={inputClassName}
          placeholder={placeholder}
          disabled={disabled}
          onChange={inputChangeHandler}
        />
      ),
      [
        rest,
        searchIcon,
        dropDownIcon,
        text,
        search,
        inputClassName,
        placeholder,
        disabled,
        inputChangeHandler,
      ]
    );

    const dropDownMenu = React.useMemo(() => {
      if (!Array.isArray(stateOptions) || !stateOptions?.length) return <></>;
      return (
        <ul className={styles.menu}>
          {stateOptions.map(({ value, text, children, subText }) => {
            const active = value === _value;
            const itemCls = classNames(styles.item, {
              [styles.itemActive]: active,
            });
            return (
              <li
                className={itemCls}
                key={value}
                onClick={selectHandler.bind(null, value)}
              >
                <div className={styles.itemContent}>
                  <div className={styles.itemText}>{text ?? children}</div>
                  <div className={styles.subText}>{subText}</div>
                </div>
                {active && <div className={styles.selected} />}
              </li>
            );
          })}
        </ul>
      );
    }, [stateOptions, _value, selectHandler]);

    return (
      <div className={_containerClassName}>
        <RcDropDown
          {...rest}
          overlay={dropDownMenu}
          visible={_visible}
          onVisibleChange={visibleChangeHandler}
        >
          {inputView}
        </RcDropDown>
      </div>
    );
  });

InputVariantDropDown.defaultProps = {
  trigger: ["click"],
  animation: "slide-up",
  minOverlayWidthMatchTrigger: true,
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};
