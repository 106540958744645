import { Button, Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";

import { isFunction, isString } from "../../../utils/isType";
import { ErrorDetectorModalView } from "../../ErrorDecorator/ErrorDecorator";
import Confirmor, { CloseType, IConfirmor } from "../Confirmor";
import styles from "./ConfirmVariant.module.scss";

export interface IConfirmVariantProps extends IConfirmor {}

export const ConfirmVariant: React.FC<IConfirmVariantProps> =
  ErrorDetectorModalView((props: IConfirmVariantProps) => {
    const { buttonTexts, footer, title, contentClassName, onConfirm, ...rest } =
      props;

    const _contentClassName = classNames(
      styles.contentClassName,
      contentClassName
    );

    const clickHandler = React.useCallback(
      (closeType: CloseType) => {
        if (!isFunction(onConfirm)) return;
        onConfirm(closeType);
      },
      [onConfirm]
    );

    const closeIcon = React.useMemo(() => {
      return (
        <span
          className={styles.closeIcon}
          onClick={clickHandler.bind(null, "cancel")}
        >
          {<Icon icon="Close" size={20} />}
        </span>
      );
    }, []);

    const titleView = React.useMemo(() => {
      if (React.isValidElement(title) && !isString(title)) return title;
      return (
        <div className={styles.title}>
          <div className={styles.titleContent}>{title}</div>
        </div>
      );
    }, [title]);

    const footerView = React.useMemo(() => {
      if (React.isValidElement(footer)) return footer;
      if (!Array.isArray(buttonTexts) || !buttonTexts.length) return null;
      return (
        <div className={styles.footer}>
          {buttonTexts.map(({ type, disabled, label, closeType, size }) => (
            <Button
              key={label}
              variant={type}
              disabled={disabled}
              onClick={clickHandler.bind(null, closeType)}
              size={size}
              className={styles.button}
            >
              {label}
            </Button>
          ))}
        </div>
      );
    }, [buttonTexts, clickHandler, footer]);

    return (
      <Confirmor
        {...rest}
        className={styles.modal}
        confirmClassName={styles.confirmVariant}
        contentClassName={_contentClassName}
        closeIcon={closeIcon}
        title={titleView}
        footer={footerView}
        closable={true}
      />
    );
  });
