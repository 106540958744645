import { ReactElement, ReactPortal } from "react";
import { createPortal } from "react-dom";
import isBrowser from "../../utils/isBrowser";
import { resolveContainer } from "./getContainer";

export type GetContainer = HTMLElement | (() => HTMLElement) | null;

export function renderToContainer(
  getContainer: GetContainer,
  node: ReactElement
) {
  if (isBrowser && getContainer) {
    const container = resolveContainer(getContainer);
    return createPortal(node, container) as ReactPortal;
  }
  return node;
}
