import React from "react";
import { useRecoilValue } from "recoil";
import { stepState, userState } from "../../../store";
import { isNumber } from "../../../utils/isType";
import useCurrentUser from "../../hooks/useCurrentUser";
import useRolePage from "../../hooks/useRolePage";
import { OrderStep } from "../../service/OrderStepTypes";
import { PageStatus, PageSubItems } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import StepPageModel from "../model/StepPageModel";
import SiteSurveyAll from "./SiteSurveyAll/SiteSurveyAll";
import SiteSurveyFse from "./SiteSurveyFSE/SiteSurveyFse";
import SiteSurveyRsm from "./SiteSurveyRsm/SiteSurveyRsm";

export default function () {
  const { usersRole } = useRecoilValue(userState);
  const { key } = useRecoilValue(stepState);
  const stepNode = StepPageModel.getStepPageByTab(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
    key,
    usersRole
  );
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY
  );

  const isCurrentUser = useCurrentUser();

  return React.useMemo(() => {
    const specialViewMap = new Map<Role, React.ReactNode>([
      [Role.ROLE_RSM_MANAGER, <SiteSurveyFse key={Role.ROLE_RSM_MANAGER} />],
      [Role.ROLE_RSM, <SiteSurveyFse key={Role.ROLE_RSM} />],
      [Role.ROLE_FSE, <SiteSurveyFse key={Role.ROLE_FSE} />],
    ]);

    const defaultViewMap = new Map<Role, React.ReactNode>([
      [Role.ROLE_RSM, <SiteSurveyRsm key={Role.ROLE_RSM} />],
      [Role.ROLE_RSM_MANAGER, <SiteSurveyRsm key={Role.ROLE_RSM_MANAGER} />],
      [Role.ROLE_FSE, <SiteSurveyFse key={Role.ROLE_FSE} />],
    ]);

    const viewMap =
      key === OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT && isCurrentUser
        ? specialViewMap
        : defaultViewMap;

    if (!isNumber(key)) return <></>;
    if (pageStatus === PageStatus.READONLY) return <SiteSurveyAll />;

    return (viewMap.get(usersRole) ?? stepNode) as React.ReactElement;
  }, [key, isCurrentUser, pageStatus, usersRole, stepNode]);
}
