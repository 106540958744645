import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import OrderRequestModel, {
  IPrepareInstallation,
} from "../../model/OrderRequestModel";

export default function () {
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const platform = useRecoilValue(platformState);
  const {
    expectedDate,
    labelSiteSurveyReport,
    labelActualSiteSurveyDate,
    siteSurveyDone,
  } = useLang({
    expectedDate: { id: "order_expected_site_survey_data" },
    labelSiteSurveyReport: { id: "order_site_survey_report" },
    labelActualSiteSurveyDate: { id: "order_site_survey_actual_date" },
    siteSurveyDone: { id: "order_site_survey_qualified" },
  });
  const [preInstallInfo, setPreInstallInfo] = React.useState(
    () => ({} as IPrepareInstallation)
  );
  const [errCode, setError] = React.useState<ErrorCode>();
  const orgs = useRecoilValue(systemUserOrgs);
  const locale = useRecoilValue(localeState);
  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const { usersRole } = useRecoilValue(userState);

  React.useEffect(() => {
    getPreInstallationInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const getPreInstallationInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      OrderRequestModel.getPreInstallInformation(installationTaskId, locale)
        .then((res) => {
          setPreInstallInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const backToAllTicket = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    preInstallInfo,
    orgs,
    expectedDate,
    platform,
    labelActualSiteSurveyDate,
    labelSiteSurveyReport,
    siteSurveyDone,
    locale,
    backToAllTicket,
  };
}
