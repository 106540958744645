import { Button, TextArea } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { formatDatePickValue } from "../../../../utils/commonUtils";
import DatePickerVariant from "../../../components/DatePickerVariant/DatePickerVariant";
import Selector from "../../../components/Selector/Selector";
import SwitchCabinet from "../../../components/SwitchCabinet/SwitchCabinet";
import Text from "../../../components/Text/Text";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { SalesAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import CommentsView from "../../components/CommentView/CommentsView";
import OrderRequestModel, {
  IDNInfo,
  IFile,
} from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationSales.module.scss";
import InstallationSaleModel from "./InstallationSalesModel";
import useInstallationSales from "./useInstallationSales";

const InstallationSalesMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    installationSaleInfo,
    rolePage,
    saleRegions,
    labelFacRequired,
    reminderFacRequired,
    labelFacUploader,
    labelTrainingRequired,
    reminderTrainingRequired,
    labelApSupervisor,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelWarrantyPeriodPhd,
    labelDisclaimerNotice,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitVisible,
    labelSubmitReminder,
    locale,
    confirmHandler,
    setSubmit,
    backAllTickHandler,
    changeHandler,
  } = useInstallationSales();

  const facAndTrainRequireView = React.useMemo(() => {
    const { facRequired, trainingRequired, apSupervisor, facUploader } =
      installationSaleInfo;
    const { pageStatus } = rolePage;
    const disabled = pageStatus === PageStatus.READONLY;

    const apSupervisorOption =
      OrderRequestModel.getApSupervisorList(saleRegions);
    const _apSupervisorOption =
      InstallationSaleModel.convertApSupervisorList(apSupervisorOption);

    return (
      <div className={styles.facAndTrainRequireView}>
        <div className={styles.toggleView}>
          <SwitchCabinet
            cabinetLabel={labelFacRequired}
            cabinetClassName={styles.switchCls}
            checked={facRequired ? facRequired : false}
            disabled={disabled}
            onChange={(isChecked: boolean) =>
              changeHandler(
                InstallationModel.FAC_REQUIRED as keyof IInstallation,
                isChecked
              )
            }
          />
          <TextReminder label={reminderFacRequired} />
        </div>
        {facRequired && (
          <Text label={labelFacUploader} className={styles.facUploader}>
            {facUploader}
          </Text>
        )}
        <div className={styles.toggleView}>
          <SwitchCabinet
            cabinetLabel={labelTrainingRequired}
            cabinetClassName={styles.switchCls}
            checked={trainingRequired ? trainingRequired : false}
            disabled={disabled}
            onChange={(isChecked: boolean) =>
              changeHandler(
                InstallationModel.TRAINING_REQUIRED as keyof IInstallation,
                isChecked
              )
            }
          />
          <TextReminder label={reminderTrainingRequired} />
        </div>
        {trainingRequired && (
          <Selector
            label={labelApSupervisor}
            options={_apSupervisorOption}
            value={apSupervisor}
            search={true}
            disabled={disabled}
            onChange={(value) =>
              changeHandler(InstallationModel.AP_SUPERVISOR, value)
            }
          />
        )}
      </div>
    );
  }, [
    installationSaleInfo,
    rolePage,
    saleRegions,
    labelFacRequired,
    reminderFacRequired,
    labelFacUploader,
    labelTrainingRequired,
    reminderTrainingRequired,
    labelApSupervisor,
    changeHandler,
  ]);

  const installDateView = React.useMemo(() => {
    const warrantyReminder = InstallationModel.getReminderByWarranty(
      installationSaleInfo.warrantyPeriod as WarrantyPeriod
    );
    const warrantyOptions = InstallationModel.getWarrantyPeriodOptions();
    const { expectedInstallDate } = installationSaleInfo;
    const { pageStatus } = rolePage;
    const disabled = pageStatus === PageStatus.READONLY;

    return (
      <div className={styles.installDateView}>
        <DatePickerVariant
          variantClassName={styles.datePickerCls}
          label={labelExpectedInstallDate}
          disabled={disabled}
          value={formatDatePickValue(expectedInstallDate as number)}
          onConfirm={(value) =>
            changeHandler(
              InstallationModel.EXPECTED_INSTALL_DATA,
              new Date(value).getTime()
            )
          }
        />
        <Selector
          label={labelWarrantyPeriod}
          options={warrantyOptions}
          value={installationSaleInfo.warrantyPeriod}
          disabled={disabled}
          onChange={(value) =>
            changeHandler(InstallationModel.WARRANTY_PERIOD, value)
          }
        />
        {(installationSaleInfo.warrantyPeriod ===
          WarrantyPeriod.DELIVERY_MONTH_15 ||
          installationSaleInfo.warrantyPeriod === WarrantyPeriod.MONTH_12) && (
          <div className={styles.warrantyReminder}>{warrantyReminder}</div>
        )}
        {installationSaleInfo.warrantyPeriod === WarrantyPeriod.OTHER && (
          <div className={styles.otherComments}>
            <TextArea
              label=""
              placeholder={labelWarrantyPeriodPhd}
              value={installationSaleInfo.otherWarrantyPeriod}
              disabled={disabled}
              onChange={(evt) =>
                changeHandler(
                  InstallationModel.WARRANTY_PERIOD_COMMENTS,
                  evt?.target?.value
                )
              }
              maxLength={50}
              rows={4}
            />
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationSaleInfo,
    rolePage,
    labelExpectedInstallDate,
    labelWarrantyPeriod,
    labelWarrantyPeriodPhd,
    locale,
    changeHandler,
  ]);

  const installUploadFileView = React.useMemo(() => {
    const list = InstallationSaleModel.getFileInfoViewModel(
      installationSaleInfo as IInstallation,
      rolePage?.pageStatus
    );
    const views = list.map(({ viewType, key, props }) => {
      const _props = {
        ...props,
        onChange: (value: IFile[]) =>
          changeHandler(key as keyof IInstallation, value),
      };

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    if (!Array.isArray(list) || !list.length) return null;

    return <div className={styles.installUploadFileView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationSaleInfo,
    rolePage?.pageStatus,
    labelDisclaimerNotice,
    locale,
    changeHandler,
  ]);

  const commentsView = React.useMemo(() => {
    return <CommentsView />;
  }, []);

  const buttonsView = React.useMemo(() => {
    const list = InstallationSaleModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SalesAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === SalesAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );
      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePage, locale, submitEnable, backAllTickHandler, setSubmit]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  return (
    <div className={styles.installationInfoSales}>
      <div className={styles.pageContainer}>
        {facAndTrainRequireView}
        {installDateView}
        <OrderInfoDnView
          {...(installationSaleInfo?.dnInfo as IDNInfo)}
          customerType={installationSaleInfo.customerType}
        />
        {installUploadFileView}
        {commentsView}
      </div>
      {buttonsView}
      <Confirmor
        footer={confirmButtonView}
        visible={submitVisible}
        content={labelSubmitReminder}
      />
    </div>
  );
});

export default InstallationSalesMobile;
