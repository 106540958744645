import { attachPropertiesToComponent } from "../utils/attachPropertiesToComponent";
import { Picker } from "./Picker";

export type { PickerActions, PickerProps, PickerRef } from "./Picker";

export type {
  PickerColumn,
  PickerColumnItem,
  PickerValue,
  PickerValueExtend,
} from "./PickerView";

export default attachPropertiesToComponent(Picker, {
  prompt,
});
