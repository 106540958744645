import LoggerService from "../../core/LoggerService";
import isDev from "../../utils/isDev";

export function measureCSSLength(raw: string | undefined | null): number {
  if (raw === null || raw === undefined || raw === "") {
    if (isDev) {
      LoggerService.log("Something went wrong when calculating CSS length");
    }
    return 0;
  }
  const withUnit = raw.trim();
  if (withUnit.endsWith("px")) {
    return parseFloat(withUnit);
  } else if (withUnit.endsWith("rem")) {
    return (
      parseFloat(withUnit) *
      parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    );
  } else if (withUnit.endsWith("vw")) {
    return (parseFloat(withUnit) * window.innerWidth) / 100;
  } else {
    if (isDev) {
      LoggerService.log("Something went wrong when calculating CSS length");
    }
    return 0;
  }
}
