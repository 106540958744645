interface IconProps {
  size?: "auto" | "default";
}

export default function (props: IconProps) {
  const { size } = props;
  const width = size === "auto" ? "100%" : "22",
    height = size === "auto" ? "100%" : "22";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4881 12.6085L8.62331 21H20C20.8284 21 21.5 20.3284 21.5 19.5V5.5C21.5 4.67157 20.8284 4 20 4H3.11342L10.3737 12.1678L10.5436 12.3589L10.4881 12.6085Z"
        fill="white"
        stroke="#0072EF"
      />
      <path
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H10L14 18.5H2C0.89543 18.5 0 17.6046 0 16.5V2.5Z"
        fill="#0072EF"
      />
      <path
        d="M13.42 10.216H16.498V10.774H14.074V12.022H16.354V12.58H14.074V13.942H16.6V14.5H13.42V10.216ZM18.8058 11.314C19.5738 11.314 19.9638 11.734 19.9638 12.586V14.5H19.3278V12.64C19.3278 12.112 19.0818 11.848 18.6018 11.848C18.4218 11.848 18.2598 11.908 18.1278 12.04C17.9837 12.184 17.8937 12.382 17.8698 12.634V14.5H17.2337V11.398H17.8698V11.782C17.9897 11.626 18.1278 11.512 18.2838 11.434C18.4398 11.35 18.6138 11.314 18.8058 11.314Z"
        fill="#0072EF"
      />
      <path
        d="M5.157 6.732H5.843V7.943H8.335V11.324H7.67V10.925H5.843V13.27H5.157V10.925H3.337V11.324H2.672V7.943H5.157V6.732ZM3.337 10.274H5.157V8.594H3.337V10.274ZM5.843 10.274H7.67V8.594H5.843V10.274Z"
        fill="white"
      />
    </svg>
  );
}
