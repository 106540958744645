export default {
  common_no: "否",
  common_comments: "备注",
  common_edit: "编辑",
  common_btn_all_tickets: "所有订单",
  common_btn_forward: "转发",
  common_btn_assign: "派工",
  common_btn_reject: "拒绝",
  common_btn_submit: "提交",
  common_btn_delete: "删除",
  common_btn_approve: "同意",
  common_btn_more: "更多",
  common_btn_add_site_survey: "补场测",
  common_on_going: "正在进行",
  common_completed: "已完成",
  common_not_start: "未开始",
  common_msg_success: "成功",
  common_msg_server_error: "服务器错误",
  common_msg_path_not_found: "页面未找到",
  common_info_title: "信息",
  common_msg_error: "错误",
  common_site_survey_title: "场测",
  common_install_title: "安装",
  common_fac_title: "FAC/IBase",
  common_btn_revise: "修改",
  common_btn_cancel: "取消",
  common_btn_confirm: "确认",
  common_new: "新",
  common_yes: "是",
  common_btn_cancel_order: "取消订单",
  common_not_required: "不需要",
  common_yes_upper: "是",
  common_no_upper: "否",
  common_inner_error: "Internal Error",
  common_action: "操作",
  common_operator: "操作人",
  common_reason: "原因",
  common_logout_reminder: "是否登出？",
  common_shirk: "收起",
  common_order: "订单",
  common_add: "添加",
  common_cancel_upper: "取消",
  common_ok_upper: "确认",
  common_others: "其他",
  common_page_title_dashboard: "Dashboard",
  common_pc_wise_title: "RMS场测&安装",
  common_btn_search: "搜索",
  common_number: "数量",
  common_comments_phd: "请输入备注",
  common_empty_phd: "暂无内容",
  common_no_permission: "请联系管理员添加权限",
  common_pagination_total_item: "共value项",
  common_pagination_per_page: "value / 页",
  common_page_title_ticket_management: "订单管理",
  common_btn_reset: "重置",
  common_btn_recover: "恢复",
};
