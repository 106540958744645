import { Button } from "@zeiss/ods-components-react";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import TextReminder, {
  ReminderType,
} from "../../../../../../components/TextReminder/TextReminder";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import ModifyContact from "../../../../../components/ModifyContact/ModifyContact";
import { ContactPersonStatus } from "../../../InfoSalesModel";
import useContactPerson, {
  ContactPersonProps,
} from "../../../hooks/useContactPerson";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./ContactPersonView.module.scss";

const ContactPersonView: React.FC<ContactPersonProps> = ErrorDetectorMediumView(
  (props: ContactPersonProps) => {
    const {
      title,
      reminder,
      revise,
      contactPersonInfo,
      visible,
      editable,
      locale,
      clickHandler,
      confirmHandler,
    } = useContactPerson(props);

    const models = React.useMemo(
      () => InfoSalesPcModel.getContactModifyModel(contactPersonInfo),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contactPersonInfo, locale]
    );

    const personInfoView = React.useMemo(() => {
      const infos = InfoSalesPcModel.getContactModels({
        ...contactPersonInfo,
      });

      return infos?.map(({ key, viewType, props }) => {
        return (
          <ViewComponent
            key={key}
            type={viewType as ViewType}
            props={{ ...props, key, className: styles.infoItem }}
          />
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactPersonInfo, locale]);

    return (
      <>
        <div className={styles.contactPersonView}>
          <div className={styles.title}>{title}</div>
          <TextReminder label={reminder} reminderType={ReminderType.ERROR} />
          <div className={styles.personInfoView}>{personInfoView}</div>
          {editable && (
            <Button className={styles.btn} onClick={clickHandler}>
              {revise}
            </Button>
          )}
        </div>
        {visible && (
          <ModifyContact
            title={title}
            status={ContactPersonStatus.UPDATE}
            models={models}
            visible={visible}
            onConfirm={confirmHandler}
          />
        )}
      </>
    );
  }
);

export default ContactPersonView;
