import { Icon } from "@zeiss/ods-components-react";
import React from "react";
import { getCssVariable } from "../../utils/commonUtils";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./PhoneCall.module.scss";

interface IPhoneCall {
  phoneNumber: number | string;
  className?: string;
  isVerify?: boolean;
}

const PhoneCall: React.FC<IPhoneCall> = ErrorDetectorSmallView(
  (props: IPhoneCall) => {
    const { phoneNumber, className, isVerify } = props;
    const { phoneCall, invalidNumber } = styles;
    const isValidPhone = Number.isInteger(+phoneNumber);
    const clsPrefix = className ? `${phoneNumber} ${className}` : phoneCall;
    const cls =
      !isValidPhone && isVerify ? `${clsPrefix} ${invalidNumber}` : clsPrefix;

    const prop = !isVerify
      ? { href: `tel:${phoneNumber}` }
      : isValidPhone
      ? { href: `tel:${phoneNumber}` }
      : null;

    const fillColor = React.useMemo(() => {
      return getCssVariable("--fill-color-headline-invert");
    }, []);

    return (
      <a className={cls} {...prop}>
        {phoneNumber}
        {phoneNumber && (
          <span className={styles.callIcon}>
            <Icon icon={"ContactPhone"} size={12} fill={fillColor} />
          </span>
        )}
      </a>
    );
  }
);

PhoneCall.defaultProps = {
  isVerify: true,
};

export default PhoneCall;
