import React from "react";
import useLang from "../../pages/hooks/useLang";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../Modal/Modal";
import { FileType } from "./Upload";
import styles from "./Upload.module.scss";

export enum PreviewAndDownload {
  PREVIEW = "preview",
  DOWNLOAD = "download",
}
interface IPreviewAndDownloadModal extends IModal {
  onTrigger: (type: PreviewAndDownload) => void;
  fileType: FileType;
}

const PreviewAndDownloadModal: React.FC<IPreviewAndDownloadModal> =
  ErrorDetectorModalView((props: IPreviewAndDownloadModal) => {
    const { onTrigger, fileType, ...rest } = props;
    const { labelPreview, labeDownload } = useLang({
      labelPreview: { id: "order_file_preview" },
      labeDownload: { id: "order_file_download" },
    });

    const clickHandler = React.useCallback(
      (type: PreviewAndDownload) => {
        isFunction(onTrigger) && onTrigger(type);
      },
      [onTrigger]
    );

    const listView = React.useMemo(() => {
      const list = [
        {
          key: PreviewAndDownload.PREVIEW,
          text: labelPreview,
          support: [FileType.IMAGE, FileType.PDF, FileType.WORD],
        },
        {
          key: PreviewAndDownload.DOWNLOAD,
          text: labeDownload,
          support: [],
        },
      ];

      const filterList = list.filter(
        ({ support }) => !support.length || support.includes(fileType)
      );

      return filterList.map(({ key, text }) => (
        <div
          key={key}
          className={styles.previewAndDownloadItem}
          onClick={clickHandler.bind(null, key)}
        >
          {text}
        </div>
      ));
    }, [labelPreview, labeDownload, fileType, clickHandler]);

    return (
      <Modal {...rest} className={styles.previewAndDownloadContainer}>
        <div className={styles.previewAndDownloadModal}>{listView}</div>
      </Modal>
    );
  });

export default PreviewAndDownloadModal;
