import { isEmpty } from "lodash";
import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import HttpService, { NormalRes } from "../../../../core/HttpService";
import {
  ISOApprove,
  ISOReject,
  StaticSourceItem,
  soApprove,
  soRejectReason,
} from "../../../../services/order.service";
import { formatDate } from "../../../../utils/commonUtils";
import { isBoolean } from "../../../../utils/isType";
import { ReminderType } from "../../../components/TextReminder/TextReminder";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { SoAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  CustomerTypes,
  ProductLine,
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import { SystemPlatform } from "./../../../../core/SystemService";

export default class InstallationSoModel {
  static rejectReason = (data: ISOReject): Promise<NormalRes> => {
    return HttpService.splitResponse(soRejectReason(data));
  };

  static approve = (data: ISOApprove): Promise<NormalRes> => {
    const formatDate = {
      ...data,
      euCs: OrderRequestModel.formatUploadItem(data?.euCs as any[]),
      deliveryNotes: OrderRequestModel.formatUploadItem(
        data?.deliveryNotes as any[]
      ),
      technicalAgreements: OrderRequestModel.formatUploadItem(
        data?.technicalAgreements as any[]
      ),
      specifications: OrderRequestModel.formatUploadItem(
        data?.specifications as any[]
      ),
      siteSurveyReports: OrderRequestModel.formatUploadItem(
        data?.siteSurveyReports as any[]
      ),
      disclaimers: OrderRequestModel.formatUploadItem(
        data?.disclaimers as any[]
      ),
      auditEmails: OrderRequestModel.formatUploadItem(
        data?.auditEmails as any[]
      ),
    };
    return HttpService.splitResponse(soApprove(formatDate));
  };

  static verifySoApprove = (infos: Partial<IInstallation>): boolean => {
    const { customerType, isSiteSurvey, isSiteSurveyDone, productLine } = infos;
    const keysMap = {
      [InstallationModel.DELIVERY_NOTES]:
        customerType === CustomerTypes.NOT_INDUSTRY,
      [InstallationModel.DISCLAIMERS]:
        (!isSiteSurvey || !isSiteSurveyDone) &&
        (productLine as ProductLine) !== ProductLine.LM,
      [InstallationModel.SITE_SURVEY_REPORT]: isSiteSurvey,
    };

    const keys = Object.entries(keysMap)
      .filter(([, value]) => value)
      .map(([key]) => key);

    return ValidationService.verifyIntegrity(infos, keys);
  };

  static getInfoViewModel = (
    info: Partial<IInstallation>,
    saleRegions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelSiteSurveyQualified,
      labelTrainRequired,
      labelApSupervisor,
      labelFacRequired,
      labelSaleTeam,
      labelRegin,
      labelProductLine,
      labelInfoSaleEmp,
      labelOwnerRsm,
      labelSiteSurveyRequired,
      labelFacUploader,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      isSiteSurvey,
      isSiteSurveyDone,
      trainingRequired,
      apSupervisor,
      facRequired,
      salesEmployee,
      region,
      productLine,
      saleTeam,
      rsm,
      facUploader,
    } = info;

    const facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      siteSurveyLabel = OrderRequestModel.getToggleLabel(
        isSiteSurvey as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        saleRegions,
        apSupervisor as string
      ),
      siteSurveyDoneLabel = OrderRequestModel.getToggleLabel(
        isSiteSurveyDone as boolean
      );

    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: viewType,
        props: {
          label: labelExpectedInstallDate,
          children: formatDate(expectedInstallDate as number),
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REQUIRED,
        viewType: viewType,
        props: {
          label: labelSiteSurveyRequired,
          children: siteSurveyLabel,
        },
      },
      {
        key: InstallationModel.IS_SITE_SURVEY_DONE,
        viewType: isSiteSurvey ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelSiteSurveyQualified,
          children: siteSurveyDoneLabel,
        },
        hidden: !isSiteSurvey,
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: viewType,
        props: {
          label: labelFacRequired,
          children: facLabel,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: viewType,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired ? viewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
        },
        hidden: !trainingRequired,
      },
      {
        key: InstallationModel.SALES_TEAM_LABEL,
        viewType: viewType,
        props: {
          label: labelSaleTeam,
          children: OrderRequestModel.getSaleTeamLabel(saleTeam as any),
        },
      },
      {
        key: InstallationModel.REGION_LABEL,
        viewType: viewType,
        props: {
          label: labelRegin,
          children: OrderRequestModel.getSoByRegion(
            saleRegions,
            region as number
          )?.text,
        },
      },
      {
        key: InstallationModel.PRODUCT_LINE_LABEL,
        viewType: viewType,
        props: {
          label: labelProductLine,
          children: OrderRequestModel.getProductLines(
            saleRegions,
            region as number
          )?.find((line: any) => line.value === productLine)?.text,
        },
      },
      {
        key: InstallationModel.SALE_EMPLOYEE,
        viewType: viewType,
        props: {
          label: labelInfoSaleEmp,
          children: salesEmployee,
        },
      },
      {
        key: InstallationModel.RSM,
        viewType: viewType,
        props: {
          label: labelOwnerRsm,
          children: rsm,
        },
      },
    ];

    return platform === SystemPlatform.ENV_MOBILE
      ? list.filter(({ hidden }) => !hidden)
      : list;
  };

  static getContactViewModel = (
    info: Partial<IInstallation>,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelFinalGoodsRecip,
      labelRecipeAddress,
      labelContactPerson,
      labelContactMobile,
      labelContactEmailOptional,
      labelWarrantyPeriod,
    } = InstallationModel.getLabels();
    const {
      finalGoodsReceiptName,
      finalGoodsRecipAddress,
      contactPersonNameEN,
      contactPersonNameCN,
      contactPersonMobile,
      contactPersonEmail,
      warrantyPeriod,
      isFinalGoodsRecipNew,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const indicator = isFinalGoodsRecipNew
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    return [
      {
        key: InstallationModel.FINAL_GOODS_RECEIPT_NAME,
        viewType: viewType,
        props: {
          label: labelFinalGoodsRecip,
          children: finalGoodsReceiptName,
          indicator,
        },
      },
      {
        key: InstallationModel.RECEIPT_ADDRESS,
        viewType: viewType,
        props: {
          label: labelRecipeAddress,
          children: finalGoodsRecipAddress,
        },
      },
      {
        key: InstallationModel.CONTACT_PERSON_NAME,
        viewType: viewType,
        props: {
          label: labelContactPerson,
          children: contactPersonNameCN || contactPersonNameEN,
        },
      },
      {
        key: InstallationModel.CONTACT_PERSON_MOBILE,
        viewType: viewType,
        props: {
          label: labelContactMobile,
          children: contactPersonMobile,
        },
      },
      {
        key: InstallationModel.CONTACT_PERSON_EMAIL,
        viewType: viewType,
        props: {
          label: labelContactEmailOptional,
          children: contactPersonEmail,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: viewType,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
        },
      },
    ];
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    pageStatus: PageStatus,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      disclaimers,
      siteSurveyReports,
      isSiteSurvey,
      isSiteSurveyDone,
      customerType,
      productLine,
      auditEmails,
      technicalAgreements,
      specifications,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelDisclaimer,
      labelSiteSurveyReport,
      uploadReminder,
      labelAuditEmail,
      labelDeliveryNoteOptional,
      labelAuditEmailReminder,
      labelDisclaimerNotSiteSurvey,
      labelDisclaimerNotice,
      labelTechAgr,
      labelSpecs,
    } = InstallationModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    const partProps = {
      disableUpload: disabled,
      deletable: !disabled,
    };
    const isIndustry = customerType === CustomerTypes.INDUSTRY;
    const labelDeliveryNote = isIndustry
      ? labelDeliveryNoteOptional
      : labelDeliverNotes;
    const indicatorDeliveryNote = isIndustry
      ? LabelIndicator.TYPE_OPTIONAL
      : LabelIndicator.TYPE_REQUIRED;

    const reminder = disabled ? "" : uploadReminder;
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const _labelDisclaimerNotice = !isSiteSurvey
      ? labelDisclaimerNotSiteSurvey
      : isBoolean(isSiteSurveyDone) && !isSiteSurveyDone
      ? labelDisclaimerNotice
      : "";
    const originList = [
      {
        key: InstallationModel.AUDIT_EMAIL,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelAuditEmail,
          value: auditEmails ?? [],
          key: InstallationModel.AUDIT_EMAIL,
          reminder,
          noticeType: ReminderType.ERROR,
          notice: !disabled && labelAuditEmailReminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelDisclaimer,
          value: disclaimers ?? [],
          key: InstallationModel.DISCLAIMERS,
          reminder,
          notice: !disabled && _labelDisclaimerNotice,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelEurOrEuu,
          value: euCs ?? [],
          key: InstallationModel.EUC_EUU,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelDeliveryNote,
          value: deliveryNotes ?? [],
          key: InstallationModel.DELIVERY_NOTES,
          reminder,
          indicator: indicatorDeliveryNote,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          key: InstallationModel.TECHNICAL_AGR,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelSpecs,
          value: specifications ?? [],
          key: InstallationModel.SPEC,
          reminder,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: viewType,
        props: {
          ...partProps,
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          key: InstallationModel.SITE_SURVEY_REPORT,
          reminder,
        },
      },
    ];
    const unIncludesKeysMap = {
      [InstallationModel.DISCLAIMERS]:
        (isSiteSurvey && isSiteSurveyDone) ||
        (productLine as ProductLine) === ProductLine.LM,
      [InstallationModel.SITE_SURVEY_REPORT]: !isSiteSurvey,
    };

    const keys = Object.entries(unIncludesKeysMap)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    const filteredList = originList.filter(({ key }) => {
      return !keys.includes(key);
    });

    return filteredList.filter(({ props }) => {
      if (disabled) {
        return !isEmpty(props?.value);
      }
      return true;
    });
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelReject, labelApprove } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: SoAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SoAction.ALL_TICKET,
        },
      },
      {
        key: SoAction.REJECT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelReject,
          key: SoAction.REJECT,
        },
      },
      {
        key: SoAction.APPROVE,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelApprove,
          key: SoAction.APPROVE,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
