import { Button, Icon, TextInput } from "@zeiss/ods-components-react";
import React from "react";
import { ErrorDetectorModalView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { Popup, PopupProps } from "../../../components/Popup/Popup";
import Correct from "../../../components/svg/Correct";
import useLang from "../../hooks/useLang";
import styles from "./SearchSelector.module.scss";
import { ConfirmType, SearchOption } from "./useSearchSelector";

export interface ISearchSelector extends Omit<PopupProps, "onClose"> {
  disabled?: boolean;
  showConfirm?: boolean;
  options: SearchOption[];
  value?: any;
  onClose?: (type: ConfirmType, value?: any) => void;
  search?: boolean;
  buttonDisabled?: boolean;
}

const SearchSelectorMobile: React.FC<ISearchSelector> = ErrorDetectorModalView(
  (props: ISearchSelector) => {
    const {
      options,
      showConfirm,
      value,
      search = true,
      className,
      buttonDisabled,
      onClose,
      ...rest
    } = props;
    const [stateOptions, setOptions] = React.useState(() => options);
    const [stateValue, setState] = React.useState(value);
    const [, setSearch] = React.useState("");
    const { labelCancel, labelConfirm } = useLang({
      labelCancel: { id: "common_btn_cancel" },
      labelConfirm: { id: "common_btn_confirm" },
    });

    React.useEffect(() => setOptions(options), [options]);

    const changeHandler = React.useCallback(
      (value: any) => {
        const { text } =
          stateOptions.find(
            ({ value: originValue }) => originValue === value
          ) ?? {};
        setState(text as string);
      },
      [stateOptions]
    );

    const confirmHandler = React.useCallback(
      (actionType: ConfirmType) => {
        if (actionType === ConfirmType.cancel) {
          onClose?.(ConfirmType.cancel);
          return;
        }
        const { value } =
          stateOptions.find((item) => item?.text === stateValue) ?? {};
        // if (value) {
        onClose?.(ConfirmType.ok, value);
        // }
      },
      [stateOptions, stateValue, onClose]
    );

    const clearHandler = React.useCallback(() => {
      setState("");
      setSearch("");
      setOptions(options);
    }, [options]);

    const searchHandler = React.useCallback(
      (evt: any) => {
        const { value } = evt?.target ?? {};
        const _value = value?.trim();
        const newOptions = _value
          ? options.filter(
              ({ text, subText }) =>
                text?.includes(value) || subText?.includes(value)
            )
          : options;
        setState(value);
        setSearch(value);
        setOptions(newOptions);
      },
      [options]
    );

    const confirmView = React.useMemo(() => {
      const disabled = !stateOptions.find((item) => item?.text === stateValue);
      return (
        <div className={styles.confirm}>
          <Button
            variant={"secondary"}
            className={styles.btn}
            onClick={confirmHandler.bind(null, ConfirmType.cancel)}
            size={"s"}
          >
            {labelCancel}
          </Button>
          <Button
            variant={"primary"}
            className={styles.btn}
            onClick={confirmHandler.bind(null, ConfirmType.ok)}
            disabled={disabled || buttonDisabled}
            size={"s"}
          >
            {labelConfirm}
          </Button>
        </div>
      );
    }, [
      stateOptions,
      labelCancel,
      buttonDisabled,
      labelConfirm,
      stateValue,
      confirmHandler,
    ]);

    const optionsView = React.useMemo(() => {
      return (
        <div className={styles.optionsList}>
          {stateOptions?.map(({ text, value, subText }) => {
            const itemView =
              typeof text === "string" ? (
                <div
                  className={styles.itemView}
                  onClick={changeHandler.bind(null, value)}
                >
                  <div>{text}</div>
                  <div>{subText}</div>
                </div>
              ) : (
                text
              );

            return (
              <div className={styles.optionItem} key={value}>
                {itemView}
                {text === stateValue && (
                  <span className={styles.icon}>
                    <Correct />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    }, [stateOptions, stateValue, changeHandler]);

    return (
      <Popup
        {...rest}
        closeOnMaskClick={true}
        onClose={onClose?.bind(null, ConfirmType.cancel) as any}
        // className={popCls}
      >
        <div className={styles.textInputContainer}>
          {search && (
            <Icon icon={"Search"} size={16} className={styles.search} />
          )}
          <TextInput
            label={""}
            value={stateValue}
            readOnly={!search}
            placeholder={"Search"}
            onInput={searchHandler}
            className={styles.input}
          />
          <span onClick={clearHandler}>
            <Icon icon={"Close"} size={16} className={styles.clear} />
          </span>
        </div>
        <div className={styles.selectForMobile}>
          {optionsView}
          {confirmView}
        </div>
      </Popup>
    );
  }
);

export default SearchSelectorMobile;
