import classNames from "classnames";
import {
  AppType,
  BasicView,
  IBasicViewProps,
} from "../../../components/ViewWrapper/ViewWrapper";
import styles from "./Text.module.scss";

interface IText extends Omit<IBasicViewProps, "viewType"> {}

const Text: React.FC<IText> = (props: IText) => {
  const { labelTextClassName, containerClassName, className } = props;

  const textCls = classNames(styles.label, labelTextClassName);
  const containerCls = classNames(styles.content, containerClassName);
  const cls = classNames(styles.container, className);

  return (
    <BasicView
      {...props}
      viewType={AppType.TYPE_MOBILE}
      labelTextClassName={textCls}
      containerClassName={containerCls}
      className={cls}
    />
  );
};

export default Text;
