import React from "react";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { ApSupervisorAction } from "../../../service/userRoleTypes";
import OrderDnInfoView from "../../OrderInfo/InfoSales/InfoSalesPc/components/OrderDnInfoView/OrderDnInfoView";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import { IDNInfo } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationApSupervisor.module.scss";
import InstallationApSupervisorModel from "./InstallationApSupervisorModel";
import useInstallationApSupervisor from "./useInstallationApSupervisor";

const InstallationApSupervisorPc: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemReginOrg,
    assignAndForwardVisible,
    submitEnable,
    assignAndForwArdConfig,
    userId,
    platform,
    locale,
    setVisible,
    assignAndForwardHandler,
    backAllTickHandler,
    setForwardAndAssignHandler,
    changeHandler,
  } = useInstallationApSupervisor();

  const dateInfoPcView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getTimeViewPcModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const datePickKeys = [
      InstallationModel.PLAN_TRAINING_START_DATE,
      InstallationModel.PLAN_TRAINING_END_DATE,
    ];
    const views = list.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onChange: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
          }
        : props;

      return (
        <div className={styles.viewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.dateInfoPcView}>{views}</div>;
  }, [infos, rolePage?.pageStatus, changeHandler]);

  const installationTimeInfoPcView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getInfoViewModel(
      infos as IInstallation,
      systemReginOrg,
      platform
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(0, splitIndex);

    const views = splitList.map(({ viewType, props, key }) => {
      return (
        <div className={styles.viewItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={props} />
        </div>
      );
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
  }, [infos, platform, systemReginOrg]);

  const installationOwnerInfoPcView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationApSupervisorModel.getInfoViewModel(
      infos as IInstallation,
      systemReginOrg,
      platform
    );
    const splitIndex = list.findIndex(
      ({ key }) => key === InstallationModel.FAC_REQUIRED
    );
    const splitList = list.slice(splitIndex);

    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    const views = splitList.map(({ viewType, props, key }) => {
      const _viewItem =
        key === InstallationModel.WARRANTY_PERIOD ? (
          <div className={styles.viewItemFull}>
            <ViewComponent type={viewType as ViewType} props={props} />
            {!!warrantyPeriod && (
              <div className={styles.warrantyPeriodReminder}>
                {warrantyPeriodReminder}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.viewItem} key={key}>
            <ViewComponent type={viewType as ViewType} props={props} />
          </div>
        );

      return _viewItem;
    });

    return <div className={styles.installationInfoPcView}>{views}</div>;
  }, [infos, platform, systemReginOrg]);

  const filesPcView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getFileInfoViewModel(
      infos as IInstallation,
      platform
    );
    const views = list.map(({ viewType, props, key }) => {
      return (
        <div className={styles.uploadItemPc} key={key}>
          <ViewComponent type={viewType as ViewType} props={props} />
        </div>
      );
    });
    if (!Array.isArray(list) || !list.length) return null;
    return <div className={styles.filesPcView}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, platform, locale]);

  const buttonsView = React.useMemo(() => {
    const list = InstallationApSupervisorModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<ApSupervisorAction, Record<string, any>>([
      [ApSupervisorAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [
        ApSupervisorAction.FORWARD,
        { onClick: setForwardAndAssignHandler.bind(null, Action.FORWARD) },
      ],
      [
        ApSupervisorAction.ASSIGN,
        { onClick: setForwardAndAssignHandler.bind(null, Action.ASSIGN) },
      ],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === ApSupervisorAction.ASSIGN ? !submitEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    submitEnable,
    locale,
    backAllTickHandler,
    setForwardAndAssignHandler,
  ]);

  return (
    <section className={styles.installationApSupervisorPc}>
      <div className={styles.pageContentPc}>
        {dateInfoPcView}
        {installationTimeInfoPcView}
        {installationOwnerInfoPcView}
        <OrderDnInfoView {...(infos.dnInfo as IDNInfo)} />
        {filesPcView}
      </div>
      {buttonsView}
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          role={assignAndForwArdConfig.role}
          action={assignAndForwArdConfig.action}
          feature={Feature.TRAINING}
          searchQuery={{ aPSupervisorUserId: userId }}
          actionData={{
            ...assignAndForwArdConfig.otherData,
            selectUserIdKey: "aprbacCorrelationId",
          }}
          onActionResponse={assignAndForwardHandler}
          onClose={() => setVisible(false)}
        />
      )}
    </section>
  );
});

export default InstallationApSupervisorPc;
