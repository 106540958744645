import { useCallback } from "react";
import type { RenderLabel } from "./DatePickerView";

const nowLabel = "Until Now";

export default function useRenderLabel(renderLabel?: RenderLabel): RenderLabel {
  return useCallback(
    (type, data) => {
      if (renderLabel) {
        return renderLabel(type, data);
      }

      // Default render
      switch (type) {
        case "minute":
        case "second":
        case "hour":
          return ("0" + data.toString()).slice(-2);
        case "now":
          return nowLabel;
        default:
          return data.toString();
      }
    },
    [renderLabel]
  );
}
