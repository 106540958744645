import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from "axios";
import AuthService from "../../core/AuthService";
import LocalStorageService from "../../core/LocalStorageService";

export default class HttpRequest {
  constructor(public baseUrl: string = "") {
    this.baseUrl = process.env.REACT_APP_API_URL || "";
  }

  public request(options: AxiosRequestConfig, showError = true): AxiosPromise {
    const instance: AxiosInstance = axios.create();
    options = this.mergeConfig(options);
    this.interceptors(instance, showError);
    return instance(options);
  }

  private interceptors(instance: AxiosInstance, showError?: boolean) {
    // 请求拦截
    instance.interceptors.request.use(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (config: AxiosRequestConfig) => {
        const token = LocalStorageService.getStorage(
          LocalStorageService.API_TOKEN
        );
        const csrfToken = LocalStorageService.getStorage(
          LocalStorageService.CSRF_TOKEN
        );
        config.headers = token
          ? {
              ...config.headers,
              Authorization: `Bearer ${token}`,
              [AuthService.CSRF_TOKEN_KEY]: csrfToken,
            }
          : config.headers;
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        const { response, message } = error;
        console.log("error", error, message);
        return Promise.reject(error).catch((res) => res);
      }
    );
  }

  // 用于合并基础路径配置和接口单独配置
  private mergeConfig(options: AxiosRequestConfig): AxiosRequestConfig {
    return Object.assign({ baseURL: this.baseUrl }, options);
  }
}
