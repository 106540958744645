import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CloseType } from "../../../../components/Confirmor/Confirmor";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import EventService from "../../../../core/EventService";
import { IUserInfo } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import useLang from "../../../hooks/useLang";
import useRolePage from "../../../hooks/useRolePage";
import { PageSubItems } from "../../../service/pageType";
import InstallationModel, { IInstallation } from "../installationModel";
import InstallationSoModel from "./InstallationSoModel";

export default function () {
  const { rejectTitle, labelSOApproveReminder, labelCancel, labelConfirm } =
    useLang({
      rejectTitle: { id: "order_reject_assignment" },
      labelSOApproveReminder: { id: "order_so_confirm_install" },
      labelCancel: { id: "common_btn_cancel" },
      labelConfirm: { id: "common_btn_confirm" },
    });
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [installationSoInfo, setInfo] = React.useState<Partial<IInstallation>>(
    {}
  );
  const [errCode, setError] = React.useState<ErrorCode>();
  const [rejectVisible, setReject] = React.useState<boolean>(false);
  const [approveVisible, setApprove] = React.useState<boolean>(false);
  const saleRegions = useRecoilValue(systemUserOrgs);
  const [buttonBlock, setBlock] = React.useState(false);
  const [reminder, setReminder] = React.useState(labelSOApproveReminder);
  const { userId } = (useRecoilValue(userState) as IUserInfo) ?? {};
  const rolePage = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);

  const navigate = useNavigate();
  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  React.useEffect(() => {
    getInfo(installationTaskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTaskId]);

  const submitEnable = React.useMemo(
    () => InstallationSoModel.verifySoApprove(installationSoInfo),
    [installationSoInfo]
  );

  const getInfo = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getInstallationInfo(installationTaskId, locale)
        .then((res) => {
          setInfo(res?.data);
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [locale, setLoading, toastError]
  );

  const changeHandler = React.useCallback(
    (key: keyof IInstallation, value: any) => {
      setInfo((pre) => ({ ...pre, [key]: value }));
    },
    []
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const approveHandle = React.useCallback(() => {
    const {
      euCs,
      deliveryNotes,
      disclaimers,
      siteSurveyReports,
      auditEmails,
      technicalAgreements,
      specifications,
    } = installationSoInfo as IInstallation;
    return InstallationSoModel.approve({
      installationTaskId,
      euCs,
      deliveryNotes,
      disclaimers,
      siteSurveyReports,
      auditEmails,
      technicalAgreements,
      specifications,
    } as any);
  }, [installationSoInfo, installationTaskId]);

  const confirmHandler = React.useCallback(
    (type: CloseType) => {
      setApprove(false);
      if (type === "cancel") {
        setBlock(false);
        return;
      }
      setLoading(true);
      setBlock(true);
      approveHandle()
        .then(() => {
          EventService.getInstance().emit(
            EventService.EVENT_SUBMIT_STEP_COMMENTS
          );
          backAllTickHandler();
        })
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [setLoading, approveHandle, backAllTickHandler, toastError]
  );

  const rejectReq = React.useCallback(
    (reason: string) => {
      return InstallationSoModel.rejectReason({
        installationTaskId,
        soRBACCorrelationId: userId,
        reason,
      });
    },
    [installationTaskId, userId]
  );

  const rejectHandler = React.useCallback(
    (type: CloseType, reason?: any) => {
      setReject(false);
      if (type === "cancel") {
        return;
      }
      setLoading(true);
      setBlock(true);
      rejectReq(reason)
        .then(backAllTickHandler)
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => {
          setBlock(false);
          setLoading(false);
        });
    },
    [setLoading, rejectReq, backAllTickHandler, toastError]
  );

  return {
    installationSoInfo,
    rejectVisible,
    approveVisible,
    rejectTitle,
    saleRegions,
    rolePage,
    submitEnable,
    labelCancel,
    buttonBlock,
    labelConfirm,
    reminder,
    platform,
    locale,
    setReject,
    setApprove,
    setReminder,
    rejectHandler,
    confirmHandler,
    backAllTickHandler,
    changeHandler,
  };
}
