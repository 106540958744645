import HttpService, { NormalRes } from "../../core/HttpService";
import { formatMessage } from "../../core/LangService";
import LocalStorageService from "../../core/LocalStorageService";
import LocaleService, { LocaleLang } from "../../core/LocaleService";
import {
  IOrderListRequest,
  IOrderListRes,
  IResetTicketReq,
  OrderItem,
  getAdminOrderList,
  getOrderList,
  resetTicket,
} from "../../services/order.service";
import { isObject } from "../../utils/isType";
import { OrderStep } from "../service/OrderStepTypes";
import { PagePaths } from "../service/pageType";
import { Role } from "../service/userRoleTypes";
import { OrderStepSummary } from "./../service/roleStateService/RoleStepPageService";

export enum OrderStatus {
  ALL = -1,
  PENDING = 1,
  PENDING_IN_PROGRESS = 11,
  PENDING_RESOLVE = 12,
  RESOLVED = 2,
  CANCEL = 3,
  SPLIT_PENDING = 41,
  SPLIT_RESOLVE = 42,
}

export interface OrderTableItem
  extends Omit<
    OrderItem,
    | "status"
    | "salesEmployeeNameEN"
    | "salesEmployeeNameCN"
    | "orderStepSummary"
    | "serviceRegion"
    | "owners"
  > {
  serviceRegion: string;
  salesEmployee: string;
  step: string;
  owners: string;
}

export default class OrderListModel {
  static getList = (params: IOrderListRequest): Promise<IOrderListRes> => {
    return HttpService.splitResponse(getOrderList(params));
  };

  static getAdminList = (params: IOrderListRequest): Promise<IOrderListRes> => {
    return HttpService.splitResponse(getAdminOrderList(params));
  };

  static resetTicket = (data: IResetTicketReq): Promise<NormalRes> => {
    return HttpService.splitResponse(resetTicket(data));
  };

  static isUnSplitOrder = (
    orderSummary: OrderStepSummary,
    parentInstallationTaskId: string
  ): boolean => {
    if (!parentInstallationTaskId || !isObject(orderSummary)) return false;
    const { key } = orderSummary;
    return key === OrderStep.SPLIT_ORDER;
  };

  static getLang = () => {
    const lang = LocalStorageService.getStorage(LocalStorageService.LOCAL_LANG);
    const defaultLang = LocaleService.getDefaultLocale().lang;
    return lang || defaultLang;
  };

  static setLang = (lang: LocaleLang) => {
    LocalStorageService.setStorage(LocalStorageService.LOCAL_LANG, lang);
    window.location.reload();
  };

  static getListPath = (role: Role) => {
    return role === Role.ROLE_ADMIN
      ? PagePaths.PATH_ADMIN
      : PagePaths.PATH_ORDER_LIST;
  };

  protected static getLabels = () => {
    return {
      labelStatusTodo: formatMessage("order_list_search_status_to_do"),
      labelStatusDone: formatMessage("order_list_search_status_done"),
      labelStatusComplete: formatMessage("order_list_search_status_complete"),
      labelStatusCancel: formatMessage("order_list_search_status_cancel"),
      labelStatusAll: formatMessage("order_list_search_status_all"),

      labelSubOrder: formatMessage("order_sub_order"),
      labelFinalGoodsRecipe: formatMessage("order_final_goods_recipe"),
      labelContactPerson: formatMessage("order_contact_person"),
      labelMainProduct: formatMessage("order_main_product"),
      labelInfoSaleEmp: formatMessage("order_info_sale_emp"),
      labelDnSerialNumber: formatMessage("order_dn_serial_number"),
      labelContactPersonName: formatMessage("order_contact_person_name"),
      labelRegin: formatMessage("order_info_region"),
      labelStep: formatMessage("order_step"),
      labelOwner: formatMessage("order_owner"),
      labelOperate: formatMessage("order_operation"),
    };
  };
}
