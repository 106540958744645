import { isFunction } from "lodash";
import React from "react";
import { useRecoilValue } from "recoil";
import { LocaleLang } from "../../../../../core/LocaleService";
import { StaticSourceItem } from "../../../../../services/order.service";
import { localeState, systemUserOrgs } from "../../../../../store";
import useRolePage from "../../../../hooks/useRolePage";
import { PageStatus, PageSubItems } from "../../../../service/pageType";
import { OrderStepSummary } from "../../../../service/roleStateService/RoleStepPageService";
import OrderRequestModel, {
  IMoreDetailItem,
  IPartnerTeamOtherInfo,
} from "../../../model/OrderRequestModel";
import InfoSalesModel from "../InfoSalesModel";
import { stepState } from "./../../../../../store/index";

export interface OrderProdInfoProps {
  infos: IPartnerTeamOtherInfo;
  onChange?: (infos: Partial<IPartnerTeamOtherInfo>) => void;
}

export interface HookOrderProdInfo {
  infos: IPartnerTeamOtherInfo;
  pageStatus: PageStatus;
  productItems: IMoreDetailItem[];
  visible: boolean;
  orderStepSummary: OrderStepSummary;
  locale: LocaleLang;
  getSaleOptions: (
    key: Pick<
      IPartnerTeamOtherInfo,
      "customerType" | "saleTeam" | "region" | "productLine"
    >
  ) => Partial<StaticSourceItem>[];
  changeHandler: (
    key: keyof Partial<IPartnerTeamOtherInfo>,
    value: any
  ) => void;
  folderChangeHandler: (visible: boolean) => void;
}

export default function (props: OrderProdInfoProps): HookOrderProdInfo {
  const { infos, onChange } = props ?? {};
  const { productItems } = infos ?? {};
  const saleRegions = useRecoilValue(systemUserOrgs);
  const [visible, setVisible] = React.useState(false);
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO
  );
  const locale = useRecoilValue(localeState);
  const orderStepSummary = useRecoilValue(stepState);

  const regions = React.useMemo(
    () =>
      saleRegions?.regionProductLine.filter(({ isDeleted }) => {
        return pageStatus === PageStatus.READONLY ? true : !isDeleted;
      }) ?? [],
    [pageStatus, saleRegions?.regionProductLine]
  );

  const productLines = React.useMemo(() => {
    const productLines =
      regions.find(({ value }) => value === infos?.region)?.productLines ?? [];
    return pageStatus === PageStatus.READONLY
      ? productLines
      : productLines.filter((item: any) => !item?.isDeleted);
  }, [infos?.region, pageStatus, regions]);

  const submitChange = React.useCallback(
    (updateItem: Partial<IPartnerTeamOtherInfo>) => {
      isFunction(onChange) && onChange(updateItem);
    },
    [onChange]
  );

  const getSaleOptions = React.useCallback(
    (
      key: Pick<
        IPartnerTeamOtherInfo,
        "customerType" | "saleTeam" | "region" | "productLine"
      >
    ) => {
      const optionsMap = new Map([
        [
          InfoSalesModel.CUSTOMER_TYPE,
          OrderRequestModel.getCustomerTypeOptions(),
        ],
        [InfoSalesModel.SALE_TEAM, OrderRequestModel.getSaleTeamOptions()],
        [InfoSalesModel.REGION, regions],
        [InfoSalesModel.PRODUCT_LINE, productLines],
      ]);

      return optionsMap.get(key as any) ?? [];
    },
    [regions, productLines]
  );

  const customerTypesChangeHandler = React.useCallback(
    (customerType: number) => {
      submitChange({ customerType });
    },
    [submitChange]
  );

  const saleTeamChangeHandler = React.useCallback(
    (saleTeam: number) => {
      submitChange({ saleTeam });
    },
    [submitChange]
  );

  const regionChangeHandler = React.useCallback(
    (region: number) => {
      submitChange({
        region,
        productLine: null,
        rsm: "",
        so: OrderRequestModel.getServiceCoordinatorLabel(saleRegions, region),
      });
    },
    [saleRegions, submitChange]
  );

  const productLineChangeHandler = React.useCallback(
    (productLine: number) => {
      submitChange({
        productLine,
        rsm: OrderRequestModel.getServiceSupervisorLabel(
          saleRegions,
          infos?.region as number,
          productLine
        ),
      });
    },
    [submitChange, saleRegions, infos?.region]
  );

  const changeHandler = React.useCallback(
    (key: keyof Partial<IPartnerTeamOtherInfo>, value: any) => {
      const handlerMap = new Map([
        [InfoSalesModel.CUSTOMER_TYPE, customerTypesChangeHandler],
        [InfoSalesModel.SALE_TEAM, saleTeamChangeHandler],
        [InfoSalesModel.REGION, regionChangeHandler],
        [InfoSalesModel.PRODUCT_LINE, productLineChangeHandler],
      ]);

      const handler = handlerMap.get(key);
      if (!isFunction(handler)) return;
      handler(value);
    },
    [
      customerTypesChangeHandler,
      saleTeamChangeHandler,
      regionChangeHandler,
      productLineChangeHandler,
    ]
  );

  const folderChangeHandler = React.useCallback((visible: boolean) => {
    setVisible(visible);
  }, []);

  return {
    infos,
    pageStatus,
    productItems,
    visible,
    orderStepSummary,
    locale,
    getSaleOptions,
    changeHandler,
    folderChangeHandler,
  };
}
