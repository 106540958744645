import React from "react";

interface ISuccess {
  size?: "auto" | "default";
}

export default function (props: ISuccess): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "25",
    height = size === "auto" ? "100%" : "24";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3353 13.6823L16.3364 7.38123L15.6675 6.63794L9.3353 12.3369L6.33645 9.63794L5.66748 10.3812L9.3353 13.6823Z"
        fill="#1E8565"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 10.01C20.002 15.5329 15.5248 20.01 10.002 20.01C4.47911 20.01 0.00195313 15.5329 0.00195313 10.01C0.00195313 4.48716 4.47911 0.0100098 10.002 0.0100098C15.5248 0.0100098 20.002 4.48716 20.002 10.01ZM19.002 10.01C19.002 14.9806 14.9725 19.01 10.002 19.01C5.03139 19.01 1.00195 14.9806 1.00195 10.01C1.00195 5.03945 5.03139 1.01001 10.002 1.01001C14.9725 1.01001 19.002 5.03945 19.002 10.01Z"
        fill="#1E8565"
      />
    </svg>
  );
}
