import { Icon, LoadingSpinner } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React, { ReactNode, useMemo } from "react";
import { isString } from "../../utils/isType";
import AutoCenter from "../AutoCenter/AutoCenter";
import type { MaskProps } from "../Mask/Mask";
import Mask from "../Mask/Mask";
import { mergeProps } from "../utils/mergeDefaultProps";
import { GetContainer } from "../utils/renderToContainer";
import { PropagationEvent } from "../utils/withStopPropagation";
import styles from "./Toast.module.scss";

export interface ToastProps {
  afterClose?: () => void;
  maskStyle?: MaskProps["style"];
  maskClassName?: string;
  maskClickable?: boolean;
  contentWrapClassName?: string;
  content?: ReactNode;
  icon?: "success" | "fail" | "loading" | React.ReactNode;
  duration?: number;
  position?: "top" | "bottom" | "center";
  visible?: boolean;
  getContainer?: GetContainer;
  stopPropagation?: PropagationEvent[];
  destroyOnClose?: boolean;
}

const defaultProps = {
  maskClickable: true,
  stopPropagation: ["click"],
};

export const InternalToast: React.FC<ToastProps> = (p: ToastProps) => {
  const props = mergeProps(defaultProps, p);
  const {
    maskClickable,
    content,
    icon,
    position,
    destroyOnClose = true,
  } = props;

  const top = useMemo(() => {
    switch (position) {
      case "top":
        return "20%";
      case "bottom":
        return "80%";
      default:
        return "50%";
    }
  }, [position]);

  const iconView = React.useMemo(() => {
    const iconMap = new Map<string, React.ReactNode>([
      ["fail", <Icon icon={"StatusInfo"} size={48} key={"close"} />],
      ["success", <Icon icon={"Benefits"} size={20} key={"close"} />],
      ["loading", <LoadingSpinner size="xs" key={"loading"} />],
    ]);

    return isString(icon) ? iconMap.get(icon) : icon;
  }, [icon]);

  return (
    <Mask
      visible={props.visible}
      destroyOnClose={destroyOnClose}
      opacity={0}
      disableBodyScroll={!maskClickable}
      getContainer={props.getContainer}
      afterClose={props.afterClose}
      style={{
        pointerEvents: maskClickable ? "none" : "auto",
        ...props.maskStyle,
      }}
      className={classNames(styles.toastMask, props.maskClassName)}
      stopPropagation={props.stopPropagation}
    >
      <div className={styles.toastWrap}>
        <div
          style={{ top }}
          className={classNames(
            styles.toastMain,
            props.contentWrapClassName,
            icon ? styles.toastMainIcon : styles.toastMainText
          )}
        >
          <AutoCenter>
            <div>{iconView}</div>
            {content}
          </AutoCenter>
        </div>
      </div>
    </Mask>
  );
};
