import { Icon, TextInput } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";

import { ErrorDetectorModalView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { Popup, PopupProps } from "../../../components/Popup/Popup";
import Correct from "../../../components/svg/Correct";
import { getCssVariable } from "../../../utils/commonUtils";
import { isFunction } from "../../../utils/isType";
import useLang from "../../hooks/useLang";
import InputVariant from "../InputVariant/InputVariant";
import styles from "./Selector.module.scss";

interface ISelector extends Omit<PopupProps, "visible"> {
  label: string;
  disabled?: boolean;
  title?: string;
  showConfirm?: boolean;
  options: {
    text: string;
    value: any;
    subText?: string;
  }[];
  value?: any;
  variantClassName?: string;
  search?: boolean;
  onChange?: (value: any) => void;
}

enum ConfirmType {
  cancel = "cancel",
  ok = "ok",
}

const Selector: React.FC<ISelector> = ErrorDetectorModalView(
  (props: ISelector) => {
    const {
      options,
      title,
      showConfirm,
      value,
      label,
      disabled,
      variantClassName,
      search,
      onChange,
      ...rest
    } = props;
    const { labelCancel, labelOk } = useLang({
      labelCancel: { id: "common_btn_cancel" },
      labelOk: { id: "common_ok_upper" },
    });
    const formatStateValue = React.useCallback(
      (
        value: string | number,
        options: { text: string | React.ReactElement; value: any }[]
      ) => {
        return (
          options?.find(({ value: originValue }) => originValue === value)
            ?.text ?? ""
        );
      },
      []
    );
    const stateText = formatStateValue(value, options) as string;
    const [stateOptions, setOptions] = React.useState(() => options);
    const [stateValue, setState] = React.useState(value);
    const [searchValue, setSearch] = React.useState("");
    const [stateVisible, setVisible] = React.useState(false);
    const preValue = React.useRef(value);
    const inputCls = classNames(
      styles.textInputContainer,
      styles.textInput,
      { [styles.textInputContainerDisabled]: disabled },
      variantClassName
    );

    React.useEffect(() => setOptions(options), [options]);

    React.useEffect(() => {
      setState(value);
      preValue.current = value;
    }, [value, options]);

    const changeHandler = React.useCallback(
      (value: any) => {
        const { text } =
          stateOptions.find(
            ({ value: originValue }) => originValue === value
          ) ?? {};
        setState(text as string);
        if (showConfirm || !isFunction(onChange)) return;
        onChange(value);
        setVisible(false);
      },
      [stateOptions, showConfirm, onChange]
    );

    const confirmHandler = React.useCallback(
      (actionType: ConfirmType) => {
        setVisible(false);
        isFunction(rest.onClose) && rest.onClose();
        if (actionType === ConfirmType.cancel) return;
        isFunction(onChange) && onChange(stateValue);
      },
      [rest, stateValue, onChange]
    );

    const afterCloseHandler = React.useCallback(() => {
      setState(preValue.current);
      isFunction(rest?.afterClose) && rest?.afterClose();
    }, [rest]);

    const visibleChangeHandler = React.useCallback(
      () => !disabled && setVisible(true),
      [disabled]
    );

    const titleView = React.useMemo(
      () => (
        <div className={styles.title}>
          <div className={styles.titleLabel}>{title}</div>
        </div>
      ),
      [title]
    );

    const searchHandler = React.useCallback(
      (evt: any) => {
        const { value } = evt?.target ?? {};
        const _value = value?.trim()?.toLocaleLowerCase();

        const newOptions = _value
          ? options.filter(
              ({ text, subText }) =>
                text?.toLocaleLowerCase()?.includes(_value) ||
                subText?.toLocaleLowerCase()?.includes(_value)
            )
          : options;
        setState(value);
        setSearch(value);
        setOptions(newOptions);
      },
      [options]
    );

    const clearHandler = React.useCallback(() => {
      setState("");
      setSearch("");
      setOptions(options);
    }, [options]);

    const confirmView = React.useMemo(() => {
      return (
        <div className={styles.confirm}>
          <div
            className={styles.cancel}
            onClick={confirmHandler.bind(null, ConfirmType.cancel)}
          >
            {labelCancel}
          </div>
          <div
            className={styles.ok}
            onClick={confirmHandler.bind(null, ConfirmType.ok)}
          >
            {labelOk}
          </div>
        </div>
      );
    }, [confirmHandler, labelCancel, labelOk]);

    const optionsView = React.useMemo(() => {
      return (
        <div className={styles.optionsList}>
          {stateOptions?.map(({ text, value, subText }) => {
            const itemView = (
              <div
                className={styles.itemView}
                onClick={changeHandler.bind(null, value)}
              >
                <div>{text}</div>
                <div>{subText}</div>
              </div>
            );

            return (
              <div className={styles.optionItem} key={value}>
                {itemView}
                {value === stateValue && (
                  <span className={styles.icon}>
                    <Correct />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    }, [stateOptions, stateValue, changeHandler]);

    const iconView = React.useMemo(() => {
      const iconCls = classNames(styles.arrow, {
        [styles.arrowActive]: !!stateValue,
      });
      const fill = getCssVariable("--fill-color-headline");
      return (
        <span className={iconCls}>
          <Icon icon={"ChevronExpand"} size={16} fill={fill} />
        </span>
      );
    }, [stateValue]);

    const searchView = React.useMemo(
      () => (
        <div className={styles.textSearchContainer}>
          {search && (
            <Icon icon={"Search"} size={16} className={styles.search} />
          )}
          <TextInput
            label={""}
            value={searchValue}
            readOnly={!search}
            placeholder={"Search"}
            onInput={searchHandler}
            className={styles.input}
          />
          <span onClick={clearHandler}>
            <Icon icon={"Close"} size={16} className={styles.clear} />
          </span>
        </div>
      ),
      [searchValue, search, searchHandler, clearHandler]
    );

    return (
      <>
        <div className={inputCls}>
          <InputVariant
            label={label}
            value={stateText}
            readOnly
            onClick={visibleChangeHandler}
            disabled={disabled}
          />
          {iconView}
        </div>
        <Popup
          {...rest}
          afterClose={afterCloseHandler}
          visible={stateVisible}
          closeOnMaskClick={true}
          onClose={() => setVisible(false)}
        >
          <div className={styles.selectForMobile}>
            {showConfirm && confirmView}
            {title && titleView}
            {search && searchView}
            {optionsView}
          </div>
        </Popup>
      </>
    );
  }
);

export default Selector;
