import React from "react";
import { useSetRecoilState } from "recoil";
import SystemService from "../core/SystemService";
import { useThrottleFn } from "../hooks";
import { platformState } from "../store";

export default function () {
  const setPlatform = useSetRecoilState(platformState);
  const sizeChangeHandLer = () => {
    const platform = SystemService.getViewPlatform();
    setPlatform(platform);
  };

  const { run } = useThrottleFn(sizeChangeHandLer, {
    wait: 100,
    leading: true,
    trailing: true,
  });

  React.useEffect(() => {
    window.addEventListener("resize", run, false);
  }, [run]);
}
