import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../services/user.service";
import { isObject } from "../../../../utils/isType";
import FoldCabinet from "../../../components/FoldCabinet/FoldCabinet";
import Text from "../../../components/Text/Text";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { Role, SalesAction } from "../../../service/userRoleTypes";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import MoreDetailView from "../../components/MoreDetailView/MoreDetailView";
import { Counter } from "../components/Counter/Counter";
import styles from "./SplitOrder.module.scss";
import SplitOrderModel, { ISplitItem, SplitType } from "./SplitOrderModel";
import useSplitOrder from "./useSplitOrder";

const SplitOrderMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    otherProductItems,
    mainProduct,
    folded,
    labelOrder,
    _splitItems,
    labelAdd,
    labelSplitDevice,
    reminderSplit,
    labelCancel,
    labelConfirm,
    rolePage,
    _reminder,
    assignAndForwardVisible,
    userId,
    parentInstallationTaskId,
    locale,
    confirmHandler,
    beforeChangeHandler,
    changeHandler,
    setFold,
    splitChangeHandler,
    submitOrderHandler,
    backAllTickHandler,
    moreActionHandler,
    setAssignAndForwardVisibleVisible,
  } = useSplitOrder();

  const productView = React.useMemo(() => {
    const showMain = isObject(mainProduct) && !!mainProduct?.itemOrderQuantity;
    const showProductItems =
      Array.isArray(otherProductItems) && !!otherProductItems.length;
    return (
      <div className={styles.productView}>
        {showMain && (
          <MoreDetailView {...mainProduct} className={styles.mainProduct} />
        )}
        {showProductItems && (
          <FoldCabinet status={folded} onChange={(folded) => setFold(folded)}>
            <div className={styles.moreDetailContainer}>
              {otherProductItems?.map((item, index) => (
                <MoreDetailView
                  itemDescription={item?.itemDescription}
                  itemMaterial={item?.itemMaterial}
                  itemOrderQuantity={item?.itemOrderQuantity}
                  key={index}
                  className={styles.subProduct}
                />
              ))}
            </div>
          </FoldCabinet>
        )}
      </div>
    );
  }, [mainProduct, otherProductItems, folded, setFold]);

  const splitItemView = React.useCallback(
    (item: ISplitItem) => {
      const {
        itemDescription,
        itemMaterial,
        itemOrderQuantity,
        editable,
        max,
        index,
      } = item;

      const countView = editable ? (
        <Counter
          value={itemOrderQuantity}
          onChange={(value) => changeHandler(value, item)}
          beforeChange={(pre, next) => beforeChangeHandler(pre, next, item)}
          max={max}
          inputAble={true}
        />
      ) : (
        <span className={styles.quantity}>{`x${itemOrderQuantity}`}</span>
      );
      const itemIndex = `${labelOrder} ${index}`;

      return (
        <div className={styles.splitItem} key={index}>
          <div className={styles.itemIndex}>{itemIndex}</div>
          <div className={styles.itemContent}>
            <div className={styles.contentInfo}>
              <div className={styles.infoDes}>{itemDescription}</div>
              <div className={styles.infoMaterial}>{itemMaterial}</div>
            </div>
            {countView}
          </div>
        </div>
      );
    },
    [labelOrder, changeHandler, beforeChangeHandler]
  );

  const splitView = React.useMemo(() => {
    if (!Array.isArray(_splitItems) || !_splitItems.flat().length) return null;
    const views = _splitItems.map((item) => splitItemView(item?.[0]));
    const mainProduct = _splitItems.flat().find(({ isMain }) => isMain);
    const showAdd = (mainProduct?.itemOrderQuantity as number) > 1;

    return (
      <div className={styles.splitView}>
        <div className={styles.splitTitle}>
          <Text label={labelSplitDevice} labelTextClassName={styles.labelTitle}>
            {showAdd && (
              <div
                className={styles.add}
                onClick={splitChangeHandler.bind(
                  null,
                  SplitType.Plus,
                  undefined
                )}
              >
                {labelAdd}
              </div>
            )}
          </Text>
        </div>
        <TextReminder label={reminderSplit} className={styles.splitReminder} />
        <div className={styles.splitDevices}>{views}</div>
      </div>
    );
  }, [
    _splitItems,
    labelAdd,
    labelSplitDevice,
    reminderSplit,
    splitChangeHandler,
    splitItemView,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, labelConfirm, confirmHandler]
  );

  const buttonViews = React.useMemo(() => {
    const list = SplitOrderModel.getButtonViewMobileModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [SalesAction.FORWARD, { onSelect: moreActionHandler }],
      [SalesAction.SUBMIT, { onClick: submitOrderHandler }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    locale,
    backAllTickHandler,
    moreActionHandler,
    submitOrderHandler,
  ]);

  return (
    <section className={styles.splitOrder}>
      <div className={styles.pageContainer}>
        {productView}
        {splitView}
      </div>
      {buttonViews}
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          onClose={(visible: boolean) =>
            setAssignAndForwardVisibleVisible(visible)
          }
          action={Action.FORWARD}
          role={Role.ROLE_SALES}
          feature={Feature.SPLIT_ORDER}
          searchQuery={{ salesUserId: userId }}
          actionData={{
            selectUserIdKey: "salesRBACCorrelationId",
            parentInstallationTaskId,
          }}
          onActionResponse={backAllTickHandler}
        />
      )}
      <Confirmor
        footer={confirmButtonView}
        visible={!!_reminder}
        content={_reminder}
      />
    </section>
  );
});

export default SplitOrderMobile;
