export enum Role {
  ROLE_ADMIN = 99999,
  ROLE_SALES_MANAGER = 10,
  ROLE_RSM_MANAGER = 20,
  ROLE_AP_MANAGER = 30,
  ROLE_SALES = 1,
  ROLE_RSM = 2,
  ROLE_FSE = 3,
  ROLE_SO = 4,
  ROLE_APPLY_SUPERVISOR = 5,
  ROLE_APPLY_ENGINEER = 6,
  ROLE_NONE = -1,
}

export enum SalesAction {
  ALL_TICKET = "allTicket",
  FORWARD = "forward",
  DELETE_ORDER = "deleteOrder",
  SUBMIT = "submit",
  UPDATE = "update",
}

export enum SalesManagerAction {
  ALL_TICKET = "allTicket",
}

export enum RsmAction {
  ALL_TICKET = "allTicket",
  FORWARD = "forward",
  ASSIGN = "assign",
  REJECT = "reject",
  SUBMIT = "submit",
  NEED_SITE_SURVEY = "needSiteSurvey",
}

export enum RsmManagerAction {
  ALL_TICKET = "allTicket",
}

export enum FseAction {
  ALL_TICKET = "allTicket",
  REJECT = "reject",
  SUBMIT = "submit",
  UPDATE = "update",
}

export enum SoAction {
  ALL_TICKET = "allTicket",
  REJECT = "reject",
  APPROVE = "approve",
  CONFIRM = "confirm",
}

export enum ApSupervisorAction {
  ALL_TICKET = "allTicket",
  FORWARD = "forward",
  ASSIGN = "assign",
  REJECT = "reject",
  SUBMIT = "submit",
}

export enum ApEngineerAction {
  ALL_TICKET = "allTicket",
  REJECT = "reject",
  SUBMIT = "submit",
}

export enum ApManagerAction {
  ALL_TICKET = "allTicket",
}
