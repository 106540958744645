import { isEqual } from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorDetectorPageView } from "../../components/ErrorDecorator/ErrorDecorator";
import Tabs from "../../components/Tabs/Tabs";
import { ErrorCode, PageError } from "../../core/ErrorCodeService";
import { ErrorRes } from "../../core/HttpService";
import { SystemPlatform } from "../../core/SystemService";
import { useTitle } from "../../hooks";
import {
  IOrderBasicInfo,
  IOrderBasicInfoRes,
} from "../../services/order.service";
import {
  errorPageState,
  loadState,
  localeState,
  orderParentTaskIdState,
  orderRequestTabs,
  orderStatus,
  orderTabState,
  orderTaskIdState,
  platformState,
  stepState,
  systemUserOrgs,
  userState,
} from "../../store";
import { queryLikeUrlLastParam } from "../../utils/commonUtils";
import HomeModel from "../Home/HomeModel";
import { useErrorMsg } from "../hooks/useErrorMsg";
import useLang from "../hooks/useLang";
import { PagePaths, PageSubItems } from "../service/pageType";
import {
  disposeOrderStepSummary,
  getOrderRequestSubTabs,
} from "../service/roleStateService/RoleStepPageService";
import FacIbase from "./FacIBase/FacIBase";
import Installation from "./Installation/Installation";
import OrderInfo from "./OrderInfo/OrderInfo";
import SplitOrderModel from "./OrderInfo/SplitOrder/SplitOrderModel";
import styles from "./OrderRequest.module.scss";
import SiteSurvey from "./SiteSurvey/SiteSurvey";
import CommentsView from "./components/CommentView/CommentsView";
import OrderHeader from "./components/OrderHeader/OrderHeader";
import OrderRequestModel, { OrderTabKey } from "./model/OrderRequestModel";

const OrderRequest: React.FC = ErrorDetectorPageView(() => {
  const { title, info, siteSurvey, installation, fac } = useLang({
    title: { id: "order_title_detail" },
    info: { id: "common_info_title" },
    siteSurvey: { id: "common_site_survey_title" },
    installation: { id: "common_install_title" },
    fac: { id: "common_fac_title" },
  });
  const [tabs, setTabs] = useRecoilState(orderRequestTabs);
  const [orderBasicInfo, setBasicInfo] = React.useState({} as IOrderBasicInfo);
  const [errorCode, setError] = React.useState<ErrorCode>();
  const userInfo = useRecoilValue(userState);
  const [orderTab, setOrderTab] = useRecoilState(orderTabState);
  const locale = useRecoilValue(localeState);
  const setInstallationTaskId = useSetRecoilState(orderTaskIdState);
  const setParentInstallationTaskId = useSetRecoilState(orderParentTaskIdState);
  const setStep = useSetRecoilState(stepState);
  const [org, setOrg] = useRecoilState(systemUserOrgs);
  const toastError = useErrorMsg(errorCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);
  const navigate = useNavigate();
  const setErrorPage = useSetRecoilState(errorPageState);
  const setOrderStatus = useSetRecoilState(orderStatus);
  const _installationTaskId = queryLikeUrlLastParam(
    HomeModel.QUERY_INSTALLATION_TASK_ID
  );
  const _parentInstallationTaskId = queryLikeUrlLastParam(
    HomeModel.QUERY_PARENT_INSTALLATION_TASK_ID
  );

  const platform = useRecoilValue(platformState);
  useTitle(title);

  const getBasicInfoResolveHandler = React.useCallback(
    (res: IOrderBasicInfoRes) => {
      const orderStepSummary = disposeOrderStepSummary(
        res?.data.orderStepSummary,
        userInfo
      );
      setBasicInfo({
        ...res?.data,
        orderStepSummary,
      });
      setOrderStatus(res?.data.orderStatus);
    },
    [setOrderStatus, userInfo]
  );

  const getBasicInfoRejectHandler = React.useCallback(
    (res: ErrorRes) => {
      const pageError = `${res?.errorCode}`.startsWith("5")
        ? PageError.ERROR_500
        : PageError.ERROR_404;
      setError(res?.errorCode);
      toastError();
      setErrorPage(pageError);
      navigate(PagePaths.PATH_ERROR_PAGE, { replace: true });
    },
    [navigate, setErrorPage, toastError]
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  React.useEffect(() => {
    OrderRequestModel.getSaleOrg(locale)
      .then((res) => {
        if (isEqual(res?.data, org)) return;
        setOrg(res?.data);
      })
      .catch((res) => {
        setError(res?.errorCode);
        toastError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  React.useEffect(() => {
    if (!_installationTaskId && !_parentInstallationTaskId) {
      backAllTickHandler();
    }
    setLoading(true);
    if (_parentInstallationTaskId) {
      setParentInstallationTaskId(_parentInstallationTaskId);
      SplitOrderModel.getSplitOrderBasicInfo(_parentInstallationTaskId)
        .then(getBasicInfoResolveHandler)
        .catch(getBasicInfoRejectHandler)
        .finally(() => setLoading(false));
      return;
    }
    if (_installationTaskId) {
      setInstallationTaskId(_installationTaskId);
      OrderRequestModel.getOrderBasicInfo(_installationTaskId)
        .then(getBasicInfoResolveHandler)
        .catch(getBasicInfoRejectHandler)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_installationTaskId, _parentInstallationTaskId]);

  React.useEffect(() => {
    const { orderStepSummary } = orderBasicInfo;
    if (!orderStepSummary?.userSteps || !userInfo) return;
    setStep(orderStepSummary);
    const { subTabs } = getOrderRequestSubTabs(
      userInfo.usersRole,
      orderStepSummary
    );
    setTabs(subTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBasicInfo, userInfo]);

  React.useEffect(() => {
    if (!Array.isArray(tabs) || !tabs?.length) return;
    const activeTab = tabs[tabs.length - 1]?.subItem;
    if (!activeTab) return;
    setOrderTab(activeTab as PageSubItems);
  }, [setOrderTab, tabs]);

  const getTabLabels = React.useCallback(
    (tab: PageSubItems) => {
      const tabLabelMaps = new Map<PageSubItems, string>([
        [PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO, info],
        [PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY, siteSurvey],
        [PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION, installation],
        [PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC, fac],
      ]);
      return tabLabelMaps.get(tab) ?? "";
    },
    [info, siteSurvey, installation, fac]
  );

  const tabList = React.useMemo(() => {
    const tabMaps = new Map<PageSubItems, React.ReactNode>([
      [
        PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        <OrderInfo key={OrderTabKey.INFO} />,
      ],
      [
        PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        <SiteSurvey key={OrderTabKey.SITE_SURVEY} />,
      ],
      [
        PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        <Installation key={OrderTabKey.INSTALLATION} />,
      ],
      [
        PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
        <FacIbase key={OrderTabKey.FAC_IBASE} />,
      ],
    ]);

    return tabs?.map(({ subItem }) => ({
      key: subItem,
      label: getTabLabels(subItem),
      children: tabMaps.get(subItem),
    }));
  }, [getTabLabels, tabs]);

  const tabViews = React.useMemo(
    () =>
      !!tabList?.length && (
        <Tabs
          destroyInactiveTabPane={false}
          items={tabList}
          activeKey={orderTab}
          onChange={(accessKey) => setOrderTab(accessKey as PageSubItems)}
          className={styles.tabs}
        />
      ),
    [orderTab, setOrderTab, tabList]
  );

  const mobilePlatformView = React.useMemo(
    () => (
      <section className={styles.orderRequestMobile}>
        <OrderHeader {...orderBasicInfo} />
        {tabViews}
      </section>
    ),
    [orderBasicInfo, tabViews]
  );

  const pcPlatformView = React.useMemo(
    () => (
      <section className={styles.orderRequestPc}>
        <div className={styles.tabView}>{tabViews}</div>
        <div className={styles.pcBasicInfoAndComments}>
          <OrderHeader {...orderBasicInfo} />
          <CommentsView />
        </div>
      </section>
    ),
    [orderBasicInfo, tabViews]
  );

  return platform === SystemPlatform.ENV_PC
    ? pcPlatformView
    : mobilePlatformView;
});

export default OrderRequest;
