import React from "react";
import { useRecoilValue } from "recoil";
import { SystemPlatform } from "../core/SystemService";
import { platformState } from "../store";

export interface PageWrapperProps {
  PagePc: React.ComponentType;
  PageMobile: React.ComponentType;
}

export default function (props: PageWrapperProps) {
  const platform = useRecoilValue(platformState);
  const { PagePc, PageMobile } = props;
  const viewMap = new Map([
    [SystemPlatform.ENV_PC, <PagePc key={SystemPlatform.ENV_PC} />],
    [SystemPlatform.ENV_MOBILE, <PageMobile key={SystemPlatform.ENV_MOBILE} />],
  ]);

  return viewMap.get(platform) ?? <></>;
}
