import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./SideNavigation.module.scss";

export interface SideNavigationSubCategoryProps {
  /** The sub category's title */
  title: string | React.ReactNode;
  /** The sub category's url */
  path: string;
  /** Determine if link is active */
  active?: boolean;
  /** customized class name */
  className?: string;
  /** customized Node */
  customizedNode?: React.ReactNode;
  /**onClick trigger event */
  onClick?: (path: string, evt?: any) => void;
}

export const SideNavigationSubCategory: React.FC<SideNavigationSubCategoryProps> =
  ErrorDetectorSmallView((props: SideNavigationSubCategoryProps) => {
    const {
      title,
      path,
      active,
      className,
      customizedNode,
      onClick = () => {},
    } = props;
    const _className = classNames(
      styles.sideNavigationLink,
      styles.sideNavigationSubCategory,
      { [styles.active]: active },
      className
    );
    const _pathClassName = classNames(styles.path, {
      [styles.active]: active,
    });
    const clickHandler = (path: string, evt: MouseEvent) => {
      onClick(path);
      evt.stopPropagation();
    };

    return customizedNode ? (
      customizedNode
    ) : (
      <div
        className={_className}
        onClick={(evt) => clickHandler(path, evt as any)}
      >
        <span className={_pathClassName}>{title}</span>
      </div>
    );
  });
