import React, { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { stepState, userState } from "../../../store";
import useRolePage from "../../hooks/useRolePage";
import { PageStatus, PageSubItems } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import StepPageModel from "../model/StepPageModel";
import FacIBaseAll from "./FacIBaseAll/FacIBaseAll";
import FacIBaseCRMUpdate from "./FacIBaseCRM/FacIBaseCRMUpdate";
import FacIBaseFacUpload from "./FacIBaseFac/FacIBaseFacUpload";

export default function () {
  const { usersRole } = useRecoilValue(userState);
  const { key } = useRecoilValue(stepState);
  const stepNode = StepPageModel.getStepPageByTab(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
    key,
    usersRole
  );
  const { pageStatus } = useRolePage(PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC);
  const viewMap = new Map<Role, React.ReactNode>([
    [
      Role.ROLE_SALES_MANAGER,
      <FacIBaseFacUpload key={Role.ROLE_SALES_MANAGER} />,
    ],
    [Role.ROLE_SALES, <FacIBaseFacUpload key={Role.ROLE_SALES} />],
    [Role.ROLE_RSM_MANAGER, <FacIBaseFacUpload key={Role.ROLE_RSM_MANAGER} />],
    [Role.ROLE_RSM, <FacIBaseFacUpload key={Role.ROLE_RSM} />],
    [Role.ROLE_SO, <FacIBaseCRMUpdate key={Role.ROLE_SO} />],
  ]);

  if (pageStatus === PageStatus.READONLY) return <FacIBaseAll />;

  return (viewMap.get(usersRole) ?? stepNode) as ReactElement;
}
