import { isEmpty, isObject } from "lodash";
import { RegUtil } from "../../../utils/RegUtils";
import { isBoolean, isNumber, isString } from "./../../../utils/isType";

export enum VerifyType {
  OPTIONAL = 0,
  TYPE_NUMBER = 11,
  TYPE_STRING = 12,
  TYPE_LOCALE_ZH = 13,
  TYPE_PHONE = 14,
  TYPE_EMAIL = 15,
  TYPE_TIME_RANGE = 16,
  TYPE_POST_CODE = 17,
  TYPE_FILES = 18,
  LENGTH_MAX_COMPANY_NAME = 21,
  LENGTH_MAX_TEXTAREA = 22,
  LENGTH_MAX_CONTACT_NAME = 23,
  LENGTH_MAX_COMMON = 24,
}

export enum ErrorType {
  errCommon = "errCommon",
  errPhone = "errPhone",
  errEmail = "errEmail",
  errZh = "errZH",
  errLimitLength = "errLimitLength",
}

export interface IVerifyItem {
  types: [VerifyType] | [VerifyType, VerifyType];
  key: string;
  value: any;
}

export interface IValidationItem {
  valid: boolean;
  key: string;
  keyLabel: string;
  errCode: string;
  errMsg: string;
}

enum MaxLengthLimit {
  companyName = 24,
  textArea = 256,
  contactName = 32,
}

// Translation for key label and error message
// To do:  need to build validation data model from data mode, make verify decoupling from view model
export default class ValidationService {
  static readonly DEFAULT_VALID_ITEM = {
    valid: true,
    key: "",
    keyLabel: "",
    errCode: "",
    errMsg: "",
  };

  static verifyIntegrity = (
    infos: Record<string, any>,
    verifyKeys: (keyof Record<string, any>)[]
  ): boolean => {
    if (!isObject(infos) || !Array.isArray(verifyKeys)) return false;
    return verifyKeys.every((key: string) => {
      if (!infos.hasOwnProperty(key)) return false;
      const value = infos[key as keyof typeof infos];
      if (!isObject(value)) {
        if (isString(value))
          return ValidationService.getVerifier({
            type: VerifyType.TYPE_STRING,
            value,
          });

        if (isNumber(value) && value > 0)
          return ValidationService.getVerifier({
            type: VerifyType.TYPE_NUMBER,
            value,
          });

        if (isBoolean(value)) return true;

        return false;
      }
      return !isEmpty(value);
    });
  };

  static validProperty = (info: IVerifyItem): IValidationItem => {
    const { types, key, value } = info;
    if (!Array.isArray(types)) return ValidationService.DEFAULT_VALID_ITEM;
    if (types.includes(VerifyType.OPTIONAL) && isEmpty(value))
      return {
        valid: true,
        key,
        keyLabel: "",
        errCode: "",
        errMsg: "",
      };

    const valid = types.every((item) =>
      ValidationService.getVerifier({ type: item, value })
    );
    const errorCodes = types.map((item) => ValidationService.getErrType(item));
    const errCode =
      errorCodes.find((item) => item !== ErrorType.errCommon) ??
      (errorCodes[0] as string);

    return {
      valid,
      key,
      keyLabel: "",
      errCode,
      errMsg: "",
    };
  };

  static getVerifier = ({ type, value }: { type: VerifyType; value: any }) => {
    const verifyMaps = new Map([
      [VerifyType.TYPE_NUMBER, ValidationService.verifyNumber(value)],
      [VerifyType.TYPE_STRING, ValidationService.verifyString(value)],
      [VerifyType.TYPE_LOCALE_ZH, ValidationService.verifyLocalZH(value)],
      [VerifyType.TYPE_PHONE, ValidationService.verifyPhone(value)],
      [VerifyType.TYPE_EMAIL, ValidationService.verifyEmail(value)],
      [VerifyType.TYPE_TIME_RANGE, ValidationService.verifyTimeRange(value)],
      [VerifyType.TYPE_POST_CODE, ValidationService.verifyPostCode(value)],
    ]);
    return verifyMaps.get(type) ?? true;
  };

  private static getErrType = (type: VerifyType) => {
    const errMap = new Map([
      [VerifyType.TYPE_PHONE, ErrorType.errPhone],
      [VerifyType.TYPE_EMAIL, ErrorType.errEmail],
      [VerifyType.TYPE_NUMBER, ErrorType.errCommon],
      [VerifyType.TYPE_STRING, ErrorType.errCommon],
      [VerifyType.TYPE_TIME_RANGE, ErrorType.errCommon],
      [VerifyType.TYPE_LOCALE_ZH, ErrorType.errZh],
      [VerifyType.LENGTH_MAX_COMPANY_NAME, ErrorType.errLimitLength],
      [VerifyType.LENGTH_MAX_CONTACT_NAME, ErrorType.errLimitLength],
      [VerifyType.LENGTH_MAX_TEXTAREA, ErrorType.errLimitLength],
    ]);

    return errMap.get(type);
  };

  private static verifyNumber = (value: number) => isNumber(value);

  private static verifyString = (value: string) =>
    isString(value) && !!value?.trim().length;

  private static verifyLocalZH = (value: string) =>
    isString(value) && RegUtil.verifyZhName(value);

  private static verifyPhone = (value: string) =>
    RegUtil.verifyMobilePhone(value);

  private static verifyEmail = (value: string) => RegUtil.verifyEmail(value);

  private static verifyPostCode = (value: string) =>
    RegUtil.verifyPostCode(value);

  private static verifyTimeRange = (value: any[]) => {
    if (!Array.isArray(value) || value?.length !== 2) return false;
    return value.every((item) => isNumber(Date.parse(item)));
  };

  private static limitMaxInput = (type: Partial<VerifyType>, value: string) => {
    const lengthLimitMap = new Map([
      [VerifyType.LENGTH_MAX_COMPANY_NAME, MaxLengthLimit.companyName],
      [VerifyType.LENGTH_MAX_TEXTAREA, MaxLengthLimit.textArea],
      [VerifyType.LENGTH_MAX_CONTACT_NAME, MaxLengthLimit.contactName],
    ]);

    const limitLength = lengthLimitMap.get(type);
    if (!limitLength) return true;
    return value?.length <= limitLength;
  };
}
