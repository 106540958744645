import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "210",
    height = size === "auto" ? "100%" : "210";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 210 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M104.856 201.489C158.758 201.489 202.453 187.001 202.453 169.128C202.453 151.255 158.758 136.767 104.856 136.767C50.9553 136.767 7.25977 151.255 7.25977 169.128C7.25977 187.001 50.9553 201.489 104.856 201.489Z"
        fill="url(#paint0_linear_5211_26668)"
      />
      <path
        d="M39.1699 139.187C39.1699 144.088 35.2119 148.046 30.3105 148.046C25.4092 148.046 21.4512 144.088 21.4512 139.187C21.4512 134.285 25.4092 124.482 30.3105 124.482C35.2119 124.482 39.1699 134.285 39.1699 139.187Z"
        fill="#E6EAF0"
      />
      <path
        d="M34.3508 137.977C34.0637 137.833 33.7356 137.956 33.592 138.243L30.8645 143.76V133.342C30.8645 133.034 30.6184 132.768 30.2903 132.768C29.9827 132.768 29.7161 133.014 29.7161 133.342V138.674L26.8245 135.762C26.5989 135.536 26.2502 135.536 26.0247 135.762C25.7991 135.987 25.7991 136.336 26.0247 136.562L29.5315 140.068C29.593 140.13 29.6545 140.13 29.7366 140.15V155.572C29.7366 155.88 29.9827 156.146 30.3108 156.146C30.6184 156.146 30.885 155.9 30.885 155.572V146.303L34.6174 138.735C34.7405 138.448 34.6379 138.12 34.3508 137.977Z"
        fill="#D0D6E0"
      />
      <path
        d="M180.469 140.253C180.469 144.252 177.229 147.492 173.229 147.492C169.23 147.492 165.99 144.252 165.99 140.253C165.99 136.254 169.23 128.235 173.229 128.235C177.229 128.256 180.469 136.254 180.469 140.253Z"
        fill="#E6EAF0"
      />
      <path
        d="M176.531 139.269C176.305 139.166 176.018 139.248 175.916 139.474L173.701 143.985V135.475C173.701 135.229 173.496 135.003 173.229 135.003C172.983 135.003 172.758 135.208 172.758 135.475V139.843L170.379 137.464C170.194 137.279 169.907 137.279 169.722 137.464C169.538 137.648 169.538 137.936 169.722 138.12L172.594 140.991C172.635 141.032 172.696 141.032 172.758 141.053V153.645C172.758 153.891 172.963 154.116 173.229 154.116C173.475 154.116 173.701 153.911 173.701 153.645V146.077L176.757 139.884C176.859 139.679 176.777 139.392 176.531 139.269Z"
        fill="#D0D6E0"
      />
      <path
        d="M190.066 157.931C190.066 160.904 187.646 163.324 184.673 163.324C181.699 163.324 179.279 160.904 179.279 157.931C179.279 154.957 181.699 148.969 184.673 148.969C187.646 148.969 190.066 154.937 190.066 157.931Z"
        fill="#E6EAF0"
      />
      <path
        d="M187.134 157.192C186.97 157.11 186.765 157.172 186.662 157.356L185.001 160.72V154.362C185.001 154.178 184.837 154.014 184.653 154.014C184.468 154.014 184.304 154.178 184.304 154.362V157.623L182.52 155.839C182.376 155.695 182.171 155.695 182.028 155.839C181.884 155.982 181.884 156.187 182.028 156.331L184.16 158.464C184.201 158.505 184.242 158.505 184.283 158.505V167.897C184.283 168.082 184.448 168.246 184.632 168.246C184.817 168.246 184.981 168.082 184.981 167.897V162.237L187.257 157.623C187.36 157.479 187.298 157.274 187.134 157.192Z"
        fill="#D0D6E0"
      />
      <path
        d="M104.856 174.542L30.3105 156.146L61.4619 147.492L104.856 174.542Z"
        fill="url(#paint1_linear_5211_26668)"
      />
      <path
        d="M104.855 74.4023L47.4336 90.8906V155.511L104.855 136.377V74.4023Z"
        fill="url(#paint2_linear_5211_26668)"
      />
      <path
        d="M47.4336 90.8701L104.855 107.174V174.542L47.4336 155.593V90.8701Z"
        fill="url(#paint3_linear_5211_26668)"
      />
      <path
        d="M47.4336 90.9316L104.855 107.256L99.3184 136.254L47.4336 117.961V90.9316Z"
        fill="url(#paint4_linear_5211_26668)"
      />
      <path
        d="M47.4346 90.9316L104.856 107.256L96.1816 126.882L39.498 108.917L47.4346 90.9316Z"
        fill="url(#paint5_linear_5211_26668)"
      />
      <path
        d="M104.855 74.4023L162.277 90.8906V155.408L104.855 136.377V74.4023Z"
        fill="url(#paint6_linear_5211_26668)"
      />
      <path
        d="M162.277 90.8701L104.855 107.174V174.542L162.277 155.593V90.8701Z"
        fill="url(#paint7_linear_5211_26668)"
      />
      <path
        d="M162.277 90.9316L104.855 107.256L112.956 133.629L162.277 116.73V90.9316Z"
        fill="url(#paint8_linear_5211_26668)"
      />
      <path
        d="M162.277 90.9316L104.855 107.256L113.51 126.882L170.193 108.917L162.277 90.9316Z"
        fill="url(#paint9_linear_5211_26668)"
      />
      <path
        d="M128.07 36.2167C123.62 42.1024 117.796 46.7987 111.418 50.4901C104.507 54.4892 96.96 57.2577 89.3106 59.5546C84.8194 60.9081 80.2666 62.0771 75.7344 63.2665C75.6729 63.287 75.6934 63.3896 75.7549 63.369C83.958 61.2362 92.1817 59.1034 100.077 56.0067C107.029 53.2792 113.735 49.7724 119.58 45.0556C122.779 42.4716 125.691 39.5595 128.152 36.2782C128.193 36.2167 128.111 36.1757 128.07 36.2167Z"
        fill="#D3DBEB"
      />
      <path
        d="M129.752 46.0807C125.425 51.0846 120.647 55.6578 115.438 59.7389C113.9 60.9488 112.341 62.0973 110.721 63.2252C110.659 63.2662 110.721 63.3482 110.782 63.3072C116.258 59.4928 121.344 55.1451 125.979 50.3463C127.291 48.9723 128.563 47.5777 129.814 46.1422C129.875 46.1217 129.793 46.0396 129.752 46.0807Z"
        fill="#D3DBEB"
      />
      <path
        d="M146.098 75.9404C146.098 75.9404 153.296 77.6016 157.438 74.4023C161.581 71.2031 160.863 69.0908 160.986 66.958C160.986 66.958 164.288 66.3633 165.949 67.3066C165.949 67.4297 166.421 66.1172 164.534 66.0146C162.647 65.9121 157.972 65.666 156.29 67.4297C154.608 69.1934 153.788 73.6846 151.532 74.2793C151.409 74.5254 153.07 71.6748 153.768 69.4395C154.465 67.2041 156.126 65.1943 159.551 65.2969C159.551 65.2969 158.484 64.0049 156.721 64.0049C154.957 64.0049 152.701 64.7227 151.532 66.835C150.363 68.9473 149.748 76.3096 146.098 75.9404Z"
        fill="url(#paint10_linear_5211_26668)"
      />
      <path
        opacity="0.8"
        d="M141.381 62.7749C141.381 62.7749 137.094 61.4009 135.926 58.4477C134.757 55.4946 135.782 54.5923 136.377 53.4438C136.377 53.4438 134.818 52.1108 133.67 52.0903C133.629 52.1518 133.793 51.3315 134.818 51.8442C135.844 52.3774 138.366 53.6899 138.694 55.146C139.022 56.602 138.058 59.2065 139.063 60.2114C139.043 60.3755 139.063 58.3657 139.391 56.9712C139.719 55.5766 139.473 54.018 137.648 53.0132C137.648 53.0132 138.612 52.6645 139.535 53.2182C140.458 53.772 141.422 54.8384 141.381 56.3149C141.319 57.7915 139.35 61.811 141.381 62.7749Z"
        fill="url(#paint11_linear_5211_26668)"
      />
      <path
        opacity="0.8"
        d="M145.277 52.6021C145.277 52.6021 143.226 50.1617 143.616 47.9674C144.005 45.773 144.969 45.609 145.769 45.1373C145.769 45.1373 145.338 43.7633 144.662 43.3121C144.621 43.3326 145.01 42.9019 145.441 43.5992C145.872 44.2965 146.876 46.0396 146.528 47.024C146.179 48.0084 144.621 49.2184 144.846 50.2027C144.785 50.2848 145.543 49.0953 146.261 48.3775C146.979 47.6598 147.43 46.6344 146.692 45.3424C146.692 45.3424 147.389 45.4859 147.758 46.1627C148.107 46.8394 148.271 47.8443 147.697 48.7057C147.102 49.5875 144.415 51.2691 145.277 52.6021Z"
        fill="url(#paint12_linear_5211_26668)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5211_26668"
          x1="104.852"
          y1="136.754"
          x2="104.852"
          y2="201.497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE3F0" />
          <stop offset="0.0398184" stopColor="#E1E6F1" stopOpacity="0.9602" />
          <stop offset="0.3162" stopColor="#F2F4F9" stopOpacity="0.6838" />
          <stop offset="0.6198" stopColor="#FCFCFE" stopOpacity="0.3802" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5211_26668"
          x1="104.852"
          y1="161.019"
          x2="30.3032"
          y2="161.019"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCC0CC" />
          <stop offset="0.2941" stopColor="#BFC3CE" stopOpacity="0.7028" />
          <stop offset="0.5142" stopColor="#C9CCD6" stopOpacity="0.4803" />
          <stop offset="0.7103" stopColor="#D9DBE2" stopOpacity="0.2822" />
          <stop offset="0.8912" stopColor="#EFF0F3" stopOpacity="0.099242" />
          <stop offset="0.9894" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5211_26668"
          x1="47.4331"
          y1="114.965"
          x2="104.851"
          y2="114.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE0E6" />
          <stop offset="1" stopColor="#DFE3EB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5211_26668"
          x1="104.851"
          y1="132.702"
          x2="47.4331"
          y2="132.702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3BDC9" />
          <stop offset="1" stopColor="#D7DAE0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5211_26668"
          x1="64.9962"
          y1="128.109"
          x2="83.9932"
          y2="95.2055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C8D1" stopOpacity="0" />
          <stop offset="1" stopColor="#8F99AB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5211_26668"
          x1="104.852"
          y1="108.912"
          x2="39.5079"
          y2="108.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C8D1" />
          <stop offset="1" stopColor="#DFE3ED" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5211_26668"
          x1="162.269"
          y1="114.906"
          x2="104.851"
          y2="114.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCD3DB" />
          <stop offset="1" stopColor="#D6DAE3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5211_26668"
          x1="104.851"
          y1="132.702"
          x2="162.269"
          y2="132.702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE0E6" />
          <stop offset="1" stopColor="#D3D8E3" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5211_26668"
          x1="145.701"
          y1="126.294"
          x2="127.241"
          y2="94.3207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C8D1" stopOpacity="0" />
          <stop offset="1" stopColor="#C7CBD1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5211_26668"
          x1="104.851"
          y1="108.912"
          x2="170.195"
          y2="108.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E9F2" />
          <stop offset="1" stopColor="#DFE3ED" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5211_26668"
          x1="146.095"
          y1="70.1984"
          x2="165.996"
          y2="70.1984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE3EB" />
          <stop offset="1" stopColor="#B3B9C9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5211_26668"
          x1="143.176"
          y1="59.7414"
          x2="132.755"
          y2="53.5612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE3EB" />
          <stop offset="1" stopColor="#B3B9C9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5211_26668"
          x1="147.48"
          y1="51.4579"
          x2="143.55"
          y2="43.8414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE3EB" />
          <stop offset="1" stopColor="#B3B9C9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
