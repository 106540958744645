import { StaticSourceItem } from "../../../../services/order.service";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { ApSupervisorAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import { SystemPlatform } from "./../../../../core/SystemService";

export default class InstallationApSupervisorModel {
  static verifyAssign = (info: Partial<IInstallation>) => {
    const keys = [
      InstallationModel.PLAN_TRAINING_END_DATE,
      InstallationModel.PLAN_TRAINING_START_DATE,
    ];
    return ValidationService.verifyIntegrity(info, keys);
  };

  static getTimeViewMobileModel = (
    info: Partial<IInstallation>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { labelPlanTrainingStartTime, labelPlanTrainingEndTime } =
      InstallationModel.getLabels();
    const { planedTrainingStartDate, planedTrainingEndDate } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const formattedPlanedTrainingStartDate = formatDatePickValue(
        planedTrainingStartDate as number
      ),
      formattedPlanedTrainingEndDate = formatDatePickValue(
        planedTrainingEndDate as number
      );

    return [
      {
        key: InstallationModel.PLAN_TRAINING_START_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelPlanTrainingStartTime,
          value: formattedPlanedTrainingStartDate,
          key: InstallationModel.PLAN_TRAINING_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.PLAN_TRAINING_END_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelPlanTrainingEndTime,
          value: formattedPlanedTrainingEndDate,
          key: InstallationModel.PLAN_TRAINING_END_DATE,
          min: formattedPlanedTrainingStartDate,
          disabled,
        },
      },
    ];
  };

  static getTimeViewPcModel = (
    info: Partial<IInstallation>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { labelPlanTrainingStartTime, labelPlanTrainingEndTime } =
      InstallationModel.getLabels();
    const { planedTrainingStartDate, planedTrainingEndDate } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const formattedPlanedTrainingStartDate = formatDatePickValue(
        planedTrainingStartDate as number
      ),
      formattedPlanedTrainingEndDate = formatDatePickValue(
        planedTrainingEndDate as number
      );

    return [
      {
        key: InstallationModel.PLAN_TRAINING_START_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelPlanTrainingStartTime,
          value: formattedPlanedTrainingStartDate,
          key: InstallationModel.PLAN_TRAINING_START_DATE,
          disabled,
        },
      },
      {
        key: InstallationModel.PLAN_TRAINING_END_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelPlanTrainingEndTime,
          value: formattedPlanedTrainingEndDate,
          key: InstallationModel.PLAN_TRAINING_END_DATE,
          minDate: formattedPlanedTrainingStartDate,
          disabled,
        },
      },
    ];
  };

  static getInfoViewModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationEndDate,
      labelPlanedInstallationStartDate,
      labelActualInstallEndDate,
      labelActualInstallStartDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      actualInstallEndDate,
      actualInstallStartDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
    } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const expectedInstallDateLabel = formatDate(expectedInstallDate as number),
      planedInstallationStartDateLabel = formatDate(
        planedInstallationStartDate as number
      ),
      planedInstallationEndDateLabel = formatDate(
        planedInstallationEndDate as number
      ),
      actualInsStartDateLabel = formatDate(actualInstallStartDate as number),
      actualInsEndDateLabel = formatDate(actualInstallEndDate as number),
      facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      );

    const textViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: textViewType,
        props: {
          label: labelExpectedInstallDate,
          children: expectedInstallDateLabel,
          key: InstallationModel.EXPECTED_INSTALL_DATA,
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: textViewType,
        props: {
          label: labelPlanedInstallationStartDate,
          children: planedInstallationStartDateLabel,
          key: InstallationModel.PLANNED_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: textViewType,
        props: {
          label: labelPlanedInstallationEndDate,
          children: planedInstallationEndDateLabel,
          key: InstallationModel.PLANNED_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        viewType: textViewType,
        props: {
          label: labelActualInstallStartDate,
          children: actualInsStartDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_START_DATE,
        },
      },
      {
        key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        viewType: textViewType,
        props: {
          label: labelActualInstallEndDate,
          children: actualInsEndDateLabel,
          key: InstallationModel.ACTUAL_INSTALL_END_DATE,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: textViewType,
        props: {
          label: labelFacRequired,
          children: facLabel,
          key: InstallationModel.FAC_REQUIRED,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired ? textViewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: textViewType,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
          key: InstallationModel.TRAINING_REQUIRED,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired ? textViewType : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
          key: InstallationModel.AP_SUPERVISOR,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: textViewType,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return platform === SystemPlatform.ENV_MOBILE
      ? list.filter(({ hidden }) => !hidden)
      : list;
  };

  static getFileInfoViewModel = (
    infos: IInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      installationReports,
      siteSurveyReports,
      disclaimers,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelSiteSurveyReport,
      labelDisclaimer,
      labelInstallationReport,
    } = InstallationModel.getLabels();
    const uploadViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: uploadViewType,
        props: {
          title: labelDisclaimer,
          value: disclaimers ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DISCLAIMERS,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: uploadViewType,
        props: {
          title: labelDeliverNotes,
          value: deliveryNotes ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.DELIVERY_NOTES,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: uploadViewType,
        props: {
          title: labelEurOrEuu,
          value: euCs ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.EUC_EUU,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: uploadViewType,
        props: {
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.TECHNICAL_AGR,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: uploadViewType,
        props: {
          title: labelSpecs,
          value: specifications ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SPEC,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: uploadViewType,
        props: {
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.SITE_SURVEY_REPORT,
        },
      },
      {
        key: InstallationModel.INSTALLATION_REPORTS,
        viewType: uploadViewType,
        props: {
          title: labelInstallationReport,
          value: installationReports ?? [],
          deletable: false,
          disableUpload: true,
          key: InstallationModel.INSTALLATION_REPORTS,
        },
      },
    ];

    return originList.filter(({ props }) => !!props?.value?.length);
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelForward, labelAssign } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: ApSupervisorAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: ApSupervisorAction.ALL_TICKET,
        },
      },
      {
        key: ApSupervisorAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelForward,
          key: ApSupervisorAction.FORWARD,
        },
      },
      {
        key: ApSupervisorAction.ASSIGN,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelAssign,
          key: ApSupervisorAction.ASSIGN,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
