import type { TabsProps } from "rc-tabs";
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";
import React from "react";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import "./Tabs.module.scss";

// more info please find in https://github.com/react-component/tabs
export interface ITabs extends TabsProps {}

const Tab: React.FC<ITabs> = ErrorDetectorModalView((props: ITabs) => {
  return <Tabs {...props} />;
});

export default Tab;
