import RouterService from "../../../core/RouterService";
import { queryLikeUrlLastParam } from "../../../utils/commonUtils";
import { PagePaths } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import HomeModel from "../HomeModel";

export default class HomeMobileModel extends HomeModel {
  static getPagePath = (usersRole: Role): PagePaths => {
    const installationTaskId = queryLikeUrlLastParam(
      HomeModel.QUERY_INSTALLATION_TASK_ID
    );
    const parentInstallationTaskId = queryLikeUrlLastParam(
      HomeModel.QUERY_PARENT_INSTALLATION_TASK_ID
    );
    const rolePageIndexPath = RouterService.getRouterIndexPathByRole(usersRole);
    if (installationTaskId || parentInstallationTaskId)
      return PagePaths.PATH_ORDER_REQUEST;
    return rolePageIndexPath;
  };
}
