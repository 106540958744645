import React from "react";
import { useRecoilState } from "recoil";
import { CloseType } from "../../components/Confirmor/Confirmor";
import AuthService from "../../core/AuthService";
import { ErrorCode } from "../../core/ErrorCodeService";
import LocalStorageService from "../../core/LocalStorageService";
import SystemService, { SystemEnv } from "../../core/SystemService";
import { loadState } from "../../store";
import OrderRequestModel from "../OrderRequest/model/OrderRequestModel";
import { useErrorMsg } from "../hooks/useErrorMsg";
import useLang from "../hooks/useLang";

const LIMIT_TIME = 2000;
const LIMIT_COUNT = 3;

export default function () {
  const { logoutLabel, cancel, confirm } = useLang({
    logoutLabel: { id: "common_logout_reminder" },
    cancel: { id: "common_btn_cancel" },
    confirm: { id: "common_btn_confirm" },
  });
  const [clickStack, setClick] = React.useState<number[]>([]);
  const [confirmVisible, setConfirm] = React.useState(false);
  const [loading, setLoading] = useRecoilState(loadState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const toastError = useErrorMsg(errCode as ErrorCode);

  React.useEffect(() => {
    const { length } = clickStack;
    if (length < LIMIT_COUNT) return;
    const max = Math.max(...clickStack),
      min = Math.min(...clickStack);
    const confirmVisible = max - min <= LIMIT_TIME;
    setClick([]);
    setConfirm(confirmVisible);
  }, [clickStack]);

  const confirmCloseHandler = React.useCallback(
    (type: CloseType) => {
      setConfirm(false);
      if (type === "cancel") return;
      setLoading(true);
      OrderRequestModel.logout()
        .then(() => {
          LocalStorageService.removeStorage(LocalStorageService.CSRF_TOKEN);
          LocalStorageService.removeStorage(LocalStorageService.API_TOKEN);
          const path = AuthService.getLogoutRedirectUrl();
          window.location.replace(path);
        })
        .catch(() => {
          setError(ErrorCode.COMMON_ERROR);
        })
        .finally(() => {
          setLoading(false);
          setClick([]);
        });
    },
    [setLoading]
  );

  const clickHandler = React.useCallback(() => {
    if (SystemService.getEnv() === SystemEnv.ENV_PROD) return;
    setClick((pre) => [...pre, Date.now()]);
  }, []);

  return {
    logoutLabel,
    cancel,
    confirm,
    confirmVisible,
    loading,
    setLoading,
    setError,
    toastError,
    confirmCloseHandler,
    clickHandler,
  };
}
