import { formatMessage } from "../../core/LangService";

export default class HomeModel {
  static readonly QUERY_INSTALLATION_TASK_ID = "installationTaskId";
  static readonly QUERY_PARENT_INSTALLATION_TASK_ID =
    "parentInstallationTaskId";

  protected static getLabels = () => ({
    orderList: formatMessage("common_btn_all_tickets"),
    dashboard: formatMessage("common_page_title_dashboard"),
    orderDetail: formatMessage("order_title_detail"),
    orderManage: formatMessage("common_page_title_ticket_management"),
  });
}
