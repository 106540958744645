import React from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { IUserInfo } from "../../../../services/user.service";
import {
  loadState,
  localeState,
  orderTaskIdState,
  platformState,
  systemUserOrgs,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import InstallationModel, { IInstallation } from "../installationModel";

export default function () {
  const [infos, setInfos] = React.useState<Partial<IInstallation>>(
    {} as Partial<IInstallation>
  );
  const installationTaskId = useRecoilValue(orderTaskIdState);
  const [errCode, setError] = React.useState<ErrorCode>();
  const systemOrgs = useRecoilValue(systemUserOrgs);
  const platform = useRecoilValue(platformState);
  const locale = useRecoilValue(localeState);
  const { usersRole } = (useRecoilValue(userState) as IUserInfo) ?? {};
  const navigate = useNavigate();

  const toastError = useErrorMsg(errCode as ErrorCode);
  const setLoading = useSetRecoilState(loadState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getInfos(installationTaskId), [installationTaskId]);

  const getInfos = React.useCallback(
    (installationTaskId: string) => {
      if (!installationTaskId) return;
      setLoading(true);
      InstallationModel.getInstallationSummaryInfo(installationTaskId, locale)
        .then((res) => setInfos(res?.data))
        .catch((res) => {
          setError(res?.errorCode);
          toastError();
        })
        .finally(() => setLoading(false));
    },
    [setLoading, toastError, locale]
  );

  const backAllTickHandler = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    infos,
    platform,
    systemOrgs,
    locale,
    backAllTickHandler,
  };
}
