import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import { isEqual, isFunction } from "lodash";
import RcDropDown from "rc-dropdown";
import { DropdownProps } from "rc-dropdown/lib/Dropdown";
import React from "react";
import { ErrorDetectorSmallView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { getCssVariable } from "../../../utils/commonUtils";
import styles from "./DropDownVariant.module.scss";

export interface DropDownOption {
  value: string | number;
  text: string | number | React.ReactNode;
  children?: React.ReactNode;
  subText?: string | number | React.ReactNode;
}

export interface DropDownVariantProps extends Omit<DropdownProps, "children"> {
  options?: DropDownOption[];
  value?: string | number;
  disabled?: boolean;
  viewClassName?: string;
  onChange?: (value: string | number) => void;
}

const DropDownVariant: React.FC<DropDownVariantProps> = ErrorDetectorSmallView(
  (props: DropDownVariantProps) => {
    const {
      value,
      options,
      disabled,
      visible,
      viewClassName,
      onVisibleChange,
      onChange,
      ...rest
    } = props;

    const [_value, setValue] = React.useState(() => value);
    const [_visible, setVisible] = React.useState(() => visible);
    const [stateOptions, setOptions] = React.useState(() => options ?? []);
    const preOptionsRef = React.useRef(options);

    React.useEffect(() => setValue(value), [value]);
    React.useEffect(() => setVisible(visible), [visible]);
    React.useEffect(() => {
      if (isEqual(preOptionsRef?.current, options)) return;
      setOptions(options ?? []);
      preOptionsRef.current = options;
    }, [options]);

    const selectHandler = React.useCallback(
      (value: string | number) => {
        setValue(value);
        setVisible(false);
        isFunction(onChange) && onChange(value);
      },
      [onChange]
    );

    const visibleChangeHandler = React.useCallback(
      (visible: boolean) => {
        isFunction(onVisibleChange) && onVisibleChange(visible);
        setVisible(visible);
      },
      [onVisibleChange]
    );

    const valueText = React.useMemo(() => {
      const { text } =
        stateOptions?.find(({ value }) => value === _value) ?? {};
      return `${text ?? ""}`;
    }, [stateOptions, _value]);

    const dropDownIcon = React.useMemo(() => {
      const iconClassName = classNames(styles.arrow, {
        [styles.arrowActive]: _visible,
      });
      const fillNormal = getCssVariable("--fill-color-headline"),
        fillDisabled = getCssVariable("--border-color-inactive");
      const fill = disabled ? fillDisabled : fillNormal;

      return (
        <span
          className={iconClassName}
          onClick={(evt) => {
            setVisible((pre) => !pre);
          }}
        >
          <Icon icon={"ChevronExpand"} size={16} fill={fill} />
        </span>
      );
    }, [_visible, disabled]);

    const view = React.useMemo(() => {
      const _className = classNames(styles.view, viewClassName, {
        [styles.disabledView]: disabled,
      });
      return (
        <div className={_className}>
          <Icon icon="Language" size={12} />
          <span className={styles.viewText}>{valueText}</span>
          {dropDownIcon}
        </div>
      );
    }, [viewClassName, disabled, valueText, dropDownIcon]);

    const dropDownMenu = React.useMemo(() => {
      if (!Array.isArray(stateOptions) || !stateOptions?.length) return <></>;
      return (
        <ul className={styles.menu}>
          {stateOptions.map(({ value, text, children, subText }) => {
            const active = value === _value;
            const itemCls = classNames(styles.item, {
              [styles.itemActive]: active,
            });
            return (
              <li
                className={itemCls}
                key={value}
                onClick={selectHandler.bind(null, value)}
              >
                <div className={styles.itemContent}>
                  <div className={styles.itemText}>{text ?? children}</div>
                  <div className={styles.subText}>{subText}</div>
                </div>
                {active && <div className={styles.selected} />}
              </li>
            );
          })}
        </ul>
      );
    }, [stateOptions, _value, selectHandler]);

    return (
      <RcDropDown
        {...rest}
        overlay={dropDownMenu}
        visible={_visible}
        onVisibleChange={visibleChangeHandler}
      >
        {view}
      </RcDropDown>
    );
  }
);
DropDownVariant.defaultProps = {
  trigger: "click",
};

export default DropDownVariant;
