interface IImg {
  fillColor?: string;
  size?: "auto" | "default";
}

export const WordIcon = (props: IImg) => {
  const { size } = props;
  const width = size === "auto" ? "100%" : "54",
    height = size === "auto" ? "100%" : "54";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8 2C8 0.895429 8.89543 0 10 0H38L54 15V52C54 53.1046 53.1046 54 52 54H10C8.89543 54 8 53.1046 8 52V2Z"
        fill="#DCE3E9"
      />
      <path
        d="M8 33.7998C8 32.6952 8.89543 31.7998 10 31.7998H37C38.1046 31.7998 39 32.6952 39 33.7998V45.7998C39 46.9044 38.1046 47.7998 37 47.7998H8V33.7998Z"
        fill="#CAD2DB"
      />
      <rect y="30" width="39" height="16" rx="2" fill="#3574EF" />
      <path
        d="M9.0623 34.86H11.6723C12.8323 34.86 13.7023 35.18 14.3023 35.82C14.8723 36.42 15.1623 37.29 15.1623 38.43C15.1623 39.56 14.8723 40.43 14.3023 41.04C13.7023 41.68 12.8323 42 11.6723 42H9.0623V34.86ZM10.2323 35.86V41H11.4523C12.3423 41 12.9923 40.79 13.4023 40.38C13.8023 39.96 14.0023 39.31 14.0023 38.43C14.0023 37.53 13.8023 36.87 13.4023 36.47C12.9923 36.06 12.3423 35.86 11.4523 35.86H10.2323ZM19.4296 34.72C20.5196 34.72 21.3796 35.07 22.0096 35.78C22.6096 36.45 22.9096 37.34 22.9096 38.44C22.9096 39.54 22.6096 40.42 22.0096 41.09C21.3796 41.79 20.5196 42.14 19.4296 42.14C18.3296 42.14 17.4696 41.78 16.8496 41.08C16.2496 40.4 15.9596 39.52 15.9596 38.44C15.9596 37.35 16.2496 36.47 16.8496 35.79C17.4696 35.07 18.3296 34.72 19.4296 34.72ZM19.4296 35.75C18.6896 35.75 18.1196 36 17.7096 36.5C17.3196 36.98 17.1296 37.62 17.1296 38.44C17.1296 39.25 17.3196 39.89 17.7096 40.37C18.1096 40.86 18.6896 41.11 19.4296 41.11C20.1696 41.11 20.7396 40.87 21.1396 40.4C21.5296 39.93 21.7296 39.28 21.7296 38.44C21.7296 37.6 21.5296 36.94 21.1396 36.46C20.7396 35.98 20.1696 35.75 19.4296 35.75ZM27.1135 34.72C27.9535 34.72 28.6535 34.94 29.1935 35.38C29.7135 35.8 30.0235 36.38 30.1335 37.1H28.9935C28.8735 36.63 28.6535 36.29 28.3235 36.07C28.0035 35.85 27.5935 35.75 27.0935 35.75C26.3435 35.75 25.7835 36 25.4035 36.52C25.0535 36.98 24.8835 37.62 24.8835 38.44C24.8835 39.28 25.0535 39.93 25.3935 40.38C25.7635 40.86 26.3435 41.11 27.1235 41.11C27.6335 41.11 28.0535 40.98 28.3735 40.74C28.7135 40.47 28.9535 40.06 29.0935 39.52H30.2335C30.0735 40.36 29.7035 41.01 29.1135 41.48C28.5635 41.92 27.9035 42.14 27.1335 42.14C25.9435 42.14 25.0535 41.76 24.4735 41.02C23.9635 40.38 23.7135 39.52 23.7135 38.44C23.7135 37.38 23.9735 36.51 24.5035 35.85C25.1035 35.09 25.9735 34.72 27.1135 34.72Z"
        fill="white"
      />
      <path d="M38 0V13C38 14.1046 38.8954 15 40 15H54L38 0Z" fill="#B1B7BE" />
      <path d="M44 15L54 23.5V15H44Z" fill="#CAD2DB" />
    </svg>
  );
};
