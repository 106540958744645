import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import FoldCabinet from "../../../../../../components/FoldCabinet/FoldCabinet";
import Text from "../../../../../../components/Text/Text";
import TextReminder from "../../../../../../components/TextReminder/TextReminder";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import MoreDetailView from "../../../../../components/MoreDetailView/MoreDetailView";
import InfoSalesModel from "../../../InfoSalesModel";
import useOrderDnInfo, {
  OrderInfoDnProps,
} from "../../../hooks/useOrderDnInfo";
import InfoSalesMobileModel from "../../InfoSaleMobileModel";
import styles from "./OrderInfoDnView.module.scss";

const VIEW_SPLIT_NUMBER = 3;
const OrderInfoDnView: React.FC<OrderInfoDnProps> = ErrorDetectorMediumView(
  (props: OrderInfoDnProps) => {
    const {
      cpoDate,
      installationOrderNo,
      serialNumber,
      serviceContractorOrder,
      dnItems,
      dnDelivered,
      visible,
      notice,
      noticeType,
      multipleVisible,
      locale,
      setVisible,
      setMultipleVisible,
    } = useOrderDnInfo(props);

    const generateMultipleOrderNoView = React.useCallback(
      (key: string, label: string, numbers: string[]) => {
        if (!Array.isArray(numbers))
          return (
            <Text label={label} key={key}>
              {numbers}
            </Text>
          );
        if (numbers.length <= 1)
          return (
            <Text label={label} key={key}>
              {numbers[0]}
            </Text>
          );
        const _numbers = numbers.map((item, index) => [
          `${label} ${index + 1}`,
          item,
        ]);
        const preSubNumber = _numbers.slice(0, VIEW_SPLIT_NUMBER),
          nextSubNumber = _numbers.slice(VIEW_SPLIT_NUMBER);

        return (
          <div className={styles.multipleOrderNoView} key={key}>
            <Text label={label} className={styles.multipleTitle} />
            <div className={styles.subOrderNoView}>
              {preSubNumber.map(([label, item]) => (
                <Text key={label} label={label}>
                  {item}
                </Text>
              ))}
              {!!nextSubNumber.length && (
                <FoldCabinet
                  cabinetClassName={styles.cabinetClassName}
                  status={
                    multipleVisible[
                      key as keyof {
                        installationOrderNo: boolean;
                        serialNumber: boolean;
                      }
                    ] as boolean
                  }
                  onChange={(visible) =>
                    setMultipleVisible((pre) => ({ ...pre, [key]: visible }))
                  }
                >
                  <div className={styles.subOrderNoMoreDetail}>
                    {nextSubNumber.map(([label, item]) => (
                      <Text key={label} label={label}>
                        {item}
                      </Text>
                    ))}
                  </div>
                </FoldCabinet>
              )}
            </div>
          </div>
        );
      },
      [multipleVisible, setMultipleVisible]
    );

    const dnView = React.useMemo(() => {
      const viewList = InfoSalesMobileModel.getDNItemModels(
        {
          cpoDate,
          installationOrderNo,
          serialNumber,
          serviceContractorOrder,
          dnItems,
        },
        dnDelivered
      );
      return viewList.map(({ key, viewType, props }) => {
        const { label, children } = props as any;
        const supportMultiple = [
          InfoSalesModel.INSTALLATION_ORDER_NO,
          InfoSalesModel.SERIAL_NUMBER,
        ].includes(key as string);
        if (supportMultiple) {
          return generateMultipleOrderNoView(key as string, label, children);
        } else {
          return (
            <ViewComponent
              type={viewType as ViewType}
              key={key}
              props={{
                ...props,
                key,
                className: styles.orderInfoItem,
              }}
            />
          );
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      cpoDate,
      installationOrderNo,
      serialNumber,
      serviceContractorOrder,
      dnItems,
      dnDelivered,
      locale,
      generateMultipleOrderNoView,
    ]);

    const moreDetailView = React.useMemo(() => {
      const viewList = InfoSalesMobileModel.getDNMoreDetailModels({ dnItems });
      if (!viewList.length) return null;
      return (
        <FoldCabinet
          status={visible}
          onChange={(visible) => setVisible(visible)}
        >
          <div className={styles.moreDetailContainer}>
            {viewList.map(({ key, props }) => (
              <MoreDetailView
                itemDescription={props?.itemDescription}
                itemMaterial={props?.itemMaterial}
                itemOrderQuantity={props?.itemOrderQuantity}
                key={key}
              />
            ))}
          </div>
        </FoldCabinet>
      );
    }, [dnItems, setVisible, visible]);

    return (
      <div className={styles.orderInfoDnView}>
        <div className={styles.notice}>
          <TextReminder label={notice} reminderType={noticeType} />
        </div>
        {dnView}
        {moreDetailView}
      </div>
    );
  }
);

export default OrderInfoDnView;
