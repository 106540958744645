import { isEmpty } from "lodash";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorDetectorModalView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { NormalRes, Response } from "../../../../core/HttpService";
import {
  Action,
  Feature,
  ISearUserReq,
} from "../../../../services/user.service";
import { loadState, orderTaskIdState } from "../../../../store";
import { isFunction } from "../../../../utils/isType";
import SearchSelector from "../../../components/SearchSelector/SearchSelector";
import {
  ConfirmType,
  SearchOption,
} from "../../../components/SearchSelector/useSearchSelector";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import { Role } from "../../../service/userRoleTypes";
import AssignOrForwardUserModel from "./AssignOrForwardUserModel";

export interface IAssignOrForwardUser {
  visible: boolean;
  role: Role;
  feature: Feature;
  action: Action;
  searchQuery: Record<string, string>;
  actionData?: { selectUserIdKey: string; [key: string]: any };
  onActionResponse?: (res: Promise<NormalRes>) => void;
  onSearchResponse?: (res: Promise<NormalRes>) => void;
  onClose?: (visible: boolean) => void;
}

const AssignOrForwardUser: React.FC<IAssignOrForwardUser> =
  ErrorDetectorModalView((props: IAssignOrForwardUser) => {
    const {
      visible,
      action,
      role,
      feature,
      searchQuery,
      actionData,
      onClose,
      onSearchResponse,
      onActionResponse,
    } = props;

    const installationTaskId = useRecoilValue(orderTaskIdState);
    const [options, setOptions] = React.useState<SearchOption[]>(() => []);
    const [stateVisible, setVisible] = React.useState<boolean>(() => visible);
    const [errCode, setError] = React.useState<ErrorCode>();
    const [buttonBlock, setBlock] = React.useState(false);
    const toastError = useErrorMsg(errCode as ErrorCode);
    const setLoading = useSetRecoilState(loadState);

    React.useEffect(() => setVisible(visible), [visible]);
    React.useEffect(() => {
      if (!role || isEmpty(searchQuery)) return;
      const searchPromise = AssignOrForwardUserModel.getSaleForwardUsers(
        role,
        searchQuery as ISearUserReq
      ).then((res: Response<SearchOption[]>) => {
        setOptions(res.data);
        return res;
      });
      if (isFunction(onSearchResponse)) {
        onSearchResponse(searchPromise);
        return;
      }
      searchPromise.catch((res) => {
        setError(res?.errorCode);
        toastError();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, role]);

    const assignOrForwardUser = React.useCallback(
      (
        role: Role,
        action: Action,
        feature: Feature,
        installationTaskId: string,
        actionData: Record<string, string>
      ) => {
        const paramsValid = [role, action, feature, !isEmpty(actionData)].every(
          (item) => !!item
        );
        if (!paramsValid) return;
        const formattedData = { ...actionData };
        delete formattedData.selectUserIdKey;
        setLoading(true);
        setBlock(true);
        const _data = actionData?.parentInstallationTaskId
          ? formattedData
          : {
              ...formattedData,
              installationTaskId,
            };
        const actionPromise = AssignOrForwardUserModel.assignOrForwardUser(
          role,
          action as Action,
          feature,
          _data
        ).finally(() => {
          setBlock(false);
          setLoading(false);
        });

        if (isFunction(onActionResponse)) {
          onActionResponse(actionPromise);
          return;
        }
        actionPromise.catch((res) => {
          setError(res?.errorCode);
          toastError();
        });
      },
      [onActionResponse, setLoading, toastError]
    );

    const closeHandler = React.useCallback(
      (type: ConfirmType, value?: any) => {
        isFunction(onClose) && onClose(false);
        if (type === ConfirmType.cancel) {
          return;
        }

        assignOrForwardUser(role, action, feature, installationTaskId, {
          ...actionData,
          [actionData?.selectUserIdKey as string]: value,
        });
      },
      [
        role,
        action,
        feature,
        installationTaskId,
        actionData,
        onClose,
        assignOrForwardUser,
      ]
    );

    return (
      <SearchSelector
        options={options}
        visible={stateVisible}
        onClose={closeHandler}
        buttonDisabled={buttonBlock}
      />
    );
  });

export default AssignOrForwardUser;
