interface IImg {
    fillColor?: string;
    size?: "auto" | "default";
}

export const PDFIcon = (props: IImg) => {
    const { size } = props;
    const width = size === "auto" ? "100%" : "48",
        height = size === "auto" ? "100%" : "48";

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 48 48"
            fill="none" >
            <path opacity="0.5" d="M8 2C8 0.895429 8.89543 0 10 0H38L54 15V52C54 53.1046 53.1046 54 52 54H10C8.89543 54 8 53.1046 8 52V2Z" fill="#DCE3E9" />
            <path d="M8 33.7998C8 32.6952 8.89543 31.7998 10 31.7998H37C38.1046 31.7998 39 32.6952 39 33.7998V45.7998C39 46.9044 38.1046 47.7998 37 47.7998H8V33.7998Z" fill="#CAD2DB" />
            <rect y="30" width="39" height="16" rx="2" fill="#E13038" />
            <path d="M38 0V13C38 14.1046 38.8954 15 40 15H54L38 0Z" fill="#B1B7BE" />
            <path d="M44 15L54 23.5V15H44Z" fill="#CAD2DB" />
            <path d="M10.3109 42V38.93H12.2941C14.2437 38.93 15.2185 38.1049 15.2185 36.4538C15.2185 34.8179 14.2508 34 12.3165 34H9V42H10.3109ZM12.2157 37.8095H10.3109V35.1204H12.2157C12.7983 35.1204 13.22 35.2254 13.4818 35.4342C13.7578 35.643 13.8964 35.9832 13.8964 36.4538C13.8964 36.9325 13.7619 37.2788 13.493 37.4958C13.217 37.7046 12.7902 37.8095 12.2157 37.8095ZM19.2633 42C20.5559 42 21.5378 41.6415 22.2101 40.9244C22.8518 40.2368 23.1737 39.262 23.1737 38C23.1737 36.7298 22.8528 35.755 22.2101 35.0756C21.5378 34.3585 20.5559 34 19.2633 34H16.3389V42H19.2633ZM19.0168 40.8796H17.6499V35.1204H19.0168C20.0181 35.1204 20.7464 35.3486 21.2017 35.8039C21.6499 36.2521 21.8739 36.9845 21.8739 38C21.8739 38.986 21.6499 39.7143 21.2017 40.1849C20.7382 40.6483 20.0099 40.8796 19.0168 40.8796ZM25.7171 42V38.4594H29.6274V37.3389H25.7171V35.1204H29.8627V34H24.4062V42H25.7171Z" fill="white" />
        </svg>
    );
}

