/**
 * BreadCrumb component only be used in wise or FA project, it will be enhanced in feature to support multiple extending
 */

import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { getCssVariable } from "../../utils/commonUtils";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./BreadCrumb.module.scss";

export interface crumbNode {
  /** Uniq value in list */
  value: string | number;
  text?: string | React.ReactNode;
  node?: React.ReactNode;
}

export interface BreadCrumbProps {
  nodes: crumbNode[];
  className?: string;
  nodeClassName?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  icon?: React.ReactNode;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ErrorDetectorSmallView(
  (props: BreadCrumbProps) => {
    const { value, nodes, onChange, icon, className, nodeClassName } = props;
    const _className = classNames(styles.breadCrumb, className);
    const _nodeClassName = classNames(styles.nodeClassName, nodeClassName);
    const fill = getCssVariable("--color-interaction-link");
    const [_value, setValue] = React.useState<string | number>(
      value as string | number
    );

    React.useEffect(() => setValue(value as string | number), [value]);
    const _nodes = React.useMemo(() => {
      const index = nodes.findIndex(({ value }) => value === _value);
      return nodes.slice(0, index + 1);
    }, [_value, nodes]);

    const changeHandler = (value: string | number) => {
      setValue(value);
      isFunction(onChange) && onChange(value);
    };

    return (
      <div className={_className}>
        {_nodes.map(({ value, text, node }, index) => {
          const show = index !== _nodes.length - 1;
          return (
            <div
              className={_nodeClassName}
              key={value}
              onClick={changeHandler.bind(null, value)}
            >
              {<div className={styles.label}>{text}</div> || node}
              {(show && (
                <div className={styles.icon}>
                  <Icon icon="ChevronNext" size={12} fill={fill} />
                </div>
              )) ||
                icon}
            </div>
          );
        })}
      </div>
    );
  }
);

export default BreadCrumb;
