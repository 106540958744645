import HttpService, { NormalRes } from "../../../../core/HttpService";
import { SystemPlatform } from "../../../../core/SystemService";
import {
  IRsmNeedSiteSurveyReq,
  StaticSourceItem,
  rsmNeedSiteSurvey,
} from "../../../../services/order.service";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import { SearchOption } from "../../../components/SearchSelector/useSearchSelector";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { RsmAction } from "../../../service/userRoleTypes";
import OrderInfoModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  ViewItemModel,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";

export enum InstallationRsmAction {
  FORWARD = "forward",
  ASSIGN = "assign",
  NEED_SITE_SURVEY = "needSiteSurvey",
}

export default class InstallationRsmModel {
  static rsmNeedSiteSurvey = (
    data: IRsmNeedSiteSurveyReq
  ): Promise<NormalRes> => {
    return HttpService.splitResponse(rsmNeedSiteSurvey(data));
  };

  static verifyRsmAssign = (info: Partial<IInstallation>): boolean => {
    const keys = [
      InstallationModel.PLANNED_INSTALL_END_DATE,
      InstallationModel.PLANNED_INSTALL_START_DATE,
      InstallationModel.OWNER_INSTALLATION_FSE,
    ];
    return ValidationService.verifyIntegrity(info, keys);
  };

  static getInfoViewMobileModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    pageStatus: PageStatus,
    fseList: SearchOption[]
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationStartDate,
      labelPlanedInstallationEndDate,
      labelFacRequired,
      labelFacUploader,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelOwnerInstallationFse,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      facRequired,
      facUploader,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      ownerInstallationFse,
    } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      ),
      warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
        warrantyPeriod as WarrantyPeriod
      );

    const list = [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelExpectedInstallDate,
          children: formatDate(expectedInstallDate as number),
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelPlanedInstallationStartDate,
          value: formatDatePickValue(planedInstallationStartDate as number),
          disabled,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelPlanedInstallationEndDate,
          value: formatDatePickValue(planedInstallationEndDate as number),
          min: formatDatePickValue(planedInstallationStartDate as number),
          disabled,
        },
      },
      {
        key: InstallationModel.OWNER_INSTALLATION_FSE,
        viewType: ViewType.VIEW_SELECT,
        props: {
          label: labelOwnerInstallationFse,
          value: ownerInstallationFse,
          options: fseList,
          search: true,
          disabled,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacRequired,
          children: facLabel,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
        hidden: !facRequired,
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
        },
        hidden: !trainingRequired,
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
        },
      },
    ];

    return list.filter(({ hidden }) => !hidden);
  };
  static getInfoViewPcModel = (
    info: Partial<IInstallation>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    pageStatus: PageStatus,
    fseList: SearchOption[]
  ): Partial<ViewItemModel>[] => {
    const {
      labelExpectedInstallDate,
      labelPlanedInstallationStartDate,
      labelPlanedInstallationEndDate,
      labelFacRequired,
      labelTrainRequired,
      labelApSupervisor,
      labelWarrantyPeriod,
      labelFacUploader,
      labelOwnerInstallationFse,
    } = InstallationModel.getLabels();
    const {
      expectedInstallDate,
      planedInstallationStartDate,
      planedInstallationEndDate,
      facRequired,
      trainingRequired,
      apSupervisor,
      warrantyPeriod,
      facUploader,
      ownerInstallationFse,
    } = info;
    const disabled = pageStatus === PageStatus.READONLY;

    const facLabel = OrderRequestModel.getToggleLabel(facRequired as boolean),
      trainLabel = OrderRequestModel.getToggleLabel(
        trainingRequired as boolean
      ),
      apSupervisorLabel = OrderRequestModel.getApSupervisorLabel(
        orgOptions,
        apSupervisor as string
      ),
      warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
        warrantyPeriod as WarrantyPeriod
      );
    const customerKey1 = `${InstallationModel.INSTALLATION_TASK_ID}_1`;
    const customerKey2 = `${InstallationModel.INSTALLATION_TASK_ID}_2`;

    return [
      {
        key: InstallationModel.EXPECTED_INSTALL_DATA,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelExpectedInstallDate,
          children: formatDate(expectedInstallDate as number),
        },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_START_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelPlanedInstallationStartDate,
          value: formatDatePickValue(planedInstallationStartDate as number),
          disabled,
        },
      },
      {
        key: InstallationModel.PLANNED_INSTALL_END_DATE,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelPlanedInstallationEndDate,
          value: formatDatePickValue(planedInstallationEndDate as number),
          minDate: formatDatePickValue(planedInstallationStartDate as number),
          disabled,
        },
      },
      {
        key: InstallationModel.OWNER_INSTALLATION_FSE,
        viewType: ViewType.VIEW_DROP_DOWN_SELECT,
        props: {
          label: labelOwnerInstallationFse,
          value: ownerInstallationFse,
          options: fseList,
          search: true,
        },
      },
      {
        key: customerKey2,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey2,
        },
      },
      {
        key: InstallationModel.FAC_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelFacRequired,
          children: facLabel,
        },
      },
      {
        key: InstallationModel.FAC_UPLOADER,
        viewType: facRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelFacUploader,
          children: facUploader,
        },
      },
      {
        key: InstallationModel.TRAINING_REQUIRED,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelTrainRequired,
          children: trainLabel,
        },
      },
      {
        key: InstallationModel.AP_SUPERVISOR,
        viewType: trainingRequired
          ? ViewType.VIEW_TEXT_VARIANT_PC
          : ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelApSupervisor,
          children: apSupervisorLabel,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
        },
      },
    ];
  };
  static getFileInfoViewModel = (
    infos: IInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      deliveryNotes,
      euCs,
      technicalAgreements,
      specifications,
      disclaimers,
      siteSurveyReports,
    } = infos;
    const {
      labelDeliverNotes,
      labelEurOrEuu,
      labelTechAgr,
      labelSpecs,
      labelDisclaimer,
      labelSiteSurveyReport,
    } = InstallationModel.getLabels();

    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: InstallationModel.DISCLAIMERS,
        viewType: viewType,
        props: {
          title: labelDisclaimer,
          value: disclaimers ?? [],
          key: InstallationModel.DISCLAIMERS,
          deletable: false,
          disableUpload: true,
        },
      },
      {
        key: InstallationModel.SITE_SURVEY_REPORT,
        viewType: viewType,
        props: {
          title: labelSiteSurveyReport,
          value: siteSurveyReports ?? [],
          key: InstallationModel.SITE_SURVEY_REPORT,
          deletable: false,
          disableUpload: true,
        },
      },
      {
        key: InstallationModel.EUC_EUU,
        viewType: viewType,
        props: {
          title: labelEurOrEuu,
          value: euCs ?? [],
          key: InstallationModel.EUC_EUU,
          deletable: false,
          disableUpload: true,
        },
      },
      {
        key: InstallationModel.DELIVERY_NOTES,
        viewType: viewType,
        props: {
          title: labelDeliverNotes,
          value: deliveryNotes ?? [],
          key: InstallationModel.DELIVERY_NOTES,
          deletable: false,
          disableUpload: true,
        },
      },
      {
        key: InstallationModel.TECHNICAL_AGR,
        viewType: viewType,
        props: {
          title: labelTechAgr,
          value: technicalAgreements ?? [],
          key: InstallationModel.TECHNICAL_AGR,
          deletable: false,
          disableUpload: true,
        },
      },
      {
        key: InstallationModel.SPEC,
        viewType: viewType,
        props: {
          title: labelSpecs,
          value: specifications ?? [],
          key: InstallationModel.SPEC,
          deletable: false,
          disableUpload: true,
        },
      },
    ];
    return originList.filter(({ props }) => !!props?.value?.length);
  };

  static getButtonViewMobileModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const {
      labelAllTicket,
      labelForward,
      labelNeedSiteSurvey,
      labelAssign,
      labelMore,
    } = OrderInfoModel.getLabels();

    const original = [
      {
        key: RsmAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: RsmAction.ALL_TICKET,
        },
      },
      {
        key: RsmAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON_MORE_VARIANT,
        props: {
          actions: [
            { value: InstallationRsmAction.FORWARD, text: labelForward },
            {
              value: InstallationRsmAction.NEED_SITE_SURVEY,
              text: labelNeedSiteSurvey,
            },
          ],
          children: labelMore,
          key: RsmAction.FORWARD,
        },
      },
      {
        key: RsmAction.ASSIGN,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelAssign,
          key: RsmAction.ASSIGN,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static getButtonViewPcModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelForward, labelNeedSiteSurvey, labelAssign } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: RsmAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: RsmAction.ALL_TICKET,
        },
      },
      {
        key: RsmAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelForward,
          key: RsmAction.FORWARD,
        },
      },
      {
        key: RsmAction.NEED_SITE_SURVEY,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelNeedSiteSurvey,
          key: RsmAction.NEED_SITE_SURVEY,
        },
      },
      {
        key: RsmAction.ASSIGN,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelAssign,
          key: RsmAction.ASSIGN,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
