import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../../../../../components/ErrorDecorator/ErrorDecorator";
import { isFunction, isNumber } from "../../../../../utils/isType";
import styles from "./Counter.module.scss";

export enum BeforeChangeType {
  Cancel = "cancel",
  Ok = "ok",
}

// currently, only support integer
export interface ICounter {
  value?: number;
  beforeChange?: (pre: number, next: number) => BeforeChangeType;
  onChange?: (value: number) => void;
  className?: string;
  disableMinus?: boolean;
  disablePlus?: boolean;
  perMinus?: number;
  perPlus?: number;
  max?: number;
  min?: number;
  inputAble?: boolean;
}

enum CalculateType {
  Minus = 1,
  Plus = 2,
  Typing = 3,
}

const DEFAULT_PER = 1;

export const Counter: React.FC<ICounter> = ErrorDetectorSmallView(
  (props: ICounter) => {
    const {
      value,
      className,
      disableMinus,
      disablePlus,
      perMinus,
      perPlus,
      max,
      min,
      inputAble,
      onChange,
      beforeChange,
    } = props;
    const [_value, setValue] = React.useState(formatValue(value as number));
    const [_disableMinus, setMinus] = React.useState(!!disableMinus);
    const [_disablePlus, setPlus] = React.useState(!!disablePlus);
    const minusCls = classNames(styles.icon, {
      [styles.disabled]: _disableMinus,
    });
    const plusCls = classNames(styles.icon, {
      [styles.disabled]: _disablePlus,
    });
    const _className = classNames(styles.counter, className);

    React.useEffect(() => setValue(formatValue(value as number)), [value]);
    React.useEffect(() => setMinus(!!disableMinus), [disableMinus]);
    React.useEffect(() => setPlus(!!disablePlus), [disablePlus]);
    React.useEffect(() => {
      if (isNumber(max)) {
        setPlus(_value >= max);
      }
      if (isNumber(min)) {
        setMinus(_value <= min);
      }
    }, [_value, max, min]);

    const changeHandler = (
      type: CalculateType,
      value: number = DEFAULT_PER
    ) => {
      const _perMinus = formatValue(perMinus as number)
        ? formatValue(perMinus as number)
        : DEFAULT_PER;
      const _perPlus = formatValue(perPlus as number)
        ? formatValue(perPlus as number)
        : DEFAULT_PER;
      let changedValue = _value;
      if (type === CalculateType.Typing) {
        changedValue = value ?? DEFAULT_PER;
      } else {
        changedValue =
          type === CalculateType.Minus ? _value - _perMinus : _value + _perPlus;
      }
      if (isNumber(max)) {
        changedValue = changedValue <= max ? changedValue : max;
      }

      if (isNumber(min)) {
        changedValue = changedValue >= min ? changedValue : min;
      }
      if (isFunction(beforeChange) && beforeChange(_value, changedValue)) {
        const result = beforeChange(_value, changedValue);
        if (result === BeforeChangeType.Ok) {
          setValue(changedValue);
          isFunction(onChange) && isFunction(onChange(changedValue));
        }
      } else {
        setValue(changedValue);
        isFunction(onChange) && isFunction(onChange(changedValue));
      }
    };

    const typingHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.currentTarget;
      if (isNaN(+value)) return DEFAULT_PER;
      changeHandler(CalculateType.Typing, +value);
    };

    return (
      <div className={_className}>
        <div
          onClick={() => changeHandler(CalculateType.Minus)}
          className={minusCls}
        >
          <Icon icon={"Minus"} size={12} />
        </div>
        <input
          readOnly={!inputAble}
          className={styles.input}
          value={_value}
          type="number"
          onChange={typingHandler}
        />
        <div
          onClick={() => changeHandler(CalculateType.Plus)}
          className={plusCls}
        >
          <Icon icon={"Plus"} size={12} />
        </div>
      </div>
    );
  }
);

const formatValue = (value: number): number => {
  if (!isNumber(value)) return 0;
  return value;
};
