import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { LoadingSpinner } from "@zeiss/ods-components-react";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { getScrollParent } from "../utils/getScrollParent";
import { mergeProps } from "../utils/mergeDefaultProps";
import { rubberbandIfOutOfBounds } from "../utils/rubberband";
import { sleep } from "../utils/sleep";
import { supportsPassive } from "../utils/supportsPassive";
import styles from "./PullToRefresh.module.scss";

export type PullStatus = "pulling" | "canRelease" | "refreshing" | "complete";

export type PullToRefreshProps = {
  onRefresh?: () => Promise<any>;
  completeDelay?: number;
  headHeight?: number;
  threshold?: number;
  disabled?: boolean;
  renderText?: (status: PullStatus) => ReactNode;
  children?: React.ReactNode;
};

export const defaultProps = {
  pullingText: "Refresh",
  canReleaseText: "Release to refresh",
  refreshingText: "Loading...",
  completeText: "Succeed",
  completeDelay: 500,
  disabled: false,
  onRefresh: () => {},
};

export const PullToRefresh: FC<PullToRefreshProps> = (p) => {
  const props = mergeProps(defaultProps, p);
  const headHeight = props.headHeight ?? 40;
  const threshold = props.threshold ?? 60;

  const [status, setStatus] = useState<PullStatus>("pulling");

  const [springStyles, api] = useSpring(() => ({
    from: { height: 0 },
    config: {
      tension: 300,
      friction: 30,
      clamp: true,
    },
  }));

  const elementRef = useRef<HTMLDivElement>(null);

  const pullingRef = useRef(false);

  //debounce when in pulling
  useEffect(() => {
    elementRef.current?.addEventListener("touchmove", () => {});
  }, []);

  const reset = () => {
    return new Promise<void>((resolve) => {
      api.start({
        to: {
          height: 0,
        },
        onResolve() {
          setStatus("pulling");
          resolve();
        },
      });
    });
  };

  async function doRefresh() {
    api.start({ height: headHeight });
    setStatus("refreshing");
    try {
      await props.onRefresh();
      setStatus("complete");
    } catch (e) {
      reset();
      throw e;
    }
    if (props.completeDelay > 0) {
      await sleep(props.completeDelay);
    }
    reset();
  }

  useDrag(
    (state) => {
      if (status === "refreshing" || status === "complete") return;

      const { event } = state;

      if (state.last) {
        pullingRef.current = false;
        if (status === "canRelease") {
          doRefresh();
        } else {
          api.start({ height: 0 });
        }
        return;
      }

      const [, y] = state.movement;
      if (state.first && y > 0) {
        const target = state.event.target;
        if (!target || !(target instanceof Element)) return;
        let scrollParent = getScrollParent(target);
        while (true) {
          if (!scrollParent) return;
          const scrollTop = getScrollTop(scrollParent);
          if (scrollTop > 0) {
            return;
          }
          if (scrollParent instanceof Window) {
            break;
          }
          scrollParent = getScrollParent(scrollParent.parentNode as Element);
        }
        pullingRef.current = true;
      }

      if (!pullingRef.current) return;

      if (event.cancelable) {
        event.preventDefault();
      }
      event.stopPropagation();
      const height = Math.max(
        rubberbandIfOutOfBounds(y, 0, 0, headHeight * 5, 0.5),
        0
      );
      api.start({ height });
      setStatus(height > threshold ? "canRelease" : "pulling");
    },
    {
      pointer: { touch: true },
      axis: "y",
      target: elementRef,
      enabled: !props.disabled,
      eventOptions: supportsPassive
        ? { passive: false }
        : (false as unknown as AddEventListenerOptions),
    }
  );

  const renderStatusText = () => {
    if (props.renderText) {
      return props.renderText?.(status);
    }
    if (status === "refreshing") return <LoadingSpinner size={"xs"} />;
  };

  return (
    <animated.div ref={elementRef} className={styles.pullToRefresh}>
      <animated.div style={springStyles} className={styles.head}>
        <div className={styles.headContent} style={{ height: headHeight }}>
          {renderStatusText()}
        </div>
      </animated.div>
      <div className={styles.content}>{props.children}</div>
    </animated.div>
  );
};

function getScrollTop(element: Window | Element) {
  return "scrollTop" in element ? element.scrollTop : element.scrollY;
}
