import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import { ErrorDetectorPageView } from "../../../../../components/ErrorDecorator/ErrorDecorator";
import LocaleService, { LocaleLang } from "../../../../../core/LocaleService";
import { SystemPlatform } from "../../../../../core/SystemService";
import { localeState, platformState } from "../../../../../store";
import FoldCabinet from "../../../../components/FoldCabinet/FoldCabinet";
import useLang from "../../../../hooks/useLang";
import { defaultRecord } from "../../../OrderInfo/InfoSales/hooks/useInfoSiteSurvey";
import SiteSurveyModel, {
  AccessoryProperties,
  AccessoryTypes,
} from "../../model/SiteSurveyModel";
import styles from "./SiteSurveyAccessory.module.scss";

interface ISiteSurveyAccessory {
  accessories: Record<AccessoryTypes, Partial<AccessoryProperties>>;
}

const SiteSurveyAccessory: React.FC<ISiteSurveyAccessory> =
  ErrorDetectorPageView((props: ISiteSurveyAccessory) => {
    const platform = useRecoilValue(platformState);
    const locale = useRecoilValue(localeState);
    const list = Object.entries(props?.accessories ?? {});
    const { title } = useLang({ title: { id: "order_accessories" } });
    const [status, setStatus] = React.useState(true);
    const defaultKeyList = Object.entries(defaultRecord).map(([key]) => key);
    const filterList = list.filter(([item]) => defaultKeyList.includes(item));
    const { length } = filterList ?? [];

    const [accessoryType] = filterList[length - 1] ?? [];
    const noneSubContent = [AccessoryTypes.EP, AccessoryTypes.MUTE];
    const listCls = classNames(styles.list, {
      [styles.listWithoutPaddingBottom]: noneSubContent.includes(
        accessoryType as AccessoryTypes
      ),
    });
    const isPc = platform === SystemPlatform.ENV_PC;
    const className = isPc ? styles.cabinetClassName : "";

    return React.useMemo(
      () =>
        !!filterList?.length && (
          <FoldCabinet
            label={title}
            status={status}
            className={className}
            onChange={(status) => setStatus(status)}
            cabinetClassName={styles.siteSurveyCabinet}
          >
            <div className={listCls}>
              {filterList?.map(([accessoryType, accessoryProperties]) => {
                const typeLabel = SiteSurveyModel.getAccessoriesLabels(
                  accessoryType as AccessoryTypes
                );

                const valueList =
                  Object?.entries(accessoryProperties ?? ({} as any)) ?? [];
                const filterViewList = valueList.filter(
                  ([key]) => key !== "notUse"
                );
                const accessoryPropertiesCls = isPc
                  ? classNames(styles.accessoryPropertiesPc, {
                      [styles.accessoryPropertiesOne]:
                        filterViewList.length === 1,
                    })
                  : classNames(styles.accessoryProperties, {
                      [styles.accessoryPropertiesOne]:
                        filterViewList.length === 1,
                    });
                return (
                  <div className={styles.accessoryType} key={accessoryType}>
                    <div className={styles.typeLabel}>{typeLabel}</div>

                    {!!filterViewList.length && (
                      <div className={accessoryPropertiesCls}>
                        {filterViewList?.map(([key, value], index) => {
                          const itemLabel =
                            SiteSurveyModel.getAccessoriesItemLabel(key);
                          const valueLabel =
                            SiteSurveyModel.getThirdPartyOptionLabel(
                              key as keyof AccessoryProperties,
                              value
                            );
                          const accessoryItem = isPc
                            ? styles.accessoryItemPc
                            : styles.accessoryItem;

                          return (
                            <div className={accessoryItem} key={key}>
                              {![
                                SiteSurveyModel.SPEC,
                                SiteSurveyModel.COMMENTS,
                              ].includes(key) && (
                                <div className={styles.label}>
                                  {`${
                                    index + 1
                                  }. ${itemLabel}${LocaleService.getColon(
                                    LocaleLang.ZH_CN
                                  )} `}
                                </div>
                              )}
                              <div className={styles.value}>{valueLabel}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </FoldCabinet>
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [className, filterList, isPc, listCls, status, title, locale]
    );
  });

export default SiteSurveyAccessory;
