import { Button } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import Table from "../../../../components/Table/Table";
import { Action, Feature } from "../../../../services/user.service";
import { isObject } from "../../../../utils/isType";
import FoldCabinetVariantPc from "../../../components/FoldCabinet/FoldCabinetVariantPc";
import Text from "../../../components/Text/Text";
import TextReminder from "../../../components/TextReminder/TextReminder";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { Role, SalesAction } from "../../../service/userRoleTypes";
import AssignOrForwardUser from "../../components/AssignOrForwardUser/AssignOrForwardUser";
import InfoSalesModel, { ButtonMore } from "../InfoSales/InfoSalesModel";
import { Counter } from "../components/Counter/Counter";
import styles from "./SplitOrder.module.scss";
import SplitOrderModel, { ISplitItem, SplitType } from "./SplitOrderModel";
import useSplitOrder from "./useSplitOrder";

const SplitOrderPc: React.FC = ErrorDetectorPageView(() => {
  const {
    otherProductItems,
    mainProduct,
    folded,
    labelOrder,
    _splitItems,
    labelAdd,
    labelSplitDevice,
    reminderSplit,
    labelCancel,
    labelConfirm,
    rolePage,
    _reminder,
    assignAndForwardVisible,
    userId,
    parentInstallationTaskId,
    locale,
    pageStatus,
    confirmHandler,
    beforeChangeHandler,
    changeHandler,
    setFold,
    splitChangeHandler,
    submitOrderHandler,
    backAllTickHandler,
    moreActionHandler,
    setAssignAndForwardVisibleVisible,
  } = useSplitOrder();

  const _pcContent = classNames(styles.pageContentPc, {
    [styles.pageContentPcOverHeight]:
      _splitItems.length || document.documentElement.clientHeight < 590,
  });
  const productView = React.useMemo(() => {
    const showMain = isObject(mainProduct);
    const modelList = SplitOrderModel.getMainProductViewModel(mainProduct);
    const column = SplitOrderModel.getSubProductColumn();
    const viewList = modelList.map((item) => (
      <div className={styles.pcViewItem} key={item.key}>
        <ViewComponent type={item.viewType as ViewType} props={item.props} />
      </div>
    ));
    const showProductItems =
      Array.isArray(otherProductItems) && !!otherProductItems.length;
    const _otherProductItems = otherProductItems?.map((item) => ({
      ...item,
      itemOrderQuantity: `x${item.itemOrderQuantity}`,
    }));
    return (
      <div className={styles.productPcView}>
        {!!showMain && (
          <>
            <div className={styles.mainProductTitle}>
              {mainProduct.itemDescription}
            </div>
            <div className={styles.viewListPc}>{viewList}</div>
          </>
        )}
        {showProductItems && (
          <FoldCabinetVariantPc
            status={folded}
            onChange={(folded) => setFold(folded)}
          >
            <div className={styles.moreDetailContainer}>
              <Table columns={column} data={_otherProductItems} />
            </div>
          </FoldCabinetVariantPc>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainProduct, otherProductItems, folded, locale, setFold]);

  const renderSplitItem = React.useCallback(
    (item: ISplitItem) => {
      const {
        itemDescription,
        itemMaterial,
        itemOrderQuantity,
        editable,
        max,
        index,
      } = item;

      const countView = editable ? (
        <Counter
          value={itemOrderQuantity}
          onChange={(value) => changeHandler(value, item)}
          beforeChange={(pre, next) => beforeChangeHandler(pre, next, item)}
          max={max}
          inputAble={true}
        />
      ) : (
        <span className={styles.quantity}>{`x${itemOrderQuantity}`}</span>
      );
      const itemIndex = `${labelOrder} ${index}`;

      return {
        [InfoSalesModel.ITEM_ORDER]: itemIndex,
        [InfoSalesModel.ITEM_DESC]: itemDescription,
        [InfoSalesModel.ITEM_MATERIAL]: itemMaterial,
        [InfoSalesModel.ITEM_ORDER_QUANTITY]: countView,
      };
    },
    [labelOrder, beforeChangeHandler, changeHandler]
  );

  const splitOrderTableView = React.useMemo(() => {
    const splitColumn = SplitOrderModel.getUnSplitOrderColumn();
    const _data = _splitItems.map((item) => renderSplitItem(item?.[0]));

    return <Table columns={splitColumn} data={_data} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_splitItems, locale, renderSplitItem]);

  const splitView = React.useMemo(() => {
    if (!Array.isArray(_splitItems) || !_splitItems.flat().length) return null;
    const mainProduct = _splitItems.flat().find(({ isMain }) => isMain);
    const showAdd =
      pageStatus !== PageStatus.READONLY &&
      (mainProduct?.itemOrderQuantity as number) > 1;

    return (
      <div className={styles.splitPcView}>
        <div>
          <div className={styles.splitTitle}>
            <Text
              label={labelSplitDevice}
              labelTextClassName={styles.labelTitle}
            >
              {showAdd && (
                <div
                  className={styles.add}
                  onClick={splitChangeHandler.bind(
                    null,
                    SplitType.Plus,
                    undefined
                  )}
                >
                  {labelAdd}
                </div>
              )}
            </Text>
          </div>
          <TextReminder
            label={reminderSplit}
            className={styles.splitReminder}
          />
        </div>
        <div className={styles.splitDevices}>{splitOrderTableView}</div>
      </div>
    );
  }, [
    _splitItems,
    pageStatus,
    labelSplitDevice,
    splitChangeHandler,
    labelAdd,
    reminderSplit,
    splitOrderTableView,
  ]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtonsPc}>
        <Button
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button variant="primary" onClick={confirmHandler.bind(null, "ok")}>
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, labelConfirm, confirmHandler]
  );

  const buttonViews = React.useMemo(() => {
    const list = SplitOrderModel.getButtonViewPcModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [
        SalesAction.FORWARD,
        { onClick: moreActionHandler.bind(null, ButtonMore.FORWARD) },
      ],
      [
        SalesAction.DELETE_ORDER,
        { onClick: moreActionHandler.bind(null, ButtonMore.CANCEL_ORDER) },
      ],
      [SalesAction.SUBMIT, { onClick: submitOrderHandler }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttonsPc}>{views}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rolePage,
    locale,
    backAllTickHandler,
    moreActionHandler,
    submitOrderHandler,
  ]);

  return (
    <section className={styles.splitOrderPc}>
      <div className={_pcContent}>
        {productView}
        {splitView}
      </div>
      {buttonViews}
      {assignAndForwardVisible && (
        <AssignOrForwardUser
          visible={assignAndForwardVisible}
          onClose={(visible: boolean) =>
            setAssignAndForwardVisibleVisible(visible)
          }
          action={Action.FORWARD}
          role={Role.ROLE_SALES}
          feature={Feature.SPLIT_ORDER}
          searchQuery={{ salesUserId: userId }}
          actionData={{
            selectUserIdKey: "salesRBACCorrelationId",
            parentInstallationTaskId,
          }}
          onActionResponse={backAllTickHandler}
        />
      )}
      <ConfirmVariantWithIcon
        footer={confirmButtonView}
        visible={!!_reminder}
        title={_reminder}
        onConfirm={confirmHandler}
      />
    </section>
  );
});

export default SplitOrderPc;
