import { Icon } from "@zeiss/ods-components-react";
import type { NotificationConfig } from "rc-notification";
import "rc-notification/assets/index.less";
import React from "react";
import { getCssVariable } from "../../utils/commonUtils";
import { isFunction } from "../../utils/isType";
import Fail from "../svg/Fail";
import Info from "../svg/Info";
import Suc from "../svg/Suc";
import Warning from "../svg/Warning";
import styles from "./Notification.module.scss";

interface INotification extends NotificationConfig {
  notice?: INotice;
}

type Placement =
  | "top"
  | "topLeft"
  | "topRight"
  | "bottom"
  | "bottomLeft"
  | "bottomRight";

export interface INotice {
  content?: string | React.ReactNode;
  contentType?: NotificationType;
  duration?: number | null;
  closeIcon?: React.ReactNode;
  closable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  key: React.Key;
  placement?: Placement;
  onClose?: VoidFunction;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export interface OpenConfig {
  key: React.Key;
  placement?: Placement;
  content?: React.ReactNode;
  duration?: number | null;
}

// Actually, your should use the component in your basic layout pages, due to
// we recommend the component should have only one instance in system

export enum NotificationType {
  success = "suc",
  failed = "failed",
  info = "info",
  warning = "warning",
}

export interface NotificationContentProps {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  contentType?: NotificationType;
  customizedView?: React.ReactNode;
  onClose?: (...arg: any[]) => void;
}

export const NotificationContentView = (props: NotificationContentProps) => {
  const { contentType, content, title, customizedView, onClose } = props;
  const closeHandler = React.useCallback(() => {
    isFunction(onClose) && onClose();
  }, [onClose]);

  const statusIcon = React.useMemo(() => {
    const iconMap = new Map([
      [NotificationType.info, <Info key={NotificationType.info} size="auto" />],
      [
        NotificationType.success,
        <Suc key={NotificationType.success} size="auto" />,
      ],
      [NotificationType.failed, <Fail key={NotificationType.failed} />],
      [
        NotificationType.warning,
        <Warning key={NotificationType.warning} size="auto" />,
      ],
    ]);
    return iconMap.get(contentType as NotificationType);
  }, [contentType]);

  const borderColor = React.useMemo(() => {
    const colorMap = new Map([
      [NotificationType.info, "--color-interaction-link"],
      [NotificationType.success, "--color-functional-success-0"],
      [NotificationType.failed, "--color-functional-error-0"],
      [NotificationType.warning, "--color-functional-warning-0"],
    ]);

    const colorVariable = colorMap.get(contentType as NotificationType);
    return getCssVariable(colorVariable as string);
  }, [contentType]);

  const iconView = React.useMemo(() => {
    return (
      <div className={styles.closeIcon} onClick={closeHandler}>
        <Icon icon="Close" size={16} />
      </div>
    );
  }, [closeHandler]);

  const defaultView = React.useMemo(() => {
    const style = { borderColor };

    return (
      <div className={styles.notificationContentView} style={style}>
        {iconView}
        <div className={styles.title}>
          <div className={styles.icon}>{statusIcon}</div>
          <div className={styles.titleText}>{title}</div>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    );
  }, [borderColor, content, iconView, statusIcon, title]);

  return React.isValidElement(customizedView) ? customizedView : defaultView;
};
