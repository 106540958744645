import React from "react";
import createEffectWithTarget from "../components/utils/createEffectWithTarget";
import type { BasicTarget } from "../components/utils/domTarget";
import { getTargetElement } from "../components/utils/domTarget";
import getDocumentOrShadow from "../components/utils/getDocumentOrShadow";
import useLatest from "./useLatest";

type DocumentEventKey = keyof DocumentEventMap;

export default function useClickAway<T extends Event = Event>(
  onClickAway: (event: T) => void,
  target: BasicTarget | BasicTarget[],
  eventName: DocumentEventKey | DocumentEventKey[] = "click"
) {
  const onClickAwayRef = useLatest(onClickAway);
  const useEffectWithTarget = createEffectWithTarget(React.useLayoutEffect);

  useEffectWithTarget(
    () => {
      const handler = (event: any) => {
        const targets = Array.isArray(target) ? target : [target];
        if (
          targets.some((item) => {
            const targetElement = getTargetElement(item);
            return !targetElement || targetElement.contains(event.target);
          })
        ) {
          return;
        }
        onClickAwayRef.current(event);
      };

      const documentOrShadow = getDocumentOrShadow(target);

      const eventNames = Array.isArray(eventName) ? eventName : [eventName];

      eventNames.forEach((event) =>
        documentOrShadow.addEventListener(event, handler)
      );

      return () => {
        eventNames.forEach((event) =>
          documentOrShadow.removeEventListener(event, handler)
        );
      };
    },
    Array.isArray(eventName) ? eventName : [eventName],
    target
  );
}
