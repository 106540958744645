export enum LocaleLang {
  EN_US = "en-US",
  ZH_CN = "zh-CN",
}

export enum MeasureUnit {
  IMPERIAL = "imperial",
  METRIC = "metric",
}

export enum Decimal {
  DOT = ".",
  COMMA = ",",
}

export interface Locale {
  text: string;
  lang: LocaleLang;
  timeFormat: { date: string; time: string };
  measureUnit?: MeasureUnit;
  temperature?: MeasureUnit;
  decimal?: Decimal;
}

const textEnglish = "English",
  textChinese = "中文";

export default class LocaleService {
  private static readonly Locales: Locale[] = [
    {
      text: textEnglish,
      lang: LocaleLang.EN_US,
      timeFormat: { date: "MMM DD YYYY", time: "hh:mm:ss A" },
      measureUnit: MeasureUnit.IMPERIAL,
      temperature: MeasureUnit.IMPERIAL,
      decimal: Decimal.DOT,
    },
    {
      text: textChinese,
      lang: LocaleLang.ZH_CN,
      timeFormat: { date: "MMM DD YYYY", time: "hh:mm:ss A" },
      measureUnit: MeasureUnit.METRIC,
      temperature: MeasureUnit.METRIC,
      decimal: Decimal.DOT,
    },
  ];

  private static readonly DEFAULT_LOCAL = {
    text: textEnglish,
    lang: LocaleLang.EN_US,
    timeFormat: { date: "MMM DD YYYY", time: "hh:mm:ss A" },
    measureUnit: MeasureUnit.IMPERIAL,
    temperature: MeasureUnit.IMPERIAL,
    decimal: Decimal.DOT,
  };

  static getLocale = (language: LocaleLang): Locale | undefined => {
    return LocaleService.Locales.find(
      ({ lang }) => lang.toLowerCase() === language.toLowerCase()
    );
  };

  static getDefaultLocale = () => {
    const language = window?.navigator?.language ?? LocaleLang.EN_US;
    return (
      LocaleService.Locales.find(
        ({ lang }) => lang.toLowerCase() === language.toLowerCase()
      ) ?? LocaleService.DEFAULT_LOCAL
    );
  };

  static getColon = (language: LocaleLang) => {
    const colonMaps = new Map([
      [LocaleLang.EN_US, ":"],
      [LocaleLang.ZH_CN, "："],
    ]);

    return colonMaps.get(language) ?? ":";
  };
}
