import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "160",
    height = size === "auto" ? "100%" : "160";

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="160" height="160" rx="20" fill="#161D86" />
      <path
        d="M121.791 130.883C133.018 94.1334 130.508 52.6422 69.1425 40.0205L61.5416 53.2001V61.6378L86.6455 63.2416C98.807 69.7687 105.055 85.0216 106.659 91.8322C106.659 91.8322 102.517 93.6313 98.2212 94.1334C93.9257 94.6355 93.0842 92.8085 93.2004 91.8322H53.3828V97.6898H64.7493V103.757L93.2004 106.197V117.633L80.0209 120.841L78.4867 116.518H66.2137L64.7493 119.935L58.2641 125.792V130.883H121.791Z"
        stroke="white"
        strokeWidth="2.5"
      />
      <circle
        cx="106.589"
        cy="116.657"
        r="7.81011"
        stroke="white"
        strokeWidth="1.2"
      />
      <circle
        cx="106.588"
        cy="116.656"
        r="5.135"
        stroke="white"
        strokeWidth="1.2"
      />
      <circle
        cx="106.591"
        cy="116.659"
        r="2.21"
        stroke="white"
        strokeWidth="1.2"
      />
      <circle
        cx="115.514"
        cy="103.686"
        r="3.90506"
        stroke="white"
        strokeWidth="1.2"
      />
      <circle
        cx="115.515"
        cy="103.686"
        r="1.53413"
        stroke="white"
        strokeWidth="1.2"
      />
      <circle cx="112.306" cy="68.9597" r="1.6736" fill="white" />
      <circle cx="115.515" cy="74.3982" r="1.6736" fill="white" />
      <circle cx="117.746" cy="79.8377" r="1.6736" fill="white" />
      <circle cx="119.838" cy="85.9744" r="1.6736" fill="white" />
      <circle cx="120.814" cy="91.8318" r="1.6736" fill="white" />
      <rect
        x="52.2628"
        y="34.5362"
        width="14.957"
        height="14.957"
        transform="rotate(30 52.2628 34.5362)"
        stroke="white"
        strokeWidth="2.5"
      />
      <rect
        x="86.9409"
        y="66.2248"
        width="0.906531"
        height="22.16"
        transform="rotate(68 86.9409 66.2248)"
        stroke="white"
        strokeWidth="0.906531"
      />
      <rect
        x="80.4957"
        y="73.0135"
        width="11.8888"
        height="5.75225"
        rx="2.87612"
        transform="rotate(68 80.4957 73.0135)"
        stroke="white"
        strokeWidth="2.5"
      />
      <rect
        x="86.2513"
        y="69.2021"
        width="9.6573"
        height="5.75225"
        rx="2.87612"
        transform="rotate(50 86.2513 69.2021)"
        stroke="white"
        strokeWidth="2.5"
      />
      <rect
        x="68.4277"
        y="85.9453"
        width="9.6573"
        height="5.75225"
        rx="2.87612"
        transform="rotate(-90 68.4277 85.9453)"
        stroke="white"
        strokeWidth="2.5"
      />
      <path
        d="M63.911 64.9042L83.2647 65.8044L66.7803 72.3306L63.911 64.9042Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <rect
        x="43.3844"
        y="35.2375"
        width="5.01616"
        height="7.34691"
        transform="rotate(30 43.3844 35.2375)"
        stroke="white"
        strokeWidth="1.3"
      />
      <rect
        x="38.9"
        y="31.844"
        width="3.46903"
        height="8.74157"
        transform="rotate(30 38.9 31.844)"
        stroke="white"
        strokeWidth="1.3"
      />
      <rect
        x="-0.737059"
        y="0.197494"
        width="1.07913"
        height="8.12564"
        transform="matrix(-0.866025 -0.5 -0.5 0.866025 36.3386 30.9773)"
        stroke="white"
        strokeWidth="1.07913"
      />
    </svg>
  );
}
