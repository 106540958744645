import { Tab } from "rc-tabs/lib/interface";
import { LocaleLang } from "../../../core/LocaleService";
import {
  IOrderListRequest,
  OrderItem,
  StaticSourceItem,
} from "../../../services/order.service";
import { IUserInfo } from "../../../services/user.service";
import { objectHasProperties } from "../../../utils/commonUtils";
import { isNumber } from "../../../utils/isType";
import OrderRequestModel from "../../OrderRequest/model/OrderRequestModel";
import { DropDownOption } from "../../components/DropDownVariant/DropDownVriant";
import { disposeOrderStepSummary } from "../../service/roleStateService/RoleStepPageService";
import OrderListModel, { OrderStatus, OrderTableItem } from "../OrderListModel";
import { PcListViewType } from "./OrderListPc";

export enum TableActions {
  VIEW = "view",
  RESET = "reset",
}

export default class OrderListPcModel extends OrderListModel {
  static readonly SUB_ORDER = "subsStandardOrder";
  static readonly FINAL_GOODS_RECIPE = "fullFinalGoodsRecipName";
  static readonly MAIN_PRODUCT = "mainProduct";
  static readonly SALE_EMPLOYEE = "salesEmployee";
  static readonly SERIAL_NUMBERS = "serialNumbers";
  static readonly CONTACT_PERSON = "contactPersonName";
  static readonly SERVICE_REGION = "serviceRegion";
  static readonly STEP = "step";
  static readonly OWNER = "owners";
  static readonly OPERATION = "operation";

  static readonly DEFAULT_PAGE_INDEX = 1;
  static readonly DEFAULT_PAGE_NUMBER = 10;
  static readonly PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  static readonly LANG_ZH = "中文";
  static readonly LANG_EN = "English";

  static getListPc = (
    params: IOrderListRequest,
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    userInfo: IUserInfo,
    locale?: LocaleLang
  ) => {
    return OrderListModel.getList(params).then((res) => ({
      ...res,
      data: {
        ...res.data,
        list: OrderListPcModel.formatListData(
          res.data.list,
          orgs,
          userInfo,
          locale
        ),
      },
    }));
  };

  static getAdminListPc = (
    params: IOrderListRequest,
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    userInfo: IUserInfo,
    locale?: LocaleLang
  ) => {
    return OrderListModel.getAdminList(params).then((res) => ({
      ...res,
      data: {
        ...res.data,
        list: OrderListPcModel.formatListData(
          res.data.list,
          orgs,
          userInfo,
          locale
        ),
      },
    }));
  };

  static calcListPageSize = (total: number): number[] => {
    if (!isNumber(total)) return [];
    return OrderListPcModel.PAGE_SIZE_OPTIONS.filter(
      (size) => total / size > 1
    );
  };

  static formatListData = (
    list: OrderItem[],
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    userInfo: IUserInfo,
    locale?: LocaleLang
  ): OrderTableItem[] => {
    if (
      !Array.isArray(list) ||
      !objectHasProperties(orgs, ["apSupervisor", "regionProductLine"])
    )
      return [];
    return list.map((item) => {
      const formattedOrderStepSummary = disposeOrderStepSummary(
        item.orderStepSummary,
        userInfo
      );
      const { key, userNameCN, userNameEN } = formattedOrderStepSummary ?? {};
      const isUnSplitOrder = OrderListModel.isUnSplitOrder(
        formattedOrderStepSummary,
        item.parentInstallationTaskId as string
      );
      const serialNumbers = Array.isArray(item.serialNumbers)
        ? item.serialNumbers.slice(0, 5).join(", ")
        : "";

      return {
        id: item.id,
        parentInstallationTaskId: item.parentInstallationTaskId,
        fullFinalGoodsRecipName: item.fullFinalGoodsRecipName,
        contactPersonName: item.contactPersonName,
        mainProduct: item.mainProduct,
        subsStandardOrder: item.subsStandardOrder,
        salesEmployee: OrderRequestModel.formatNameByLocale(
          item.salesEmployeeNameCN,
          item.salesEmployeeNameEN,
          locale
        ),
        step: OrderRequestModel.formatStepLabel(key),
        serialNumbers,
        serviceRegion: OrderRequestModel.getRegionLabel(
          orgs,
          item.serviceRegion
        ) as string,
        owners: OrderRequestModel.formatNameByLocale(
          userNameCN as string,
          userNameEN as string,
          locale
        ),
        OrderStepSummary: formattedOrderStepSummary,
        isUnSplitOrder,
        isFinalGoodsRecipNew: item.isFinalGoodsRecipNew,
        isMain: item.isMain,
      };
    });
  };
  static getOrderStatusList = (viewType?: PcListViewType): Tab[] => {
    const {
      labelStatusTodo,
      labelStatusDone,
      labelStatusComplete,
      labelStatusCancel,
      labelStatusAll,
    } = OrderListModel.getLabels();
    const isAdminView = viewType === PcListViewType.Admin;

    const list = [
      {
        key: `${OrderStatus.ALL}`,
        label: labelStatusAll,
      },
      {
        key: `${OrderStatus.PENDING_IN_PROGRESS}`,
        label: labelStatusTodo,
        hide: isAdminView,
      },
      {
        key: `${OrderStatus.PENDING_RESOLVE}`,
        label: labelStatusDone,
        hide: isAdminView,
      },
      {
        key: `${OrderStatus.RESOLVED}`,
        label: labelStatusComplete,
        hide: isAdminView,
      },
      {
        key: `${OrderStatus.CANCEL}`,
        label: labelStatusCancel,
      },
    ];

    return list.filter(({ hide }) => !hide);
  };

  static getTableColumns = (): { key: string; label: string }[] => {
    const {
      labelSubOrder,
      labelFinalGoodsRecipe,
      labelContactPerson,
      labelMainProduct,
      labelInfoSaleEmp,
      labelDnSerialNumber,
      labelRegin,
      labelStep,
      labelOwner,
      labelOperate,
    } = OrderListModel.getLabels();
    return [
      {
        key: OrderListPcModel.SUB_ORDER,
        label: labelSubOrder,
      },
      {
        key: OrderListPcModel.FINAL_GOODS_RECIPE,
        label: labelFinalGoodsRecipe,
      },
      {
        key: OrderListPcModel.SALE_EMPLOYEE,
        label: labelInfoSaleEmp,
      },
      {
        key: OrderListPcModel.MAIN_PRODUCT,
        label: labelMainProduct,
      },
      {
        key: OrderListPcModel.SERIAL_NUMBERS,
        label: labelDnSerialNumber,
      },
      {
        key: OrderListPcModel.CONTACT_PERSON,
        label: labelContactPerson,
      },
      {
        key: OrderListPcModel.SERVICE_REGION,
        label: labelRegin,
      },
      {
        key: OrderListPcModel.STEP,
        label: labelStep,
      },
      {
        key: OrderListPcModel.OWNER,
        label: labelOwner,
      },
      {
        key: OrderListPcModel.OPERATION,
        label: labelOperate,
      },
    ];
  };

  static getLangOptions = (): DropDownOption[] => {
    return [
      {
        text: OrderListPcModel.LANG_ZH,
        value: LocaleLang.ZH_CN,
      },
      {
        text: OrderListPcModel.LANG_EN,
        value: LocaleLang.EN_US,
      },
    ];
  };
}
