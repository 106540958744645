import * as React from "react";

import { AccordionItemProps } from "./types";
import { AccordionContext } from "./useAccordion";

/**
 * useAccordionItem
 *
 * React hook that provides the open/close functionality
 * for an accordion item and its children
 */
let indexCounter = -1;

export function useAccordionItem(props: AccordionItemProps) {
  const { disabled, id, ...htmlProps } = props;
  const { getAccordionItemProps } = React.useContext(AccordionContext);

  // eslint-disable-next-line no-return-assign
  const index = React.useMemo(() => (indexCounter += 1), []);

  const { isOpen, onChange } = getAccordionItemProps(index);

  const onOpen = () => {
    onChange?.(true);
  };

  const onClose = () => {
    onChange?.(false);
  };

  /**
   * Toggle the visibility of the accordion item
   */
  const onClick = React.useCallback(() => {
    onChange?.(!isOpen);
  }, [index, isOpen, onChange]);

  return {
    isOpen,
    disabled,
    onOpen,
    onClose,
    onClick,
    htmlProps,
  };
}

export type UseAccordionItemReturn = ReturnType<typeof useAccordionItem>;
export const AccordionItemContext = React.createContext<
  Omit<UseAccordionItemReturn, "htmlProps">
>({
  isOpen: false,
  disabled: false,
  onOpen: () => {},
  onClose: () => {},
  onClick: () => {},
});
AccordionItemContext.displayName = "AccordionItemContext";
