import { OrderStep } from "../../service/OrderStepTypes";
import { PageSubItems } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import FacIBaseCRMUpdate from "../FacIBase/FacIBaseCRM/FacIBaseCRMUpdate";
import FacIBaseFacUpload from "../FacIBase/FacIBaseFac/FacIBaseFacUpload";
import InstallationApEngineer from "../Installation/InstallationApEngineer/InstallationApEngineer";
import InstallationApSupervisor from "../Installation/InstallationApSupervisor/InstallationApSupervisor";
import InstallationFse from "../Installation/InstallationFse/InstallationFse";
import InstallationRsm from "../Installation/InstallationRsm/InstallationRsm";
import InstallationSales from "../Installation/InstallationSales/InstallationSales";
import InstallationSo from "../Installation/InstallationSo/InstallationSo";
import InfoSales from "../OrderInfo/InfoSales/InfoSales";
import SplitOrder from "../OrderInfo/SplitOrder/SplitOrder";
import SiteSurveyFse from "../SiteSurvey/SiteSurveyFSE/SiteSurveyFse";
import SiteSurveyRsm from "../SiteSurvey/SiteSurveyRsm/SiteSurveyRsm";

export default class StepPageModel {
  static getStepPageByTab = (
    tab: PageSubItems,
    step: OrderStep,
    role: Role
  ): React.ReactNode => {
    const tabList = [
      {
        tab: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
        subTabs: [
          {
            step: OrderStep.SPLIT_ORDER,
            page: <SplitOrder key={OrderStep.SPLIT_ORDER} />,
            supportRole: [Role.ROLE_SALES, Role.ROLE_ADMIN],
          },
          {
            step: OrderStep.SITE_SURVEY_SUBMIT,
            page: <InfoSales key={OrderStep.SITE_SURVEY_SUBMIT} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_RSM,
              Role.ROLE_FSE,
              Role.ROLE_RSM_MANAGER,
              Role.ROLE_APPLY_SUPERVISOR,
              Role.ROLE_APPLY_ENGINEER,
              Role.ROLE_AP_MANAGER,
            ],
          },
        ],
      },
      {
        tab: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
        subTabs: [
          {
            step: OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
            page: <SiteSurveyRsm key={OrderStep.SUBMIT_SITE_SURVEY_ASSIGN} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_RSM,
              Role.ROLE_RSM_MANAGER,
              Role.ROLE_SO,
            ],
          },
          {
            step: OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
            page: (
              <SiteSurveyFse key={OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT} />
            ),
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_RSM,
              Role.ROLE_FSE,
              Role.ROLE_RSM_MANAGER,
            ],
          },
        ],
      },
      {
        tab: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
        subTabs: [
          {
            step: OrderStep.SUBMISSION,
            page: <InstallationSales key={OrderStep.SUBMISSION} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
            ],
          },
          {
            step: OrderStep.AUDIT,
            page: <InstallationSo key={OrderStep.AUDIT} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
            ],
          },
          {
            step: OrderStep.ASSIGN,
            page: <InstallationRsm key={OrderStep.ASSIGN} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_RSM,
              Role.ROLE_RSM_MANAGER,
            ],
          },
          {
            step: OrderStep.INSTALL,
            page: <InstallationFse key={OrderStep.INSTALL} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_RSM,
              Role.ROLE_FSE,
              Role.ROLE_RSM_MANAGER,
            ],
          },
          {
            step: OrderStep.TRAINING_ASSIGN,
            page: <InstallationApSupervisor key={OrderStep.TRAINING_ASSIGN} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_APPLY_SUPERVISOR,
              Role.ROLE_AP_MANAGER,
            ],
          },
          {
            step: OrderStep.TRAINING_IMPLEMENT,
            page: <InstallationApEngineer key={OrderStep.TRAINING_IMPLEMENT} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_APPLY_SUPERVISOR,
              Role.ROLE_APPLY_ENGINEER,
              Role.ROLE_AP_MANAGER,
            ],
          },
        ],
      },
      {
        tab: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
        subTabs: [
          {
            step: OrderStep.FAC_UPLOAD,
            page: <FacIBaseFacUpload key={OrderStep.FAC_UPLOAD} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_RSM,
            ],
          },
          {
            step: OrderStep.SO_CONFIRM,
            page: <FacIBaseCRMUpdate key={OrderStep.SO_CONFIRM} />,
            supportRole: [
              Role.ROLE_ADMIN,
              Role.ROLE_SALES_MANAGER,
              Role.ROLE_SALES,
              Role.ROLE_SO,
              Role.ROLE_RSM,
              Role.ROLE_SALES_MANAGER,
            ],
          },
        ],
      },
    ];

    const filterPages = tabList
      .find(({ tab: _tab }) => _tab === tab)
      ?.subTabs.filter(
        ({ step: _step, supportRole }) =>
          _step <= step && supportRole.includes(role)
      );

    return filterPages?.reverse()[0]?.page ?? <></>;
  };
}
