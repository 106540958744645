import { OrderStep } from "../OrderStepTypes";
import { OrderRequestPage, PageStatus, PageSubItems } from "../pageType";
import { ApManagerAction, Role } from "../userRoleTypes";
import { OrderStepSummary, RoleStepPageService } from "./RoleStepPageService";

export default class ApManagerStepPageService implements RoleStepPageService {
  private static readonly STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_AP_MANAGER,
      state: OrderStep.TRAINING_ASSIGN,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_AP_MANAGER,
      state: OrderStep.TRAINING_IMPLEMENT,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_ORDER_FAC_LM_META_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_AP_MANAGER,
      state: OrderStep.FAC_UPLOAD_LM,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
      ],
    };

  private static readonly STATE_CRM_IBASE_CONFIRM_SUB_ITEMS: OrderRequestPage =
    {
      role: Role.ROLE_AP_MANAGER,
      state: OrderStep.SO_CONFIRM,
      subTabs: [
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
        {
          subItem: PageSubItems.PAGE_ORDER_REQUEST_SUB_FAC,
          pageStatus: PageStatus.READONLY,
          actions: [ApManagerAction.ALL_TICKET],
        },
      ],
    };

  static getRolePageListByStep = (
    step: OrderStepSummary
  ): OrderRequestPage[] => {
    return [
      ApManagerStepPageService.STATE_INSTALLATION_TRAINING_ASSIGN_SUB_ITEMS,
      ApManagerStepPageService.STATE_INSTALLATION_TRAINING_IMPLEMENT_SUB_ITEMS,
      ApManagerStepPageService.STATE_ORDER_FAC_LM_META_SUB_ITEMS,
      ApManagerStepPageService.STATE_CRM_IBASE_CONFIRM_SUB_ITEMS,
    ];
  };
}
