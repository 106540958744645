import { Button } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React, { SyntheticEvent } from "react";
import { isFunction, isString } from "../../utils/isType";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../Modal/Modal";
import styles from "./Confirmor.module.scss";

export type CloseType = "cancel" | "ok";
export enum buttonType {
  primary = "primary-highlight",
  default = "default",
  disable = "disabled",
}

export interface IConfirmor extends IModal {
  confirmClassName?: string;
  contentClassName?: string;
  buttonTexts?: {
    type: "primary" | "secondary" | "tertiary";
    label: string;
    closeType: string & CloseType;
    disabled?: boolean;
    size?: "s" | "m" | "l";
  }[];
  hideFooter?: boolean;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  footer?: React.ReactNode;
  onConfirm?: (type: CloseType, evt?: SyntheticEvent) => void;
}

const labelCancel = "Cancel",
  labelOk = "Ok";

const Confirmor: React.FC<IConfirmor> = ErrorDetectorModalView(
  (props: IConfirmor) => {
    const {
      confirmClassName,
      buttonTexts,
      hideFooter,
      title,
      content,
      footer,
      className,
      contentClassName,
      onConfirm,
      ...rest
    } = props;

    const confirmCls = classNames(styles.confirmor, confirmClassName);
    const containCls = classNames(styles.modelContainer, className);

    const confirmHandler = React.useCallback(
      (closeType: string & CloseType, evt?: SyntheticEvent) => {
        isFunction(onConfirm) && onConfirm(closeType, evt);
      },
      [onConfirm]
    );

    const onCloseHandler = React.useCallback((evt: SyntheticEvent) => {
      isFunction(onConfirm) && onConfirm("cancel", evt);
    }, []);

    const titleView = React.useMemo(() => {
      const view = <div className={styles.title}>{title}</div>;
      return isString(title) ? view : title;
    }, [title]);

    const contentView = React.useMemo(() => {
      const cls = classNames(styles.content, contentClassName, {
        [styles.textCenter]: isString(content),
      });
      return <div className={cls}>{content}</div>;
    }, [content, contentClassName]);

    const footerView = React.useMemo(() => {
      if (hideFooter) return null;
      if (footer) return footer;
      const btnViews = buttonTexts?.map((item) => {
        const { type, label, closeType, disabled, size } = item;
        return (
          <Button
            className={styles.btnContainer}
            key={label}
            onClick={(evt) => confirmHandler(closeType, evt)}
            variant={type}
            disabled={disabled}
            size={size}
          >
            {label}
          </Button>
        );
      });

      return <div className={styles.footer}>{btnViews}</div>;
    }, [confirmHandler, hideFooter, footer, buttonTexts]);

    return (
      <Modal
        {...rest}
        onClose={onCloseHandler}
        className={containCls}
        animation={""}
      >
        <div className={confirmCls}>
          {titleView}
          {contentView}
          {footerView}
        </div>
      </Modal>
    );
  }
);

export default Confirmor;
