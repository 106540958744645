import classNames from "classnames";
import type { InputProps, InputRef } from "rc-input";
import RcInput from "rc-input";
import React, { forwardRef, isValidElement } from "react";
import { ErrorDetectorSmallView } from "../../ErrorDecorator/ErrorDecorator";
import {
  AppType,
  BasicView,
  IBasicViewProps,
  LabelIndicator,
} from "../../ViewWrapper/ViewWrapper";
import styles from "./InputBasic.module.scss";

export interface IInputBasicProps
  extends InputProps,
    Partial<InputRef>,
    IBasicViewProps {
  containerClassName?: string;
  label?: string;
  error?: boolean | string | React.ReactNode;
}

export const InputBasic: React.FC<IInputBasicProps> = ErrorDetectorSmallView(
  forwardRef<InputRef, IInputBasicProps>((props: IInputBasicProps, ref) => {
    const { containerClassName, error, disabled, indicator, ...rest } = props;
    const isError = error !== undefined && error !== false;
    const _indicator = disabled ? LabelIndicator.TYPE_OPTIONAL : indicator;

    const _containerClassName = classNames(
      styles.container,
      containerClassName,
      { [styles.error]: isError },
      { [styles.disabled]: disabled }
    );

    const errorView = React.useMemo(() => {
      if (!isError) return null;
      if (isValidElement(error)) return error;
      return <div className={styles.errorText}>{error}</div>;
    }, [isError, error]);

    return (
      <BasicView
        {...rest}
        viewType={AppType.TYPE_PC}
        labelClassName={styles.label}
        indicator={_indicator}
      >
        <div className={_containerClassName}>
          <RcInput {...rest} ref={ref} disabled={disabled} />
        </div>
        {errorView}
      </BasicView>
    );
  })
);
