import { cloneDeep } from "lodash";
import { UploadItem } from "../../../components/Upload/Upload";
import { identifyFileTypeByName } from "../../../components/Upload/utils";
import HttpService, { Response } from "../../../core/HttpService";
import { formatMessage } from "../../../core/LangService";
import { LocaleLang } from "../../../core/LocaleService";
import {
  IOrderBasicInfoRes,
  IOrderComments,
  IPreInstallReq,
  IPreInstallRes,
  IUploadFile,
  OrgRes,
  StaticSourceItem,
  getOrderBasicInfo,
  getOrderComment,
  getOrderSteps,
  getPreInstallInfo,
  getSaleOrg,
  insertComments,
  submitPreInstallInfo,
} from "../../../services/order.service";
import { logout } from "../../../services/system.service";
import { RegUtil } from "../../../utils/RegUtils";
import {
  convertImageToBase64,
  downloadAttachment,
  downloadByUrl,
  formatDate,
  isImageUrl,
  isPdfUrl,
  isWeComEnv,
} from "../../../utils/commonUtils";
import { isBoolean, isObject } from "../../../utils/isType";
import { StepIcon } from "../../components/StepVariant/StepVariant";
import { ViewType } from "../../components/ViewTypes";
import { OrderStep, OrderStepStatus } from "../../service/OrderStepTypes";

import SystemService, { SystemPlatform } from "../../../core/SystemService";
import { IFacIBase } from "../FacIBase/FacIBaseModel";
import { IInstallation } from "../Installation/installationModel";
import {
  AccessoryProperties,
  AccessoryTypes,
} from "../SiteSurvey/model/SiteSurveyModel";
import {
  IStepItemProcess,
  ProcessAction,
} from "../components/StepVariantProcess/StepVariantProcess";
import { VerifyType } from "../service/ValidationService";

export interface OrderStepItem {
  key: OrderStep;
  status: OrderStepStatus;
  action?: ProcessAction;
  author: string;
  authorNameCN?: string;
  authorNameEN?: string;
  time: string | number;
  comments?: string;
}

export enum CommentKey {
  SPLIT_ORDER = OrderStep.SPLIT_ORDER,
  SITE_SURVEY_SUBMIT = OrderStep.SITE_SURVEY_SUBMIT,
  SUBMIT_SITE_SURVEY_ASSIGN = OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
  SUBMIT_SITE_SURVEY_IMPLEMENT = OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
  SUBMIT_SITE_SURVEY_IMPLEMENT_IN_PROGRESS = OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT_IN_PROGRESS,
  SUBMIT_SITE_SURVEY_IMPLEMENT_IN_COMPLETE = OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT_IN_COMPLETE,
  SUBMISSION = OrderStep.SUBMISSION,
  AUDIT = OrderStep.AUDIT,
  ASSIGN = OrderStep.ASSIGN,
  INSTALL = OrderStep.INSTALL,
  INSTALL_IN_PROGRESS = OrderStep.INSTALL_IN_PROGRESS,
  INSTALL_COMPLETE = OrderStep.INSTALL_COMPLETE,
  TRAINING_ASSIGN = OrderStep.TRAINING_ASSIGN,
  TRAINING_IMPLEMENT = OrderStep.TRAINING_IMPLEMENT,
  TRAINING_IMPLEMENT_IN_PROGRESS = OrderStep.TRAINING_IMPLEMENT_IN_PROGRESS,
  TRAINING_IMPLEMENT_COMPLETE = OrderStep.TRAINING_IMPLEMENT_COMPLETE,
  FAC_UPLOAD = OrderStep.FAC_UPLOAD,
  FAC_UPLOAD_LM = OrderStep.FAC_UPLOAD_LM,
  FAC_UPLOAD_EM = OrderStep.FAC_UPLOAD_EM,
  SO_CONFIRM = OrderStep.SO_CONFIRM,

  EMPLOYEE_QUIT = 1001,
}
export interface IFile {
  name: string;
  size?: number;
  url: string;
  fileId?: string;
}

export interface ViewItemModel {
  key: string;
  keyLabel: string;
  viewType: ViewType;
  props?: Record<string, any>;
  verifyType?: [VerifyType] | [VerifyType, VerifyType];
  valid?: boolean;
  [key: string]: any;
}

export interface IMoreDetailItem {
  itemNo?: string;
  itemDescription: string;
  itemOrderQuantity: number;
  itemMaterial: string;
  isMain?: boolean; // define as main order, user as first
}

export enum CustomerTypes {
  NOT_INDUSTRY = 1, // dn need be done before installation
  INDUSTRY = 2,
}

export enum SaleTeam {
  CLINIC = 1,
  INDUSTRY = 2,
  ALS = 3,
  AMS = 4,
}

export enum ProductLine {
  EM = 1,
  LM = 2,
  META = 3,
  XRM = 4,
}

export enum OrderTabKey {
  INFO = "info",
  SITE_SURVEY = "siteSurvey",
  INSTALLATION = "installation",
  FAC_IBASE = "facIBase",
}

export interface IPartnerTeamOtherInfo {
  incoterms: string;
  customerType: CustomerTypes;
  salesEmployeeNameCN: string;
  salesEmployeeNameEN: string;
  salesEmployee: string;
  salesManagerNameCN: string;
  salesManagerNameEN: string;
  salesManager?: string;
  ownerRsmNameCN: string;
  ownerRsmNameEN: string;
  ownerRsm?: string;
  rsmNameCN: string;
  rsmNameEN: string;
  rsm?: string;
  facUploaderNameCN: string;
  facUploaderNameEN: string;
  facUploader?: string;
  rsmSiteSurveyCN: string;
  rsmSiteSurveyEN: string;
  rsmSiteSurvey?: string;
  rsmInstallationCN: string;
  rsmInstallationEN: string;
  rsmInstallation?: string;
  serviceSupervisor?: string; // named as rsm
  fseSiteSurveyNameCN: string;
  fseSiteSurveyNameEN: string;
  fseSiteSurvey?: string;
  fseInstallationNameCN: string;
  fseInstallationNameEN: string;
  fseInstallation?: string;
  soNameEN: string;
  soNameCN: string;
  so?: string | number;
  apSupervisorNameEN: string;
  apSupervisorNameCN: string;
  apSupervisor: string | number | undefined;
  apEngineerNameCN: string;
  apEngineerNameEN: string;
  apEngineer: string | number;
  saleTeam: number | null;
  region: number | null;
  productLine: number | null;
  productItems: IMoreDetailItem[];
}

export interface IDNInfo {
  cpoDate: number;
  installationOrderNo: string[];
  serialNumber: string[];
  serviceContractorOrder: string;
  dnItems: IMoreDetailItem[];
}

export interface IContactPerson {
  name: string;
  recipCity: string;
  recipAddress: string;
  mobile: string;
  email: string;
}

export interface IThirdParty
  extends Record<Partial<AccessoryTypes>, Partial<AccessoryProperties>> {}
export interface IFinalGoodsReceipt {
  name: string;
  recipCity: string;
  recipAddress: string;
  isNew?: boolean;
}

export interface IPrepareInstallation {
  partnerTeamOtherInfo: IPartnerTeamOtherInfo;
  dnInfo: IDNInfo;
  dnDelivered: boolean;
  contactPerson: IContactPerson;
  finalsGoodsRecip: IFinalGoodsReceipt[];
  isFinalGoodsRecipNew: boolean;
  soldParty: IFinalGoodsReceipt;
  thirdParty: IThirdParty;
  isSiteSurvey: boolean;
  expectedStartDate: number;
  expectedEndDate: number;
  disclaimer: IFile[];
  isSiteSurveyDone: boolean;
  isRsmNeedSiteSurvey?: boolean;
  actuarySiteSurveyDate: number;
  siteSurveyReports: IFile[];
  installationTaskId?: string;
}

export interface Draft {
  saleSiteSurvey: Partial<IPrepareInstallation>;
  fseSiteSurvey?: Pick<
    IPrepareInstallation,
    "actuarySiteSurveyDate" | "siteSurveyReports"
  >;
  saleInstallation: Partial<IInstallation>;
  soInstallation: Pick<
    IInstallation,
    | "deliveryNotes"
    | "technicalAgreements"
    | "siteSurveyReports"
    | "disclaimers"
  >;
  rsmInstallation: Pick<
    IInstallation,
    "planedInstallationStartDate" | "planedInstallationEndDate"
  >;
  fseInstallation: Pick<
    IInstallation,
    "actualInstallStartDate" | "actualInstallEndDate" | "installationReports"
  >;
  apSupervisorTraining?: Pick<
    IInstallation,
    "planedTrainingStartDate" | "planedTrainingEndDate"
  >;
  apEngineerTraining?: Pick<
    IInstallation,
    "actualTrainingStartDate" | "actualTrainingEndDate"
  >;
  facUpload?: Partial<IFacIBase>;
}

export default class OrderRequestModel {
  static getOrderBasicInfo = (id: string): Promise<IOrderBasicInfoRes> => {
    const infoRes = HttpService.splitResponse(getOrderBasicInfo(id));
    return infoRes.then((res) =>
      OrderRequestModel.formatOrderBasicInfoLogic(res)
    );
  };

  static getOrderSteps = (
    id: string,
    locale?: LocaleLang
  ): Promise<Response<IStepItemProcess[]>> => {
    return HttpService.splitResponse(getOrderSteps(id)).then((res) => ({
      ...res,
      data: OrderRequestModel.formatSteps(res?.data, locale),
    }));
  };

  static getPreInstallInformation = (
    installationTaskId: string,
    locale?: LocaleLang
  ): Promise<IPreInstallRes> => {
    const infos = HttpService.splitResponse(
      getPreInstallInfo(installationTaskId)
    );
    return infos.then((res) => ({
      ...res,
      data: {
        ...res?.data,
        disclaimer: OrderRequestModel.formatFile(res?.data?.disclaimer),
        siteSurveyReports: OrderRequestModel.formatFile(
          res?.data?.siteSurveyReports
        ),
        partnerTeamOtherInfo: OrderRequestModel.formatPartPartnerTeamOtherInfo(
          res?.data?.partnerTeamOtherInfo,
          locale
        ),
      },
    }));
  };

  static submitPreInstallationInfo = (
    data: IPreInstallReq
  ): Promise<Response<{}>> => {
    return HttpService.splitResponse(submitPreInstallInfo(data));
  };

  static getSaleOrg = (
    locale?: LocaleLang
  ): Promise<
    Response<{
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    }>
  > => {
    const orgRes = HttpService.splitResponse(getSaleOrg());
    return orgRes.then((res) => ({
      ...res,
      data: OrderRequestModel.formatOrg(res?.data, locale),
    }));
  };

  static getOrderComments = (
    id: string,
    locale?: LocaleLang
  ): Promise<Response<IStepItemProcess[]>> => {
    return HttpService.splitResponse(getOrderComment(id)).then((res) => ({
      ...res,
      data: OrderRequestModel.formatComments(res?.data, locale),
    }));
  };

  static insertComments = (data: IOrderComments): Promise<Response<{}>> => {
    return HttpService.splitResponse(insertComments(data));
  };

  static logout = (): Promise<Response<{}>> => {
    return HttpService.splitResponse(logout());
  };

  static getLabels = () => {
    return {
      labelYes: formatMessage("common_yes"),
      labelNo: formatMessage("common_no"),
      labelComments: formatMessage("common_comments"),
      labelEdit: formatMessage("common_edit"),
      labelBtnAllTickets: formatMessage("common_btn_all_tickets"),
      labelBtnForward: formatMessage("common_btn_forward"),
      labelBtnAssign: formatMessage("common_btn_assign"),
      labelBtnReject: formatMessage("common_btn_reject"),
      labelBtnSubmit: formatMessage("common_btn_submit"),
      labelBtnDelete: formatMessage("common_btn_delete"),
      labelBtnApprove: formatMessage("common_btn_approve"),
      labelBtnMore: formatMessage("common_btn_more"),
      labelBtnAddSiteSurvey: formatMessage("common_btn_add_site_survey"),
      labelMainProduct: formatMessage("order_main_product"),
      labelStepStatusOnGoing: formatMessage("common_on_going"),
      labelStepStatusCompleted: formatMessage("common_completed"),
      labelStepStatusNotStart: formatMessage("common_not_start"),
      labelYesUpper: formatMessage("common_yes_upper"),
      labelNoUpper: formatMessage("common_no_upper"),
      labelSelectPhd: formatMessage("order_select_phd"),
    };
  };

  static getCustomerTypeOptions = () => {
    return [
      {
        value: CustomerTypes.NOT_INDUSTRY,
        text: formatMessage("order_customer_type_not_industry"),
      },
      {
        value: CustomerTypes.INDUSTRY,
        text: formatMessage("order_customer_type_industry"),
      },
    ];
  };

  static getCustomerLabel = (type: CustomerTypes): string => {
    return (
      OrderRequestModel.getCustomerTypeOptions().find(
        ({ value }) => value === type
      )?.text ?? ""
    );
  };

  static getSaleTeamOptions = () => {
    return [
      {
        value: SaleTeam.CLINIC,
        text: "Clinic",
      },
      {
        value: SaleTeam.INDUSTRY,
        text: "Industry",
      },
      {
        value: SaleTeam.ALS,
        text: "ALS",
      },
      {
        value: SaleTeam.AMS,
        text: "AMS",
      },
    ];
  };

  static getSaleTeamLabel = (team: SaleTeam): string => {
    return (
      OrderRequestModel.getSaleTeamOptions().find(({ value }) => value === team)
        ?.text ?? ""
    );
  };

  static getApSupervisorList = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    lang?: LocaleLang
  ) => {
    return orgs.apSupervisor ?? [];
  };

  static getApSupervisorLabel = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    apSupervisor: string,
    lang?: LocaleLang
  ) => {
    return OrderRequestModel.getApSupervisorList(orgs)?.find(
      (item: any) => item?.value === apSupervisor
    )?.text;
  };

  static getSoByRegion = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    region: number,
    lang?: LocaleLang
  ) => {
    return orgs?.regionProductLine?.find((item) => item.value === region);
  };

  static getProductLines = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    region: number,
    lang?: LocaleLang
  ) => {
    return (
      orgs?.regionProductLine?.find((item) => item.value === region)
        ?.productLines ?? []
    );
  };

  static getRegionLabel = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    region: number,
    lang?: LocaleLang
  ) => {
    return orgs?.regionProductLine?.find((item) => item.value === region)?.text;
  };

  static getServiceSupervisorLabel = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    region: number,
    productLine: number,
    lang?: LocaleLang
  ) => {
    const item = orgs?.regionProductLine?.find((item) => item.value === region);
    return item?.productLines?.find(
      (item: StaticSourceItem) => item.value === productLine
    )?.rsm?.text;
  };

  static getServiceCoordinatorLabel = (
    orgs: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    region: number,
    lang?: LocaleLang
  ) => {
    return orgs?.regionProductLine?.find((item) => item.value === region)?.so
      ?.text;
  };

  static getToggleLabel = (value: boolean) => {
    const { labelYesUpper, labelNoUpper } = OrderRequestModel.getLabels();
    return isBoolean(value) ? (value ? labelYesUpper : labelNoUpper) : "";
  };

  private static formatOrderBasicInfoLogic = (
    res: IOrderBasicInfoRes
  ): IOrderBasicInfoRes => {
    const { poDate } = (res?.data as any) ?? {};

    return {
      ...res,
      data: {
        ...res.data,
        poDate: formatDate(poDate) as any,
      },
    };
  };

  private static formatOrg = (
    orgs: OrgRes,
    locale?: LocaleLang
  ): {
    apSupervisor: StaticSourceItem[];
    regionProductLine: Partial<StaticSourceItem>[];
  } => {
    const { apSupervisor, regionProductLine } = orgs ?? {};
    if (!Array.isArray(apSupervisor) && !Array.isArray(regionProductLine))
      return { apSupervisor: [], regionProductLine: [] };

    const _list = cloneDeep(apSupervisor);
    _list.sort((pre, next) =>
      pre?.userNameCN?.localeCompare(next.userNameCN, "zh")
    );
    return {
      apSupervisor: _list.map(
        ({ key: value, userNameCN, userNameEN, email }) => ({
          value,
          text: OrderRequestModel.formatNameByLocale(
            userNameCN,
            userNameEN,
            locale
          ),
          userNameCN,
          userNameEN,
          email: email,
        })
      ),
      regionProductLine: regionProductLine.map(
        ({ region, productLines, so }) => ({
          value: region?.key,
          text: region?.value,
          isDeleted: region?.isDeleted,
          productLines: productLines?.map(
            ({ key: value, value: text, rsm, isDeleted }) => ({
              value,
              text,
              isDeleted,
              rsm: {
                value: rsm?.key,
                text: OrderRequestModel.formatNameByLocale(
                  rsm?.userNameCN,
                  rsm?.userNameEN,
                  locale
                ),
              },
            })
          ),
          so: {
            ...so,
            text: OrderRequestModel.formatNameByLocale(
              so?.userNameCN,
              so?.userNameEN,
              locale
            ),
          },
        })
      ),
    };
  };

  private static formatSteps = (
    steps: OrderStepItem[],
    locale?: LocaleLang
  ): IStepItemProcess[] => {
    if (!Array.isArray(steps)) return [];
    return steps.map((item: OrderStepItem) => {
      const { key, status, authorNameEN, authorNameCN } = item;

      return {
        ...item,
        label: OrderRequestModel.formatStepLabel(key),
        statusLabel: OrderRequestModel.formatStepStatusLabel(status),
        icon: OrderRequestModel.formatStepIcon(status),
        author: OrderRequestModel.formatNameByLocale(
          authorNameCN as string,
          authorNameEN as string,
          locale
        ),
      } as IStepItemProcess;
    });
  };

  private static formatComments = (
    steps: IOrderComments[],
    locale?: LocaleLang
  ): IStepItemProcess[] => {
    if (!Array.isArray(steps)) return [];
    return steps.map((item: IOrderComments) => {
      const {
        key,
        status,
        comments: comment,
        authorNameEN,
        authorNameCN,
        commentsCN,
        commentsEN,
      } = item;
      return {
        ...item,
        label: OrderRequestModel.formatCommentLabel(key),
        statusLabel: OrderRequestModel.formatStepStatusLabel(status),
        icon: OrderRequestModel.formatStepIcon(status),
        author: OrderRequestModel.formatNameByLocale(
          authorNameCN as string,
          authorNameEN as string,
          locale
        ),
        comment:
          OrderRequestModel.formatNameByLocale(
            commentsCN as string,
            commentsEN as string,
            locale
          ) || comment,
      } as IStepItemProcess;
    });
  };

  static formatStepLabel = (step: OrderStep): string => {
    const stepMap = new Map<OrderStep, string>([
      [OrderStep.SPLIT_ORDER, formatMessage("order_list_unSplit_label")],
      [
        OrderStep.SITE_SURVEY_SUBMIT,
        formatMessage("order_step_site_survey_req_sub"),
      ],
      [
        OrderStep.SUBMIT_SITE_SURVEY_ASSIGN,
        formatMessage("order_step_site_survey_assign"),
      ],
      [
        OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT,
        formatMessage("order_step_site_survey_implement"),
      ],
      [OrderStep.SUBMISSION, formatMessage("order_step_install_req")],
      [OrderStep.AUDIT, formatMessage("order_step_install_audit")],
      [OrderStep.ASSIGN, formatMessage("order_step_install_assign")],
      [OrderStep.INSTALL, formatMessage("order_step_install_implement")],
      [OrderStep.TRAINING_ASSIGN, formatMessage("order_step_trainingAssign")],
      [
        OrderStep.TRAINING_IMPLEMENT,
        formatMessage("order_step_training_implement"),
      ],
      [OrderStep.FAC_UPLOAD, formatMessage("order_step_upload_fac")],
      [OrderStep.SO_CONFIRM, formatMessage("order_step_update_crm")],
    ]);

    return stepMap.get(step) ?? "";
  };

  static formatCommentLabel = (step: CommentKey): string => {
    const stepLabel = OrderRequestModel.formatStepLabel(step as any);
    const commentMap = new Map<CommentKey, string>([
      [CommentKey.EMPLOYEE_QUIT, formatMessage("order_comment_employee_quite")],
    ]);

    return (stepLabel || commentMap.get(step)) ?? "";
  };

  static formatStepStatusLabel = (status: OrderStepStatus): string => {
    const statusLabel = new Map<OrderStepStatus, string>([
      [OrderStepStatus.PENDING, formatMessage("common_on_going")],
      [OrderStepStatus.REJECT, formatMessage("common_completed")],
      [OrderStepStatus.RESOLVE, formatMessage("common_completed")],
      [OrderStepStatus.NOT_START, formatMessage("common_not_start")],
      [OrderStepStatus.NOT_REQUIRED, formatMessage("common_not_required")],
    ]);

    return statusLabel.get(status) ?? "";
  };

  static formatStepIcon = (status: OrderStepStatus): StepIcon => {
    const statusIcon = new Map<OrderStepStatus, StepIcon>([
      [OrderStepStatus.PENDING, StepIcon.PENDING],
      [OrderStepStatus.REJECT, StepIcon.COMPLETE],
      [OrderStepStatus.RESOLVE, StepIcon.COMPLETE],
      [OrderStepStatus.NOT_START, StepIcon.INACTIVE],
      [OrderStepStatus.NOT_REQUIRED, StepIcon.INACTIVE],
    ]);

    return statusIcon.get(status) ?? StepIcon.INACTIVE;
  };

  static formatFile = (
    files: { fileName: string; fileUrl: string }[]
  ): Partial<UploadItem>[] => {
    if (!Array.isArray(files)) return [];
    return files
      .filter(({ fileUrl, fileName }) => RegUtil.verifyWebAddr(fileUrl))
      .map(({ fileName: name, fileUrl: url }) => ({
        name,
        url,
        fileType: identifyFileTypeByName(name),
      }));
  };

  static formatUploadItem = (files: IFile[]): IUploadFile[] => {
    if (!Array.isArray(files)) return [];
    return files
      .filter(({ url, name }) => RegUtil.verifyWebAddr(url))
      .map(({ url: fileUrl, name: fileName }) => ({
        fileName,
        fileUrl,
      }));
  };

  static formatPartPartnerTeamOtherInfo = (
    infos: IPartnerTeamOtherInfo,
    locale?: LocaleLang
  ): IPartnerTeamOtherInfo => {
    if (!isObject(infos)) return {} as IPartnerTeamOtherInfo;
    const {
      salesManagerNameCN,
      salesManagerNameEN,
      salesEmployeeNameCN,
      salesEmployeeNameEN,
      fseSiteSurveyNameCN,
      fseSiteSurveyNameEN,
      fseInstallationNameCN,
      fseInstallationNameEN,
      rsmNameCN,
      rsmNameEN,
      ownerRsmNameCN,
      ownerRsmNameEN,
      rsmSiteSurveyCN,
      rsmSiteSurveyEN,
      rsmInstallationCN,
      rsmInstallationEN,
      soNameCN,
      soNameEN,
      apSupervisorNameCN,
      apSupervisorNameEN,
      apEngineerNameEN,
      apEngineerNameCN,
      facUploaderNameEN,
      facUploaderNameCN,
    } = infos;

    const rsm = OrderRequestModel.formatNameByLocale(
        rsmNameCN,
        rsmNameEN,
        locale
      ),
      ownerRsm = OrderRequestModel.formatNameByLocale(
        ownerRsmNameCN,
        ownerRsmNameEN,
        locale
      ),
      rsmSiteSurvey = OrderRequestModel.formatNameByLocale(
        rsmSiteSurveyCN,
        rsmSiteSurveyEN,
        locale
      ),
      rsmInstallation = OrderRequestModel.formatNameByLocale(
        rsmInstallationCN,
        rsmInstallationEN,
        locale
      ),
      salesManager = OrderRequestModel.formatNameByLocale(
        salesManagerNameCN,
        salesManagerNameEN,
        locale
      ),
      salesEmployee = OrderRequestModel.formatNameByLocale(
        salesEmployeeNameCN,
        salesEmployeeNameEN,
        locale
      ),
      fseSiteSurvey = OrderRequestModel.formatNameByLocale(
        fseSiteSurveyNameCN,
        fseSiteSurveyNameEN,
        locale
      ),
      fseInstallation = OrderRequestModel.formatNameByLocale(
        fseInstallationNameCN,
        fseInstallationNameEN,
        locale
      ),
      apSupervisor = OrderRequestModel.formatNameByLocale(
        apSupervisorNameCN,
        apSupervisorNameEN,
        locale
      ),
      apEngineer = OrderRequestModel.formatNameByLocale(
        apEngineerNameCN,
        apEngineerNameEN,
        locale
      ),
      facUploader = OrderRequestModel.formatNameByLocale(
        facUploaderNameCN,
        facUploaderNameEN,
        locale
      ),
      so = OrderRequestModel.formatNameByLocale(soNameCN, soNameEN, locale);

    return {
      ...infos,
      salesManager,
      salesEmployee,
      fseSiteSurvey,
      fseInstallation,
      rsm,
      ownerRsm,
      rsmSiteSurvey,
      rsmInstallation,
      so,
      apSupervisor,
      apEngineer,
      facUploader,
    };
  };

  static formatNameByLocale = (
    nameCN: string,
    nameEN: string,
    locale?: LocaleLang
  ): string => {
    const nameMap = new Map([
      [LocaleLang.EN_US, nameEN],
      [LocaleLang.ZH_CN, nameCN],
    ]);
    return nameMap.get(locale as LocaleLang) ?? nameEN ?? "";
  };

  static downloadFile = (url: string, name?: string): Promise<any> => {
    const weComEnv = isWeComEnv();
    const isMobilePlatform =
      SystemService.getPlatform() === SystemPlatform.ENV_MOBILE;

    if (weComEnv && isMobilePlatform) {
      return Promise.resolve().then(() => downloadByUrl(url, name));
    }

    if (isImageUrl(url)) {
      return convertImageToBase64(url).then((url) => downloadByUrl(url, name));
    }
    if (isPdfUrl(url)) {
      return downloadAttachment(url, name);
    }
    return Promise.resolve().then(() => downloadByUrl(url, name));
  };
}
