import { LoadingSpinner } from "@zeiss/ods-components-react";
import React from "react";
import { debounce } from "throttle-debounce";
import { isNumber } from "../../utils/isType";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import styles from "./Loader.module.scss";

export enum LoaderSize {
  SMALL = "small",
  MEDIUM = "medium",
  large = "large",
}

interface ILoaderProps {
  loading: boolean;
  size?: LoaderSize;
  className?: string;
  delay?: number; // milliseconds
  children?: React.ReactNode;
}

const Loader: React.FC<ILoaderProps> = ErrorDetectorSmallView(
  (props: ILoaderProps) => {
    const { loading, size, className, delay = 0, children } = props;
    const [spinning, setSpinning] = React.useState<boolean>(
      () => loading && !shouldDelay(loading, delay)
    );

    React.useEffect(() => {
      if (!delay) {
        setSpinning(loading);
        return;
      }
      const updateSpinning = debounce(delay, () => {
        setSpinning(loading);
      });
      updateSpinning();
      return () => {
        updateSpinning?.cancel?.();
      };
    }, [delay, loading]);

    const loadingView = React.useMemo(() => {
      const cls = `${styles.loaderView} ${className}`;
      return (
        <div className={cls}>
          <LoadingSpinner size="s" />
        </div>
      );
    }, [loading, className, size]);

    return (
      <section className={styles.loader}>
        {spinning && loadingView}
        {children}
      </section>
    );
  }
);

const shouldDelay = (spinning?: boolean, delay?: number): boolean => {
  return !!spinning && !!delay && isNumber(Number(delay));
};

export default Loader;
