import React from "react";
import {
  ConfirmIconType,
  ConfirmVariantWithIcon,
} from "../../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../../components/ViewTypes";
import { Role, SalesAction } from "../../../../service/userRoleTypes";
import AssignOrForwardUser from "../../../components/AssignOrForwardUser/AssignOrForwardUser";
import { ProductLine } from "../../../model/OrderRequestModel";
import { SubOrders } from "../../components/SubOrders/SubOrders";
import { ButtonMore } from "../InfoSalesModel";
import useInfoSales from "../hooks/useInfoSales";
import styles from "./InfoSalesPc.module.scss";
import InfoSalesPcModel from "./InfoSalesPcModel";
import ContactPersonView from "./components/ContactPersonView/ContactPersonView";
import { FinalGoodsRecipView } from "./components/FinalGoodsRecipView/FinalGoodsRecipView";
import InfoSiteSurveyView from "./components/InfoSiteSurveyView/InfoSiteSurveyView";
import OrderDnInfoView from "./components/OrderDnInfoView/OrderDnInfoView";
import OrderProductInfoView from "./components/OrderProductInfoView/OrderProductInfoView";
import OrderRequestPartnerView from "./components/OrderRequestPartnerView/OrderRequestPartnerView";
import { SoldToPartyView } from "./components/SoldToPartyView/SoldToPartyView";

const InfoSalesPc: React.FC = ErrorDetectorPageView(() => {
  const {
    cancel,
    confirm,
    confirmVisible,
    disclaimer,
    partnerTeamOtherInfo,
    thirdParty,
    stateNeedSiteSurvey,
    expectedStartDate,
    rolePage,
    buttOnEnable,
    forwardVisible,
    preInstallInfo,
    userId,
    buttonBlock,
    reminderRef,
    cancelOrderReminder,
    siteSurveyInfoChangeHandler,
    partnerInfoChangeHandler,
    confirmCloseHandler,
    assignAndForwardHandler,
    setForward,
    backToAllTicket,
    moreActionHandler,
    submitOrderHandler,
    contactPersonChangeHandler,
  } = useInfoSales();

  const buttonViews = React.useMemo(() => {
    const list = InfoSalesPcModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backToAllTicket }],
      [
        SalesAction.FORWARD,
        { onClick: moreActionHandler.bind(null, ButtonMore.FORWARD) },
      ],
      [
        SalesAction.DELETE_ORDER,
        { onClick: moreActionHandler.bind(null, ButtonMore.CANCEL_ORDER) },
      ],
      [SalesAction.SUBMIT, { onClick: submitOrderHandler }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === SalesAction.SUBMIT ? !buttOnEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.buttons}>{views}</div>;
  }, [
    rolePage,
    buttOnEnable,
    backToAllTicket,
    moreActionHandler,
    submitOrderHandler,
  ]);

  const confirmorView = React.useMemo(() => {
    const type =
      reminderRef.current === cancelOrderReminder
        ? ConfirmIconType.WARNING
        : ConfirmIconType.INFO;
    return (
      <ConfirmVariantWithIcon
        visible={confirmVisible}
        onConfirm={confirmCloseHandler}
        title={reminderRef.current}
        type={type}
        buttonTexts={[
          {
            type: "secondary",
            label: cancel,
            closeType: "cancel",
          },
          {
            type: "primary",
            label: confirm,
            closeType: "ok",
            disabled: buttonBlock,
          },
        ]}
      />
    );
  }, [
    reminderRef,
    cancelOrderReminder,
    confirmVisible,
    cancel,
    confirm,
    buttonBlock,
    confirmCloseHandler,
  ]);

  return (
    <div className={styles.infoSalePc}>
      <div className={styles.pageContent}>
        <div className={styles.contentContainer}>
          <SubOrders />
        </div>
        <OrderProductInfoView
          infos={partnerTeamOtherInfo}
          onChange={partnerInfoChangeHandler}
        />
        <OrderRequestPartnerView {...partnerTeamOtherInfo} />
        <OrderDnInfoView {...preInstallInfo?.dnInfo} />
        <ContactPersonView onChange={contactPersonChangeHandler} />
        <FinalGoodsRecipView />
        <SoldToPartyView {...preInstallInfo?.soldParty} />
        <InfoSiteSurveyView
          thirdParty={thirdParty}
          isSiteSurvey={stateNeedSiteSurvey}
          expectedStartDate={expectedStartDate}
          productLine={partnerTeamOtherInfo?.productLine as ProductLine}
          isRsmNeedSiteSurvey={preInstallInfo?.isRsmNeedSiteSurvey}
          disclaimer={disclaimer}
          onChange={siteSurveyInfoChangeHandler}
        />
      </div>
      {buttonViews}
      {confirmorView}
      {forwardVisible && (
        <AssignOrForwardUser
          visible={forwardVisible}
          onClose={(visible: boolean) => setForward(visible)}
          action={Action.FORWARD}
          role={Role.ROLE_SALES}
          feature={Feature.INFO}
          searchQuery={{ salesUserId: userId }}
          actionData={{ selectUserIdKey: "salesRBACCorrelationId" }}
          onActionResponse={assignAndForwardHandler}
        />
      )}
    </div>
  );
});

export default InfoSalesPc;
