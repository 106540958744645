import { RegUtil } from "./RegUtils";
import { isNumber, isObject, isString } from "./isType";

export const getTimeZoneOffsetTimeStamp = (): number => {
  return new Date().getTimezoneOffset() * 60 * 1000;
};

export const downloadByUrl = (url: string, name?: string) => {
  const eleLink: HTMLAnchorElement = document.createElement("a");
  eleLink.download = name || url;
  eleLink.href = url;
  eleLink.style.display = "none";

  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};

export const convertImageToBase64 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.setAttribute("crossorigin", "anonymous");
    img.src = url;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctr = canvas.getContext("2d");
      if (!ctr) return reject();
      ctr.drawImage(img, 0, 0, img.width, img.height);
      resolve(canvas.toDataURL());
    };
    img.onerror = function (error) {
      reject(error);
    };
  });
};

export const downloadAttachment = (url: string, name?: string): Promise<any> =>
  fetch(url)
    .then((res) => {
      if (res.status !== 200) {
        return res.json();
      }
      return res.blob();
    })
    .then((blobRes) => {
      const blob = new Blob([blobRes], {
        type: "application/octet-stream",
        "Content-Disposition": "attachment",
      } as any);
      const url = window.URL.createObjectURL(blob);
      downloadByUrl(url, name);
      URL.revokeObjectURL(url);
    });

export const openLink = (url: string, callback?: (...arg: any[]) => void) => {
  const eleLink: HTMLAnchorElement = document.createElement("a");
  eleLink.target = "_blank";
  eleLink.href = url;
  eleLink.style.display = "none";

  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
  callback && callback();
};

export const convertFileToBase64 = (blob: Blob | File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (event) => {
      const base64 = event?.target?.result;
      resolve(base64);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const safetyInputText = (text: string): string => {
  if (typeof text !== "string") return text;
  return text?.replace(RegUtil.inputTextPattern, "") ?? "";
};

export const copyToClipboard = (textToCopy: string): Promise<void> => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "absolute";
    textArea.style.opacity = "0";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export const queryUrlByKey = (key: string): string | null => {
  const urlObj = new URL(window.location.href);
  return urlObj.searchParams.get(key);
};

export const deepClone = <T>(params: T): T => {
  if (typeof params !== "object") return params;
  if (params === null) return params;
  if (Array.isArray(params)) {
    const newArr = [];
    for (const i of params) {
      if (Array.isArray(params[i])) {
        deepClone(params[i]);
      } else {
        newArr.push(params[i]);
      }
    }

    return newArr as T;
  }

  const newObj: Record<string, any> = {};
  for (const i in params) {
    if (!params.hasOwnProperty(i)) continue;
    if (typeof params[i] === "object") {
      deepClone(params[i]);
    } else {
      newObj[i] = params[i];
    }
  }
  return newObj as T;
};

export const getCssVariable = (
  name: string,
  fallback?: string
): string | undefined => {
  if (!document?.documentElement) return fallback;
  const style = getComputedStyle(document.documentElement);
  return style?.getPropertyValue(name) ?? fallback;
};

/**
 *
 * @param size byte number
 */
export const getFileSize = (size: number): string => {
  if (!isNumber(+size)) return "";
  const KB = size / 1024,
    MB = size / 1024 / 1024;
  if (size < 1024) return `${size}B`;
  if (size / 1024 < 1024) return `${KB}KB`;
  return `${MB}MB`;
};

/**
 *
 * @param timestamp
 * @returns string
 */
export const formatDate = (time: number | Date | string): string => {
  if (!time) return "";
  if (isString(time) && (!isNumber(+time) || +time < 0)) return "";
  if (isNumber(time) && time < 0) return "";
  if (!isNumber(new Date(time).getTime())) return "";
  return new Date(+time).toLocaleDateString("zh-CN").replace(/\//g, "-");
};

export const formatDatePickValue = (time: number): Date | undefined => {
  if (!isNumber(time) || time < 0) return undefined;
  return new Date(time);
};

/**
 *
 * @param key
 * @returns
 */
export const queryCookieByKey = (key: string): string | number | undefined => {
  const { cookie } = document;
  if (!cookie || !isString(key)) return;
  const splittedList = cookie.split(";");
  const found = splittedList.find((item) => item.startsWith(key));
  if (!found) return;
  const index = found.indexOf("=");
  if (!~index) return;
  return found.slice(index + 1);
};

export const isSameOrigin = (url: string) => {
  if (!isString(url)) return false;
  const { origin } = new URL(url);
  const localOrigin = location.origin;

  return localOrigin === origin;
};

export const isImageUrl = (url: string): boolean => {
  if (!isString(url)) return false;
  const suffix = [".png", ".jpg", ".jpeg", ".svg", ".gif"];

  return suffix.some((item) => url.includes(item));
};

export const isPdfUrl = (url: string): boolean => {
  if (!isString(url)) return false;
  const suffix = [".pdf"];

  return suffix.some((item) => url.includes(item));
};

export const queryLikeUrlLastParam = (key: string): string => {
  const url = window.location.href;
  const splitArr = url.split("=");
  splitArr.reverse();
  const findIndex = splitArr.findIndex((item: string) => item.includes(key));
  if (findIndex < 0) return "";
  const result = splitArr[findIndex - 1] ?? "";
  if (!result.includes("#")) return result;
  return result.split("#")[0];
};

/** Confirm wecom env */
export const isWeComEnv = () =>
  navigator?.userAgent?.toLowerCase()?.includes("wxwork");

/** Confirm if object contain the properties */
export const objectHasProperties = (
  instance: Record<string, any> | any,
  properties: (string | number)[]
): boolean => {
  if (!isObject(instance) || !Array.isArray(properties) || !properties.length)
    return false;
  return properties.every((key) => instance.hasOwnProperty(key));
};
