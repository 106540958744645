import classNames from "classnames";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import FoldCabinet from "../../../../../../components/FoldCabinet/FoldCabinet";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import OrderInfoModel from "../../../InfoSalesModel";
import useRequestPartner, {
  OrderRequestPartnerProps,
} from "../../../hooks/useRequestPartner";
import InfoSalesMobileModel from "../../InfoSaleMobileModel";
import styles from "./OrderInfoView.module.scss";

const OrderRequestPartner: React.FC<OrderRequestPartnerProps> =
  ErrorDetectorMediumView((props: OrderRequestPartnerProps) => {
    const { partnerTitle, visible, stateInfo, orderStepSummary, setVisible } =
      useRequestPartner(props);

    const partnerInfoView = React.useMemo(() => {
      const keys = [
        OrderInfoModel.SALE_EMPLOYEE,
        OrderInfoModel.SERVICE_SUPERVISOR,
        OrderInfoModel.RSM_SITE_SURVEY,
        OrderInfoModel.RSM_INSTALLATION,
        OrderInfoModel.FSE_INSTALLATION,
        OrderInfoModel.FSE_SITE_SURVEY,
        OrderInfoModel.SERVICE_COORDINATOR,
      ];
      const viewList = InfoSalesMobileModel.getStepAuthorModels(
        stateInfo,
        orderStepSummary
      ).filter(({ key }) => keys.find((item) => item === key));
      return viewList.map(({ key, props, viewType }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.orderInfoItem,
          }}
        />
      ));
    }, [stateInfo]);

    const partnerMoreDetail = React.useMemo(() => {
      const keys = [
        OrderInfoModel.SALE_MANAGER,
        OrderInfoModel.AP_SUPERVISOR,
        OrderInfoModel.AP_ENGINEER,
      ];
      const viewList = InfoSalesMobileModel.getStepAuthorModels(
        stateInfo,
        orderStepSummary
      ).filter(({ key }) => keys.find((item) => item === key));
      const view = viewList.map(({ key, props, viewType }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.orderInfoItem,
          }}
        />
      ));
      return (
        <FoldCabinet
          status={visible}
          onChange={(visible) => setVisible(visible)}
          cabinetClassName={classNames({ [styles.cabinetCls]: visible })}
        >
          <div className={styles.moreDetailContainer}>{view}</div>
        </FoldCabinet>
      );
    }, [stateInfo, visible, setVisible]);

    return (
      <div className={styles.orderRequestPartner}>
        <div className={styles.title}>{partnerTitle}</div>
        {partnerInfoView}
        {partnerMoreDetail}
      </div>
    );
  });

export default OrderRequestPartner;
