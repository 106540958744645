import React from "react";
import { ConfirmVariantWithIcon } from "../../../../components/Confirmor/ConfirmVariant/ConfirmVariantWithIcon";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { InputVariantDatePicker } from "../../../../components/Input/InputVariantDatePicker/InputVariantDatePicker";
import {
  AppType,
  LabelIndicator,
} from "../../../../components/ViewWrapper/ViewWrapper";
import { formatDate, formatDatePickValue } from "../../../../utils/commonUtils";
import FileUploadVariant from "../../../components/FileUploadVariant/FileUploadVariant";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import SwitchCabinet from "../../../components/SwitchCabinet/SwitchCabinet";
import { IndicatorNew } from "../../../components/Text/IndicatorNew/IndicatorNew";
import TextVariantPc from "../../../components/TextVariantPc/TextVariantPc";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { PageStatus } from "../../../service/pageType";
import { FseAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import SiteSurveyAccessory from "../components/SiteSurveyAccessory/SiteSurveyAccessory";
import SiteSurveyModel from "../model/SiteSurveyModel";
import styles from "./SiteSurveyFSE.module.scss";
import SiteSurveyFseModel from "./SiteSurveyFseModel";
import useSiteSurveyFse from "./useSiteSurveyFse";

const SiteSurveyFsePc: React.FC = ErrorDetectorPageView(() => {
  const {
    submitOrderReminder,
    cancel,
    confirm,
    rejectAssign,
    expectedStartDate,
    actuarySiteSurveyDate,
    rolePage,
    thirdParty,
    labelExpectedDate,
    labelActualSiteSurveyDate,
    siteSurveyDone,
    isSiteSurveyDone,
    siteSurveyReport,
    uploadReminder,
    siteSurveyReports,
    submitEnable,
    rejectVisible,
    submitVisible,
    buttonBlock,
    siteSurveyFseInfo,
    platform,
    orgs,
    confirmCloseHandler,
    rejectHandler,
    changeHandler,
    backToAllTicket,
    setReject,
    setSubmit,
  } = useSiteSurveyFse();

  const contactPcView = React.useMemo(() => {
    const list = SiteSurveyFseModel.getContactInfoModel(
      siteSurveyFseInfo,
      platform
    );

    return (
      <div className={styles.contactPcView}>
        {list.map(({ viewType, key, props }) => {
          const propsMap = new Map([
            [
              InfoSalesModel.FINAL_GOODS_RECIP,
              { ...props, indicatorCustom: <IndicatorNew /> },
            ],
          ]);
          const newProps = propsMap.get(key as string) || props;

          const component = (
            <ViewComponent
              type={viewType as ViewType}
              props={{
                ...newProps,
                key,
                className: styles.pcTextItem,
              }}
            />
          );
          return (
            <div key={key} className={styles.subItem}>
              {component}
            </div>
          );
        })}
      </div>
    );
  }, [siteSurveyFseInfo, platform]);

  const servicePersonView = React.useMemo(() => {
    const list = SiteSurveyFseModel.getOrgInfoModel(
      siteSurveyFseInfo?.partnerTeamOtherInfo,
      orgs,
      platform
    );
    const orgView = list.map(({ viewType, key, props }) => {
      const component = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            key,
            className: styles.subItem,
          }}
        />
      );
      return component;
    });

    return <div className={styles.servicePersonView}>{orgView}</div>;
  }, [siteSurveyFseInfo, orgs, platform]);

  const subView = React.useMemo(() => {
    const expectedLabel = formatDate(expectedStartDate);
    const formatDateParams = formatDatePickValue(actuarySiteSurveyDate);
    const disabled = rolePage?.pageStatus === PageStatus.READONLY;

    return (
      <div className={styles.subView}>
        <SiteSurveyAccessory accessories={thirdParty} />
        <div className={styles.subItemList}>
          <div className={styles.subItem}>
            <TextVariantPc
              label={labelExpectedDate}
              className={styles.expectedDate}
            >
              {expectedLabel}
            </TextVariantPc>
          </div>
          <div className={styles.subItem}>
            <div className={styles.datePicker}>
              <InputVariantDatePicker
                label={labelActualSiteSurveyDate}
                value={formatDateParams}
                disabled={disabled}
                onChange={(value) =>
                  changeHandler(
                    SiteSurveyModel.ACTUAL_SITE_SURVEY_DATE,
                    value?.getTime()
                  )
                }
              />
            </div>
          </div>
          <div className={styles.subItem}>
            <SwitchCabinet
              labelClassName={styles.pcSwitchCabinet}
              cabinetLabel={siteSurveyDone}
              indicator={LabelIndicator.TYPE_REQUIRED}
              viewType={AppType.TYPE_PC}
              cabinetClassName={styles.cabinetCls}
              checked={isSiteSurveyDone ? isSiteSurveyDone : false}
              disabled={disabled}
              onChange={(value) =>
                changeHandler(SiteSurveyModel.IS_SITE_SURVEY_DONE, value)
              }
            />
          </div>
        </div>
      </div>
    );
  }, [
    actuarySiteSurveyDate,
    expectedStartDate,
    isSiteSurveyDone,
    labelActualSiteSurveyDate,
    labelExpectedDate,
    rolePage?.pageStatus,
    siteSurveyDone,
    thirdParty,
    changeHandler,
  ]);

  const siteSurveyViewPc = React.useMemo(() => {
    const disabled = rolePage?.pageStatus === PageStatus.READONLY;

    return (
      <div className={styles.siteSurveyViewPc}>
        {contactPcView}
        {servicePersonView}
        {subView}
        <div className={styles.pcUploadView}>
          <FileUploadVariant
            title={siteSurveyReport}
            reminder={disabled ? "" : uploadReminder}
            maxCount={5}
            onChange={(value) =>
              changeHandler(SiteSurveyModel.SITE_SURVEY_REPORT, value)
            }
            value={siteSurveyReports}
            deletable={!disabled}
          />
        </div>
      </div>
    );
  }, [
    rolePage?.pageStatus,
    contactPcView,
    servicePersonView,
    subView,
    siteSurveyReport,
    uploadReminder,
    siteSurveyReports,
    changeHandler,
  ]);

  const buttonViews = React.useMemo(() => {
    const list = SiteSurveyFseModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<FseAction, Record<string, any>>([
      [FseAction.ALL_TICKET, { onClick: backToAllTicket }],
      [FseAction.REJECT, { onClick: () => setReject(true) }],
      [FseAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            disabled: key === FseAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return <div key={key}>{view}</div>;
    });

    return <div className={styles.pcButtons}>{views}</div>;
  }, [rolePage, backToAllTicket, setReject, setSubmit, submitEnable]);

  return (
    <>
      <div className={styles.siteSurveyRsePc}>
        <div className={styles.pcPageContent}>{siteSurveyViewPc}</div>
        {buttonViews}
      </div>
      <ConfirmVariantWithIcon
        visible={submitVisible}
        onConfirm={confirmCloseHandler}
        title={submitOrderReminder}
        buttonTexts={[
          {
            type: "secondary",
            label: cancel,
            closeType: "cancel",
          },
          {
            type: "primary",
            label: confirm,
            closeType: "ok",
            disabled: buttonBlock,
          },
        ]}
      />
      <RejectModalVariant
        title={rejectAssign}
        visible={rejectVisible}
        onConfirm={rejectHandler}
      />
    </>
  );
});

export default SiteSurveyFsePc;
