import { Icon, ProgressBar } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { getCssVariable } from "../../utils/commonUtils";
import { isFunction, isString } from "../../utils/isType";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import { PDFIcon } from "../PdfViewer/PDFIcon";
import { ExcelIcon } from "../WordViewer/ExcelIcon";
import { WordIcon } from "../WordViewer/WordIcon";
import UnknownFile from "../svg/UnknownFile";
import styles from "./FileUpload.module.scss";
import FileUploadService, {
  FileType,
  FileUploadStatus,
  IFile,
} from "./FileUploadService";

export enum FileAction {
  PREVIEW = "preview",
  DOWNLOAD = "download",
  DELETE = "delete",
}

export interface IFileItemProps extends Partial<HTMLDivElement> {
  file: IFile;
  deletable?: boolean;
  downloadable?: boolean;
  previewAble?: boolean;
  onAction?: (action: FileAction, id: number) => void;
}

export const FileItem: React.FC<IFileItemProps> = ErrorDetectorSmallView(
  (props: IFileItemProps) => {
    const { file, deletable, downloadable, previewAble, onAction, className } =
      props;
    const { file: _file, name, fileType, url, status, id } = file ?? {};
    const _className = classNames(styles.fileItem, {
      [styles.fileItemFail]: status === FileUploadStatus.FAILED,
      className,
    });
    const pendingStatus = status === FileUploadStatus.PENDING;
    const processAnimation = pendingStatus ? "spinning" : false;

    const handler = (action: FileAction) => {
      if (!isFunction(onAction)) return;
      onAction(action, id);
    };

    const _url = React.useMemo(() => {
      if (isString(url)) return url;
      if (!(_file instanceof Blob)) return "";
      const fileUrl = URL.createObjectURL(_file);
      URL.revokeObjectURL(fileUrl);
      return fileUrl;
    }, [_file, url]);

    const fileIcon = React.useMemo(() => {
      const iconMap = new Map([
        [
          FileType.IMAGE,
          <img
            key={FileType.IMAGE}
            src={_url}
            className={styles.fileIconImg}
          />,
        ],
        [FileType.PDF, <PDFIcon key={FileType.PDF} size="auto" />],
        [FileType.WORD, <WordIcon key={FileType.WORD} size="auto" />],
        [FileType.EXCEL, <ExcelIcon key={FileType.EXCEL} size="auto" />],
      ]);

      return (
        iconMap.get(fileType) ?? (
          <UnknownFile key={FileType.OTHER} size="auto" />
        )
      );
    }, [_url, fileType]);

    const actionView = React.useMemo(() => {
      const enablePreviewAndDownload = status === FileUploadStatus.RESOLVED;
      const pending = status === FileUploadStatus.PENDING;
      const fill = getCssVariable("--color-interaction-link");

      const actionList = [
        {
          action: FileAction.PREVIEW,
          enable:
            FileUploadService.getFilePreviewEnable(fileType) &&
            previewAble &&
            enablePreviewAndDownload,
          icon: <Icon icon="Visibility" size={16} fill={fill} />,
        },
        {
          action: FileAction.DOWNLOAD,
          enable: downloadable && enablePreviewAndDownload,
          icon: <Icon icon="Download" size={16} fill={fill} />,
        },
        {
          action: FileAction.DELETE,
          enable: deletable && !pending,
          icon: <Icon icon="Delete" size={16} fill={fill} />,
        },
      ];

      return (
        <div className={styles.actions}>
          {actionList.map(
            ({ action, enable, icon }) =>
              enable && (
                <div
                  className={styles.actionItem}
                  key={action}
                  onClick={handler.bind(null, action)}
                >
                  {icon}
                </div>
              )
          )}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, fileType, previewAble, downloadable, deletable]);

    return (
      <div className={_className}>
        <div className={styles.fileIcon}>{fileIcon}</div>
        <div className={styles.fileContent}>
          <div className={styles.name}>{name}</div>
          {pendingStatus && (
            <div className={styles.progress}>
              <ProgressBar animate={processAnimation} />
            </div>
          )}
        </div>
        {actionView}
      </div>
    );
  }
);

FileItem.defaultProps = {
  deletable: true,
  previewAble: true,
  downloadable: true,
};
