import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "159",
    height = size === "auto" ? "100%" : "122";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 159 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.98"
        d="M114.74 116.588H36.5234L65.5675 54.3545H84.6759L114.74 116.588Z"
        fill="url(#paint0_linear_2451_30629)"
      />
      <path
        opacity="0.98"
        d="M80.0533 117.268H14.4193L5.2373 77.8195L45.706 60.1357L80.0533 117.268Z"
        fill="url(#paint1_linear_2451_30629)"
      />
      <path
        d="M40.9107 63.4006L36.2858 65.305L44.5155 70.6782L39.7885 73.9088L27.7499 78.8399L24.2472 70.2361L4.79502 78.1597L0 66.4613L6.80145 33.8143L11.5285 30.5836L21.0165 27.999L31.9668 54.7628L36.5918 52.8583L40.9107 63.4006ZM19.9283 59.6938L14.249 45.8188L13.841 45.9889L10.8823 62.9245L11.0524 63.3326L19.9283 59.6938Z"
        fill="url(#paint2_linear_2451_30629)"
      />
      <path
        d="M45.6041 60.1693L40.9791 62.0737L44.4819 70.6776L32.4433 75.6086L28.9405 67.0048L9.48839 74.9625L4.69336 63.264L11.4948 30.617L25.7099 24.8018L36.6602 51.5655L41.2852 49.6611L45.6041 60.1693ZM24.6556 56.4965L18.9764 42.6216L18.5683 42.7916L15.6097 59.7272L15.7797 60.1353L24.6556 56.4965Z"
        fill="url(#paint3_linear_2451_30629)"
      />
      <path
        opacity="0.98"
        d="M158.95 117.268L85.4941 121.349L104.538 59.1152L145.687 69.3174L158.95 117.268Z"
        fill="url(#paint4_linear_2451_30629)"
      />
      <path
        d="M146.333 71.7658H141.096L138.613 78.8053H133.24L126.472 74.5543L129.499 65.8825L109.877 59.013L114.026 47.2125L135.11 27.4883H140.483L154.835 32.5214L145.347 59.5571L150.006 61.1895L146.333 71.7658ZM133.24 55.2722L138.137 41.2612L137.729 41.1252L124.432 51.7355L124.296 52.1436L133.24 55.2722Z"
        fill="url(#paint5_linear_2451_30629)"
      />
      <path
        d="M141.096 71.7658L136.301 70.1334L133.274 78.8053L121.134 74.5543L124.16 65.8825L104.538 59.013L108.687 47.2125L135.145 27.4883L149.496 32.5214L140.008 59.5571L144.667 61.1895L141.096 71.7658ZM127.867 55.2722L132.764 41.2612L132.356 41.1252L119.059 51.7355L118.923 52.1436L127.867 55.2722Z"
        fill="url(#paint6_linear_2451_30629)"
      />
      <path
        d="M75.3258 56.4625C72.4352 56.4625 69.8167 56.0884 67.4702 55.3742C65.1237 54.6261 63.1172 53.2998 61.4849 51.3954C59.8525 49.491 58.5943 46.8724 57.7101 43.5737C56.8259 40.275 56.3838 36.0921 56.3838 31.025C56.3838 25.958 56.8259 21.7751 57.7101 18.5104C58.5943 15.2457 59.8525 12.6611 61.4849 10.7567C63.1172 8.8523 65.1237 7.56003 67.4702 6.81187C69.8167 6.06371 72.4352 5.72363 75.3258 5.72363C78.2165 5.72363 80.835 6.09772 83.1815 6.81187C85.528 7.56003 87.5005 8.8523 89.1328 10.7567C90.7311 12.6611 91.9894 15.2457 92.8736 18.5104C93.7578 21.7751 94.1999 25.958 94.1999 31.025C94.1999 36.0921 93.7578 40.275 92.8736 43.5737C91.9894 46.8724 90.7652 49.491 89.1328 51.3954C87.5345 53.2998 85.528 54.6601 83.1815 55.3742C80.835 56.0884 78.2165 56.4625 75.3258 56.4625ZM75.3258 44.934C76.4481 44.934 77.4003 44.73 78.1484 44.3559C78.8966 43.9818 79.4747 43.2677 79.8828 42.2474C80.3249 41.2272 80.631 39.8329 80.801 38.0305C81.0051 36.2282 81.0731 33.9157 81.0731 31.059C81.0731 28.2024 80.9711 25.8899 80.801 24.0536C80.597 22.2172 80.2909 20.7889 79.8828 19.7346C79.4407 18.6804 78.8626 17.9662 78.1484 17.5922C77.4003 17.2181 76.4821 17.0141 75.3258 17.0141C74.2376 17.0141 73.3194 17.2181 72.5713 17.5922C71.8231 17.9662 71.245 18.6804 70.8029 19.7346C70.3608 20.7889 70.0207 22.2172 69.8507 24.0536C69.6466 25.8899 69.5786 28.2024 69.5786 31.059C69.5786 33.9157 69.6806 36.2282 69.8507 38.0305C70.0547 39.8329 70.3608 41.2272 70.8029 42.2474C71.245 43.2677 71.8571 43.9818 72.5713 44.3559C73.3194 44.764 74.2376 44.934 75.3258 44.934Z"
        fill="url(#paint7_linear_2451_30629)"
      />
      <path
        d="M75.3258 51.6334C72.4352 51.6334 69.8167 51.2593 67.4702 50.5451C65.1237 49.797 63.1172 48.4707 61.4849 46.5663C59.8525 44.6619 58.5943 42.0433 57.7101 38.7446C56.8259 35.4459 56.3838 31.263 56.3838 26.1959C56.3838 21.1289 56.8259 16.946 57.7101 13.6813C58.5943 10.4166 59.8525 7.83202 61.4849 5.92761C63.1172 4.02321 65.1237 2.73093 67.4702 1.98277C69.8167 1.23461 72.4352 0.894531 75.3258 0.894531C78.2165 0.894531 80.835 1.26862 83.1815 1.98277C85.528 2.73093 87.5005 4.02321 89.1328 5.92761C90.7311 7.83202 91.9894 10.4166 92.8736 13.6813C93.7578 16.946 94.1999 21.1289 94.1999 26.1959C94.1999 31.263 93.7578 35.4459 92.8736 38.7446C91.9894 42.0433 90.7652 44.6619 89.1328 46.5663C87.5345 48.4707 85.528 49.831 83.1815 50.5451C80.835 51.2593 78.2165 51.6334 75.3258 51.6334ZM75.3258 40.1049C76.4481 40.1049 77.4003 39.9009 78.1484 39.5268C78.8966 39.1527 79.4747 38.4386 79.8828 37.4184C80.3249 36.3981 80.631 35.0038 80.801 33.2014C81.0051 31.3991 81.0731 29.0866 81.0731 26.2299C81.0731 23.3733 80.9711 21.0609 80.801 19.2245C80.597 17.3881 80.2909 15.9598 79.8828 14.9055C79.4407 13.8513 78.8626 13.1372 78.1484 12.7631C77.4003 12.389 76.4821 12.1849 75.3258 12.1849C74.2376 12.1849 73.3194 12.389 72.5713 12.7631C71.8231 13.1372 71.245 13.8513 70.8029 14.9055C70.3608 15.9598 70.0207 17.3881 69.8507 19.2245C69.6466 21.0609 69.5786 23.3733 69.5786 26.2299C69.5786 29.0866 69.6806 31.3991 69.8507 33.2014C70.0547 35.0038 70.3608 36.3981 70.8029 37.4184C71.245 38.4386 71.8571 39.1527 72.5713 39.5268C73.3194 39.9009 74.2376 40.1049 75.3258 40.1049Z"
        fill="url(#paint8_linear_2451_30629)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2451_30629"
          x1="75.6279"
          y1="116.588"
          x2="75.6279"
          y2="54.3747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE2E5" stopOpacity="0" />
          <stop offset="1" stopColor="#D7DFE8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2451_30629"
          x1="42.6416"
          y1="117.268"
          x2="42.6416"
          y2="60.1543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE2E5" stopOpacity="0" />
          <stop offset="1" stopColor="#D7DFE8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2451_30629"
          x1="44.417"
          y1="76.0542"
          x2="10.3596"
          y2="32.9124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7B1C1" />
          <stop offset="1" stopColor="#D7DDE2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2451_30629"
          x1="38.0705"
          y1="77.3263"
          x2="13.9793"
          y2="28.6959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D0E0" />
          <stop offset="1" stopColor="#EFF2F6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2451_30629"
          x1="122.218"
          y1="121.349"
          x2="122.218"
          y2="59.1355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE2E5" stopOpacity="0" />
          <stop offset="1" stopColor="#D7DFE8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2451_30629"
          x1="154.735"
          y1="75.9935"
          x2="120.363"
          y2="32.4289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7B1C1" />
          <stop offset="1" stopColor="#D7DDE2" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2451_30629"
          x1="141.217"
          y1="80.5401"
          x2="118.146"
          y2="29.8717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D0E0" />
          <stop offset="1" stopColor="#EFF2F6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2451_30629"
          x1="94.1162"
          y1="53.6824"
          x2="59.2716"
          y2="16.1107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7B1C1" />
          <stop offset="1" stopColor="#D7DDE2" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2451_30629"
          x1="87.2362"
          y1="53.3487"
          x2="62.0724"
          y2="6.33282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D0E0" />
          <stop offset="1" stopColor="#EFF2F6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
