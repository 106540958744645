import classNames from "classnames";
import type { InputProps, InputRef } from "rc-input";
import RcInput from "rc-input";
import React, { forwardRef } from "react";
import { ErrorDetectorSmallView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import styles from "./SearchPc.module.scss";

export interface SearchPcProps extends InputProps, Partial<InputRef> {
  containerClassName?: string;
  label?: string;
}

export const SearchPc: React.FC<SearchPcProps> = ErrorDetectorSmallView(
  forwardRef<InputRef, SearchPcProps>((props: SearchPcProps, ref) => {
    const { containerClassName, label, ...rest } = props;
    const _containerClassName = classNames(
      styles.container,
      containerClassName
    );

    return (
      <div className={_containerClassName}>
        <RcInput {...rest} ref={ref} />
      </div>
    );
  })
);
