import { useRecoilValue } from "recoil";
import { SystemPlatform } from "../../../core/SystemService";
import { platformState } from "../../../store";
import SearchSelectorMobile from "./SearchSelectorMobile";
import SearchSelectorPc from "./SearchSelectorPc";

export default function (props: any) {
  const platform = useRecoilValue(platformState);
  return platform === SystemPlatform.ENV_PC ? (
    <SearchSelectorPc {...props} />
  ) : (
    <SearchSelectorMobile {...props} />
  );
}
