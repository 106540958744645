import {
  ITableColumn,
  ITableItem,
} from "../../../../../components/Table/Table";
import { LabelIndicator } from "../../../../../components/ViewWrapper/ViewWrapper";
import { SystemPlatform } from "../../../../../core/SystemService";
import { formatDate } from "../../../../../utils/commonUtils";
import { ViewType } from "../../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../../service/pageType";
import { OrderStepSummary } from "../../../../service/roleStateService/RoleStepPageService";
import { SalesAction } from "../../../../service/userRoleTypes";
import OrderRequestModel, {
  IDNInfo,
  IFinalGoodsReceipt,
  IPartnerTeamOtherInfo,
  ViewItemModel,
} from "../../../model/OrderRequestModel";
import { VerifyType } from "../../../service/ValidationService";
import InfoSalesModel, { IContactPerson } from "../InfoSalesModel";

export default class InfoSalesPcModel extends InfoSalesModel {
  static getSaleBasicModifyModels = (
    infos: Partial<IPartnerTeamOtherInfo>,
    pageStatus?: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { incoterms, customerType, region, productLine, saleTeam } =
      infos ?? {};
    const {
      labelIncoterms,
      labelCustomerType,
      labelRegin,
      labelProductLine,
      labelSaleTeam,
    } = InfoSalesModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;

    return [
      {
        key: InfoSalesModel.INCOTERMS,
        viewType: ViewType.VIEW_INPUT_BASIC,
        props: { label: labelIncoterms, value: incoterms, readOnly: true },
      },
      {
        key: InfoSalesModel.INSTALLATION_TASK_ID,
        viewType: ViewType.VIEW_CUSTOMIZED,
      },
      {
        key: InfoSalesModel.CUSTOMER_TYPE,
        viewType: ViewType.VIEW_DROP_DOWN_SELECT,
        props: { label: labelCustomerType, value: customerType, disabled },
      },
      {
        key: InfoSalesModel.SALE_TEAM,
        viewType: ViewType.VIEW_DROP_DOWN_SELECT,
        props: { label: labelSaleTeam, value: saleTeam, disabled },
      },
      {
        key: InfoSalesModel.REGION,
        viewType: ViewType.VIEW_DROP_DOWN_SELECT,
        props: { label: labelRegin, value: region, disabled },
      },
      {
        key: InfoSalesModel.PRODUCT_LINE,
        viewType: ViewType.VIEW_DROP_DOWN_SELECT,
        props: { label: labelProductLine, value: productLine, disabled },
      },
    ];
  };

  static getProductItemModels = (): ITableColumn[] => {
    const { labelMaterialName, labelNumber, labelMaterialNo } =
      InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.ITEM_DESC,
        label: labelMaterialName,
      },
      {
        key: InfoSalesModel.ITEM_MATERIAL,
        label: labelMaterialNo,
      },
      {
        key: InfoSalesModel.ITEM_ORDER_QUANTITY,
        label: labelNumber,
      },
    ];
  };

  static getStepAuthorModels = (
    infos: Partial<IPartnerTeamOtherInfo>,
    orderStepSummary: OrderStepSummary
  ): Partial<ViewItemModel>[] => {
    const {
      salesEmployee,
      salesManager,
      so,
      rsm,
      fseSiteSurvey,
      fseInstallation,
      apSupervisor,
      apEngineer,
      facUploader,
    } = infos ?? {};
    const {
      labelInfoSaleEmp,
      labelInfoSaleManager,
      labelServiceCoo,
      labelOwnerRsm,
      labelApSupervisor,
      labelApEngineer,
      labelFseSiteSurvey,
      labelFseInstallation,
      labelFacUploader,
    } = InfoSalesModel.getLabels();
    const { facRequired, trainingRequired, siteSurveyRequired } =
      orderStepSummary as OrderStepSummary;
    const list = [
      {
        key: InfoSalesModel.SALE_EMPLOYEE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelInfoSaleEmp, children: salesEmployee },
      },
      {
        key: InfoSalesModel.SERVICE_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelOwnerRsm, children: rsm },
      },
      {
        key: InfoSalesModel.FSE_SITE_SURVEY,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelFseSiteSurvey, children: fseSiteSurvey },
        hidden: !siteSurveyRequired,
      },
      {
        key: InfoSalesModel.FSE_INSTALLATION,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelFseInstallation, children: fseInstallation },
      },
      {
        key: InfoSalesModel.SERVICE_COORDINATOR,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelServiceCoo, children: so },
      },
      {
        key: InfoSalesModel.SALE_MANAGER,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelInfoSaleManager, children: salesManager },
      },
      {
        key: InfoSalesModel.FAC_UPLOADER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelFacUploader, children: facUploader },
        hidden: !facRequired || !facUploader,
      },
      {
        key: InfoSalesModel.AP_SUPERVISOR,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelApSupervisor, children: apSupervisor },
        hidden: !trainingRequired,
      },
      {
        key: InfoSalesModel.AP_ENGINEER,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelApEngineer, children: apEngineer },
        hidden: !trainingRequired,
      },
    ];

    return list.filter(({ hidden }) => !hidden);
  };

  static getDNItemModels = (
    infos: IDNInfo,
    dnDelivery?: boolean
  ): Partial<ViewItemModel>[] => {
    const {
      cpoDate,
      serviceContractorOrder,
      installationOrderNo,
      serialNumber,
    } = infos;
    const {
      labelDnDelivery,
      labelDnCpoDate,
      labelDnServiceContactorOrder,
      labelDnInstallOrderNo,
      labelDnSerialNumber,
    } = InfoSalesModel.getLabels();
    const dnDeliveryLabel = OrderRequestModel.getToggleLabel(
      dnDelivery as boolean
    );

    return [
      {
        key: InfoSalesModel.DN_DELIVERY,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelDnDelivery, children: dnDeliveryLabel },
      },
      {
        key: InfoSalesModel.CPO_DATE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelDnCpoDate, children: formatDate(cpoDate) },
      },
      {
        key: InfoSalesModel.INSTALLATION_ORDER_NO,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelDnInstallOrderNo,
          children: installationOrderNo,
        },
      },
      {
        key: InfoSalesModel.SERIAL_NUMBER,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelDnSerialNumber,
          children: serialNumber,
        },
      },
      {
        key: InfoSalesModel.SERVICE_CONTRACTOR_ORDER,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelDnServiceContactorOrder,
          children: serviceContractorOrder,
        },
      },
    ];
  };

  static getDnInstallationAndSerialNumberColumns = (
    label: string
  ): ITableColumn[] => {
    const { labelCompanyName } = InfoSalesModel.getLabels();

    return [
      {
        key: InfoSalesModel.DN_TABLE_INSTALLATION_ORDER_NAME,
        label: labelCompanyName,
      },
      {
        key: InfoSalesModel.DN_TABLE_INSTALLATION_ORDER_NUMBER,
        label: label,
      },
    ];
  };

  static generateDnInstallationAndSerialNumberData = (
    list: string[],
    label: string
  ): ITableItem[] => {
    if (!Array.isArray(list) || !list.length) return [];
    return list.map((item, index) => ({
      name: `${label}${index + 1}`,
      number: item,
    }));
  };

  static getDnItemColumns = (): ITableColumn[] => {
    const { labelMaterialNo, labelNumber, labelMaterialName } =
      InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.ITEM_DESC,
        label: labelMaterialName,
      },
      {
        key: InfoSalesModel.ITEM_MATERIAL,
        label: labelMaterialNo,
      },
      {
        key: InfoSalesModel.ITEM_ORDER_QUANTITY,
        label: labelNumber,
      },
    ];
  };

  static getContactModels = (
    infos: IContactPerson
  ): Partial<ViewItemModel>[] => {
    const { mobile, email, recipCity, recipAddress, name } = infos ?? {};
    const {
      labelCompanyName,
      labelContactMobile,
      labelContactEmailOptional,
      labelRecipeCity,
      labelRecipeAddress,
    } = InfoSalesModel.getLabels();

    return [
      {
        key: InfoSalesModel.CONTACT_NAME,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelCompanyName, children: name },
      },
      {
        key: InfoSalesModel.MOBILE_PHONE,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelContactMobile, children: mobile },
      },
      {
        key: InfoSalesModel.EMAIL,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelContactEmailOptional, children: email },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
    ];
  };

  static getPartnerItem = (
    partnerItem: IFinalGoodsReceipt
  ): Partial<ViewItemModel>[] => {
    const { recipCity, recipAddress, name } = partnerItem ?? {};
    const { labelRecipeCity, labelRecipeAddress, labelCompanyName } =
      this.getLabels();
    return [
      {
        key: InfoSalesModel.COMPANY_NAME,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelCompanyName, children: name },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
    ];
  };

  static getContactModifyModel = (
    infos: IContactPerson
  ): Partial<ViewItemModel>[] => {
    const { name, mobile, email, recipCity, recipAddress } = infos ?? {};
    const {
      labelCompanyName,
      labelContactMobile,
      labelContactEmailOptional,
      labelRecipeCity,
      labelRecipeAddress,
    } = InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.CONTACT_NAME,
        viewType: ViewType.VIEW_INPUT_BASIC,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelCompanyName, value: name, maxLength: 20 },
      },
      {
        key: InfoSalesModel.MOBILE_PHONE,
        viewType: ViewType.VIEW_INPUT_BASIC,
        verifyType: [VerifyType.TYPE_STRING],
        props: {
          label: labelContactMobile,
          value: mobile,
          type: "number",
        },
      },
      {
        key: InfoSalesModel.EMAIL,
        viewType: ViewType.VIEW_INPUT_BASIC,
        verifyType: [VerifyType.TYPE_EMAIL, VerifyType.OPTIONAL],
        props: {
          label: labelContactEmailOptional,
          value: email,
          inputType: VerifyType.TYPE_EMAIL,
          indicator: LabelIndicator.TYPE_OPTIONAL,
        },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: ViewType.VIEW_INPUT_BASIC,
        verifyType: [VerifyType.LENGTH_MAX_COMMON, VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeCity,
          value: recipCity,
          maxLength: 20,
        },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: ViewType.VIEW_INPUT_BASIC,
        verifyType: [VerifyType.LENGTH_MAX_COMMON, VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeAddress,
          value: recipAddress,
          maxLength: 100,
        },
      },
    ];
  };

  static getPartnerModifyItem = (
    partnerItem: IFinalGoodsReceipt,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { name, recipCity, recipAddress } = partnerItem ?? {};
    const { labelRecipeCity, labelRecipeAddress, labelCompanyName } =
      InfoSalesModel.getLabels();
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_INPUT_BASIC
        : ViewType.VIEW_INPUT;

    return [
      {
        key: InfoSalesModel.COMPANY_NAME,
        viewType,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelCompanyName, value: name, maxLength: 50 },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType,
        verifyType: [VerifyType.TYPE_STRING],
        props: { label: labelRecipeCity, value: recipCity, maxLength: 100 },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType,
        verifyType: [VerifyType.TYPE_STRING],
        props: {
          label: labelRecipeAddress,
          value: recipAddress,
          maxLength: 100,
        },
      },
    ];
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelForward, labelCancelOrder, labelSubmit } =
      InfoSalesModel.getLabels();

    const original = [
      {
        key: SalesAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SalesAction.ALL_TICKET,
        },
      },
      {
        key: SalesAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelForward,
          key: SalesAction.FORWARD,
        },
      },
      {
        key: SalesAction.DELETE_ORDER,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelCancelOrder,
          key: SalesAction.DELETE_ORDER,
        },
      },
      {
        key: SalesAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: SalesAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
