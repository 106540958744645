import React, { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { stepState, userState } from "../../../store";
import { isNumber } from "../../../utils/isType";
import useCurrentUser from "../../hooks/useCurrentUser";
import useRolePage from "../../hooks/useRolePage";
import { OrderStep } from "../../service/OrderStepTypes";
import { PageStatus, PageSubItems } from "../../service/pageType";
import { Role } from "../../service/userRoleTypes";
import StepPageModel from "../model/StepPageModel";
import InstallationAll from "./InstallationAll/InstallationAll";
import InstallationApEngineer from "./InstallationApEngineer/InstallationApEngineer";
import InstallationApSupervisor from "./InstallationApSupervisor/InstallationApSupervisor";
import InstallationFse from "./InstallationFse/InstallationFse";
import InstallationRsm from "./InstallationRsm/InstallationRsm";
import InstallationSales from "./InstallationSales/InstallationSales";
import InstallationSo from "./InstallationSo/InstallationSo";

export default function () {
  const { usersRole } = useRecoilValue(userState);
  const { key } = useRecoilValue(stepState);
  const stepNode = StepPageModel.getStepPageByTab(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_SITE_SURVEY,
    key,
    usersRole
  );
  const isCurrentUser = useCurrentUser();
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_INSTALLATION
  );

  return React.useMemo(() => {
    const defaultViewMap = new Map<Role, React.ReactNode>([
      [
        Role.ROLE_SALES_MANAGER,
        <InstallationSales key={Role.ROLE_SALES_MANAGER} />,
      ],
      [Role.ROLE_SALES, <InstallationSales key={Role.ROLE_SALES} />],
      [Role.ROLE_SO, <InstallationSo key={Role.ROLE_SO} />],
      [Role.ROLE_RSM_MANAGER, <InstallationRsm key={Role.ROLE_RSM_MANAGER} />],
      [Role.ROLE_RSM, <InstallationRsm key={Role.ROLE_RSM} />],
      [Role.ROLE_FSE, <InstallationFse key={Role.ROLE_FSE} />],
      [
        Role.ROLE_AP_MANAGER,
        <InstallationApSupervisor key={Role.ROLE_AP_MANAGER} />,
      ],
      [
        Role.ROLE_APPLY_SUPERVISOR,
        <InstallationApSupervisor key={Role.ROLE_APPLY_SUPERVISOR} />,
      ],
      [
        Role.ROLE_APPLY_ENGINEER,
        <InstallationApEngineer key={Role.ROLE_APPLY_ENGINEER} />,
      ],
    ]);

    const installationImplementMap = new Map<Role, React.ReactNode>([
      [
        Role.ROLE_SALES_MANAGER,
        <InstallationSales key={Role.ROLE_SALES_MANAGER} />,
      ],
      [Role.ROLE_SALES, <InstallationSales key={Role.ROLE_SALES} />],
      [Role.ROLE_SO, <InstallationSo key={Role.ROLE_SO} />],
      [Role.ROLE_RSM_MANAGER, <InstallationFse key={Role.ROLE_RSM_MANAGER} />],
      [Role.ROLE_RSM, <InstallationFse key={Role.ROLE_RSM} />],
      [Role.ROLE_FSE, <InstallationFse key={Role.ROLE_FSE} />],
      [
        Role.ROLE_AP_MANAGER,
        <InstallationApSupervisor key={Role.ROLE_AP_MANAGER} />,
      ],
      [
        Role.ROLE_APPLY_SUPERVISOR,
        <InstallationApSupervisor key={Role.ROLE_APPLY_SUPERVISOR} />,
      ],
      [
        Role.ROLE_APPLY_ENGINEER,
        <InstallationApEngineer key={Role.ROLE_APPLY_ENGINEER} />,
      ],
    ]);

    const trainingImplementMap = new Map<Role, React.ReactNode>([
      [
        Role.ROLE_SALES_MANAGER,
        <InstallationSales key={Role.ROLE_SALES_MANAGER} />,
      ],
      [Role.ROLE_SALES, <InstallationSales key={Role.ROLE_SALES} />],
      [Role.ROLE_SO, <InstallationSo key={Role.ROLE_SO} />],
      [Role.ROLE_RSM_MANAGER, <InstallationRsm key={Role.ROLE_RSM_MANAGER} />],
      [Role.ROLE_RSM, <InstallationRsm key={Role.ROLE_RSM} />],
      [Role.ROLE_FSE, <InstallationFse key={Role.ROLE_FSE} />],
      [
        Role.ROLE_AP_MANAGER,
        <InstallationApEngineer key={Role.ROLE_AP_MANAGER} />,
      ],
      [
        Role.ROLE_APPLY_SUPERVISOR,
        <InstallationApEngineer key={Role.ROLE_APPLY_SUPERVISOR} />,
      ],
      [
        Role.ROLE_APPLY_ENGINEER,
        <InstallationApEngineer key={Role.ROLE_APPLY_ENGINEER} />,
      ],
    ]);

    const stepMaps = new Map<OrderStep, Map<Role, React.ReactNode>>([
      [OrderStep.INSTALL, installationImplementMap],
      [OrderStep.TRAINING_IMPLEMENT, trainingImplementMap],
    ]);

    if (!isNumber(key)) return <></>;
    const viewMap = isCurrentUser
      ? stepMaps.get(key) ?? defaultViewMap
      : defaultViewMap;

    if (pageStatus === PageStatus.READONLY) return <InstallationAll />;

    return (viewMap.get(usersRole) ?? stepNode) as ReactElement;
  }, [key, isCurrentUser, pageStatus, usersRole, stepNode]);
}
