import React from "react";
import Confirmor from "../../../../../components/Confirmor/Confirmor";
import { ErrorDetectorMediumView } from "../../../../../components/ErrorDecorator/ErrorDecorator";
import { Action, Feature } from "../../../../../services/user.service";
import { ViewComponent, ViewType } from "../../../../components/ViewTypes";
import { Role, SalesAction } from "../../../../service/userRoleTypes";
import AssignOrForwardUser from "../../../components/AssignOrForwardUser/AssignOrForwardUser";
import CommentsView from "../../../components/CommentView/CommentsView";
import { ProductLine } from "../../../model/OrderRequestModel";
import { SubOrders } from "../../components/SubOrders/SubOrders";
import useInfoSales from "../hooks/useInfoSales";
import InfoSalesMobileModel from "./InfoSaleMobileModel";
import styles from "./InfoSalesMobile.module.scss";
import {
  ContactPerson,
  FinalGoodsReceipt,
  SoldToParty,
} from "./components/ContactInfo/ContactInfo";
import OrderInfoDnView from "./components/OrderInfoDnView/OrderInfoDnView";
import OrderInfoSiteSurvey from "./components/OrderInfoSiteSurvey/OrderInfoSiteSurvey";
import OrderInfoView from "./components/OrderInfoView/OrderInfoView";

const InfoSalesMobile: React.FC = ErrorDetectorMediumView(() => {
  const {
    reminder,
    cancel,
    confirm,
    confirmVisible,
    disclaimer,
    partnerTeamOtherInfo,
    thirdParty,
    stateNeedSiteSurvey,
    expectedStartDate,
    rolePage,
    buttOnEnable,
    forwardVisible,
    preInstallInfo,
    userId,
    buttonBlock,
    siteSurveyInfoChangeHandler,
    partnerInfoChangeHandler,
    confirmCloseHandler,
    assignAndForwardHandler,
    setForward,
    backToAllTicket,
    moreActionHandler,
    submitOrderHandler,
    contactPersonChangeHandler,
  } = useInfoSales();

  const buttonViews = React.useMemo(() => {
    const list = InfoSalesMobileModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<SalesAction, Record<string, any>>([
      [SalesAction.ALL_TICKET, { onClick: backToAllTicket }],
      [SalesAction.FORWARD, { onSelect: moreActionHandler }],
      [SalesAction.SUBMIT, { onClick: submitOrderHandler }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === SalesAction.SUBMIT ? !buttOnEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
  }, [
    rolePage,
    buttOnEnable,
    backToAllTicket,
    moreActionHandler,
    submitOrderHandler,
  ]);

  const confirmorView = React.useMemo(
    () => (
      <Confirmor
        visible={confirmVisible}
        onConfirm={confirmCloseHandler}
        content={<div className={styles.reminder}>{reminder}</div>}
        buttonTexts={[
          {
            type: "secondary",
            label: cancel,
            closeType: "cancel",
          },
          {
            type: "primary",
            label: confirm,
            closeType: "ok",
            disabled: buttonBlock,
          },
        ]}
      />
    ),
    [
      confirmVisible,
      confirmCloseHandler,
      reminder,
      cancel,
      confirm,
      buttonBlock,
    ]
  );

  return (
    <div className={styles.orderInfo}>
      <div className={styles.pageContainer}>
        <SubOrders />
        <OrderInfoView
          infos={partnerTeamOtherInfo}
          onChange={partnerInfoChangeHandler}
        />
        <OrderInfoDnView {...preInstallInfo?.dnInfo} />
        <ContactPerson onChange={contactPersonChangeHandler} />
        <FinalGoodsReceipt />
        <SoldToParty {...preInstallInfo?.soldParty} />
        <OrderInfoSiteSurvey
          thirdParty={thirdParty}
          isSiteSurvey={stateNeedSiteSurvey}
          expectedStartDate={expectedStartDate}
          productLine={partnerTeamOtherInfo?.productLine as ProductLine}
          isRsmNeedSiteSurvey={preInstallInfo?.isRsmNeedSiteSurvey}
          disclaimer={disclaimer}
          onChange={siteSurveyInfoChangeHandler}
        />
        <div className={styles.commentView}>
          <CommentsView />
        </div>
      </div>
      {buttonViews}
      {confirmorView}
      {forwardVisible && (
        <AssignOrForwardUser
          visible={forwardVisible}
          onClose={(visible: boolean) => setForward(visible)}
          action={Action.FORWARD}
          role={Role.ROLE_SALES}
          feature={Feature.INFO}
          searchQuery={{ salesUserId: userId }}
          actionData={{ selectUserIdKey: "salesRBACCorrelationId" }}
          onActionResponse={assignAndForwardHandler}
        />
      )}
    </div>
  );
});

export default InfoSalesMobile;
