import classNames from "classnames";
import { ErrorDetectorSmallView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import useLang from "../../../hooks/useLang";
import styles from "./IndicatorNew.module.scss";

export interface IndicatorNewProps {
  label?: string;
  className?: string;
}

export const IndicatorNew: React.FC<IndicatorNewProps> = ErrorDetectorSmallView(
  (props: IndicatorNewProps) => {
    const { label, className } = props;
    const _className = classNames(styles.indicatorNew, className);
    const { defaultLabel } = useLang({ defaultLabel: { id: "common_new" } });
    const _label = label || defaultLabel;

    return <div className={_className}>{_label}</div>;
  }
);
