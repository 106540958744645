import classNames from "classnames";
import React from "react";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorMediumView } from "../ErrorDecorator/ErrorDecorator";
import FileUpload from "../svg/FileUpload";
import styles from "./FileUpload.module.scss";
export interface IUploadItemProps {
  className?: string;
  disabled?: boolean;
  node?: React.ReactNode;
  accept?: string;
  reminder?: string;
  beforeUpload?: (file: File) => boolean;
  onUpload: (file: File) => void;
}

export const UploadItem: React.FC<IUploadItemProps> = ErrorDetectorMediumView(
  (props: IUploadItemProps) => {
    const {
      className,
      disabled,
      node,
      accept,
      reminder,
      beforeUpload,
      onUpload,
    } = props;

    const _uploadItem = classNames(styles.uploadItemContainer, {
      [styles.disabled]: disabled,
    });

    const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = evt?.currentTarget ?? {};
      const _files = Array.prototype.slice.call(files);
      evt.target.value = "";
      if (!Array.isArray(_files) || !_files.length) return;
      const file = _files[0];
      if (isFunction(beforeUpload)) {
        const result = beforeUpload(file);
        result && onUpload(file);
        return;
      }

      if (isFunction(onUpload)) {
        onUpload(file);
      }
    };

    const view = React.useMemo(() => {
      const _className = classNames(
        styles.uploadItem,
        { [styles.disable]: disabled },
        className
      );

      return node ? (
        node
      ) : (
        <div className={_className}>
          <FileUpload />
          {reminder && <div className={styles.reminder}>{reminder}</div>}
        </div>
      );
    }, [disabled, className, node, reminder]);

    return (
      <div className={_uploadItem}>
        {view}
        {!disabled && (
          <input
            className={styles.uploadInput}
            accept={accept}
            onChange={changeHandler}
            type="file"
          />
        )}
      </div>
    );
  }
);
