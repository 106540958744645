import React from "react";
import { useRecoilValue } from "recoil";
import { ErrorCode } from "../../../../core/ErrorCodeService";
import { IOrderBasicInfo } from "../../../../services/order.service";
import {
  isFinalGoodsRecipChangeState,
  orderTaskIdState,
  platformState,
  userState,
} from "../../../../store";
import { useErrorMsg } from "../../../hooks/useErrorMsg";
import { OrderStep } from "../../../service/OrderStepTypes";
import { disposeOrderStepSummary } from "../../../service/roleStateService/RoleStepPageService";
import OrderRequestModel from "../../model/OrderRequestModel";
import OrderHeaderModel from "./OrderHeaderModel";

export interface OrderHeaderProps extends IOrderBasicInfo {}

export default function (props: OrderHeaderProps) {
  const installationId = useRecoilValue(orderTaskIdState);
  const userInfo = useRecoilValue(userState);
  const [visible, setVisible] = React.useState(false);
  const [infos, setInfos] = React.useState<OrderHeaderProps>(
    {} as OrderHeaderProps
  );
  const isFinalGoodsRecipChanged = useRecoilValue(isFinalGoodsRecipChangeState);
  const [errorCode, setError] = React.useState<ErrorCode>();
  const toastError = useErrorMsg(errorCode as ErrorCode);
  const platform = useRecoilValue(platformState);
  const list = OrderHeaderModel.getModelList(infos);
  const { key } = infos?.orderStepSummary ?? {};
  const isUnSplitOrder = key === OrderStep.SPLIT_ORDER;
  const _list = isUnSplitOrder
    ? list.filter(({ key }) => key !== OrderHeaderModel.STEP)
    : list;

  React.useEffect(() => {
    setInfos(props);
  }, [props]);

  React.useEffect(() => {
    if (isFinalGoodsRecipChanged && installationId) {
      OrderRequestModel.getOrderBasicInfo(installationId)
        .then((res) =>
          setInfos({
            ...res?.data,
            orderStepSummary: disposeOrderStepSummary(
              res?.data?.orderStepSummary,
              userInfo
            ),
          })
        )
        .catch((res) => {
          setError(res?.errCode);
          toastError();
        });
    }
  }, [isFinalGoodsRecipChanged, installationId, userInfo, toastError]);

  const changeHandler = React.useCallback(
    (visible: boolean) => setVisible(visible),
    []
  );

  return {
    visible,
    _list,
    key,
    infos,
    platform,
    changeHandler,
  };
}
