import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import { PDFIcon } from "./PDFIcon";
import styles from "./PdfView.module.scss";

export interface IFileThumb {
  className?: string;
  thumb?: React.ReactElement;
  onClick?: () => void;
}

const FileThumb: React.FC<IFileThumb> = ErrorDetectorSmallView(
  (props: IFileThumb) => {
    const { className, thumb, onClick } = props;
    const thumbView = thumb ?? <PDFIcon />;

    return (
      <div className={classNames(styles.fileThumb, className)}>
        <div className={styles.thumb} onClick={onClick}>
          {thumbView}
        </div>
      </div>
    );
  }
);

export default FileThumb;
