import classNames from "classnames";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import React from "react";
import { DocumentProps, PageProps } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../Modal/Modal";
import { PreviewAndDownload } from "../Upload/PreviewAndDownloadModal";
import { FileType } from "../Upload/Upload";
import FileThumb, { IFileThumb } from "./FileThumb";
import styles from "./PdfView.module.scss";

interface IPdfViewer extends IModal {
  pdfDocument?: Omit<DocumentProps, "file">;
  pdfPage?: PageProps;
  file: URL | File | Blob;
  fileType?: FileType;
  fileThumb?: IFileThumb;
  actionType?: PreviewAndDownload;
  previewIndex?: number;
  activeIndex?: number;
  onThumbClick?: (enablePreview?: boolean) => void;
  onError?: (error: any) => void;
}

const loadFailMsg = "Failed to load PDF file",
  loadPending = "Loading PDF…";

const PdfViewer = ErrorDetectorModalView((props: IPdfViewer) => {
  const {
    pdfDocument,
    pdfPage,
    file,
    fileThumb,
    fileType,
    actionType,
    previewIndex,
    activeIndex,
    onClose,
    onThumbClick,
    onError,
    ...rest
  } = props;
  const [numPages, setNum] = React.useState<number>(0);
  const { loading, error } = pdfDocument ?? {};
  const [visible, setVisible] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(
    () => document?.documentElement?.clientWidth
  );

  // dose not work
  // React.useLayoutEffect(() => {
  //   window.addEventListener("resize", resizePdfPage, false);
  //   return window.removeEventListener("resize", resizePdfPage, false);
  // }, []);

  // const resizePdfPage = () => {
  //   setPageSize(document?.documentElement?.clientWidth);
  // };

  React.useEffect(
    () =>
      setVisible(
        PreviewAndDownload.PREVIEW === actionType &&
          previewIndex === activeIndex
      ),
    [actionType, previewIndex, activeIndex]
  );

  const loadPdfSucHandler = React.useCallback((pdf: PDFDocumentProxy) => {
    const { numPages } = pdf;
    setNum(numPages);
  }, []);

  const closeHandler = React.useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      setVisible(false);
      isFunction(onClose) && onClose(e);
    },
    []
  );

  const clickHandler = React.useCallback(() => {
    isFunction(onThumbClick) && onThumbClick();
  }, [actionType]);

  const errorHandler = React.useCallback((error: any) => {
    isFunction(onError) && onError(error);
  }, []);

  const errorView = React.useMemo(() => {
    if (error) return error;
    return loadFailMsg;
  }, [error]);

  const loadingView = React.useMemo(() => {
    if (loading) return loading;
    return loadPending;
  }, [loading]);

  const docCls = classNames(styles.document, pdfDocument?.className);

  return (
    <>
      <FileThumb {...fileThumb} onClick={clickHandler} />
      <Modal
        {...rest}
        visible={visible}
        onClose={closeHandler}
        destroyOnClose={true}
        className={styles.pdfModal}
      >
        <div className={styles.pdfContainer} onClick={closeHandler}>
          <Document
            {...pdfDocument}
            className={docCls}
            file={file}
            onLoadSuccess={loadPdfSucHandler}
            onLoadError={errorHandler}
            error={errorView}
            loading={loadingView}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageSize * 0.8}
              />
            ))}
          </Document>
        </div>
      </Modal>
    </>
  );
});

export default PdfViewer;
