import isBrowser from "../../utils/isBrowser";

export let supportsPassive = false;

if (isBrowser) {
  try {
    const opts = {};
    Object.defineProperty(opts, "passive", {
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener("test-passive", null as any, opts);
  } catch (e) {}
}
