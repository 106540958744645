import PageWrapper from "../../../../layout/PageWrapper";
import ModifyContactMobile from "./ModifyContactMobile";
import ModifyContactPc from "./ModifyContactPc";
import { ModifyContactProps } from "./useModifyContact";

export default (props: ModifyContactProps) => (
  <PageWrapper
    PagePc={() => <ModifyContactPc {...props} />}
    PageMobile={() => <ModifyContactMobile {...props} />}
  />
);
