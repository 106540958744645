import classNames from "classnames";
import { renderAsync } from "docx-preview";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import React, { LegacyRef } from "react";
import { DocumentProps } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { RegUtil } from "../../utils/RegUtils";
import { isFunction, isString } from "../../utils/isType";
import styles from "./FileUpload.module.scss";
import { FileUploadCode } from "./FileUploadService";

export interface IPreviewImg extends Partial<HTMLImageElement> {
  onPreview?: (result: Promise<any>) => void;
}
export const PreviewImg: React.FC<IPreviewImg> = (props: IPreviewImg) => {
  const { className, src, onPreview } = props;
  const _className = classNames(styles.previewImg, className);
  const loadHandler = (status?: FileUploadCode | undefined) => {
    if (!isFunction(onPreview)) return;
    const result =
      status === FileUploadCode.PREVIEW_FAILED
        ? Promise.reject(FileUploadCode.PREVIEW_FAILED)
        : Promise.resolve();
    onPreview(result);
  };

  return (
    <img
      onLoad={() => loadHandler()}
      src={src}
      className={_className}
      onError={loadHandler.bind(null, FileUploadCode.PREVIEW_FAILED)}
    />
  );
};

export interface IPreviewPdf extends DocumentProps {
  onPreview?: (result: Promise<any>) => void;
}
export const PreviewPdf: React.FC<IPreviewPdf> = (props: IPreviewPdf) => {
  const { file, className, loading, error, onPreview, ...rest } = props;
  const _className = classNames(styles.previewPdf, className);

  const [numPages, setNum] = React.useState<number>(0);

  const loadHandler = React.useCallback(
    (status?: FileUploadCode | undefined) => {
      if (!isFunction(onPreview)) return;
      const result =
        status === FileUploadCode.PREVIEW_FAILED
          ? Promise.reject(FileUploadCode.PREVIEW_FAILED)
          : Promise.resolve();
      onPreview(result);
    },
    [onPreview]
  );

  const loadPdfSucHandler = React.useCallback(
    (pdf: PDFDocumentProxy) => {
      const { numPages } = pdf;
      setNum(numPages);
      loadHandler();
    },
    [loadHandler]
  );

  return (
    <Document
      {...rest}
      className={_className}
      file={file}
      onLoadSuccess={loadPdfSucHandler}
      onLoadError={loadHandler.bind(null, FileUploadCode.PREVIEW_FAILED)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={698}
          height={420}
        />
      ))}
    </Document>
  );
};

export interface IPreviewWord {
  file?: File | Blob;
  url?: string;
  onPreview?: (result: Promise<any>) => void;
}
export const PreviewWord: React.FC<IPreviewWord> = (props: IPreviewWord) => {
  const { file, url, onPreview } = props;
  const [blob, setBlob] = React.useState(() => file);
  const containerRef: LegacyRef<HTMLDivElement> = React.useRef(null);
  const loadHandler = (status?: FileUploadCode | undefined) => {
    if (!isFunction(onPreview)) return;
    const result =
      status === FileUploadCode.PREVIEW_FAILED
        ? Promise.reject(FileUploadCode.PREVIEW_FAILED)
        : Promise.resolve();
    onPreview(result);
  };

  const _fetch = (url: string) => {
    if (!RegUtil.verifyWebAddr(url)) return;
    fetch(url)
      .then((response) => response.blob())
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        setBlob(blob);
        isFunction(loadHandler) && loadHandler(FileUploadCode.PREVIEW_SUC);
      })
      .catch((error) => {
        console.log(`Error: ${error?.message}`);
        isFunction(loadHandler) && loadHandler(FileUploadCode.PREVIEW_FAILED);
      });
  };

  React.useEffect(() => {
    if (isString(url)) {
      _fetch(url as string);
      return;
    }
    if (file instanceof Blob) {
      setBlob(blob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, url]);

  React.useEffect(() => {
    if (!(blob instanceof Blob) || !containerRef.current) return;
    renderAsync(blob, containerRef.current, undefined, {
      ignoreWidth: true,
      inWrapper: false,
      className: styles.containerWord,
    }).catch(loadHandler.bind(null, FileUploadCode.PREVIEW_FAILED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  return <div ref={containerRef} />;
};
