import React, { FC } from "react";
import { ErrorDetectorSmallView } from "../ErrorDecorator/ErrorDecorator";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import styles from "./AutoCenter.module.scss";

export type AutoCenterProps = { children?: React.ReactNode } & NativeProps;

// Text is automatically centered.
// The content is not enough for the width of the entire line, or it is used after reaching the full width.
const AutoCenter: FC<AutoCenterProps> = ErrorDetectorSmallView(
  (props: AutoCenterProps) => {
    return withNativeProps(
      props,
      <div className={styles.center}>
        <div className={styles.centerContent}>{props.children}</div>
      </div>
    );
  }
);

export default AutoCenter;
