import HttpService from "../../../../core/HttpService";
import { formatMessage } from "../../../../core/LangService";
import {
  IOrderCancelRes,
  IOrderContactRes,
  IOrderContactUpdateReq,
  IOrderContactUpdateRes,
  IOrderFinalGoodRecipAddReq,
  IOrderFinalGoodRecipAddRes,
  IOrderFinalGoodRecipRes,
  IOrderSoldToPartyRes,
  IRemoveFileRes,
  IUploadFileRes,
  StaticSourceItem,
  addFinalGoodsRecip,
  getContactPerson,
  getFinalGoodsRecip,
  getSoldToParty,
  orderCancel,
  removeFile,
  updateContactPerson,
  uploadFile,
} from "../../../../services/order.service";
import {
  IFinalGoodsReceipt,
  IPartnerTeamOtherInfo,
  IPrepareInstallation,
} from "../../model/OrderRequestModel";
import ValidationService from "../../service/ValidationService";

export interface IContactPerson {
  name: string;
  mobile: string;
  email: string;
  recipCity: string;
  recipAddress: string;
}

export interface IOrderInfoPartner {
  saleEmployee: string;
  saleManager: string;
  serviceCoordinator: string;
  fse: string;
  apSupervisor: string;
  apEngineer: string;
  serviceSupervisor: string | number;
}

export enum FinalGoodsRecipStatus {
  ADD = "finalGoodsRecipAdd",
  UPDATE = "finalGoodsRecipUpdate",
}

export enum ContactPersonStatus {
  UPDATE = "contactPersonUpdate",
}

export enum ButtonMore {
  FORWARD = "forward",
  CANCEL_ORDER = "cancel",
}
export default class InfoSalesModel {
  static readonly SALE_EMPLOYEE = "salesEmployee";
  static readonly SALE_MANAGER = "salesManager";
  static readonly SERVICE_COORDINATOR = "so";
  static readonly FSE_SITE_SURVEY = "fseSiteSurvey";
  static readonly FSE_INSTALLATION = "fseInstallation";
  static readonly SERVICE_SUPERVISOR = "rsm";
  static readonly AP_SUPERVISOR = "apSupervisor";
  static readonly AP_ENGINEER = "apEngineer";
  static readonly INCOTERMS = "incoterms";
  static readonly SALE_TEAM = "saleTeam";
  static readonly CUSTOMER_TYPE = "customerType";
  static readonly REGION = "region";
  static readonly PRODUCT_LINE = "productLine";
  static readonly IS_SITE_SURVEY = "isSiteSurvey";
  static readonly EXPECTED_START_DATE = "expectedStartDate";
  static readonly DISCLAIMER = "disclaimer";
  static readonly THIRD_PARTY = "thirdParty";
  static readonly PARTNER_TEAM_OTHER_INFO = "partnerTeamOtherInfo";
  static readonly INSTALLATION_TASK_ID = "installationTaskId";
  static readonly OWNER_RSM = "ownerRsm";
  static readonly RSM_SITE_SURVEY = "rsmSiteSurvey";
  static readonly RSM_INSTALLATION = "rsmInstallation";
  static readonly FINAL_GOODS_RECIP = "finalGoodsRecip";

  static readonly PARTNER = "partner";
  static readonly COMPANY_NAME = "name";
  static readonly CONTACT_NAME = "name";
  static readonly EMAIL = "email";
  static readonly MOBILE_PHONE = "mobile";
  static readonly RECIPE_CITY = "recipCity";
  static readonly RECIPE_ADDRESS = "recipAddress";
  static readonly POST_CODE = "postCode";
  static readonly CPO_DATE = "cpoDate";
  static readonly DN_DELIVERY = "dnDelivery";
  static readonly INSTALLATION_ORDER_NO = "installationOrder";
  static readonly SERIAL_NUMBER = "serialNumber";
  static readonly SERVICE_CONTRACTOR_ORDER = "serviceContractorOrder";
  static readonly PART_NAME = "partName";
  static readonly PART_SN = "partSn";
  static readonly PART_QUANTIFY = "partQuantify";
  static readonly AFTER_SALE_NAME = "afterSaleName";
  static readonly AFTER_SALE_NUMBER = "afterSaleNumber";
  static readonly AFTER_SALE_QUANTITY = "afterSaleQuantity";
  static readonly ITEM_DESC = "itemDescription";
  static readonly ITEM_MATERIAL = "itemMaterial";
  static readonly ITEM_ORDER_QUANTITY = "itemOrderQuantity";
  static readonly ITEM_ORDER = "itemOrder";
  static readonly OPERATION = "operation";
  static readonly FAC_UPLOADER = "facUploader";
  static readonly SUB_STANDARD_ORDER = "subsStandardOrder";
  static readonly DN_TABLE_INSTALLATION_ORDER_NAME = "name";
  static readonly DN_TABLE_INSTALLATION_ORDER_NUMBER = "number";

  static uploadFile = (data: File): Promise<IUploadFileRes> => {
    return HttpService.splitResponse(uploadFile(data));
  };

  static removeFile = (fileId: number | string): Promise<IRemoveFileRes> => {
    return HttpService.splitResponse(removeFile(fileId));
  };

  static getContactPerson = (id: string): Promise<IOrderContactRes> => {
    return HttpService.splitResponse(getContactPerson(id));
  };

  static getFinalGoodsRecip = (
    id: string
  ): Promise<IOrderFinalGoodRecipRes> => {
    return HttpService.splitResponse(getFinalGoodsRecip(id));
  };

  static getSoldToParty = (id: string): Promise<IOrderSoldToPartyRes> => {
    return HttpService.splitResponse(getSoldToParty(id));
  };

  static cancelOrder = (id: string): Promise<IOrderCancelRes> => {
    return HttpService.splitResponse(orderCancel(id));
  };

  static updateContactPerson = (
    data: IOrderContactUpdateReq
  ): Promise<IOrderContactUpdateRes> => {
    return HttpService.splitResponse(updateContactPerson(data));
  };

  static addFinalGoodsRecip = (
    data: IOrderFinalGoodRecipAddReq
  ): Promise<IOrderFinalGoodRecipAddRes> => {
    return HttpService.splitResponse(addFinalGoodsRecip(data));
  };

  static verifyOrgInfo = (infos: IPartnerTeamOtherInfo): boolean => {
    const keys = [
      InfoSalesModel.CUSTOMER_TYPE,
      InfoSalesModel.SALE_TEAM,
      InfoSalesModel.REGION,
      InfoSalesModel.PRODUCT_LINE,
    ];

    return ValidationService.verifyIntegrity(infos, keys);
  };

  static verifyOrgRegion = (
    region: number,
    regions: Partial<StaticSourceItem>[]
  ): boolean => {
    return !!regions?.find(({ value }) => value === region);
  };

  static verifyOrgProductLine = (
    productLine: number,
    productLines: Partial<StaticSourceItem>[]
  ): boolean => {
    return !!productLines?.find(({ value }) => value === productLine);
  };

  static verifyContactPerson = (
    infos: IContactPerson,
    keys: (keyof IContactPerson)[]
  ): boolean => {
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static verifyFinalGoodsRecipient = (
    infos: IFinalGoodsReceipt,
    keys: (keyof IFinalGoodsReceipt)[]
  ): boolean => {
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static verifySaleSubmit = (
    infos: Partial<IPrepareInstallation>,
    keys: (keyof IPrepareInstallation)[]
  ) => {
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static verifyContactPersonFromSass = (infos: IContactPerson): boolean => {
    return InfoSalesModel.verifyContactPerson(infos, [
      InfoSalesModel.CONTACT_NAME,
      InfoSalesModel.MOBILE_PHONE,
      InfoSalesModel.RECIPE_CITY,
      InfoSalesModel.RECIPE_ADDRESS,
    ]);
  };

  static verifyFinalGoodsRecipientFromSass = (
    infos: IFinalGoodsReceipt
  ): boolean => {
    return InfoSalesModel.verifyFinalGoodsRecipient(infos, [
      InfoSalesModel.COMPANY_NAME,
      InfoSalesModel.RECIPE_CITY,
      InfoSalesModel.RECIPE_ADDRESS,
    ]);
  };

  static getSiteSurveyExpectedDateLimit = (): [Date, Date] => {
    const now = new Date();
    const today = now.setHours(0, 0, 0, 0);
    const perDay = 24 * 60 * 60 * 1000;
    return [new Date(today), new Date(today + perDay * 60)];
  };

  static getLabels = () => {
    return {
      labelInfoSaleEmp: formatMessage("order_info_sale_emp"),
      labelInfoSaleManager: formatMessage("order_info_sale_manager"),
      labelServiceCoo: formatMessage("order_service_coordinator"),
      labelIncoterms: formatMessage("order_info_incoterms"),
      labelSaleTeam: formatMessage("order_info_sale_team"),
      labelSaleManager: formatMessage("order_sale_manager"),
      labelApSupervisor: formatMessage("order_ap_supervisor"),
      labelApEngineer: formatMessage("order_ap_engineer"),
      labelCustomerType: formatMessage("order_info_customer_type"),
      labelRegin: formatMessage("order_info_region"),
      labelProductLine: formatMessage("order_info_product_line"),
      labelOwnerRsm: formatMessage("order_rsm_shorter"),
      labelDnNotice: formatMessage("order_dn_notice"),
      labelDnCpoDate: formatMessage("order_dn_cpo_date"),
      labelDnInstallOrderNo: formatMessage("order_dn_install_order_no"),
      labelDnSerialNumber: formatMessage("order_dn_serial_number"),
      labelDnServiceContactorOrder: formatMessage(
        "order_dn_service_contactor_order"
      ),
      labelContactPerson: formatMessage("order_contact_person"),
      labelContactMobile: formatMessage("order_contact_mobile"),
      labelContactEmail: formatMessage("order_contact_email"),
      labelContactEmailOptional: formatMessage("order_contact_email_optional"),
      labelRecipeCity: formatMessage("order_contact_recipe_city"),
      labelRecipeAddress: formatMessage("order_contact_recipe_address"),
      labelFinalGoodRecipe: formatMessage("order_final_goods_recipe"),
      labelPostCode: formatMessage("order_post_code"),
      labelSoldToParty: formatMessage("order_sold_to_party"),
      labelCompanyName: formatMessage("order_contact_company_name"),
      labelContactPersonName: formatMessage("order_contact_person_name"),
      labelAllTicket: formatMessage("common_btn_all_tickets"),
      labelForward: formatMessage("common_btn_forward"),
      labelCancelOrder: formatMessage("common_btn_cancel_order"),
      labelSubmit: formatMessage("common_btn_submit"),
      labelMore: formatMessage("common_btn_more"),
      labelAssign: formatMessage("common_btn_assign"),
      labelReject: formatMessage("common_btn_reject"),
      labelApprove: formatMessage("common_btn_approve"),
      labelNeedSiteSurvey: formatMessage("order_need_site_survey"),
      labelRsmNeedSiteSurveySwitchReminder: formatMessage(
        "order_rsm_need_site_survey_switch_reminder"
      ),
      labelConfirm: formatMessage("common_btn_confirm"),
      labelDeliveryNoteOptional: formatMessage("order_delivery_note_optional"),
      labelFseSiteSurvey: formatMessage("order_fse_site_survey"),
      labelFseInstallation: formatMessage("order_fse_installation"),
      labelDnDelivery: formatMessage("order_dn_delivery"),

      labelRsmSiteSurvey: formatMessage("order_rsm_site_survey"),
      labelRsmInstallation: formatMessage("order_rsm_installation"),
      labelDeviceName: formatMessage("order_device_name"),
      labelNumber: formatMessage("common_number"),
      labelAfterSales: formatMessage("order_after_sales"),
      labelOrder: formatMessage("common_order"),
      labelFacUploader: formatMessage("order_install_fac_uploader"),
      labelOperate: formatMessage("order_operation"),
      labelOrderNumber: formatMessage("order_order_number"),
      labelMaterialNo: formatMessage("order_material_no"),
      labelMaterialName: formatMessage("order_material_name"),
    };
  };
}
