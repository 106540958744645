import classNames from "classnames";
import FoldCabinet, { IFoldCabinet } from "./FoldCabinet";
import styles from "./FoldCabinet.module.scss";

export default function (props: IFoldCabinet) {
  const { className, labelClassName, ...rest } = props;
  const _className = classNames(styles.foldCabinetVariantPc, className);
  const _labelClassName = classNames(styles.labelClassName, labelClassName);

  return (
    <FoldCabinet
      {...rest}
      className={_className}
      labelClassName={_labelClassName}
    />
  );
}
