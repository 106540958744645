import OrderRequestModel from "../../pages/OrderRequest/model/OrderRequestModel";
import { RegUtil } from "../../utils/RegUtils";
import { isNumber, isObject } from "../../utils/isType";

export enum FileUploadStatus {
  PENDING = "pending",
  RESOLVED = "resolved",
  FAILED = "failed",
}

export enum FileType {
  IMAGE = "image",
  PDF = "pdf",
  EXCEL = "spreadsheetml.sheet",
  WORD = "word",
  OTHER = "other",
}

export interface IFile {
  id: number;
  status: FileUploadStatus;
  name: string;
  fileType: FileType;
  url?: string;
  file?: File | Blob;
}

export interface IFileUploadItem {
  id?: number;
  url: string;
  name: string;
}

export enum FileUploadCode {
  INVALID_FILE_TYPE = 101,
  OVER_LIMIT_SIZE = 102,
  DELETE_FILE_FAILED = 103,
  UPLOAD_FAILED = 104,
  PREVIEW_FAILED = 105,
  DOWNLOAD_FAILED = 106,
  PREVIEW_SUC = 10,
}

export default class FileUploadService {
  static SUPPORT_PREVIEW = [
    FileType.IMAGE,
    FileType.PDF,
    FileType.WORD,
    FileType.EXCEL,
  ];

  static getProcessValues = (value: IFileUploadItem[]): IFile[] => {
    if (!Array.isArray(value)) return [];
    return value.map(({ url, name, id }) => {
      // use sample logic to confirm file type, will refine feature
      const [_name, ...suffix] = name.split(".") ?? [];
      const _suffix = suffix?.reverse()?.[0];
      return {
        id: id as number,
        status: RegUtil.verifyWebAddr(url)
          ? FileUploadStatus.RESOLVED
          : FileUploadStatus.FAILED,
        name: name,
        fileType: FileUploadService.getFileType(`.${_suffix}`) as FileType,
        url,
      };
    });
  };

  static getPropsValue = (file: IFile): IFileUploadItem => {
    if (!isObject(file)) return {} as IFileUploadItem;
    const { url, name } = file;
    return { url: url as string, name };
  };

  static getFilePreviewEnable = (fileType: FileType): boolean => {
    const support = FileUploadService.SUPPORT_PREVIEW.filter(
      (item) => item !== FileType.EXCEL
    );

    return support.includes(fileType);
  };

  static validFileType = (type: string, accept: string) => {
    const acceptArr = accept.split(",");
    const acceptFileTypes = acceptArr.map((item) =>
      FileUploadService.getFileType(item)
    );
    const _fileType = FileUploadService.getFileType(type);
    return acceptFileTypes.includes(_fileType);
  };

  static getFileType = (type: string): FileType | null => {
    const _type = type?.toLocaleLowerCase();
    const convertList = [
      FileUploadService.convertImageAcceptToFileType,
      FileUploadService.convertPdfAcceptToFileType,
      FileUploadService.convertWordAcceptToFileType,
      FileUploadService.convertExcelAcceptToFileType,
    ];

    return convertList
      .map((convert) => {
        return convert(_type);
      })
      ?.filter((item) => item)[0];
  };

  static fileDownload = (url: string, name: string): void | Promise<any> => {
    if (!RegUtil.verifyWebAddr(url)) return;
    const result = OrderRequestModel.downloadFile(url, name);
    if (result instanceof Promise) {
      return result;
    }
  };

  static validFileSize = (size: number, limit: number) => {
    if (isNumber(size)) false;
    if (!isNumber(limit)) return true;
    return size / 1024 < limit;
  };

  private static convertImageAcceptToFileType = (accept: string) => {
    const suffix = [".png", ".gif", ".jpg", ".jpeg", ".svg", ".webp", "image/"];
    return suffix.includes(accept) ? FileType.IMAGE : null;
  };

  private static convertPdfAcceptToFileType = (accept: string) => {
    const suffix = [".pdf"];
    return suffix.includes(accept) ? FileType.PDF : null;
  };

  private static convertWordAcceptToFileType = (accept: string) => {
    const suffix = [".doc", ".docx"];
    return suffix.includes(accept) ? FileType.WORD : null;
  };

  private static convertExcelAcceptToFileType = (accept: string) => {
    const suffix = [".xlsx", ".xls"];
    return suffix.includes(accept) ? FileType.EXCEL : null;
  };
}
