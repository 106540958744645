import { isEmpty } from "lodash";
import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import { formatMessage } from "../../../../core/LangService";
import { SystemPlatform } from "../../../../core/SystemService";
import { IOrderBasicInfo } from "../../../../services/order.service";
import { isNumber } from "../../../../utils/isType";
import { ViewType } from "../../../components/ViewTypes";
import { OrderStep } from "../../../service/OrderStepTypes";
import { ViewItemModel } from "../../model/OrderRequestModel";

// need to know if reflow in basic infos;
export default class OrderHeaderModel {
  static SUB_ORDER = "subStandardOrder";
  static FINAL_GOODS_RECIPE = "finalGoodsRecipName";
  static PO_DATE = "pODate";
  static MAIN_PRODUCT = "mainProduct";
  static STEP = "step";

  static getModelList = (
    infos: IOrderBasicInfo,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelSubOrder,
      labelFinalGoodsRecipe,
      labelPoDate,
      labelMainProduct,
      labelStep,
    } = OrderHeaderModel.getLabels();
    if (isEmpty(infos)) return [];
    const textViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    const {
      subsStandardOrder,
      finalGoodsRecipName,
      poDate,
      mainProduct,
      productQuantity,
      isFinalGoodsRecipNew,
    } = infos;
    const _mainProduct = productQuantity
      ? `${mainProduct} x${productQuantity}`
      : mainProduct;
    const indicator = isFinalGoodsRecipNew
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;

    return [
      {
        key: OrderHeaderModel.SUB_ORDER,
        viewType: textViewType,
        props: { label: labelSubOrder, children: subsStandardOrder },
      },
      {
        key: OrderHeaderModel.FINAL_GOODS_RECIPE,
        viewType: textViewType,
        props: {
          label: labelFinalGoodsRecipe,
          children: finalGoodsRecipName,
          indicator,
        },
      },
      {
        key: OrderHeaderModel.PO_DATE,
        viewType: textViewType,
        props: { label: labelPoDate, children: poDate },
      },
      {
        key: OrderHeaderModel.MAIN_PRODUCT,
        viewType: textViewType,
        props: { label: labelMainProduct, children: _mainProduct },
      },
      {
        key: OrderHeaderModel.STEP,
        viewType: textViewType,
        props: { label: labelStep },
      },
    ];
  };

  static getStepLabel = (step: OrderStep): string => {
    if (!isNumber(+step)) return "";
    const {
      labelStepSiteSurveyReqSub,
      labelStepSiteSurveyAssign,
      labelStepSiteSurveyImplement,
      labelStepInstallReqSub,
      labelStepInstallReqAudit,
      labelStepInstallAssign,
      labelStepInstallImplement,
      labelStepTrainingAssign,
      labelStepTrainingImplement,
      labelStepUploadFac,
      labelStepUpdateCRM,
    } = OrderHeaderModel.getLabels();
    const labelMap = new Map([
      [OrderStep.SITE_SURVEY_SUBMIT, labelStepSiteSurveyReqSub],
      [OrderStep.SUBMIT_SITE_SURVEY_ASSIGN, labelStepSiteSurveyAssign],
      [OrderStep.SUBMIT_SITE_SURVEY_IMPLEMENT, labelStepSiteSurveyImplement],
      [OrderStep.SUBMISSION, labelStepInstallReqSub],
      [OrderStep.AUDIT, labelStepInstallReqAudit],
      [OrderStep.ASSIGN, labelStepInstallAssign],
      [OrderStep.INSTALL, labelStepInstallImplement],
      [OrderStep.TRAINING_ASSIGN, labelStepTrainingAssign],
      [OrderStep.TRAINING_IMPLEMENT, labelStepTrainingImplement],
      [OrderStep.FAC_UPLOAD, labelStepUploadFac],
      [OrderStep.SO_CONFIRM, labelStepUpdateCRM],
    ]);

    return labelMap.get(step) ?? "";
  };

  static getLabels = (): Record<string, string> => ({
    labelSubOrder: formatMessage("order_sub_order"),
    labelFinalGoodsRecipe: formatMessage("order_final_goods_recipe"),
    labelPoDate: formatMessage("order_po_date"),
    labelMainProduct: formatMessage("order_main_product"),
    labelStep: formatMessage("order_step"),
    labelStepSiteSurveyReqSub: formatMessage("order_step_site_survey_req_sub"),
    labelStepSiteSurveyAssign: formatMessage("order_step_site_survey_assign"),
    labelStepSiteSurveyImplement: formatMessage(
      "order_step_site_survey_implement"
    ),
    labelStepInstallReqSub: formatMessage("order_step_install_req"),
    labelStepInstallReqAudit: formatMessage("order_step_install_audit"),
    labelStepInstallAssign: formatMessage("order_step_install_assign"),
    labelStepInstallImplement: formatMessage("order_step_install_implement"),
    labelStepTrainingAssign: formatMessage("order_step_trainingAssign"),
    labelStepTrainingImplement: formatMessage("order_step_training_implement"),
    labelStepUploadFac: formatMessage("order_step_upload_fac"),
    labelStepUpdateCRM: formatMessage("order_step_update_crm"),
  });
}
