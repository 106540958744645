import { cloneDeep } from "lodash";
import React from "react";
import { useRecoilValue } from "recoil";
import { UploadItem } from "../../../../../components/Upload/Upload";
import { LocaleLang } from "../../../../../core/LocaleService";
import { localeState } from "../../../../../store";
import { isObject } from "../../../../../utils/isType";
import { ViewType } from "../../../../components/ViewTypes";
import useLang from "../../../../hooks/useLang";
import useRolePage from "../../../../hooks/useRolePage";
import { PageStatus, PageSubItems } from "../../../../service/pageType";
import {
  AccessoryProperties,
  AccessoryTypes,
} from "../../../SiteSurvey/model/SiteSurveyModel";
import {
  IFile,
  IPrepareInstallation,
  IThirdParty,
  ProductLine,
} from "../../../model/OrderRequestModel";
import InfoSalesModel from "../InfoSalesModel";

export interface InfoSiteSurveyProps
  extends Pick<
    IPrepareInstallation,
    | "isSiteSurvey"
    | "expectedStartDate"
    | "thirdParty"
    | "disclaimer"
    | "isRsmNeedSiteSurvey"
  > {
  productLine?: ProductLine;
  onChange: (data: { key: keyof IPrepareInstallation; value: any }) => void;
}

export interface HookInfoSiteSurvey {
  isRsmNeedSiteSurvey: boolean;
  reminderNeedSiteSurvey: string;
  isSiteSurvey: boolean;
  reminderYes: string;
  reminderNo: string;
  siteSurvey: string;
  party3ViewTitle: string;
  part3ViewStatus: boolean;
  thirdParty: IThirdParty;
  disclaimer: IFile[];
  pageStatus: PageStatus;
  expectedSiteSurvey: string;
  labelDisclaimer: string;
  uploadReminder: string;
  expectedStartDate: number;
  selectPhd: string;
  showDisclaimer: boolean;
  locale: LocaleLang;
  dateChangeHandler: (date: Date) => void;
  subChangeHandler: (
    key: string,
    subKey: string,
    viewType: ViewType,
    result: any
  ) => void;
  checkboxChangeHandler: (key: AccessoryTypes, isChecked: boolean) => void;
  setPart3ViewStatus: React.Dispatch<React.SetStateAction<boolean>>;
  fileChangHandler: (items: UploadItem[]) => void;
  onChange: (data: { key: keyof IPrepareInstallation; value: any }) => void;
}

export const defaultRecord = {
  [AccessoryTypes.AIRLOCK]: {},
  [AccessoryTypes.MUTE]: {},
  [AccessoryTypes.EP]: {},
  [AccessoryTypes.CRYO]: {},
  [AccessoryTypes.LASER]: {},
  [AccessoryTypes.VIEW3]: {},
  [AccessoryTypes.COLD_TABLE]: {},
  [AccessoryTypes.CL]: {},
  [AccessoryTypes.ENERGY_SPECTRUM]: {},
  [AccessoryTypes.EBSD]: {},
  [AccessoryTypes.VIBRATION_ISOLATION]: {},
  [AccessoryTypes.DEMAGNETIZER]: {},
  [AccessoryTypes.UPS]: {},
  [AccessoryTypes.OTHER]: {},
};

export default function (props: InfoSiteSurveyProps): HookInfoSiteSurvey {
  const {
    siteSurvey,
    reminderYes,
    reminderNo,
    expectedSiteSurvey,
    labelDisclaimer,
    uploadReminder,
    reminderNeedSiteSurvey,
    selectPhd,
  } = useLang({
    siteSurvey: { id: "order_site_survey_required" },
    reminderYes: { id: "order_site_survey_selected" },
    reminderNo: { id: "order_site_survey_not_selected" },
    expectedSiteSurvey: { id: "order_expected_site_survey_data" },
    labelDisclaimer: { id: "order_upload_disclaimer" },
    uploadReminder: { id: "order_upload_file_reminder" },
    reminderNeedSiteSurvey: {
      id: "order_rsm_need_site_survey_switch_reminder",
    },
    selectPhd: { id: "order_select_phd" },
  });
  const {
    isSiteSurvey,
    expectedStartDate,
    thirdParty: _thirdParty,
    disclaimer,
    isRsmNeedSiteSurvey,
    productLine,
    onChange,
  } = props;
  const thirdParty = React.useMemo(
    () =>
      (isObject(_thirdParty) ? _thirdParty : {}) as Record<
        AccessoryTypes,
        Partial<AccessoryProperties>
      >,
    [_thirdParty]
  );
  const { party3ViewTitle } = useLang({
    party3ViewTitle: { id: "order_accessories" },
  });
  const [part3ViewStatus, setPart3ViewStatus] = React.useState(true);
  const { pageStatus } = useRolePage(
    PageSubItems.PAGE_ORDER_REQUEST_SUB_ORDER_INFO
  );
  const showDisclaimer = !!productLine && productLine !== ProductLine.LM;
  const locale = useRecoilValue(localeState);

  const dateChangeHandler = React.useCallback(
    (date: Date) =>
      onChange({
        key: InfoSalesModel.EXPECTED_START_DATE,
        value: date?.getTime(),
      }),
    [onChange]
  );

  const subChangeHandler = React.useCallback(
    (key: string, subKey: string, viewType: ViewType, result: any) => {
      const value = [
        ViewType.VIEW_INPUT,
        ViewType.VIEW_TEXTAREA,
        ViewType.VIEW_INPUT_BASIC,
      ].includes(viewType)
        ? result?.target.value
        : result;
      const copied: Record<string, any> = cloneDeep(thirdParty);
      const updateItem = copied[key];
      updateItem[subKey] = value;
      onChange({
        key: InfoSalesModel.THIRD_PARTY,
        value: { key, value: updateItem, action: "update" },
      });
    },
    [onChange, thirdParty]
  );

  const checkboxChangeHandler = React.useCallback(
    (key: AccessoryTypes, isChecked: boolean) => {
      if (isChecked) {
        // "backend need some key in a object, now named notUse "
        onChange({
          key: InfoSalesModel.THIRD_PARTY,
          value: { key, value: { notUse: "" }, action: "update" },
        });
      } else {
        onChange({
          key: InfoSalesModel.THIRD_PARTY,
          value: { key, value: { notUse: "" }, action: "delete" },
        });
      }
    },
    [onChange]
  );

  const fileChangHandler = React.useCallback(
    (items: UploadItem[]) => {
      onChange({
        key: InfoSalesModel.DISCLAIMER,
        value: items,
      });
    },
    [onChange]
  );

  return {
    isRsmNeedSiteSurvey: isRsmNeedSiteSurvey as boolean,
    reminderNeedSiteSurvey,
    isSiteSurvey,
    reminderYes,
    reminderNo,
    siteSurvey,
    party3ViewTitle,
    part3ViewStatus,
    thirdParty,
    disclaimer,
    pageStatus,
    expectedSiteSurvey,
    labelDisclaimer,
    uploadReminder,
    expectedStartDate,
    selectPhd,
    showDisclaimer,
    locale,
    dateChangeHandler,
    subChangeHandler,
    checkboxChangeHandler,
    setPart3ViewStatus,
    fileChangHandler,
    onChange,
  };
}
