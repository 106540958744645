import React from "react";

interface IIcon {
  size?: "auto" | "default";
}

export default function (props: IIcon): React.ReactElement {
  const { size } = props;
  const width = size === "auto" ? "100%" : "157",
    height = size === "auto" ? "100%" : "139";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 157 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.165 116.036C156.165 128.467 121.207 138.519 78.0823 138.519C34.9575 138.519 0 128.467 0 116.036C0 113.344 0 111.549 0 108.542C10.8148 99.8814 44.2466 98.5352 78.0823 98.5352C112.546 98.5352 145.798 98.6249 156.165 107.51C156.165 112.536 156.165 113.523 156.165 116.036Z"
        fill="url(#paint0_linear_2451_30656)"
      />
      <path
        d="M78.0823 130.531C121.206 130.531 156.165 120.465 156.165 108.049C156.165 95.6321 121.206 85.5664 78.0823 85.5664C34.9586 85.5664 0 95.6321 0 108.049C0 120.465 34.9586 130.531 78.0823 130.531Z"
        fill="url(#paint1_linear_2451_30656)"
      />
      <path
        opacity="0.98"
        d="M134.636 126.906L46.3223 124.663L41.7002 106.623H115.071L134.636 126.906Z"
        fill="url(#paint2_linear_2451_30656)"
      />
      <path
        d="M105.199 40.4323H101.743V25.7133C101.743 11.3982 91.0182 0 77.5108 0C64.0035 0 53.2335 11.4431 53.2335 25.7582V40.4771H49.7782C43.8996 40.4771 39.3672 45.2339 39.3672 51.5164V99.2632C39.3672 105.501 43.8547 110.302 49.7782 110.302H105.199C111.077 110.302 115.61 105.546 115.61 99.2632V51.4266C115.565 45.2339 111.077 40.4323 105.199 40.4323ZM80.9213 76.0629V84.4994C80.9213 86.6983 79.5302 88.1792 77.466 88.1792C75.4017 88.1792 74.0106 86.6983 74.0106 84.4994V76.0629C71.9464 74.9859 70.5552 72.3832 70.5552 69.8253C70.5552 65.7866 73.6516 62.4658 77.5108 62.4658C81.3701 62.4658 84.4664 65.7417 84.4664 69.8253C84.3767 72.3832 82.9856 74.9411 80.9213 76.0629ZM94.7877 40.4323H60.1443V25.7133C60.1443 15.3921 67.773 7.35948 77.466 7.35948C87.1589 7.35948 94.7877 15.437 94.7877 25.7133C94.7877 25.7582 94.7877 40.4323 94.7877 40.4323Z"
        fill="url(#paint3_linear_2451_30656)"
      />
      <path
        d="M107.667 42.6759H104.301V28.4505C104.301 14.5842 93.9349 3.58984 80.8314 3.58984C67.7728 3.58984 57.317 14.6291 57.317 28.4954V42.7207H53.9514C48.2523 42.7207 43.8994 47.298 43.8994 53.401V99.6221C43.8994 105.635 48.2523 110.302 53.9514 110.302H107.577C113.276 110.302 117.629 105.725 117.629 99.6221V53.3561C117.674 47.3428 113.366 42.6759 107.667 42.6759ZM84.1522 77.1846V85.3519C84.1522 87.5059 82.8059 88.897 80.7866 88.897C78.7672 88.897 77.4209 87.461 77.4209 85.3519V77.1846C75.4016 76.1525 74.0553 73.6395 74.0553 71.1714C74.0553 67.2673 77.0619 64.0363 80.7866 64.0363C84.5112 64.0363 87.5178 67.2224 87.5178 71.1714C87.5178 73.6395 86.1715 76.0628 84.1522 77.1846ZM97.5698 42.6759H64.0482V28.4505C64.0482 18.4883 71.4526 10.7249 80.7866 10.7249C90.1205 10.7249 97.5249 18.5332 97.5249 28.4505C97.5698 28.4954 97.5698 42.6759 97.5698 42.6759Z"
        fill="url(#paint4_linear_2451_30656)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2451_30656"
          x1="78.0823"
          y1="138.519"
          x2="78.0823"
          y2="98.5576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC6D5" />
          <stop offset="1" stopColor="#D7DDE2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2451_30656"
          x1="78.0823"
          y1="130.536"
          x2="78.0823"
          y2="85.5664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3DBE8" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2451_30656"
          x1="88.1635"
          y1="126.906"
          x2="88.1635"
          y2="106.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" stopOpacity="0" />
          <stop offset="1" stopColor="#B4C0CC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2451_30656"
          x1="115.441"
          y1="104.259"
          x2="39.4762"
          y2="28.294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7B1C1" />
          <stop offset="1" stopColor="#D7DDE2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2451_30656"
          x1="104.052"
          y1="113.91"
          x2="48.9693"
          y2="18.5038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D0E0" />
          <stop offset="1" stopColor="#EFF2F6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
