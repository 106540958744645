import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import { IContactPerson } from "../../model/OrderRequestModel";
import ValidationService, {
  IValidationItem,
  IVerifyItem,
  VerifyType,
} from "../../service/ValidationService";
import styles from "./ModifyContact.module.scss";
import useModifyContact, {
  Action,
  ModifyContactProps,
} from "./useModifyContact";

const ModifyContactMobile: React.FC<ModifyContactProps> = ErrorDetectorPageView(
  (props: ModifyContactProps) => {
    const {
      stateModels,
      title,
      subTitle,
      stateVisible,
      cancel,
      confirm,
      blockBtn,
      rest,
      clickHandler,
      changeHandler,
    } = useModifyContact(props);

    const typingValid = React.useMemo(() => {
      const validList: IValidationItem[] = stateModels.map(
        ({ key, props, verifyType }) => {
          const verifyItem: IVerifyItem = {
            key: key as string,
            types: verifyType as [VerifyType] | [VerifyType, VerifyType],
            value: props?.value,
          };
          return ValidationService.validProperty(verifyItem);
        }
      );
      return validList.every(({ valid }) => valid);
    }, [stateModels]);

    const submitValid = React.useMemo(() => {
      let infos: IContactPerson = {} as IContactPerson;
      const keys: (keyof IContactPerson)[] = stateModels
        .map(({ key }) => key as keyof IContactPerson)
        .filter((key) => key !== InfoSalesModel.EMAIL);
      stateModels.forEach(({ key, props }) => {
        infos = {
          ...infos,
          [key as keyof typeof infos]: props?.value,
        };
      });
      return InfoSalesModel.verifyContactPerson(infos, keys);
    }, [stateModels]);

    const views = React.useMemo(
      () =>
        stateModels?.map(({ key, props, viewType }) => (
          <ViewComponent
            key={key}
            type={viewType as ViewType}
            props={{
              ...(props as Record<string, any>),
              key,
              onChange: (evt: any) =>
                changeHandler(key as string, evt?.target?.value),
            }}
          />
        )),
      [stateModels, changeHandler]
    );

    const footerView = () => {
      return (
        <div className={styles.buttons}>
          <Button
            variant={"secondary"}
            className={styles.btn}
            onClick={clickHandler.bind(null, Action.Cancel)}
            size="s"
          >
            {cancel}
          </Button>
          <Button
            variant={"primary"}
            className={styles.btn}
            onClick={clickHandler.bind(null, Action.Confirm)}
            disabled={!submitValid || !typingValid || blockBtn}
            size="s"
          >
            {confirm}
          </Button>
        </div>
      );
    };

    const titleView = React.useMemo(() => {
      return (
        <span className={styles.title}>
          {title}
          {subTitle && <span className={styles.new}>{subTitle}</span>}
        </span>
      );
    }, [title, subTitle]);

    const contentView = () => (
      <section className={styles.modifyContact}>
        <div className={styles.views}>
          <div className={styles.title}>{titleView}</div>
          {views}
        </div>
      </section>
    );

    return (
      <Confirmor
        footer={footerView()}
        content={contentView()}
        {...rest}
        visible={stateVisible}
      />
    );
  }
);

export default ModifyContactMobile;
