import { isEmpty } from "lodash";
import { ToggleItem } from "../../../components/Toggle/Toggle";
import { LabelIndicator } from "../../../components/ViewWrapper/ViewWrapper";
import { LocaleLang } from "../../../core/LocaleService";
import { OrderItem } from "../../../services/order.service";
import { ViewItemModel } from "../../OrderRequest/model/OrderRequestModel";
import { ViewType } from "../../components/ViewTypes";
import OrderListModel from "../OrderListModel";

export default class OrderListMobileModel extends OrderListModel {
  static readonly SUB_ORDER = "subOrder";
  static readonly CONTACT_PERSON = "contactPerson";
  static readonly FINAL_GOODS_RECIPE = "finalGoodsRecipe";
  static readonly MAIN_PRODUCT = "mainProduct";
  static readonly DEC = "dec";

  static readonly LANG_ZH = "中";
  static readonly LANG_EN = "En";

  static getInfoModels = (infos: OrderItem): Partial<ViewItemModel>[] => {
    if (isEmpty(infos)) return [];
    const {
      contactPersonName,
      fullFinalGoodsRecipName,
      mainProduct,
      subsStandardOrder,
      isFinalGoodsRecipNew,
    } = infos ?? {};
    const indicator = isFinalGoodsRecipNew
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;
    const {
      labelSubOrder,
      labelFinalGoodsRecipe,
      labelContactPerson,
      labelMainProduct,
    } = OrderListModel.getLabels();
    return [
      {
        key: OrderListMobileModel.SUB_ORDER,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelSubOrder, children: subsStandardOrder },
      },
      {
        key: OrderListMobileModel.FINAL_GOODS_RECIPE,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelFinalGoodsRecipe,
          children: fullFinalGoodsRecipName,
          indicator,
        },
      },
      {
        key: OrderListMobileModel.MAIN_PRODUCT,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelMainProduct, children: mainProduct },
      },
      {
        key: OrderListMobileModel.CONTACT_PERSON,
        viewType: ViewType.VIEW_TEXT,
        props: { label: labelContactPerson, children: contactPersonName },
      },
    ];
  };

  static getLangItems = (): ToggleItem[] => {
    return [
      {
        text: OrderListMobileModel.LANG_ZH,
        value: LocaleLang.ZH_CN,
      },
      {
        text: OrderListMobileModel.LANG_EN,
        value: LocaleLang.EN_US,
      },
    ];
  };
}
