import classNames from "classnames";
import React from "react";
import { RegUtil } from "../../utils/RegUtils";
import { isFunction, isNumber } from "../../utils/isType";
import { ErrorDetectorPageView } from "../ErrorDecorator/ErrorDecorator";
import { FileAction, FileItem } from "./FileItem";
import styles from "./FileUpload.module.scss";
import FileUploadService, {
  FileType,
  FileUploadCode,
  FileUploadStatus,
  IFile,
  IFileUploadItem,
} from "./FileUploadService";
import Preview from "./Preview";
import { IPreviewImg, IPreviewPdf, IPreviewWord } from "./PreviewFile";
import { UploadItem } from "./UploadItem";

/**
 * Todo:
 * 1. when user upload file without suffix , will not parse it correctly
 */

export interface IFileUploadProps {
  className?: string;
  value: IFileUploadItem[];
  accept?: string;
  disabled?: boolean;
  /** Customized upload node */
  node?: React.ReactNode;
  /** Upload reminder text  */
  reminder?: string;
  /** Upload node class name */
  uploadClassName?: string;
  deletable?: boolean;
  downloadable?: boolean;
  previewAble?: boolean;
  /** File size of KB unit */
  size?: number;
  maxCount?: number;
  /** pdf preview config */
  pdf?: IPreviewPdf;
  /** word preview config */
  word?: IPreviewWord;
  /** image preview config */
  image?: IPreviewImg;
  /** Trigger when value changed */
  onChange?: (files: IFileUploadItem[]) => void;
  beforeUpload?: (file: File) => boolean;
  onUpload: (file: File) => Promise<IFileUploadItem>;
  beforeDelete?: (file: IFileUploadItem) => Promise<void>;
  onDelete?: (file: IFileUploadItem) => Promise<void>;
  onDownload?: (result: Promise<any>) => void;
  onPreview?: (result: Promise<any>) => void;
  onError?: (code: FileUploadCode) => void;
  onSuccess?: () => void;
}

const FileUpload: React.FC<IFileUploadProps> = ErrorDetectorPageView(
  React.forwardRef<HTMLDivElement, IFileUploadProps>(
    (props: IFileUploadProps, ref) => {
      const {
        className,
        value,
        accept,
        disabled,
        node,
        size: maxSize,
        maxCount,
        reminder,
        uploadClassName,
        deletable,
        downloadable,
        previewAble,
        pdf,
        word,
        image,
        beforeUpload,
        onUpload,
        onChange,
        onPreview,
        onDownload,
        onError,
        onSuccess,
        beforeDelete,
        onDelete,
      } = props;

      const [_value, setValue] = React.useState(() => formatValue(value));
      const [visible, setVisible] = React.useState(false);
      const _className = classNames(styles.fileUpload, className);
      const [processValue, setProcess] = React.useState<IFile[]>(() =>
        combineProcessValues(formatValue(value))
      );
      const [activeId, setActive] = React.useState<number>();
      const uploadAble = !isNumber(maxCount) || _value.length < maxCount;
      const activeValue = React.useMemo(
        () => processValue?.find(({ id }) => id === activeId) as IFile,
        [processValue, activeId]
      );
      const currentRef = React.useRef<IFile>({} as IFile);
      const processValueRef = React.useRef<IFile[]>([]);
      const _valueRef = React.useRef<IFileUploadItem[]>([]);

      React.useEffect(() => {
        setValue(formatValue(value));
        setProcess(combineProcessValues(formatValue(value)));
      }, [value]);

      React.useEffect(() => {
        processValueRef.current = processValue;
      }, [processValue]);

      React.useEffect(() => {
        _valueRef.current = _value;
      }, [_value]);

      const setCurrenUploadItem = (
        file: File | undefined,
        status: FileUploadStatus
      ): void => {
        const { length } = processValueRef.current;
        if (status === FileUploadStatus.PENDING && file instanceof File) {
          const _file = {
            id: length ? length + 1 : length,
            status,
            name: file?.name,
            fileType: FileUploadService.getFileType(file?.type) as FileType,
            file,
          };
          currentRef.current = _file;
          setProcess([...processValueRef.current, _file]);
        }
        const { id } = currentRef.current;
        if (status === FileUploadStatus.RESOLVED) {
          currentRef.current = {} as IFile;
          setProcess([
            ...processValueRef.current.filter(({ id: _id }) => id !== _id),
          ]);
        }
        if (status === FileUploadStatus.FAILED) {
          const uploadingItem = processValueRef.current.find(
            ({ id: _id }) => _id === id
          );
          if (!uploadingItem) return;
          currentRef.current = {} as IFile;
          setProcess([
            ...processValueRef.current.filter(({ id: _id }) => id !== _id),
          ]);
        }
      };

      const beforeUploadHandler = (file: File): boolean => {
        if (!isFunction(beforeUpload)) return true;
        return beforeUpload(file);
      };

      const uploadHandler = (file: File) => {
        if (!isFunction(onUpload)) return;
        const result = onUpload(file);
        if (!(result instanceof Promise)) return;
        setCurrenUploadItem(file, FileUploadStatus.PENDING);
        result
          .then((result: IFileUploadItem) => {
            isFunction(onSuccess) && onSuccess();
            setCurrenUploadItem(file, FileUploadStatus.RESOLVED);
            isFunction(onChange) &&
              onChange([...value, result as IFileUploadItem]);
          })
          .catch(() => {
            setCurrenUploadItem(file, FileUploadStatus.FAILED);
            isFunction(onError) && onError(FileUploadCode.UPLOAD_FAILED);
          });
      };

      const _deleteHandler = (id: number): void => {
        const deleteItem = processValue?.find(
          ({ id: _id }) => _id === id
        ) as IFile;
        const _deleteItem = FileUploadService.getPropsValue(deleteItem);
        const removedProcess = processValue.filter(({ id: _id }) => _id !== id);
        const removeValue = _valueRef.current.filter(
          ({ id: _id }) => _id !== id
        );
        if (isFunction(onDelete) && onDelete(_deleteItem) instanceof Promise) {
          onDelete(_deleteItem)
            .then(() => {
              setProcess(removedProcess);
              isFunction(onChange) && onChange(removeValue);
            })
            .catch(
              () =>
                isFunction(onError) &&
                onError(FileUploadCode.DELETE_FILE_FAILED)
            );
        } else {
          setProcess(removedProcess);
          isFunction(onChange) && onChange(removeValue);
        }
      };

      const deleteHandler = (id: number) => {
        const deleteItem = processValueRef.current?.find(
          ({ id: _id }) => _id === id
        ) as IFile;
        const _deleteItem = FileUploadService.getPropsValue(deleteItem);

        if (
          deleteItem?.file &&
          !RegUtil.verifyWebAddr(deleteItem?.url as string)
        ) {
          setCurrenUploadItem(undefined, FileUploadStatus.RESOLVED);
        } else {
          if (isFunction(beforeDelete)) {
            beforeDelete(_deleteItem)
              ?.then(() => _deleteHandler(id))
              ?.catch((err) => console.log(err));
          } else {
            _deleteHandler(id);
          }
        }
      };

      const preViewHandler = () => {
        setVisible(true);
      };

      const downloadHandler = (id: number) => {
        const _activeValue = processValueRef.current?.find(
          ({ id: _id }) => _id === id
        ) as IFile;
        const result = FileUploadService.fileDownload(
          _activeValue?.url as string,
          _activeValue?.name
        );
        if (!isFunction(onDownload)) return;
        if (result instanceof Promise) {
          result.catch(() => {
            onDownload(Promise.reject(FileUploadCode.DOWNLOAD_FAILED));
          });
        } else {
          onDownload(Promise.resolve());
        }
      };

      const actionHandler = (action: FileAction, id: number) => {
        const actionMap = new Map([
          [FileAction.PREVIEW, preViewHandler],
          [FileAction.DOWNLOAD, downloadHandler],
          [FileAction.DELETE, deleteHandler],
        ]);
        setActive(id);
        const handler = actionMap.get(action);
        isFunction(handler) && handler(id);
      };

      const filesView = React.useMemo(() => {
        return (
          <>
            {processValue?.map((file) => (
              <FileItem
                key={file?.id}
                file={file as IFile}
                deletable={deletable}
                downloadable={downloadable}
                previewAble={previewAble}
                onAction={actionHandler}
              />
            ))}
          </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [processValue, deletable, downloadable, previewAble, _value]);

      return (
        <div className={_className} ref={ref}>
          {uploadAble && !disabled && (
            <UploadItem
              className={uploadClassName}
              node={node}
              disabled={disabled}
              reminder={reminder}
              accept={accept}
              onUpload={uploadHandler}
              beforeUpload={beforeUploadHandler}
            />
          )}
          {filesView}
          {visible && (
            <Preview
              visible={visible}
              file={activeValue}
              pdf={pdf}
              image={image}
              word={word}
              onPreview={onPreview}
              onClose={() => setVisible(false)}
            />
          )}
        </div>
      );
    }
  )
);

const formatValue = (value: IFileUploadItem[]): IFileUploadItem[] => {
  if (!Array.isArray(value)) return [];
  return value.map((item, index) => ({ ...item, id: index }));
};
const combineProcessValues = (value: IFileUploadItem[]): IFile[] => {
  const processValues = FileUploadService.getProcessValues(value);
  return processValues.filter((item) => item) as IFile[];
};

export default FileUpload;
