import classNames from "classnames";
import React, { useCallback } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputBasicWrapperProps } from "../../../pages/components/InputBasicVariant/InputBasicVariant";
import { formatDate } from "../../../utils/commonUtils";
import { ErrorDetectorMediumView } from "../../ErrorDecorator/ErrorDecorator";
import {
  LabelIndicator,
  LabelIndicatorIcon,
} from "../../ViewWrapper/ViewWrapper";
import Calendar from "../../svg/Calendar";
import { InputBasic } from "../InputBasic/InputBasic";
import { DatePickerHeader } from "./DatePickerHeader/DatePickerHeader";
import styles from "./InputVariantDatePicker.module.scss";

export interface IInputVariantDatePickerProps
  extends Omit<ReactDatePickerProps, "value">,
    Omit<InputBasicWrapperProps, "onChange"> {
  label?: string;
  value?: Date;
  inputClassName?: string;
  mode?: "default" | "feature" | "past" | "all";
}

export interface ReactDatePickerHeaderProps
  extends ReactDatePickerCustomHeaderProps {
  maxDate?: Date;
  minDate?: Date;
}

export const InputVariantDatePicker: React.FC<IInputVariantDatePickerProps> =
  ErrorDetectorMediumView((props: IInputVariantDatePickerProps) => {
    const {
      value,
      inputClassName,
      disabled,
      maxDate,
      minDate,
      onChange,
      onSelect,
      ...rest
    } = props;

    const _inputClassName = classNames(styles.datePickerInput, inputClassName);

    const Header: ReactDatePickerProps["renderCustomHeader"] = useCallback(
      (props: ReactDatePickerHeaderProps) => (
        <DatePickerHeader
          headerProps={{
            ...props,
            maxDate: maxDate as Date,
            minDate: minDate as Date,
          }}
        />
      ),
      [maxDate, minDate]
    );
    const iconClassName = classNames(styles.icon, {
      [styles.disabled]: disabled,
    });
    const _value = formatDate(value as Date);
    const calenderIcon = (
      <span className={iconClassName}>
        <Calendar size="auto" />
      </span>
    );

    return (
      <div className={styles.datePickerRoot}>
        <ReactDatePicker
          {...rest}
          onChange={onChange}
          onSelect={onSelect}
          value={_value}
          selected={value}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          customInput={
            <InputBasic
              {...rest}
              addonBefore={calenderIcon}
              containerClassName={_inputClassName}
            />
          }
          popperClassName={styles.datePickerClassName}
          renderCustomHeader={Header}
          fixedHeight
        />
      </div>
    );
  });

InputVariantDatePicker.defaultProps = {
  mode: "default",
  indicator: LabelIndicator.TYPE_REQUIRED,
  indicatorSymbol: LabelIndicatorIcon.TYPE_STAR,
};
