import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import { SystemPlatform } from "../../../../core/SystemService";
import { StaticSourceItem } from "../../../../services/order.service";
import { ViewType } from "../../../components/ViewTypes";
import { RsmAction } from "../../../service/userRoleTypes";
import InfoSalesModel from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  CustomerTypes,
  IPartnerTeamOtherInfo,
  IPrepareInstallation,
  SaleTeam,
  ViewItemModel,
} from "../../model/OrderRequestModel";
import SiteSurveyRsmModel from "../SiteSurveyRsm/SiteSurveyRsmModel";
import SiteSurveyModel from "../model/SiteSurveyModel";

export default class SiteSurveyAllModel extends SiteSurveyModel {
  static getContactInfoModel = (
    infos: IPrepareInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { name, mobile } = infos?.contactPerson ?? {};
    const [finalGoodsReceipt, finalGoodsReceiptNew] =
      infos?.finalsGoodsRecip ?? [];
    const recipient = finalGoodsReceiptNew?.name
      ? finalGoodsReceiptNew
      : finalGoodsReceipt ?? {};
    const { name: receiptName, recipCity, recipAddress } = recipient;
    const indicator = finalGoodsReceiptNew?.name
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    const customerKey1 = `${InfoSalesModel.INSTALLATION_TASK_ID}_1`;

    const {
      labelContactPerson,
      labelContactMobile,
      labelRecipeCity,
      labelRecipeAddress,
      labelFinalGoodRecipe,
    } = InfoSalesModel.getLabels();

    return [
      {
        key: SiteSurveyRsmModel.FINAL_GOODS_RECIP,
        viewType: _viewType,
        props: {
          label: labelFinalGoodRecipe,
          children: receiptName,
          indicator,
        },
      },
      {
        key: InfoSalesModel.RECIPE_CITY,
        viewType: _viewType,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: InfoSalesModel.RECIPE_ADDRESS,
        viewType: _viewType,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: InfoSalesModel.CONTACT_NAME,
        viewType: _viewType,
        props: { label: labelContactPerson, children: name },
      },
      {
        key: InfoSalesModel.MOBILE_PHONE,
        viewType: _viewType,
        props: { label: labelContactMobile, children: mobile },
      },
    ];
  };

  static getOrgInfoModel = (
    infos: Partial<IPartnerTeamOtherInfo>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { saleTeam, region, customerType, salesEmployee } = infos ?? {};
    const { labelInfoSaleEmp, labelCustomerType, labelSaleTeam, labelRegin } =
      InfoSalesModel.getLabels();
    const customerTypeLabel = OrderRequestModel.getCustomerLabel(
        customerType as CustomerTypes
      ),
      saleTeamLabel = OrderRequestModel.getSaleTeamLabel(saleTeam as SaleTeam),
      serviceRegion = OrderRequestModel.getRegionLabel(
        orgOptions,
        region as number
      );
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    return [
      {
        key: InfoSalesModel.SALE_EMPLOYEE,
        viewType: _viewType,
        props: { label: labelInfoSaleEmp, children: salesEmployee },
      },
      {
        key: InfoSalesModel.CUSTOMER_TYPE,
        viewType: _viewType,
        props: { label: labelCustomerType, children: customerTypeLabel },
      },
      {
        key: InfoSalesModel.SALE_TEAM,
        viewType: _viewType,
        props: { label: labelSaleTeam, children: saleTeamLabel },
      },
      {
        key: InfoSalesModel.REGION,
        viewType: _viewType,
        props: { label: labelRegin, children: serviceRegion },
      },
    ];
  };

  static getButtonViewModel = (): Partial<ViewItemModel>[] => {
    const { labelAllTicket } = InfoSalesModel.getLabels();
    return [
      {
        key: RsmAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: RsmAction.ALL_TICKET,
        },
      },
    ];
  };
}
