import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import { ErrorDetectorSmallView } from "../../../components/ErrorDecorator/ErrorDecorator";
import { getCssVariable } from "../../../utils/commonUtils";
import styles from "./TextReminder.module.scss";

export enum ReminderType {
  INFO = "info",
  ERROR = "error",
  WARNING = "warning",
}

interface ITextReminder {
  label: string;
  reminderType?: ReminderType;
  className?: string;
}

const TextReminder: React.FC<ITextReminder> = ErrorDetectorSmallView(
  (props: ITextReminder) => {
    const { label, reminderType = ReminderType.INFO, className } = props;
    const [fill, setFill] = React.useState("");
    const _className = classNames(styles.textReminder, className);

    React.useEffect(() => {
      const error = getCssVariable("--color-functional-error-0"),
        warning = getCssVariable("--color-functional-warning-0"),
        info = getCssVariable("--color-interaction-link");

      const colorMap = new Map([
        [ReminderType.ERROR, error],
        [ReminderType.INFO, info],
        [ReminderType.WARNING, warning],
      ]);
      const fill = colorMap.get(reminderType) ?? info;
      setFill(fill as string);
    }, [reminderType]);

    return (
      <section className={_className}>
        <Icon
          size={12}
          icon={"StatusInfo"}
          fill={fill}
          className={styles.icon}
        />
        <span style={{ color: fill }} className={styles.label}>
          {label}
        </span>
      </section>
    );
  }
);

export default TextReminder;
