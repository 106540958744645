import { Icon } from "@zeiss/ods-components-react";
import classNames from "classnames";
import React from "react";
import SystemService, { SystemPlatform } from "../../core/SystemService";
import { isString } from "../../utils/isType";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../Modal/Modal";
import styles from "./FileUpload.module.scss";
import { FileType, IFile } from "./FileUploadService";
import {
  IPreviewImg,
  IPreviewPdf,
  IPreviewWord,
  PreviewImg,
  PreviewPdf,
  PreviewWord,
} from "./PreviewFile";
import { isWeComEnv } from "./util";

export interface IPreviewProps extends IModal {
  file: IFile;
  pdf?: IPreviewPdf;
  word?: IPreviewWord;
  image?: IPreviewImg;
  contentClassName?: string;
  onPreview?: (result: Promise<any>) => void;
}

const Preview: React.FC<IPreviewProps> = ErrorDetectorModalView(
  (props: IPreviewProps) => {
    const {
      file,
      className,
      pdf,
      word,
      image,
      visible,
      contentClassName,
      onClose,
      onPreview,
      ...rest
    } = props;
    const { file: _file, fileType, url } = file ?? {};
    const { current: isMobileWecom } = React.useRef(
      isWeComEnv() && SystemService.getPlatform() === SystemPlatform.ENV_MOBILE
    );
    const _url = React.useMemo(() => {
      if (isString(url)) return url;
      if (!(_file instanceof Blob)) return "";
      const fileUrl = URL.createObjectURL(_file);
      URL.revokeObjectURL(fileUrl);
      return fileUrl;
    }, [_file, url]);

    const _className = classNames(
      styles.modal,
      { [styles.img]: fileType === FileType.IMAGE },
      { [styles.word]: fileType === FileType.WORD },
      { [styles.pdf]: fileType === FileType.PDF },
      className
    );

    // React.useEffect(() => {
    //   if (!visible || !isWecom) return;
    //   openLink(_url);
    // }, [visible, _url, isWecom]);

    const view = React.useMemo(() => {
      const browserViewMap = new Map([
        [
          FileType.IMAGE,
          <PreviewImg
            {...image}
            src={_url}
            key={FileType.IMAGE}
            onPreview={onPreview}
          />,
        ],
        [
          FileType.PDF,
          <PreviewPdf
            {...pdf}
            file={url || _file}
            key={FileType.PDF}
            onPreview={onPreview}
          />,
        ],
        [
          FileType.WORD,
          <PreviewWord
            {...word}
            file={_file}
            url={url}
            key={FileType.IMAGE}
            onPreview={onPreview}
          />,
        ],
      ]);
      const _view = browserViewMap.get(fileType);
      const _contentClassName = classNames(
        styles.preViewContent,
        { [styles.img]: fileType === FileType.IMAGE },
        { [styles.word]: fileType === FileType.WORD },
        { [styles.pdf]: fileType === FileType.PDF },
        contentClassName
      );

      return <div className={_contentClassName}>{_view}</div>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, _url, pdf, url, _file, word, fileType, contentClassName]);

    const closeIcon = React.useMemo(() => {
      return (
        <span className={styles.closeIcon} onClick={onClose}>
          {<Icon icon="Close" size={20} />}
        </span>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isMobileWecom ? (
      <Modal
        {...rest}
        visible={visible}
        className={_className}
        closeIcon={closeIcon}
        closable={true}
        maskClosable={true}
        onClose={onClose}
        destroyOnClose={true}
      >
        {view}
      </Modal>
    ) : null;
  }
);

export default Preview;
