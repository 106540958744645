import { cloneDeep } from "lodash";
import { ITableColumn } from "../../../../components/Table/Table";
import HttpService, { NormalRes, Response } from "../../../../core/HttpService";
import {
  ILinkedOrderList,
  IOrderBasicInfo,
  ISplitOrderInfoRes,
  ISplitOrderReq,
  cancelUnSplitOrder,
  getLinkedOrderList,
  getSplitOrderBasicInfo,
  getSplitOrderInfo,
} from "../../../../services/order.service";
import { formatDate } from "../../../../utils/commonUtils";
import { isNumber } from "../../../../utils/isType";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { SalesAction } from "../../../service/userRoleTypes";
import { IMoreDetailItem, ViewItemModel } from "../../model/OrderRequestModel";
import InfoSalesModel, { ButtonMore } from "../InfoSales/InfoSalesModel";
import { splitOrder } from "./../../../../services/order.service";

export enum SplitType {
  Minus = 1,
  Plus = 2,
  Change = 3,
}

export enum ConfirmReminder {
  CancelOrder = 1,
  Submit = 2,
  DeleteSplit = 3,
}

export interface ISplitItem extends IMoreDetailItem {
  id?: string;
  index?: number;
  editable?: boolean;
  max?: number;
}

export interface ISplitOrder
  extends Omit<ISplitOrderInfoRes, "splitOrderProductItems"> {
  splitOrderProductItems: ISplitItem[][];
}

export interface ISplitSubOrder {
  installationTaskId: string;
  accessible: boolean;
  subsStandardOrder: string;
}

export default class SplitOrderModel {
  static readonly PARENT_INSTALLATION_TASK_ID = "parentInstallationTaskId";
  static readonly SPLIT_ITEMS = "splitItems";
  static readonly PRODUCT_ITEMS = "productItems";

  static getSplitInfo = (
    parentInstallationTaskId: string
  ): Promise<Response<ISplitOrder>> => {
    const result = HttpService.splitResponse(
      getSplitOrderInfo(parentInstallationTaskId)
    );

    return result.then((res) => ({
      ...res,
      data: {
        ...res.data,
        splitOrderProductItems: SplitOrderModel.convertSplitItems(
          res.data?.splitOrderProductItems
        ),
      },
    }));
  };

  static getSplitOrderBasicInfo = (
    parentInstallationTaskId: string
  ): Promise<Response<IOrderBasicInfo>> => {
    const result = HttpService.splitResponse(
      getSplitOrderBasicInfo(parentInstallationTaskId)
    );

    return result.then((res) => ({
      ...res,
      data: { ...res.data, poDate: formatDate(res.data?.poDate) },
    }));
  };

  static splitOrder = (data: ISplitOrderReq): Promise<NormalRes> => {
    const revertData = {
      ...data,
      splitOrderProductItems: SplitOrderModel.revertSplitItems(
        data?.splitOrderProductItems as ISplitItem[][]
      ),
    };
    return HttpService.splitResponse(splitOrder(revertData));
  };

  static cancelOrder = (
    parentInstallationTaskId: string
  ): Promise<NormalRes> => {
    return HttpService.splitResponse(
      cancelUnSplitOrder(parentInstallationTaskId)
    );
  };

  static getLinkedOrderList = (
    installationTaskId: string,
    userId: string
  ): Promise<Response<ISplitSubOrder[]>> => {
    const result = HttpService.splitResponse(
      getLinkedOrderList(installationTaskId)
    );

    return result.then((res) => ({
      ...res,
      data: SplitOrderModel.convertLinkOrderList(
        res.data,
        userId,
        installationTaskId
      ),
    }));
  };

  static getMainProductViewModel = (
    mainProduct: IMoreDetailItem
  ): Partial<ViewItemModel>[] => {
    const { labelMaterialNo, labelNumber } = InfoSalesModel.getLabels();
    const { itemOrderQuantity, itemMaterial } = mainProduct ?? {};
    return [
      {
        key: InfoSalesModel.ITEM_MATERIAL,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelMaterialNo, children: itemMaterial },
      },
      {
        key: InfoSalesModel.ITEM_ORDER_QUANTITY,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: { label: labelNumber, children: itemOrderQuantity },
      },
    ];
  };

  static getSubProductColumn = (): ITableColumn[] => {
    const { labelDeviceName, labelNumber, labelMaterialNo } =
      InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.ITEM_DESC,
        label: labelDeviceName,
      },
      {
        key: InfoSalesModel.ITEM_MATERIAL,
        label: labelMaterialNo,
      },
      {
        key: InfoSalesModel.ITEM_ORDER_QUANTITY,
        label: labelNumber,
      },
    ];
  };

  static getUnSplitOrderColumn = (): ITableColumn[] => {
    const { labelDeviceName, labelNumber, labelMaterialNo, labelOrderNumber } =
      InfoSalesModel.getLabels();
    return [
      {
        key: InfoSalesModel.ITEM_ORDER,
        label: labelOrderNumber,
      },
      {
        key: InfoSalesModel.ITEM_DESC,
        label: labelDeviceName,
      },
      {
        key: InfoSalesModel.ITEM_MATERIAL,
        label: labelMaterialNo,
      },
      {
        key: InfoSalesModel.ITEM_ORDER_QUANTITY,
        label: labelNumber,
      },
    ];
  };

  static getButtonViewMobileModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const {
      labelAllTicket,
      labelForward,
      labelCancelOrder,
      labelSubmit,
      labelMore,
    } = InfoSalesModel.getLabels();

    const original = [
      {
        key: SalesAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SalesAction.ALL_TICKET,
        },
      },
      {
        key: SalesAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON_MORE_VARIANT,
        props: {
          actions: [
            { value: ButtonMore.FORWARD, text: labelForward },
            { value: ButtonMore.CANCEL_ORDER, text: labelCancelOrder },
          ],
          children: labelMore,
          key: SalesAction.FORWARD,
        },
      },
      {
        key: SalesAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: SalesAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static getButtonViewPcModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelForward, labelCancelOrder, labelSubmit } =
      InfoSalesModel.getLabels();

    const original = [
      {
        key: SalesAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SalesAction.ALL_TICKET,
        },
      },
      {
        key: SalesAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelForward,
          key: SalesAction.FORWARD,
        },
      },
      {
        key: SalesAction.DELETE_ORDER,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelCancelOrder,
          key: SalesAction.DELETE_ORDER,
        },
      },
      {
        key: SalesAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: SalesAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static convertSplitItems = (list: IMoreDetailItem[][]): ISplitItem[][] => {
    if (!Array.isArray(list) || !list.length) return [];
    const _list = cloneDeep(list);
    return _list.map((item: IMoreDetailItem[], index) => {
      if (!Array.isArray(item)) return [];
      // currently, every _item will have one child.
      return item.map((_item: IMoreDetailItem) => ({
        ..._item,
        index: index + 1,
        editable: !_item.isMain,
      }));
    });
  };

  static revertSplitItems = (
    list: ISplitItem[][]
  ): { id: string; itemOrderQuantity: number }[][] => {
    if (!Array.isArray(list) || !list.length) return [];
    const _list = cloneDeep(list);
    return _list.map((item: ISplitItem[]) => {
      if (!Array.isArray(item)) return [];
      // currently, every _item will have one child.
      item.forEach((item) => !item.isMain && delete item.id);
      return item.map((_item: ISplitItem) => ({
        id: _item.id as string,
        itemOrderQuantity: _item.itemOrderQuantity,
      }));
    });
  };

  static enablePlusItem = (list: ISplitItem[][]): boolean => {
    if (!Array.isArray(list) || !list.length) return false;
    const flatted = list.flat().filter((item) => item);
    const { itemOrderQuantity } = flatted.find(({ isMain }) => isMain) ?? {};

    return (itemOrderQuantity as number) > 1;
  };

  static updateSplitItemMax = (
    total: number,
    list: ISplitItem[][]
  ): ISplitItem[][] => {
    if (!Array.isArray(list) || !list.length || !isNumber(total)) return [];
    const _list = cloneDeep(list);
    const flatted = _list.flat().filter((item) => item);
    const subTotal = flatted
      .filter(({ isMain }) => !isMain)
      .map(({ itemOrderQuantity }) => itemOrderQuantity)
      .reduce((pre, next) => pre + next, 0);
    const count = total - subTotal;
    return _list.map((_listItem: ISplitItem[]) => {
      if (!Array.isArray(_listItem)) return [];
      return _listItem.map((_item: ISplitItem) => ({
        ..._item,
        itemOrderQuantity: _item.isMain ? count : _item.itemOrderQuantity,
        editable: !_item.isMain,
        max: _item.itemOrderQuantity + count - 1,
      }));
    });
  };

  static convertLinkOrderList = (
    list: ILinkedOrderList,
    userId: string,
    currentTaskId: string,
    includeSelf: boolean = false
  ): ISplitSubOrder[] => {
    if (!Array.isArray(list) || !list.length) return [];
    const _list = list
      .map(({ installationTaskId, orderStepSummary, subsStandardOrder }) => {
        const { involvedUsers } = orderStepSummary;
        if (!Array.isArray(involvedUsers) || !involvedUsers.length)
          return null as any;
        const accessible = involvedUsers
          .map(({ userId }) => userId)
          .includes(userId);
        return {
          installationTaskId,
          accessible,
          subsStandardOrder,
        };
      })
      .filter((item) => item);
    const _filterList = !includeSelf
      ? _list.filter(
          ({ installationTaskId }) => installationTaskId !== currentTaskId
        )
      : _list;

    const enableList = _filterList.filter(({ accessible }) => accessible),
      disableList = _filterList.filter(({ accessible }) => !accessible);

    return enableList.concat(disableList);
  };

  static getOperationColumn = (): { key: string; label: string } => {
    const { labelOperate } = InfoSalesModel.getLabels();
    return {
      key: InfoSalesModel.OPERATION,
      label: labelOperate,
    };
  };
}
