import { useNotification } from "rc-notification";
import React from "react";
import { Outlet, useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import { SideNavigation } from "../../../components/SideNavigation/SideNavigation";
import { LocaleLang } from "../../../core/LocaleService";
import RouterService from "../../../core/RouterService";
import SystemService from "../../../core/SystemService";
import {
  localeState,
  notificationHandleState,
  userState,
} from "../../../store";
import { openLink } from "../../../utils/commonUtils";
import OrderListModel from "../../OrderList/OrderListModel";
import OrderListPcModel from "../../OrderList/OrderListPc/OrderListPcModel";
import DropDownVariant from "../../components/DropDownVariant/DropDownVriant";
import useLang from "../../hooks/useLang";
import { PagePaths } from "../../service/pageType";
import styles from "./HomePc.module.scss";
import HomePcModel from "./HomePcModel";

const motion = {
  motionName: "rc-notification-fade",
  motionAppear: true,
  motionEnter: true,
  motionLeave: true,
  onLeaveStart: (ele: Record<string, any>) => {
    const { offsetHeight } = ele;
    return { height: offsetHeight };
  },
  onLeaveActive: () => ({ height: 0, opacity: 0, margin: 0 }),
};

const HomePc: React.FC = () => {
  const { usersRole } = useRecoilValue(userState);
  const groups = HomePcModel.getSideNavGroups(usersRole);
  const navigate = useNavigate();
  const { title } = useLang({
    title: { id: "common_pc_wise_title" },
  });
  const defaultPath = RouterService.getRouterIndexPathByRole(usersRole);
  const [activePath, setPath] = React.useState<PagePaths>(() => {
    return defaultPath;
  });
  const [open, setOpen] = React.useState(
    () =>
      document.documentElement.clientWidth > SystemService.PLATFORM_PC_MIN_SIZE
  );
  const setNotificationHandle = useSetRecoilState(notificationHandleState);
  const locale = useRecoilValue(localeState);

  const [notice, contextHolder] = useNotification({
    motion,
    maxCount: 3,
    duration: 2,
  });
  const { pathname } = HomePcModel.getPagePath();

  React.useEffect(() => {
    setNotificationHandle(notice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notice]);

  React.useEffect(() => {
    window.addEventListener("resize", autoSetSideMenu, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const isDefaultPage = [PagePaths.PATH_DEFAULT, PagePaths.PATH_ANY].includes(
      pathname
    );
    const defaultPath = RouterService.getRouterIndexPathByRole(usersRole);
    const _path = isDefaultPage ? defaultPath : pathname;

    if (!isDefaultPage) return;
    navigate(_path, { replace: true });
  }, [navigate, pathname, usersRole]);

  const breadCrumbNodes = React.useMemo(() => {
    return HomePcModel.getRouteBreadCrumb(pathname as PagePaths, usersRole);
  }, [pathname, usersRole]);

  const navigateHandler = (path: string | number) => {
    const last = breadCrumbNodes.at(-1);
    if (path === PagePaths.PATH_DASHBOARD) {
      openLink(HomePcModel.DASHBOARD_OUT_LINK);
      return;
    }

    if (last?.value === path) return;

    setPath(path as PagePaths);
    navigate({
      pathname: path as PagePaths,
    });
  };

  const changeLang = React.useCallback((lang: string | number) => {
    OrderListModel.setLang(lang as LocaleLang);
  }, []);

  const autoSetSideMenu = React.useCallback(() => {
    const open =
      document.documentElement.clientWidth > SystemService.PLATFORM_PC_MIN_SIZE;
    setOpen(open);
  }, []);

  const headerSubItems = React.useMemo(() => {
    const options = OrderListPcModel.getLangOptions();
    return (
      <DropDownVariant options={options} value={locale} onChange={changeLang} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <section className={styles.homePc}>
      <HeaderBar
        title={title}
        onHomeClick={navigateHandler.bind(null, PagePaths.PATH_ORDER_LIST)}
        headerSubItems={headerSubItems}
      />
      <section className={styles.main}>
        <SideNavigation
          open={open}
          className={styles.sideNavigation}
          activePath={activePath}
          groups={groups}
          onChange={navigateHandler}
        />
        <div className={styles.content}>
          <BreadCrumb
            className={styles.breadCrumb}
            value={pathname}
            nodes={breadCrumbNodes}
            onChange={navigateHandler}
          />
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </section>
      {contextHolder}
    </section>
  );
};

export default HomePc;
