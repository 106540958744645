import { Icon } from "@zeiss/ods-components-react";
import React from "react";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import { IFinalGoodsReceipt } from "../../../../../model/OrderRequestModel";
import useSoldToParty from "../../../hooks/useSoldToParty";
import InfoSalesPcModel from "../../InfoSalesPcModel";
import styles from "./SoldToPartyView.module.scss";

export interface FinalGoodsRecipProps extends IFinalGoodsReceipt {}

export const SoldToPartyView: React.FC<FinalGoodsRecipProps> =
  ErrorDetectorMediumView((props: IFinalGoodsReceipt) => {
    const { title } = useSoldToParty();

    const contactNameView = React.useMemo(
      () => (
        <div className={styles.name}>
          <Icon icon={"Map"} size={16} className={styles.icon} />
          <span className={styles.nameLabel}>{props?.name}</span>
        </div>
      ),
      [props?.name]
    );

    const infoView = React.useMemo(() => {
      const infos = InfoSalesPcModel.getPartnerItem(props);

      return infos?.map(({ key, viewType, props }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{ ...props, key, className: styles.infoItem }}
        />
      ));
    }, [props]);

    return (
      <div className={styles.soldToPartyView}>
        <div className={styles.title}>{title}</div>
        <div className={styles.personInfoView}>{infoView}</div>
      </div>
    );
  });
