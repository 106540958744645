import PageWrapper from "../../../../layout/PageWrapper";
import InstallationSalesMobile from "./InstallationSalesMobile";
import InstallationSalesPc from "./InstallationSalesPc";

export default () => (
  <PageWrapper
    PagePc={InstallationSalesPc}
    PageMobile={InstallationSalesMobile}
  />
);
