import { Button } from "@zeiss/ods-components-react";
import React from "react";
import Confirmor from "../../../../components/Confirmor/Confirmor";
import { ErrorDetectorPageView } from "../../../../components/ErrorDecorator/ErrorDecorator";
import RejectModalVariant from "../../../components/RejectModalVariant/RejectModalVariant";
import { ViewComponent, ViewType } from "../../../components/ViewTypes";
import { FseAction } from "../../../service/userRoleTypes";
import OrderInfoDnView from "../../OrderInfo/InfoSales/InfoSalesMobile/components/OrderInfoDnView/OrderInfoDnView";
import CommentsView from "../../components/CommentView/CommentsView";
import { IDNInfo, IFile } from "../../model/OrderRequestModel";
import InstallationModel, {
  IInstallation,
  WarrantyPeriod,
} from "../installationModel";
import styles from "./InstallationFse.module.scss";
import InstallationFseModel from "./InstallationFseModel";
import useInstallationFse from "./useInstallationFse";

const InstallationFseMobile: React.FC = ErrorDetectorPageView(() => {
  const {
    infos,
    rolePage,
    systemReginOrg,
    labelCancel,
    buttonBlock,
    labelConfirm,
    submitEnable,
    rejectVisible,
    rejectTitle,
    submitVisible,
    labelSubmitReminder,
    rejectHandler,
    setReject,
    setSubmit,
    confirmHandler,
    changeHandler,
    backAllTickHandler,
  } = useInstallationFse();

  const installationInfoView = React.useMemo(() => {
    const { warrantyPeriod, otherWarrantyPeriod } = infos;
    const list = InstallationFseModel.getInfoViewMobileModel(
      infos as Partial<IInstallation>,
      systemReginOrg,
      rolePage?.pageStatus
    );
    const datePickKeys = [
      InstallationModel.ACTUAL_INSTALL_END_DATE,
      InstallationModel.ACTUAL_INSTALL_START_DATE,
    ];
    const views = list.map(({ viewType, props, key }) => {
      const isDatePicker = datePickKeys.some((item) => item === key);
      const _props = isDatePicker
        ? {
            ...props,
            onConfirm: (value: Date) =>
              changeHandler(key as keyof IInstallation, value?.getTime()),
            variantClassName:
              key === InstallationModel.ACTUAL_INSTALL_START_DATE
                ? styles.actualInstallStartDate
                : styles.actualInstallEndDate,
          }
        : key === InstallationModel.PLANNED_INSTALL_END_DATE
        ? {
            ...props,
            className: styles.planedEndTime,
          }
        : props;

      return (
        <ViewComponent key={key} type={viewType as ViewType} props={_props} />
      );
    });
    const warrantyPeriodReminder =
      warrantyPeriod !== WarrantyPeriod.OTHER
        ? InstallationModel.getReminderByWarranty(
            warrantyPeriod as WarrantyPeriod
          )
        : otherWarrantyPeriod;

    return (
      <div className={styles.installationInfoView}>
        {views}
        {!!warrantyPeriod && (
          <div className={styles.warrantyPeriodReminder}>
            {warrantyPeriodReminder}
          </div>
        )}
      </div>
    );
  }, [infos, rolePage?.pageStatus, systemReginOrg, changeHandler]);

  const filesView = React.useMemo(() => {
    const list = InstallationFseModel.getFileInfoViewModel(
      infos as IInstallation,
      rolePage?.pageStatus
    );
    const views = list.map(({ viewType, key, props }) => {
      const isUploadEdit = key === InstallationModel.INSTALLATION_REPORTS;
      const _props = isUploadEdit
        ? { ...props, onChange: (value: IFile[]) => changeHandler(key, value) }
        : props;

      return (
        <div className={styles.uploadItem} key={key}>
          <ViewComponent type={viewType as ViewType} props={_props} />
        </div>
      );
    });

    return <div className={styles.filesView}>{views}</div>;
  }, [infos, rolePage?.pageStatus, changeHandler]);

  const confirmButtonView = React.useMemo(
    () => (
      <div className={styles.confirmButtons}>
        <Button
          className={styles.confirmBtn}
          variant="secondary"
          onClick={confirmHandler.bind(null, "cancel")}
        >
          {labelCancel}
        </Button>
        <Button
          className={styles.confirmBtn}
          variant="primary"
          onClick={confirmHandler.bind(null, "ok")}
          disabled={buttonBlock}
        >
          {labelConfirm}
        </Button>
      </div>
    ),
    [labelCancel, buttonBlock, labelConfirm, confirmHandler]
  );

  const buttonsView = React.useMemo(() => {
    const list = InstallationFseModel.getButtonViewModel(rolePage);
    const actionHandler = new Map<FseAction, Record<string, any>>([
      [FseAction.ALL_TICKET, { onClick: backAllTickHandler }],
      [FseAction.REJECT, { onClick: () => setReject(true) }],
      [FseAction.SUBMIT, { onClick: () => setSubmit(true) }],
    ]);
    const { length } = list;
    const style = {
      width: `calc((100% - ${(8 / 37.5) * (length - 1)}rem) / ${length})`,
    };
    const views = list.map(({ viewType, props, key }) => {
      const view = (
        <ViewComponent
          type={viewType as ViewType}
          props={{
            ...props,
            ...actionHandler.get(props?.key),
            className: styles.btn,
            disabled: key === FseAction.SUBMIT ? !submitEnable : false,
          }}
        />
      );

      return (
        <div style={style} key={key}>
          {view}
        </div>
      );
    });

    return <div className={styles.buttons}>{views}</div>;
  }, [rolePage, submitEnable, backAllTickHandler, setReject, setSubmit]);

  return (
    <section className={styles.installationFse}>
      <div className={styles.pageContainer}>
        {installationInfoView}
        <OrderInfoDnView {...(infos.dnInfo as IDNInfo)} />
        {filesView}
        <CommentsView />
      </div>
      {buttonsView}
      {rejectVisible && (
        <RejectModalVariant
          title={rejectTitle}
          visible={rejectVisible}
          onConfirm={rejectHandler}
        />
      )}
      <Confirmor
        footer={confirmButtonView}
        visible={submitVisible}
        content={labelSubmitReminder}
      />
    </section>
  );
});

export default InstallationFseMobile;
