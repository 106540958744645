export enum ErrorCode {
  SUCCESS = 200,
  COMMON_ERROR = -1,
  SERVER_ERROR = 502,
  PATH_NOT_FOUND = 404,
}

export enum PageError {
  ERROR_403 = "PAGE_403",
  ERROR_404 = "PAGE_404",
  ERROR_502 = "PAGE_502",
  ERROR_500 = "PAGE_500",
}

export default class ErrorCodeService {
  static getErrorMsgKey = (code: ErrorCode): string => {
    const keyMap = new Map([
      [ErrorCode.SUCCESS, "common_msg_success"],
      [ErrorCode.COMMON_ERROR, "common_msg_error"],
      [ErrorCode.SERVER_ERROR, "common_msg_server_error"],
      [ErrorCode.PATH_NOT_FOUND, "common_msg_path_not_found"],
    ]);

    return "common_inner_error";
  };
}
