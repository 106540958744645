import { clear, config, show } from "./Method";

export type { ToastHandler, ToastShowProps } from "./Method";

// more info, please find in https://mobile.ant.design/zh/components/toast
const Toast = {
  show,
  clear,
  config,
};

export default Toast;
