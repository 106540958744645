import { LabelIndicator } from "../../../../components/ViewWrapper/ViewWrapper";
import { SystemPlatform } from "../../../../core/SystemService";
import { StaticSourceItem } from "../../../../services/order.service";
import { ViewType } from "../../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../../service/pageType";
import { RsmAction } from "../../../service/userRoleTypes";
import {
  default as InfoSalesModel,
  default as OrderInfoModel,
} from "../../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  CustomerTypes,
  IPartnerTeamOtherInfo,
  IPrepareInstallation,
  SaleTeam,
  ViewItemModel,
} from "../../model/OrderRequestModel";

export default class SiteSurveyRsmModel {
  static readonly SALE_EMPLOYEE = "saleEmployee";
  static readonly SALE_MANAGER = "saleManager";
  static readonly SERVICE_COORDINATOR = "serviceCoordinator";
  static readonly FSE = "fse";
  static readonly SERVICE_SUPERVISOR = "serviceSupervisor";
  static readonly AP_SUPERVISOR = "apSupervisor";
  static readonly AP_ENGINEER = "apEngineer";
  static readonly INCOTERMS = "incoterms";
  static readonly SALE_TEAM = "saleTeam";
  static readonly CUSTOMER_TYPE = "customerType";
  static readonly REGION = "region";
  static readonly PRODUCT_LINE = "productLine";
  static readonly FINAL_GOODS_RECIP = "finalGoodsRecip";

  static getOrgInfoModel = (
    infos: Partial<IPartnerTeamOtherInfo>,
    orgOptions: {
      apSupervisor: StaticSourceItem[];
      regionProductLine: Partial<StaticSourceItem>[];
    },
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { saleTeam, region, customerType, salesEmployee } = infos ?? {};
    const { labelInfoSaleEmp, labelCustomerType, labelSaleTeam, labelRegin } =
      OrderInfoModel.getLabels();
    const customerTypeLabel = OrderRequestModel.getCustomerLabel(
        customerType as CustomerTypes
      ),
      saleTeamLabel = OrderRequestModel.getSaleTeamLabel(saleTeam as SaleTeam),
      serviceRegion = OrderRequestModel.getRegionLabel(
        orgOptions,
        region as number
      );
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;
    return [
      {
        key: OrderInfoModel.SALE_EMPLOYEE,
        viewType: _viewType,
        props: { label: labelInfoSaleEmp, children: salesEmployee },
      },
      {
        key: OrderInfoModel.CUSTOMER_TYPE,
        viewType: _viewType,
        props: { label: labelCustomerType, children: customerTypeLabel },
      },
      {
        key: OrderInfoModel.SALE_TEAM,
        viewType: _viewType,
        props: { label: labelSaleTeam, children: saleTeamLabel },
      },
      {
        key: OrderInfoModel.REGION,
        viewType: _viewType,
        props: { label: labelRegin, children: serviceRegion },
      },
    ];
  };

  static getContactInfoModel = (
    infos: IPrepareInstallation,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { name, mobile } = infos?.contactPerson ?? {};
    const [finalGoodsReceipt, finalGoodsReceiptNew] =
      infos?.finalsGoodsRecip ?? [];
    const recipient = finalGoodsReceiptNew?.name
      ? finalGoodsReceiptNew
      : finalGoodsReceipt ?? {};
    const indicator = finalGoodsReceiptNew?.name
      ? LabelIndicator.TYPE_REQUIRED
      : LabelIndicator.TYPE_OPTIONAL;
    const { name: receiptName, recipCity, recipAddress } = recipient;
    const _viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const {
      labelContactPerson,
      labelContactMobile,
      labelRecipeCity,
      labelRecipeAddress,
      labelFinalGoodRecipe,
    } = InfoSalesModel.getLabels();
    const customerKey1 = `${InfoSalesModel.INSTALLATION_TASK_ID}_1`;

    return [
      {
        key: SiteSurveyRsmModel.FINAL_GOODS_RECIP,
        viewType: _viewType,
        props: {
          label: labelFinalGoodRecipe,
          children: receiptName,
          indicator,
        },
      },
      {
        key: OrderInfoModel.RECIPE_CITY,
        viewType: _viewType,
        props: { label: labelRecipeCity, children: recipCity },
      },
      {
        key: OrderInfoModel.RECIPE_ADDRESS,
        viewType: _viewType,
        props: { label: labelRecipeAddress, children: recipAddress },
      },
      {
        key: customerKey1,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: undefined,
          children: undefined,
          key: customerKey1,
        },
      },
      {
        key: OrderInfoModel.CONTACT_NAME,
        viewType: _viewType,
        props: { label: labelContactPerson, children: name },
      },
      {
        key: OrderInfoModel.MOBILE_PHONE,
        viewType: _viewType,
        props: { label: labelContactMobile, children: mobile },
      },
    ];
  };

  static getButtonViewModel = (rolePage: SubTabs): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelAssign, labelForward } =
      OrderInfoModel.getLabels();

    const original = [
      {
        key: RsmAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: RsmAction.ALL_TICKET,
        },
      },
      {
        key: RsmAction.FORWARD,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelForward,
          key: RsmAction.FORWARD,
        },
      },
      {
        key: RsmAction.ASSIGN,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelAssign,
          key: RsmAction.ASSIGN,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };
}
