import PageWrapper from "../../layout/PageWrapper";
import OrderListMobile from "./OrderListMobile/OrderListMobile";
import OrderListPc, { PcListViewType } from "./OrderListPc/OrderListPc";

export default () => (
  <PageWrapper
    PagePc={() => <OrderListPc viewType={PcListViewType.Basic} />}
    PageMobile={OrderListMobile}
  />
);
