import { isString } from "../../utils/isType";
import { FileType } from "./Upload";

export const identifyFileType = (file: File | Blob) => {
  if (!(file instanceof File) || !(file instanceof Blob)) return FileType.OTHER;
  const { type } = file;
  if (type.includes(FileType.IMAGE)) return FileType.IMAGE;
  if (type.includes(FileType.PDF)) return FileType.PDF;
  if (type.includes(FileType.EXCEL)) return FileType.EXCEL;
  if (type.includes(FileType.WORD)) return FileType.WORD;
  return FileType.OTHER;
};

export const identifyFileTypeByName = (fileName: string) => {
  if (!isString(fileName)) return FileType.OTHER;
  const lowerCase = fileName.toLowerCase();
  if (lowerCase.includes("doc")) return FileType.WORD;
  if (lowerCase.includes("xlsx")) return FileType.EXCEL;
  if (lowerCase.includes("pdf")) return FileType.PDF;
  if (["png", "jpg", "jpeg"].some((item) => lowerCase.includes(item)))
    return FileType.IMAGE;
  return FileType.OTHER;
};
