import HttpService from "../../../core/HttpService";
import { formatMessage } from "../../../core/LangService";
import { LocaleLang } from "../../../core/LocaleService";
import {
  IInstallationRes,
  StaticSourceItem,
  getInstallationInfo,
  getInstallationSummaryInfo,
  getTrainingInfo,
} from "../../../services/order.service";
import OrderRequestModel, {
  CustomerTypes,
  IDNInfo,
  IFile,
  ProductLine,
} from "../model/OrderRequestModel";

export enum WarrantyPeriod {
  MONTH_12 = 1,
  DELIVERY_MONTH_15 = 2,
  OTHER = 3,
}

export interface IInstallation {
  installationTaskId?: string;
  expectedInstallDate: string | number;
  isSiteSurveyDone: boolean;
  isSiteSurvey: boolean;
  trainingRequired: boolean;
  facRequired: boolean;
  apSupervisorId: string;
  apSupervisorCN: string;
  apSupervisorEN: string;
  saleTeam: string;
  productLine: ProductLine;
  customerType: CustomerTypes;
  salesEmployeeNameCN: string;
  salesEmployeeNameEN: string;
  salesEmployee: string;
  rsmNameCN: string;
  rsmNameEN: string;
  rsm: string;
  ownerRsmNameCN: string;
  ownerRsmNameEN: string;
  ownerRsm: string;
  facUploaderNameCN: string;
  facUploaderNameEN: string;
  facUploader: string; // all installation page need this label , changed in 2023.8.22
  rsmInstallationCN: string;
  rsmInstallationEN: string;
  rsmInstallation: string;
  rsmSiteSurveyCN: string;
  rsmSiteSurveyEN: string;
  rsmSiteSurvey: string;
  finalGoodsReceiptName: string;
  isFinalGoodsRecipNew: boolean;
  finalGoodsRecipAddress: string;
  region: number;
  contactPersonNameEN: string; // *
  contactPersonNameCN: string; // *
  contactPersonMobile: string;
  contactPersonEmail?: string;
  warrantyPeriod: WarrantyPeriod;
  warrantyPeriodLabel: string;
  otherWarrantyPeriod: string;
  planedInstallationStartDate: number | string;
  planedInstallationEndDate: number | string;
  disclaimers?: IFile[];
  deliveryNotes: IFile[];
  euCs?: IFile[];
  installationReports: IFile[];
  technicalAgreements?: IFile[];
  specifications?: IFile[];
  apSupervisor: string;
  actualInstallStartDate: number;
  actualInstallEndDate: number;
  actualTrainingStartDate: number;
  actualTrainingEndDate: number;
  planedTrainingStartDate: number | string;
  planedTrainingEndDate: number | string;
  trainingReports?: IFile[];
  siteSurveyReports?: IFile[];
  actuarySiteSurveyDate?: number;
  dnInfo: IDNInfo;
  dnDelivered: boolean;
  ownerInstallationFse: string;
  ownerInstallationFseName?: string;
  ownerInstallationFseNameCN: string;
  ownerInstallationFseNameEN: string;
  auditEmails?: IFile[];
}

export default class InstallationModel {
  static readonly EXPECTED_INSTALL_DATA = "expectedInstallDate";
  static readonly IS_SITE_SURVEY_DONE = "isSiteSurveyDone";
  static readonly SITE_SURVEY_REQUIRED = "siteSurveyRequired";
  static readonly TRAINING_REQUIRED = "trainingRequired";
  static readonly FAC_REQUIRED = "facRequired";
  static readonly FAC_UPLOADER = "facUploader";
  static readonly AP_SUPERVISOR = "apSupervisor";
  static readonly SALES_TEAM_LABEL = "saleTeamLabel";
  static readonly REGION_LABEL = "regionLabel";
  static readonly PRODUCT_LINE_LABEL = "productLineLabel";
  static readonly SALE_EMPLOYEE = "saleEmployee";
  static readonly OWNER_RSM = "ownerRsm";
  static readonly RSM = "rsm";
  static readonly RSM_SITE_SURVEY = "rsmSiteSurvey";
  static readonly RSM_INSTALLATION = "rsmInstallation";
  static readonly FINAL_GOODS_RECEIPT_NAME = "finalGoodsReceiptName";
  static readonly RECEIPT_ADDRESS = "receiptAddress";
  static readonly CONTACT_PERSON_NAME = "contactPersonName";
  static readonly CONTACT_PERSON_MOBILE = "contactPersonMobile";
  static readonly CONTACT_PERSON_EMAIL = "contactPersonEmail";
  static readonly WARRANTY_PERIOD = "warrantyPeriod";
  static readonly WARRANTY_PERIOD_LABEL = "warrantyPeriodLabel";
  static readonly WARRANTY_PERIOD_COMMENTS = "otherWarrantyPeriod";
  static readonly PLANNED_INSTALL_START_DATE = "planedInstallationStartDate";
  static readonly PLANNED_INSTALL_END_DATE = "planedInstallationEndDate";
  static readonly AUDIT_EMAIL = "auditEmails";
  static readonly OWNER_INSTALLATION_FSE = "ownerInstallationFse";
  static readonly DISCLAIMERS = "disclaimers";
  static readonly DELIVERY_NOTES = "deliveryNotes";
  static readonly EUC_EUU = "euCs";
  static readonly COMMENTS = "comments";
  static readonly INSTALLATION_REPORTS = "installationReports";
  static readonly TECHNICAL_AGR = "technicalAgreements";
  static readonly SPEC = "specifications";
  static readonly ACTUAL_INSTALL_START_DATE = "actualInstallStartDate";
  static readonly ACTUAL_INSTALL_END_DATE = "actualInstallEndDate";
  static readonly ACTUAL_TRAINING_END_DATE = "actualTrainingEndDate";
  static readonly ACTUAL_TRAINING_START_DATE = "actualTrainingStartDate";
  static readonly TRAINING_REPORTS = "trainingReports";
  static readonly SITE_SURVEY_REPORT = "siteSurveyReports";
  static readonly PLAN_TRAINING_START_DATE = "planedTrainingStartDate";
  static readonly PLAN_TRAINING_END_DATE = "planedTrainingEndDate";
  static readonly INSTALLATION_TASK_ID = "installationTaskId";

  static getInstallationInfo = (
    installationTaskId: string,
    locale?: LocaleLang
  ): Promise<IInstallationRes> => {
    const infos = HttpService.splitResponse(
      getInstallationInfo(installationTaskId)
    );

    return infos.then((res) => ({
      ...res?.data,
      data: {
        ...res?.data,
        disclaimers: OrderRequestModel.formatFile(res?.data?.disclaimers),
        euCs: OrderRequestModel.formatFile(res?.data?.euCs),
        installationReports: OrderRequestModel.formatFile(
          res?.data?.installationReports
        ),
        technicalAgreements: OrderRequestModel.formatFile(
          res?.data?.technicalAgreements
        ),
        specifications: OrderRequestModel.formatFile(res?.data?.specifications),
        deliveryNotes: OrderRequestModel.formatFile(res?.data?.deliveryNotes),
        siteSurveyReports: OrderRequestModel.formatFile(
          res?.data?.siteSurveyReports
        ),
        auditEmails: OrderRequestModel.formatFile(res?.data?.auditEmails),
        rsm: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmNameCN,
          res?.data?.rsmNameEN,
          locale
        ),
        ownerRsm: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerRsmNameCN,
          res?.data?.ownerRsmNameEN,
          locale
        ),
        rsmSiteSurvey: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmSiteSurveyCN,
          res?.data?.rsmSiteSurveyEN,
          locale
        ),
        rsmInstallation: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmInstallationCN,
          res?.data?.rsmInstallationEN,
          locale
        ),
        facUploader: OrderRequestModel.formatNameByLocale(
          res?.data?.facUploaderNameCN,
          res?.data?.facUploaderNameEN,
          locale
        ),
        salesEmployee: OrderRequestModel.formatNameByLocale(
          res?.data?.salesEmployeeNameCN,
          res?.data?.salesEmployeeNameEN,
          locale
        ),
        ownerInstallationFseName: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerInstallationFseNameCN,
          res?.data?.ownerInstallationFseNameEN,
          locale
        ),
      },
    }));
  };

  static getTrainingInfo = (
    installationTaskId: string,
    locale?: LocaleLang
  ): Promise<IInstallationRes> => {
    const infos = HttpService.splitResponse(
      getTrainingInfo(installationTaskId)
    );

    return infos.then((res) => ({
      ...res?.data,
      data: {
        ...res?.data,
        disclaimers: OrderRequestModel.formatFile(res?.data?.disclaimers),
        euCs: OrderRequestModel.formatFile(res?.data?.euCs),
        installationReports: OrderRequestModel.formatFile(
          res?.data?.installationReports
        ),
        technicalAgreements: OrderRequestModel.formatFile(
          res?.data?.technicalAgreements
        ),
        specifications: OrderRequestModel.formatFile(res?.data?.specifications),
        deliveryNotes: OrderRequestModel.formatFile(res?.data?.deliveryNotes),
        trainingReports: OrderRequestModel.formatFile(
          res?.data?.trainingReports
        ),
        siteSurveyReports: OrderRequestModel.formatFile(
          res?.data?.siteSurveyReports
        ),
        ownerRsm: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerRsmNameCN,
          res?.data?.ownerRsmNameEN,
          locale
        ),
        rsmInstallation: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmInstallationCN,
          res?.data?.rsmInstallationEN,
          locale
        ),
        rsmSiteSurvey: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmSiteSurveyCN,
          res?.data?.rsmSiteSurveyEN,
          locale
        ),
        facUploader: OrderRequestModel.formatNameByLocale(
          res?.data?.facUploaderNameCN,
          res?.data?.facUploaderNameEN,
          locale
        ),
      },
    }));
  };

  static getInstallationSummaryInfo = (
    installationTaskId: string,
    locale?: LocaleLang
  ): Promise<IInstallationRes> => {
    const infos = HttpService.splitResponse(
      getInstallationSummaryInfo(installationTaskId)
    );

    return infos.then((res) => ({
      ...res?.data,
      data: {
        ...res?.data,
        disclaimers: OrderRequestModel.formatFile(res?.data?.disclaimers),
        euCs: OrderRequestModel.formatFile(res?.data?.euCs),
        installationReports: OrderRequestModel.formatFile(
          res?.data?.installationReports
        ),
        technicalAgreements: OrderRequestModel.formatFile(
          res?.data?.technicalAgreements
        ),
        specifications: OrderRequestModel.formatFile(res?.data?.specifications),
        deliveryNotes: OrderRequestModel.formatFile(res?.data?.deliveryNotes),
        trainingReports: OrderRequestModel.formatFile(
          res?.data?.trainingReports
        ),
        siteSurveyReports: OrderRequestModel.formatFile(
          res?.data?.siteSurveyReports
        ),
        auditEmails: OrderRequestModel.formatFile(res?.data?.auditEmails),
        ownerRsm: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerRsmNameCN,
          res?.data?.ownerRsmNameEN,
          locale
        ),
        rsmInstallation: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmInstallationCN,
          res?.data?.rsmInstallationEN,
          locale
        ),
        rsmSiteSurvey: OrderRequestModel.formatNameByLocale(
          res?.data?.rsmSiteSurveyCN,
          res?.data?.rsmSiteSurveyEN,
          locale
        ),
        facUploader: OrderRequestModel.formatNameByLocale(
          res?.data?.facUploaderNameCN,
          res?.data?.facUploaderNameEN,
          locale
        ),
        ownerInstallationFseName: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerInstallationFseNameCN,
          res?.data?.ownerInstallationFseNameEN,
          locale
        ),
      },
    }));
  };

  static getWarrantyPeriodOptions = (): StaticSourceItem[] => {
    const {
      labelWarrantyOptions12Months,
      labelWarrantyOptionsDeliveryIn15Month,
      labelOther,
    } = InstallationModel.getLabels();
    return [
      {
        value: WarrantyPeriod.MONTH_12,
        text: labelWarrantyOptions12Months,
      },
      {
        value: WarrantyPeriod.DELIVERY_MONTH_15,
        text: labelWarrantyOptionsDeliveryIn15Month,
      },
      {
        value: WarrantyPeriod.OTHER,
        text: labelOther,
      },
    ];
  };

  static getReminderByWarranty = (warranty: WarrantyPeriod): string => {
    const { labelWarrantyPeriod12Reminder, labelWarrantyPeriod15Reminder } =
      InstallationModel.getLabels();

    const reminderMaps = new Map([
      [WarrantyPeriod.MONTH_12, labelWarrantyPeriod12Reminder],
      [WarrantyPeriod.DELIVERY_MONTH_15, labelWarrantyPeriod15Reminder],
    ]);
    return reminderMaps.get(warranty) ?? "";
  };

  static getWarrantyPeriodLabel = (warrantyPeriod: WarrantyPeriod): string => {
    return (
      InstallationModel.getWarrantyPeriodOptions().find(
        ({ value }) => value === warrantyPeriod
      )?.text ?? ""
    );
  };

  static getLabels = () => {
    return {
      labelExpectedInstallDate: formatMessage("order_install_expected_data"),
      labelSiteSurveyQualified: formatMessage("order_site_survey_qualified"),
      labelFacRequired: formatMessage("order_install_fac_required"),
      labelFacUploader: formatMessage("order_install_fac_uploader"),
      labelTrainRequired: formatMessage("order_install_train_required"),
      labelTrainingRequired: formatMessage("order_install_train_required"),
      labelApSupervisor: formatMessage("order_ap_supervisor"),
      labelInfoSaleEmp: formatMessage("order_info_sale_emp"),
      labelSaleTeam: formatMessage("order_info_sale_team"),
      labelRegin: formatMessage("order_info_region"),
      labelProductLine: formatMessage("order_info_product_line"),
      labelServiceSupervisor: formatMessage("order_info_service_supervisor"),
      labelName: formatMessage("order_contact_company_name"),
      labelRecipeAddress: formatMessage("order_contact_recipe_address"),
      labelContactPerson: formatMessage("order_contact_person"),
      labelContactMobile: formatMessage("order_contact_mobile"),
      labelContactEmailOptional: formatMessage("order_contact_email_optional"),
      labelWarrantyPeriod: formatMessage("order_install_warranty_period"),
      labelEurOrEuu: formatMessage("order_upload_euc_euu"),
      labelDeliverNotes: formatMessage("order_delivery_notes"),
      labelTechAgr: formatMessage("order_install_tech_agr"),
      labelSpecs: formatMessage("order_install_specs"),
      labelTrainReports: formatMessage("order_train_reports"),
      labelActualInstallStartDate: formatMessage(
        "order_actual_install_start_date"
      ),
      labelActualInstallEndDate: formatMessage("order_actual_install_end_date"),
      labelWarrantyPeriod12Reminder: formatMessage(
        "order_install_warranty_period_12_reminder"
      ),
      labelWarrantyPeriod15Reminder: formatMessage(
        "order_install_warranty_period_15_reminder"
      ),
      labelWarrantyOptions12Months: formatMessage(
        "order_install_warranty_12_months"
      ),
      labelWarrantyOptionsDeliveryIn15Month: formatMessage(
        "order_install_warranty_delivery_in_15_months"
      ),
      labelOther: formatMessage("common_others"),
      labelPlanedInstallationStartDate: formatMessage(
        "order_planed_installation_start_date"
      ),
      labelPlanedInstallationEndDate: formatMessage(
        "order_planed_installation_end_date"
      ),
      labelInstallationReport: formatMessage("order_installation_reports"),
      labelTrainingReport: formatMessage("order_training_report"),
      labelSiteSurveyReport: formatMessage("order_site_survey_report"),
      labelDisclaimer: formatMessage("order_upload_disclaimer"),
      labelPlanTrainingStartTime: formatMessage(
        "order_plan_training_start_time"
      ),
      labelPlanTrainingEndTime: formatMessage("order_plan_training_end_time"),
      labelActualTrainingEndTime: formatMessage(
        "order_actual_training_end_time"
      ),
      labelActualTrainingStartTime: formatMessage(
        "order_actual_training_start_time"
      ),
      uploadReminder: formatMessage("order_upload_file_reminder"),
      labelDeliveryNoteOptional: formatMessage("order_delivery_note_optional"),
      labelSiteSurveyRequired: formatMessage("order_site_survey_required"),
      labelRsmSiteSurvey: formatMessage("order_rsm_site_survey"),
      labelRsmInstallation: formatMessage("order_rsm_installation"),
      labelOwnerRsm: formatMessage("order_rsm_shorter"),
      labelDisclaimerNotice: formatMessage("order_disclaimer_notice"),
      labelAuditEmail: formatMessage("order_audit_email"),
      labelAuditEmailReminder: formatMessage("order_audit_email_reminder"),
      labelOwnerInstallationFse: formatMessage("order_owner_installation_fse"),
      labelDisclaimerNotSiteSurvey: formatMessage(
        "order_disclaimer_not_site_survey"
      ),
      labelFinalGoodsRecip: formatMessage("order_final_goods_recipe"),
    };
  };
}
