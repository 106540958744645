import { Button, Icon } from "@zeiss/ods-components-react";
import React from "react";
import { useRecoilValue } from "recoil";
import { ErrorDetectorMediumView } from "../../../../../../../components/ErrorDecorator/ErrorDecorator";
import PhoneCall from "../../../../../../../components/PhoneCall/PhoneCall";
import Person from "../../../../../../../components/svg/Person";
import { localeState } from "../../../../../../../store";
import TextReminder, {
  ReminderType,
} from "../../../../../../components/TextReminder/TextReminder";
import {
  ViewComponent,
  ViewType,
} from "../../../../../../components/ViewTypes";
import ModifyContact from "../../../../../components/ModifyContact/ModifyContact";
import { IFinalGoodsReceipt } from "../../../../../model/OrderRequestModel";
import OrderInfoModel, {
  ContactPersonStatus,
  FinalGoodsRecipStatus,
} from "../../../InfoSalesModel";
import { FinalGoodsRecipProps } from "../../../InfoSalesPc/components/SoldToPartyView/SoldToPartyView";
import useContactPerson, {
  ContactPersonProps,
} from "../../../hooks/useContactPerson";
import useFinalGoodsRecip from "../../../hooks/useFinalGoodsRecip";
import useSoldToParty from "../../../hooks/useSoldToParty";
import InfoSalesMobileModel from "../../InfoSaleMobileModel";
import styles from "./ContactInfo.module.scss";

export const ContactPerson: React.FC<ContactPersonProps> =
  ErrorDetectorMediumView((props: ContactPersonProps) => {
    const {
      title,
      reminder,
      revise,
      name,
      contactPersonInfo,
      visible,
      editable,
      locale,
      clickHandler,
      confirmHandler,
    } = useContactPerson(props);

    const models = React.useMemo(
      () => InfoSalesMobileModel.getContactModifyModel(contactPersonInfo),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contactPersonInfo, locale]
    );

    const contactNameView = React.useMemo(
      () => (
        <div className={styles.name}>
          <span className={styles.icon}>
            <Person />
          </span>
          <span className={styles.nameLabel}>{name}</span>
        </div>
      ),
      [name]
    );

    const infoView = React.useMemo(() => {
      const infos = InfoSalesMobileModel.getContactModels({
        ...contactPersonInfo,
      });

      return infos?.map(({ key, viewType, props }) => {
        const newProps =
          key === OrderInfoModel.MOBILE_PHONE
            ? {
                ...props,
                children: <PhoneCall phoneNumber={props?.children} />,
              }
            : props;

        return (
          <ViewComponent
            key={key}
            type={viewType as ViewType}
            props={{ ...newProps, key }}
          />
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactPersonInfo, locale]);

    return (
      <>
        <div className={styles.contactPerson}>
          <div className={styles.viewTitle}>{title}</div>
          <TextReminder
            label={reminder}
            className={styles.reminder}
            reminderType={ReminderType.ERROR}
          />
          {contactNameView}
          {infoView}
          {editable && (
            <Button className={styles.btn} onClick={clickHandler} size="s">
              {revise}
            </Button>
          )}
        </div>
        {visible && (
          <ModifyContact
            title={title}
            status={ContactPersonStatus.UPDATE}
            models={models}
            visible={visible}
            onConfirm={confirmHandler}
            contentClassName={styles.notContentPending}
          />
        )}
      </>
    );
  });

export const FinalGoodsReceipt: React.FC = ErrorDetectorMediumView(() => {
  const {
    title,
    finalGoodRecip,
    editable,
    revise,
    newLabel,
    finalGoodRecipNew,
    visible,
    models,
    locale,
    updateHandler,
    confirmHandler,
  } = useFinalGoodsRecip();

  const contactNameView = React.useCallback(
    (name: string) => (
      <div className={styles.name}>
        <Icon icon={"Map"} size={16} className={styles.icon} />
        <span className={styles.nameLabel}>{name}</span>
      </div>
    ),
    []
  );

  const infoView = React.useCallback((recipInfos: IFinalGoodsReceipt) => {
    const infos = InfoSalesMobileModel.getPartnerItem(recipInfos);
    return infos?.map(({ key, viewType, props }) => (
      <ViewComponent
        key={key}
        type={viewType as ViewType}
        props={{ ...props, key }}
      />
    ));
  }, []);

  const finalGoodsRecipView = React.useMemo(() => {
    return (
      <>
        <div className={styles.contactPerson}>
          <div className={styles.viewTitle}>{title}</div>
          {contactNameView(finalGoodRecip?.name)}
          {infoView(finalGoodRecip)}
          {editable && !finalGoodRecipNew?.name && (
            <Button
              className={styles.btn}
              onClick={updateHandler.bind(null, FinalGoodsRecipStatus.ADD)}
              size="s"
            >
              {revise}
            </Button>
          )}
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    locale,
    finalGoodRecip,
    editable,
    finalGoodRecipNew?.name,
    updateHandler,
    contactNameView,
    infoView,
    revise,
  ]);

  const finalGoodsRecipNewView = React.useMemo(() => {
    const titleText = (
      <span className={styles.title}>
        {title}
        <span className={styles.new}>{newLabel}</span>
      </span>
    );

    return (
      finalGoodRecipNew?.name && (
        <div className={styles.contactPerson}>
          <div className={styles.viewTitle}>{titleText}</div>
          {contactNameView(finalGoodRecipNew?.name)}
          {infoView(finalGoodRecipNew)}
          {editable && (
            <Button
              className={styles.btn}
              onClick={updateHandler.bind(null, FinalGoodsRecipStatus.UPDATE)}
              size="s"
            >
              {revise}
            </Button>
          )}
        </div>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    newLabel,
    locale,
    finalGoodRecipNew,
    editable,
    revise,
    updateHandler,
    contactNameView,
    infoView,
  ]);

  return (
    <>
      {finalGoodsRecipView}
      {finalGoodsRecipNewView}
      {visible && (
        <ModifyContact
          title={title}
          subTitle={newLabel}
          status={FinalGoodsRecipStatus.UPDATE}
          models={models}
          visible={visible}
          onConfirm={confirmHandler}
        />
      )}
    </>
  );
});

export const SoldToParty: React.FC<FinalGoodsRecipProps> =
  ErrorDetectorMediumView((props: FinalGoodsRecipProps) => {
    const { title } = useSoldToParty();
    const locale = useRecoilValue(localeState);

    const contactNameView = React.useMemo(
      () => (
        <div className={styles.name}>
          <Icon icon={"Map"} size={16} className={styles.icon} />
          <span className={styles.nameLabel}>{props?.name}</span>
        </div>
      ),
      [props?.name]
    );

    const infoView = React.useMemo(() => {
      const infos = InfoSalesMobileModel.getPartnerItem(props);

      return infos?.map(({ key, viewType, props }) => (
        <ViewComponent
          key={key}
          type={viewType as ViewType}
          props={{ ...props, key }}
        />
      ));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, locale]);

    return (
      <div className={styles.contactPerson}>
        <div className={styles.viewTitle}>{title}</div>
        {contactNameView}
        {infoView}
      </div>
    );
  });
