import { ITableColumn } from "../../../components/Table/Table";
import HttpService, { Response } from "../../../core/HttpService";
import { formatMessage } from "../../../core/LangService";
import { LocaleLang } from "../../../core/LocaleService";
import { SystemPlatform } from "../../../core/SystemService";
import {
  IFACIBaseCRMConfirmReq,
  IFacIBaseReq,
  IFacIBaseRes,
  crmConfirm,
  getFacIBaseInfoFAC,
  submitFacIBaseInfo,
} from "../../../services/order.service";
import { formatDate, formatDatePickValue } from "../../../utils/commonUtils";
import { ViewType } from "../../components/ViewTypes";
import { PageStatus, SubTabs } from "../../service/pageType";
import { RsmAction, SoAction } from "../../service/userRoleTypes";
import InstallationModel, {
  WarrantyPeriod,
} from "../Installation/installationModel";
import OrderInfoModel from "../OrderInfo/InfoSales/InfoSalesModel";
import OrderRequestModel, {
  IFile,
  ViewItemModel,
} from "../model/OrderRequestModel";
import ValidationService from "../service/ValidationService";

export interface IBaseInfo {
  iBaseInstallationDate?: string | number;
  iBaseWarrantyStartDate?: string | number;
  iBaseWarrantyEndDate?: string | number;
  iBaseAcceptanceDate?: string | number;
  iBaseNumber?: string;
}

export interface IFacIBase {
  installationTaskId: string;
  facCacReports?: IFile[];
  warrantyPeriod?: WarrantyPeriod;
  otherWarrantyPeriod?: string;
  acceptanceDate?: string | number;
  ibaseInfos: IBaseInfo[];
  ownerInstallationFseName?: string;
  ownerInstallationFseNameCN: string;
  ownerInstallationFseNameEN: string;
}

export default class FacIBaseModel {
  static readonly INSTALLATION_DATE = "iBaseInstallationDate";
  static readonly WARRANTY_START_DATE = "iBaseWarrantyStartDate";
  static readonly WARRANTY_END_DATE = "iBaseWarrantyEndDate";
  static readonly FAC_CAC_REPORTS = "facCacReports";
  static readonly ACCEPTANCE_DATE_FAC = "acceptanceDate";
  static readonly WARRANTY_PERIOD = "warrantyPeriod";
  static readonly IBASE_NUMBER = "iBaseNumber";
  static readonly IBASE_ACCEPTANCE_DATE = "iBaseAcceptanceDate";

  static getFacIBaseInfo = (
    installationTaskId: string,
    locale?: LocaleLang
  ): Promise<IFacIBaseRes> => {
    const infos = HttpService.splitResponse(
      getFacIBaseInfoFAC(installationTaskId)
    );

    return infos.then((res) => ({
      ...res?.data,
      data: {
        ...res?.data,
        facCacReports: OrderRequestModel.formatFile(res?.data?.facCacReports),
        ibaseInfos: res?.data?.ibaseInfos?.map((item: IBaseInfo) => ({
          ...item,
          iBaseInstallationDate: formatDate(
            item.iBaseInstallationDate as number
          ),
          iBaseWarrantyStartDate: formatDate(
            item.iBaseWarrantyStartDate as number
          ),
          iBaseWarrantyEndDate: formatDate(item.iBaseWarrantyEndDate as number),
          iBaseAcceptanceDate: formatDate(item.iBaseAcceptanceDate as number),
        })),
        ownerInstallationFseName: OrderRequestModel.formatNameByLocale(
          res?.data?.ownerInstallationFseNameCN,
          res?.data?.ownerInstallationFseNameEN,
          locale
        ),
      },
    }));
  };

  static submitFacIBaseInfo = (data: IFacIBaseReq): Promise<Response<{}>> => {
    const formattedData = {
      ...data,
      facCacReports: OrderRequestModel.formatUploadItem(
        data?.facCacReports as any[]
      ),
    };
    return HttpService.splitResponse(submitFacIBaseInfo(formattedData));
  };

  static crmConfirm = (data: IFACIBaseCRMConfirmReq): Promise<Response<{}>> => {
    return HttpService.splitResponse(crmConfirm(data));
  };

  static verifyRsmSubmitFacIBase = (infos: Partial<IFacIBase>) => {
    const keys = [
      FacIBaseModel.FAC_CAC_REPORTS,
      FacIBaseModel.ACCEPTANCE_DATE_FAC,
    ];
    return ValidationService.verifyIntegrity(infos, keys);
  };

  static getCRMDateInfoViewModel = (
    info: Partial<IBaseInfo>,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const {
      labelInstallationDate,
      labelAcceptanceDate,
      labelWarrantyStartDate,
      labelWarrantyEndDate,
      labelIBaseNumber,
    } = FacIBaseModel.getLabels();
    const {
      iBaseNumber,
      iBaseAcceptanceDate,
      iBaseInstallationDate,
      iBaseWarrantyEndDate,
      iBaseWarrantyStartDate,
    } = info;

    const textViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    return [
      {
        key: FacIBaseModel.IBASE_NUMBER,
        viewType: ViewType.VIEW_CUSTOMIZED,
        props: {
          label: labelIBaseNumber,
          children: iBaseNumber,
        },
      },
      {
        key: FacIBaseModel.INSTALLATION_DATE,
        viewType: textViewType,
        props: {
          label: labelInstallationDate,
          children: iBaseInstallationDate,
        },
      },
      {
        key: FacIBaseModel.IBASE_ACCEPTANCE_DATE,
        viewType: textViewType,
        props: {
          label: labelAcceptanceDate,
          children: iBaseAcceptanceDate,
        },
      },
      {
        key: FacIBaseModel.WARRANTY_START_DATE,
        viewType: textViewType,
        props: {
          label: labelWarrantyStartDate,
          children: iBaseWarrantyStartDate,
        },
      },
      {
        key: FacIBaseModel.WARRANTY_END_DATE,
        viewType: textViewType,
        props: {
          label: labelWarrantyEndDate,
          children: iBaseWarrantyEndDate,
        },
      },
    ];
  };

  static getFacFileInfoViewModel = (
    infos: IFacIBase,
    pageStatus: PageStatus,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { facCacReports } = infos;
    const { labelFacCacReports } = FacIBaseModel.getLabels();
    const { uploadReminder } = InstallationModel.getLabels();
    const disabled = pageStatus === PageStatus.READONLY;
    const partProps = {
      disableUpload: disabled,
      deletable: !disabled,
    };
    const reminder = disabled ? "" : uploadReminder;
    const uploadViewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: FacIBaseModel.FAC_CAC_REPORTS,
        viewType: uploadViewType,
        props: {
          ...partProps,
          title: labelFacCacReports,
          value: facCacReports ?? [],
          key: FacIBaseModel.FAC_CAC_REPORTS,
          reminder,
        },
      },
    ];

    return originList;
  };

  static getFacFileInfoReadonlyViewModel = (
    infos: IFacIBase,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { facCacReports } = infos;
    const { labelFacCacReports } = FacIBaseModel.getLabels();
    const { uploadReminder } = InstallationModel.getLabels();
    const partProps = {
      disableUpload: true,
      deletable: false,
    };
    const reminder = platform === SystemPlatform.ENV_PC ? uploadReminder : "";
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_UPLOAD_STANDARD_VARIANT
        : ViewType.VIEW_UPLOAD_VARIANT;

    const originList = [
      {
        key: FacIBaseModel.FAC_CAC_REPORTS,
        viewType,
        props: {
          ...partProps,
          title: labelFacCacReports,
          value: facCacReports ?? [],
          key: FacIBaseModel.FAC_CAC_REPORTS,
          reminder,
        },
      },
    ];

    return originList.filter(
      ({ props }) => Array.isArray(props.value) && props.value.length
    );
  };

  static getFacInfoViewMobileModel = (
    info: Partial<IFacIBase>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { labelAcceptanceDate, labelWarrantyPeriod } =
      FacIBaseModel.getLabels();
    const { acceptanceDate, warrantyPeriod } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const formattedAcceptanceDate = formatDatePickValue(
      acceptanceDate as number
    );
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
        viewType: ViewType.VIEW_DATE_PICKER,
        props: {
          label: labelAcceptanceDate,
          value: formattedAcceptanceDate,
          key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
          disabled,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];
  };

  static getFacInfoReadonlyViewModel = (
    info: Partial<IFacIBase>,
    platform?: SystemPlatform
  ): Partial<ViewItemModel>[] => {
    const { labelAcceptanceDate, labelWarrantyPeriod } =
      FacIBaseModel.getLabels();
    const { acceptanceDate, warrantyPeriod } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const formattedAcceptanceDate = formatDate(acceptanceDate as number);
    const viewType =
      platform === SystemPlatform.ENV_PC
        ? ViewType.VIEW_TEXT_VARIANT_PC
        : ViewType.VIEW_TEXT;

    const list = [
      {
        key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
        viewType,
        props: {
          label: labelAcceptanceDate,
          children: formattedAcceptanceDate,
          key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];

    return list.filter(({ props }) => !!props?.children);
  };

  static getFacInfoViewPcModel = (
    info: Partial<IFacIBase>,
    pageStatus: PageStatus
  ): Partial<ViewItemModel>[] => {
    const { labelAcceptanceDate, labelWarrantyPeriod } =
      FacIBaseModel.getLabels();
    const { acceptanceDate, warrantyPeriod } = info;
    const warrantyPeriodLabel = InstallationModel.getWarrantyPeriodLabel(
      warrantyPeriod as WarrantyPeriod
    );
    const formattedAcceptanceDate = formatDatePickValue(
      acceptanceDate as number
    );
    const disabled = pageStatus === PageStatus.READONLY;
    return [
      {
        key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
        viewType: ViewType.VIEW_DROP_DOWN_DATE_PICKER,
        props: {
          label: labelAcceptanceDate,
          value: formattedAcceptanceDate,
          key: FacIBaseModel.ACCEPTANCE_DATE_FAC,
          disabled,
        },
      },
      {
        key: InstallationModel.WARRANTY_PERIOD,
        viewType: ViewType.VIEW_TEXT_VARIANT_PC,
        props: {
          label: labelWarrantyPeriod,
          children: warrantyPeriodLabel,
          key: InstallationModel.WARRANTY_PERIOD,
        },
      },
    ];
  };

  static getFacUploadButtonViewModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelSubmit } = OrderInfoModel.getLabels();

    const original = [
      {
        key: RsmAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: RsmAction.ALL_TICKET,
        },
      },
      {
        key: RsmAction.SUBMIT,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelSubmit,
          key: RsmAction.SUBMIT,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static getCRMConfirmButtonViewModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelConfirm } = OrderInfoModel.getLabels();

    const original = [
      {
        key: SoAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SoAction.ALL_TICKET,
        },
      },
      {
        key: SoAction.CONFIRM,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelConfirm,
          key: SoAction.CONFIRM,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static getIBaseItemColumns = (): ITableColumn[] => {
    const {
      labelInstallationDate,
      labelAcceptanceDate,
      labelWarrantyStartDate,
      labelWarrantyEndDate,
      labelIBaseNumber,
    } = FacIBaseModel.getLabels();
    return [
      {
        key: FacIBaseModel.IBASE_NUMBER,
        label: labelIBaseNumber,
      },
      {
        key: FacIBaseModel.INSTALLATION_DATE,
        label: labelInstallationDate,
      },
      {
        key: FacIBaseModel.IBASE_ACCEPTANCE_DATE,
        label: labelAcceptanceDate,
      },
      {
        key: FacIBaseModel.WARRANTY_START_DATE,
        label: labelWarrantyStartDate,
      },
      {
        key: FacIBaseModel.WARRANTY_END_DATE,
        label: labelWarrantyEndDate,
      },
    ];
  };

  static getSoButtonViewModel = (
    rolePage: SubTabs
  ): Partial<ViewItemModel>[] => {
    const { pageStatus, actions } = rolePage ?? {};
    const { labelAllTicket, labelConfirm } = OrderInfoModel.getLabels();

    const original = [
      {
        key: SoAction.ALL_TICKET,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          variant: "secondary",
          children: labelAllTicket,
          key: SoAction.ALL_TICKET,
        },
      },
      {
        key: SoAction.CONFIRM,
        viewType: ViewType.VIEW_BUTTON,
        props: {
          children: labelConfirm,
          key: SoAction.CONFIRM,
        },
      },
    ];

    return pageStatus === PageStatus.READONLY
      ? original.filter(({ key }) => actions?.includes(key))
      : original;
  };

  static getLabels = () => {
    return {
      labelInstallationDate: formatMessage("order_fac_installation_date"),
      labelAcceptanceDate: formatMessage("order_fac_acceptance_date"),
      labelWarrantyStartDate: formatMessage("order_warranty_start_date"),
      labelWarrantyEndDate: formatMessage("order_warrant_end_data"),
      labelFacCacReports: formatMessage("order_fac_cac_report"),
      labelWarrantyPeriod: formatMessage("order_install_warranty_period"),
      labelIBaseNumber: formatMessage("order_iBase_number"),
      labelOwnerInstallationFse: formatMessage("order_owner_installation_fse"),
    };
  };
}
