import React, { FC, useEffect, useRef, useState } from "react";
import { useLockFn, useThrottleFn } from "../../hooks";
import { isFunction, isWindow } from "../../utils/isType";
import { ErrorDetectorPageView } from "../ErrorDecorator/ErrorDecorator";
import { getScrollParent } from "../utils/getScrollParent";
import { NativeProps, withNativeProps } from "../utils/mergeNativeProps";
import styles from "./InfiniteScroll.module.scss";

// more information, please find https://mobile.ant.design/zh/components/infinite-scroll
export type InfiniteScrollProps = {
  loadMore: (isRetry: boolean) => Promise<void>;
  hasMore: boolean;
  threshold?: number;
  children?:
    | React.ReactNode
    | ((
        hasMore: boolean,
        failed: boolean,
        retry: () => void
      ) => React.ReactNode);
} & NativeProps;

export const InfiniteScroll: FC<InfiniteScrollProps> = ErrorDetectorPageView(
  (props: InfiniteScrollProps) => {
    const { hasMore, threshold = 250, children } = props;
    const [failed, setFailed] = useState(false);
    const doLoadMore = useLockFn(async (isRetry: boolean) => {
      try {
        await props.loadMore(isRetry);
      } catch (e) {
        setFailed(true);
        throw e;
      }
    });

    const elementRef = useRef<HTMLDivElement>(null);

    // Prevent duplicated trigger of `check` function
    const [flag, setFlag] = useState({});
    const nextFlagRef = useRef(flag);

    const [scrollParent, setScrollParent] = useState<
      Window | Element | null | undefined
    >();

    const { run: check } = useThrottleFn(
      async () => {
        if (nextFlagRef.current !== flag) return;
        if (!hasMore) return;
        const element = elementRef.current;
        if (!element) return;
        if (!element?.offsetParent) return;
        const parent = getScrollParent(element);
        setScrollParent(parent);
        if (!parent) return;
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top;
        const current = isWindow(parent)
          ? window.innerHeight
          : parent.getBoundingClientRect().bottom;
        if (current >= elementTop - threshold) {
          const nextFlag = {};
          nextFlagRef.current = nextFlag;
          await doLoadMore(false);
          setFlag(nextFlag);
        }
      },
      {
        wait: 100,
        leading: true,
        trailing: true,
      }
    );

    // Make sure to trigger `loadMore` when content changes
    useEffect(() => {
      check();
    });

    useEffect(() => {
      const element = elementRef.current;
      if (!element) return;
      if (!scrollParent) return;
      function onScroll() {
        check();
      }
      scrollParent.addEventListener("scroll", onScroll);
      return () => {
        scrollParent.removeEventListener("scroll", onScroll);
      };
    }, [scrollParent]);

    async function retry() {
      setFailed(false);
      await doLoadMore(true);
      setFlag(nextFlagRef.current);
    }

    return withNativeProps(
      props,
      <div className={styles.infiniteScroll} ref={elementRef}>
        {isFunction(children)
          ? children(props.hasMore, failed, retry)
          : children}
      </div>
    );
  }
);
